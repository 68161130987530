import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const InvoiceHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("invoices");

  return (
    <>
      <div className="flex items-center  gap-[8.5px]">
        <a onClick={() => navigate(-1)}>
          <svg
            className="mt-[-2px] cursor-pointer"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
          </svg>
        </a>
        <h1 className="hidden lg:block md:block items-center xl:text-[20px] text-[16px]">
          {t("addNewInvoice")}
        </h1>
        <h1 className="md:hidden flex justify-center w-full font-semibold text-[#000000] leading-6 text-[20px]">
          {t("addNewInvoice")}
        </h1>
      </div>
    </>
  );
};

export default InvoiceHeader;
