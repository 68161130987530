import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowDirection, ArrowPointer } from "../../../assets/icons";
import ExpenseInfoWidget from "../../expense/ExpenseInfoWidget";

interface NotificationProps {
  onClose?: () => void;
  onButtonClick?: () => void;
  showSuccessModal?: boolean;
  icon: React.ReactNode;
  buttonText?: string;
  buttonAction?: () => void;
  title?: string;
  autoClose?: boolean;
  position?: "left" | "right";
  secondButtonText?: string;
  secondButtonAction?: () => void;
  showExpenseInfoWidget?: boolean;
  getBudget?: () => Promise<
    { actualExpenses: number; allocatedBudget: number }[]
  >;
  Icon?: React.ComponentType<{ className?: string }>;
  isBillable?: boolean;
}

const Notification: React.FC<NotificationProps> = ({
  onClose,
  onButtonClick,
  showSuccessModal,
  icon,
  buttonText,
  buttonAction,
  title,
  autoClose = true,
  position = "left",
  secondButtonText,
  secondButtonAction,
  isBillable,
  showExpenseInfoWidget = false,
  getBudget,
  Icon,
}) => {
  const navigate = useNavigate();
  const [autoCloseTimeout, setAutoCloseTimeout] = useState<number | undefined>(
    undefined
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => {
    let timer: number;

    if (autoClose) {
      timer = window.setTimeout(() => {
        onClose && onClose();
      }, 1000); // Set timeout to 1000 milliseconds (1 second)

      setAutoCloseTimeout(timer);
    }

    return () => {
      clearTimeout(timer); // Clear the timeout when the component unmounts
    };
  }, [autoClose, onClose]);

  const handleDefaultButtonClick = () => {
    onClose && onClose();
  };

  const handleDefaultButtonAction = () => {
    onButtonClick && onButtonClick();
  };

  const handleButtonClick = () => {
    if (buttonAction) {
      clearTimeout(autoCloseTimeout); // Clear the auto-close timeout
      buttonAction();
    } else {
      handleDefaultButtonAction();
    }
  };

  const handleSecondButtonClick = () => {
    if (secondButtonAction) {
      clearTimeout(autoCloseTimeout); // Clear the auto-close timeout
      secondButtonAction();
    } else {
      handleDefaultButtonAction();
    }
  };
  return (
    <>
      <div
        className={`h-screen w-[434px] ${
          position === "right" ? "flex-row-reverse" : ""
        }`}
      >
        {title && (
          <div className="flex justify-center mt-[1em] text-[25px] font-bold">
            <h3 className="mt-[-2em]">{title}</h3>
          </div>
        )}
        <div className="flex justify-center mt-[1em]">{icon}</div>
        <div role="form" onSubmit={(e) => e.preventDefault()}>
          <div className="mt-[2em]">
            {showExpenseInfoWidget && getBudget && Icon && (
              <ExpenseInfoWidget getBudget={getBudget} Icon={ArrowPointer} />
            )}
          </div>
          <div className="flex ml-[3em] pt-[49px] pb-[40px]">
            {buttonText && (
              <button
                className="w-[215px] h-[48px] rounded-md text-[#FFFFFF] text-[16px] font-medium bg-[#FF6702]"
                onClick={handleButtonClick}
              >
                {buttonText}
              </button>
            )}
          </div>
          <div className="flex ml-[3em] pb-[40px]">
            {secondButtonText && !isBillable && (
              <button
                className="w-[215px] h-[48px] rounded-md text-[#044E97] border border-[#044E97] text-[16px] font-medium bg-[#FFFFFF]"
                onClick={handleSecondButtonClick}
              >
                {secondButtonText}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
