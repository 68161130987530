import React, { useEffect, useState } from "react";
import AddModal from "../modal/AddModal";
import { Button } from "../../../common";
import { InputComponent } from "../../../core/Forms";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import ExchangeRateIcon from "../../../../assets/icons/ExchangeRateIcon";
import { AddSquareIcon } from "../../../../assets/icons";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import { VariationItem } from "../../../../@types/inventory";
import { useInventoryStore } from "../../../../state/useInventoryStore";

interface Option {
  name: string;
  type: string[];
}

export interface FetchedVariationItem {
  id?: string | undefined;
  variantName: string;
  types: string[];
  price: string;
  costPrice: string;
  quantity: string;
}
interface ProductOptionsProps {
  options: Option[];
  setOptions: (options: Option[]) => void;
  setOpenOptions?: (options: boolean) => void;
  handleOpenVariants: () => void;
  setCurrentView: React.Dispatch<
    React.SetStateAction<
      | "selectItem"
      | "addInventory"
      | "productOptions"
      | "productVariants"
      | "viewSelectItems"
      | "showSelectedItem"
      | null
    >
  >;
  variations: VariationItem[];
  setVariationList: React.Dispatch<React.SetStateAction<VariationItem[]>>;
  closeProductOptions: () => void;
}
const ProductOptions: React.FC<ProductOptionsProps> = ({
  options,
  setOptions,
  handleOpenVariants,
  setCurrentView,
  variations,
  setVariationList,
  closeProductOptions,
}) => {
  const [variationName, setVariationName] = useState("");
  const [variationType, setVariationType] = useState("");
  const [localVariations, setLocalVariations] = useState<
    FetchedVariationItem[]
  >([]);
  const fetchVariations = useInventoryStore((state) => state.fetchVariations);
  const storeVariations = useInventoryStore((state) => state.variationItems);
  const [isAddingOption, setIsAddingOption] = useState(false);
  const [selectVariantOption, setSelectVariantOption] = useState<
    | {
        name: string;
        type: string[];
      }[]
    | undefined
  >();

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  useEffect(() => {
    setLocalVariations(storeVariations);
    setVariationList(storeVariations);
  }, [storeVariations, setVariationList]);

  const addOption = () => {
    setIsAddingOption(true);
    const newOptions = [...options, { name: "", type: [""] }];
    setOptions(newOptions);
  };

  const removeOption = (index: number) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setOptions(updatedOptions);
  };

  const updateOptionName = (index: number, name: string) => {
    const updatedOptions = [...options];
    updatedOptions[index].name = name;
    setOptions(updatedOptions);
  };

  const updateOptionValue = (
    optionIndex: number,
    valueIndex: number,
    value: string,
  ) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].type[valueIndex] = value;
    setOptions(updatedOptions);
  };

  const addOptionValue = (index: number) => {
    const updatedOptions = [...options];
    updatedOptions[index].type.push("");
    setOptions(updatedOptions);
  };

  const removeOptionValue = (optionIndex: number, valueIndex: number) => {
    const updatedOptions = [...options];
    updatedOptions[optionIndex].type = updatedOptions[optionIndex].type.filter(
      (_: any, i: number) => i !== valueIndex,
    );
    setOptions(updatedOptions);
  };

  const handleBackToAddInventory = () => {
    setCurrentView("addInventory");
  };

  const handleShowvariant = (variantType: string) => {
    setCurrentView("showSelectedItem");
  };

  const handleVariantClick = (variantType: Option[]) => {
    setOptions(variantType);
    handleAddNewOptionClick();
  };
  const [showNewOption, setShowNewOption] = useState(false);
  const [showVariations, setShowVariations] = useState(true);
  const handleAddNewOptionClick = () => {
    setShowNewOption(!showNewOption);
    setShowVariations(!showVariations);
  };

  useEffect(() => {
    console.log(showNewOption);
  }, [showNewOption]);

  return (
    <AddModal className="md:max-w-[500px]">
      <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-5 pb-2">
            <button
              className="mt-[-2px] cursor-pointer"
              onClick={handleBackToAddInventory}
            >
              <svg
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </button>

            <div className="w-full flex justify-center">
              <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                Product options
              </p>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="flex flex-col justify-start text-[14px] ">
              <p className="font-semibold text-[20px] leading-6 text-[#000000] mb-2">
                Product options
              </p>
              <p className="mb-2">Select your preferred option</p>
            </div>
            {showNewOption || (
              <div
                className="text-[#044E97] text-[14px] font-medium"
                onClick={handleAddNewOptionClick}
              >
                <p>Add new option</p>
              </div>
            )}
          </div>

          <div className="w-full">
            {/* Display existing variations */}
            {showVariations &&
              localVariations.length > 0 &&
              localVariations.map((variation, index) => (
                <div
                  key={index}
                  className="border border-gray-200 bg-[#FCFCFD] p-3 rounded-lg mb-4 w-full"
                  /* @ts-ignore */
                  onClick={() =>
                    handleVariantClick([
                      {
                        name: variation?.variantName,
                        type: variation?.types,
                      },
                    ])
                  }
                >
                  <div className="flex justify-between items-center">
                    {/* @ts-ignore */}
                    <h3 className="font-medium">{variation.variantName}</h3>
                  </div>
                  <div className="flex gap-2 mt-2 flex-wrap">
                    {/* @ts-ignore */}
                    {variation.types &&
                      /* @ts-ignore */
                      variation.types.map((type, idx) => (
                        <span
                          key={idx}
                          className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-700"
                        >
                          {type}
                        </span>
                      ))}
                  </div>
                </div>
              ))}

            {showNewOption && (
              <div>
                {selectVariantOption ? (
                  <>
                    {selectVariantOption.map((option, index) => {
                      return (
                        <div
                          key={index}
                          className="border border-gray-200 bg-[#FCFCFD] p-3 rounded-lg mb-4 w-full"
                        >
                          <div className="flex items-center justify-between mb-4 w-full">
                            <InputComponent
                              type="text"
                              className="w-full"
                              placeholder="Option name"
                              mainWidth="w-full"
                              value={option.name}
                              handleChange={(e) =>
                                updateOptionName(index, e.target.value)
                              }
                            />
                            <button
                              className="ml-2 text-red-500"
                              onClick={() => removeOption(index)}
                            >
                              <DeleteIcon />
                            </button>
                          </div>

                          <div className="flex gap-2 pl-3 w-full">
                            <ExchangeRateIcon />
                            <div className="w-full">
                              {option?.type?.map((value, valueIndex) => (
                                <div
                                  key={valueIndex}
                                  className="flex items-center gap-[14.5px] mb-2 w-full"
                                >
                                  <InputComponent
                                    type="text"
                                    mainWidth="w-full"
                                    className="p-2 w-full flex-grow rounded-lg"
                                    placeholder="Option value"
                                    value={value}
                                    handleChange={(e) =>
                                      updateOptionValue(
                                        index,
                                        valueIndex,
                                        e.target.value,
                                      )
                                    }
                                  />
                                  {option.type.length - 1 !== valueIndex && (
                                    <button
                                      className="text-red-500"
                                      onClick={() =>
                                        removeOptionValue(index, valueIndex)
                                      }
                                    >
                                      <DeleteIcon />
                                    </button>
                                  )}
                                  {option.type.length - 1 === valueIndex && (
                                    <button
                                      className="ml-[0.35em] text-red-500"
                                      onClick={() => addOptionValue(index)}
                                    >
                                      <PlusIcon />
                                    </button>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {options.map((option, index) => (
                      <div
                        key={index}
                        className="border border-gray-200 bg-[#FCFCFD] p-3 rounded-lg mb-4 w-full"
                      >
                        <div className="flex items-center justify-between mb-4 w-full">
                          <InputComponent
                            type="text"
                            className="w-full"
                            placeholder="Option name"
                            mainWidth="w-full"
                            value={option.name}
                            handleChange={(e) =>
                              updateOptionName(index, e.target.value)
                            }
                          />
                        </div>

                        <div className="flex gap-2 pl-3 w-full">
                          <ExchangeRateIcon />
                          <div className="w-full">
                            {option.type.map((value, valueIndex) => (
                              <div
                                key={valueIndex}
                                className={`flex items-center gap-[14.5px] mb-2 w-full ${option.type.length - 1 === valueIndex && "h-16 items-start "}`}
                              >
                                <InputComponent
                                  type="text"
                                  mainWidth="w-full"
                                  className="p-2 w-full flex-grow rounded-lg"
                                  placeholder="Option value"
                                  value={value}
                                  handleChange={(e) =>
                                    updateOptionValue(
                                      index,
                                      valueIndex,
                                      e.target.value,
                                    )
                                  }
                                />
                                {option.type.length - 1 !== valueIndex && (
                                  <button
                                    className={`text-red-500 ${option.type.length == 1 && "hidden"}`}
                                    onClick={() =>
                                      removeOptionValue(index, valueIndex)
                                    }
                                  >
                                    <DeleteIcon />
                                  </button>
                                )}
                                {option.type.length - 1 === valueIndex && (
                                  <div className="flex flex-col justify-between">
                                    <button
                                      className={`text-red-500 mb-5 ${option.type.length == 1 && "hidden"}`}
                                      onClick={() =>
                                        removeOptionValue(index, valueIndex)
                                      }
                                    >
                                      <DeleteIcon />
                                    </button>
                                    <button
                                      className="ml-[0.35em] text-red-500"
                                      onClick={() => addOptionValue(index)}
                                    >
                                      <PlusIcon />
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}

            {/* Add new product options */}
            {localVariations.length === 0 && (
              <button
                className="flex gap-2 items-center mb-4"
                onClick={addOption}
              >
                <PlusIcon />
                <span className="text-[#044E97] font-semibold text-[16px] leading-6">
                  Add another option
                </span>
              </button>
            )}
          </div>
        </div>

        <div
          style={{
            boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
          }}
          className="w-full py-5"
        >
          <Button
            variant="secondary"
            disabled={false}
            text={"Continue"}
            size="full"
            font="semibold"
            onClick={handleOpenVariants}
          />
        </div>
      </div>
    </AddModal>
  );
};

export default ProductOptions;
