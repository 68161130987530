// Modal.js
import React, { ReactNode } from "react";

interface AddModalProps {
  children: ReactNode;
  className?: string;
}

const AddModal: React.FC<AddModalProps> = ({ children, className }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-end md:items-center justify-center bg-black bg-opacity-50">
      {/* Modal content */}
      <div className="w-full max-w-lg bg-white rounded-t-[24px] lg:rounded-[24px] max-h-[90vh] no-scrollbar shadow-lg overflow-y-auto flex flex-col grow">
        <div className="p-5 flex-1">{children}</div>
      </div>
    </div>
  );
};

export default AddModal;
