import React from "react";
import toast from "react-hot-toast";
import { IVendor } from "../../../@types";
import { createVendor } from "../../../backend-services/vendor";
import { VendorT } from "../../../types";
import { CreateModal } from "../../common/CreateModal";
import { AddVendors } from "../../vendors/Addvendor";
import CreateVendorForm from "../../vendors/Createvendor";

interface CreateProps {
  showSideModal: boolean;
  setShowSideModal: (show: boolean) => void;
  handleSelectedVendor: (selectedVendor: IVendor | null) => void;
  onVendorCreate?: (vendor: any) => void;
  onClose?: () => void;
}

const CreateVendorModal: React.FC<CreateProps> = ({
  handleSelectedVendor,
  showSideModal,
  setShowSideModal,
  onClose,
  onVendorCreate,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);

  const handleCreateVendor = async (vendor: VendorT) => {
    setIsLoading(true);
    setErrorMessage("");

    if (!vendor.name) {
      setErrorMessage("The name field is required.");
      setIsLoading(false);
      return;
    }

    const flatVendor = {
      name: vendor.name,
      phone: vendor.phone,
      address: vendor.address,
      city: vendor.city,
      state: vendor.state,
    };

    try {
      const data = await createVendor(flatVendor);
      setIsLoading(false);
      setIsOpen(false);
      toast.success("Vendor created successfully!");
      if (onVendorCreate) {
        onVendorCreate({
          id: data.id,
          name: data.name,
          phone: data.phone,
          address: data.address,
        });
      }
      if (onClose) onClose();
    } catch (error: any) {
      setErrorMessage(error.message || "Error creating vendor");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="hidden lg:block md:block">
        <div className="fixed top-0 left-0 w-screen h-screen bg-opacity-80 flex items-center justify-center z-10 ">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <CreateVendorForm
              isLoading={isLoading}
              show={true}
              onSubmit={handleCreateVendor}
              buttontitle={"Add Vendor"}
              onClose={() => setShowSideModal(false)}
              setFormData={function (
                value: React.SetStateAction<IVendor>
              ): void {
                throw new Error("Function not implemented.");
              }}
            />
          </div>
        </div>
      </div>
      <div className="lg:hidden md:hidden">
        <AddVendors
          show={showSideModal}
          onClose={() => setShowSideModal(false)}
          onVendorCreate={(vendor) => {
            handleSelectedVendor(vendor);
            setShowSideModal(false);
          }}
        />
      </div>
    </div>
  );
};

export default CreateVendorModal;
