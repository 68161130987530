import React from "react";
import { CloseIcon, CloseIconSmall } from "../../../assets/icons";
import BannerIcon from "../../../assets/icons/bannerIcon";

interface SuccessBannerProps {
  message: string;
  linkText?: string;
  onLinkClick?: () => void;
  onClose?: () => void;
}

const SuccessBanner: React.FC<SuccessBannerProps> = ({
  message,
  linkText,
  onLinkClick,
  onClose,
}) => {
  return (
    <div className="fixed w-screen top-5 md:top-[72px] flex items-center justify-center z-50">
      <div className="bg-[#E0EEFE] rounded shadow p-3 md:p-4 flex gap-3 items-center transform -translate-x-0 lg:-translate-x-[80px]">
        <BannerIcon />
        <span className="flex-1 text-[#084784]   lg:text-[16px] 2xl:text-[20px] text-[14px] whitespace-nowrap hidden lg:block md:block font-medium">
          {message}
        </span>
        <span className="flex-1 text-[#084784] 0 lg:text-[16px] 2xl:text-[20px] text-[10px] whitespace-normal lg:hidden md:hidden font-medium">
          {message}
        </span>
        <button
          className="ml-2 text-[#084784] underline  biglg:text-[16px] xl:text-[16px] 2xl:text-[20px] text-[8px] md:text-[14px] hidden lg:block md:block "
          onClick={onLinkClick}
        >
          {linkText}
        </button>
        <button
          className="ml-[-1.5em] text-[#084784] underline  biglg:text-[16px] xl:text-[16px] 2xl:text-[20px] text-[8px] md:text-[14px] lg:hidden md:hidden mt-[3em] font-semibold"
          onClick={onLinkClick}
        >
          {linkText}
        </button>
        {onClose && (
          <button
            className="hidden lg:block md:block ml-4 text-gray-700 text-xl"
            onClick={onClose}
          >
            <CloseIcon />
          </button>
        )}
        {onClose && (
          <button
            className="lg:hidden md:hidden ml-[-1.5em] text-gray-700  mt-[-2em]"
            onClick={onClose}
          >
            <CloseIconSmall />
          </button>
        )}
      </div>
    </div>
  );
};

export default SuccessBanner;
