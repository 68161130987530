import react, { useState } from "react";
import { SubscriptionModal } from "../components/common/Subscription/SubscriptionModal";
import StatsContainer from "../components/core/StatCard";
import { useSubUserStore } from "../state/useSubUserStore";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../assets/icons";
import { CustomSearchDropdown } from "../components/common/CustomSearch";
import {
  useCustomerSearch,
  useExpenseSearch,
  useInventorySearch,
  useInvoiceSearch,
} from "../hooks";
import { useServiceSearch } from "../hooks/service/useServiceSearch";
import { inventoryService } from "../services";
import React from "react";
import { IInventory } from "../@types";
import { useUserStore, useSubscriptionStore } from "../state";
import SubscriptionBanner from "../components/Subscription/SubscriptionBanner";
import { ChangePassword } from "../components/core/ChangePassword";
import { useTranslation } from "react-i18next";
import CardComponent from "../components/core/operatorDashboard/CardComponent";
import { CardWithSlideshow } from "../components/core/operatorDashboard/CardWithSlideshow";

const OperatorWelcomePage = () => {
  const { t } = useTranslation("dashboard");
  const navigate = useNavigate();
  const { subscriptionData } = useSubscriptionStore();
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [bannerMessage, setBannerMessage] = useState<string>("");
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(true);
  const isPasswordChanged = useSubUserStore((state) => state.isPasswordChanged);
  const [text, setText] = useState("");
  const [, setIsSearching] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState<string>("Customers");
  const [error, setError] = useState<string>("");

  const handleCloseChangePasswordModal = () => {
    setIsChangePasswordModalOpen(false);
  };

  const handleCategoryChange = (categoryName: string) => {
    setSelectedCategory(categoryName);
    setText("");
  };

  const handleSearchSelect = (item: any) => {
    if (item && item.type)
      switch (item.type) {
        case "invoice":
          navigate(`/invoices/${item.data.id}/view`);
          break;
        case "expense":
          navigate(`/expenses/${item.data.id}/view`);
          break;
        case "customer":
          navigate(`/customers/${item.data.id}/view`);
          break;
        case "inventory":
          navigate(`/inventories/${item.data.id}/view`);
          break;
        case "service":
          navigate(`/services/${item.data.id}/view`);
          break;
        default:
          break;
      }
  };

  const handleSearch = async (text: string, category: string) => {
    if (!text || text.length < 2) return;

    try {
      setIsSearching(true);
      const selectedCategory = categories.find((cat) => cat.name === category);
      if (selectedCategory) {
        await selectedCategory.handleSearch(text);
      }
    } catch (error) {
      throw new Error("Error occurred during search:");
    } finally {
      setIsSearching(false);
    }
  };

  const {
    data: customers,
    isSearching: isCustomerSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const {
    data: invoices,
    isSearching: isInvoiceSearching,
    handleSearch: handleInvoiceSearch,
  } = useInvoiceSearch();
  const {
    data: expenses,
    isSearching: isExpenseSearching,
    handleSearch: handleExpenseSearch,
  } = useExpenseSearch();
  const {
    data: inventories,
    isSearching: isInventorySearching,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();
  const {
    data: services,
    isSearching: isServiceSearching,
    handleSearch: handleServiceSearch,
  } = useServiceSearch();

  const allData = [
    ...customers.map((customer) => ({
      type: "customer",
      data: customer,
      display: `${customer.firstName} (${customer.lastName})`,
    })),
    ...invoices.map((invoice) => ({
      type: "invoice",
      data: invoice,
      display: invoice.invoiceNumber,
    })),
    ...expenses.map((expense) => ({
      type: "expense",
      data: expense,
      display: expense.vendorName,
    })),
    ...inventories.map((inventory) => ({
      type: "inventory",
      data: inventory,
      display: inventory.name,
    })),
    ...services.map((service) => ({
      type: "service",
      data: service,
      display: service.name,
    })),
  ];

  const categories = [
    {
      name: "Invoices",
      placeholder: "Search for Invoice number or customer name",
      searchKey: "invoiceNumber",
      handleSearch: handleInvoiceSearch,
    },
    {
      name: "Expenses",
      placeholder: "Search for Vendor name",
      searchKey: "vendorName",
      handleSearch: handleExpenseSearch,
    },
    {
      name: "Customers",
      placeholder: "Search Customer Name ",
      searchKey: "firstName",
      handleSearch: handleCustomerSearch,
    },
    {
      name: "Inventories",
      placeholder: "Search for inventory name ",
      searchKey: "name",
      handleSearch: handleInventorySearch,
    },
    {
      name: "Services",
      placeholder: "Search for services name",
      searchKey: "name",
      handleSearch: handleServiceSearch,
    },
  ];

  const [outStockInventories, setOutStockInventories] = useState<IInventory[]>(
    []
  );

  const fetchOutOfStockInventory = async () => {
    try {
      const res = await inventoryService.get(
        `/inventories?stockStatus=out-of-stock`
      );
      const inventories = res.data.data;
      const updatedInventories = inventories.slice(0, 4);
      setOutStockInventories(updatedInventories);
    } catch (error) {
      setError("Failed to fetch Inventories. Please try again later.");
    }
  };

  React.useEffect(() => {
    fetchOutOfStockInventory();
  }, []);

  const closeShowBanner = () => {
    setShowBanner(false);
  };

  const subscriptionStatus = (status: string, plan: string) => {
    return status !== "active" || plan === "free";
  };

  return (
    <div className="overflow-hidden">
      <p className="font-semibold text-[24px]"> Welcome back,</p>
      <div className="">
        <div className=" fixed top-[68px] w-[350px] h-[50px] md:h-0 lg:h-0 xl:h-0 2xl:h-0 biglg:h-0 md:w-[650px] lg:w-[700px] left-[50%] lg:left-[59%] transform -translate-x-1/2 z-50">
          {subscriptionStatus(subscriptionData.status, subscriptionData.plan) &&
            showBanner && (
              <SubscriptionBanner
                message={bannerMessage}
                onClose={closeShowBanner}
              />
            )}
        </div>
      </div>
      <div className="mt-[2em] ml-[-8em] md:ml-[-23em]  xl:ml-0 2xl:ml-0 biglg:ml-[-20em]">
        <CustomSearchDropdown
          data={allData}
          searchKey={"display"}
          displayKey="display"
          dropdownClassName="biglg:w-[650px] xl:w-[750px] 2xl:w-[750px] md:w-[608px] bigmd:w-[608px] w-[370px] mt-[-1em] ml-[-6.2em] biglg:ml-[-9.3em] 2xl:ml-[-9.3em] xl:ml-[-9.3em]"
          classNames="w-[290px]"
          dropdownStyle={{
            maxHeight: "1500px",

            overflowY: "auto",
          }}
          value={text}
          isLoading={
            isCustomerSearching ||
            isInvoiceSearching ||
            isExpenseSearching ||
            isInventorySearching ||
            isServiceSearching
          }
          handleChangeText={(text: string) => {
            const currentCategory = categories.find(
              (cat) => cat.name === selectedCategory
            );

            if (currentCategory) {
              handleSearch(text, currentCategory.name);
            } else {
              setError("Selected category not found");
            }
          }}
          onSelect={handleSearchSelect}
          emptyMessage={"This Item is not found"}
          clearIcon={CloseIcon}
          categories={categories}
          onCategoryChange={handleCategoryChange}
          placeholder={
            categories.find((cat) => cat.name === selectedCategory)?.placeholder
          }
        />
      </div>
      <div>
        <StatsContainer />
      </div>
      <div className="w-full mx-1 flex flex-col lg:flex-row gap-5 md:gap-8 lg:gap-[2%]">
        <div className="lg:w-1/2">
          <CardWithSlideshow />
        </div>
        <CardComponent inventories={outStockInventories} />
      </div>
      <div>
        {!isPasswordChanged && isChangePasswordModalOpen && (
          <SubscriptionModal>
            <ChangePassword onClose={handleCloseChangePasswordModal} />
          </SubscriptionModal>
        )}
      </div>
      <div className="mt-[50px]"></div>
    </div>
  );
};

export { OperatorWelcomePage };
