import React, { useState, useEffect } from "react";
import AddModal from "../modal/AddModal";
import { CloseIcon } from "../../../../assets/icons";
import { InputComponent } from "../../../core/Forms";
import { Button } from "../../../common";
import { ICreateSalesData, IInventory } from "../../../../@types";
import toast from "react-hot-toast";
import { formatCurrencyMoney } from "../../../../utils";

export interface InventoryFormData {
  id: string;
  name: string;
  amount: number;
  quantity: number;
  rate: number | undefined;
  variations:
    | {
        name: string;
        costPrice: number;
        quantity: number;
        id: string;
        type: string;
        price: number;
      }[]
    | undefined;
}

export interface Vary {
  name: string;
  costPrice: number;
  quantity: number;
  id: string;
  type: string;
  price: number;
}

interface ShowSelectedItemProps {
  openViewSelectItems: (
    quantities: { [key: string]: string },
    selectedInventory: IInventory | null,
  ) => void;
  selectedInventory: InventoryFormData;
  closeModal: () => void;
  setCurrentView: React.Dispatch<
    React.SetStateAction<
      | "selectItem"
      | "addInventory"
      | "productOptions"
      | "productVariants"
      | "viewSelectItems"
      | "showSelectedItem"
      | null
    >
  >;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  formData: ICreateSalesData;
  edit?: boolean;
  fullData?: any;
}
type InventoryItem = {
  id: string;
  name: string;
  amount: number;
  quantity: number;
  rate: number;
};
const ShowSelectedItem = ({
  selectedInventory,
  closeModal,
  setFormData,
  formData,
  edit = false,
  fullData,
}: ShowSelectedItemProps) => {
  const [inventoryItem, setInventoryItem] =
    useState<InventoryFormData>(selectedInventory);
  const [selectedVariation, setSelectedVariation] = useState<
    | {
        name: string;
        costPrice: number;
        quantity: number;
        id: string;
        type: string;
        price: number;
      }[]
    | undefined
  >(selectedInventory?.variations);
  const [selectedVariations, setSelectedVariations] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    if (edit == true && selectedVariation && inventoryItem?.variations) {
      const strings = inventoryItem?.variations.map((i) => i.id);
      setSelectedVariations((prev) => [...prev, ...strings]);
    }
  }, [edit]);

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    variationId: string,
    hasVariations: boolean,
    quant: number,
  ) => {
    const quantity = e.target.value;
    const newQuantity = parseInt(quantity, 10) || 0;

    if (newQuantity > quant) {
      toast.error(
        "Item Quantity is more than current stock. Input lower amount.",
      );
      return;
    }

    handleVariationSelect(variationId, newQuantity);

    if (hasVariations) {
      setSelectedVariation((prev) =>
        prev?.map((i) => {
          return i.id == variationId ? { ...i, quantity: newQuantity } : i;
        }),
      );
    } else {
      setInventoryItem((prev) => ({
        ...prev,
        quantity: newQuantity, // Update the quantity
      }));
    }

    setQuantities((prev) => ({
      ...prev,
      [variationId]: quantity,
    }));
  };

  const handleClearQuantity = (variationId: string) => {
    if (selectedVariation) {
      const rest = selectedVariation.filter((i) => i.id !== variationId);

      if (rest.length == 0) {
        const updated = formData.inventories.filter(
          (i) => i.id !== inventoryItem.id,
        );
        //  @ts-ignore
        setFormData((prev) => ({ ...prev, inventories: updated }));
      } else {
        const data = {
          id: inventoryItem.id,
          amount: inventoryItem.amount,
          name: inventoryItem.name,
          quantity: inventoryItem.quantity,
          rate: inventoryItem.rate,
          variations: rest,
        };
        const restData = formData.inventories.filter(
          (i) => i.id !== inventoryItem.id,
        );
        //  @ts-ignore
        setFormData((prev) => ({
          ...prev,
          //  @ts-ignore
          inventories: [...restData, data],
        }));
      }
    } else {
      const updated = formData.inventories.filter((i) => i.id !== variationId);
      //  @ts-ignore
      setFormData((prev) => ({ ...prev, inventories: updated }));
    }
    closeModal();
  };

  const selected = selectedVariation?.filter(
    (item) => selectedVariations.includes(item.id) && item.quantity > 0,
  );
  const handleVariationSelect = (variationId: string, quantity: number) => {
    setSelectedVariations((prevSelected) => {
      if (quantity === 0) {
        // Remove the variation if quantity is 0
        return prevSelected.filter((id) => id !== variationId);
      } else if (!prevSelected.includes(variationId)) {
        // Add the variation if not already selected
        return [...prevSelected, variationId];
      }
      return prevSelected; // Return the same array if no changes are made
    });
  };
  const handleDoneClick = () => {
    const newData = {
      id: inventoryItem.id,
      amount: inventoryItem.amount,
      name: inventoryItem.name,
      quantity: inventoryItem.quantity,
      rate: inventoryItem.rate,
      variations: selected?.length ? selected : undefined,
    };
    if (formData.inventories.find((i) => i.id == inventoryItem.id)) {
      const updated = formData.inventories.map((i) => {
        return i.id == inventoryItem.id ? { ...newData } : i;
      });
      //  @ts-ignore
      setFormData((prev) => ({ ...prev, inventories: updated }));
    } else {
      const updatedInventories = [...(formData.inventories || []), newData];
      //  @ts-ignore
      setFormData((prev) => ({ ...prev, inventories: updatedInventories }));
    }
    closeModal();
  };

  const check = (id: string) => {
    return edit
      ? fullData?.variations?.find((i: any) => i.id == id)?.quantity
      : fullData?.variations?.find((i: any) => i.id == id)?.quantity;
  };

  const renderInventoryDetails = () => {
    if (!selectedInventory) return null;

    const { name, rate, id: inventoryId, quantity } = selectedInventory;

    return (
      selectedVariation && selectedVariation.length > 0
        ? selectedVariation
        : [
            {
              id: inventoryId,
              name: "Default",
              price: rate,
              quantity: 0,
              type: "",
            },
          ]
    ).map((variation) => {
      const variationId = variation.id || "";
      const formVariationEntry = formData.inventories.find((i) =>
        i.variations?.find((v) => v.id == variationId),
      )?.variations;
      const formInventoryEntry = formData.inventories.find(
        (i) => i.id == variationId,
      );
      const quant = selectedVariation
        ? check(variationId)
        : fullData?.quantity || 0;

      return (
        <div
          key={variationId}
          className={`w-full border-[1.5px] rounded-[8px] bg-[#FCFCFD] p-2 mt-2 ${
            selectedVariations.includes(variationId) ||
            formInventoryEntry ||
            formVariationEntry
              ? "border-[#0469C5]"
              : "border-gray-200"
          } ${quant == 0 && "border-[#c50404]"}`}
        >
          <div className="flex justify-between text-[14px] p-1">
            <p className="font-medium text-gray-800 leading-5">{name}</p>
            {variation.name !== "Default" ? (
              <p className="text-gray-700 leading-5">
                {variation.name} - {variation.type}
              </p>
            ) : (
              <p className="text-gray-700 leading-5">No Variations</p>
            )}
          </div>
          <div className="flex gap-2 w-full mt-2">
            <InputComponent
              value={formatCurrencyMoney(
                variation.price,
                formData.exchangeRate,
                formData.currency,
              )}
              // value={`N${variation.price}`}
              className="w-full border border-gray-200 p-2 text-gray-400"
              name="price"
              disabled={true}
            />
            <InputComponent
              placeholder={`${quant} Items left`}
              name="quantity"
              value={
                selectedVariation
                  ? variation?.quantity
                  : inventoryItem.quantity || ""
              }
              disabled={quant == 0}
              className="w-full border border-gray-200 p-2 text-gray-400"
              handleChange={(e) =>
                handleQuantityChange(
                  e,
                  variationId,
                  selectedVariation !== undefined,
                  quant!,
                )
              }
            />
          </div>
          <div className="flex justify-between items-center">
            {quant == 0 && (
              <div className=" w-full text-start text-[14px] text-[#D92D20] font-medium p-1 mt-3">
                <p>Out of Stock</p>
              </div>
            )}
            {quant !== 0 && (
              <div className=" w-full text-start text-[14px] text-gray-500 font-medium p-1 mt-1">
                <p>{quant} items left</p>
              </div>
            )}
            {(formData.inventories.find((i) => i.id == variationId) ||
              formData.inventories.find((i) =>
                i.variations?.find((v) => v.id == variationId),
              )) &&
              edit && (
                <div className="text-end w-full text-[14px] text-[#D92D20] font-medium p-1 mt-3">
                  <p onClick={() => handleClearQuantity(variationId)}>Remove</p>
                </div>
              )}
          </div>
        </div>
      );
    });
  };

  return (
    <AddModal>
      <div>
        <div className="flex justify-between items-center py-2">
          <p className="font-semibold text-[18px] leading-6 text-[#000]">
            Product Variants
          </p>
          <div onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>

        <hr className="my-3 mx-[-20px] border border-gray-200" />

        {renderInventoryDetails()}

        <div className="mt-5">
          <Button
            variant="secondary"
            outline={true}
            disabled={selected?.length == 0}
            text="Done"
            size="full"
            font="semibold"
            onClick={handleDoneClick}
          />
        </div>
      </div>
    </AddModal>
  );
};

export default ShowSelectedItem;
