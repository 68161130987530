import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { CirclePicker } from "react-color";
import { Button } from "../../common";
import { generateDistinctColorPalette } from "../../../utils/generateColor";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useUserStore } from "../../../state";
import { toBase64 } from "../../../utils/fileToBase64";
import { MAX_FILE_SIZE_KB, fileSizeInKB } from "../../../constants/todo";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface BrandIdentityProps {
  onClose: () => void;
}

const BrandIdentityModal = ({ onClose }: BrandIdentityProps) => {
  const user = useUserStore((state) => state.user);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedColor, setSelectedColor] = useState<string | undefined>(
    undefined
  );
  const [base64Logo, setBase64Logo] = useState("");
  const [error, setError] = React.useState<string>();
  const colors = generateDistinctColorPalette();
  const updateProfile = useCompanyStore((state) => state.updateCompanyProfile);

  const handleColorChange = (color: any) => {
    setSelectedColor(color.hex);
  };

  useEffect(() => {
    if (user.brandColor) {
      setSelectedColor(user.brandColor);
    }

    if (user.logoBase64) {
      setBase64Logo(user.logoBase64);
    }
  }, []);

  const handleImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files ? e.target.files[0] : null;
    if (selectedFile) {
      if (fileSizeInKB(selectedFile) > MAX_FILE_SIZE_KB) {
        setError(`File size should not exceed ${MAX_FILE_SIZE_KB} KB`);
        return;
      }
      const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
      if (!allowedTypes.includes(selectedFile.type)) {
        setError("Please upload a PNG, JPG, ");
        return;
      }
      const logoString: string = selectedFile
        ? ((await toBase64(selectedFile)) as string)
        : "";
      setBase64Logo(logoString);
      setError(undefined);
    }
  };

  const handleSave = async () => {
    if (!base64Logo && !selectedColor) {
      setError("Please upload a logo file");
      return;
    }
    try {
      setIsLoading(true);
      const response = await updateProfile({
        brandColor: selectedColor,
        brandLogo: base64Logo,
      });
      onClose();
    } catch (error) {
      setError("Error submitting the profile data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Programmatically trigger the file input click
    }
  };

  const renderErrorMessage = () => {
    if (error) {
      return <div className="text-red-500 mt-2">{error}</div>;
    }
    return null;
  };

  return (
    <div className="bg-gray-900 bg-opacity-30 fixed inset-0 z-50 flex items-center justify-center p-4 md:p-8">
      <div className="relative max-h-[calc(100vh-2rem)] w-[350px] md:w-[500px] p-4 md:p-6 bg-white rounded-2xl overflow-y-auto">
        <div className="text-[#212121]">
          <div>
            <h3 className="text-[18px] md:text-[20px] leading-6 font-semibold">
              Brand Identity
            </h3>
            <p className="mt-4 md:mt-8 font-medium text-[#475467]">
              Upload your logo and select a color that suits your{" "}
              <br className="hidden md:block" /> brand
            </p>

            <div className="mt-6 text-[16px] text-[#000000] font-medium">
              <label className="block text-[14px] md:text-[16px] leading-6 mb-2">
                Upload Brand Logo
              </label>
              <div className="relative flex flex-col items-center justify-center w-full h-24 md:h-28 bg-[#F9FAFB] border border-dashed border-[#D0D5DD] rounded-xl cursor-pointer">
                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  onChange={handleImageUpload}
                  ref={fileInputRef} // Assign ref to the input element
                  className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                />
                {base64Logo ? (
                  <>
                    <img
                      src={base64Logo}
                      alt="Uploaded logo preview"
                      className="w-full h-full object-contain rounded-xl"
                    />
                    <div
                      className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity"
                      onClick={handleEditClick} // Trigger file input click on overlay click
                    >
                      <span className="text-gray-500 font-semibold text-sm">
                        Click to Edit
                      </span>
                    </div>
                  </>
                ) : (
                  <span className="leading-6 text-gray-500">Upload image</span>
                )}
              </div>
              {base64Logo && (
                <div className="text-green-600 mt-2">
                  File uploaded successfully
                </div>
              )}
            </div>
          </div>
          {renderErrorMessage()}
          <div className="mt-6">
            <p className="text-[14px] md:text-[16px] font-medium leading-6 text-[#000000]">
              Choose a color that best fit the brand
            </p>
            <div className="my-3 h-9 w-9 relative">
              <input
                type="color"
                id="color-input"
                className={`block w-full h-full rounded-md relative ${
                  !selectedColor ? "bg-white" : ""
                }`}
                value={user.brandColor || selectedColor} // Default to white if no color is selected
                onChange={(e) => setSelectedColor(e.target.value)}
                style={{
                  backgroundColor: selectedColor || "transparent",
                  appearance: "none", // Hides default browser styling for better customization
                  position: "absolute",
                  top: 0,
                  left: 0,
                  opacity: selectedColor ? 1 : 0, // Hide input background if no color is selected
                }}
              />
              {!selectedColor && (
                <div
                  className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center pointer-events-none border-[0.5px] border-[#D0D5DD]"
                  style={{
                    zIndex: 1, // Ensure icon is above the input's transparent layer
                  }}
                >
                  <PlusIcon color="#292D32" />
                </div>
              )}
            </div>

            <div className="mt-[1em] hidden md:block">
              <CirclePicker
                onChange={handleColorChange}
                width="100%"
                circleSize={34}
                circleSpacing={20}
                colors={colors}
              />
            </div>
            <div className="mt-[1em] md:hidden">
              <CirclePicker
                onChange={handleColorChange}
                width="100%"
                circleSize={30}
                circleSpacing={14}
                colors={colors}
              />
            </div>
          </div>

          <div className="w-full gap-[5%] flex justify-between mt-8 md:mt-10">
            <button
              onClick={onClose}
              type="button"
              className={`text-[#044E97] border border-[#EAECF0] w-full h-12 rounded-md font-semibold`}
            >
              Cancel
            </button>
            <Button
              type="submit"
              onClick={handleSave}
              isLoading={isLoading}
              disabled={isLoading}
              variant={"secondary"}
              font="semibold"
              size="full"
            >
              {isLoading ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandIdentityModal;
