import * as React from "react";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import { BankData } from "../../../types";
import { useUserStore } from "../../../state";
import { MobileTable } from "../../common/mobileTable";
import { Statuses } from "../../../constants";
import { formatMoney } from "../../../utils";
import DocumentTable from "../invoice/invoiceDocumentTable";
import { useTranslation } from "react-i18next";
import { Table } from "../../common/Table";
import { ReusableTableColumn } from "../../../@types/table";
import { ReusableTable } from "../../table";
import { useCompanyStore } from "../../../state/useCompanyStore";

type MobileDataItem =
  | {
      name: any;
      quantity?: any;
      amount?: any;
    }
  | {
      name: any;
      duration?: any;
      price?: any;
    };
type GenericTableItem = {
  name: any;
  quantity: any;
  amount: any;
  duration?: any;
  price?: any;
};

interface InvoiceDocumentProps {
  type: "invoice" | "quote";
  document: any;
  address: string;
  bankData: BankData[];
  brandColor: string;
}

const InvoiceDocument: React.FC<InvoiceDocumentProps> = ({
  type,
  document,
  address,
  bankData,
  brandColor,
}) => {
  const [isLoading] = React.useState(false);
  const isRetailBusiness = useCompanyStore((state) => state.isRetailBusiness());
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const fetchUserProfile = useCompanyStore((state) => state.fetchCompanyProfile);
  const bankAccount = user.bankAccounts || [];
  const { t } = useTranslation("invoices");
  React.useEffect(() => {
    if (!user) {
      fetchUserProfile();
    }
  }, [user, fetchUserProfile]);

  if (!document) {
    navigate("/error", { state: { status: 500 } });
    throw new Error("Invoice not found");
  }

  let mobileData: MobileDataItem[] = [];
  let data: any[] = [];
  if (document && document.inventories && isRetailBusiness) {
    // Retail business, show inventories data
    data = document.inventories.flatMap((inv: any) => {
      if (Array.isArray(inv.variations) && inv.variations.length > 0) {
        return inv.variations.map((variation: any) => ({
          items: `${inv.name} - ${variation.type}`,
          quantity: variation?.quantity || 0,
          rate: variation?.price || "0",
          amount: formatMoney(
            `${
              parseInt(variation.price || "0") *
              parseInt(variation.quantity || "0")
            }`,
            document.currency
          ),
        }));
      }
      return {
        items: inv.name,
        quantity: inv?.quantity || 0,
        rate: inv?.rate || "0",
        amount: formatMoney(
          `${parseInt(inv.rate || "0") * parseInt(inv.quantity || "0")}`,
          document.currency
        ),
      };
    });
  } else if (document && document.services && !isRetailBusiness) {
    // Not a retail business, show services data
    data = document.services.map((ser: any) => ({
      services: ser.name,
      duration: ser?.duration || "N/A",
      serviceCharge: formatMoney(
        `${parseInt(ser.price || "0")}`,
        document.currency
      ),
    }));

    // Ensure inventories exist before calling flatMap
    if (document.inventories && Array.isArray(document.inventories)) {
      mobileData = document.inventories.flatMap((inv: any) => {
        if (Array.isArray(inv.variations) && inv.variations.length > 0) {
          return inv.variations.map((variation: any) => ({
            name: inv.name + " - " + variation.type,
            quantity: variation.quantity,
            amount: formatMoney(
              `${parseInt(variation.rate) * parseInt(variation.quantity)}`,
              document.currency
            ),
          }));
        } else {
          return [
            {
              name: inv.name,
              quantity: inv.quantity,
              amount: formatMoney(
                `${parseInt(inv.rate) * parseInt(inv.quantity)}`,
                document.currency
              ),
            },
          ];
        }
      });
    } else if (document && document.services && !isRetailBusiness) {
      data = document.services.map((ser: any) => ({
        services: ser.name,
        duration: ser?.duration || "N/A",
        serviceCharge: formatMoney(
          `${parseInt(ser.price || "0")}`,
          document.currency
        ),
      }));

      mobileData = document.services.map((ser: any, i: any) => ({
        name: ser.name,
        duration: ser?.duration,
        serviceCharge: formatMoney(`${parseInt(ser.price)}`, document.currency),
      }));
    }
  }

  const Servicecolumns: ReusableTableColumn[] = [
    {
      key: "services",
      label: "Services",
      className: "flex-1 text-left",
      bodyClassName: "text-[#344054] text-left",
      // width: 200,
    },
    {
      key: "duration",
      label: "Duration",
      className: "flex-1 text-center",
      bodyClassName: "text-[#344054] text-center",
      // width: 100,
    },
    {
      key: "serviceCharge",
      label: "Service Charge",
      className: "flex-1 text-center",
      bodyClassName: "text-[#344054] text-center",
      // width: 150,
    },
  ];

  const Invoicecolumns: ReusableTableColumn[] = [
    {
      key: "items",
      label: "Items",
      className: "flex-1 text-left",
      bodyClassName: "text-[#344054]",
    },
    {
      key: "quantity",
      label: "Quantity",
      className: "flex-1 text-center",
      bodyClassName: "text-[#344054] text-center",
    },
    {
      key: "rate",
      label: "Rate",
      className: "flex-1 text-center",
      bodyClassName: "text-[#344054] text-center",
    },
    {
      key: "amount",
      label: "Amount",
      className: "flex-1 text-center",
      bodyClassName: "text-[#344054] text-center",
    },
  ];

  const renderLineItemsMobileTableHead = () => {
    return {
      name: { title: "Item" },
      quantity: { title: "Qty" },
      amount: { title: "Amount" },
    };
  };

  const renderLineItemsMobileTableHeadService = () => {
    return {
      name: { title: "Service" },
      duration: { title: "Duration" },
      price: { title: "Charge" },
    };
  };

  const calculateSubtotalWithVariations = () => {
    const subtotalWithVariations = (document.inventories || []).flatMap(
      (inv: any) => {
        if (Array.isArray(inv.variations) && inv.variations.length > 0) {
          // Inventories with variations
          return inv.variations.map((variation: any) => ({
            amount: parseInt(variation.price) * parseInt(variation.quantity),
          }));
        } else {
          // Inventories without variations
          return {
            amount: parseInt(inv.rate) * parseInt(inv.quantity),
          };
        }
      }
    );

    return subtotalWithVariations.reduce(
      (prev: any, curr: { amount: any }) => prev + curr.amount,
      0
    );
  };

  const subTotalWithVariations = calculateSubtotalWithVariations();

  const subTotal =
    subTotalWithVariations +
    ([] as { amount: number; quantity: number; rate: number }[])
      .concat(
        (document.services || []).map((ser: any) => ({
          amount: ser.price,
          quantity: 0,
          rate: 0,
        }))
      )
      .reduce((prev, curr) => prev + curr.amount, 0);

  let discount =
    document.discount !== undefined ? subTotal * document.discount : 0;
  let tax = document.tax !== undefined ? subTotal * document.tax : 0;
  let total = subTotal - discount + tax;

  const minHeight = "850px";
  const tableHeight = `${data.length * 500}px`;
  const containerHeight = tableHeight > minHeight ? tableHeight : minHeight;
  const activeBankData = bankAccount.filter(
    (bankAccount: BankData) => bankAccount.isActive
  );

  return (
    <>
      {/* Mobile screens */}

      <div className="bg-white h-full block md:hidden w-[100%]  py-[1em] ">
        <div className="border border-gray-100 rounded-b-[12px] mt-[-2em]">
          <div className="flex justify-between  items-center ">
            <div className="">
              <p className="ml-[1em] text-[14px] font-semibold mt-2 ">
                {user.businessName}
              </p>
              {address ? (
                <>
                  <p className="w-[110px] text-[14px] ml-[1em] text-[#667085]">
                    {address}
                  </p>
                </>
              ) : (
                <>
                  <p className="text-[14px] ml-3 sm:text-[14px] text-[#667085]">
                    No Address Yet.
                  </p>

                  <Link
                    to="/settings"
                    className="text-red-500 ml-3 text-[60%] md:text-[80%] hover:underline"
                  >
                    {t("settings")}
                  </Link>
                </>
              )}
              <p className=" ml-[1em] text-[14px] text-[#667085] ">
                {user.phone}
              </p>
            </div>
            {type === "invoice" && (
              <div className=" text-left font-semibold mt-[-1.7em] pr-2">
                {document.status === Statuses.PAID ? (
                  ""
                ) : (
                  <>
                    <p className="text-[14px] sm:text-[14px] md:text-[14px] text-[#667085]">
                      {" "}
                      {t("balanceDue")}
                    </p>
                    <p className="text-[14px] sm:text-[14px] md:text-[14px] font-font-semibold  ">
                      {" "}
                      {formatMoney(document.balanceDue, document.currency)}
                    </p>
                  </>
                )}
              </div>
            )}
          </div>

          <hr className="my-5 mx-auto w-[100%]" />

          <div className="flex justify-between">
            <div className="ml-3">
              <p className="font-normal text-[14px] mb"> {t("billto")}</p>
              <div className="leading-5">
                <p className="mb-2 font-semibold text-[#000000] not-italic text-[14px] leading-6 tracking-wide md:text-[13px] sm:text-[14px]">
                  {document.customer &&
                    `${document.customer.firstName} ${document.customer.lastName}.`}
                </p>
                <p className="w-[150px] text-[14px] font-normal">
                  {document.customer?.address}
                </p>
              </div>
            </div>

            <div className="flex flex-col mr-3 gap-4">
              <div className="flex flex-col text-right">
                <p className="font-medium  not-italic  text-[14px] leading-6 tracking-wide sm:text-[70%]  md:text-[80%] text-[#667085]">
                  {t("invoiceDate")}
                </p>
                <p className="font-semibold text-[#000000] not-italic  text-[14px] leading-6 tracking-wide sm:text-[70%] md:text-[80%]">
                  {dayjs(document.createdAt).format("DD MMMM, YYYY")}
                </p>
              </div>
              <div className="flex flex-col text-right mt-[-1em]">
                <p className="font-medium not-italic  text-[14px] leading-6 tracking-wide sm:text-[70%] md:text-[80%] text-[#667085]">
                  {t("dueDate")}:
                </p>
                <p className="font-semibold text-[#000000] not-italic  text-[14px] leading-6 tracking-wide sm:text-[70%] md:text-[80%]">
                  {dayjs(document.invoiceDueAt).format("DD MMMM, YYYY")}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 ">
            <div className="bg-white h-full block md:hidden w-[100%] py-[1em]">
              <div className="border border-gray-100 mt-[-2em] ">
                <div className="flex flex-col gap-3 px-4 mb-4 mt-1">
                  {isRetailBusiness
                    ? // Inventory Data for Retail Business
                      data.map((row, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <div className="flex flex-col text-left text-[14px] text-black font-medium mt-1">
                              {row.items}
                              <div className="flex-1 text-center text-[14px] text-gray-600">
                                {`${row.quantity} x ${formatMoney(
                                  row.rate || "0",
                                  document.currency
                                )}`}
                              </div>
                            </div>
                            <div className="flex-1 text-right text-[14px] text-black font-semibold">
                              {row.amount}
                            </div>
                          </div>
                        );
                      })
                    : // Service Data for Non-Retail Business
                      data.map((row, index) => {
                        return (
                          <div
                            key={index}
                            className="flex justify-between items-center pb-3"
                          >
                            <div className="flex-1 text-left text-[14px] text-black font-medium">
                              {row.services}
                            </div>

                            <div className="flex-1 text-center text-[14px] text-gray-600">
                              {row.duration}
                            </div>

                            <div className="flex-1 text-right text-[14px] text-black font-semibold">
                              {row.serviceCharge}
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[20px] ">
            <div className="">
              <div className="text-left mr-[]">
                <div className="flex justify-between 540:gap-[17em] text-[14px] px-[1em] mb-3">
                  <p className="font-medium text-left text-[#000000] not-italic  text-[14px] leading-6 tracking-wide  sm:text-[11px] ">
                    {t("subTotal")}:
                  </p>
                  <p className="font-semibold">
                    {formatMoney(subTotal, document.currency)}
                  </p>
                </div>
                <div className="flex justify-between 540:gap-[19em] text-[14px] px-[1em] mb-3">
                  <p className="font-medium  text-left text-[#000000] not-italic  text-[14px] leading-6 tracking-wide  sm:text-[14px]">
                    {t("discount")}:
                  </p>
                  <p className="font-semibold">
                    {formatMoney(discount, document.currency)}
                  </p>
                </div>
                {type === "invoice" && (
                  <div className="flex justify-between 540:gap-[20em] text-[14px] px-[1em] mb-3">
                    <p className="font-medium text-left text-[#000000] not-italic  text-[14px] leading-6 tracking-wide  sm:text-[14px]">
                      {t("tax")}:
                    </p>
                    <p className="font-semibold">
                      {formatMoney(tax, document.currency)}
                    </p>
                  </div>
                )}
                <hr className="w-[100%]" />
                <div className="flex justify-between  540:gap-[17em] px-[1em] mt-[20px] font-semibold text-[14px] mb-3  ">
                  <p className="text-[#000000] text-[14px] ">Total</p>
                  <p className="text-[#000000] font-semibold">
                    {formatMoney(total, document.currency)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-white text-[14px] mt-[2em] rounded-md ">
          {activeBankData && activeBankData.length > 0 ? (
            <>
              {activeBankData.map((bank: BankData, index: number) => (
                <p
                  className="max-w-[650px] my-3 flex flex-col bg-[#F2F4F7] py-2 px-2 rounded-md "
                  key={index}
                >
                  <span className="px-2">{bank.accountNumber} </span>
                  <span className="px-2">
                    {bank.accountName} | {bank.bankName}
                  </span>
                </p>
              ))}
            </>
          ) : (
            <span className="px-[1em] py-[1em]">
              {t("bankSettings")}&nbsp;
              <Link
                to="/settings?tab=bankAccount"
                className="text-red-500 hover:underline"
              >
                {t("bankSettings1")}
              </Link>
              &nbsp;{t("bankSettings3")}
            </span>
          )}
        </div>
      </div>

      {/* Desktop screens */}
      <div
        className="mx-auto h-full bg-white  hidden px-[2em]  md:block border border-gray-100 rounded-[40px] mt-[3em] mb-0"
        id="document-document"
      >
        <div className=" w-full" style={{ height: containerHeight }}>
          <div className="">
            <div className="flex justify-between items-center">
              <div className="mt-[3em]">
                <div style={{ width: "90px", height: "60px" }}>
                  {user && (user.logoBase64 || user.logoUrl) && (
                    <img
                      src={user.logoBase64 || user.logoUrl}
                      alt="Logo"
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                  {!user ||
                    (!user.logoBase64 && !user.logoUrl && (
                      <p className="font-semibold whitespace-nowrap text-[18px]">
                        {user.businessName}
                      </p>
                    ))}
                </div>
                {address ? (
                  <>
                    <p className="w-[110px] text-[18px] mt-[] lg:whitespace-nowrap ">
                      {address}
                    </p>
                  </>
                ) : (
                  <>
                    <p>No Address Yet.</p>
                    <Link
                      to="/settings"
                      className="text-red-500 hover:underline"
                    >
                      {t("settings")}
                    </Link>
                  </>
                )}
                <p className=" text-[18px]">{user.phone}</p>
              </div>
              {type === "invoice" && (
                <div className="text-right font-semibold mt-[2em] ">
                  <span className="text-[30px] font-semibold">
                    {t("singleInvoice")}
                  </span>
                  <p className="text-[20px]">{document.invoiceNumber}</p>
                  {document.status === Statuses.PAID.toLowerCase() ? (
                    ""
                  ) : (
                    <>
                      <p className="text-[20px] text-[#667085]">
                        {" "}
                        {t("balanceDue")}
                      </p>
                      <p className="text-[24px]  ">
                        {" "}
                        {formatMoney(document.balanceDue, document.currency)}
                      </p>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="mt-10">
            <div>
              <p className="font-semibold mb-4 text-[18px]"> {t("billto")}</p>
            </div>
            <div className="flex justify-between ">
              <div className="leading-5 text-[18px] font-medium text-[#344054]">
                {document.customer &&
                  `${document.customer.firstName} ${document.customer.lastName}.`}
                <p className="w-[150px]">
                  {document.customer && document.customer?.address}
                </p>
              </div>

              <div className="text-[18px] mt-[-1em] ">
                <div className="flex justify-end  gap-5 pb-[14px]">
                  <p className="text-right font-medium text-[#667085]">
                    {t("invoiceDate")}:
                  </p>
                  <p className="text-right font-semibold">
                    {dayjs(document.issueDate).format("DD MMMM, YYYY")}
                  </p>
                </div>
                {type === "invoice" && (
                  <div className="flex justify-end  gap-5 ">
                    <p className="font-medium text-right  text-[#667085]">
                      {t("dueDate")}:
                    </p>
                    <p className="text-right font-semibold">
                      {dayjs(document.invoiceDueAt).format("DD MMMM, YYYY")}
                    </p>
                  </div>
                )}
                {type === "quote" && (
                  <div className="flex justify-end  gap-5">
                    <p className="font-medium text-right  text-[#667085]">
                      {t("expirydate")}:
                    </p>
                    <p className="text-right font-semibold">
                      {dayjs(document.expiryDate).format("DD MMMM, YYYY")}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="mt-10 h-"></div>

          <div className="z-0 w-[100%] mt-[-1em]" style={{ overflowX: "auto" }}>
            {isRetailBusiness
              ? data && (
                  <ReusableTable
                    columns={Invoicecolumns}
                    data={data}
                    isLoading={isLoading}
                  />
                )
              : data && (
                  <ReusableTable
                    columns={Servicecolumns}
                    data={data}
                    isLoading={isLoading}
                  />
                )}
          </div>

          {type === "invoice" && (
            <div className="mt-[40px]  ">
              <div className="flex justify-between">
                <div>
                  <p className="text-[16px] font-medium bg-[#F2F4F7] mb-2 py-2 pl-2 pr-[7em]">
                    {" "}
                    {t("paymentDetails")}
                  </p>
                  <div className="text-[16px] flex flex-col">
                    {activeBankData && activeBankData.length > 0 ? (
                      <>
                        {activeBankData.map((bank: BankData, index: number) => (
                          <div
                            key={index}
                            className="flex flex-col p-4 mb-4 bg-[#F2F4F7] rounded-lg"
                          >
                            <span className="text-gray-600">
                              {bank.bankName}
                            </span>
                            <span className="text-gray-600">
                              {bank.accountNumber}
                            </span>
                            <span className="text-gray-600">
                              {bank.accountName}
                            </span>
                          </div>
                        ))}
                      </>
                    ) : (
                      <span
                        className="px-[1em] py-[3em]"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {t("bankSettings")}&nbsp;
                        <Link
                          to="/settings?tab=bankAccount"
                          className="text-red-500 hover:underline"
                        >
                          {t("bankSettings1")}
                        </Link>
                        &nbsp;{t("bankSettings3")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="float-right md:ml-0 lg:ml-0 ">
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em]">
                    <p>{t("subTotal")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(subTotal, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em] mt-2">
                    <p>{t("discount")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(discount, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em] mt-2">
                    <p>{t("tax")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(tax, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] text-[white] bg-[#0D3C6D] px-[2em] font-semibold lg:text-[16px] md:text-[14px] py-3 mt-4">
                    <p>{t("Total amount")}</p>
                    <p>{formatMoney(total, document.currency)}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {type === "quote" && (
            <div className="mt-[40px]  ">
              <div className="flex justify-between">
                <div>
                  <p className="text-[16px] font-medium bg-[#F2F4F7] mb-2 py-2 pl-2 pr-[7em]">
                    {" "}
                    {t("paymentDetails")}
                  </p>
                  <div className="text-[16px] flex flex-col">
                    {activeBankData && activeBankData.length > 0 ? (
                      <>
                        {activeBankData.map((bank: BankData, index: number) => (
                          <div
                            key={index}
                            className={`flex flex-col p-4 mb-4 bg-[#F2F4F7] rounded-lg  ${
                              index % 2 === 0 ? "bg-[#F2F4F7]" : "bg-[#F9FAFB]"
                            }`}
                          >
                            <span className="text-gray-600">
                              {bank.bankName}
                            </span>
                            <span className="text-gray-600">
                              {bank.accountNumber}
                            </span>
                            <span className="text-gray-600">
                              {bank.accountName}
                            </span>
                          </div>
                        ))}
                      </>
                    ) : (
                      <span
                        className="px-[1em] py-[3em]"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {t("bankSettings")}&nbsp;
                        <Link
                          to="/settings?tab=bankAccount"
                          className="text-red-500 hover:underline"
                        >
                          {t("bankSettings1")}
                        </Link>
                        &nbsp;{t("bankSettings3")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="float-right md:ml-0 lg:ml-0 ">
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em]">
                    <p>{t("subTotal")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(subTotal, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em] mt-2">
                    <p>{t("discount")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(discount, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] mb-4 lg:text-[16px] md:text-[14px] text-[#475467] px-[2em] mt-2">
                    <p>{t("tax")}</p>
                    <p className="text-right text-[black] text-[16px] font-semibold">
                      {formatMoney(tax, document.currency)}
                    </p>
                  </div>
                  <div className="flex justify-between gap-[6em] text-[white] bg-[#0D3C6D] px-[2em] font-semibold lg:text-[16px] md:text-[14px] py-3 mt-4">
                    <p>{t("Total amount")}</p>
                    <p>{formatMoney(total, document.currency)}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { InvoiceDocument };
