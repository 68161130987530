import React, { createContext, useState, useEffect, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { CognitoUser } from "amazon-cognito-identity-js";

interface UserContextType {
  user: CognitoUser | null;
  setCognitoUser: (user: CognitoUser | null) => void;
}

export const UserContext = createContext<UserContextType | undefined>(
  undefined
);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setCognitoUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    if (user) {
      Sentry.withScope((scope) => {
        scope.setUser({
          id: user.getUsername(),
          email: (user as any)?.attributes?.email || "Unknown",
        });
      });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setCognitoUser }}>
      {children}
    </UserContext.Provider>
  );
};
