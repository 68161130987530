import React, { useEffect, useState } from "react";
import { SearchInput, SidebarDrawer } from "../../common";
import { AddSquareIcon, CloseIcon, SearchIcon } from "../../../assets/icons";
import { CreateUser } from "./create/CreateUser";
import { EditUser } from "./edit/EditUser";
import { DisablePrompt } from "./DisablePrompt";
import { DeleteUser } from "./DeleteUser";
import { UsersTable } from "./list/UsersTable";
import { UserDetail } from "../../../types";
import { EnablePrompt } from "./EnablePrompt";
import { useSubUserStore } from "../../../state/useSubUserStore";
import ThreeDot from "../../../assets/icons/ThreeDot";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { checkSubscription } from "../../../utils/subscription";
import { servicesType } from "../../../constants/Subscription";
import { SubscriptionModal } from "../../common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../common/Subscription/MobileSubscriptionModal";
import DesktopSubscriptionModal from "../../common/Subscription/DesktopSubscriptionModal";
import DeleteUserIcon from "../../../assets/icons/deleteUser";
import { EnableUserIcon } from "../../../assets/icons/enableUser";
import EditUserIcon from "../../../assets/icons/editUser";
import RectangleModal from "../../common/rectangleModal";
import PlusSignIcon from "../../../assets/icons/PlussignIcon";
import { useUserSearch } from "../../../hooks/users/useUserSearch";
import { getRoleStyle } from "../../../utils/getRoleBadge";
import PageLoader from "../../common/PageLoader";

const UserManagement = () => {
  const { t } = useTranslation("user");
  const navigate = useNavigate();

  const [text, setText] = React.useState("");
  const [isSideBarOpen, setIsSidebarOpen] = React.useState<boolean>(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [disable, setDisable] = React.useState<boolean>(false);
  const [enable, setEnable] = React.useState<boolean>(false);
  const [deleteUser, setDeleteUser] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<string>("");
  const [openDotmenuId, setOpenDotmenuId] = useState<string | null>(null);
  const [openDotmenu, setOpenDotmenu] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const isMobile = window.innerWidth < 768;

  const handleUserClick = () => {
    const subscriptionCheck = checkSubscription(servicesType.Users);
    if (subscriptionCheck.status) {
      setIsSidebarOpen(true);
    } else {
      setIsSidebarOpen(false);
      setShowSubscriptionModal(true);
      setSubscriptionErrorMessage(subscriptionCheck.message || "");
    }
  };

  const handleDropdown = (id: string) => {
    setOpenDotmenu(true);
    setOpenDotmenuId(openDotmenuId === id ? null : id);
  };

  const handleEdit = (id: string) => {
    setOpenDotmenu(false);
    setIsEdit(true);
    setUserId(id);
  };

  const handleDisable = (id: string) => {
    setOpenDotmenu(false);
    setDisable(true);
    setUserId(id);
  };
  const handleEnable = (id: string) => {
    setOpenDotmenu(false);
    setEnable(true);
    setUserId(id);
  };

  const handleDelete = (id: string) => {
    setOpenDotmenu(false);
    setDeleteUser(true);
    setUserId(id);
  };

  const {
    fetchUsers,
    users: allUsers,
    fetchMoreData,
    pagination,
    isLoading,
  } = useSubUserStore((state) => ({
    fetchUsers: state.fetchUsers,
    fetchMoreData: state.fetchMoreData,
    users: state.users,
    pagination: state.pagination,
    isLoading: state.isLoading,
  }));

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const {
    data: searchedUsers,
    isSearching,
    handleSearch: handleUserSearch,
  } = useUserSearch();

  if (isLoading) {
    return (
      <div>
        <PageLoader />
      </div>
    );
  }
  // Decide which users to display based on whether a search is active
  const displayedUsers = isSearching ? searchedUsers : allUsers;

  const userData = (users: UserDetail[]) => {
    return users?.map((user: any) => {
      return {
        id: user.id,
        user: `${user.firstname} ${user.lastname}`,
        email: user.email,
        role: (
          <span
            className={`px-2 py-1 text-[12px] lg:text-[14px] rounded font-medium ${getRoleStyle(
              user.role
            )}`}
          >
            {user.role}
          </span>
        ),
        userStatus: user.status,
        status: (
          <span
            className={`px-2 py-1 text-[12px] lg:text-[14px] rounded border border-gray-200 font-medium ${
              user.status === undefined || null
                ? "bg-[#B5CDB4] text-[#12B76A]"
                : user.status === "ENABLED"
                ? "text-[#088306]"
                : "text-[#FB0303]"
            }  `}
          >
            {user.status === undefined || null
              ? "Active"
              : user.status === "ENABLED"
              ? "Active"
              : "Disabled"}
          </span>
        ),
        action: (
          <div className="flex items-center justify-center">
            <div className="relative">
              <div
                className="cursor-pointer"
                onClick={() => handleDropdown(user.id)}
              >
                <ThreeDot />
              </div>
              {openDotmenu && openDotmenuId === user.id && (
                <div className="absolute z-50 shadow-lg rounded-md right-[-0em] md:right-[-2.5em] top-4 md:top-6 right- w-[180px] bg-white text-[11px] md:text-[14px] text-[#4E4E4E] ">
                  <div
                    className="flex justify-start pl-3"
                    onClick={() => handleEdit(user.id)}
                  >
                    <span className="mt-[0.8em] pr-2">
                      <EditUserIcon />
                    </span>
                    <p
                      className="cursor-pointer lg:py-3 py-2 text-center text-[16px]"
                      onClick={() => handleEdit(user.id)}
                    >
                      Edit User
                    </p>
                  </div>
                  {user.status === "ENABLED" && (
                    <div
                      className="flex items-center justify-start pl-3 cursor-pointer lg:py-3 pb-2 text-center border-[#A4A4A4] text-[16px]"
                      onClick={() => handleDisable(user.id)}
                    >
                      <EnableUserIcon />
                      <span className="ml-2">Disable</span>
                    </div>
                  )}
                  {user.status === "DISABLED" && (
                    <div
                      className="flex items-center justify-start pl-3 cursor-pointer lg:py-3 pb-2 text-center border-[#A4A4A4] text-[16px]"
                      onClick={() => handleEnable(user.id)}
                    >
                      <EnableUserIcon />
                      <span className="ml-2">Enable</span>
                    </div>
                  )}
                  <div
                    className="flex justify-start pl-3"
                    onClick={() => handleDelete(user.id)}
                  >
                    <span className="lg:mt-[0.8em] mt-[0.2em] pr-2">
                      <DeleteUserIcon />
                    </span>
                    <p
                      className="cursor-pointer lg:py-3 pb-2 text-[#FB0303] text-center text-[16px]"
                      onClick={() => handleDelete(user.id)}
                    >
                      Delete
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ),
      };
    });
  };

  return (
    <>
      <div className="md:px-[24px] pt-[20px] md:pt-[32px] lg:mt-[] ">
        <div className="flex items-center justify-between px-[20px] md:px-0">
          <div className=""></div>
        </div>
        <div className="mx-[20px] md:mx-0 md:gap-[24px] mt-[24px] md:flex  lg:flex justify-between">
          <div className="relative w-[] lg:block">
            <SearchInput
              dropdownWidth="w-full xl:w-[300px] lg:w-[290px]"
              placeholder="Search by user’s name"
              displayKey="name"
              icon={<SearchIcon />}
              isLoading={isSearching}
              value={text}
              clearIcon={CloseIcon}
              data={
                isSearching
                  ? displayedUsers.map((user) => ({
                      ...user,
                      name: `${user.firstname || user.firstName} ${
                        user.lastname || user.lastName
                      }`,
                    }))
                  : []
              }
              handleChangeText={handleUserSearch}
              searchKey={"name"}
              emptyMessage={"This Item is not found"}
              dropdownStyle={{
                maxHeight: "800px",
                overflowY: "auto",
              }}
              classNames="w-full block p-4 pl-10 xl:w-[300px] lg:w-[290px] md:mt-[0em] lg:ml-[0em] !xl:ml-[0em] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] h-[39.77px] rounded-lg p-2 mt-[5px] focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
            />
          </div>
          <div
            onClick={handleUserClick}
            className=" hidden md:flex items-center text-[white] bg-[#044E97] gap-1 font-semibold text-[16px] rounded-[4px] border border-[#0A0A0A1A] px-[16.5px] h-[48px] cursor-pointer z-0"
          >
            <PlusSignIcon />
            <p className="">{t("user.addNewUser")}</p>
          </div>
        </div>

        <div className="relative">
          <div className="overflow-auto relative  z-1">
            <UsersTable
              handleDisable={handleDisable}
              handleEnable={handleEnable}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              data={userData(allUsers)}
              isLoading={isLoading}
              meta={pagination}
              fetchMoreData={fetchMoreData}
            />
          </div>

          <div
            onClick={handleUserClick}
            className="sticky bottom-5 flex justify-center items-center bg-[#044E97] text-white gap-2 font-semibold text-[16px] rounded-md px-4 py-3 w-full z-10 md:hidden cursor-pointer"
          >
            <p>{t("user.addNewUser")}</p>
          </div>
        </div>

        {disable && (
          <RectangleModal
            isOpen={disable}
            onCancel={() => setDisable(false)}
            title={"Disable User"}
          >
            <DisablePrompt userId={userId} setShowPrompt={setDisable} />
          </RectangleModal>
        )}
        {enable && (
          <RectangleModal
            isOpen={enable}
            onCancel={() => setEnable(false)}
            title={"Enable User"}
          >
            <EnablePrompt userId={userId} setShowPrompt={setEnable} />
          </RectangleModal>
        )}

        {deleteUser && (
          <RectangleModal
            isOpen={deleteUser}
            onCancel={() => setDeleteUser(false)}
            title={"Delete User"}
          >
            <DeleteUser userId={userId} setShowPrompt={setDeleteUser} />
          </RectangleModal>
        )}

        {isSideBarOpen && (
          <CreateUser
            onClose={() => setIsSidebarOpen(false)}
            show={isSideBarOpen}
          />
        )}

        {isEdit && (
          <EditUser
            userId={userId}
            onClose={() => setIsEdit(false)}
            show={isEdit}
          />
        )}
        <div className="mt-[-30em]">
          {showSubscriptionModal && (
            <SubscriptionModal>
              <MobileSubscriptionModal
                onClose={() => setShowSubscriptionModal(false)}
                titleText={`Need to access more features?`}
                subtitleText={subscriptionErrorMessage}
              />
            </SubscriptionModal>
          )}
        </div>
      </div>
    </>
  );
};

export default UserManagement;
