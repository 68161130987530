import React, { useEffect, useState } from "react";
import { BankData } from "../../../types";
import { GroupButton } from "../../common";
import EditIcon from "../../../assets/icons/EditIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { EditBankAccount } from "./edit";
import { BankAccountDeletePrompt } from "./delete";
import PageLoader from "../../common/PageLoader";
import InfoIcon from "../../../assets/icons/InfoIcon";
import ArrowLeft from "../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../assets/icons/ArrowRight";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import { useTranslation } from "react-i18next";

interface ViewProps {
  bankAccount: BankData;
  onToggleActive: () => void;
  onTogglePayout: () => void;
  onEdit: () => void;
  accountIndex: number;
}

export const BankAccountView: React.FC<ViewProps> = ({
  bankAccount,
  onToggleActive,
  accountIndex,
  onEdit,
  onTogglePayout,
}) => {
  const { t } = useTranslation("settings");
  const [showDeletePrompt, setShowDeletePrompt] = useState(false);
  const [isActive, setIsActive] = useState(bankAccount.isActive);
  const [isPayoutAccount, setIsPayoutAccount] = useState(
    bankAccount.isPayoutAccount
  );
  const [bankAccountState, setBankAccountState] =
    useState<BankData>(bankAccount);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  useEffect(() => {
    setIsActive(bankAccount.isActive);
    setIsPayoutAccount(bankAccount.isPayoutAccount);
    setBankAccountState(bankAccount);
  }, [bankAccount]);

  useEffect(() => {
    if (deleteSuccess) {
      const timer = setTimeout(() => {
        setDeleteSuccess(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [deleteSuccess]);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleOpenDeletePrompt = () => {
    setShowDeletePrompt(true);
  };
  const handleCloseDeletePrompt = () => {
    setShowDeletePrompt(false);
  };

  const handleToggleActive = () => {
    onToggleActive();
    setIsActive(!isActive);
  };

  const handleTogglePayout = () => {
    onTogglePayout();
    setIsPayoutAccount(!isPayoutAccount);
  };

  return (
    <>
      <div className="max-w-[580px] bg-[#ECF2FF] p-[14px] md:p-5 border border-[#044E9733] border-opacity-20 rounded text-[#000000]">
        <div className="flex justify-between items-end">
          <div className="">
            <h3 className="text-[13px] md:text-[16px] leading-5">Bank Name:</h3>
            <p className="font-semibold md:text-[16px] text-[14px] leading-5 mt-2">
              {bankAccountState.bankName}
            </p>
          </div>
          <div className="text-[#044E97] text-[20px] md:text-[17px] lg:text-[14+px] font-medium mt-[-2rem] ">
            <GroupButton
              buttons={[
                {
                  text: "",
                  icon: <EditIcon />,
                  onClick: onEdit,
                  fontSize: "!px-2",
                },
                {
                  text: "",
                  icon: <DeleteIcon />,
                  onClick: handleOpenDeletePrompt,
                  fontSize: "!px-2",
                },
              ]}
            />
          </div>
        </div>
        <div className="flex gap-[11.2%] mt-7">
          <div className="">
            <h3 className="text-[13px] md:text-[16px] leading-5">
              Account Number:
            </h3>
            <p className="font-semibold md:text-[16px] text-[14px] leading-5 mt-[6px]">
              {bankAccountState.accountNumber}
            </p>
          </div>
          <div className="">
            <h3 className="text-[13px] md:text-[16px] leading-5">
              Account Name:
            </h3>
            <span className="font-semibold md:text-[16px] leading-5 mt-[6px] text-[14px]">
              {bankAccountState.accountName}
            </span>
          </div>
        </div>

        <div className="bg-[#F3FBFB] p-3 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-4 flex 320:text-[12px] 360:text-[13px] 390:text-[14px] md:text-[16px] items-center justify-between">
          <div className="flex gap-2 items-center">
            <span>{`${
              isActive
                ? "Active Bank Account"
                : `${t("Settings.inactivebankaccount")}`
            }`}</span>
            <div
              className="relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <InfoIcon color="#404040" />
              {isHovered && (
                <div className="absolute w-[204px] left-[-5.7em]">
                  <div className="flex items-center justify-center">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                    </svg>
                  </div>
                  <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                    Inactive bank accounts will not reflect in your invoice.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={`w-16 h-8 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
              isActive
                ? "bg-[#044E97] justify-end"
                : "bg-[#ffffff] justify-start"
            }`}
          >
            <div
              onClick={handleToggleActive}
              className={`${
                isActive ? "bg-[#ffffff]" : "bg-[#044E97]"
              } cursor-pointer w-6 h-6 rounded-full flex justify-center items-center`}
            >
              {isActive ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </div>
        </div>

        <div className="bg-[#F3FBFB] p-3 rounded-[8px] border border-[#044E9733] border-opacity-10 mt-4 flex 320:text-[12px] 360:text-[13px] 390:text-[14px] md:text-[16px] items-center justify-between">
          <div className="flex gap-2 items-center">
            <span>{`${
              isPayoutAccount
                ? "Payout Account"
                : "Use this account for Paystack verification"
            }`}</span>
            <div
              className="relative"
              onMouseEnter={handleMouseEnter2}
              onMouseLeave={handleMouseLeave2}
            >
              <InfoIcon color="#404040" />
              {isHovered2 && (
                <div className="absolute w-[204px] left-[-5.7em]">
                  <div className="flex items-center justify-center">
                    <svg
                      width="18"
                      height="15"
                      viewBox="0 0 18 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                    </svg>
                  </div>
                  <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                    All payment from your customers will be sent to this
                    account.
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className={`w-16 h-8 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-in ${
              isPayoutAccount
                ? "bg-[#044E97] justify-end"
                : "bg-[#ffffff] justify-start"
            }`}
          >
            <div
              onClick={handleTogglePayout}
              className={`${
                isPayoutAccount ? "bg-[#ffffff]" : "bg-[#044E97]"
              } cursor-pointer w-6 h-6 rounded-full flex justify-center items-center`}
            >
              {isPayoutAccount ? <ArrowLeft /> : <ArrowRight />}
            </div>
          </div>
        </div>
      </div>
      {deleteSuccess && (
        <SuccessBanner message={"Account deleted successfully"} />
      )}

      <BankAccountDeletePrompt
        setDeleteSuccess={setDeleteSuccess}
        bankName={bankAccountState.bankName}
        accountNumber={bankAccountState.accountNumber}
        showPrompt={showDeletePrompt}
        bankAccountId={accountIndex}
        onClose={handleCloseDeletePrompt}
      />
    </>
  );
};
