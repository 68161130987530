import React, { useEffect } from "react";
import { IExpense, PreviewExpense, TransactionType } from "../../@types";
import useNotifications from "../../hooks/UseNotifications";
import { CloseIcon, PDFIcon } from "../../assets/icons";
import { shortenFileName } from "../../utils";
import { Button } from "../common";
import ExpenseUploadSuccess from "../payments/RecordPayment/ExpenseUploadSuccess";
import InfoIcon from "../../assets/icons/InfoIcon";
import FileUploadComponent from "./FileUploadComponent";
import useUploadExpense from "../../hooks/expenses/useUploadExpense";
import ExpensePreviewSuccess from "../payments/RecordPayment/ExpensePreviewSuccess";
import { useTranslation } from "react-i18next";
import cx from "classnames";

interface UploadExpenseProps {
  entity?: IExpense;
  onRecordEntity: (data: any) => void;
  paymentType: TransactionType;
  closeModal: () => void;
  preview: boolean;
  setPreview: (preview: boolean) => void;
  setExpenseData: (expense: PreviewExpense) => void;
  setShowPopup: (modal: boolean) => void;
}

const UploadExpense: React.FC<UploadExpenseProps> = ({
  entity,
  onRecordEntity,
  paymentType,
  closeModal,
  setPreview,
  preview,
  setExpenseData,
  setShowPopup,
}) => {
  const { t } = useTranslation("expenses");
  const [triggerAction, setTriggerAction] = React.useState<boolean>(false);
  useNotifications(triggerAction);
  const {
    file,
    error,
    isLoading,
    isPreviewing,
    success,
    previewSuccess,
    setFile,
    handleUpload,
    handleAutoSave,
    handlePreviewAndSave,
  } = useUploadExpense({
    entity,
    onRecordEntity,
    paymentType,
    setPreview,
    preview,
    setExpenseData,
    setShowPopup,
  });

  useEffect(() => {
    return () => {
      setTriggerAction(false);
    };
  }, [handleAutoSave]);

  const handleCloseModal = () => {
    closeModal();
  };

  if (previewSuccess) {
    return <ExpensePreviewSuccess isLoading={isPreviewing} />;
  }

  if (success) {
    return (
      <ExpenseUploadSuccess
        closeModal={handleCloseModal}
        text={"Go back to Expense"}
        navigateTo={"expense"}
      />
    );
  }

  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-end md:items-center justify-center">
        <div
          className={cx(
            "overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[538px] p-6 bg-white",
            "rounded-t-[16px] rounded-b-[16px] shadow-md"
          )}
        >
          <div className="px-5">
            <div className="flex items-center justify-between">
              <p className="text-[24px] font-semibold">
                {t("budget.uploadExpense")}
              </p>

              <div
                className="w-11 h-11 flex justify-center items-center  cursor-pointer"
                onClick={closeModal}
              >
                <CloseIcon />
              </div>
            </div>
            {error ? (
              <div className="text-center p-2 bg-red-300 text-red-700 font-medium mb-4">
                {error}
              </div>
            ) : null}

            <div className="flex justify-center mt-[24px] bg-[#F9FAFB] w-full h-[120px] text-center">
              <FileUploadComponent onUpload={handleUpload} />
            </div>

            {file && (
              <div className="flex items-center justify-between text-lg mt-3">
                <div className="flex justify-start">
                  <PDFIcon />
                  <span className="text-[#0500F9] mx-3 font-medium">
                    {shortenFileName(file)}
                  </span>
                </div>
                <a href="#close" onClick={() => setFile(undefined)}>
                  <CloseIcon color="red" />
                </a>
              </div>
            )}
            <div className="text-[#475467] text-[14px] font-medium whitespace-normal mt-2">
              Click 'Auto save' and our Esemie will extract the information and
              record it in your payment history or Click 'Preview & save' to
              review, edit and manually save the receipt.
            </div>

            <div className="flex items-center gap-4 mt-12">
              <Button
                onClick={handlePreviewAndSave}
                variant="secondary"
                outline={true}
                text={"Preview & Save"}
                isLoading={isPreviewing}
                disabled={isPreviewing}
                font="semibold"
              />
              <Button
                onClick={handleAutoSave}
                variant="secondary"
                text={"Auto Save"}
                isLoading={isLoading}
                disabled={isLoading}
                font="semibold"
                outline={true}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadExpense;
