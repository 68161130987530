import React from "react";

const BannerIcon = () => {
  return (
    <div>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_52490_73382)">
          <path
            d="M18.3346 10.0007C18.3346 5.39828 14.6037 1.66732 10.0013 1.66732C5.39893 1.66732 1.66797 5.39828 1.66797 10.0007C1.66797 14.603 5.39893 18.334 10.0013 18.334C14.6037 18.334 18.3346 14.603 18.3346 10.0007Z"
            stroke="#084784"
            stroke-width="1.5"
          />
          <path
            d="M6.66797 10.6253C6.66797 10.6253 8.0013 11.3858 8.66797 12.5003C8.66797 12.5003 10.668 8.12533 13.3346 6.66699"
            stroke="#084784"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_52490_73382">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default BannerIcon;
