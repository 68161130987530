import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useInvoiceStore } from "../../../state";
import { useInvoiceSearch } from "../../../hooks";
import ExpenseEmptyIcon from "../../../assets/icons/InvCusExpEmptyIcon";
import EmptyPage from "../../../components/emptyPage/EmptyPage";
import { useInvoiceStatsData } from "../../../hooks/Stats/invoice";
import { checkSubscription } from "../../../utils/subscription";
import { servicesType } from "../../../constants/Subscription";
import ActionModal from "../../../components/common/ActionModal";
import UploadIcon from "../../../assets/icons/svgicons/UploadIcon.svg";
import { SubscriptionModal } from "../../../components/common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../../components/common/Subscription/MobileSubscriptionModal";
import { defaultShortcuts } from "../../../constants/helpServices";
import DatePickerWithShortcuts from "../../../components/DatePicker";
import { ICustomer } from "../../../@types";
import { useTranslation } from "react-i18next";
import useRetailBusinessStatus from "../../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../../../components/Sales/invoice/invoiceCreationModal";
import { ListInfoCardProps } from "../../../components/list-page/desktop/ListInfoCard";
import ReusableListPage from "../../../components/list-page";
import { columns, renderInvoicesData } from "../../../utils/invoice-list";
import {
  invoiceCategories,
  mobileInvoiceCategories,
} from "../../../constants/filterCategories";
import PageLoader from "../../../components/common/PageLoader";

const ListInvoicePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentDate = new Date();
  const [dateRange, setDateRange] = useState<{
    startDate: string;
    endDate: string;
  }>({
    startDate: "",
    endDate: "",
  });
  const [startOfMonth, setStartOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endOfMonth, setEndOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const { invoiceData, error } = useInvoiceStatsData(startOfMonth, endOfMonth);
  const fetchMoreData = useInvoiceStore((state) => state.fetchMoreData);
  const onSearchData = useInvoiceStore((state) => state.setSearchText);
  const handleFilter = useInvoiceStore((state) => state.setFilter);
  const fetchData = useInvoiceStore((state) => state.fetchData);
  const clearAllFilters = useInvoiceStore((state) => state.clearAllFilters);
  const { t } = useTranslation("invoices");
  const invoices = useInvoiceStore((state) => state.invoices);
  const isLoading = useInvoiceStore((state) => state.isLoading);
  const isLoadingInitial = useInvoiceStore((state) => state.isLoadingInitial);
  const isLoadingFilter = useInvoiceStore((state) => state.isLoadingFilter);
  const isLoadingMore = useInvoiceStore((state) => state.isLoadingMore);
  const range = useInvoiceStore((state) => state.range);
  const pagination = useInvoiceStore((state) => state.pagination);
  const searchText = useInvoiceStore((state) => state.searchText);
  const filter = useInvoiceStore((state) => state.filter);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] =
    React.useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const isRetailBusiness = useRetailBusinessStatus();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>("Filter by date");
  const [selected, setSelected] = useState<[Date, Date] | null>(null);
  const {
    data: searchResult,
    isSearching,
    searchedDone,
    setSearchedDone,
    handleSearch: handleInvoiceSearch,
  } = useInvoiceSearch();

  useEffect(() => {
    if (dateRange.startDate || dateRange.endDate) {
      fetchData({ startDate: dateRange.startDate, endDate: dateRange.endDate });
    } else {
      fetchData();
    }
  }, [searchText, range, dateRange, fetchData]);

  useEffect(() => {
    if ("status" in filter) {
      fetchData({ actionType: "filter" });
    }
  }, [filter, fetchData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDateRange = params.get("startDate") || "";
    const endDateRange = params.get("endDate") || "";
    setDateRange({ startDate: startDateRange, endDate: endDateRange });
  }, [location.search]);

  const fetchInvoicePage = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      setIsModalOpen(true);
      return;
    }
    setShowSubscriptionModal(true);
    setSubscriptionErrorMessage(subscriptionCheck.message || "");
  };

  const handleInventoryInvoiceClick = async () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/invoices/create");
      return;
    } else {
      setShowSubscriptionModal(true);
    }
  };

  const handleFreeformInvoiceClick = () => {
    const subscriptionCheck = checkSubscription(servicesType.Invoices);
    if (subscriptionCheck.status) {
      navigate("/invoices/createFreeform");
    } else {
      setShowSubscriptionModal(true);
      setSubscriptionErrorMessage(subscriptionCheck.message || "");
    }
  };

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const infoCardData = [
    {
      title: t("totalInvoices"),
      mobileTitle: t("totalInvoices"),
      value: `${invoiceData?.totalInvoices || 0}`,
      bgColor: "#F0F7FF",
      className: "w-full",
    },
    {
      title: t("paidStatus"),
      mobileTitle: t("paidStatus"),
      value: `${invoiceData?.totalPaidInvoices || 0}`,
      bgColor: "#ECFDF3",
      className: "w-full",
    },
    {
      title: t("pendingStatus"),
      mobileTitle: t("pendingStatus"),
      value: `${invoiceData?.totalPendingInvoices || 0}`,
      bgColor: "#FFFAEB",
      className: "w-full",
    },
    {
      title: t("overdueStatus"),
      mobileTitle: t("overdueStatus"),
      value: `${invoiceData?.totalOverDueInvoices || 0}`,
      bgColor: "#FEF3F2",
      className: "w-full",
    },
  ] as ListInfoCardProps[];

  const handleRangeSelect = async (ranges: any) => {
    setShowCalendar(false);
    setSelected(ranges);
    const startDate = ranges[0];
    const endDate = ranges[1];
    let label = "";
    for (const shortcut of defaultShortcuts) {
      const [shortcutStart, shortcutEnd] = shortcut.range();

      if (
        startDate.getTime() === shortcutStart.getTime() &&
        endDate.getTime() === shortcutEnd.getTime()
      ) {
        label = shortcut.label;
        break;
      }
    }
    if (label) {
      setSelectedLabel(label);
    } else {
      setSelectedLabel(
        `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
      );
    }
    setStartOfMonth(new Date(startDate));
    setEndOfMonth(new Date(endDate));
  };

  const handleApplyButtonClick = async (
    startDate: Date,
    endDate: Date | null
  ) => {
    if (endDate === null) {
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
    }
    const newStartDate = startDate?.toISOString().replace(".000Z", "");
    const newEndDate = endDate?.toISOString().replace(".000Z", "");
    setDateRange({ startDate: newStartDate, endDate: newEndDate });
    try {
      await handleRangeSelect([startDate, endDate]);
      handleCalendarToggle();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!showCalendar) {
      setSelected(null);
    }
  }, [showCalendar]);

  const handleFilterSubmit = (filter: Record<string, string | boolean>) => {
    if (filter.status !== undefined || filter.isReoccurring !== undefined) {
      //@ts-ignore
      const newFilter: Partial<Filter> = {};
      if (filter.status !== undefined) {
        newFilter.status = filter.status as string;
      }
      if (filter.isReoccurring !== undefined) {
        newFilter.isReoccurring = filter.isReoccurring as boolean | null;
      }
      //@ts-ignore
      handleFilter(newFilter as Filter);
    }
  };

  const actionButtons = generateActionButtons(
    isRetailBusiness,
    handleInventoryInvoiceClick,
    handleFreeformInvoiceClick
  );

  if (isLoadingInitial) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  if (error) {
    return (
      <div>
        {typeof error === "object" && "message" in error && error.message
          ? error.message
          : JSON.stringify(error)}
      </div>
    );
  }

  return (
    <>
      <div>
        <ReusableListPage
          categories={invoiceCategories}
          mobileCategories={mobileInvoiceCategories}
          createTitle="Create Invoice"
          pageTitle="Invoice"
          showReOccurring={true}
          reOccurringText="Recurring invoice"
          showCalendar={showCalendar}
          infoCardData={infoCardData}
          handleCalendarToggle={handleCalendarToggle}
          handleCreate={fetchInvoicePage}
          selectedLabel={selectedLabel}
          fetchMoreData={fetchMoreData}
          onSearchData={onSearchData}
          handleFilter={handleFilter}
          handleSearch={handleInvoiceSearch}
          meta={pagination}
          data={renderInvoicesData(invoices, null)}
          modules="invoices"
          searchDisplayKey="customer.firstName"
          searchPlaceHolder="Search by customer"
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isLoadingFilter={isLoadingFilter}
          isSearching={isSearching}
          searchResult={searchResult}
          selectedFilter={filter}
          searchedDone={searchedDone}
          setSearchedDone={setSearchedDone}
          columns={columns}
          onSubmit={handleFilterSubmit}
          mobileCard={"invoiceCard"}
          clearAllFilters={clearAllFilters}
          emptyStateDescription={t("invoice.invoiceFirst")}
          emptyPageIcon={<ExpenseEmptyIcon />}
          emptyPageHref="https://www.youtube.com/embed/fJUWHHoUaxo"
          emptyPageLinkName="invoices"
          handleSetCustomer={function (
            selectedCustomer: ICustomer | null
          ): void {
            throw new Error("Function not implemented.");
          }}
          emptyMessage={t("invoice.emptyState")}
        />
      </div>

      <div ref={containerRef}>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an invoice creation option"
          icon={UploadIcon}
          actionButtons={actionButtons}
        />
      </div>
      <div className="mt-[-30em]">
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>
      {showCalendar && (
        <>
          <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
            <div className="md:flex justify-center items-center h-full w-full">
              <DatePickerWithShortcuts
                onRangeSelect={handleRangeSelect}
                selected={selected}
                onClose={handleCalendarToggle}
                onApplyButtonClick={(startDate, endDate) =>
                  handleApplyButtonClick(startDate, endDate)
                }
                fetchData={fetchData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { ListInvoicePage };
