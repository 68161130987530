import React from "react";
import toast from "react-hot-toast";
import { formatPhoneNumber } from "react-phone-number-input";
import { ICustomer } from "../../../@types";
import { createCustomer } from "../../../backend-services";
import { CustomerT } from "../../../types";
import { validatePhoneNumber } from "../../../utils";
import { CreateModal } from "../../common/CreateModal";
import { CreateCustomer } from "../../customers";
import CreateForm from "../../customers/Create/CreateForm";

interface CreateProps {
  showSideModal: boolean;
  setShowSideModal: (show: boolean) => void;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  onCustomerCreate?: (customer: any) => void;
  createCustomers?: (newCustomer: any) => void;
  fetchCustomers?: () => void;
  onClose?: () => void;
}

const CreateCustomerModal: React.FC<CreateProps> = ({
  handleSetCustomer,
  showSideModal,
  setShowSideModal,
  onCustomerCreate,
  createCustomers,
  fetchCustomers,
  onClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [backendErrorMessage, setBackendErrorMessage] = React.useState("");
  const [formData, setFormData] = React.useState<ICustomer>({} as ICustomer);

  function isErrorResponse(
    error: any
  ): error is { response: { data: { message: string } } } {
    return (
      typeof error === "object" &&
      error.response &&
      typeof error.response === "object" &&
      error.response.data &&
      typeof error.response.data === "object" &&
      error.response.data.message
    );
  }

  const handleCreateCustomer = async (customer: CustomerT) => {
    setIsLoading(true);
    setErrorMessage("");
    setBackendErrorMessage("");

    if (!customer.phone || !validatePhoneNumber(customer.phone)) {
      setErrorMessage("Please input a valid phone number");
      setBackendErrorMessage("");
      setIsLoading(false);
      return;
    }

    try {
      let data;

      if (onCustomerCreate) {
        data = await createCustomer({
          ...customer,
          phone: customer.phone,
          isOptedIn: false,
          outletId: "",
        });
      } else if (createCustomers) {
        data = createCustomers({
          ...customer,
          phone: customer.phone,
          outletId: "",
        });
      }

      setIsLoading(false);

      // Check if the response indicates a duplicate customer
      if (data && !data.firstName && !data.lastName && !data.metadata) {
        toast.error(
          "A customer with this phone number or email already exists."
        );
      } else {
        fetchCustomers && fetchCustomers();
        setIsOpen(false);
        toast.success("Customer created successfully!");

        if (data && onCustomerCreate) {
          onCustomerCreate({
            id: data.id,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            businessName: data.businessName,
            phone: data.phone,
            address: data.address,
            city: data.city,
            state: data.state,
          });
        }
      }
    } catch (error: any) {
      setIsLoading(false);

      if (isErrorResponse(error)) {
        const errorMessage = error.response.data.message;
        setErrorMessage(errorMessage);
        setBackendErrorMessage(errorMessage);
      } else {
        setErrorMessage(error.message);
        setBackendErrorMessage(error.message);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  return (
    <>
      <div className="hidden md:block">
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-30 flex items-center justify-center z-10 ">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <CreateForm
              isLoading={isLoading}
              show={true}
              onSubmit={handleCreateCustomer}
              buttontitle={"Add"}
              backendErrorMessage={backendErrorMessage}
              setFormData={setFormData}
              onClose={handleClose}
              title={"Add New Customer"}
            />
          </div>
        </div>
      </div>
      <div className="md:hidden">
        <CreateCustomer
          show={showSideModal}
          onClose={() => setShowSideModal(false)}
          onCustomerCreate={(customer) => {
            handleSetCustomer(customer);
            setShowSideModal(false);
          }}
        />
      </div>
    </>
  );
};

export { CreateCustomerModal };
