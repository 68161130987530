import { ICreateSalesData, ICustomer, ItemInput } from "../../@types";
import React from "react";
import {
  createDraft,
  createInvoice,
  createQuote,
  updateQuote,
} from "../../backend-services";

export type InvoiceValidationErrors = {
  [K in keyof ICreateSalesData]: string | undefined;
};

export const validateInvoiceDraftForm = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<
    React.SetStateAction<InvoiceValidationErrors>
  >,
): boolean => {
  const errors: InvoiceValidationErrors = {
    customerId: formData.customerId ? undefined : "Select a Customer",
    invoiceDueAt: formData.invoiceDueAt ? undefined : "Due Date is required",
    invoiceNumber: formData.invoiceNumber
      ? undefined
      : "Autogenerate an Invoice Number",
    description: undefined,
    currency: formData.currency ? undefined : "Currency is required",
    additionalInformation: undefined,
    discount: undefined,
    inventories: undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    isReoccurring: undefined,
    isReoccurringEndDate: undefined,
    isReoccurringStartDate: undefined,
    reoccurringGroupName: undefined,
    status: undefined,
    tax: undefined,
    isPaymentCompleted: undefined,
    shouldSendEmail: undefined,
    shouldSendSMS: undefined,
    exchangeRate: undefined,
    reminderSettings: undefined,
  };
  let isError = false;

  if (!formData.inventories || formData.inventories.length === 0) {
    errors.inventories = "Add at least one inventory";
    isError = true;
  } else {
    formData.inventories.forEach((item: ItemInput, index: number) => {
      ["id", "rate", "quantity"].forEach((field) => {
        if (!item[field]) {
          errors.inventories = `Inventory ${index + 1} is missing ${field}. `;
          isError = true;
        }
      });
    });
  }

  setValidationResults(errors);
  return (
    !errors.customerId &&
    !errors.invoiceDueAt &&
    !errors.invoiceNumber &&
    !errors.description &&
    !errors.currency &&
    !isError
  );
};

export const createInvoiceData = async (
  formData: ICreateSalesData,
  customer: ICustomer,
  isRetail: boolean,
) => {
  let itemsArray;
  let invoiceType: "inventory" | "service" | "freeform";
  if (isRetail) {
    // Handle inventories for retail business
    const parsedInventories = formData?.inventories?.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (inventory?.variations && inventory?.variations.length > 0) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: inventory?.variations,
            },
          ];
          return variations;
        } else {
          // If no variations, create a single item array
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              rate: Number(inventory?.rate),
              quantity: Number(inventory?.quantity),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "inventory";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;
    invoiceType = "service";
  }

  const invoiceData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    invoiceDueAt: new Date(formData.invoiceDueAt || "").toISOString(),
    isReoccurring: formData.isReoccurring,
    additionalInformation: formData.additionalInformation,
    status: formData.isReoccurring ? undefined : "pending",
    isPaymentCompleted: formData.isPaymentCompleted,
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    invoiceType: invoiceType,
    exchangeRate: formData.exchangeRate,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    reminderSettings: formData.reminderSettings,
    ...(formData.isReoccurring && {
      isReoccurringStartDate: new Date().toISOString(),
      reoccurringGroupName: formData.reoccurringGroupName,
      isReoccurringEndDate: new Date(
        formData.isReoccurringEndDate || "",
      ).toISOString(),
    }),
  };
  if (formData.isPaymentCompleted) {
    invoiceData.paymentMethod = formData.paymentMethod;
  }
  return await createInvoice(invoiceData);
};

export const createInvoiceFreeformData = async (
  formData: ICreateSalesData,
  customer: ICustomer,
  isRetail: boolean,
  quoteNo?: string,
) => {
  let itemsArray;
  let invoiceType: "freeform";
  if (isRetail) {
    // Handle inventories for retail business
    const parsedInventories = formData.inventories.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (
          inventory?.variations?.id !== undefined &&
          inventory?.variations?.id !== null
        ) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: [
                {
                  id: inventory?.variations?.id,
                  name: inventory?.variations?.name,
                  type: inventory?.variations?.type,
                  quantity: Number(inventory?.quantity),
                },
              ],
            },
          ];
          return variations;
        } else {
          // If no variations, create a single item array
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              quantity: Number(inventory?.quantity),
              rate: Number(inventory?.rate),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "freeform";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;
    invoiceType = "freeform";
  }

  const invoiceData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    invoiceDueAt: new Date(formData.invoiceDueAt || "").toISOString(),
    isReoccurring: formData.isReoccurring,
    additionalInformation: formData.additionalInformation,
    status: formData.isReoccurring ? undefined : "pending",
    isPaymentCompleted: formData.isPaymentCompleted,
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    invoiceType: invoiceType,
    exchangeRate: formData.exchangeRate,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    reminderSettings: formData.reminderSettings,
    ...(formData.isReoccurring && {
      isReoccurringStartDate: new Date().toISOString(),
      reoccurringGroupName: formData.reoccurringGroupName,
      isReoccurringEndDate: new Date(
        formData.isReoccurringEndDate || "",
      ).toISOString(),
    }),
  };
  if (formData.isPaymentCompleted) {
    invoiceData.paymentMethod = formData.paymentMethod;
  }
  return await createInvoice(invoiceData);
};

export const createQuoteData = async (
  formData: ICreateSalesData,
  customer: ICustomer,
  isRetail: boolean,
  quoteNo?: string,
) => {
  let itemsArray;
  let invoiceType: "inventory" | "service";
  if (isRetail) {
    const parsedInventories = formData.inventories.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (
          inventory?.variations.id !== undefined &&
          inventory?.variations.id !== null
        ) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: [
                {
                  id: inventory?.variations?.id,
                  name: inventory?.variations?.name,
                  type: inventory?.variations?.type,
                  quantity: Number(inventory?.quantity),
                },
              ],
            },
          ];
          return variations;
        } else {
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              rate: Number(inventory?.rate),
              quantity: Number(inventory?.quantity),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "inventory";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;

    invoiceType = "service";
  }

  const quoteData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    expiryDate: new Date(formData.expiryDate || "").toISOString(),
    status: formData.status ? undefined : "active",
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    exchangeRate: formData.exchangeRate,
    invoiceType: invoiceType,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    reminderSettings: formData.reminderSettings,
  };
  if (quoteNo) {
    const updatedQuote = await updateQuote({ id: quoteNo, data: quoteData });
    return updatedQuote;
  } else {
    // Otherwise, create a new quote
    const createdQuote = await createQuote(quoteData);
    return createdQuote;
  }
};

export const createQuoteFreeformData = async (
  formData: ICreateSalesData,
  customer: ICustomer,
  isRetail: boolean,
  quoteNo?: string,
) => {
  let itemsArray;
  let invoiceType: "freeform";
  if (isRetail) {
    // Handle inventories for retail business
    const parsedInventories = formData.inventories.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (
          inventory?.variations.id !== undefined &&
          inventory?.variations.id !== null
        ) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: [
                {
                  id: inventory?.variations?.id,
                  name: inventory?.variations?.name,
                  type: inventory?.variations?.type,
                  quantity: Number(inventory?.quantity),
                },
              ],
            },
          ];
          return variations;
        } else {
          // If no variations, create a single item array
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              quantity: Number(inventory?.quantity),
              rate: Number(inventory?.rate),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "freeform";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;
    invoiceType = "freeform";
  }

  const quoteData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    expiryDate: new Date(formData.expiryDate || "").toISOString(),
    status: formData.status ? undefined : "active",
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    exchangeRate: formData.exchangeRate,
    invoiceType: invoiceType,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    reminderSettings: formData.reminderSettings,
  };
  if (quoteNo) {
    const updatedQuote = await updateQuote({ id: quoteNo, data: quoteData });
    return updatedQuote;
  } else {
    // Otherwise, create a new quote
    const createdQuote = await createQuote(quoteData);
    return createdQuote;
  }
};

export const createDraftData = async (
  formData: ICreateSalesData,
  customer: ICustomer | null,
  isRetail: boolean,
) => {
  let itemsArray;
  let invoiceType: "inventory" | "service";
  if (isRetail) {
    // Handle inventories for retail business
    const parsedInventories = formData?.inventories?.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (inventory?.variations && inventory?.variations.length > 0) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: inventory.variations,
            },
          ];
          return variations;
        } else {
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              quantity: Number(inventory?.quantity),
              rate: Number(inventory?.rate),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "inventory";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;

    invoiceType = "service";
  }

  const invoiceData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    invoiceDueAt: new Date(formData.invoiceDueAt || "").toISOString(),
    isReoccurring: formData.isReoccurring,
    additionalInformation: formData.additionalInformation,
    status: formData.isReoccurring ? undefined : "draft",
    isPaymentCompleted: formData.isPaymentCompleted,
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    exchangeRate: formData.exchangeRate,
    invoiceType: invoiceType,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    reminderSettings: formData.reminderSettings,
    ...(formData.isReoccurring && {
      isReoccurringStartDate: new Date().toISOString(),
      reoccurringGroupName: formData.reoccurringGroupName,
      isReoccurringEndDate: new Date(
        formData.isReoccurringEndDate || "",
      ).toISOString(),
    }),
  };
  return await createDraft(invoiceData);
};

export const createDraftFreeformData = async (
  formData: ICreateSalesData,
  customer: ICustomer | null,
  isRetail: boolean,
) => {
  let itemsArray;
  let invoiceType: "freeform";
  if (isRetail) {
    // Handle inventories for retail business
    const parsedInventories = formData.inventories.map(
      (inventory: {
        id: string;
        name: string;
        rate: number;
        quantity: number;
        variations?: any;
      }) => {
        if (
          inventory?.variations.id !== undefined &&
          inventory?.variations.id !== null
        ) {
          const variations = [
            {
              id: inventory?.id,
              name: inventory?.name,
              variations: [
                {
                  id: inventory?.variations?.id,
                  name: inventory?.variations?.name,
                  type: inventory?.variations?.type,
                  quantity: Number(inventory?.quantity),
                },
              ],
            },
          ];
          return variations;
        } else {
          return [
            {
              id: inventory?.id,
              name: inventory?.name,
              quantity: Number(inventory?.quantity),
              rate: Number(inventory?.rate),
            },
          ];
        }
      },
    );

    itemsArray = parsedInventories.flat();
    invoiceType = "freeform";
  } else {
    // Handle services for non-retail business
    const parsedServices = formData.services.map(
      (service: {
        id: string;
        name: string;
        duration: string;
        price: string;
      }) => ({
        id: service.id,
        name: service.name,
        duration: service.duration,
        price: parseFloat(service.price),
      }),
    );

    itemsArray = parsedServices;

    invoiceType = "freeform";
  }

  const invoiceData: ICreateSalesData = {
    customerId: customer?.id,
    description: formData.description,
    discount: formData.discount ? formData.discount / 100 : undefined,
    tax: formData.tax ? Number(formData.tax) / 100 : undefined,
    issueDate: new Date(formData.issueDate || "").toISOString(),
    invoiceDueAt: new Date(formData.invoiceDueAt || "").toISOString(),
    isReoccurring: formData.isReoccurring,
    additionalInformation: formData.additionalInformation,
    status: formData.isReoccurring ? undefined : "draft",
    isPaymentCompleted: formData.isPaymentCompleted,
    shouldSendEmail: formData.shouldSendEmail,
    shouldSendWhatsApp: formData.shouldSendWhatsApp,
    shouldSendSMS: formData.shouldSendSMS,
    currency: formData.currency,
    exchangeRate: formData.exchangeRate,
    invoiceType: invoiceType,
    inventories: isRetail ? itemsArray : undefined,
    services: isRetail ? undefined : itemsArray,
    ...(formData.isReoccurring && {
      isReoccurringStartDate: new Date().toISOString(),
      reoccurringGroupName: formData.reoccurringGroupName,
      isReoccurringEndDate: new Date(
        formData.isReoccurringEndDate || "",
      ).toISOString(),
    }),
  };

  return await createDraft(invoiceData);
};
