import React from "react";

const ExchangeRateIcon = ({ color = "#475467" }: { color?: string }) => {
  return (
    <>
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.479 12.4108L8.56817 14.3216L9.7467 15.5001L13.6693 11.5774L9.74669 7.65485L8.56818 8.83336L10.4789 10.7441L2.0026 10.744L2.00272 0.5H0.336057L0.335938 11.5773C0.335938 12.0375 0.709029 12.4106 1.16926 12.4106L10.479 12.4108Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default ExchangeRateIcon;
