import React, {
  FormEventHandler,
  useState,
  useEffect,
  useCallback,
} from "react";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useNavigate, useLocation } from "react-router-dom";
import LogoBlack from "../LogoBlack";
import * as UI from "../common";
import { Alert } from "flowbite-react";
import userPool from "../../utils/userPool";
import { useTranslation } from "react-i18next";
import useCognito from "../../hooks/auth/useCognito";
import ForgetPasswordIcon from "../../assets/icons/forgetPasswordIcon";
import { PinCode } from "../common";
import toast from "react-hot-toast";
import { PinCodeMobile } from "../common/PinCode/mobile";

const ResetPasswordView = () => {
  const { t } = useTranslation("onboarding");
  const {
    login,
    loading: isLoginLoading,
    error: message,
    confirmPassword,
    forgotPassword,
  } = useCognito();
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage;

  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  if (!email) throw new Error("User not found");

  const [code, setCode] = useState<string>("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isOtpDisabled, setIsOtpDisabled] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [, setErrorMessage] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    setIsButtonDisabled(code.length !== 6);
  }, [code]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    } else {
      setIsOtpDisabled(false);
    }
    return () => clearInterval(timer);
  }, [countdown]);

  const formatCountdown = (seconds: number) => {
    const minutes = String(Math.floor(seconds / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${minutes}:${secs}`;
  };

  const handleResetPassword: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (attempts >= 1) return;

    if (!code) {
      setErrorMessage("Please enter the OTP code.");
      return;
    }

    setAttempts(attempts + 1);
    setIsLoading(true);

    navigate(
      `/change-password?email=${encodeURIComponent(
        email!
      )}&code=${encodeURIComponent(code)}`
    );
  };

  const handleResendOTP = useCallback(async () => {
    if (isOtpDisabled) return;

    setCountdown(60);
    setIsOtpDisabled(true);
    setIsResending(true);

    try {
      await forgotPassword(email!);
      toast.success("Code resent successfully.");
    } catch (err: any) {
      const message = err?.message || "Error resending OTP. Try again.";
      setErrorMessage(message);
    } finally {
      setIsResending(false);
    }
  }, [email, isOtpDisabled]);

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block">
        <div className="flex items-center justify-center min-h-screen bg-[#F2F9FD]">
          <div className="w-full max-w-lg p-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-center mb-6 lg:hidden">
              <LogoBlack />
            </div>
            <div className="flex justify-center">
              <ForgetPasswordIcon />
            </div>

            <div className="text-center mb-4 mt-[1em]">
              <h1 className=" font-semibold text-[20px]">
                Password Reset Code
              </h1>
              <p className="text-[16px] text-gray-600 mt-5">
                To reset your password, input the code sent to{" "}
                <span className="text-[#044E97]">{email}</span>.
              </p>
              {errorMessage && (
                <Alert color="failure" className="mt-4">
                  {errorMessage}
                </Alert>
              )}
            </div>

            <form onSubmit={handleResetPassword}>
              <div className="w-full mt-[2em] mb-6 flex justify-center items-center">
                <PinCode codeLength={6} onCodeComplete={setCode} />
              </div>

              <div className="mt-[2em] text-[16px]">
                <UI.Button
                  text="Continue"
                  variant="secondary"
                  isLoading={isLoading}
                  disabled={isButtonDisabled || isLoading}
                />
              </div>
              <div className="text-center mt-4">
                <button
                  type="button"
                  onClick={handleResendOTP}
                  disabled={isOtpDisabled}
                  className={`text-[#044E97] text-[16px] font-semibold ${
                    isOtpDisabled ? "cursor-not-allowed opacity-50" : ""
                  }`}
                >
                  {isOtpDisabled
                    ? `Resending code in ${formatCountdown(countdown)}`
                    : "Resend OTP"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="lg:hidden md:hidden mt-[6em]">
        <div className="">
          <div className="flex justify-center">
            <ForgetPasswordIcon />
          </div>

          <div className="text-center mb-4 mt-[1em]">
            <h1 className="text-2xl font-semibold lg:text-3xl">
              Password Reset Code
            </h1>
            <p className="text-sm lg:text-base text-gray-600 mt-2">
              To reset your password, input the code sent to{" "}
              <span className="text-[#044E97]">{email}</span>.
            </p>
            {errorMessage && (
              <Alert color="failure" className="mt-4">
                {errorMessage}
              </Alert>
            )}
          </div>

          <form onSubmit={handleResetPassword}>
            <div className="w-full mt-4 mb-6 flex justify-center items-center">
              <PinCodeMobile codeLength={6} onCodeComplete={setCode} />
            </div>

            <div className="mb-6 px-[2em]">
              <UI.Button
                text="Continue"
                variant="secondary"
                isLoading={isLoading}
                disabled={isButtonDisabled || isLoading}
              />
            </div>
            <div className="text-center mt-4">
              <button
                type="button"
                onClick={handleResendOTP}
                disabled={isOtpDisabled}
                className={`text-[#044E97]   text-sm font-semibold ${
                  isOtpDisabled ? "cursor-not-allowed opacity-50" : ""
                }`}
              >
                {isOtpDisabled
                  ? `Resending code in ${formatCountdown(countdown)}`
                  : "Resend OTP"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export { ResetPasswordView };
