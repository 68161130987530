import React, {
  useState,
  useEffect,
  FormEvent,
  SetStateAction,
  Dispatch,
} from "react";
import { useInventoryStore } from "../../../state/useInventoryStore";
import { createVariationService } from "../../../backend-services/inventory";
import { v4 as uuidv4 } from "uuid";
import { InputComponent } from "../../core/Forms";
import * as UI from "../../common";
import { Button } from "flowbite-react";
import { AddSquareIcon, CloseIcon } from "../../../assets/icons";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { SidebarDrawer } from "../../common";
import LeftNotificationPopup from "../../core/NotificationPopup/LeftNotificationPopup";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import { useTranslation } from "react-i18next";
interface VariationFormProps {
  close: Dispatch<SetStateAction<boolean>>;
  closeForm: () => void;
  closeCreateForm: () => void;
}

const VariationForm = ({
  close,
  closeForm,
  closeCreateForm,
}: VariationFormProps) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    variationName: "",
  });
  const { t } = useTranslation("inventories");
  const [variationInputTypes, setVariationInputTypes] = useState<
    { id: number; placeholder: string; value: string }[]
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showSuccessfulModal, setShowSuccessfulModal] = React.useState(false);
  const [error, setError] = useState<string>("");
  const [indexState, setIndexState] = useState<number>(0);

  const { createVariation, fetchVariation, variations } = useInventoryStore(
    (state) => ({
      createVariation: state.createVariation,
      updateVariation: state.updateVariation,
      isLoadingState: state.isLoading,
      fetchVariation: state.fetchVariation,
      variations: state.variations,
    })
  );

  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const variationTypesArray = variationInputTypes.map((type) => type.value);
      const data = {
        variantName: formData.variationName,
        types: variationTypesArray,
      };
      const hasValues = variationInputTypes.some((type) => type.value !== "");
      if (hasValues || formData.variationName) {
        await createVariation(data);
        fetchVariation();
        closeCreateForm();
      }
    } catch (error: any) {
      toast.error(error.message);
      throw new Error(error.message);
    } finally {
      setIsLoading(false);
      setShowSuccessfulModal(true);
      closeForm();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const isName = variations.find((item: any) => item.variantName === value);
    if (isName) {
      toast.error("Variation Name already exists");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (index: any, newValue: any) => {
    const hasValues = variationInputTypes.some(
      (type) => type.value === newValue
    );
    if (hasValues) {
      toast.error("The Variation already exists");
      newValue = "";
    }
    const updatedVariationInputTypes = [...variationInputTypes];
    updatedVariationInputTypes[index] = {
      ...updatedVariationInputTypes[index],
      value: newValue,
    };
    setError("");
    setVariationInputTypes(updatedVariationInputTypes);
  };

  const handleAddVariationType = () => {
    if (
      variationInputTypes.length === 0 ||
      variationInputTypes[variationInputTypes.length - 1].value !== ""
    ) {
      const newVariationInput = {
        id: parseInt(uuidv4()),
        placeholder: "Type",
        value: "",
      };
      setVariationInputTypes([...variationInputTypes, newVariationInput]);
      setError("");
    } else {
      const variationNameIndex = variationInputTypes.findIndex(
        (type) => type.value === ""
      );
      setIndexState(variationNameIndex);
      setError("Required");
    }
  };

  const handleClearSelection = (index: number, value: string) => {
    const inputValue = variationInputTypes.filter(
      (inputType, indexInput) => indexInput !== index
    );
    setVariationInputTypes(inputValue);
  };

  useEffect(() => {
    return () => {
      fetchVariation();
    };
  }, []);

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col items-center">
          <div className="flex flex-col items-center gap-[16px] md:gap-[14px] mb-[] md:mb-[16px]">
            <div className="flex flex-col">
              <label
                htmlFor="first-name"
                className="text-[1rem] text-[#666666] font-normal"
              >
                Variation Name
              </label>
              <div className="max-w-[350px] md:min-w-[434px]">
                <InputComponent
                  name="variationName"
                  value={formData.variationName}
                  className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal w-[280px] max-w-[350px] md:min-w-[434px] h-[48px] rounded"
                  type="text"
                  placeholder="e.g. Size"
                  handleChange={handleOnChange}
                />
              </div>
            </div>

            {variationInputTypes.map((type: any, index: any) => {
              return (
                <div key={index} className="flex flex-col mb-[10px]">
                  <label
                    htmlFor="variation-type"
                    className="text-[1rem] text-[#666666] font-normal"
                  >
                    {t("inventory.variationType")}
                  </label>
                  <div className="flex flex-row relative">
                    <div className="max-w-[350px] md:min-w-[434px]">
                      <InputComponent
                        name="variationType"
                        className="border bg-[#F2F2F2] placeholder:text-[1rem] placeholder:text-[#000000] placeholder:font-normal h-[48px] w-[280px] rounded max-w-[350px] md:min-w-[434px]"
                        type="text"
                        placeholder="e.g. Large"
                        value={type.value}
                        handleChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                      {index == indexState && (
                        <span className="text-red-500 absolute">{error}</span>
                      )}
                    </div>
                    <div>
                      {type.value !== "" && (
                        <button
                          type="button"
                          onClick={(e) =>
                            handleClearSelection(index, type.value)
                          }
                          className={`absolute ml-[-3em] mt-[1em] text-red-500`}
                        >
                          <CloseIcon />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="w-full justify-start">
              <button
                type="button"
                onClick={handleAddVariationType}
                className="flex gap-4 items-center"
              >
                <p className="text-[#044E97] text-[16px]">
                  {" "}
                  {t("inventory.addVariationType")}
                </p>
                <AddSquareIcon />
              </button>
            </div>
          </div>

          <div className="xl:w-[300px] md:w-[300px] mt-[0em]" />
          <div className="flex justify-center items-center">
            <div className="xl:w-[300px] md:w-[300px] w-[215px] lg:w-[215px] flex justify-center xl:mt-[1em]">
              <UI.Button
                variant="secondary"
                type="submit"
                text="Save Changes"
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </form>

      {showSuccessfulModal && (
        <>
          <SidebarDrawer
            title={"Variation Created"}
            show={true}
            position={"left"}
            onClose={() => setShowSuccessfulModal(false)}
          >
            <LeftNotificationPopup
              title={""}
              icon={<ProcessSuccessful />}
              onClose={() => setShowSuccessfulModal(false)}
            />
          </SidebarDrawer>
        </>
      )}
    </div>
  );
};

export default VariationForm;
