import React, { ChangeEvent, useEffect, useState } from "react";
import InvoiceDate from "../../Sales/create/SalesCreate/InvoiceDate";
import { InvoiceErrors } from "../../../modules/validateInvoiceForm";
import InvoiceCurrency from "../../Sales/create/SalesCreate/InvoiceCurrency";
import CompanyUpdate from "../../Sales/create/SalesCreate/companyProfile";
import CustomerSection from "./CustomerSection";
import { useUserStore } from "../../../state";
import { clearFieldError } from "../../../utils";
import { ICreateProfile, ICreateSalesData } from "../../../@types";

interface LogoComponentProps {
  logoUrl: string;
  setLogoUrl: (url: string) => void;
  error?: string;
  onUploadSuccess: () => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
}

const CompanySection: React.FC<LogoComponentProps> = ({
  logoUrl,
  setLogoUrl,
  error,
  onUploadSuccess,
  formData,
  setFormData,
  setValidationResults,
  validationResults,
}) => {
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] =
    React.useState("₦ Naira");
  const [showRateInput, setShowRateInput] = useState(false);
  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData: any) => {
      return { ...prevData, [name]: value };
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const [userData, setUserData] = useState<ICreateProfile | null>(null);
  // const [validationResults, setValidationResults] =
  //   React.useState<InvoiceErrors>({});
  const handleDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    const currencyValue = value === "₦ Naira" ? "NGN" : value;
    if (value == "NGN") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: currencyValue || "NGN",
        exchangeRate: 0,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: currencyValue || "NGN",
      }));
    }
    setSelectedCurrencyLabel(value);
    setShowRateInput(currencyValue !== "NGN");
  };

  const user = useUserStore((state) => state.user);
  const [localLogoUrl, setLocalLogoUrl] = useState<string | undefined>(logoUrl);
  const [localError, setLocalError] = useState<string | undefined>(error);

  useEffect(() => {
    setLocalError(error);
  }, [error]);

  useEffect(() => {
    setLocalLogoUrl(logoUrl);
  }, [logoUrl]);
  const MAX_FILE_SIZE_KB = 100;
  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size / 1024 > MAX_FILE_SIZE_KB) {
        setLocalError(`File size exceeds ${MAX_FILE_SIZE_KB} KB`);
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setLocalError(undefined);
        const result = reader.result as string;
        setLocalLogoUrl(result);
        setLogoUrl(result);
        onUploadSuccess();
      };
      reader.readAsDataURL(file);
    }
  };

  const displayLogo = localLogoUrl || user?.logoBase64 || user?.logoUrl;
  const handleProfileUpdate = (updatedProfile: ICreateProfile) => {
    setUserData(updatedProfile);
  };

  return (
    <>
      {/* Mobile */}
      <div className="lg:hidden md:hidden">
        {/* Business Logo */}
        <div className="mt-5 text-[16px] text-[#000000] font-medium">
          <label className="text-[14px] md:text-[16px] leading-6 font-medium mb-2">
            Your Logo
          </label>
          <div
            className="relative flex items-center justify-center w-full h-24 md:h-28 bg-[#F9FAFB] border border-gray-200 rounded-xl cursor-pointer"
            onClick={() => document.getElementById("logoUpload")?.click()} // Trigger file input click
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleLogoUpload}
              style={{ display: "none" }}
              id="logoUpload"
            />

            {displayLogo ? (
              <div className="relative w-full h-full">
                <img
                  src={displayLogo}
                  alt="Uploaded logo preview"
                  className="w-full h-full object-contain rounded-xl"
                />
                {/* Edit overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                  <span className="text-white font-semibold text-sm">
                    Click to Edit
                  </span>
                </div>
              </div>
            ) : (
              <span className="leading-6 text-[14px] font-medium text-gray-700">
                Click to Edit
              </span>
            )}

            {/* Upload button positioned at the bottom-right */}
            <span className="absolute bottom-2 right-2 text-white text-[14px] font-medium bg-[black] px-2 rounded-md">
              Upload
            </span>
          </div>
        </div>

        {/* Date */}
        <InvoiceDate
          formData={formData}
          handleInputChange={handleInputChange}
          validationResults={validationResults}
          setValidationResults={setValidationResults}
          setFormData={setFormData}
        />

        <InvoiceCurrency
          formData={formData}
          setFormData={setFormData}
          showRateInput={true}
          selectedCurrencyLabel={selectedCurrencyLabel}
          handleDropDownChange={handleDropDownChange}
          validationResults={validationResults}
          toolTipText=""
        />

        <CompanyUpdate show={true} onCreateProfile={handleProfileUpdate} />
      </div>

      {/* Desktop */}
      <div className="hidden md:block lg:block">
        <div className="flex justify-between">
          {/* Business Logo */}
          <div className="flex flex-col">
            <div className="mt-5 text-[16px] text-[#000000] font-medium">
              <label className="text-[14px] md:text-[16px] leading-6 font-medium mb-2">
                Your Logo
              </label>
              <div
                className="relative flex items-center justify-center w-full lg:w-[370px] h-24 md:h-28 bg-[#F9FAFB] border border-gray-200 rounded-xl cursor-pointer"
                onClick={() => document.getElementById("logoUpload")?.click()} // Trigger file input click
              >
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleLogoUpload}
                  style={{ display: "none" }}
                  id="logoUpload"
                />

                {displayLogo ? (
                  <div className="relative w-full h-full">
                    <img
                      src={displayLogo}
                      alt="Uploaded logo preview"
                      className="w-full h-full object-contain rounded-xl"
                    />
                    {/* Edit overlay */}
                    <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity">
                      <span className="text-white font-semibold text-sm">
                        Click to Edit
                      </span>
                    </div>
                  </div>
                ) : (
                  <span className="leading-6 text-[14px] font-medium text-gray-700">
                    Click to Edit
                  </span>
                )}

                {/* Upload button positioned at the bottom-right */}
                <span className="absolute bottom-2 right-2 text-white text-[14px] font-medium bg-[black] px-2 rounded-md">
                  Upload
                </span>
              </div>
            </div>
            <CompanyUpdate show={true} onCreateProfile={handleProfileUpdate} />
          </div>
          {/* Date */}
          <div className="flex flex-col mr-[-3em]">
            <InvoiceDate
              formData={formData}
              handleInputChange={handleInputChange}
              validationResults={validationResults}
              setValidationResults={setValidationResults}
              setFormData={setFormData}
            />

            <InvoiceCurrency
              formData={formData}
              setFormData={setFormData}
              showRateInput={true}
              selectedCurrencyLabel={selectedCurrencyLabel}
              handleDropDownChange={handleDropDownChange}
              validationResults={validationResults}
              toolTipText=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanySection;
