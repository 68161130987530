import React, { useEffect } from "react";
import InvoiceHeader from "./sections/InvoiceHeader";
import CompanySection from "./sections/CompanySection";
import CustomerSection from "./sections/CustomerSection";
import LineItemsSection from "./sections/LineItemsSection";
import AdditionalDetailsSection from "./sections/AdditionalDetailsSection";
import BankSection from "./sections/BankSection";
import SummarySection from "./sections/SummarySection";
import { ICreateSalesData, IInventory, ItemInput } from "../../@types";
import {
  validateInvoiceBasicInfo,
  validateInvoiceItemDetails,
  validateInvoiceSummary,
} from "../../modules/validateInvoiceForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInvoiceFormState } from "../../hooks/invoice/useInvoiceFormState";
import { useInvoiceCalculations } from "../../hooks/invoice/useInvoiceCalculations";
import { clearFieldError } from "../../utils";
import toast from "react-hot-toast";
import { checkSubscription } from "../../utils/subscription";
import { servicesType } from "../../constants/Subscription";
import SendInvoiceModal from "./sendInvoiceModal";
import { Button } from "../common/Button";
import useLineItems from "../../modules/lineItem";
import LineTable from "../Sales/create/SalesCreate/LineTable";
import SuccessBanner from "../common/Banner/SuccessBanner";
import ServiceLineItem from "./Service/ServiceLineItem";
import { useCompanyStore } from "../../state/useCompanyStore";
import { useInventoryStore } from "../../state/useInventoryStore";
import useInvoiceState from "../../hooks/invoice/useInvoiceState";
import { useInvoiceActions } from "../../hooks/invoice/useInvoiceActions";

const InvoiceContainer = () => {
  const navigate = useNavigate();
  const userStore = useCompanyStore();
  const { fetchVariations, fetchData } = useInventoryStore();
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const location = useLocation();
  const { serviceData } = location.state || {};

  const { items, addItem, setItems, removeItem, updateCell, updateRow, reset } =
    useLineItems([{ id: "", name: "", rate: 0, quantity: 0 }]);

  const {
    bannerLinkText,
    bannerMessage,
    isBannerVisible,
    isDraftLoading,
    selectedInventory,
    setAddItemModalOpen,
    setBannerLinkText,
    setBannerMessage,
    setError,
    setIsBannerVisible,
    setIsisDraftLoading,
    setLoading,
    setSelectedInventory,
    setShowSubscriptionModal,
    setShowSuccessModal,
    setSubscriptionErrorMessage,
    createdInvoiceId,
    setCreatedInvoiceId,
    setCreatedEmail,
    setCustomer,
    selectedCurrencyLabel,
  } = useInvoiceState();

  const {
    formData,
    setFormData,
    customer,
    handleSetCustomer,
    validationResults,
    setErrorMessage,
    isLoading,
    setIsLoading,
    showSidebar,
    setShowSidebar,
    handleInputChange,
    resetForm,
    isRetail,
    setValidationResults,
    setLogoUrl,
    handleEditProfileLogo,
    handlePaymentDropDownChange,
  } = useInvoiceFormState();

  const { processCreateDraftInvoice, processCreateInvoice, fetchDraftInvoice } =
    useInvoiceActions({
      customer,
      formData,
      invoiceNo,
      isRetail,
      setBannerLinkText,
      setBannerMessage,
      setCreatedEmail,
      setCreatedInvoiceId,
      setError,
      setErrorMessage,
      setIsBannerVisible,
      setIsisDraftLoading,
      setIsLoading,
      setShowSidebar,
      setShowSubscriptionModal,
      setShowSuccessModal,
      setSubscriptionErrorMessage,
      setCustomer,
      setFormData,
      setItems,
      setLoading,
    });

  const { subTotal, total, calcTax, calcDiscount } = useInvoiceCalculations(
    formData,
    isRetail,
  );

  const [markAsPaid, setMarkAsPaid] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [shouldSendWhatsApp, setShouldSendWhatsApp] = React.useState(false);
  const [shouldSendSMS, setShouldSendSMS] = React.useState(false);

  const handleViewSelection = (inventory: IInventory) => {
    setSelectedInventory(inventory);
    setAddItemModalOpen(true);
  };

  const handleCreateInvoice = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();
    try {
      const basicInfoValid = validateInvoiceBasicInfo(
        formData,
        setValidationResults,
      );
      const itemDetailsValid = validateInvoiceItemDetails(
        formData,
        setValidationResults,
        userStore.isRetailBusiness(),
      );
      const summaryValid = validateInvoiceSummary(
        formData,
        setValidationResults,
      );

      if (basicInfoValid && itemDetailsValid && summaryValid) {
        const subscriptionCheck = checkSubscription(servicesType.Invoices);
        if (subscriptionCheck.status) {
          setShowSidebar(true);
        } else {
          setErrorMessage("Insufficient subscription to create invoices.");
          setShowSubscriptionModal(true);
          setSubscriptionErrorMessage(subscriptionCheck.message || "");
        }
      } else {
        setErrorMessage("Please fill out all required fields correctly.");

        // Scroll to the topmost page if there are any errors
        document.getElementById("top-of-page")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    } catch (error) {
      console.error("Failed to create invoice:", error);
      toast.error("Failed to create invoice. Please try again later.");
    }
  };

  const onCreateInvoice = async () => {
    await processCreateInvoice(
      sendEmail,
      markAsPaid,
      shouldSendWhatsApp,
      shouldSendSMS,
    );
    resetForm();
    reset();
    setIsBannerVisible(true);
    setShowSidebar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    console.log("Validation results in InvoiceParent:", validationResults);
  }, [validationResults]);

  function handleSendEmailChange(checked: boolean) {
    setSendEmail(checked);
  }
  function handleMarkAsPaidChange(event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) {
    setMarkAsPaid(event.target.checked);
  }
  function handleshouldSendWhatsAppChange(checked: boolean) {
    setShouldSendWhatsApp(checked);
  }
  function handleshouldSendSMSChange(checked: boolean) {
    setShouldSendSMS(checked);
  }
  const handleViewInvoice = () => {
    if (createdInvoiceId) {
      const viewInvoiceRoute = `/invoices/${createdInvoiceId}/view`;
      navigate(viewInvoiceRoute);
    }
  };
  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const onSaveDraft = async (event: any) => {
    event.preventDefault();
    await processCreateDraftInvoice();
    navigate("/invoices");
  };

  useEffect(() => {
    if (invoiceNo) {
      setLoading(true);
      fetchDraftInvoice(invoiceNo);
    } else {
      setIsLoading(false);
    }
  }, [invoiceNo]);

  useEffect(() => {
    fetchData();
  }, [customer]);

  useEffect(() => {
    fetchVariations();
  }, []);

  useEffect(() => {
    if (serviceData) {
      const ngnPrice =
        serviceData.price?.find(
          (priceItem: { amount: number; currency: string }) =>
            priceItem.currency === "NGN",
        )?.amount || 0;

      setFormData((prevFormData) => ({
        ...prevFormData,
        services: [
          {
            id: serviceData.id || "",
            name: serviceData.name || "",
            duration: serviceData.duration || "",
            price: ngnPrice,
          },
        ],
      }));
    }
  }, [serviceData]);
  React.useEffect(() => {
    clearFieldError("inventories", validationResults, setValidationResults);
    const rest = items
      .map((item: ItemInput) => ({
        id: item.id,
        name: item.name,
        amount: 0,
        quantity: item.quantity || 0,
        rate: item.rate || 0,
        variations: item.variation || [],
      }))
      .filter((i) => i.id !== "");
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        inventories: rest,
      } as ICreateSalesData;
    });
  }, [items]);

  React.useEffect(() => {
    clearFieldError("services", validationResults, setValidationResults);
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        services: items,
      };
    });
  }, [items]);

  useEffect(() => {
    if (
      typeof formData.exchangeRate !== "number" &&
      isNaN(Number(formData.exchangeRate))
    ) {
      const rest = formData.inventories.filter((i) => i.id !== "");
      setFormData((prevData) => ({
        ...prevData,
        exchangeRate: 1,
        inventories: rest,
      }));
    }
  }, [formData]);

  return (
    <div id="top-of-page">
      {isBannerVisible && (
        <SuccessBanner
          message={bannerMessage}
          linkText={bannerLinkText}
          onLinkClick={handleViewInvoice}
          onClose={handleCloseBanner}
        />
      )}
      <InvoiceHeader />
      <div id="invoice-date">
        <CompanySection
          logoUrl={formData.logoBase64 || ""}
          setLogoUrl={setLogoUrl}
          error={undefined}
          onUploadSuccess={handleEditProfileLogo}
          formData={formData}
          setFormData={setFormData}
          validationResults={validationResults}
          setValidationResults={setValidationResults}
        />
      </div>
      <div id="customer-info" className="">
        <CustomerSection
          customer={customer}
          handleSetCustomer={handleSetCustomer}
          validationResults={validationResults}
          formData={formData}
          setValidationResults={setValidationResults}
        />
      </div>
      {isRetail ? (
        <LineItemsSection
          formData={formData}
          setFormData={setFormData}
          variations={[]}
          total={selectedInventory?.total || 0}
        />
      ) : (
        <ServiceLineItem formData={formData} setFormData={setFormData} />
      )}
      <div id="line-table">
        <LineTable
          items={items}
          addItem={addItem}
          setItems={setItems}
          removeItem={removeItem}
          updateCell={updateCell}
          updateRow={updateRow}
          formData={formData}
          setFormData={setFormData}
          invoiceNo={invoiceNo}
          validationResults={validationResults}
          setValidationResults={setValidationResults}
          selectedCurrencyLabel={selectedCurrencyLabel}
        />
      </div>
      <SummarySection
        subTotal={subTotal}
        formData={formData}
        calcDiscount={calcDiscount}
        calcTax={calcTax}
        total={total}
        handleInputChange={handleInputChange}
      />
      <hr className="my-5 hidden md:block" />
      <div className="hidden md:block">
        {" "}
        <BankSection />
      </div>
      <hr className="my-5 hidden md:block" />
      <AdditionalDetailsSection
        formData={formData}
        handleInputChange={handleInputChange}
      />
      <hr className="my-5 mt-8 md:hidden " />
      <div className="md:hidden">
        {" "}
        <BankSection />
      </div>

      {/* <RecurringSection /> */}
      <hr className="my-5 " />
      {/* <ActionButtons /> */}
      <div className="w-full py-5 flex justify-between lg:justify-end gap-[1em]">
        <Button
          outline={true}
          variant="secondary"
          text={"Save as Draft"}
          size="small"
          font="semibold"
          onClick={onSaveDraft}
          isLoading={isDraftLoading}
          disabled={isDraftLoading}
        />
        <div className="flex ">
          <Button
            variant="secondary"
            text={"Save"}
            size="small"
            font="semibold"
            onClick={handleCreateInvoice}
          />
        </div>
      </div>
      {showSidebar && (
        <SendInvoiceModal
          onCreateInvoice={onCreateInvoice}
          onClose={() => setShowSidebar(false)}
          formData={formData}
          validationResults={validationResults}
          handlePaymentDropDownChange={handlePaymentDropDownChange}
          markAsPaid={markAsPaid}
          handleMarkAsPaidChange={handleMarkAsPaidChange}
          handleSendEmailChange={handleSendEmailChange}
          shouldSendSMS={shouldSendSMS}
          customer={customer}
          sendEmail={sendEmail}
          handleshouldSendWhatsAppChange={handleshouldSendWhatsAppChange}
          handleInputChange={handleInputChange}
          handleshouldSendSMSChange={handleshouldSendSMSChange}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default InvoiceContainer;
