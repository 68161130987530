import React, { useState } from "react";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  CustomerIcon,
  ExpensesIcon,
  HomeIcon,
  InvoiceIcon,
  ReportIcon,
  InventoryIcon,
  ActiveHomeIcon,
  ActiveInvoiceIcon,
  ActiveExpensesIcon,
  ActiveCustomerIcon,
  ActiveInventoryIcon,
  ActiveReportIcon,
} from "../../assets/icons";
import { useUserStore } from "../../state";
import LogoWhite from "./../LogoWhite";
import { isAdmin } from "../../utils/isAdmin";
import { UnionIcon } from "../../assets/icons/UnionIcon";
import UserIcon, { ActiveUserIcon } from "../../assets/icons/UserIcon";
import useRetailBusinessStatus from "../../hooks/invoice/useRetailBusinessStatus";
import ServiceIcon, { ActiveServiceIcon } from "../../assets/icons/ServiceIcon";
import { ToggleBar } from "../../assets/icons/ToggleBar";
import EsemieLogo from "../../assets/icons/EsemieLogo";

const SideMenu: React.FC = () => {
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const { roles } = useUserStore();
  const isRetailBusiness = useRetailBusinessStatus();

  const menuItems = [
    {
      paths: isAdmin(roles) ? ["/dashboard"] : ["/operator-dashboard"],
      label: "Insights",
      icon: <HomeIcon />,
      activeIcon: <ActiveHomeIcon />,
      to: isAdmin(roles) ? "/dashboard" : "/operator-dashboard",
      className: "menu-item-1",
    },
    {
      label: "Sales",
      icon: <InvoiceIcon />,
      activeIcon: <ActiveInvoiceIcon />,
      className: "menu-item-2",
      subItems: [
        {
          paths: ["/invoices", "/invoices/create", "/invoices/:invoiceNo/view"],
          label: "Invoices",
          to: "/invoices",
        },
        {
          paths: ["/quotes", "/quotes/create", "/quotes/:quoteId/view"],
          label: "Quotes",
          to: "/quotes",
        },
      ],
    },
    {
      paths: ["/expenses", "/expenses/create", "/expenses/expenseName/view"],
      label: "Expenses",
      icon: <ExpensesIcon />,
      activeIcon: <ActiveExpensesIcon />,
      to: "/expenses",
      className: "menu-item-3",
    },
    {
      paths: [
        "/customers",
        "/customers/create",
        "/customers/customerName/view",
      ],
      label: "Customers",
      icon: <CustomerIcon />,
      activeIcon: <ActiveCustomerIcon />,
      to: "/customers",
      className: "menu-item-4",
    },
    {
      paths: isRetailBusiness ? ["/inventories"] : ["/services"],
      label: isRetailBusiness ? "Inventory" : "Services",
      icon: isRetailBusiness ? <InventoryIcon /> : <ServiceIcon />,
      activeIcon: isRetailBusiness ? (
        <ActiveInventoryIcon />
      ) : (
        <ActiveServiceIcon />
      ),
      to: isRetailBusiness ? "/inventories" : "/services",
      className: "menu-item-5",
    },
    {
      paths: ["/Reports"],
      label: "Reports",
      icon: <ReportIcon />,
      activeIcon: <ActiveReportIcon />,
      to: "/reports",
      className: "menu-item-6",
    },
    {
      paths: ["/user-management"],
      label: "Users",
      icon: <UserIcon />,
      activeIcon: <ActiveUserIcon />,
      to: "/user-management",
      className: "menu-item-7",
    },
  ];

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const isActive = (paths: string | string[]) => {
    if (typeof paths === "string") return pathname === paths;
    return paths.some((path) => pathname.startsWith(path));
  };

  const toggleDropdown = (label: boolean) => {
    setDropdownOpen(label);
  };

  const renderMenuItem = (menuItem: any) => {
    return (
      <>
        {/* For items with submenus */}
        {menuItem.subItems ? (
          <div onMouseLeave={() => toggleDropdown(false)}>
            <div
              className={cx(
                `flex items-center justify-between cursor-pointer rounded-xl  ${
                  isCollapsed ? "p-3" : "px-3 py-[10px]"
                }`,
                isActive(menuItem.subItems?.map((sub: any) => sub.to))
                  ? "bg-[#BBDDFC] text-[#0D3C6D]"
                  : "hover:bg-[#1A5F91]"
              )}
              onClick={() => toggleDropdown(!dropdownOpen)}
            >
              <div className="flex items-center gap-3">
                <span className={menuItem.className}>
                  {isActive(menuItem.subItems?.map((sub: any) => sub.to))
                    ? menuItem.activeIcon
                    : menuItem.icon}
                </span>
                {!isCollapsed && <span>{menuItem.label}</span>}
              </div>

              {!isCollapsed && (
                <div
                  className={cx(
                    "transition-transform",
                    dropdownOpen ? "rotate-90" : ""
                  )}
                >
                  <UnionIcon />
                </div>
              )}
            </div>

            {dropdownOpen && (
              <ul className="pl-8">
                {menuItem.subItems?.map((subItem: any) => (
                  <li key={subItem.label} className="mt-2">
                    <Link
                      to={subItem.to}
                      className={` block p-2 rounded-xl ${
                        isActive(subItem.to)
                          ? "bg-[#BBDDFC] text-[#0D3C6D]"
                          : "hover:bg-[#1A5F91]"
                      }`}
                    >
                      {subItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          // For single menu items
          <Link
            to={menuItem.to!}
            className={cx(
              `flex items-center gap-3 rounded-xl ${
                isCollapsed ? "p-3" : "px-3 py-[10px]"
              }`,
              isActive(menuItem.to)
                ? "bg-[#BBDDFC] text-[#0D3C6D]"
                : "hover:bg-[#1A5F91]"
            )}
          >
            <span className={menuItem.className}>
              {isActive(menuItem.to) ? menuItem.activeIcon : menuItem.icon}
            </span>
            {!isCollapsed && (
              <span className="font-medium leading-6">{menuItem.label}</span>
            )}
          </Link>
        )}
      </>
    );
  };

  return (
    <>
      <div
        aria-label="Toggle Sidebar"
        onMouseEnter={() => setIsCollapsed(false)}
        className={cx("bg-[#0D3C6D] hidden lg:block relative ", {
          "w-[100px] px-7": isCollapsed,
          "w-[280px] px-4 transition-transform": !isCollapsed,
        })}
      >
        {isCollapsed ? (
          <div className="py-[18px]">
            <div className="rounded-[8px] bg-[#044E97] p-[2.75px]">
              <EsemieLogo />
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between py-6">
            <LogoWhite />
            <span
              data-testid="collapse-button"
              onClick={toggleSidebar}
              className="p-2 rounded-lg bg-[#0469C5] cursor-pointer"
            >
              <ToggleBar />
            </span>
          </div>
        )}

        <nav className="mt-[1.5em] w-full text-white text-[16px]">
          <ul className="w-full space-y-3">
            {menuItems?.map((menuItem) => (
              <li key={menuItem.label}>{renderMenuItem(menuItem)}</li>
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default SideMenu;
