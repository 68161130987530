import React, { useEffect, useState } from "react";
import { ICreateSalesData, ISetReminder } from "../../../../@types";
import { useUserStore } from "../../../../state";
import InvoiceReminderModal from "./AutomateModal";
import { useCompanyStore } from "../../../../state/useCompanyStore";

interface ReminderProps {
  selectedReminders: ISetReminder[];
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
}

const EnableReminder: React.FC<ReminderProps> = ({
  selectedReminders,
  formData,
  setFormData,
}) => {
  const { setUser, reminder } = useCompanyStore();
  const user = useUserStore((state) => state.user);
  const [modalTitle, setModalTitle] = useState("Automate Reminder");
  const [hasReminder, setHasReminder] = useState(user?.hasReminder || false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRemindersState, setSelectedRemindersState] =
    useState<ISetReminder[]>(selectedReminders);
  const [currentReminderType, setCurrentReminderType] = useState<
    "before" | "due" | "after"
  >("before");

  useEffect(() => {
    setHasReminder(user?.hasReminder || false);
  }, [user?.hasReminder]);

  useEffect(() => {
    //@ts-ignore
    if (reminder?.reminders?.length > 0) {
      //@ts-ignore
      setSelectedRemindersState(reminder?.reminders);
    }
  }, [reminder]);

  const toggleReminder = () => {
    setHasReminder((prev: any) => {
      const newState = !prev;
      setUser((prevUser: any) => ({ ...prevUser, hasReminder: newState }));
      setIsModalVisible(newState);
      return newState;
    });
  };

  const handleSaveReminder = (reminderData: ISetReminder) => {
    const updatedReminders = selectedRemindersState.map((reminder) =>
      reminder.remindBy === reminderData.remindBy
        ? { ...reminder, ...reminderData }
        : reminder
    );

    setSelectedRemindersState(updatedReminders);

    setFormData((prevFormData) => ({
      ...prevFormData,
      reminderSettings: updatedReminders,
    }));

    setIsModalVisible(false);
  };

  const renderReminderRow = (
    label: string,
    remindBy: "before" | "due" | "after"
  ) => {
    const reminderItem = user?.reminders?.find(
      (r: { remindBy: string }) => r.remindBy === remindBy
    );
    const days = reminderItem?.days ?? 0;

    return (
      <div key={remindBy} className="flex items-center gap-2 text-[#044E97]">
        <span>{label}</span>
        <div className="text-sm text-[#4E4E4E]">
          <span>
            {days} days{" "}
            {remindBy === "before"
              ? "before due date"
              : remindBy === "due"
              ? "on due date"
              : "after due date"}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="mt-[1em] md:max-w-[480px] gap-[2em] flex flex-col pr-[3em]">
      <div className="border border-gray-300">
        <div className="flex items-center justify-between gap-[3em] border border-gray-300 py-2 px-[1em] rounded-md mr-[em]">
          <span className="lg:text-[14px] text-sm text-gray-700">
            {hasReminder
              ? "Disable Automated Reminders"
              : "Enable Automated Reminders"}
          </span>

          <div
            onClick={toggleReminder}
            className={`w-12 h-6 p-1 rounded-[69.33px] flex items-center transition duration-200 ease-in cursor-pointer ${
              hasReminder
                ? "bg-[#044E97] justify-end border-[#044E97]"
                : "bg-[#ffffff] justify-start border border-gray-300"
            }`}
            style={{ borderWidth: "1px" }}
          >
            <div
              className={`w-4 h-4 rounded-full shadow-md transition-transform duration-200 ease-in ${
                hasReminder ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
              }`}
            ></div>
          </div>
        </div>
        {hasReminder && (
          <div className="flex justify-between">
            <div className="flex flex-col mt-[1em] text-[10px] lg:text-[14px] pt-2 pl-[1em] mb-2">
              {renderReminderRow("Reminder - 1", "before")}
              <div className="mb-2" />
              {renderReminderRow("Reminder - 2", "due")}
              <div className="mb-2" />
              {renderReminderRow("Reminder - 3", "after")}
            </div>
          </div>
        )}
        {hasReminder && (
          <div
            className=" text-[#044E97] cursor-pointer justify-end items-end flex text-right mt-[-1.5em] pr-2"
            onClick={() => {
              setModalTitle("Edit Reminder");
              setIsModalVisible(true);
            }}
          >
            Edit
          </div>
        )}
      </div>

      {/* Modal for editing the reminder */}
      {isModalVisible && (
        <InvoiceReminderModal
          setShowSendModal={setIsModalVisible}
          onSaveReminder={handleSaveReminder}
          reminderType={currentReminderType}
          days={
            selectedRemindersState.find(
              (reminder) => reminder.remindBy === currentReminderType
            )?.days || 0
          }
          title={modalTitle}
        />
      )}
    </div>
  );
};

export default EnableReminder;
