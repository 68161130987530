import React from "react";
import { CloseIcon, PDFIcon } from "../../assets/icons";
import FileUploadComponent from "./FileUploadComponent";
import { shortenFileName } from "../../utils";
import InfoIcon from "../../assets/icons/InfoIcon";
import { Button } from "../common";
import { fileToBase64 } from "../../utils/fileToBase64";
import { AttachExpenseReceipt } from "../../backend-services";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import { Navigate, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

interface UploadExpenseReceiptProps {
  expenseId: string;
  closeModal: (close: boolean) => void;
}

const UploadExpenseReceipt: React.FC<UploadExpenseReceiptProps> = ({
  expenseId,
  closeModal,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("expenses");
  const [file, setFile] = React.useState<File | null>();
  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);

  const handleUpload = async (uploadedFile: File) => {
    setFile(uploadedFile);
    setError(undefined);
  };

  const handleAttachExpenseReceipt = async () => {
    if (!file) {
      return setError("Please upload receipt.");
    }
    setIsLoading(true);
    try {
      const base64 = await fileToBase64(file);
      const response = await AttachExpenseReceipt({
        image: base64,
        id: expenseId,
      });
      closeModal(false);
      setIsLoading(false);
      toast.success("Upload Successful!");
      navigate("/expenses");
    } catch (error) {
      setIsLoading(false);
      throw new Error(`${error}`);
    }
  };

  return (
    <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-end md:items-center justify-center">
      <div
        className={cx(
          "overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[538px] p-6 bg-white",
          "rounded-t-[16px] rounded-b-[16px] shadow-md" // Added rounded corners for both top and bottom
        )}
      >
        <div className="flex justify-between">
          <h3 className="text-[24px] font-semibold">
            {t("budget.attachReceipt")}
          </h3>
          <div
            className="cursor-pointer flex justify-center items-center w-11 h-11 "
            onClick={() => closeModal(false)}
          >
            <CloseIcon />
          </div>
        </div>

        {error ? (
          <div className="text-center p-2 bg-red-300 text-red-700 font-medium mb-4">
            {error}
          </div>
        ) : null}

        <div className="flex justify-center mt-6 bg-[#F9FAFB] w-full h-[120px] text-center">
          <FileUploadComponent onUpload={handleUpload} />
        </div>

        {file && (
          <div className="flex items-center justify-between text-lg mt-3">
            <div className="flex justify-start">
              <PDFIcon />
              <span className="text-[#0500F9] mx-3 font-medium">
                {shortenFileName(file)}
              </span>
            </div>
            <a href="#close" onClick={() => setFile(undefined)}>
              <CloseIcon color="red" />
            </a>
          </div>
        )}

        <div className="flex justify-center items-center mt-10">
          <Button
            onClick={handleAttachExpenseReceipt}
            variant="secondary"
            text={"Upload Receipt"}
            isLoading={isLoading}
            disabled={isLoading}
            size="medium"
            outline={true}
          />
        </div>
      </div>
    </div>
  );
};

export { UploadExpenseReceipt };
