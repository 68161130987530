import React, { useState, useEffect } from "react";
import AddModal from "../modal/AddModal";
import { Button, SearchInput } from "../../../common";
import SelectedMarkIcon from "../../../../assets/icons/SelectedIcon";
import ExchangeRateIcon from "../../../../assets/icons/ExchangeRateIcon";
import { IInventory, Variation } from "../../../../@types";

interface ViewSelectItemsProps {
  selectedInventory: IInventory | null;
  quantities: { [key: string]: string };
  closeModal: () => void;
  // onAddItem: (itemData: { selectedInventory: IInventory | null; quantities: { [key: string]: string }; total: number }) => void;
  // onViewSelection: (inventory: IInventory) => void;
}

const ViewSelectItems: React.FC<ViewSelectItemsProps> = ({
  selectedInventory,
  quantities,
  closeModal,
}) => {
  const [variation, setVariation] = useState<Variation | null>(null);
  const [quantityEntered, setQuantityEntered] = useState<number>(0);
  const [rate, setRate] = useState<number>(selectedInventory?.rate || 0);
  const [selectedItems, setSelectedItems] = useState<IInventory[]>([]);

  useEffect(() => {
    console.log("Props received in ViewSelectItems:", {
      selectedInventory,
      quantities,
    });
  }, [selectedInventory, quantities]);

  useEffect(() => {
    if (selectedInventory) {
      const defaultVariation = selectedInventory.variations
        ? selectedInventory.variations[0]
        : null;
      setVariation(defaultVariation);
      const quantityId = defaultVariation
        ? defaultVariation.id
        : selectedInventory.id;
      //@ts-ignore
      setQuantityEntered(Number(quantities[quantityId] || 1));
      const rateToSet = defaultVariation?.price || selectedInventory.rate || 0;
      setRate(rateToSet);
    }
  }, [selectedInventory, quantities]);

  useEffect(() => {
    if (variation) {
      setRate(variation.price || 0);
    }
  }, [variation]);

  const hasVariation = variation != null;

  // const handleAddItem = () => {
  //   const total = Object.keys(quantities).reduce((acc, key) => {
  //     const quantity = parseInt(quantities[key], 10) || 0;
  //     const price =
  //       selectedInventory?.variations?.find((variation) => variation.id === key)?.price ||
  //       selectedInventory?.rate ||
  //       0;
  //     return acc + quantity * price;
  //   }, 0);

  //   onAddItem({
  //     selectedInventory,
  //     quantities,
  //     total,
  //   });
  // };
  // const handleItemClick = (inventory: IInventory) => {
  //   onViewSelection(inventory); // Send the data up
  // };

  const handleAddItem = () => {
    // Handle adding the item logic
    const total = Object.keys(quantities).reduce((acc, key) => {
      const quantity = parseInt(quantities[key], 10) || 0;
      const price =
        selectedInventory?.variations?.find((variation) => variation.id === key)
          ?.price ||
        selectedInventory?.rate ||
        0;
      return acc + quantity * price;
    }, 0);

    // Trigger parent to handle the item addition (pass data back)
    // Example: onAddItem({ selectedInventory, quantities, total });

    if (selectedInventory) {
      setSelectedItems((prev) => [...prev, selectedInventory]);
      closeModal();
    }
  };

  return (
    <AddModal>
      <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-5 pb-2">
            <div>
              <svg
                className="mt-[-2px] cursor-pointer"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </div>

            <div className="w-full flex justify-center">
              <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                Select Item
              </p>
            </div>
          </div>

          <SearchInput
            placeholder="Search"
            data={[]}
            isLoading={false}
            displayKey="name"
            emptyMessage={"This customer is not found"}
            classNames="w-full border border-gray-300 min-h-[48px] placeholder:text-[16px] rounded-lg focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal !pl-[8px]"
            dropdownStyle={{
              maxHeight: "800px",
              overflowY: "auto",
            }}
            searchKey={"name"}
            icon={<></>}
          />

          {/* Conditional rendering based on whether there are variations or not */}
          {hasVariation ? (
            <div className="p-1 rounded-lg bg-gray-100 space-y-1 mt-5">
              <div className="px-1 py-[6px] flex justify-between items-center">
                <p className="text-[#000] font-semibold leading-5">
                  {selectedInventory?.name}
                </p>
                <div className="flex gap-3 items-center">
                  <p className="font-semibold text-[#044E97] leading-5">Edit</p>
                  <SelectedMarkIcon />
                </div>
              </div>
              <div className="p-2 bg-[#FFFFFF] rounded-md flex justify-between items-center">
                <div className="flex gap-3 items-center">
                  <ExchangeRateIcon color="#98A2B3" />
                  <div className="flex items-center gap-1 text-gray-700 leading-5">
                    <p>{variation?.name}</p>
                    <div className="w-1 h-1 rounded-full bg-gray-400" />
                    <p>{variation?.type}</p>
                    <div className="w-1 h-1 rounded-full bg-gray-400 text-gray-600 " />
                    <p>{quantityEntered}Qty</p>
                  </div>
                </div>
                <p className="font-semibold text-[#000] leading-5">
                  {quantityEntered * rate}
                </p>
              </div>
            </div>
          ) : (
            <div className="p-2 rounded-lg border border-gray-200 flex justify-between items-center mt-3">
              <div className="space-y-1 font-semibold">
                <p className="text-[#000] leading-5">
                  {selectedInventory?.name}
                </p>
                <p className="text-[12px] text-gray-600 leading-4 ">
                  {quantityEntered}Qty
                </p>
              </div>

              <div className="flex gap-3 items-center">
                <p className="font-semibold text-[#000] leading-5 text-[16px]">
                  N{quantityEntered * rate}
                </p>
                <SelectedMarkIcon />
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
          }}
          className="w-full py-5"
        >
          <Button
            variant="secondary"
            disabled={false}
            text={"Add item"}
            size="full"
            font="semibold"
            onClick={handleAddItem}
          />
        </div>
      </div>
    </AddModal>
  );
};

export default ViewSelectItems;
