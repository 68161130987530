import React from "react";
import { CloseIcon } from "../../../assets/icons";
import { editBankAccount } from "../../../backend-services";
import { useUserStore } from "../../../state";
import { BankData } from "../../../types";
import { useTranslation } from "react-i18next";

interface BankAccountModalProps {
  activeBankData: BankData[];
  selectedAccounts: string[];
  setSelectedAccounts: React.Dispatch<React.SetStateAction<string[]>>;
  toggleModal?: () => void;
  onShowCreateBank: () => void;
}

const BankAccountModal: React.FC<BankAccountModalProps> = ({
  activeBankData,
  selectedAccounts,
  setSelectedAccounts,
  toggleModal,
  onShowCreateBank,
}) => {
  const { t } = useTranslation("invoices");
  const user = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const handleCheckboxChange = async (accountNumber: string) => {
    const updatedBankAccounts = activeBankData.map((bank) => ({
      ...bank,
      isActive: bank.accountNumber === accountNumber,
    }));

    setSelectedAccounts([accountNumber]);

    for (const bank of updatedBankAccounts) {
      const accountIndex = activeBankData.findIndex(
        (b) => b.accountNumber === bank.accountNumber,
      );

      const { accountName, accountNumber, bankName, currency, id, isActive } =
        bank;
      const updatedData = {
        accountName,
        accountNumber,
        bankName,
        currency,
        id,
        isActive,
      };

      try {
        await editBankAccount(accountIndex, updatedData);
        setUser({
          ...user,
          bankAccounts: updatedBankAccounts,
        });
      } catch (error) {
        console.error(
          `Error editing bank account ${bank.accountNumber}:`,
          error
        );
      }
    }
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block w-[400px] relative">
        <button
          onClick={toggleModal}
          className="absolute top-2 right-2 shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
          aria-label="Close modal"
        >
          <CloseIcon color="black" />
        </button>
        <h1 className="lg:text-[25px] font-normal">{t("selectBank")}</h1>
        <div className="mt-6">
          {activeBankData.map((bank: BankData, index: number) => (
            <div
              key={index}
              className="flex flex-col ml-[em] whitespace-nowrap lg:text-[14px] mt-2"
            >
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={selectedAccounts.includes(bank.accountNumber)}
                  onChange={() => handleCheckboxChange(bank.accountNumber)}
                />
                <span className="lg:text-[16px]">{bank.bankName}</span>
              </label>
              <span className="lg:text-[13px] text-[#666666] ml-6">
                {bank.accountNumber}
              </span>
              <span className="lg:text-[16px] ml-6">{bank.accountName}</span>
              <hr className="mt-[1em]" />
            </div>
          ))}
        </div>
        <div className="flex justify-start gap-8">
          <button
            className="text-[#044E97] border border-[#044E97] py-3 px-[2.5em] mt-4 rounded-md font-semibold text-sm"
            onClick={toggleModal}
          >
            {t("useSelect")}
          </button>
          <button
            className="text-[white] border bg-[#044E97] p-3 mt-4 rounded-md font-semibold px-[1.5em] text-sm"
            onClick={onShowCreateBank}
          >
            {t("addBankAccount")}
          </button>
        </div>
      </div>

      {/* Mobile */}
      <div className="lg:hidden md:hidden fixed bg-white w-full h-[401px] bottom-0 right-0 left-0 rounded-t-[1em]">
        <button
          onClick={toggleModal}
          className="absolute top-2 right-2 mt-[2em] shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
          aria-label="Close modal"
        >
          <CloseIcon color="black" />
        </button>
        <h1 className="text-[18px] font-normal mt-[2em] pl-[2em]">
          Select Bank Account
        </h1>

        <div className="mt-6 pl-[2em]">
          {activeBankData.map((bank: BankData, index: number) => (
            <div
              key={index}
              className="flex flex-col ml-[1em] whitespace-nowrap lg:text-[14px]"
            >
              <label className="flex items-center space-x-4">
                <input
                  type="checkbox"
                  checked={selectedAccounts.includes(bank.accountNumber)}
                  onChange={() => handleCheckboxChange(bank.accountNumber)}
                />
                <span className="lg:text-[16px]">{bank.bankName}</span>
              </label>
              <span className="lg:text-[13px] text-[#666666] ml-7 mt-1">
                {bank.accountNumber}
              </span>
              <span className="lg:text-[16px] ml-7">{bank.accountName}</span>
              <hr />
            </div>
          ))}
        </div>
        <div className="flex gap-2 mt-4 ml-3 mb-[1em]">
          <button
            className="text-[#044E97] border border-[#044E97] px-[2em] py-3 rounded-md font-semibold text-sm"
            onClick={toggleModal}
          >
            {t("useSelect")}
          </button>
          <button
            className="text-[white] border bg-[#044E97] px-[1.5em] py-3 rounded-md font-semibold text-sm"
            onClick={onShowCreateBank}
          >
            {t("addBankAccount")}
          </button>
        </div>
      </div>
    </>
  );
};

export default BankAccountModal;
