import React, { useState } from "react";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { useUserStore } from "../../../state";
import { BankData } from "../../../types";
import SelectBank from "./bank/SelectBank";
import ErrorComponent from "../../Error";
import { createBankAccount } from "../../../backend-services";
import { useCompanyStore } from "../../../state/useCompanyStore";
import toast from "react-hot-toast";
import BankAccountForm from "../../settings/bank/BankAccountForm";

const BankSection = () => {
  const { user, setUser } = useUserStore();
  const [isOpen, setIsOpen] = useState({ bankForm: false });
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [modalInView, setModalInView] = useState("select_bank");
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const { companyBankAccounts, setCompanyBankAccounts } = useCompanyStore();
  const [createSuccess, setCreateSuccess] = useState(false);

  const bankAccounts = user.bankAccounts ? user.bankAccounts.flat() : [];
  const activeBankData = bankAccounts.filter(
    (bankAccount: BankData) => bankAccount.isActive,
  );

  const displayedBankData =
    selectedAccounts.length > 0
      ? bankAccounts.filter((bank: { accountNumber: string }) =>
          selectedAccounts.includes(bank.accountNumber),
        )
      : activeBankData;

  const handleCreate = async (data: BankData) => {
    try {
      setIsLoading(true);
      const newBankAccount = await createBankAccount({
        ...data,
        currency: "NGN",
        isActive: true,
      });
      setCompanyBankAccounts(newBankAccount?.data);
      setUser({ ...user, bankAccounts: newBankAccount?.data });
      setCreateSuccess(true);
      setModalInView("select_bank");
      setIsLoading(false);
    } catch (error) {
      toast.error("Unable to create Bank Account. Please try again");
    }
    setIsLoading(false);
  };

  const onClose = () => {
    setModalInView("select_bank");
  };

  return (
    <>
      <div className="w-full pt-5  border-gray-200 md:hidden">
        <p className="text-[14px] font-medium leading-5 mb-1">Bank details</p>
        {displayedBankData.map((bank: BankData, index: number) => (
          <div key={index} className="py-2 px-3 my-2 bg-[#F9FAFB]">
            <p className="font-semibold text-[14px] leading-5 mb-1">
              {bank.accountName}
            </p>
            <div className="flex items-center gap-1 font-medium text-[14px] leading-5 text-gray-500">
              <p>{bank.accountNumber}</p>
              <div className="w-1 h-1 rounded-full bg-gray-300" />
              <p>{bank.bankName}</p>
            </div>
          </div>
        ))}
        <button
          onClick={() => setIsSelectOpen(true)}
          className="font-medium py-[10px] text-[16px] leading-6 text-[#0469C5] w-full text-center"
        >
          {bankAccounts.length > 0 ? "Change account" : "Add account"}
        </button>
      </div>

      {/* Desktop */}
      <div className="w-[350px] pt-5 border-gray-200 hidden md:block lg:block">
        <div className="flex gap-[5em] mb-1">
          <p className="text-[16px] font-medium leading-5  whitespace-nowrap">
            Bank details
          </p>
          <button
            onClick={() => setIsSelectOpen(true)}
            className="font-medium  text-[16px] flex items-center gap-1 p-2 rounded bg-[#E0EEFE] text-[#0469C5] "
          >
            <span className="">
              <PlusIcon />{" "}
            </span>{" "}
            {bankAccounts.length > 0 ? "Change account" : "Add account"}
          </button>
        </div>
        {displayedBankData.length === 0 && (
          <div className="py-2 px-3 my-2 bg-[#F9FAFB]">
            <p className="font-semibold text-[14px] leading-5 mb-1 text-[#]">
              Account Name
            </p>
            <div className="flex flex-col items-start  font-medium text-[14px] leading-5 text-gray-500">
              <p>Account Number</p>
              <div className=" rounded-full bg-gray-300" />
              <p>Bank Name</p>
            </div>
          </div>
        )}
        {displayedBankData.map((bank: BankData, index: number) => (
          <div key={index} className="py-2 px-3 my-2 bg-[#F9FAFB]">
            <p className="font-semibold text-[14px] leading-5 mb-1">
              {bank.accountName}
            </p>
            <div className="flex flex-col items-start  font-medium text-[14px] leading-5 text-gray-500">
              <p>{bank.accountNumber}</p>
              <div className=" rounded-full bg-gray-300" />
              <p>{bank.bankName}</p>
            </div>
          </div>
        ))}
      </div>

      {/* SelectBank Modal */}
      {isSelectOpen && modalInView === "select_bank" && (
        <SelectBank
          setOpenSelect={setIsSelectOpen}
          setModalInView={setModalInView}
          selectedAccounts={selectedAccounts}
          setSelectedAccounts={setSelectedAccounts}
        />
      )}
      {isSelectOpen && modalInView === "bank_form" && (
        <BankAccountForm
          initialData={{
            bankName: "",
            accountNumber: "",
            accountName: "",
            isActive: true,
            currency: "NGN",
          }}
          title="Add Bank Account"
          onSubmit={handleCreate}
          onClose={onClose}
          isLoading={isLoading}
          fromToDos={true}
        />
      )}
    </>
  );
};

export default BankSection;
