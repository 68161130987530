import { updateRC } from "../backend-services";

export const verifyCode = async (debouncedCode: string) => {
  let resultMessage = "Registration code verification failed";
  try {
    const result = await updateRC(debouncedCode);

    if (result.message === "Company is already verified") {
      resultMessage = "Company is already verified";
    } else if (result.message === "Registration code verified") {
      resultMessage = "Registration code verified";
    } else if (result.message === "Company already exists") {
      resultMessage = "Company already exists";
    }
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;
      if (status === 200) {
        resultMessage = "Registration code verified";
      } else if (data?.message) {
        resultMessage =
          data.message === "Company is already verified"
            ? "Company is already verified"
            : data.message === "Company already exists"
            ? "Company already exists"
            : "Registration code verification failed";
      } else {
        resultMessage = "Registration code verification failed";
      }
    } else {
      resultMessage = "Registration code verification failed";
    }
  }
  return resultMessage;
};
