import { toast } from "react-hot-toast";
import { profileService } from "../services";
import {
  ICreateBudget,
  ICreateProfile,
  ICreateSupport,
  ISetReminder,
  IUpdateProfile,
} from "../@types";
import moment from "moment";
import { handleError, withRetry } from "../utils";
import { BankData } from "../types";

const PATH = `profile`;
const BASE = `companies`;

export const createProfile = async (data: ICreateProfile) => {
  return withRetry(async () => {
    const res = await profileService.post("/companies", data);
    return res.data;
  }).catch(handleError);
};

export const getProfile = async () => {
  return withRetry(async () => {
    const res = await profileService.get(`/${BASE}/${PATH}`);
    return res.data;
  }).catch(handleError);
};

export const getInvoiceStats = async (start: string, end: string) => {
  let link = `/${BASE}/invoice-stats`;
  if (start) {
    link = `/${BASE}/invoice-stats?start=${start}`;
  }
  if (end) {
    link = `/${BASE}/invoice-stats?end=${end}`;
  }
  if (start && end) {
    link = `/${BASE}/invoice-stats?start=${start}&end=${end}`;
  }
  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const getInventoryStats = async (start?: string, end?: string) => {
  let link = `/${BASE}/inventory-stats`;
  if (start) {
    link = `/${BASE}/inventory-stats?start=${start}`;
  }
  if (end) {
    link = `/${BASE}/inventory-stats?end=${end}`;
  }
  if (start && end) {
    link = `/${BASE}/inventory-stats?start=${start}&end=${end}`;
  }
  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const getCustomerStats = async (start?: string, end?: string) => {
  let link = `/${BASE}/customer-stats`;
  if (start) {
    link = `/${BASE}/customer-stats?start=${start}`;
  }
  if (end) {
    link = `/${BASE}/customer-stats?end=${end}`;
  }
  if (start && end) {
    link = `/${BASE}/customer-stats?start=${start}&end=${end}`;
  }
  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const updateProfile = async (data: IUpdateProfile) => {
  return withRetry(async () => {
    const res = await profileService.patch(`/${BASE}/${PATH}`, data);
    return res.data;
  }).catch(handleError);
};

export const getExpenseStats = async (start?: string, end?: string) => {
  const currentDate = moment();
  const defaultStart = moment(currentDate)
    .startOf("month")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const defaultEnd = moment(currentDate)
    .endOf("month")
    .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  let link = `/${BASE}/expenses-stats`;
  if (start) {
    link = `/${BASE}/expenses-stats?start=${start}`;
  } else {
    link = `/${BASE}/expenses-stats?start=${defaultStart}`;
  }
  if (end) {
    link = `${link}&end=${end}`;
  } else {
    link = `${link}&end=${defaultEnd}`;
  }

  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const getCompanyStats = async (start: string, end: string) => {
  let link = `/${BASE}/stats`;
  if (start) {
    link = `/${BASE}/stats?start=${start}`;
  }
  if (end) {
    link = `/${BASE}/stats?end=${end}`;
  }
  if (start && end) {
    link = `/${BASE}/stats?start=${start}&end=${end}`;
  }
  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const getBudgetHistory = async (
  startMonthYear?: string,
  endMonthYear?: string
) => {
  let link = "/budgets";

  if (startMonthYear && endMonthYear) {
    link += `?startMonthYear=${startMonthYear}&endMonthYear=${endMonthYear}`;
  } else if (startMonthYear) {
    link += `?startMonthYear=${startMonthYear}`;
  } else if (endMonthYear) {
    link += `?endMonthYear=${endMonthYear}`;
  }

  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const updateUserSettings = async ({
  firstLogin,
}: {
  firstLogin: boolean;
}) => {
  const settings = {
    firstLogin: firstLogin,
  };

  return withRetry(async () => {
    const res = await profileService.patch(`/${BASE}/settings`, settings);
    return res.data;
  }).catch(handleError);
};

export const createBudget = async (data: {
  monthYear: string;
  allocatedBudget: number;
}) => {
  return withRetry(async () => {
    const res = await profileService.post("/budgets", data);
    return res.data;
  }).catch(handleError);
};

export const updateBudget = async (data: {
  monthYear: string;
  allocatedBudget: number;
}) => {
  return withRetry(async () => {
    const res = await profileService.put(`/budgets`, data);
    return res.data;
  }).catch(handleError);
};

export const createSupport = async (data: ICreateSupport) => {
  return withRetry(async () => {
    const res = await profileService.post("/support", data);
    return res.data;
  }).catch(handleError);
};

export const getServiceStats = async (start: string, end: string) => {
  let link = `/${BASE}/service-stats`;
  if (start) {
    link = `/${BASE}/service-stats?start=${start}`;
  }
  if (end) {
    link = `/${BASE}/service-stats?end=${end}`;
  }
  if (start && end) {
    link = `/${BASE}/service-stats?start=${start}&end=${end}`;
  }
  return withRetry(async () => {
    const res = await profileService.get(link);
    return res.data;
  }).catch(handleError);
};

export const getProfitReport = async (
  startDate: string,
  endDate: string,
  downloadType: "csv" | "pdf"
) => {
  return withRetry(async () => {
    const res = await profileService.get(
      `/${BASE}/profit-loss-report?startDate=${startDate}&endDate=${endDate}&downloadType=${downloadType}`
    );
    return res.data;
  }).catch(handleError);
};

export const updateRC = async (rcNumber: string) => {
  return withRetry(async () => {
    const payload = { rcnumber: rcNumber };
    const res = await profileService.patch(`/${BASE}/verify`, payload);
    return res.data;
  }).catch((error: any) => {
    throw new Error(error?.message || error.response?.data);
  });
};

export const setReminders = async (data: ISetReminder) => {
  return withRetry(async () => {
    const res = await profileService.post("/companies/set-reminder", data);
    return res.data;
  }).catch(handleError);
};

export const getMostBookedServices = async () => {
  return withRetry(async () => {
    const res = await profileService.get(`/${BASE}/most-booked-services`);
    return res.data;
  }).catch((error: any) => {
    throw new Error(error?.message || error.response?.data);
  });
};

export const getTopSellingProducts = async () => {
  return withRetry(async () => {
    const res = await profileService.get(`/${BASE}/top-selling-products`);
    return res.data;
  }).catch((error: any) => {
    throw new Error(error?.message || error.response?.data);
  });
};
