import "intl";
import "intl/locale-data/jsonp/en";

export const formatMoney = (
  amount: string | number | null | undefined,
  currencyCode: string = "NGN",
  raw?: boolean,
) => {
  const parsedAmount =
    amount === null || amount === undefined
      ? 0
      : typeof amount === "string"
        ? Number(amount)
        : amount;
  const fixedAmount = isNaN(parsedAmount) ? 0 : parsedAmount.toFixed(2);
  const parts = fixedAmount.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const currency = `${currencyCode} ${parts.join(".")}`;

  if (raw) {
    return currency;
  }

  let currencySymbol;
  switch (currencyCode) {
    case "N":
      currencySymbol = "N";
      break;
    case "NGN":
      currencySymbol = "\u20A6";
      break;
    case "EUR":
      currencySymbol = "€";
      break;
    case "GBP":
      currencySymbol = "£";
      break;
    case "USD":
      currencySymbol = "$";
      break;
    default:
      currencySymbol = currencyCode;
  }

  const formattedCurrency = `${currencySymbol}${parts.join(".")}`;

  return formattedCurrency.replace(currencyCode, currencySymbol);
};
export const formatCurrencyMoney = (
  amount: string | number | null | undefined,
  exchangeRate: number = 1,
  currencyCode: string = "NGN",
  raw?: boolean,
) => {
  // Parse the amount into a number
  const parsedAmount =
    amount === null || amount === undefined
      ? 0
      : typeof amount === "string"
        ? Number(amount)
        : amount;
  const rate = exchangeRate == 0 ? 1 : exchangeRate;
  // Handle invalid amounts and apply exchange rate
  const adjustedAmount = isNaN(parsedAmount) ? 0 : parsedAmount / rate;

  // Format the amount with two decimal places
  const fixedAmount = adjustedAmount.toFixed(2);

  // Add commas to separate thousands
  const parts = fixedAmount.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Construct the currency string
  const currency = `${currencyCode} ${parts.join(".")}`;

  // If raw output is requested, return the currency string directly
  if (raw) {
    return currency;
  }

  // Define currency symbols for common codes
  let currencySymbol;
  switch (currencyCode) {
    case "N":
      currencySymbol = "N";
      break;
    case "NGN":
      currencySymbol = "\u20A6"; // Nigerian Naira symbol
      break;
    case "EUR":
      currencySymbol = "€"; // Euro symbol
      break;
    case "GBP":
      currencySymbol = "£"; // British Pound symbol
      break;
    case "USD":
      currencySymbol = "$"; // US Dollar symbol
      break;
    default:
      currencySymbol = currencyCode; // Fallback to the currency code
  }

  // Format the final currency string with the symbol
  const formattedCurrency = `${currencySymbol}${parts.join(".")}`;

  return formattedCurrency;
};

export const formatValue = (value: number) => {
  return value === 0 ? "00" : value.toString();
};
