import { Link } from "react-router-dom";
import { IInvoice } from "../@types";
import {
  getStatusClass,
  getStatusColor,
} from "../components/Sales/invoice/Status/status";
import { Statuses } from "../constants";
import { getInvoiceReoccur, getInvoiceStatus } from "./invoice";
import { formatMoney } from "./format";
import dayjs from "dayjs";

export const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return day + "th";
  }

  switch (day % 10) {
    case 1:
      return day + "st";
    case 2:
      return day + "nd";
    case 3:
      return day + "rd";
    default:
      return day + "th";
  }
};

export const columns = [
  {
    key: "invoiceNumbers",
    label: "Invoice",
    width: 8,
  },
  {
    key: "customer",
    label: "Customer",
    width: 26,
  },
  {
    key: "isReoccurring",
    label: "Type",

    width: 15,
  },
  {
    key: "issueDate",
    label: "Issue Date",

    width: 15,
  },
  {
    key: "status",
    label: "Status",

    width: 21,
  },
  {
    key: "inventories",
    label: "Balance Due",
    width: 15,
  },
];

export const renderInvoicesData = (
  invoices: IInvoice[],
  isReoccurring: boolean | null
) => {
  return invoices
    .filter((invoice) =>
      isReoccurring !== null ? invoice.isReoccurring === isReoccurring : true
    )
    .map((invoice, index) => {
      const statusCapitalized =
        invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1);
      const isDraft = statusCapitalized === Statuses.DRAFT;
      const isFreeform =
        invoice.invoiceType === "freeform" || !invoice.invoiceType;
      const linkTo = isDraft
        ? isFreeform
          ? `/invoices/freeform/${invoice.id}`
          : `/invoices/${invoice.id}`
        : `/invoices/${invoice.id}/view`;
      const linkText = invoice.invoiceNumber;
      const statusClass = getStatusClass(statusCapitalized);
      const statusColor = getStatusColor(statusCapitalized);

      return {
        invoiceNumbers: (
          <Link className="md:text-violet-800" to={linkTo}>
            {linkText}
          </Link>
        ),
        customer: (
          <Link
            className="md:text-violet-800"
            to={`/customers/${invoice.customer.id}/view`}
          >
            {`${invoice.customer.firstName} ${invoice.customer.lastName}`}
          </Link>
        ),
        issueDate: `${dayjs(invoice.issueDate).format("MMM")} ${getDaySuffix(
          dayjs(invoice.issueDate).date()
        )} ${dayjs(invoice.issueDate).format("YYYY")}`,
        status: (
          <div
            className={`${statusClass} w-fit px-2 py-1 rounded-[4px] font-medium`}
            style={{ backgroundColor: statusColor }}
          >
            <Link className="block" to={linkTo}>
              {getInvoiceStatus(statusCapitalized)}
            </Link>
          </div>
        ),
        isReoccurring: getInvoiceReoccur(invoice.isReoccurring),
        inventories: formatMoney(invoice.balanceDue, invoice.currency),
        invoiceId: invoice.id,
        invoiceNumber: invoice.invoiceNumber,
      };
    });
};
