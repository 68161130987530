import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import * as UI from "../common";
import { InputComponent } from "../core/Forms";
import { ValidateFormSubmit, validatePhoneNumber } from "../../utils";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import PrivacyPolicy from "./PrivacyPolicy";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { useTranslation } from "react-i18next";
import TagManager from "react-gtm-module";
import TermsConditions from "./TermsConditions";
import { UseAuthContext } from "../../context/useAuthContext";
import PhoneInputComponent from "../common/PhoneInputComponent";

interface RegisterViewProps {
  handleTabChange: (auth: boolean) => void;
}

interface FormData {
  email: string;
  createPassword: string;
  businessName: string;
  phone:string;
}

interface FormErrors {
  email?: string;
  createPassword?: string;
  businessName?: string;
  phone?: string;
}

const RegisterView = ({ handleTabChange }: RegisterViewProps) => {
  const { t } = useTranslation("onboarding");

  const { register, googleLogin, error, loading } = useContext(UseAuthContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const errorRef = useRef<HTMLDivElement>(null);

  const handleShowPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const [formData, setFormData] = useState({
    email: "",
    createPassword: "",
    businessName: "",
    phone: "",
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [validationResults, setValidationResults] = useState({
    email: "",
    createPassword: "",
    businessName: "",
  });

  useEffect(() => {
    setMessage(error ?? "");
    if (
      error &&
      errorRef.current &&
      typeof errorRef.current.scrollIntoView === "function"
    ) {
      errorRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name in formData) {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value.trim(),
        }));

        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: undefined,
        }));

        setValidationResults((prevResults) => ({
          ...prevResults,
          [name]: "",
        }));

        setMessage("");
      }
    },
    [formData]
  );

  const handleSignUp = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const errorFound = ValidateFormSubmit(
        formData,
        setValidationResults,
        setMessage
      );

      if (errorFound) {
        setMessage("Fill the required details.");
        return;
      }
      const { email, createPassword, businessName, phone } = formData;

      const attributes: CognitoUserAttribute[] = [
        new CognitoUserAttribute({ Name: "email", Value: email }),
        new CognitoUserAttribute({
          Name: "custom:custom_business_name",
          Value: businessName,
        }),
        new CognitoUserAttribute({
          Name: "custom:custom_createCompany",
          Value: "true",
        }),
        new CognitoUserAttribute({
          Name: "custom:custom_groupName",
          Value: "Admin",
        }),
        new CognitoUserAttribute({ Name: "phone_number", Value: phone }),

      ];

      try {
        await register(email, createPassword, attributes);
        // Track signup completion event with Google Tag Manager
        TagManager.dataLayer({
          dataLayer: [
            {
              event: "signup_completed",
              category: "Signup",
              action: "Click",
              label: "New User Signup",
            },
          ],
        });
        navigate(`/confirm-account?email=${formData.email}`);
      } catch (err: any) {
        if (err.name === "UsernameExistsException") {
          setMessage(
            "This email address is already registered. Please use a different email."
          );
          return;
        }

        setMessage(
          err.message || "An unexpected error occurred during sign-up."
        );
      }
    },
    [formData, navigate, register]
  );

  const handleGoogleSignInClick = () => {
    googleLogin();
  };

  const handlePhoneNumberChange = (value: string) => {
    const normalizedValue = value.replace(/\D/g, ""); // Remove non-numeric characters
    const isValidPhoneNumber = validatePhoneNumber(normalizedValue); // Validate phone number
  
    if (isValidPhoneNumber) {
      setFormData((prevData) => ({
        ...prevData,
        phone: normalizedValue.startsWith("+") ? normalizedValue : `+${normalizedValue}`,
      }));
      setValidationResults((prevResults) => ({ ...prevResults, phone: "" }));
    } else {
      setValidationResults((prevResults) => ({ ...prevResults, phone: "Invalid phone format" }));
    }
  };
  
  return (
    <div className="px-[4.1%] lg:px-0">
      <div className="bg-[#fff] md:w-[434px] mx-auto mt-10 lg:mt-16">
        <div className="mb-6 lg:mb-8 relative">
          <h1 className="text-[20px] lg:text-[28px] font-semibold lg:leading-9 mb-[20px]">
            Sign up to Esemie and take <br className="hidden lg:block" />
            charge <br className="lg:hidden" /> of your business
          </h1>
          <p className="text-[16px] lg:text-[18px] text-[#475467]  mb-[20px]">
            Create an account to get started!
          </p>
          <button
            onClick={handleGoogleSignInClick}
            type="button"
            className="flex justify-center mt-[2em] py-2 px-4 w-full gap-2 rounded-md bg-white border border-[#D0D5DD] mb-[20px]"
          >
            <GoogleIcon />
            <p className="text-[16px] font-medium md:text-[16px] xl:text-[16px] xl:font-medium mt-[0.2em] text-[#212121]">
              Sign up with Google
            </p>
          </button>
          <div className="w-full flex items-center gap-[16px] my-4">
            <div className="w-full border border-[#EAECFO] " />
            <div className="text-[16px] leading-[19.2px] font-medium text-[#475467]">
              Or
            </div>
            <div className="w-full border border-[#EAECFO]" />
          </div>
          <div className="flex justify-center items-center absolute bottom-[-3em] w-full">
            {message && !error && (
              <div className="text-red-600 text-center my-4">{message}</div>
            )}
            {error && (
              <div
                ref={errorRef}
                className="text-red-600  md:text-center text-center my-4"
                role="alert"
              >
                {error}
              </div>
            )}
          </div>
        </div>

        <div>
          <form onSubmit={handleSignUp}>
            <div>
              <div className="text-[16px] text-[#000000] mb-1 mt-[-0.75em]">
                Business Name
              </div>
              <InputComponent
                placeholder="Your business name"
                type="text"
                name="businessName"
                value={formData.businessName}
                handleChange={handleInputChange}
                error={validationResults.businessName}
                className="w-full h-[48px] rounded-md  md:w-[434px] xl:w-[434px]"
              />
            </div>
            <div className="mt-[1em] ">
              <div className="text-[16px] text-[#000000] mb-1">
                {" "}
                {t("onboarding.email")}
              </div>
              <InputComponent
                placeholder="Enter your mail"
                name="email"
                type="text"
                value={formData.email}
                handleChange={handleInputChange}
                error={validationResults.email}
                className="xl:w-[434px] w-full rounded-md md:w-[434px]"
              />
            </div>
            <div className="mt-[1em] ">
              <div className="text-[16px] text-[#000000] mb-1">
                {" "}
               Phone
              </div>
             
              <PhoneInputComponent
  onPhoneNumberChange={handlePhoneNumberChange}
  initialPhoneNumber={formData.phone}
  containerClass="onboarding"
  inputClass="onboarding"
/>

            </div>
            <div className="mt-[1em] ">
              <div className="text-[16px]  text-[#000000] mb-1">
                Create Password
              </div>
              <InputComponent
                placeholder="Create Password"
                name="createPassword"
                type="password"
                value={formData.createPassword}
                handleChange={handleInputChange}
                className="w-full h-[48px] rounded-md md:max-w-[434px]"
                imageClassName="ml-[90%] w-[24px] h-[24px] mt-[-36px]"
                error={validationResults.createPassword}
              />
            </div>

            <div className="w-full mt-[2em] md:mt-[2em] lg:mt-[1.45em]">
              <div className="w-full">
                <UI.Button
                  text="Create an account"
                  variant="secondary"
                  isLoading={loading}
                  disabled={loading}
                />
              </div>
            </div>

            <p className="text-[16px] lg:text-[14px] leading-[13.8px] text-center mt-6">
              Already have an account?{" "}
              <span
                onClick={() => handleTabChange(false)}
                className="text-[#044E97] cursor-pointer font-semibold text-[16px]"
              >
                Login
              </span>
            </p>

            <div className="text-center text-[13px] leading-[18px] mt-10 mb-8 lg:mt-14 lg:mb-[20px]">
              By signing up, you agree to all the{" "}
              <span
                onClick={handleShowModal}
                className="text-[#044E97] font-medium cursor-pointer "
              >
                Terms & <br className="lg:hidden" /> Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={handleShowPolicyModal}
                className="text-[#044E97] font-medium cursor-pointer"
              >
                Privacy Policy
              </span>
            </div>
            {showModal && (
              <TermsConditions
                onClose={() => setShowModal(false)}
                setShowModal={setShowModal}
              />
            )}
            {showPolicyModal && (
              <PrivacyPolicy
                onClose={() => setShowPolicyModal(false)}
                setShowModal={setShowPolicyModal}
              />
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export { RegisterView };
