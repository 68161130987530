import React, { useState } from "react";
import { ICreateSalesData, IService } from "../../../@types";
import { ServiceData } from "../../../@types/service";
import PlusIcon from "../../../assets/icons/PlusIcon";
import AddServiceForm from "./addItem";
import InputServiceItems from "./inputServiceItems";
import SelectService from "./selectService";

interface ServiceLineItemsSectionProps {
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
}

const ServiceFreeformLineItem: React.FC<ServiceLineItemsSectionProps> = ({
  formData,
  setFormData,
}) => {
  const [currentView, setCurrentView] = useState<"AddService" | null>(null);
  const [selectedService, setSelectedService] = useState<null>(null);
  const handleBackToNone = () => setCurrentView(null);

  const handleOpenAddInventory = () => setCurrentView(null);

  const handleSetFormData = (selectedService: IService) => {
    setFormData((prevData) => ({
      ...prevData,
      services: [...(prevData.services || []), selectedService],
    }));
  };
  const handleServiceCreated = (newService: ServiceData) => {
    const service: IService = {
      id: newService.id || "",
      name: newService.name,
      duration: newService.duration,
      price: newService.price,
    };
    setFormData((prevData) => ({
      ...prevData,
      services: [...(prevData.services || []), newService],
    }));
    setCurrentView(null);
  };

  const handleRemoveService = (servicesToRemove: IService[]) => {
    setFormData((prevData) => ({
      ...prevData,
      services:
        prevData.services?.filter(
          (service: { id: string }) =>
            !servicesToRemove.some((item) => item.id === service.id)
        ) || [],
    }));
  };

  const servicesExist =
    formData.services &&
    formData.services.some(
      (service: { id: any; name: any; duration: any; price: any }) =>
        service.id || service.name || service.duration || service.price
    );

  console.log("Form Data:", formData);
  console.log("Services:", formData.services);
  return (
    <div className="md:hidden ">
      {currentView === null && !servicesExist && (
        <>
          <p className="text-[14px] font-medium leading-5 text-[#000000]">
            Invoice items
          </p>
          <button
            onClick={() => setCurrentView("AddService")}
            className="w-full font-medium leading-6 text-gray-700 flex justify-center py-3 my-2 rounded-lg border border-gray-300"
          >
            Add Service
          </button>

          {/* <p
            // onClick={handleOpenAddInven}
            className="text-[14px] font-medium leading-5 text-[#044E97]"
          >
            Add new service
          </p> */}
        </>
      )}

      {servicesExist && (
        <div className="flex flex-col j mb-4">
          <p className="text-[14px] font-medium leading-5 text-[#000000]">
            Invoice items
          </p>
          <div className="mb-4 flex flex-col">
            <p className="text-[16px] font-medium text-[#000000]">
              {formData.services[0]?.name}
            </p>
            {formData.services
              .filter((service: IService) => service.id) // Filter out the empty/default state
              .map(
                (
                  item: {
                    price: any[];
                    name:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | Iterable<React.ReactNode>
                      | null
                      | undefined;
                    duration:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | Iterable<React.ReactNode>
                      | null
                      | undefined;
                  },
                  index: React.Key | null | undefined
                ) => {
                  const price = item.price?.find(
                    (p: { currency: string }) => p.currency === "NGN"
                  );
                  const priceDisplay = price ? `N${price.amount}` : "N/A";

                  return (
                    <div key={index}>
                      <div className="flex justify-between mt-3">
                        <p className="text-[16px] font-semibold text-gray-800">
                          {item.name}
                        </p>
                        <p className="text-[16px] font-semibold text-gray-800">
                          {priceDisplay}
                        </p>
                      </div>
                      <div className="flex justify-between">
                        {/* <Clock/> */}
                        <p className="font-medium text-[14px]">
                          {item.duration} hours
                        </p>
                        <div className="flex gap-2">
                          <button
                            //@ts-ignore
                            onClick={() => handleRemoveService([item])}
                            className="text-[red] text-[16px] font-medium underline cursor-pointer"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }
              )}
          </div>
          <div className="mt-[1em] flex gap-[1em] justify-center items-center text-center border border-[#044E97] bg-[white] rounded-md px-2 py-2">
            <PlusIcon color="#044E97" />
            <button
              className="text-[14px] text-[#044E97]"
              onClick={() => setCurrentView("AddService")}
            >
              Select More Services
            </button>
          </div>
        </div>
      )}

      {currentView === "AddService" && (
        <InputServiceItems
          setIsOpen={handleBackToNone}
          // @ts-ignore
          onCreateSuccess={handleServiceCreated}
        />
      )}
    </div>
  );
};

export default ServiceFreeformLineItem;
