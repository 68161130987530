import React, { useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Link, useLocation } from "react-router-dom";
import {
  CustomerIcon,
  ExpensesIcon,
  HomeIcon,
  InvoiceIcon,
  InventoryIcon,
  ReportIcon,
  CloseIcon,
  ActiveReportIcon,
  ActiveInventoryIcon,
  ActiveCustomerIcon,
  ActiveExpensesIcon,
  ActiveInvoiceIcon,
  ActiveHomeIcon,
} from "../../assets/icons";
import { useUserStore } from "../../state";
import { UnionIcon } from "../../assets/icons/UnionIcon";
import { isAdmin } from "../../utils/isAdmin";
import UserIcon, { ActiveUserIcon } from "../../assets/icons/UserIcon";
import useRetailBusinessStatus from "../../hooks/invoice/useRetailBusinessStatus";
import LogoWhite from "./../LogoWhite";
import ServiceIcon, { ActiveServiceIcon } from "../../assets/icons/ServiceIcon";

interface MobileProps {
  handleShowMobileMenu: () => void;
}

const MobileSideMenu = ({ handleShowMobileMenu }: MobileProps) => {
  const { roles } = useUserStore();
  const isRetailBusiness = useRetailBusinessStatus();
  const { pathname } = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const menuItems = [
    {
      paths: isAdmin(roles) ? ["/dashboard"] : ["/operator-dashboard"],
      label: "Insights",
      icon: <HomeIcon />,
      activeIcon: <ActiveHomeIcon />,
      to: isAdmin(roles) ? "/dashboard" : "/operator-dashboard",
      className: "menu-item-1",
    },
    {
      label: "Sales",
      icon: <InvoiceIcon />,
      activeIcon: <ActiveInvoiceIcon />,
      className: "menu-item-2",
      subItems: [
        {
          paths: ["/invoices", "/invoices/create", "/invoices/:invoiceNo/view"],
          label: "Invoices",
          to: "/invoices",
        },
        {
          paths: ["/quotes", "/quotes/create", "/quotes/:quoteId/view"],
          label: "Quotes",
          to: "/quotes",
        },
      ],
    },
    {
      paths: ["/expenses", "/expenses/create", "/expenses/expenseName/view"],
      label: "Expenses",
      icon: <ExpensesIcon />,
      activeIcon: <ActiveExpensesIcon />,

      to: "/expenses",
      className: "menu-item-3",
    },
    {
      paths: [
        "/customers",
        "/customers/create",
        "/customers/customerName/view",
      ],
      label: "Customers",
      icon: <CustomerIcon />,
      activeIcon: <ActiveCustomerIcon />,

      to: "/customers",
      className: "menu-item-4",
    },
    {
      paths: isRetailBusiness ? ["/inventories"] : ["/services"],
      label: isRetailBusiness ? "Inventory" : "Services",
      icon: isRetailBusiness ? <InventoryIcon /> : <ServiceIcon />,
      activeIcon: isRetailBusiness ? (
        <ActiveInventoryIcon />
      ) : (
        <ActiveServiceIcon />
      ),
      to: isRetailBusiness ? "/inventories" : "/services",
      className: "menu-item-5",
    },
    {
      paths: ["/reports"],
      label: "Reports",
      icon: <ReportIcon />,
      activeIcon: <ActiveReportIcon />,
      to: "/reports",
      className: "menu-item-6",
    },
    {
      paths: ["/user-management"],
      label: "Users",
      icon: <UserIcon />,
      activeIcon: <ActiveUserIcon />,
      to: "/user-management",
      className: "menu-item-7",
    },
  ];

  const isActive = (paths: string | string[]) => {
    if (typeof paths === "string") return pathname === paths;
    return paths?.some((path) => pathname.startsWith(path));
  };

  const toggleDropdown = (label: boolean) => {
    setDropdownOpen(label);
  };

  const renderMenuItem = (menuItem: any) => {
    return (
      <>
        {/* For items with submenus */}
        {menuItem.subItems ? (
          <div onMouseLeave={() => toggleDropdown(false)}>
            <div
              className={cx(
                `flex items-center justify-between cursor-pointer rounded-xl px-3 py-[10px]`,
                isActive(menuItem.subItems?.map((sub: any) => sub.to))
                  ? "bg-[#BBDDFC] text-[#0D3C6D]"
                  : "hover:bg-[#1A5F91]"
              )}
              onClick={() => toggleDropdown(!dropdownOpen)}
            >
              <div className="flex items-center gap-3">
                <span>
                  {isActive(menuItem.subItems?.map((sub: any) => sub.to))
                    ? menuItem.activeIcon
                    : menuItem.icon}
                </span>
                <span>{menuItem.label}</span>
              </div>

              <div
                className={cx(
                  "transition-transform",
                  dropdownOpen ? "rotate-90" : ""
                )}
              >
                <UnionIcon />
              </div>
            </div>

            {dropdownOpen && (
              <ul className="pl-8">
                {menuItem.subItems?.map((subItem: any) => (
                  <li key={subItem.label} className="mt-2">
                    <Link
                      to={subItem.to}
                      className={` block p-2 rounded-xl ${
                        isActive(subItem.to)
                          ? "bg-[#BBDDFC] text-[#0D3C6D]"
                          : "hover:bg-[#1A5F91]"
                      }`}
                    >
                      {subItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          // For single menu items
          <Link
            to={menuItem.to!}
            className={cx(
              `flex items-center gap-3 rounded-xl px-3 py-[10px]`,
              isActive(menuItem.to)
                ? "bg-[#BBDDFC] text-[#0D3C6D]"
                : "hover:bg-[#1A5F91]"
            )}
          >
            <span>
              {isActive(menuItem.to) ? menuItem.activeIcon : menuItem.icon}
            </span>
            <span className="font-medium leading-6">{menuItem.label}</span>
          </Link>
        )}
      </>
    );
  };

  return (
    <>
      <aside
        ref={menuRef}
        className={cx(
          "bg-[#0D3C6D] block lg:hidden min-h-screen absolute z-50",
          {
            "w-[250px] px-4": true,
          }
        )}
      >
        <div className="w-full flex flex-col items-center justify-center">
          <div className="flex w-full items-center justify-between py-6">
            <LogoWhite />
            <span className="text-white" onClick={handleShowMobileMenu}>
              <CloseIcon />
            </span>
          </div>
          <nav className="mt-[1.5em] w-full text-white text-[16px]">
            <ul className="space-y-3 w-full">
              {menuItems.map((menuItem) => (
                <li key={menuItem.label}>{renderMenuItem(menuItem)}</li>
              ))}
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default MobileSideMenu;
