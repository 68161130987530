import React, { useEffect, useState } from "react";
import { RestockItem, VariationItem } from "../../../@types/inventory";
import { getInventory, restockInventory } from "../../../backend-services";
import { useInventoryStore } from "../../../state/useInventoryStore";
import { EditInventoryForm } from "../restock/EditInventoryForm";
import { toast } from "react-hot-toast";
import { CloseIcon } from "../../../assets/icons";
import BackArrow from "../../../assets/icons/backArrow";
import { convertToFloat } from "../../../utils/convertToFloat";
import {
  ValidateVariation,
  validateRestock,
} from "../../../modules/inventory/inventory";
import PageLoader from "../../common/PageLoader";
import { useTranslation } from "react-i18next";

interface VariationError {
  type: string;
  costPrice: string;
  price: string;
  quantity: string;
}

interface RestockProps {
  setOpenModal: (show: boolean) => void;
  restockId: string | undefined;
  fetchDetail?: (id: string) => void;
  fetchInventory?: () => void;
}

const Restock = ({
  restockId,
  setOpenModal,
  fetchDetail,
  fetchInventory,
}: RestockProps) => {
  const [inventories, setInventories] = useState<RestockItem>({
    id: restockId,
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    date: "",
  });
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [variationError, setVariationError] = React.useState<VariationError[]>(
    []
  );
  const { t } = useTranslation("inventories");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = React.useState({
    quantity: "",
    rate: "",
    costPrice: "",
  });
  const [oldQuantity, setOldQuantity] = useState(0);
  const [oldVariationQuantity, setOldVariationQuantity] = useState([]);

  const fetchInventoryItem = async (id: string) => {
    setIsLoading(true);
    try {
      const res = await getInventory(id);
      setInventories({
        ...inventories,
        name: res.name,
        rate: res.rate,
        costPrice: res.costPrice,
        quantity: "0",
        date: res.createdAt,
      });
      setOldQuantity(res?.quantity);
      setVariationList(
        res.variations.map((item: any) => ({
          ...item,
          quantity: 0,
        }))
      );
      setOldVariationQuantity(
        res.variations.map((variation: VariationItem) => variation?.quantity)
      );
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to fetch variations");
    }
  };

  const handleRestock = async () => {
    const newRate = convertToFloat(inventories.rate);
    const newQuantity = convertToFloat(inventories.quantity);
    const newCostPrice = convertToFloat(inventories.costPrice);

    const newVariationList = variationList.map((item: any) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    const { hasErrors, errors } = validateRestock({
      rate: newRate,
      quantity: newQuantity,
      costPrice: newCostPrice,
    });
    const variationErrorMsg = ValidateVariation(newVariationList);
    setVariationError([...variationErrorMsg]);

    if (hasErrors && newVariationList.length === 0) {
      setError(errors);
      return;
    }

    if (variationErrorMsg.length === 0) {
      try {
        setIsLoading(true);
        const newInventory = newVariationList.length
          ? {
              variations: newVariationList,
              date: new Date(inventories?.date || "").toISOString(),
            }
          : {
              rate: newRate,
              costPrice: newCostPrice,
              quantity: newQuantity,
              date: new Date(inventories?.date || "").toISOString(),
            };
        if (!restockId) return;
        const response = await restockInventory(restockId, newInventory);
        setIsLoading(false);
        setInventories({
          name: "",
          rate: "",
          quantity: "",
          costPrice: "",
          variations: [],
        });
        setVariationList([]);
        setOpenModal(false);
        fetchDetail?.(restockId);
        if (fetchInventory) fetchInventory();
        toast.success(t("inventory.restockSuccessful"));
      } catch (error: any) {
        if (error.message === "Inventory already exists") {
          toast(error.message);
        } else {
          toast("Unable to restock inventory");
        }
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (restockId) {
      fetchInventoryItem(restockId);
    }
  }, [restockId]);

  const { fetchVariations } = useInventoryStore((state) => ({
    fetchVariations: state.fetchVariation,
  }));

  const { variations } = useInventoryStore((state) => ({
    variations: state.variations,
  }));

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  function showCancelConfirmation() {
    const confirmationMessage =
      "You will be losing your data if you cancel this.";
    const isConfirmed = window.confirm(confirmationMessage);

    if (isConfirmed) {
      setOpenModal(false);
    } else {
    }
  }

  const cx =
    variationList?.length > 0
      ? ""
      : "fixed bottom-12 left-10 right-10 mb-34 flex gap-4 md:relative md:left-0 md:right-0 md:bottom-0";

  return isLoading ? (
    <PageLoader />
  ) : (
    <div className="lg:px-[32px] md:px-[24px] relative">
      <div className="flex items-center justify-between mt-10 py-[7px] px-4">
        <div className="flex items-center w-full md:w-fit gap-4">
          <div className="" onClick={() => setOpenModal(false)}>
            <BackArrow />
          </div>
          <div className="flex justify-center w-full">
            <h1 className="text-[18] md:text-[24px] font-medium md:font-semibold">
              {" "}
              {t("inventory.restock")}
            </h1>
          </div>
        </div>
        <div
          className="hidden md:block cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CloseIcon />
        </div>
      </div>
      <div className="mt-8 px-4 mb-8 space-y-3">
        <h1 className="text-[16px] font-medium">Product</h1>
        <p className="text-[20px] font-medium">{inventories.name}</p>
      </div>
      <EditInventoryForm
        inventory={inventories}
        variations={variationList}
        setVariations={setVariationList}
        setInventories={setInventories}
        variationList={variations}
        error={error}
        setError={setError}
        variationError={variationError}
      />

      <div className="hidden md:block mt-[24px] md:mt-[40px] border border-gray-300 px-[10px] mb-10 md:mb-6"></div>

      <div className="md:mt-6 px-4 md:flex md:justify-end">
        <div className=" l mb-34 flex gap-4 md:relative md:left-0 md:right-0 md:bottom-0">
          <button
            onClick={showCancelConfirmation}
            className="w-full bg-white rounded-[4px] md:w-[205px] text-black border border-[#044E97] text-center h-[48px] font-normal"
          >
            {t("inventory.cancel")}
          </button>
          <button
            onClick={handleRestock}
            className="w-full bg-[#044E97] rounded-[4px] md:w-[205px] text-white border border-[#A4A4A4]  h-[48px] text-center font-normal"
          >
            Save
          </button>
        </div>
      </div>
      <div className="mb-[44px]"></div>
    </div>
  );
};

export default Restock;
