import React, { useEffect, useState } from "react";
import { useNotificationStore } from "../../../../state/useNotificationStore";
import SuccessNotification from "../component/Success";
import ErrorNotification from "../component/Error";
import { EmptyIcon } from "../../../../assets/icons/NotificationsEmptyIcon";
import { NotificationEntity } from "../../../../@types";

const Card = () => {
  const notifications = useNotificationStore((state) => state?.notifications);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const batchUpdateNotifications = useNotificationStore(
    (state) => state?.batchUpdateNotifications
  );

  const markAllAsRead = () => {
    const updatedNotifications = notifications?.map((notification) => ({
      ...notification,
      isRead: true,
    }));
    batchUpdateNotifications(updatedNotifications);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div className="">
      <div className="flex flex-col gap-5">
        {notifications?.length < 1 || !notifications ? (
          <>
            <div className=" flex flex-col items-center justify-center gap-10 ml-[5em] xl:ml-[2em] lg:ml-[1em] md:ml-[1em]">
              <EmptyIcon />
              <div className="flex flex-col items-center justify-center ">
                <h3 className="text-[18px] font-bold">{"You're up to date"}</h3>
                <p>You do not have any new notifications.</p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-row-reverse">
              <p
                onClick={markAllAsRead}
                className="text-blue-900 cursor-pointer"
              >
                <u>Mark all as read</u>
              </p>
            </div>

            {notifications
              ? notifications.map((notification) =>
                  notification?.type === "success" ? (
                    <SuccessNotification
                      key={notification.id}
                      notification={notification}
                      closeDrawer={closeDrawer}
                    />
                  ) : (
                    <ErrorNotification
                      key={notification.id}
                      notification={notification}
                    />
                  )
                )
              : ""}
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
