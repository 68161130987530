import React, { useState } from "react";
import { ICreateSalesData, InventoryItem, ItemInput } from "../../../../@types";
import { ServiceData } from "../../../../@types/service";
import PlusBoxIcon from "../../../../assets/icons/PlusBoxIcon";
import {
  INVOICE_TABLE_HEADER,
  SERVICE_TABLE_HEADER,
} from "../../../../constants/invoice";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { useUserStore } from "../../../../state";
import {
  LineItemTable,
  TableColumn,
} from "../../../common/LineItemTable/InventoryLineItemTable";
import { ServiceLineItemTable } from "../../../common/LineItemTable/ServiceLineItemTable";
import CreateInventoryModal from "../../invoice/inventory/CreateInventoryModal";
import FullScreenOverlay from "../../invoice/inventory/FullScreenModal";
import CreateServiceModal from "../../invoice/services/CreateServiceModal";
import { useTranslation } from "react-i18next";
import { useCompanyStore } from "../../../../state/useCompanyStore";

interface SalesItemDetailsProps {
  formData: ICreateSalesData;
  updatedNewItem?: ItemInput;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  invoiceNo?: string;
  quoteNo?: string;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  selectedCurrencyLabel: string;
  items: ItemInput[];
  addItem: () => void;
  setItems: any;
  removeItem: (index: any) => void;
  updateCell: (
    index: number,
    field: string | number,
    value: string | number,
  ) => void;
  updateRow: (index: number, item: ItemInput) => void;
}

const LineTable: React.FC<SalesItemDetailsProps> = ({
  formData,
  setFormData,
  invoiceNo,
  quoteNo,
  validationResults,
  selectedCurrencyLabel,
  setValidationResults,
  items,
  addItem,
  setItems,
  removeItem,
  updateCell,
  updateRow,
}) => {
  const { t } = useTranslation("invoices");
  const columns: TableColumn[] = INVOICE_TABLE_HEADER;
  const column: TableColumn[] = SERVICE_TABLE_HEADER;
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();
  const [showCreateService, setShowCreateService] = useState(false);
  const [showCreateInventory, setShowCreateInventory] = useState(false);

  const handleClearErrors = () => {
    setValidationResults((prevResults) => ({
      ...prevResults,
      inventories: "",
      services: "",
    }));
  };

  const handleAddInventory = () => {
    setShowCreateInventory(true);
  };

  const handleAddService = () => {
    setShowCreateService(true);
  };

  const handleCreateSuccess = (newItem: ItemInput) => {
    const indexOfEmptyRow = items.findIndex((item) => !item.id);

    if (indexOfEmptyRow !== -1) {
      updateRow(indexOfEmptyRow, newItem);
    } else {
      setItems((prevItems: any) => [...prevItems, newItem]);
    }

    setShowCreateInventory(false);
  };

  const handleCreateServiceSuccess = (newService: ServiceData) => {
    const newItem: ItemInput = {
      id: newService.id || "",
      name: newService.name,
      price:
        newService.price?.length > 0
          ? // @ts-ignore
            parseFloat(newService.price[0].amount)
          : 0,
      duration: newService.duration,
    };

    const indexOfEmptyRow = items.findIndex((item) => !item.id);
    if (indexOfEmptyRow !== -1) {
      updateRow(indexOfEmptyRow, newItem);
    } else {
      setItems((prevItems: any) => [...prevItems, newItem]);
    }

    setShowCreateService(false);
  };

  return (
    <div className="hidden lg:block md:block">
      <div className="lg:hidden md:hidden">
        <FullScreenOverlay
          isOpen={showCreateInventory && isRetail}
          onClose={() => setShowCreateInventory(false)}
        >
          <CreateInventoryModal
            show={showCreateInventory}
            setShowCreateInventory={setShowCreateInventory}
            onCreateSuccess={handleCreateSuccess}
          />
        </FullScreenOverlay>

        <FullScreenOverlay
          isOpen={showCreateService}
          onClose={() => setShowCreateService(false)}
        >
          <CreateServiceModal
            show={false}
            setShowCreateService={setShowCreateService}
            //@ts-ignore
            onCreateSuccess={handleCreateServiceSuccess}
          />
        </FullScreenOverlay>
      </div>
      <div className="hidden lg:block md:block">
        <FullScreenOverlay
          isOpen={showCreateInventory && isRetail}
          onClose={() => setShowCreateInventory(false)}
        >
          <CreateInventoryModal
            show={showCreateInventory}
            setShowCreateInventory={setShowCreateInventory}
            onCreateSuccess={handleCreateSuccess}
          />
        </FullScreenOverlay>

        <FullScreenOverlay
          isOpen={showCreateService}
          onClose={() => setShowCreateService(false)}
        >
          <CreateServiceModal
            show={false}
            setShowCreateService={setShowCreateService}
            //@ts-ignore
            onCreateSuccess={handleCreateServiceSuccess}
          />
        </FullScreenOverlay>
      </div>
      <div className="mt-[2em] relative">
        {isRetail ? (
          <LineItemTable
            columns={columns}
            items={items}
            isSearchInput={true}
            addItem={addItem}
            removeItem={removeItem}
            updateCell={updateCell}
            updateRow={updateRow}
            isImportedToInvoice={false}
            isInvoice={true}
            handleClearErrors={handleClearErrors}
            currency={formData.currency ?? ""}
            //   @ts-ignore
            exchangeRate={formData.exchangeRate}
          />
        ) : (
          <ServiceLineItemTable
            columns={column}
            items={formData.services}
            isSearchInput={true}
            addItem={addItem}
            removeItem={removeItem}
            updateCell={updateCell}
            updateRow={updateRow}
            isImportedToInvoice={false}
            selectedCurrencyLabel={selectedCurrencyLabel}
            handleClearErrors={handleClearErrors}
            isInvoice={true}
            currency={formData.currency ?? ""}
            //   @ts-ignore
            exchangeRate={formData.exchangeRate}
          />
        )}

        <div className="absolute mt-[-3em]">
          {validationResults.inventories && (
            <div className=" ml-4 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
              <span className="text-red-500">
                {validationResults.inventories}
              </span>
            </div>
          )}
          {validationResults.services && (
            <div className="mt-[] ml-4 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
              <span className="text-red-500">{validationResults.services}</span>
            </div>
          )}
        </div>
        <div className=" absolute hidden lg:block md:block xl:mt-[-1em] biglg:mt-[-1em] lg:mt-[-1em]">
          <span
            onClick={isRetail ? handleAddInventory : handleAddService}
            className="flex items-center text-[#044E97] font-medium cursor-pointer w-[180px] py-2 px-[1em] rounded-md ml-[2em]"
          >
            {isRetail ? t("addNewInventory") : t("addNewService")}
          </span>
        </div>

        <div className="absolute mt-[-1em] lg:hidden md:hidden">
          <span
            onClick={isRetail ? handleAddInventory : handleAddService}
            className="flex items-center text-[#044E97] text-[10px] font-medium cursor-pointer border border-[#044E97] w-[120px] py-2 px-[1em] 320:ml-[7em] 375:ml-[3em] 360:ml-[4em] rounded-md"
          >
            {isRetail ? t("addNewInventory") : t("addNewService")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default LineTable;
