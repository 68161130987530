import React, { useState } from "react";
import { ICreateSalesData, ICustomer } from "../../@types";
import { CloseIcon } from "../../assets/icons";
import SelectedMarkIcon from "../../assets/icons/SelectedIcon";
import { InvoiceErrors } from "../../modules/validateInvoiceForm";
import { Button } from "../common";
import PhoneInputComponent from "../common/PhoneInputComponent";
import { InputComponent } from "../core/Forms/InputComponent";
import CommunicationOptions from "./sections/communication";
import MarkAsPaidSection from "./sections/markPaid";
import AddModal from "./sections/modal/AddModal";

interface CreateInvoiceProps {
  onCreateInvoice?: (event: any) => Promise<void>;
  onClose: () => void;
  formData: ICreateSalesData;
  validationResults: InvoiceErrors;
  handleMarkAsPaidChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePaymentDropDownChange: (name: string, value: string) => void;
  markAsPaid: boolean;
  handleSendEmailChange: (value: boolean) => void;
  shouldSendSMS: boolean;
  customer: ICustomer | null;
  sendEmail: boolean;
  handleshouldSendWhatsAppChange: (value: boolean) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleshouldSendSMSChange: (value: boolean) => void;
  isLoading?: boolean;
}

const SendInvoiceModal: React.FC<CreateInvoiceProps> = ({
  onCreateInvoice,
  onClose,
  formData,
  handleMarkAsPaidChange,
  handlePaymentDropDownChange,
  validationResults,
  markAsPaid,
  handleSendEmailChange,
  shouldSendSMS,
  customer,
  sendEmail,
  handleshouldSendWhatsAppChange,
  handleInputChange,
  handleshouldSendSMSChange,
  isLoading,
}) => {
  const isEmailAvailable = customer?.email;
  const isPhoneAvailable = customer?.phone;

  const handleShouldSendEmailChange = (value: boolean) => {
    if (shouldSendSMS) {
      handleshouldSendSMSChange(!shouldSendSMS);
    }
    handleSendEmailChange(value);
  };

  const handleWhatsAppChange = (value: boolean) => {
    handleshouldSendWhatsAppChange(value);
    handleSendEmailChange(!value);
    handleshouldSendSMSChange(!value);
  };

  const handleSMSChange = (value: boolean) => {
    if (sendEmail) {
      handleSendEmailChange(!sendEmail);
    }
    handleshouldSendSMSChange(value);
  };

  const handlePhoneNumberChange = (phoneNumber: string) => {};

  console.log({ isEmailAvailable, isPhoneAvailable, sendEmail, shouldSendSMS });

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <AddModal>
      <div className="mx-auto bg-white rounded-lg p-3 lg:px-1 lg:pt-1">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg lg:text-[20px] font-semibold">Send Invoice</h2>
          <button
            className="text-gray-500 hover:text-gray-800"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Share Options */}
        <div>
          {customer?.phone && (
            <div
              className={`px-[1em] py-2 rounded-md cursor-pointer ${
                shouldSendSMS ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => handleSMSChange(!shouldSendSMS)}
            >
              <div className="flex justify-between items-center">
                <span className="text-[18px] font-medium">Share Via SMS</span>
                {shouldSendSMS ? (
                  <SelectedMarkIcon />
                ) : (
                  <span className="w-5 h-5 rounded-full border-2 border-gray-300"></span>
                )}
              </div>
              {shouldSendSMS && (
                <div className="mt-[0.1em]">
                  <p className="text-[14px] text-gray-600">{customer.phone}</p>
                </div>
              )}
            </div>
          )}

          {customer?.email && (
            <div
              className={`px-[1em] py-2 rounded-md cursor-pointer mt-2 ${
                sendEmail ? "bg-gray-100" : "bg-white"
              }`}
              onClick={() => handleShouldSendEmailChange(!sendEmail)}
            >
              <div className="flex justify-between items-center">
                <span className="text-[18px] font-medium">Share Via Email</span>
                {sendEmail ? (
                  <SelectedMarkIcon />
                ) : (
                  <span className="w-5 h-5 rounded-full border-2 border-gray-300"></span>
                )}
              </div>
              {sendEmail && (
                <div className="mt-[0.1em]">
                  <p className="text-[14px] text-gray-600">{customer.email}</p>
                </div>
              )}
            </div>
          )}
        </div>
        <hr className="mt-[1em] mb-1" />

        {/* Submit Button */}
        <MarkAsPaidSection
          markAsPaid={markAsPaid}
          handleMarkAsPaidChange={handleMarkAsPaidChange}
          formData={formData}
          validationResults={validationResults}
          handlePaymentDropDownChange={handlePaymentDropDownChange}
        />
        <hr className="mt-2" />
        <div className="flex justify-end items-end text-right mt-[1em]">
          <Button
            text="Create Invoice"
            size="medium"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onCreateInvoice}
            variant="secondary"
          />
        </div>
      </div>
    </AddModal>
  );
};

export default SendInvoiceModal;
