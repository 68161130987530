import React from "react";
import { useSubscriptionStore, useUserStore } from "../../state";
import { useSubUserStore } from "../../state/useSubUserStore";
import { getCurrentMonthData } from "../../utils/DateTimeRange";
import { useCompanyStore } from "../../state/useCompanyStore";

const useUserDataLoader = () => {
  const { setUser } = useUserStore();
  const { fetchCompanyProfile, setBudget, setCompanyBankAccounts } =
    useCompanyStore();
  const fetchActiveSubscription = useSubscriptionStore(
    (state) => state.fetchActiveSubscription,
  );
  const fetchUser = useSubUserStore((state) => state.fetchUser);
  const { setSubscriptionData } = useSubscriptionStore();
  const [loading, setLoading] = React.useState(false);
  const [isPasswordModalShown, setIsPasswordModalShown] = React.useState(false);
  const [businessModalVisible, setBusinessModalVisible] = React.useState(false);
  const [error, setError] = React.useState<string | null>();

  const determineModalVisibility = (
    companyProfile: any,
    activeSubscription: any,
    userId: string,
  ) => {
    const user = useUserStore.getState().user;
    const { isPasswordChanged, isRootUser } = useSubUserStore.getState();

    if (!isPasswordChanged && !isRootUser) {
      setIsPasswordModalShown(true);
    } else {
      setIsPasswordModalShown(false);
    }
    if (user && (!user.businessType || user.businessType.trim() === "")) {
      setBusinessModalVisible(true);
    } else {
      setBusinessModalVisible(false);
    }
  };

  const fetchData = async (userId: string) => {
    setLoading(true);
    try {
      const [companyProfile, activeSubscription] = await Promise.all([
        fetchCompanyProfile(),
        fetchUser(userId),
        fetchActiveSubscription(),
      ]);
      const bankAccounts = companyProfile.bankAccounts || [];
      setCompanyBankAccounts(bankAccounts);
      const budget = getCurrentMonthData(companyProfile.budget || []);
      setBudget(budget);
      setUser(companyProfile);
      setSubscriptionData(activeSubscription);

      // Determine modal visibility based on fetched data
      determineModalVisibility(companyProfile, activeSubscription, userId);
      return companyProfile;
    } catch (err: any) {
      setError((err?.message || "An unknown error occurred").toString());
    } finally {
      setLoading(false);
    }
  };
  return {
    fetchData,
    isPasswordModalShown,
    error,
    businessModalVisible,
    loading,
    setBusinessModalVisible,
    setIsPasswordModalShown,
  };
};

export default useUserDataLoader;
