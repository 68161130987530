import React from "react";
import { useTranslation } from "react-i18next";

interface ActionButtonProps {
  label: string;
  onClick: () => void;
}

const generateActionButtons = (
  isRetailBusiness: boolean,
  handleInventoryInvoiceClick: () => void,
  handleFreeformInvoiceClick: () => void
): ActionButtonProps[] => {
  const { t } = useTranslation("invoices");
  return [
    {
      label: isRetailBusiness ? t("withInventory") : "With Service",
      onClick: handleInventoryInvoiceClick,
    },
    {
      label: isRetailBusiness ? t("withoutInventory") : "Without Service",
      onClick: handleFreeformInvoiceClick,
    },
  ];
};
export default generateActionButtons;
