import React from "react";
import { dashboardInitialState, formatLocalDate } from "../utils/dashboard";
import { profileService } from "../services";
import { DashboardStats } from "../@types";

interface useFetchDashboardStatsProps {
  startDate: Date | null;
  endDate: Date | null;
}

export const useFetchDashboardStats = ({
  startDate,
  endDate,
}: useFetchDashboardStatsProps) => {
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [dashboardStats, setDashboardStats] = React.useState<DashboardStats>(
    dashboardInitialState
  );

  const fetchDashboardStats = React.useCallback(
    async (start: Date, end: Date) => {
      setIsLoading(true);
      try {
        const stats = await profileService.get(
          `/companies/stats?limit=${4}&start=${formatLocalDate(
            start
          )}&end=${formatLocalDate(end)}`
        );
        setDashboardStats(stats.data as DashboardStats);
      } catch (error) {
        setError("Error fetching transactions.");
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  React.useEffect(() => {
    if (startDate && endDate) {
      fetchDashboardStats(startDate, endDate);
    }
  }, [startDate, endDate]);

  return {
    dashboardStats,
    error,
    isLoading,
    fetchDashboardStats,
  };
};
