import React, { useEffect, useState } from "react";
import PurchaseTable from "../../purchase/Table";
import { DropdownComponent } from "../../core/Forms";
import { FILTER_LIST } from "../../../constants";
import EmptyPage from "../../../assets/icons/svgicons/EmptyState.svg";
import { RemovedStockTable } from "./Table/RemovedStockTable";
import {
  RestockTablekData,
  removeDataProps,
  VariationTableData,
} from "../../../@types/inventory";
import VariationTable from "./Table/VariationTable";
import {
  filterDataByDuration,
  filterDataByRemoval,
} from "../../../utils/durationFilter";

interface ViewInventoryTableProps {
  variationData: VariationTableData[];
  isLoading: boolean;
  restockData: any;
  removedStockData: removeDataProps[];
  isArchived: boolean;
}

const ViewInventoryTable = ({
  variationData,
  isLoading,
  restockData,
  removedStockData,
  isArchived,
}: ViewInventoryTableProps) => {
  const [selectedVariations, setSelectedVariations] = useState(false);
  const [selectedRestock, setSelectedRestock] = useState(false);
  const [selectedRemoval, setSelectedRemoval] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("Recent");
  const dropDownArray = FILTER_LIST;
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [filteredRestockData, setFilteredRestockData] = useState<
    RestockTablekData[]
  >([]);
  const [filteredStockRemoval, setFilteredStockRemoval] = useState<
    removeDataProps[]
  >([]);

  const handleDropDownChange = (value: string) => {
    let sortValue = "";
    if (value === "Recent") sortValue = "recent";
    if (value === "Last Week") sortValue = "lastweek";
    if (value === "Last Month") sortValue = "lastmonth";
    if (value === "Last Quarter") sortValue = "lastquarter";

    if (selectedRestock) {
      const filteredData = filterDataByDuration(restockData, sortValue);
      setFilteredRestockData(filteredData);
    }
    if (selectedRemoval) {
      const filteredData = filterDataByRemoval(removedStockData, sortValue);
      setFilteredStockRemoval(filteredData);
    }
  };

  const handleSelectedTable = (table: string) => {
    if (table === "variations") {
      setSelectedVariations(true);
      setSelectedRestock(false);
      setSelectedRemoval(false);
    } else if (table === "restock") {
      setSelectedVariations(false);
      setSelectedRestock(true);
      setSelectedRemoval(false);
    }
    if (table === "removal") {
      setSelectedVariations(false);
      setSelectedRestock(false);
      setSelectedRemoval(true);
    }
  };
  const hasVariations = variationData.length > 0;

  useEffect(() => {
    handleSelectedTable("variations");
    handleDropDownChange("Recent");
  }, []);

  // useEffect(() => {
  //   // Automatically select the Restock Table when there are no variations
  //   if (variationData?.length > 0) {
  //     handleSelectedTable('variations');
  //   } else {
  //     handleSelectedTable('restock');
  //   }

  //   handleDropDownChange('Recent');
  // }, [variationData]);

  return (
    <div className="mt-[64px] pr-[1em]">
      <div className=" md:flex items-center justify-between md:mb-[24px]">
        <div className="flex items-center gap-4 md:gap-8 text-[14px] md:text-[18px] font-medium mb-[24px] md:mb-0">
          {/* {selectedVariations && variationData?.length > 0 && ( */}
          <div className="h-[28px]">
            <p
              className={`cursor-pointer ${
                selectedVariations && "text-[#044E97] font-bold"
              }`}
              onClick={() => handleSelectedTable("variations")}
            >
              Variations
            </p>
            {selectedVariations && (
              <div className="border-[2px] border-[#044E97] rounded-t-[4px]" />
            )}
          </div>
          {/* )} */}
          <div className="h-[28px]">
            <p
              className={`cursor-pointer ${
                selectedRestock && "text-[#044E97] font-bold"
              }`}
              onClick={() => handleSelectedTable("restock")}
            >
              Restock Table
            </p>
            {selectedRestock && (
              <div className="border-[2px] border-[#044E97] rounded-t-[4px]" />
            )}
          </div>

          <div className="h-[28px]">
            <p
              className={`cursor-pointer ${
                selectedRemoval && "text-[#044E97] font-bold"
              }`}
              onClick={() => handleSelectedTable("removal")}
            >
              Stock Removal
            </p>
            {selectedRemoval && (
              <div className="border-[2px] border-[#044E97] rounded-t-[4px]" />
            )}
          </div>
        </div>
        {!selectedVariations && (
          <div className="md:block border border-gray-200">
            <DropdownComponent
              options={dropDownArray}
              value={selectedValue}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
              isSubmitted={isSubmitted}
              onFilter={handleDropDownChange}
              dropdownClassName="xl:w-[290px] 2xl:w-[400px] lg:w-[185px] 1024:w-[100px] text-[16px] font-normal text-black w-full"
              iconClassName="xl:mr-[1em] lg:mr-[1em] mr-[12px]"
            />
          </div>
        )}
      </div>
      {selectedVariations && variationData?.length > 0 ? (
        <VariationTable
          data={variationData}
          isLoading={isLoading}
          isArchived={isArchived}
        />
      ) : (
        selectedVariations && (
          <div className="mt-6 space-y-4 font-semibold">
            <div className="w-full bg-[#ffffff] h-[400px] flex flex-col justify-center text-center">
              <div className="w-full flex justify-center">
                <img src={EmptyPage} alt="empty page" />
              </div>
              <div className="mt-8">
                <h1 className="lg:text-[24px] text-[18px] font-medium">
                  This Inventory do not have variations
                </h1>
                <p className="text-[14px]  lg:text-[16px] font-medium ">
                  If an Inventory has variations, you will find all <br /> the
                  details here.
                </p>
              </div>
            </div>
          </div>
        )
      )}
      {/* {selectedVariations && variationData?.length > 0 && (
        <div className="mt-6">
          <VariationTable data={variationData} isLoading={isLoading} isArchived={isArchived}  />
        </div>
      )} */}
      {selectedRestock && restockData?.length > 0 ? (
        <PurchaseTable
          isLoading={isLoading}
          restockData={
            filteredRestockData.length > 0 ? filteredRestockData : restockData
          }
        />
      ) : (
        selectedRestock && (
          <div className="mt-6 space-y-4 font-semibold">
            <div className="w-full bg-[#ffffff] h-[400px] flex flex-col justify-center text-center">
              <div className="w-full flex justify-center">
                <img src={EmptyPage} alt="empty page" />
              </div>
              <div className="mt-8">
                <h1 className="lg:text-[24px] text-[18px] font-medium">
                  This Inventory has not been restocked
                </h1>
                <p className="text-[14px]  lg:text-[16px] font-medium ">
                  Restock information will be displayed here.
                </p>
              </div>
            </div>
          </div>
        )
      )}
      {selectedRemoval && removedStockData?.length > 0 ? (
        <RemovedStockTable
          hasVariations={hasVariations}
          isLoading={isLoading}
          removeData={
            filteredStockRemoval.length > 0
              ? filteredStockRemoval
              : removedStockData
          }
        />
      ) : (
        selectedRemoval && (
          <div className="mt-6 space-y-4 font-semibold">
            <div className="w-full bg-[#ffffff] h-[441px] text-center py-[60px]">
              <div className="w-full flex justify-center">
                <img src={EmptyPage} alt="empty page" />
              </div>
              <div className="mt-8">
                <h1 className="lg:text-[24px] text-[18px] font-medium">
                  There is no data here.
                </h1>
                <p className="text-[14px]  lg:text-[16px] font-medium ">
                  Find all details of removed stocks and the reason for removal.
                </p>
              </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};
export { ViewInventoryTable };
