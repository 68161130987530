import React, { useState } from "react";
import AddModal from "../modal/AddModal";
import { InputComponent } from "../../../core/Forms";
import LocationSelector from "../../../common/Address";
import PhoneInputComponent from "../../../common/PhoneInputComponent";
import {
  clearFieldError,
  formatNumber,
  validatePhoneNumber,
} from "../../../../utils";
import { useTranslation } from "react-i18next";
import { Button } from "../../../common";
import { CustomerT } from "../../../../types";
import { ICustomer } from "../../../../@types";
import { createCustomer } from "../../../../backend-services";
import toast from "react-hot-toast";
import { validateCustomerForm } from "../../../../modules/customers/customers";
interface AddNewCustomerProps {
  setIsOpen: (isOpen: boolean) => void;
  setFormData: React.Dispatch<React.SetStateAction<ICustomer>>;
  fetchCustomers?: () => void;
  handleSetCustomer: (newCustomer: ICustomer) => void;
  onClose?: () => void;
}

const AddNewCustomerDesktop: React.FC<AddNewCustomerProps> = ({
  setIsOpen,
  fetchCustomers,
  setFormData,
  handleSetCustomer,
  onClose,
}) => {
  const { t } = useTranslation("customer");
  const [customer, setCustomer] = React.useState<CustomerT>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    state: "",
    city: "",
  });

  const [phone, setPhone] = React.useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [firstName, setFirstName] = React.useState(customer?.firstName);
  const [lastName, setlastName] = React.useState(customer?.lastName);
  const [email, setEmail] = React.useState(customer?.email);
  const [address, setAddress] = React.useState(customer?.address);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [backendErrorMessage, setBackendErrorMessage] = React.useState("");
  const [businessName, setBusinessName] = React.useState(
    customer?.businessName,
  );
  const [validationResults, setValidationResults] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const { name, value } = e.target;

    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));

    // Update individual states if needed
    switch (name) {
      case "firstName":
        setFirstName(value);
        break;
      case "lastName":
        setlastName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "address":
        setAddress(value);
        break;
      default:
        break;
    }
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handlePhoneNumberChange = (value: string) => {
    const normalizedValue = value.replace(/\D/g, "");
    const isValidPhoneNumber = validatePhoneNumber(normalizedValue);

    if (isValidPhoneNumber) {
      const formattedPhoneNumber = normalizedValue.startsWith("+")
        ? normalizedValue
        : `+${normalizedValue}`;

      setPhone(formattedPhoneNumber);
      setValidationResults({ ...validationResults, phone: "" });
    } else {
      setValidationResults({
        ...validationResults,
        phone: "Invalid phone format",
      });
    }
  };
  const handleLocationChange = (
    selectedState: string,
    cities: string | any[],
  ) => {
    setState(selectedState);
    setCity(cities.length > 0 ? cities[0] : "");

    setFormData((prevData: any) => ({
      ...prevData,
      state: selectedState,
      city: cities.length > 0 ? cities[0] : "",
    }));
  };

  function isErrorResponse(
    error: any,
  ): error is { response: { data: { message: string } } } {
    return (
      typeof error === "object" &&
      error.response &&
      typeof error.response === "object" &&
      error.response.data &&
      typeof error.response.data === "object" &&
      error.response.data.message
    );
  }

  const handleCreateCustomer = async () => {
    setIsLoading(true);
    const { hasErrors, errors } = validateCustomerForm({
      firstName,
      lastName,
      phone,
      email,
    });
    setValidationResults(errors);

    if (hasErrors) {
      setIsLoading(false);
      return;
    }

    try {
      const customerData = await createCustomer({
        ...customer,
        phone,
        isOptedIn: false,
        outletId: "",
      });

      setIsLoading(false);
      handleSetCustomer(customerData);
      // Check for duplicate customer
      if (
        customerData &&
        !customerData.firstName &&
        !customerData.lastName &&
        !customerData.metadata
      ) {
        toast.error(
          "A customer with this phone number or email already exists.",
        );
      } else {
        fetchCustomers && fetchCustomers();
        onClose?.();
        setIsOpen(false);
        toast.success("Customer created successfully!");
      }
    } catch (error: any) {
      setIsLoading(false);
      toast.error(
        error.message || "An error occurred while creating the customer.",
      );
    }
    onClose?.();
  };

  return (
    <>
      {/* mobile */}

      {/* Desktop */}
      <AddModal>
        <div className="hidden md:block">
          <div className=" md:h-full pt-12 md:pt-1 flex flex-col justify-between">
            <div>
              <div className="flex justify-between items-center mb-5 pb-2 md:hidden">
                <div
                  onClick={() => {
                    setIsOpen(false);
                    onClose?.();
                  }}
                >
                  <svg
                    className="mt-[-2px] cursor-pointer"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 1L1.5 8L8.5 15"
                      stroke="#33363F"
                      strokeWidth="2"
                    />
                  </svg>
                </div>

                <div className="w-full flex justify-center ">
                  <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                    New Customer
                  </p>
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <div className="w-full">
                  <label
                    htmlFor="First Name"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    First name
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="First Name"
                    name="firstName"
                    type="text"
                    error={validationResults.firstName}
                    value={firstName}
                    handleChange={handleInputChange}
                    className="w-full placeholder:text-gray-400 border border-gray-300"
                  />
                </div>

                <div className="w-full">
                  <label
                    htmlFor="Last Name"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Last name
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="Last Name"
                    name="lastName"
                    type="text"
                    value={lastName}
                    handleChange={handleInputChange}
                    className="w-full placeholder:text-gray-400 border border-gray-300"
                    error={validationResults.firstName}
                  />
                </div>
              </div>

              <div className="flex justify-between gap-3">
                <div className="mt-3 w-full">
                  <label
                    htmlFor="Phone Number"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    {t("customer.phone")}
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <PhoneInputComponent
                    onPhoneNumberChange={handlePhoneNumberChange}
                    initialPhoneNumber={phone}
                    inputClass="onboarding"
                    containerClass="onboarding"
                  />
                  {validationResults.phone &&
                    validationResults.phone.length > 0 && (
                      <p className="text-red-500 text-sm">
                        {validationResults.phone}
                      </p>
                    )}
                </div>
                <div className=" mt-3 w-full">
                  <label
                    htmlFor="Email"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Email
                  </label>
                  <InputComponent
                    name="email"
                    placeholder="Email Address"
                    type="text"
                    value={email}
                    handleChange={handleInputChange}
                    className="w-full placeholder:text-gray-400 border border-gray-300"
                  />
                </div>
              </div>
              <div className="flex justify-between gap-3">
                <div className=" mt-3 w-full">
                  <label
                    htmlFor="BusinessName"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    {t("customer.businessName")}
                  </label>
                  <InputComponent
                    name="businessName"
                    placeholder="Business Name"
                    type="text"
                    value={businessName}
                    handleChange={handleInputChange}
                    className="w-full placeholder:text-gray-400 border border-gray-300"
                  />
                </div>

                <div className=" mt-3 w-full">
                  <label
                    htmlFor="Address"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    {t("customer.address")}
                  </label>
                  <InputComponent
                    placeholder="Billing Address"
                    name="address"
                    type="text"
                    value={address}
                    handleChange={handleInputChange}
                    className="w-full placeholder:text-gray-400 border border-gray-300"
                  />
                </div>
              </div>

              <div className="w-full mt-3">
                <LocationSelector
                  display="flex flex-row items-center w-full gap-3 "
                  onLocationChange={handleLocationChange}
                  onError={(error: any) => {}}
                  selectedState={state}
                  selectedCity={city}
                  required={false}
                />
              </div>
            </div>

            <div
              style={{
                boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
              }}
              className="w-full py-5"
            >
              <div className="mt-[32px] flex gap-6 w-full">
                <Button
                  outline={true}
                  variant="secondary"
                  disabled={false}
                  text={"Cancel"}
                  size="full"
                  onClick={onClose}
                />
                <Button
                  type="button"
                  onClick={() => handleCreateCustomer()}
                  disabled={isLoading}
                  variant="secondary"
                  // className="w-full"
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </AddModal>
    </>
  );
};

export default AddNewCustomerDesktop;
