import React, { ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { ErrorPage } from "../pages";

interface SentryErrorBoundaryProps {
  children: ReactNode;
}

interface CustomError {
  statusCode?: number;
  message?: string;
  [key: string]: any;
}

const SentryErrorBoundary = ({ children }: SentryErrorBoundaryProps) => {
  const navigate = useNavigate();

  const getActionButton = (error: CustomError) => {
    if (error?.statusCode === 401) {
      return (
        <button
          className="inline-flex items-center px-6 py-3 mt-1 text-base font-medium text-white bg-[#044E97] rounded"
          onClick={() => navigate("/login")}
        >
          Go to Login
        </button>
      );
    } else if (error?.statusCode === 500) {
      return (
        <button
          className="inline-flex items-center px-6 py-3 mt-1 text-base font-medium text-white bg-[#044E97] rounded"
          onClick={() => window.location.reload()}
        >
          Reload Page
        </button>
      );
    } else {
      // Fallback action if needed
      return (
        <button
          className="inline-flex items-center px-6 py-3 mt-1 text-base font-medium text-white bg-[#044E97] rounded"
          onClick={() => navigate("/dashboard")}
        >
          Go to Homepage
        </button>
      );
    }
  };

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => {
        // Default to 500 if statusCode is undefined
        const statusCode = (error as CustomError)?.statusCode ?? 500;

        // Only show fallback for 500, 403, or 404 errors
        if ([500, 403, 404].includes(statusCode)) {
          return (
            <ErrorPage
              statusCode={statusCode}
              errorTitle={
                statusCode === 401
                  ? "Unauthorized"
                  : statusCode === 403
                  ? "Forbidden"
                  : statusCode === 404
                  ? "Not Found"
                  : "Internal Server Error"
              }
              errorText={
                statusCode === 401
                  ? "You need to log in to access this page."
                  : statusCode === 403
                  ? "You do not have permission to access this page."
                  : statusCode === 404
                  ? "Oops! The page you're looking for doesn't exist."
                  : "Oops, something went wrong. We're working to fix it."
              }
              actionButton={getActionButton(error as CustomError)}
            />
          );
        }

        // Return an empty div to satisfy the fallback's requirement of returning a valid React element
        return <div style={{ display: "none" }} />;
      }}
      onError={(error, componentStack) => {
        const statusCode = (error as CustomError)?.statusCode ?? 500;

        // Capture errors only for 500, 403, or 404
        if ([500, 403, 404].includes(statusCode)) {
          console.error(
            "Sentry ErrorBoundary caught an error:",
            error,
            componentStack
          );
        }
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
