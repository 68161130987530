import { toast } from "react-hot-toast";
import { ICreateExpenseData, IEditExpenseData } from "../@types";
import { expenseService } from "../services";
import { handleError, withRetry } from "../utils";

export const createExpense = async (data: ICreateExpenseData) => {
  return withRetry(async () => {
    const res = await expenseService.post("expenses", data);
    return res.data;
  }).catch(handleError);
};

export const getExpense = async (id: string) => {
  return withRetry(async () => {
    const res = await expenseService.get(`/expenses/${id}`);
    return res.data;
  }).catch(handleError);
};

export const deleteExpense = async (id: string) => {
  return withRetry(async () => {
    const res = await expenseService.delete(`/expenses/${id}`);
    return res.data;
  }).catch(handleError);
};

export const editExpense = async (id: string, data: IEditExpenseData) => {
  return withRetry(async () => {
    const res = await expenseService.patch(`/expenses/${id}`, data);
    return res.data;
  }).catch(handleError);
};

export const getExpenseReport = async (
  startDate: string,
  endDate: string,
  paymentMethod: string,
  vendorId: number,
  downloadType: "csv" | "pdf"
) => {
  return withRetry(async () => {
    const res = await expenseService.get(
      `/expenses/report?startDate=${startDate}&endDate=${endDate}&paymentMethod=${paymentMethod}&vendorId=${vendorId}&downloadType=${downloadType}`
    );
    return res.data;
  }).catch(handleError);
};

export const editToggleProtected = async (
  id: string,
  data: IEditExpenseData
) => {
  return withRetry(async () => {
    const res = await expenseService.patch(
      `/expenses/${id}/toggle-protected`,
      data
    );
    return res.data;
  }).catch(handleError);
};
