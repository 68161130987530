import React, { useState } from "react";
import AddModal from "../modal/AddModal";
import { CloseIcon } from "../../../../assets/icons";
import { Button } from "../../../common";
import SelectedMarkIcon from "../../../../assets/icons/SelectedIcon";
import { useUserStore } from "../../../../state";
import { BankData } from "../../../../types";

interface SelectBankProps {
  setOpenSelect: (openSelect: boolean) => void;
  setModalInView: (text: string) => void;
  selectedAccounts: string[];
  setSelectedAccounts: (accounts: string[]) => void;
}

const SelectBank: React.FC<SelectBankProps> = ({
  setOpenSelect,
  setModalInView,
  selectedAccounts,
  setSelectedAccounts,
}) => {
  const { user } = useUserStore();
  const bankAccounts = user.bankAccounts ? user.bankAccounts.flat() : [];

  return (
    <>
      <AddModal>
        <div className="flex justify-center">
          <div className="w-[60px] h-[6px] rounded-[4px] bg-gray-300" />
        </div>
        <div className="flex justify-between items-center my-4">
          <p className="text-[18px] font-semibold leading-6">
            Select Bank account
          </p>
          <div onClick={() => setOpenSelect(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="mt-4">
          {bankAccounts.reverse().map((bank: BankData, index: number) => (
            <div
              key={index}
              className={`text-[14px] flex items-center justify-between py-1 ${
                selectedAccounts.includes(bank.accountNumber)
                  ? "bg-gray-100"
                  : ""
              }`}
              onClick={() => {
                if (!selectedAccounts.includes(bank.accountNumber)) {
                  setSelectedAccounts([bank.accountNumber]);
                } else {
                  setSelectedAccounts([]);
                }
              }}
            >
              <div>
                <h5 className="font-semibold leading-5 text-gray-700">
                  {bank.accountName}
                </h5>
                <div className="flex items-center gap-2 text-gray-500">
                  <p className="leading-5">{bank.accountNumber}</p>
                  <div className="w-1 h-1 rounded-full bg-gray-300" />
                  <p className="leading-5">{bank.bankName}</p>
                </div>
              </div>
              {selectedAccounts.includes(bank.accountNumber) && (
                <SelectedMarkIcon />
              )}
            </div>
          ))}
        </div>

        <div className="flex gap-4 w-full mt-8">
          <Button
            variant="secondary"
            outline={true}
            text="Add new account"
            size="full"
            onClick={() => setModalInView("bank_form")}
          />
          <Button
            variant="secondary"
            text="Continue"
            size="full"
            onClick={() => {
              if (selectedAccounts.length > 0) {
                setOpenSelect(false);
              } else {
                alert("Please select a bank account");
              }
            }}
          />
        </div>
      </AddModal>
    </>
  );
};

export default SelectBank;
