import React from "react";
import { CloseIcon } from "../../../assets/icons";
import cx from "classnames";
import { IInvoice } from "../../../@types";

interface CenterPopupComponentProps {
  isOpen: boolean;
  onClose: () => void;
  invoice: any;
}

const CenterPopupComponent: React.FC<CenterPopupComponentProps> = ({
  isOpen,
  onClose,
  invoice,
}) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-50">
          {/* Centered Popup */}
          <div className="bg-white shadow-lg rounded-lg w-full max-w-lg p-6">
            <div className="flex items-center justify-between">
              <h3 className="text-[25px] font-semibold">Reminder History</h3>
              <button
                aria-label="Close"
                className="flex items-center rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF]"
                type="button"
                onClick={onClose}
              >
                <CloseIcon />
              </button>
            </div>

            {/* Panel Content with Scrollable Area */}
            <div className="mt-4 text-[13px] lg:text-[16px] pb-4 max-h-[400px] overflow-y-auto border-b border-gray-300">
              <div className="space-y-2">
                {invoice?.reminders?.length > 0 ? (
                  invoice.reminders.map(
                    (
                      reminder: { channel: string; time: string },
                      index: React.Key | null | undefined
                    ) => (
                      <div key={index} className="p-2">
                        <div className="text-[#044E97]">
                          Reminder sent via {reminder.channel}
                        </div>
                        <div className="text-gray-600">
                          {formatDate(reminder.time)}
                        </div>
                      </div>
                    ),
                  )
                ) : (
                  <div>No reminders found.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CenterPopupComponent;
