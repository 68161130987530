import React, { useState } from "react";
import { TableItem } from "..";
import { DownloadIcon } from "../../../../assets/icons";
import { formatMoney, shortenText } from "../../../../utils";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import { divide } from "lodash";
import { useNavigate } from "react-router-dom";
import Cash from "../../../../assets/icons/cash";
import CardIco from "../../../../assets/icons/card";
import Transfer from "../../../../assets/icons/transfer";

const Card: React.FC<{
  data: TableItem;
  menu: React.ReactNode;
  index: number;
  length: number;
  CustomerCard?: boolean;
  variationCard?: boolean | undefined;
  expenseCard?: boolean | undefined;
  invoiceCard?: boolean | undefined;
  quoteCard?: boolean | undefined;
  inventoryCard?: boolean | undefined;
  restockCard?: boolean | undefined;
  removeStockCard?: boolean | undefined;
  archiveCard?: boolean | undefined;
  serviceCard?: boolean | undefined;
  userCard?: boolean | undefined;
  paymentHistoryCard?: boolean | undefined;
  modules?: string;
}> = ({
  data,
  menu,
  index,
  length,
  CustomerCard,
  expenseCard,
  invoiceCard,
  quoteCard,
  inventoryCard,
  removeStockCard,
  serviceCard,
  paymentHistoryCard,
  variationCard,
  restockCard,
  archiveCard,
  userCard,
  modules,
}) => {
  const isEven = index % 2 === 0;
  const [showTooltip, setShowTooltip] = useState(false);
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [reasonContent, setReasonContent] = useState("");

  const handleViewReason = (reason: any) => {
    setReasonContent(reason);
    setShowReasonModal(true);
  };
  const navigate = useNavigate();

  const handleRowClick = (id: any) => {
    if (modules) {
      navigate(`/${modules}/${id}/view`);
    }
  };

  return (
    <div className="relative z-1">
      {removeStockCard &&
        showTooltip &&
        typeof data.reason1 === "string" &&
        data.reason1.length > 12 && (
          <div className="absolute bg-white p-2 top-[-42%] right-6 max-w-[50%] text-[16px] z-50 shadow-lg rounded-[4px]">
            {data.reason1}
          </div>
        )}
      {CustomerCard && (
        <>
          <div
            onClick={() => handleRowClick(data.id)}
            className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
              index === length - 1 ? "border-transparent" : ""
            }`}
          >
            <div>
              <h2 className="text-[#000] text-[14px] font-medium leading-5">
                {data.customer}
              </h2>
              <span className="text-gray-500 text-[12px] leading-4 font-normal mt-1">
                {data.email}
              </span>
            </div>

            <div className="text-right">
              {/* <div>{menu}</div> */}
              <p className="text-[14px] text-[#000] font-semibold leading-5">
                {data.billed}
              </p>
              <p className="text-[12px] text-gray-500 leading-4 font-medium mt-1">
                {data.createdAt}
              </p>
            </div>
          </div>
        </>
      )}

      {invoiceCard && (
        <div
          onClick={() => handleRowClick(data.invoiceId)}
          className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
            index === length - 1 ? "border-transparent" : ""
          }`}
        >
          <div>
            <div className="flex flex-row gap-3 items-center ">
              <span>{data.status}</span>
              <span className="text-[14px] leading-5 font-normal whitespace-nowrap">
                {data.isReoccurring}
              </span>
            </div>
            <h2 className=" text-[16px] leading-6 font-semibold mt-3 mb-2">
              {data.customer}{" "}
            </h2>
            <p className="text-[#044E97] font-medium text-[14px] leading-5">
              {data?.invoiceNumbers}
            </p>
          </div>

          <div>
            {/* <span>{menu}</span> */}
            <div className="text-[16px] leading-6 text-[#000] font-semibold">
              {data.inventories}
            </div>
            <div className="text-[14px] text-gray-500 leading-5 mt-3 font-medium">
              {data.issueDate}
            </div>
          </div>
        </div>
      )}

      {inventoryCard && (
        <div
          onClick={() => handleRowClick(data.id)}
          className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
            index === length - 1 ? "border-transparent" : ""
          }`}
        >
          <div>
            <h2 className=" text-[14px] font-semibold text-gray-800">
              {data.mobileName}{" "}
            </h2>
            <div className="flex items-center gap-1 text-gray-600 text-[14px] font-medium mt-1">
              <span>{data.variations === 0 ? "No" : data.variations}</span>
              <span>variations</span>
            </div>
          </div>

          <div className="font-semibold text-[14px] text-right">
            <p className="text-[#000]">{data.price}</p>
            <span className="mt-1 text-[#044E97] font-medium leading-5">
              <a
                href={`/inventories/${data.id}/restock`}
                onClick={(event) => event.stopPropagation()}
              >
                {"Restock"}
              </a>
            </span>
          </div>
        </div>
      )}

      {serviceCard && (
        <div
          onClick={() => handleRowClick(data.id)}
          className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
            index === length - 1 ? "border-transparent" : ""
          }`}
        >
          <div>
            <div className="flex gap-1 items-center">
              <span className="text-gray-600 leading-4 text-[12px] font-medium">
                {data.serviceId?.toString().toUpperCase().slice(0, 6)}
              </span>
              <span className="text-[12px] font-sm font-normal">
                {data.status}
              </span>
            </div>

            <p className="text-[#044E97] leading-5 text-[14px] font-semibold mt-2">
              {data.name}
            </p>
          </div>

          <div className="text-right">
            {/* <span>{menu}</span> */}
            <p className="text-[16px] font-semibold text-[#212121] leading-6 mt-2 ">
              {data.price}
            </p>
            <p className="text-gray-500 text-[12px] font-medium leading-4">{`${data.duration} hours`}</p>
          </div>
        </div>
      )}

      {quoteCard && (
        <div
          onClick={() => handleRowClick(data.id)}
          className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
            index === length - 1 ? "border-transparent" : ""
          }`}
        >
          <div>
            <h2 className="text-[16px] leading-6 text-[#000000] font-semibold">
              {data.customer}{" "}
            </h2>
            <p className="text-[#044E97] text-[14px] py-2 font-medium leading-6 ">
              {data.quoteNumber}
            </p>
            <span>{data.status}</span>
          </div>
          <div>
            <p className="text-[16px] leading -6 font-semibold">
              {data.inventories}
            </p>
            <p className="text-[14px] leading-5 text-gray-600 font-medium">
              {data.issueDate}
            </p>
          </div>
        </div>
      )}

      {expenseCard && (
        <div
          onClick={() => handleRowClick(data.id)}
          className={`w-full p-3 border-b border-gray-200 flex justify-between cursor-pointer ${
            index === length - 1 ? "border-transparent" : ""
          }`}
        >
          <div>
            <h2 className="text-black leading-5 text-[14px] font-semibold mb-[4px]">
              {data.vendor || data.name}{" "}
            </h2>
            <p className="text-[14px] text-gray-600 leading5 font-normal">
              {data.category || data.rate}
            </p>
          </div>

          <div className="text-right">
            {/* <span className="">{menu}</span> */}
            <p className="text-[14px] leading-5 text-[#000] font-semibold">
              {data.amount}
            </p>
            <p className="text-[14px] text-gray-600 leading-5 mt-1">
              {data.date}
            </p>
          </div>
        </div>
      )}

      {paymentHistoryCard && (
        <>
          <div
            className={`mx-auto bg-white rounded-sm shadow-md overflow-hidden md:max-w-2xl w-full h-[106px] ${
              isEven ? "bg-[#FFFFFF]" : "bg-[#E6EDF5]"
            }`}
          >
            <div className="flex flex-row justify-between p-3 mt-2">
              <div>
                <p className="text-[14px] font-semibold text-[#0469C5] mb-6">
                  {data.entityType}
                </p>
                <p className="text-[14px] font-medium text-[#475467] mt-[2em]">
                  {data.date}
                </p>
              </div>
              <div className="mr-[em]">
                <div className="flex mb-4 gap-4">
                  <p className="text-[16px] font-semibold text-[#000000]">
                    {data.amount}
                  </p>
                  <p className="text-[16px] font-semibold text-[#000000]">
                    {data.proof}
                  </p>
                </div>
                <p className="text-[16px] font-medium text-[#344054] flex items-center mt-[1.3em]">
                  <span className="mr-2">
                    {data.method === "transfer" && <Transfer />}
                    {data.method === "cash" && <Cash />}
                    {data.method === "card" && <CardIco />}
                    {!data.method && <span>N/A</span>}
                  </span>
                  {/* @ts-ignore */}
                  {typeof data.method === "string" && data.method
                    ? data.method.charAt(0).toUpperCase() + data.method.slice(1)
                    : "N/A"}
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      {variationCard && (
        <div className="mx-auto rounded-sm shadow-sm overflow-hidden md:max-w-2xl w-full h-[90px] border border-gray-100">
          <div className="flex flex-col gap-5">
            {/* Header section */}
            <div className="bg-[#EAECF0] w-full">
              <div className="flex justify-between px-[16px] py-[4px]">
                <div className="flex gap-1 text-[14px] font-medium">
                  <span>{`${data.name}`}</span>
                </div>
                <span className="text-[15px] font-semibold">{data.price}</span>
              </div>
            </div>

            {/* Body section */}
            <div className="flex justify-between items-end px-[16px] mt-[-0.8em]">
              <div className="flex items-center text-[12px] font-normal gap-[4em]">
                <div className="flex flex-col items-center gap-[4px]">
                  <p className="font-medium text-gray-600">Type</p>
                  <span className="font-medium text-black">{data.type}</span>
                </div>
                <div className="flex flex-col items-center gap-[4px]">
                  <p className="font-medium text-gray-600">Quantity left</p>
                  <span className="font-medium text-black">
                    {data.quantity}
                  </span>
                </div>
              </div>
              <div className="text-[14px] text-[red] font-medium">
                {/* <p className="font-normal">Delete</p> */}
                <p className="mt-[-2em]"> {data.bin}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {restockCard && (
        <div className="mx-auto rounded-sm shadow-sm overflow-hidden md:max-w-2xl w-full h-[158px] border border-gray-100">
          <div className="flex flex-col gap-5">
            {/* Header section */}
            <div className="bg-[#EAECF0] w-full">
              <div className="flex justify-between px-[16px] py-[5px]">
                <div className="flex gap-1 text-[14px] font-medium">
                  <span>{`${data.name}`}</span>
                </div>
                <span className="text-[15px] font-semibold">{data.date}</span>
              </div>
            </div>

            {/* Body section */}
            <div className="flex justify-between items-end px-[16px] mb-4">
              <div className="flex flex-col  text-[12px] font-normal  w-full gap-2 ">
                <div className="flex justify-between ">
                  <div className="flex flex-col items-start gap-[4px] justify-start ">
                    <p className="font-medium text-gray-600">Quantity</p>
                    <span className="font-medium text-black">
                      {data.quantityRestocked}
                    </span>
                  </div>
                  <div className="flex flex-col text-right gap-[4px] items-end justify-end">
                    <p className="font-medium text-gray-600 whitespace-nowrap">
                      Old price
                    </p>
                    <span className="font-medium text-black">
                      {data.oldSellingPrice || 0}
                    </span>
                  </div>
                </div>

                <div className="flex justify-between ">
                  <div className="flex flex-col items-start gap-[4px] justify-start ">
                    <p className="font-medium text-gray-600">Variation</p>
                    <span className="font-medium text-black">
                      {data.variation}
                    </span>
                  </div>
                  <div className="flex flex-col text-right gap-[4px] items-end justify-end">
                    <p className="font-medium text-gray-600 whitespace-nowrap">
                      New price
                    </p>
                    <span className="font-medium text-black">
                      {data.newSellingPrice || 0}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {removeStockCard && (
        <div className="mx-auto rounded-sm shadow-sm overflow-hidden md:max-w-2xl w-full h-[118px] border border-gray-100">
          <div className="flex flex-col gap-5">
            {/* Header section */}
            <div className="bg-[#EAECF0] w-full">
              <div className="flex justify-between px-[16px] py-[5px]">
                <div className="flex gap-1 text-[14px] font-medium">
                  <span>{`${data.variation}`}</span>
                </div>
                <span className="text-[15px] font-semibold">{data.date}</span>
              </div>
            </div>

            {/* Body section */}
            <div className="flex justify-between items-end px-[16px] mb-4">
              <div className="flex flex-col text-[12px] font-normal w-full gap-2">
                <div className="flex justify-between">
                  {data.type && (
                    <div className="flex flex-col items-start gap-[4px] justify-start">
                      <p className="font-medium text-gray-600">Var. Type</p>
                      <span className="font-medium text-black">
                        {data.type}
                      </span>
                    </div>
                  )}
                  <div className="flex flex-col items-center gap-[4px] items-end justify-end">
                    <p className="font-medium text-gray-600 whitespace-nowrap">
                      Quantity
                    </p>
                    <span className="font-medium text-black">
                      {data.quantity}
                    </span>
                  </div>
                  {data.variation && (
                    <div className="flex flex-col items-start gap-[4px] justify-start">
                      <p className="font-medium text-gray-600">Var</p>
                      <span className="font-medium text-black">
                        {data.variation}
                      </span>
                    </div>
                  )}
                  <div
                    onClick={() => handleViewReason(data.reason1)}
                    className="flex flex-col items-center gap-[4px] items-end justify-end cursor-pointer"
                  >
                    <p className="font-medium text-gray-600 whitespace-nowrap">
                      Reason
                    </p>
                    <span className="text-blue-600">View</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Reason Modal */}
      {showReasonModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg p-6 w-[90%] max-w-[400px]">
            <h2 className="text-lg font-semibold mb-4">
              Reason for stock removal
            </h2>
            <p className="text-black text-[16px] font-medium">
              {reasonContent}
            </p>
            <button
              className="mt-4 px-4 py-2 bg-white text-blue-600 rounded w-full border border-gray-200 font-semibold"
              onClick={() => setShowReasonModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}

      {archiveCard && (
        <div
          className={`mx-auto rounded-md shadow-sm overflow-hidden md:max-w-2xl mt-3 w-full h-[116px] px-[16px] py-[16px] ${
            isEven ? "bg-[#FFFFFF]" : "bg-[#E6EDF5]"
          }`}
        >
          <div className="flex justify-between">
            <div className="flex flex-col gap-4">
              <div className="flex gap-1 text-[15px] font-medium">
                <p className="text-[#212121]">
                  {typeof data.id === "string" ? data.id.substring(0, 3) : ""}
                </p>
                <span>-</span>
                <span>{data.name}</span>
              </div>

              <div className="flex justify-between items-end">
                <div className="flex gap-[12px] items-center text-[12px] font-normal">
                  <div className="flex flex-col items-center gap-[12px]">
                    <p className="font-normal">Var</p>
                    <span className="font-medium ">{data.variations}</span>
                  </div>
                  <div className="flex flex-col items-center gap-[12px]">
                    <p className="font-normal">Qty left</p>
                    <span className="font-medium ">{data.quantityLeft}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="space-y-[5px]">
              <div>
                <p className="font-normal text-[12px]">Unit Price</p>
                <span className="font-semibold text-[15px]">{data.price}</span>
              </div>
              <div>
                <p className="font-normal text-[12px]">Date</p>
                <span className="font-semibold text-[15px]">{data.date}</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {userCard && (
        <div
          className={` relative z-1 flex justify-between items-start mx-auto rounded-md shadow-sm overflow-hidden md:max-w-2xl mt-2 w-full h-[124px] px-[16px] pt-[12px] pb-[11.17px] ${
            isEven ? "bg-[#FFFFFF]" : "bg-[#E6EDF5]"
          }`}
        >
          <div className="">
            <span className="text-[15px] font-medium">{data.user}</span>

            <div className="flex text-[12px] justify-between ">
              <div className="flex flex-col justify-start text-left items-start">
                <p>Email</p>
                <span className="font-medium whitespace-normal">{data.email}</span>
              </div>
              <div className="flex flex-col justify-end items-end ">
              <div className="mt-[-4em]">{data.action}</div> 
                <p className="mt-[1em]">Role</p>
                <span className="font-medium">{data.role}</span>
              </div>
            </div>
            <div>{data.status}</div>
          </div>

          {/* <div>{data.action}</div> */}
        </div>
      )}
    </div>
  );
};

export default Card;
