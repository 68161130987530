import React from "react";
import SelectCustomer from "./customer/SelectCustomer";
import AddNewCustomer from "./customer/AddNewCustomer";
import { ICreateSalesData, ICustomer } from "../../../@types";
import { InvoiceErrors } from "../../../modules/validateInvoiceForm";
import PlusIcon from "../../../assets/icons/PlusIcon";
import AddNewCustomerDesktop from "./customer/desktopAddCustomer";

interface CustomerInfoProps {
  customer: ICustomer | null;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  formData: ICreateSalesData;
  onClearError?: () => void;
}

const CustomerSection: React.FC<CustomerInfoProps> = ({
  customer,
  handleSetCustomer,
  validationResults,
  setValidationResults,
  formData,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [modalInView, setModalInView] = React.useState("select_customer");
  const [, setFormData] = React.useState<ICustomer>({} as ICustomer);
  const handleSetCustomerWrapper = (selectedCustomer: ICustomer | null) => {
    handleSetCustomer(selectedCustomer);
    if (selectedCustomer) {
      setValidationResults((prevResults: any) => ({
        ...prevResults,
        customerId: "",
      }));
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setModalInView("select_customer");
    // onClose && onClose();
  };

  return (
    <>
      <div className="w-full py-5 border-y border-gray-200 lg:hidden md:hidden">
        <div className="text-[14px] flex justify-between items-center">
          <p className="py-1 font-medium leading-5 text-[#000000]">Bill to</p>
          {customer && (
            <button
              onClick={() => setIsOpen(true)}
              className="font-semibold leading-5 text-[#044E97] px-2 py-1"
            >
              Change
            </button>
          )}
        </div>
        {customer ? (
          <div className="space-y-1 font-normal text-[14px] text-gray-700 mt-2">
            <p className="font-semibold leading-5">
              {customer.name ||
                `${customer.firstName || ""} ${customer.lastName || ""}`.trim()}
            </p>
            <p>{customer.address}</p>
            <p>{[customer.city, customer.state].filter(Boolean).join(", ")}</p>
          </div>
        ) : (
          <button
            onClick={() => setIsOpen(true)}
            className="w-full font-medium leading-6 text-gray-700 flex justify-center py-3 rounded-lg border border-gray-300 mt-2"
          >
            Add Customer
          </button>
        )}
        {validationResults.customerId && (
          <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[12px]">
            <span className="text-red-500">{validationResults.customerId}</span>
          </div>
        )}
      </div>
      {/* Desktop */}
      <div className="w-full lg:max-w-[31%] hidden md:block mt-10">
        <div className="flex items-center justify-between">
          <p className="text-[16px] font-semibold leading-6 text-[#000000]">
            Bill to
          </p>
          <button
            onClick={() => setIsOpen(true)}
            className="flex items-center gap-1 p-2 rounded bg-[#E0EEFE]"
          >
            <PlusIcon />
            <p className="font-medium leading-6 text-[16px] text-[#044E97]">
              {customer ? "Change Customer" : "Add Customer"}
            </p>
          </button>
        </div>

        {customer ? (
          <div className="border border-gray-200 rounded-lg py-3 px-2 mt-2">
            <div className="italic text-[16px] leading-6 text-gray-400 space-y-1">
              <p className="medium leading-6">
                {" "}
                {customer.name ||
                  `${customer.firstName || ""} ${
                    customer.lastName || ""
                  }`.trim()}
              </p>
              <p>{customer.address}</p>
              <p>
                {[customer.city, customer.state].filter(Boolean).join(", ")}
              </p>
            </div>
          </div>
        ) : (
          <div className="border border-gray-200 rounded-lg py-3 px-2 mt-2">
            <div className="italic text-[16px] leading-6 text-gray-400 space-y-1">
              <p className="medium leading-6">Customer name</p>
              <p>Address</p>
              <p>State, City</p>
            </div>
          </div>
        )}
        {validationResults.customerId && (
          <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[12px]">
            <span className="text-red-500">{validationResults.customerId}</span>
          </div>
        )}
      </div>
      {/* Modal components */}
      {isOpen && modalInView === "select_customer" && (
        <SelectCustomer
          setModalInView={setModalInView}
          setIsOpen={setIsOpen}
          customer={customer}
          handleSetCustomer={(selectedCustomer) => {
            handleSetCustomerWrapper(selectedCustomer);
            setIsOpen(false);
          }}
          formData={formData}
        />
      )}
      <div className="md:hidden">
        {isOpen && modalInView === "add_customer" && (
          <AddNewCustomer
            setIsOpen={setIsOpen}
            setFormData={setFormData}
            handleSetCustomer={(newCustomer) => {
              handleSetCustomer(newCustomer);
              setIsOpen(false);
            }}
            onClose={handleClose}
          />
        )}
      </div>
      <div className="hidden md:block">
        {isOpen && modalInView === "add_customer" && (
          <AddNewCustomerDesktop
            setIsOpen={setIsOpen}
            setFormData={setFormData}
            handleSetCustomer={(newCustomer) => {
              handleSetCustomer(newCustomer);
              setIsOpen(false);
            }}
            onClose={handleClose}
          />
        )}
      </div>

      {/* {isOpen && modalInView === "select_customer" && (
        <SelectCustomer setModalInView={setModalInView} setIsOpen={setIsOpen} />
      )}
      {isOpen && modalInView === "add_customer" && (
        <AddNewCustomer setIsOpen={setIsOpen} />
      )} */}
    </>
  );
};

export default CustomerSection;
