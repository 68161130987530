import * as React from "react";
import { useInView } from "react-intersection-observer";
import MediaQuery from "react-responsive";
import { IPaginationMeta } from "../../@types";
import { Filter } from "../../@types/invoice";
import { sortData } from "../../utils";
import { FloatingScrollButton } from "../FloatingScrollButton";
import Empty from "./Table/Empty";
import { Head } from "./Table/Head";
import { Loading } from "./Table/Loading";
import { MobileHead } from "./Table/MobileHead.";
import CardList from "./Table/MobileTableComponent/CardList";
import { MobileTableBody } from "./MobileTableBody";

export type HeadingType = {
  /**
   * Title show on the column head.
   */
  title?: string;
  /**
   * Icon show on the column head.
   */
  icon?: any;
  /**
   * Callback function after the title/icon is clicked.
   * @returns void
   */
  onClick?: () => void;
};

type PaginationProps = {
  /**
   * Show the table footer pagination.
   * The meta prop and fetchMore Data prop will be required
   */
  showPagination?: true;
  /**
   * Pagination meta data
   */
  meta?: IPaginationMeta;
  /**
   * Fetch more data for pagination
   * @param offset The point to start fetching data.
   * @param limit Max number of data to fetch.
   * @param cursor Last data id.
   * @returns Void
   */
  fetchMoreData?: (direction: "up" | "down") => void;
};

type HeadProps =
  | {
      showHead?: false;
      buttons?: never;
      handleSearch?: never;
    }
  | {
      /**
       * Show the table head components.
       * @type boolean
       */
      showHead?: true;

      /**
       * List of grouped buttons to show in the head component
       */
      buttons?: Array<{
        text: string;
        icon: any;
        href?: string;
        onClick?: () => void;
      }>;
      /**
       * Function called when search text changes.
       * @param text search text
       * @returns void
       */
      isSearching?: boolean;
      searchedDone?: boolean | undefined;
      setSearchedDone?: (searchedDone: boolean) => void;
      handleSearch?: (text: string) => void;
      handleSort?: (text: string) => void;
      selectedFilter?: any;
      handleFilter?: (filter: Filter) => void;
      searchResult: any[];
      searchDisplayKey: string;
      searchPlaceHolder: string;
      handleSearchSelect?: (item: any) => void;
      showMobileInvoiceTable?: boolean;
      showMobileServiceTable?: boolean;
    };
export type TableItem = {
  [key: string]:
    | string
    | number
    | boolean
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
};

export type TableData = {
  data: [];
};
type ItemProps<T> = {
  /**
   * A HeadingType Object displayed in the table header.
   * The key of this object will be used as key for the items displayed on the table data.
   */
  head: T;
  /**
   * Array of object with keys of HeadingType as it's key.
   * Determines the data that will be rendered in the body of the table
   */
  data: Array<{ [key in keyof T]: any }>;
} & PaginationProps &
  HeadProps;

export type mobileTableProps<T> = {
  /**
   * Indicates whether table data is loading or not.
   */
  isLoading: boolean;
  isSearching?: boolean;
  hideFloatingScrollButton?: boolean;
  showMobileInvoiceTable?: boolean | undefined | any;
  showMobileServiceTable?: boolean | undefined | any;
  CustomerCard?: boolean | any;
  expenseCard?: boolean | undefined;
  invoiceCard?: boolean | undefined;
  quoteCard?: boolean | undefined;
  serviceCard?: boolean | undefined;
  payHistoryTable?: boolean;
} & ItemProps<T>;

const MobileTable = <T extends { [key: string]: HeadingType }>(
  props: mobileTableProps<T>
) => {
  const [data, setData] = React.useState<typeof props.data>([]);
  const [sort, setSort] = React.useState<{
    column: string;
    direction: "ASC" | "DESC";
  }>({ column: "", direction: "DESC" });

  const fetchPageData = (direction: "up" | "down") => {
    if (direction === "down" && props?.meta?.hasMoreData) {
      props.fetchMoreData ? props.fetchMoreData("down") : undefined;
    }
  };

  const { ref, inView } = useInView({
    threshold: 0,
  });

  React.useEffect(() => {
    if (inView && props.showPagination && props?.meta?.hasMoreData) {
      fetchPageData("down");
    }
  }, [inView, props.showPagination, props.meta]);

  React.useEffect(() => {
    setData(props.data);
  }, [props.data]);

  React.useEffect(() => {
    setData(sortData(props.data, sort.column, sort.direction));
  }, [sort]);

  const handleSort = (column: string) => {
    if (sort.column === column) {
      setSort({ column, direction: sort.direction === "ASC" ? "DESC" : "ASC" });
    } else {
      setSort({ column, direction: "ASC" });
    }
  };

  return (
    <div ref={ref}>
      <div className="relative md:rounded-lg position-relative ">
        {props.showHead && (
          <div className="mb-4">
            <MobileHead
              handleSearchSelect={props.handleSearchSelect}
              buttons={props.buttons}
              handleSearch={props.handleSearch}
              handleSort={props.handleSort}
              searchResult={props.searchResult}
              searchDisplayKey={props.searchDisplayKey}
              handleFilter={props.handleFilter}
              filter={props.selectedFilter}
              searchPlaceHolder={props.searchPlaceHolder}
              isSearching={props.isSearching}
              searchedDone={props?.searchedDone}
              setSearchedDone={props?.setSearchedDone}
            />
          </div>
        )}
        <div className="lg:block w-full text-center"></div>
        <table className="w-full">
          <tbody className="divide-y font-black ">
            <MobileTableBody
              payHistoryTable={props.payHistoryTable}
              data={data}
              isLoading={props.isLoading}
              showMobileInvoiceTable={props?.showMobileInvoiceTable}
              showMobileServiceTable={props?.showMobileServiceTable}
              head={props.head}
              CustomerCard={props.CustomerCard}
              expenseCard={props.expenseCard}
              invoiceCard={props.invoiceCard}
              quoteCard={props.quoteCard}
              serviceCard={props.serviceCard}
              inventoryCard={undefined}
              variationCard={undefined}
              restockCard={undefined}
              removeStockCard={undefined}
            />

            <tr>
              <td colSpan={Object.keys(props.head).length}></td>
            </tr>
          </tbody>
        </table>

        {data.length === 0 && !props.isLoading && <Empty />}
        {data.length !== 0 && !props.isLoading && (
          <>
            {/* {!props.hideFloatingScrollButton && (
              <FloatingScrollButton
                className="sticky bottom-8 left-[1099px] z-10"
                action={() => {
                  if (props.showPagination && props?.meta?.hasMoreData) {
                    fetchPageData('down');
                  }
                }}
                title="Scroll Down"
                direction="down"
                fadeMode={!props?.meta?.hasMoreData}
              />
            )} */}
          </>
        )}
      </div>
    </div>
  );
};

export { MobileTable };
