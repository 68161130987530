export const uploadToPresignedURL = async (
  url: string,
  fields: any,
  uploadedFile: File
) => {
  const formData = new FormData();

  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key]);
  });

  formData.append("file", uploadedFile);

  const res = await fetch(url, {
    method: "POST",
    body: formData,
  });

  if (!res.ok) {
    throw new Error(`${res.status} ${res.statusText}`);
  }
};
