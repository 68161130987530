import React, {
  FormEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link } from "react-router-dom";
import * as UI from "../common";
import { Alert } from "flowbite-react";
import { InputComponent } from "../core/Forms";
import { validateLoginForm } from "../../modules/auth/login";
import { clearFieldError } from "../../utils";
import GoogleIcon from "../../assets/icons/GoogleIcon";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/useContext";
import Cookies from "js-cookie";
import AccountLoader from "./AccountLoader";
import { UseAuthContext } from "../../context/useAuthContext";
import * as Sentry from "@sentry/react";

interface DecodedToken {
  given_name: string;
  family_name: string;
  email: string;
  "custom:custom_business_name": string;
  phone_number: string;
  "custom:custom_businessType": string;
  locale: string;
  "custom:custom_FirstLogin": string;
  "cognito:groups": string[];
  sub: string;
  "custom:custom_userid": string;
}

interface LoginViewProps {
  handleTabChange: (auth: boolean) => void;
}

const LoginView = ({ handleTabChange }: LoginViewProps) => {
  const { t } = useTranslation("onboarding");

  const { login, googleLogin, error, loading, handleGoogleCallback } =
    useContext(UseAuthContext);
  const userContext = useContext(UserContext);

  if (!userContext) {
    throw new Error("UserContext is not available");
  }

  const { setCognitoUser } = userContext;
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigation = Cookies.get("navigation");

  useEffect(() => {
    if (!error) {
      setMessage("");
      return;
    }

    let errorMessage = "An unknown error occurred";

    if (typeof error === "string") {
      errorMessage = error;
    } else if (error && typeof error === "object" && "message" in error) {
      errorMessage = (error as { message: string }).message;
    }

    setMessage(errorMessage);

    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [error]);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleShowPolicyModal = () => {
    setShowPolicyModal(true);
  };

  const [validationResults, setValidationResults] = useState({
    email: "",
    password: "",
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateForm();
    clearFieldError(name, validationResults, setValidationResults);
  };

  const validateForm = () => {
    const errors = validateLoginForm(formData);
    setValidationResults(errors);
  };

  const handleSignIn: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    validateForm();

    const hasErrors = Object.values(validationResults).some(
      (error) => error !== ""
    );
    if (hasErrors) {
      setMessage("Fill the required detials");
      return;
    }

    try {
      await login(formData.email.toLowerCase(), formData.password);
    } catch (err) {}
  };

  const handleGoogleSignInClick = () => {
    googleLogin();
  };

  useEffect(() => {
    handleGoogleCallback();
  }, []);

  if (isAuthenticating && navigation === "freeInvoice") {
    return <AccountLoader />;
  }

  return (
    <div className="px-[4.1%] lg:px-0">
      <div className="bg-[#fff] md:w-[434px] mx-auto mt-10 lg:mt-[130px] relative">
        <h1 className="lg:text-[36px] mt-6 font-semibold text-[#0E2943] mb-[1em] lg:mb-6 md:text-[28px] text-[20px]">
          Log in to Esemie
        </h1>
        <button
          onClick={handleGoogleSignInClick}
          type="button"
          className="flex justify-center mx-auto mt-[1em] py-2 px-4 w-full gap-2 rounded-md bg-white border border-[#A4A4A4]"
        >
          <GoogleIcon />
          <p className="text-[16px] font-medium md:text-[16px] xl:text-[16px] xl:font-medium mt-[0.2em]">
            Continue with Google
          </p>
        </button>
        <div className="w-full flex items-center gap-[16px] my-4 lg:my-8">
          <div className="w-full border border-[#EAECFO] lg:mt-[-1em]" />
          <div className="text-[16px] leading-[19.2px] font-medium lg:mt-[-1em] text-[#475467]">
            Or
          </div>
          <div className="w-full border border-[#EAECFO] lg:mt-[-1em]" />
        </div>

        <div
          ref={messageRef}
          className="flex justify-center items-center w-full"
        >
          {message ? (
            <Alert className="text-center w-1/2" color="failure">
              {String(message)}
            </Alert>
          ) : null}
        </div>

        <div className="mb-[1em] lg:mb-6 lg:mt-[-0.95em]">
          <form onSubmit={handleSignIn}>
            <div>
              <div className="text-[16px] mb-2 text-[#000000]">Email</div>
              <InputComponent
                placeholder="Email"
                name="email"
                type="text"
                value={formData.email}
                handleChange={handleInputChange}
                error={validationResults.email}
                className="w-full rounded-md md:w-[434px] lg:w-[434px] xl:w-[434px]"
              />
            </div>
            <div className="mt-[1.5em]">
              <div className="text-[16px] mb-2 text-[#000000]">Password</div>
              <InputComponent
                placeholder="Password"
                name="password"
                type="password"
                value={formData.password}
                handleChange={handleInputChange}
                error={validationResults.password}
                className="w-full rounded-md md:w-[434px] xl:w-[434px]"
                imageClassName="ml-[90%] w-[24px] h-[24px] mt-[-36px]"
              />
            </div>

            <div className="w-full md:w-[434px]">
              <div className="mt-[2em]">
                <UI.Button
                  text="Log in"
                  isLoading={loading}
                  variant="secondary"
                />
              </div>
            </div>

            <div className="text-[#044E97] font-medium leading-[19.2px] text-center mt-4">
              <Link to="/forget-password">
                {t("onboarding.Forgotpassword")}
              </Link>
            </div>

            <p className="text-[16px] lg:text-[16px] leading-[16.8px] text-center mt-6 lg:mt-8 text-[#344054]">
              Don’t have an account?{" "}
              <span
                onClick={() => handleTabChange(true)}
                className="text-[#044E97] cursor-pointer font-semibold text-[16px]"
              >
                Create account
              </span>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export { LoginView };
