import React, { ChangeEvent, useEffect } from "react";
import UploadIcon from "../../../assets/icons/UploadIcon";
import InfoIcon from "../../../assets/icons/InfoIcon";
import { generateUploadURL } from "../../../backend-services";
import { Button, SidebarDrawer } from "../../common";
import { CloseIcon, PDFIcon, RemoveIcon } from "../../../assets/icons";
import { toast } from "react-hot-toast";
import { shortenFileName } from "../../../utils";
import { IInvoice, TransactionType } from "../../../@types";
import ExpenseUploadSuccess from "./ExpenseUploadSuccess";
import useNotifications from "../../../hooks/UseNotifications";
import { handleRecordPayment } from "../../../hooks/payment/handleRecordPayment";
import FileUploadComponent from "../../expense/FileUploadComponent";
import { useTranslation } from "react-i18next";

interface UploadRecordPaymentProps {
  entity?: IInvoice;
  onRecordEntity: any;
  paymentType: TransactionType;
  closeModal: () => void;
}

const UploadTransactionEvidence: React.FC<UploadRecordPaymentProps> = ({
  entity,
  onRecordEntity,
  paymentType,
  closeModal,
}) => {
  const { t } = useTranslation("invoices");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [file, setFile] = React.useState<File | null>();
  const [error, setError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [triggerAction, setTriggerAction] = React.useState<boolean>(false);
  useNotifications(triggerAction);

  const handleUpload = (uploadedFile: File) => {
    setFile(uploadedFile);
    setError(undefined);
  };

  const handleRecordPaymentWrap = async () => {
    if (!file) {
      return setError("Please upload receipt.");
    }
    setError(undefined);
    try {
      setIsLoading(true);
      await handleRecordPayment(file, entity, paymentType);
      setIsLoading(false);
      setSuccess(true);
      setTriggerAction(true);
    } catch (e: any) {
      setIsLoading(false);
      setError(e.message);
      toast.error("An error occured while uploading image: " + e.message);
    }
  };

  useEffect(() => {
    return () => {
      setTriggerAction(false);
    };
  }, [handleRecordPaymentWrap]);
  const handleCloseModal = () => {
    closeModal();
  };

  if (success) {
    if (paymentType === "revenue") {
      return (
        <ExpenseUploadSuccess
          closeModal={handleCloseModal}
          text={"Go back to Invoice"}
          navigateTo={"invoice"}
        />
      );
    } else if (paymentType === "expenses") {
      return (
        <ExpenseUploadSuccess
          closeModal={handleCloseModal}
          text={"Go back to Expense"}
          navigateTo={"expense"}
        />
      );
    }
  }

  const buttonText = isLoading
    ? "Recording..."
    : entity
    ? "Record Purchase"
    : "Upload Expense";

  return (
    <>
      <div className=" z-auto">
        <div className="mt-[43px] text-center leading-5">
          <p className="font-semibold"> </p>
          <p></p>
        </div>
        {error ? (
          <div className="text-center p-2 bg-red-300 text-red-700 font-medium mb-4">
            {error}
          </div>
        ) : null}

        <div className="flex justify-center bg-[#F9FAFB] mt-[13px] h-[100px]">
          <FileUploadComponent onUpload={handleUpload} />
        </div>

        {file && (
          <div className="flex items-center justify-between text-lg mt-[2em]">
            <div className="flex">
              <PDFIcon />
              <span className="text-[#0500F9] font-medium ">
                {shortenFileName(file)}
              </span>
            </div>
            <a href="#close" onClick={() => setFile(undefined)}>
              <RemoveIcon />
            </a>
          </div>
        )}

        <div className="flex justify-center pt-[20px] pb-[20px] text-[16px] font-semibold">
          <button
            className="flex justify-center py-[1em] px-[6em] text-[16px] font-semibold  text-[#044E97] bg-white border border-[#044E97] w-full text-center rounded-md"
            onClick={handleRecordPaymentWrap}
            disabled={isLoading}
          >
            {buttonText}
          </button>
        </div>
      </div>
    </>
  );
};

export { UploadTransactionEvidence };
