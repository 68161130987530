import { useEffect, useState } from "react";
import { ICreateSalesData } from "../../@types";

export const useInvoiceCalculations = (
  formData: ICreateSalesData,
  isRetail: boolean,
) => {
  const [subTotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [calcTax, setCalcTax] = useState(0);
  const [calcDiscount, setCalcDiscount] = useState(0);

  useEffect(() => {
    let calculatedSubTotal = 0;
    if (isRetail && formData.inventories && !formData.lineItems) {
      for (let inventory of formData?.inventories) {
        if (inventory.variations && inventory.variations.length > 0) {
          for (let variation of inventory.variations) {
            calculatedSubTotal += variation.quantity * variation.price;
          }
        } else {
          calculatedSubTotal +=
            (inventory.rate || 0) * (inventory.quantity || 0);
        }
      }
    } else if (
      !isRetail &&
      formData.services &&
      formData.lineItems &&
      formData.lineItems.length < 1
    ) {
      for (const service of formData.services) {
        calculatedSubTotal += service.price || 0;
      }
    } else if (formData.lineItems) {
      for (const item of formData.lineItems) {
        calculatedSubTotal += (item.rate || 0) * (item.quantity || 0);
      }
    }

    let discountAmount = calculatedSubTotal * ((formData.discount || 0) / 100);
    let taxAmount = calculatedSubTotal * ((formData.tax || 0) / 100);
    let grandTotal = calculatedSubTotal - discountAmount + taxAmount;

    if (formData.exchangeRate) {
      const exchangeRate = formData.exchangeRate;
      calculatedSubTotal /= exchangeRate;
      discountAmount /= exchangeRate;
      taxAmount /= exchangeRate;
      grandTotal /= exchangeRate;
    }

    setSubtotal(calculatedSubTotal);
    setCalcDiscount(discountAmount);
    setCalcTax(taxAmount);
    setTotal(grandTotal);
  }, [formData, isRetail]);

  return { subTotal, total, calcTax, calcDiscount };
};
