import React, { useEffect, useState } from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import LocationSelector from "../../common/Address";
import { businessInfo } from "../../../@types/onboarding";
import { INDUSTRIES } from "../../../constants/industries";
import { clearFieldError, validatePhoneNumber } from "../../../utils";
import { useUserStore } from "../../../state";
import { validateInfoForm } from "../../../modules/auth/register";
import { DEBOUNCE_DELAY } from "../../../constants/todo";
import { verifyCode } from "../../../utils/verifyCode";
import PhoneInputComponent from "../../common/PhoneInputComponent";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface ValidationResults {
  firstName: string;
  lastName: string;
  businessName: string;
  industry: string;
  code: string;
  address: string;
  state: string;
  city: string;
  phone: string;
  [key: string]: string;
}

const initialFormData = {
  firstName: "",
  lastName: "",
  industry: "",
  businessName: "",
  code: "",
  address: "",
  state: "",
  city: "",
  phone: "",
};

interface BusinessInfoModalProps {
  setShowBusinessInfo: (ShowBusinessInfo: boolean) => void;
}

const BusinessInfoModal = ({ setShowBusinessInfo }: BusinessInfoModalProps) => {
  const user = useUserStore((state) => state.user);
  const initialPhoneNumber = "+234";
  const dropDownArray = INDUSTRIES;
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [state, setState] = React.useState("");
  const [city, setCity] = React.useState("");
  const [debouncedCode, setDebouncedCode] = useState("");
  const [verificationResult, setVerificationResult] = useState<string | null>(
    null
  );
  const [formData, setFormData] = useState<businessInfo>(initialFormData);
  const [validationResults, setValidationResults] =
    useState<ValidationResults>(initialFormData);
  const updateProfile = useCompanyStore((state) => state.updateCompanyProfile);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (name === "code") {
      setDebouncedCode(value);
    }
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleDropDownChange = (name: string, selectedValue: string) => {
    setFormData({ ...formData, industry: selectedValue });
    clearFieldError(name, validationResults, setValidationResults);
  };

  const handleLocationChange = (selectedState: string, cities: string[]) => {
    setState(selectedState);
    setCity(cities.length > 0 ? cities[0] : "");
    const updatedFormData = {
      ...formData,
      state: selectedState,
      city: cities.length > 0 ? cities[0] : "",
    };
    setFormData(updatedFormData);
  };

  const handlePhoneNumberChange = (value: string) => {
    if (value) {
      const trimmedValue = value.trim();
      const normalizedValue = trimmedValue.replace(/\D/g, "");

      const isValidPhoneNumber = validatePhoneNumber(normalizedValue);

      if (isValidPhoneNumber) {
        setFormData({ ...formData, phone: "+" + normalizedValue });
        setValidationResults({ ...validationResults, phone: "" });
      } else {
        setValidationResults({
          ...validationResults,
          phone: "Invalid phone number format",
        });
      }
    } else {
      setFormData({ ...formData, phone: "" });
      setValidationResults({ ...validationResults, phone: "" });
    }
  };

  const handlePhoneNumberKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.value.length >= 17) {
      event.preventDefault();
    }
  };

  const handleSave = async () => {
    let hasErrors = false;
    const updatedValidationResults = { ...validationResults };
    Object.keys(formData).forEach((key) => {
      if (key !== "code" && key !== "phone") {
        const value = formData[key];
        const error = validateInfoForm(key, value, formData, validationResults)[
          key
        ];
        updatedValidationResults[key] = error;

        if (value?.trim() === "" || error !== "") {
          hasErrors = true;
        }
      }
    });

    if (hasErrors) {
      setIsSubmitted(true);
      setValidationResults(updatedValidationResults);
    } else {
      setIsLoading(true);
      try {
        const response = await updateProfile({
          firstName: formData.firstName,
          lastName: formData.lastName,
          industry: formData.industry,
          address: formData.address,
          state: formData.state,
          city: formData.city,
          code: formData.code || "",
          phone: formData.phone,
          businessName: formData.businessName,
        });
        setShowBusinessInfo(false);
        setIsLoading(false);
      } catch (error) {
        setShowBusinessInfo(false);
        throw new Error("Profile update failed");
      }
    }
  };

  useEffect(() => {
    const handleVerification = async () => {
      if (debouncedCode.length >= 7) {
        setVerificationResult("Verifying registration code...");
        const result = await verifyCode(debouncedCode);
        setVerificationResult(result);
      } else {
        setVerificationResult(null);
      }
    };

    if (debouncedCode.length >= 7) {
      const handler = setTimeout(handleVerification, DEBOUNCE_DELAY);
      return () => clearTimeout(handler);
    } else {
      setVerificationResult(null);
    }
  }, [debouncedCode]);

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        firstName: user.firstName,
        lastName: user.lastName,
        businessName: user.businessName,
        city: user.city,
        state: user.state,
        address: user.address,
        code: user.code,
        phone: user.phone,
        industry: user.industry,
      });
    }
  }, [user]);

  return (
    <div className="bg-gray-900 bg-opacity-30 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-center justify-center">
      <div className="relative max-h-[calc(100vh-4rem)] mt-[-50px] w-[350px] md:w-[550px] lg:w-[650px] p-4 md:p-6 bg-white rounded-2xl overflow-y-auto">
        <div className="text-[#212121]">
          <h3 className="text-[18px] md:text-[20px] leading-6 font-semibold">
            Business Information
          </h3>
          <div className="space-y-5 mt-8">
            <div className="md:flex gap-5 w-full">
              <div className="mt-5 md:mt-0 w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    First Name
                  </p>
                  <p className="leading-5 text-[#F04438]">*</p>
                </div>
                <InputComponent
                  placeholder="Peru"
                  name="firstName"
                  type="text"
                  handleChange={handleInputChange}
                  value={formData.firstName}
                  error={validationResults.firstName}
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
              </div>
              <div className="mt-5 md:mt-0 w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    Last Name
                  </p>
                  <p className="leading-5 text-[#F04438]">*</p>
                </div>
                <InputComponent
                  placeholder="Para"
                  name="lastName"
                  type="text"
                  handleChange={handleInputChange}
                  value={formData.lastName}
                  error={validationResults.lastName}
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
              </div>
            </div>

            <div className="md:flex gap-5 w-full">
              <div className="w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    Business Name
                  </p>
                  <p className="leading-5 text-[#F04438]">*</p>
                </div>
                <InputComponent
                  placeholder="Business Name"
                  name="businessName"
                  type="text"
                  handleChange={handleInputChange}
                  value={formData.businessName}
                  error={validationResults.businessName}
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
              </div>

              <div className="mt-5 md:mt-0 w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    Industry
                  </p>
                  <p className="leading-5 text-[#F04438]">*</p>
                </div>
                <DropdownComponent
                  options={dropDownArray}
                  name="city"
                  placeholder="Select an Industry"
                  error={validationResults.industry}
                  onChange={handleDropDownChange}
                  iconClassName="mt-[6px] md:mt-0"
                  dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full placeholder:text-[16px] text-[16px]"
                  selectedValue={selectedValue || user.industry}
                  setSelectedValue={setSelectedValue}
                  isSubmitted={isSubmitted}
                />
              </div>
            </div>

            <div className="md:flex gap-5 w-full">
              <div className="w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    Phone number
                  </p>
                </div>
                <PhoneInputComponent
                  // placeholder={placeholder}
                  initialPhoneNumber={initialPhoneNumber}
                  onPhoneNumberChange={handlePhoneNumberChange}
                  onKeyDown={handlePhoneNumberKeyDown}
                  value={formData.phone}
                  inputClass="onboarding"
                  containerClass="onboarding"
                  disableDropdown
                />
                <div className="relative w-full ">
                  {validationResults.phone &&
                    validationResults.phone.length > 0 && (
                      <p className="absolute left-0 whitespace-nowrap text-red-500 text-[12px]">
                        {validationResults.phone}
                      </p>
                    )}
                </div>
              </div>
              <div className="mt-5 md:mt-0 w-full">
                <div className="flex gap-1 items-center text-[14px]">
                  <p className="text-[14px] leading-5 text-[#000000]">
                    Address
                  </p>
                  <p className="leading-5 text-[#F04438]">*</p>
                </div>
                <InputComponent
                  placeholder="Business Address"
                  name="address"
                  handleChange={handleInputChange}
                  value={formData.address}
                  error={validationResults.address}
                  type="text"
                  className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
                />
              </div>
            </div>

            <div className="w-full">
              <LocationSelector
                display="flex flex-col md:flex-row items-center w-full gap-5"
                onLocationChange={handleLocationChange}
                onError={(error: any) => {}}
                selectedState={state || user.state}
                selectedCity={city || user.city}
                stateError={validationResults.state}
                cityError={validationResults.city}
                isSubmitted={isSubmitted}
              />
            </div>
            <div className="w-full">
              <div className="flex gap-1 items-center text-[14px]">
                <p className="text-[14px] leading-5 text-[#000000]">
                  CAC Registration code
                </p>
              </div>
              <InputComponent
                placeholder="16 digit number"
                name="code"
                type="text"
                value={formData.code || user.code}
                handleChange={handleInputChange}
                className="w-full h-10 md:h-12 placeholder:text-[16px] placeholder:text-[#98A2B3] rounded-lg border-[#D0D5DD]"
              />
              {verificationResult && (
                <p
                  className={`flex mt-[1em] lg:text-[14px] text-[10px] md:text-[12px] py-2 px-2 rounded-md ${
                    verificationResult === "Verifying registration code..."
                      ? "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
                      : verificationResult.includes("failed") ||
                        verificationResult.includes("Invalid registration code")
                      ? "text-[#D82020] border border-[#FF0000] bg-[#FFE2E2]"
                      : "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
                  }`}
                >
                  {verificationResult}
                </p>
              )}
            </div>
          </div>

          <div className="w-full gap-[5%] flex justify-between mt-8 md:mt-10">
            <button
              type="button"
              onClick={() => setShowBusinessInfo(false)}
              className={`text-[#044E97] border border-[#EAECF0] w-full h-12 rounded-md font-semibold`}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleSave}
              disabled={isLoading}
              className={`text-[#ffffff] bg-[#044E97] w-full h-12 rounded-md font-semibold`}
            >
              {isLoading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfoModal;
