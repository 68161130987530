import ExpandLeft from "../../../../assets/icons/ExpandLeft";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, GroupButton, SidebarDrawer } from "../../../common";
import { getInvoiceStatus, history } from "../../../../utils";
import { CloseIcon, DownloadIcon } from "../../../../assets/icons";
import { IInvoice, IQuote } from "../../../../@types";
import { Statuses } from "../../../../constants";
import { useState } from "react";
import { useUserStore } from "../../../../state";
import { useLocation } from "react-router-dom";
import {
  invoiceToReceiptData,
  ReceiptData,
} from "../../../../utils/EscPosEncoder";
import ThermalPrinter from "../../../common/printer";
import AnnotationComponent from "../../../common/Annotation/AnnotationComponent";
import { Annotation } from "../../../../@types/annotation";
import useAnnotations from "../../../../hooks/annotation/useAnnotations";
import { InvoiceDocument } from "../../SalesDocument";
import { ConvertQuoteConfirmationPrompt } from "../ConvertQuoteConfirmation";
import EditIcon from "../../../../assets/icons/EditIcon";
import { DocumentModal } from "../../SalesDocument/DocumentModal";
import PreviewCopy from "../../SalesDocument/PreviewCopy";
import { MarkAsExpiredPrompt } from "../MarkAsExpiredPrompt";
import { isNotAccountant } from "../../../../utils/isNotAccountant";
import { useDownloadQuote } from "../../../../hooks/invoice/useDownloadQuote";
import InvoiceDownloadNotification from "../../invoice/DownloadNotifcation";
import QuoteDownloadNotification from "../DownloadNotification";
import { SubscriptionModal } from "../../../common/Subscription/SubscriptionModal";
import { getStatusClass, getStatusColor } from "../../invoice/Status/status";
import RecordIcon from "../../../../assets/icons/RecordIcon";
import { useCompanyStore } from "../../../../state/useCompanyStore";

interface ViewQuoteProps {
  quote: IInvoice | undefined;
  annotations: Annotation[];
  handleConvertQuote: any;
  handleMarkAsExpired: any;
  isRefunding: boolean;
  customerId: string;
}
const ViewQuote: React.FC<ViewQuoteProps> = ({
  quote,
  handleConvertQuote,
  handleMarkAsExpired,
  isRefunding,
  customerId,
  annotations,
}) => {
  const params = useParams();
  const quoteId = params.quoteNo;
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const freeForm = query.get("freeForm");
  const isFirstTimer = freeForm === "freeForm";
  const downloadButtonRef = React.useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const showPaymentParams = Object.fromEntries(urlParams.entries());
  const [showRecordPayment, setShowRecordPayment] = React.useState(false);
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [showExpiredPrompt, setShowExpiredPrompt] = React.useState(false);
  const [showConvertPrompt, setShowConvertPrompt] = React.useState(false);
  const [, setLoading] = React.useState(false);
  const [receipt, setReceipt] = useState<ReceiptData>();
  const [showModal, setShowModal] = useState(false);
  const [printCounter, setPrintCounter] = useState(0);
  const isRetailBusiness = useCompanyStore((state) => state.isRetailBusiness());
  const annotationsData = useAnnotations(annotations, "Quote");
  const [selectedInvoice, setSelectedInvoice] = useState<IInvoice | null>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showDownloadDirection, setShowDownloadDirection] = useState(true);
  const navigate = useNavigate();
  const { user, roles } = useUserStore();
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();

  const triggerPrint = () => {
    setPrintCounter((prevCounter) => prevCounter + 1);
  };

  React.useEffect(() => {
    if (showPaymentParams.action === "showPayment") {
      setShowRecordPayment(true);
    }
  }, [showPaymentParams.action]);

  React.useEffect(() => {
    if (user && quote) {
      const receipt = invoiceToReceiptData(quote, user, isRetail);
      setReceipt(receipt);
    }
  }, [user]);

  const handleUpdate = (updatedInvoice: IInvoice) => {
    setSelectedInvoice(updatedInvoice);
  };

  const { downloadQuotePDF, isLoadingQuote } = useDownloadQuote({
    //@ts-ignore
    quote: document,
    //@ts-ignore
    quoteId: quoteId,
    onUpdate: handleUpdate,
  });

  const handleDownloadQuotePDF = async () => {
    try {
      setIsDownloading(true);
      await downloadQuotePDF();
    } catch (error) {
      console.error("Error downloading PDF:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  React.useEffect(() => {
    if (isFirstTimer && downloadButtonRef.current) {
      downloadButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFirstTimer]);

  const handlePreviewCopyClick = async () => {
    if (isDownloading) return;
    setIsDownloading(true);
    try {
      await downloadQuotePDF();
    } catch (error) {
      console.error("Error handling download or preview:", error);
    } finally {
      setIsDownloading(false);
    }
    if (isFirstTimer) {
      setShowDownloadDirection(false);
      // setShowDownloadModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleEditClick = () => {
    navigate(`/quotes/edit/${quoteId}`, { state: { quote } });
  };
  return quote ? (
    <>
      <ThermalPrinter receipt={receipt} printCounter={printCounter} />
      {/* Desktop screens */}
      <div className="hidden md:flex items-center justify-between">
        <div className="flex items-center gap-[22px] ">
          <a href="/quotes">
            <h1 className="font-bold text-[20px] flex items-center">
              <div className="mt-[5px] ">
                <ExpandLeft />
              </div>
              {quote.quoteNumber}
            </h1>
          </a>

          <div
            className="quote-status-container"
            style={{
              backgroundColor:
                quote.status === "Expired"
                  ? "#FDB0FF"
                  : quote.status === "Active"
                  ? "#FFFCB0"
                  : "",
              borderRadius: "10px",
            }}
          >
            <span
              style={{ backgroundColor: getStatusColor(quote.status) }}
              className={`${getStatusClass(
                quote.status
              )}  px-1 text-center rounded-sm text-[16px] mt-[-1em] pb-1`}
            >
              {getInvoiceStatus(quote.status)}
            </span>
          </div>
        </div>
        <AnnotationComponent type="quotes" annotationData={annotationsData} />
      </div>
      {/*  mobile screens */}
      <div>
        <div className="flex w-[115%] md:hidden justify-between items-center mx-[-20px] px-[2em]">
          <a href="#goback" onClick={history.back}>
            <div className="mt-[5px]">
              <ExpandLeft />
            </div>
          </a>
          <div>
            <p className="text-[#044E97] text-[14px]">{quote.quoteNumber}</p>
          </div>
          <AnnotationComponent type="quote" annotationData={annotationsData} />
          {/* 
          <div>
            {quote.status !== Statuses.PAID.toLowerCase() && quote.status !== Statuses.DUE.toLowerCase() && (
              <button
                onClick={() => setShowPrompt(true)}
                className="text-[12px] pr-2"
                style={{
                  color:
                    quote.status === Statuses.CANCELLED.toLowerCase() ||
                    quote.status === Statuses.REFUNDED.toLowerCase()
                      ? '#ccc'
                      : '#E02523',
                }}
              >
                Cancel
              </button>
            )}
          </div> */}
        </div>
        <div className="flex justify-end md:hidden"></div>
      </div>
      {/*  mobile screen */}
      <div className="flex md:hidden flex-col justify-center mx-[-10%] mt-2 px-[2em]">
        <div className="flex flex-col sm:w-[200%] lg:w-full">
          <div className="flex items-center gap-[9.8px]">
            <div
              className="quote-status-container"
              style={{
                backgroundColor:
                  quote.status === "Expired"
                    ? "#FDB0FF"
                    : quote.status === "Active"
                    ? "#FFFCB0"
                    : "",
                borderRadius: "10px",
              }}
            >
              <span
                style={{ backgroundColor: getStatusColor(quote.status) }}
                className={`${getStatusClass(
                  quote.status
                )}  px-1 text-center rounded-sm text-[16px] mt-[-1em] pb-1`}
              >
                {getInvoiceStatus(quote.status)}
              </span>
            </div>
          </div>
          <div>
            <div className="mt-[34px]">
              <InvoiceDocument
                type="quote"
                document={quote}
                address={user.address}
                bankData={user.bankData}
                brandColor={user.brandColor}
              />
            </div>
            <div className="mt-[1em]">
              <hr className="mt-[5px]" />
            </div>
          </div>

          {isNotAccountant(roles) && (
            <div className="z-10 mx-auto max-w-[1012px] py-[20px] bottom-[0] mt-[-1em]  w-full px-[32px] ">
              <div className="flex flex-col items-center justify-center">
                {quote &&
                  quote.status &&
                  quote.status.toLowerCase() !==
                    Statuses.EXPIRED.toLowerCase() &&
                  quote.status.toLowerCase() !==
                    Statuses.QUOTE.toLowerCase() && (
                    <div className="flex">
                      <button
                        className="w-[100px] h-[48px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 text-[#044E97] bg-white"
                        onClick={handleEditClick}
                      >
                        <EditIcon />
                        <span>Edit</span>
                      </button>
                      <button
                        className="w-[100px] h-[48px] font-normal rounded-[4px] text-center flex flex-col items-center justify-center gap-1 text-[#044E97] bg-white"
                        onClick={handlePreviewCopyClick}
                      >
                        <DownloadIcon />
                        <span>
                          {isDownloading ? "Downloading..." : "Download"}
                        </span>
                      </button>
                      {showDownloadDirection && isFirstTimer && (
                        <div className="ml-[8em] mt-[-3em] items-end">
                          <QuoteDownloadNotification />
                        </div>
                      )}
                    </div>
                  )}
                <div className="mt-6 flex flex-col justify-center gap-6">
                  <div className="mb-[13px] items-center flex justify-center w-[180px]">
                    <button
                      className={`w-[100%] h-[48px] py-2 font-normal rounded-[4px] text-center ${
                        quote.status === Statuses.QUOTE ||
                        quote.status === Statuses.EXPIRED
                          ? "cursor-not-allowed text-[#A4A4A4] bg-[#F6F6F6] border-[#A4A4A4] border-[1px]"
                          : "cursor-pointer text-[white] bg-[#044E97] border-[1px] border-[#044E97] hover:bg-[#C9EBF355] hover:opacity-[0.7]"
                      }`}
                      onClick={() => {
                        if (
                          ![Statuses.QUOTE, Statuses.EXPIRED].includes(
                            quote.status
                          )
                        ) {
                          setShowConvertPrompt(true);
                        }
                      }}
                      disabled={[Statuses.QUOTE, Statuses.EXPIRED].includes(
                        quote.status
                      )}
                    >
                      Convert to Invoice
                    </button>
                  </div>
                  <div className="mt-[]">
                    <Button
                      onClick={() => setShowExpiredPrompt(true)}
                      text="Mark As Expired"
                      outline
                      size="full"
                      variant="danger"
                      disabled={[Statuses.QUOTE, Statuses.EXPIRED].includes(
                        quote.status
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {quote.status === Statuses.ACTIVE.toLowerCase() && (
            <>
              {showConvertPrompt && (
                <SidebarDrawer
                  title="Convert to Invoice"
                  show={true}
                  onClose={() => setShowConvertPrompt(false)}
                  position={"left"}
                >
                  <ConvertQuoteConfirmationPrompt
                    onConvert={handleConvertQuote}
                    onClose={() => setShowConvertPrompt(false)}
                    showConvertPrompt={showConvertPrompt}
                  />
                </SidebarDrawer>
              )}
            </>
          )}
        </div>
      </div>
      {/* End of mobile screens */}
      {/* Desktop screens */}
      <div className=" hidden md:flex justify-center">
        <div className="mt-[1em] w-[100%] pr-[2em]">
          <InvoiceDocument
            type="quote"
            document={quote}
            address={user.address}
            bankData={user.bankData}
            brandColor={user.brandColor}
          />
        </div>
      </div>
      {isNotAccountant(roles) && (
        <div className="z-100 mx-auto max-w-[1190px] py-[20px] sticky bottom-[0] mt-[32px] bg-[#F2F9FD] w-full border border-[#D6DADD] px-[32px] hidden md:block gap-3">
          <div className="flex flex-col items-center justify-center gap-6">
            {/* Button Wrapper */}
            <div className="flex flex-wrap items-center justify-center gap-[1em]">
              {/* Convert to Invoice Button */}
              <div className="w-[215px] h-[48px]">
                <button
                  className={`w-full h-full font-normal rounded-[4px] flex text-center items-center  ${
                    [Statuses.QUOTE, Statuses.EXPIRED].includes(quote.status)
                      ? "cursor-not-allowed text-[#A4A4A4] bg-[] border-[#A4A4A4] border-[1px] pl-2"
                      : "cursor-pointer text-[#044E97] bg-[] border-[1px] border-[#044E97] pl-2"
                  }`}
                  onClick={() => {
                    if (
                      ![Statuses.QUOTE, Statuses.EXPIRED].includes(quote.status)
                    ) {
                      setShowConvertPrompt(true);
                    }
                  }}
                  disabled={[Statuses.QUOTE, Statuses.EXPIRED].includes(
                    quote.status
                  )}
                >
                  Convert to Invoice
                  <span className="ml-[1em]">
                    <RecordIcon />
                  </span>
                </button>
              </div>

              {/* Edit Button */}
              {quote?.status?.toLowerCase() !==
                Statuses.EXPIRED.toLowerCase() &&
                quote?.status?.toLowerCase() !==
                  Statuses.QUOTE.toLowerCase() && (
                  <div className=" h-[48px] flex justify-center border border-[#044E97] rounded-lg ">
                    <GroupButton
                      buttons={[
                        {
                          text: "Edit",
                          icon: <EditIcon />,
                          onClick: handleEditClick,
                        },
                      ]}
                    />
                  </div>
                )}

              {/* Download Button */}
              {quote?.status?.toLowerCase() !==
                Statuses.EXPIRED.toLowerCase() &&
                quote?.status?.toLowerCase() !==
                  Statuses.QUOTE.toLowerCase() && (
                  <div className=" h-[48px] border border-[#044E97] rounded-lg ">
                    <GroupButton
                      buttons={[
                        {
                          text: isDownloading ? "Downloading..." : "Download",
                          icon: <DownloadIcon />,
                          onClick: handlePreviewCopyClick,
                        },
                      ]}
                    />
                  </div>
                )}

              {/* Mark as Expired Button */}
              <div className="w-[215px] h-[48px] flex">
                <Button
                  onClick={() => setShowExpiredPrompt(true)}
                  text="Mark As Expired"
                  outline
                  size="full"
                  variant="danger"
                  disabled={[Statuses.QUOTE, Statuses.EXPIRED].includes(
                    quote.status
                  )}
                />
                {/* <span className=''><CloseIcon/></span> */}
              </div>
            </div>

            {/* Download Notification */}
            {showDownloadDirection && isFirstTimer && (
              <div className="mt-4">
                <QuoteDownloadNotification />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="mt-[em] ">
        {showModal && (
          <DocumentModal>
            <PreviewCopy
              document={quote}
              type="quote"
              address={user.address}
              bankData={user.bankData}
              onClose={handleCloseModal}
              businessName={user.businessName}
              brandLogo={user.brandLogo || ""}
              brandColor={user.brandColor}
              code={user.code}
              phone={user.phone}
              note={quote.description}
            />
          </DocumentModal>
        )}
      </div>{" "}
      {showConvertPrompt && (
        <SubscriptionModal>
          <ConvertQuoteConfirmationPrompt
            onConvert={handleConvertQuote}
            onClose={() => setShowConvertPrompt(false)}
            showConvertPrompt={showConvertPrompt}
          />
        </SubscriptionModal>
      )}
      {showExpiredPrompt && (
        <SubscriptionModal>
          <MarkAsExpiredPrompt
            onExpired={handleMarkAsExpired}
            onClose={() => setShowExpiredPrompt(false)}
            showExpiredPrompt={showExpiredPrompt}
          />
        </SubscriptionModal>
      )}
    </>
  ) : null;
};

export { ViewQuote };
