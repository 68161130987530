import React, { useCallback } from "react";
import cx from "classnames";
import toast from "react-hot-toast";
import { CloseIcon } from "../../assets/icons";
import { editCustomer } from "../../backend-services";
import CreateForm from "./Create/CreateForm";
import { CustomerT } from "../../types";
import { ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";

interface EditCustomerProps {
  show: boolean;
  onClose?: () => void;
  customer: CustomerT;
  customerId: string;
  showIsLoading: any;
  hideIsLoading: any;
  onEditSuccess: (updatedData: CustomerT) => void;
}

const EditCustomer = ({
  show,
  onClose,
  customer,
  customerId,
  showIsLoading,
  hideIsLoading,
  onEditSuccess,
}: EditCustomerProps) => {
  const { t } = useTranslation("customer");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [, setErrorMessage] = React.useState("");
  const [backendErrorMessage, setBackendErrorMessage] = React.useState("");
  const [formData, setFormData] = React.useState<ICustomer>(
    customer as ICustomer
  );

  const handleEditSuccess = (updatedData: CustomerT) => {
    onEditSuccess && onEditSuccess(updatedData);
  };

  const handleEditCustomer = useCallback(
    async (customer: CustomerT) => {
      setIsLoading(true);
      if (!customer.phone) {
        setErrorMessage("Please input a phone number");
        setBackendErrorMessage("");
        setIsLoading(false);
        return;
      }
      try {
        const data = await editCustomer(customerId, customer);
        // setIsLoading(false);
        setIsOpen(false);

        toast.success("Customer details updated successfully!");
        // const isLoaded = showIsLoading()
        handleEditSuccess(data);
        onClose && onClose();
      } catch (e) {
        setErrorMessage("Unable to update customer.");
        setIsLoading(false);
      }
    },
    [customer, customerId, handleEditSuccess, onClose]
  );

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="lg:hidden md:hidden fixed top-0 right-0 left-0 z-50 h-full overflow-y-auto overflow-x-hidden items-start justify-end flex bg-gray-900 bg-opacity-50 "
      >
        <div
          className={cx({
            "relative z-50 ": true,
            "p-8 max-w-lg bg-white shadow h-[200vh] 540:h-[600vh] lg:h-[200vh] xl:h-[200vh]":
              true,
          })}
        >
          {/* MODAL HEADER */}
          <div className="flex items-center justify-between">
            <h3 className="text-[20px] font-semibold ">{"Edit Customer"}</h3>

            <button
              aria-label="Close"
              className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
              type="button"
              onClick={handleClose}
            >
              <CloseIcon color="#212121" />
            </button>
          </div>

          {/* MODAL BODY */}
          <div className="lg:hidden md:hidden mt-10 w-full">
            <CreateForm
              isLoading={isLoading}
              show={true}
              onSubmit={handleEditCustomer}
              buttontitle={"Update Changes"}
              customer={customer}
              setFormData={setFormData}
            />
          </div>
        </div>
      </div>
      <div className="hidden md:block">
        <div className="fixed top-0 left-0 w-screen h-screen bg-gray-800 bg-opacity-30 flex items-center justify-center z-10 ">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <CreateForm
              isLoading={isLoading}
              show={true}
              customer={customer}
              onSubmit={handleEditCustomer}
              buttontitle={"Update Changes"}
              backendErrorMessage={backendErrorMessage}
              setFormData={function (
                value: React.SetStateAction<ICustomer>
              ): void {
                throw new Error("Function not implemented.");
              }}
              onClose={handleClose}
              title={t("customer.editCust")}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { EditCustomer };
