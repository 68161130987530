import React, { useEffect, useRef, useState } from "react";
import ShowDropdownIcon from "../../../../assets/icons/ShowDropdownIcon";

interface Option {
  value: string;
  label: string;
  name?: string;
  icon?: React.ReactNode;
}

export interface DropdownProps {
  options: Option[];
  name?: string;
  value?: string;
  error?: any;
  activeValue?: string;
  selectedValue?: string;
  setSelectedValue?: (value: string) => void;
  showOptions?: boolean;
  setShowOptions?: (value: boolean) => void;
  setCategoryError?: (value: string) => void;
  placeholder?: string;
  onChange?: (name: string, value: string) => void;
  onFilter?: (value: string) => void;
  isSubmitted?: boolean;
  dropdownClassName?: string;
  iconClassName?: string;
  disabled?: boolean;
  className?: string;
  label?: string;
  handlevariation?: (index: any, name: string, value: string) => void;
  variationIndex?: number;
  dropdownWidth?: string;
  styles?: {
    option: (
      styles: any,
      { data, isDisabled, isFocused, isSelected }: any
    ) => any;
  };
  optionStyles?: {
    [key: string]: React.CSSProperties;
  };
}

const MobileDropdownComponent: React.FC<DropdownProps> = ({
  name,
  options,
  value,
  error,
  placeholder,
  selectedValue,
  setSelectedValue,
  onChange,
  onFilter,
  isSubmitted,
  dropdownClassName,
  iconClassName,
  disabled,
  label,
  handlevariation,
  variationIndex,
  styles,
  optionStyles,
  activeValue,
  dropdownWidth,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const iconContainerClassName = showOptions
    ? "absolute top-0 right-[5%] mt-4 md:mt-5 flex items-center"
    : "absolute top-0 right-[5%] mt-4 md:mt-5 flex items-center";

  const inputClassName = `h-[48px] border border-[#A4A4A4] placeholder:text-[16px] placeholder-gray-500 rounded-lg p-2 focus:border-[#138EFF] focus:outline-none shadow-lg shadow-gray-800 ${
    error && !selectedValue ? "ring-1 ring-red-500" : ""
  } ${dropdownClassName} ${selectedValue ? "ring-[0.5px] ring-gray-300" : ""} ${
    styles ? "w-[108px]" : ""
  }`;

  return (
    <div className="relative" ref={dropdownRef}>
      <div
        className="relative"
        onClick={() => {
          if (!disabled) {
            setShowOptions(!showOptions);
          }
        }}
      >
        <input
          className={inputClassName}
          value={label || selectedValue}
          placeholder={placeholder}
          readOnly
          disabled={disabled}
          style={{ width: dropdownWidth }}
        />
        <div className={iconContainerClassName}>
          <ShowDropdownIcon iconClassName={iconClassName} />
        </div>
      </div>

      {showOptions && (
        <ul
          className={`z-50 absolute ${dropdownClassName}`}
          style={{
            backgroundColor: "#F9F9F9",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            width: "100%",
            padding: "0",
            listStyle: "none",
            marginTop: "8px", // Ensures dropdown is spaced from the input
          }}
        >
          {options.map((option, index) => (
            <li
              key={option.value}
              className={`py-2 px-2 cursor-pointer items-center font-light flex justify-between focus:border-gray-300 focus:outline-none text-[14px] ${
                hoveredIndex === index ? "hover:bg-gray-300" : ""
              }`}
              style={{
                backgroundColor: hoveredIndex === index ? "#E5E5E5" : "#F9F9F9",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                transition: "background-color 0.3s",
                fontWeight: option.value === activeValue ? "bold" : "normal",
              }}
              onClick={() => {
                setShowOptions(false);
                if (setSelectedValue) {
                  setSelectedValue(option.value);
                }
                if (onChange) {
                  onChange(name || "", option.value);
                }
                if (onFilter) {
                  onFilter(option.value);
                }
                if (handlevariation) {
                  handlevariation(variationIndex, name || "", option.value);
                }
              }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <div className="flex items-center">
                {option.icon && (
                  <span className="mr-2">
                    <span className="icon-container inline-block align-middle h-4 lg:w-4">
                      {option.icon}
                    </span>
                  </span>
                )}
                <span style={optionStyles && optionStyles[option.value]}>
                  {option.label}
                </span>
              </div>
              <span className="ml-2 ">{option.name}</span>
            </li>
          ))}
        </ul>
      )}
      {isSubmitted && !selectedValue && error && (
        <p className="text-red-500 absolute whitespace-nowrap text-[12px]">
          {error}
        </p>
      )}
    </div>
  );
};

export { MobileDropdownComponent };
