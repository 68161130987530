import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { CloseIconBig } from "../../../../assets/icons";
import Card from "./Card";
import { useNotificationStore } from "../../../../state/useNotificationStore";

interface NotificationProps {
  show: boolean;
  onClose?: () => void;
}

const Notification: React.FC<NotificationProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOpen(props.show);
  }, [props.show]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        handleClose();
      }
    };
    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  const notifications = useNotificationStore((state) => state.notifications);
  const batchUpdateNotifications = useNotificationStore(
    (state) => state.batchUpdateNotifications
  );

  const UpdateNotifications = () => {
    const updateNotifications = notifications?.map((notification) => ({
      ...notification,
      isRead: true,
    }));
    batchUpdateNotifications(updateNotifications);
  };

  const handleClose = () => {
    setIsOpen(false);
    props.onClose && props.onClose();
  };

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="fixed w-[498px] top-0 right-0 bottom-0 z-50 h-full overflow-y-auto overflow-x-hidden items-start flex "
      >
        <div
          ref={modalRef}
          onClick={() => {}}
          className={cx({
            "relative  z-50 h-full w-full": true,
            "p-3 max-w-lg bg-white shadow": true,
            "animate-slide-in-right": isOpen,
          })}
        >
          {/* MODAL HEADER */}
          <div className="flex items-center justify-between p-3">
            <h3 className="text-[25px] font-bold ml-[6em]">Notifications</h3>
            <button
              aria-label="Close"
              className="ml-auto text-[35px] inline-flex items-center rounded-lg bg-transparent text-red-400"
              type="button"
              onClick={handleClose}
            >
              <CloseIconBig />
            </button>
          </div>

          {/* MODAL BODY */}
          <div className="p-4 pt-6">
            <Card />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { Notification };
