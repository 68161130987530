import { create } from "zustand";
import { IProfile, ISetReminder, IUpdateProfile } from "../@types";
import { profileService } from "../services";
import i18n from "../i18n";
import {
  BUSINESS_TYPE_RETAIL,
  STORAGE_USER_DATA,
  BUDGET,
  BANK_ACCOUNTS,
  REMINDER,
} from "../constants";
import { convertImageToBase64 } from "../utils/convertToBase64";
import { BankData, Budget, BusinessType } from "../types";
import { createBankAccount, updateProfile } from "../backend-services";

interface CompanyState {
 user:any
 setUser: (user: any) => void;
  budget: Budget;
  setBudget: (budget: Budget) => void;
  companyBankAccounts: BankData[];
  setCompanyBankAccounts: (accounts: BankData[]) => void;
  reminder: ISetReminder | null;
  setReminder: (reminder: ISetReminder | null) => void;
  hasReminder: boolean;
  fetchCompanyProfile: () => Promise<IProfile>;
  isRetailBusiness: () => boolean;
  locale: string;
  setLocale: (locale: string) => void;
  brandColor: string;
  updateBusinessType: (type: BusinessType) => Promise<void>;
  updateCompanyProfile: (profileData: IUpdateProfile) => Promise<void>;
  createBankAccount: (data: BankData) => Promise<void>;
}

export const useCompanyStore = create<CompanyState>((set, get) => {
  const parseJSON = (item: string | null, defaultValue: any) => {
    try {
      return item ? JSON.parse(item) : defaultValue;
    } catch (e) {
      console.error("Error parsing JSON:", e);
      return defaultValue;
    }
  };

  const budget = parseJSON(localStorage.getItem(BUDGET), {});
  const companyBankAccounts = parseJSON(localStorage.getItem(BANK_ACCOUNTS), []);
  const reminder = parseJSON(localStorage.getItem(REMINDER), null) as ISetReminder | null;

  return {
    user: {
        businessName: "",
        email: "",
        phone: "",
        address: "",
        id: "",
        firstName: "",
        lastName: "",
        brandLogo: null,
        logoUrl: "",
        state: "",
        city: "",
        code: "",
        industry: "",
        brandColor: "",
        bankAccounts: [],
      },
    budget,
    companyBankAccounts,
    reminder,
    hasReminder: reminder !== null,
    locale: localStorage.getItem("locale") || "en",
    brandColor: "#000000",

    setUser: (user: IProfile & { logoBase64?: string }) => {
          const hasLogoData = user.logoBase64 !== undefined;
          const updatedUser = { ...get().user, ...user };
          if (hasLogoData) updatedUser.logoBase64 = user.logoBase64;
          localStorage.setItem(STORAGE_USER_DATA, JSON.stringify(updatedUser));
          set({ user: updatedUser });
    },
    

    setBudget: (budget: Budget) => {
        const currentBudget = JSON.stringify(budget);
        localStorage.setItem(BUDGET, currentBudget);
        set({ budget });
      },

    setCompanyBankAccounts: (accounts) => {
        localStorage.setItem(BANK_ACCOUNTS, JSON.stringify(accounts));
        set({ companyBankAccounts: accounts });
      },

      setReminder: (reminder: ISetReminder | null) => {
        console.log("Setting reminder:", reminder); 
        if (reminder) {
          localStorage.setItem(REMINDER, JSON.stringify(reminder));
          set({ reminder, hasReminder: true });
        } else {
          localStorage.removeItem(REMINDER);
          set({ reminder: null, hasReminder: false });
        }
        console.log("Updated store state:", get()); 
      },

    fetchCompanyProfile: async () => {
        try {
          const res = await profileService.get(`/companies/profile`);
          const companyProfile = res.data;
  
          if (companyProfile.logoUrl) {
            convertImageToBase64(
              companyProfile.logoUrl,
              (base64) => set({ user: { ...companyProfile, logoBase64: base64 } }),
              () => set({ user: companyProfile }),
            );
          } else {
            set({ user: companyProfile });
          }
          return companyProfile;
        } catch (e: any) {
          console.error("Error fetching user profile:", e);
        }
      },
  
      isRetailBusiness: () => {
        const { user } = get();
        return user?.businessType === BUSINESS_TYPE_RETAIL;
      },

      setLocale: (locale) => {
        i18n.changeLanguage(locale);
        localStorage.setItem("locale", locale);
        set({ locale });
      },
  

      updateBusinessType: async (type: BusinessType) => {
        try {
          await updateProfile({ businessType: type });
          const updatedUser = { ...get().user, businessType: type };
          set({ user: updatedUser });
        } catch (err) {
          console.error("Error updating business type:", err);
          throw err;
        }
      },

    updateCompanyProfile: async (profileData: IUpdateProfile) => {
        try {
          await updateProfile(profileData);
          const updatedUser = { ...get().user, ...profileData };
          set({ user: updatedUser });
        } catch (err) {
          console.error("Error updating business type:", err);
          throw err;
        }
      },

      createBankAccount: async (data: BankData) => {
        try {
          const result = await createBankAccount(data);
          const currentUser = get().user;
          const updatedUser = {
            ...currentUser,
            bankAccounts: [...(currentUser.bankAccounts || []), ...result.data],
          };
          set({ user: updatedUser });
        } catch (err) {
          console.error("Error creating bank account:", err);
          throw err;
        }
      },
  };
});
