import React, { useState, useRef, useEffect } from "react";
import { ICreateSalesData } from "../../../../@types";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import DateIcon, { SmallDateIcon } from "../../../../assets/icons/Date";
import { InputComponent } from "../../../core/Forms";
import { useTranslation } from "react-i18next";

interface InvoiceDateProps {
  handleDropDownChange?: (name: string, value: string) => void;
  formData: any;
  // formData: ICreateSalesData;
  setFormData?: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  selectedCurrencyLabel?: string;
  validationResults: InvoiceErrors;
  setValidationResults?: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  toolTipText?: string;
  toolTipText2?: string;
  handleInputChange: (event: { target: { name: any; value: any } }) => void;
}

const InvoiceDate: React.FC<InvoiceDateProps> = ({
  formData,
  validationResults,
  toolTipText,
  handleInputChange,
  toolTipText2,
}) => {
  const { t } = useTranslation("invoices");
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  return (
    <>
      {/* Desktop */}
      <div className="hidden lg:block md:block mt-[2em]">
        <div className="flex  md:gap-5 w-full">
          <div className="w-full">
            <div className="flex gap-1">
              <label
                htmlFor="issueDate"
                className="lg:text-[16px] md:text-[14px]"
              >
                {t("invoiceDate")}
              </label>
              {toolTipText && (
                <div
                  className="relative mt-[0.3em] md:mt-[0.16em] biglg:mt-[0.3em] xl:mt-[0.3em]"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <Tooltip />
                  {isHovered && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10 biglg:mt-[2em]">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <InputComponent
              type="date"
              placeholder="Issue Date"
              value={formData.issueDate}
              name="issueDate"
              handleChange={handleInputChange}
              restrictFutureDates={true}
              className="w-[200px]"
            />
            {validationResults.issueDate && (
              <div className="mt-2 ml-4">
                <span className="text-red-500">
                  {validationResults.issueDate}
                </span>
              </div>
            )}
          </div>
          <div className="w-[200px] mr-[3em]">
            <div className="flex gap-1">
              <label htmlFor="invoiceDueAt" className="xl:text-[16px] ">
                {t("dueDate")}
              </label>

              {toolTipText2 && (
                <div
                  className="relative mt-[0.3em] biglg:mt-[-0.1em] xl:mt-[0.2em] md:mt-[0.16em]"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  <Tooltip />
                  {isHovered2 && (
                    <div className="absolute w-[204px] left-[-5.7em] z-10">
                      <div className="flex items-center justify-center">
                        <svg
                          width="18"
                          height="15"
                          viewBox="0 0 18 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 0L17.6603 15H0.339746L9 0Z"
                            fill="#FFF4C6"
                          />
                        </svg>
                      </div>
                      <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                        {toolTipText2}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <InputComponent
              type="date"
              placeholder="Due Date"
              value={formData.invoiceDueAt}
              name="invoiceDueAt"
              handleChange={handleInputChange}
              restrictPreviousDates={false}
              className="w-[200px]"
            />
            {validationResults.invoiceDueAt && (
              <div className="mt-2 xl:text-[14px] lg:text-[12px] biglg:text-[12px] md:text-[11px] ">
                <span className="text-red-500">
                  {validationResults.invoiceDueAt}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="py-5 border-y-[2px] border-gray-200 md:hidden w-full flex gap-5 items-center">
        <div className="w-full">
          <label
            htmlFor="issueDate"
            className="text-[14px] leading-5 font-medium text-[#000000]"
          >
            Invoice date:
          </label>
          <div className="">
            <InputComponent
              type="date"
              placeholder="Issue Date"
              value={formData.issueDate}
              name="issueDate"
              handleChange={handleInputChange}
              restrictFutureDates={true}
              className="w-full"
            />
          </div>
          {validationResults.issueDate && (
            <div className="mt-2 ml-4">
              <span className="text-red-500">
                {validationResults.issueDate}
              </span>
            </div>
          )}
        </div>

        <div className="w-full">
          <label
            htmlFor="invoiceDueAt"
            className="text-[14px] leading-5 font-medium text-[#000000]"
          >
            {t("dueDate")} <span className="text-red-500">*</span>
          </label>
          <div className="">
            <InputComponent
              type="date"
              placeholder="Due Date"
              value={formData.invoiceDueAt}
              name="invoiceDueAt"
              handleChange={handleInputChange}
              restrictPreviousDates={false}
              className="w-full"
              error={validationResults.invoiceDueAt}
            />
          </div>
          {/* {validationResults.invoiceDueAt && (
            <div className="mt-[em] text-[9px]">
              <span className="text-red-500">
                {validationResults.invoiceDueAt}
              </span>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default InvoiceDate;
