import { customerService } from "../services";
import { toast } from "react-hot-toast";
import { CustomerT } from "../types";
import { handleError, withRetry } from "../utils";

export const createCustomer = async (data: CustomerT) => {
  return withRetry(async () => {
    const res = await customerService.post("customers", data);
    return res.data;
  }).catch(handleError);
};

export const getCustomer = async (id: string) => {
  return withRetry(async () => {
    const res = await customerService.get(`/customers/${id}`);
    return res.data;
  }).catch(handleError);
};

export const editCustomer = async (id: string, data: CustomerT) => {
  return withRetry(async () => {
    const res = await customerService.patch(`/customers/${id}`, data);
    return res.data;
  }).catch(handleError);
};

export const deleteCustomer = async (id: string) => {
  return withRetry(async () => {
    const res = await customerService.delete(`/customers/${id}`);
    return res.data;
  }).catch(handleError);
};

export const getCustomerReport = async (
  startDate: string,
  endDate: string,
  downloadType: "csv" | "pdf"
) => {
  return withRetry(async () => {
    const res = await customerService.get(
      `/customers/report?startDate=${startDate}&endDate=${endDate}&downloadType=${downloadType}`
    );
    return res.data;
  }).catch(handleError);
};
