import React from "react";

export const Pen = () => (
  <>
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2255 3.23723C11.8465 2.56442 12.157 2.22801 12.4869 2.03179C13.283 1.55831 14.2633 1.54359 15.0727 1.99295C15.4081 2.17918 15.7282 2.50612 16.3683 3.15998C17.0083 3.81385 17.3284 4.14079 17.5107 4.48345C17.9506 5.31028 17.9362 6.31168 17.4727 7.12494C17.2806 7.46198 16.9513 7.77916 16.2926 8.41352L8.45625 15.9613C7.20813 17.1634 6.58406 17.7645 5.80411 18.0691C5.02416 18.3738 4.16673 18.3513 2.45187 18.3065L2.21854 18.3004C1.69648 18.2867 1.43545 18.2799 1.28372 18.1077C1.13198 17.9355 1.1527 17.6696 1.19413 17.1378L1.21663 16.8491C1.33324 15.3523 1.39154 14.6039 1.68382 13.9312C1.9761 13.2585 2.48026 12.7122 3.48858 11.6198L11.2255 3.23723Z"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M10.3281 3.33203L16.1615 9.16537"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linejoin="round"
      />
      <path
        d="M11.1641 18.332L17.8307 18.332"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </>
);

export const PenSmall = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.4618 5.8739L13.836 4.4997L13.8361 4.49967C14.0705 4.26519 14.1878 4.14794 14.2722 4.03574C14.8083 3.32353 14.8083 2.34253 14.2722 1.63032C14.1878 1.51812 14.0705 1.40088 13.8361 1.16639L13.836 1.16636L13.836 1.16634C13.6015 0.931854 13.4843 0.814608 13.3721 0.730161C12.6599 0.194113 11.6789 0.194113 10.9667 0.730161C10.8545 0.814608 10.7372 0.931853 10.5027 1.16634L10.5027 1.16636L9.10916 2.55991C9.90609 3.94013 11.0628 5.08789 12.4618 5.8739ZM7.65457 4.0145L2.19241 9.47665C1.76735 9.90171 1.55482 10.1142 1.41509 10.3753C1.27535 10.6364 1.21641 10.9312 1.09852 11.5206L0.649789 13.7643C0.583267 14.0969 0.550006 14.2632 0.644614 14.3578C0.739222 14.4524 0.905526 14.4191 1.23813 14.3526H1.23814L3.48179 13.9039C4.07124 13.786 4.36596 13.727 4.62706 13.5873C4.88816 13.4476 5.10069 13.235 5.52575 12.81L11.0035 7.33228C9.6556 6.48155 8.51341 5.34714 7.65457 4.0145Z"
      fill="#0A79B3"
    />
  </svg>
);
