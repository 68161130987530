export function formattedDate(dateText: string | undefined) {
  const isoDate = `${dateText}`;

  // Create a Date object from the ISO string
  const date = new Date(isoDate);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1; // Adding 1 because months are zero-based
  const year = date.getUTCFullYear();

  // Format the date components to "dd/mm/yyyy"
  return `${day.toString().padStart(2, "0")}/${month
    .toString()
    .padStart(2, "0")}/${year}`;
}

export function formatDate(inputDate: string): string {
  const date = new Date(inputDate);

  // Define the month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the day of the month and format with ordinal suffix
  const day = date.getDate();
  const daySuffix = (day: any) => {
    if (day > 3 && day < 21) return "th"; // covers 11th - 19th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format the date as desired
  const formattedDate = `${monthNames[date.getMonth()]} ${day}${daySuffix(
    day
  )}, ${date.getFullYear()}`;

  return formattedDate;
}
