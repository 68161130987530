import { useState, useEffect } from "react";
import { InvoiceStatsData } from "../../@types";
import { getInvoiceStats } from "../../backend-services";

interface ErrorType {
  message?: string;
}

export function useInvoiceStatsData(startDate: Date, endDate: Date) {
  const [invoiceData, setInvoiceData] = useState<InvoiceStatsData | null>(null);
  const [error, setError] = useState<ErrorType | null>(null);
  const fetchData = async () => {
    try {
      const response = await getInvoiceStats(
        startDate.toISOString(),
        endDate.toISOString()
      );
      const data: InvoiceStatsData = {
        totalInvoices: response.totalInvoices,
        totalPaidInvoices: response.totalPaidInvoices,
        totalPendingInvoices: response.totalPendingInvoices,
        totalOverDueInvoices: response.totalOverDueInvoices,
        totalCancelledInvoices: response.totalCancelledInvoices,
        totalDraftInvoice: response.totalDraftInvoice,
        totalInvoiceValue: response.totalInvoiceValue,
        totalRefundedInvoices: response.totalRefundedInvoices,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setInvoiceData(data);
      setError(null);
    } catch (error) {
      setError({ message: "Failed to fetch invoice statistics" });
      setInvoiceData(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return { invoiceData, error };
}
