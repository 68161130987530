import ReactGA from "react-ga4";

const initializeGA = (): void => {
  const trackingId = process.env.REACT_APP_GTM_TRACKING_ID;
  if (trackingId) {
    ReactGA.initialize(trackingId);
  } else {
    console.error("Google Analytics tracking ID not found");
  }
};

export const trackPage = (page: string): void => {
  ReactGA.send({ hitType: "pageview", page });
};

export default initializeGA;
