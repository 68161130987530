import { create } from "zustand";
import { customerService, expenseService } from "../services";
import { CustomerT, newCustomerT } from "../types";
import { debounce } from "lodash";
import { IPaginationMeta } from "../@types";
import { createCustomer } from "../backend-services";

interface CustomerStore {
  customers: CustomerT[];
  newCustomers: CustomerT[];
  isLoading: boolean;
  isLoadingMore: boolean;
  pagination: IPaginationMeta;
  error: boolean;
  range: string;
  fetchCustomers: () => Promise<void>;
  fetchData: (
    startDate?: string,
    endDate?: string
  ) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  createCustomer: (customer: any) => Promise<void>;
  setSearchText: (text: string) => void;
  searchText: string;
}

export const useCustomerStore = create<CustomerStore>((set, get) => ({
  customers: [],
  newCustomers: [],
  isLoading: false,
  isLoadingMore: false,
  error: false,
  range: "",
  searchText: "",
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  handleSort: (text: string) => {
    set({ range: text });
  },

  fetchCustomers: async () => {
    set({ isLoading: true });
    try {
      const res = await customerService.get("/customers");
      set({ customers: res.data.data, isLoading: false });
    } catch (e: any) {
      set((state) => ({ isLoading: false, error: e.message }));
    }
  },
  createCustomer: async (newCustomerData: any) => {
    set({ isLoading: true });
    try {
      const res = await createCustomer(newCustomerData);
      const newCustomer = res;

      set((state) => ({
        customers: [newCustomer, ...state.customers],
        newCustomers: [newCustomer, ...state.newCustomers],
        isLoading: false,
      }));
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },

  fetchData: async (startDate?: string, endDate?: string) => {
    set({ isLoading: true });
    const { searchText, range, newCustomers } = get();
    try {
      let query = `/customers`;
      if (startDate && endDate) {
        query += `?startDate=${startDate}&endDate=${endDate}`;
      }
      if (searchText && searchText.length > 0) query += `search=${searchText}&`;
      if (range.length > 0) query += `range=${range.toLowerCase()}&`;

      const res = await customerService.get(query);
      let combinedCustomers = res.data.data;
      if (searchText && searchText.length > 0) {
        const searchRegex = new RegExp(searchText, "i");
        const filteredNewCustomers = newCustomers.filter(
          (customer) =>
            (customer.name && searchRegex.test(customer.name)) ||
            (customer.email && searchRegex.test(customer.email))
        );
        combinedCustomers = [...filteredNewCustomers, ...combinedCustomers];
      }
      set({
        customers: combinedCustomers,
        pagination: res.data.meta,
        isLoading: false,
      });
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },

  fetchMoreData: async () => {
    if (!get().pagination.hasMoreData || get().isLoadingMore) return;
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;
    try {
      const res = await customerService.get(`/customers?cursor=${cursor}`);
      set((state) => ({
        customers: [...state.customers, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set({ isLoadingMore: false, error: e.message });
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
}));
