// import React from 'react';
// import AddModal from '../modal/AddModal';
// import { Button } from '../../../common';
// import { Option } from '../LineItemsSection';
// import { InputComponent } from '../../../core/Forms';
// import { useNavigate } from 'react-router-dom';

// interface ProductVariantsProps {
//   options: Option[];
//   setCurrentView: React.Dispatch<React.SetStateAction<'selectItem' | 'addInventory' | 'productOptions' | 'productVariants' | 'viewSelectItems' | 'showSelectedItem' | null>>;
// }

// const ProductVariants: React.FC<ProductVariantsProps> = ({ options, setCurrentView  }) => {
//   const handleBackToAddProduct = () => setCurrentView('productOptions');

//   return (
//     <AddModal>
//       <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
//         <div>
//         <div className="flex justify-between items-center mb-5 pb-2">
//         <div onClick={handleBackToAddProduct} className="cursor-pointer">
//               <svg
//                 className="mt-[-2px]"
//                 width="10"
//                 height="16"
//                 viewBox="0 0 10 16"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
//               </svg>
//             </div>

//             <div className="w-full flex justify-center">
//               <p className="font-semibold text-[20px] leading-6 text-[#000000]">Product variants</p>
//             </div>
//           </div>

//           <div className="space-y-3">
//             {options.map((option, index) =>
//               option.values.map((value, valueIndex) => (
//                 <div
//                   key={`${index}-${valueIndex}`}
//                   className="border border-gray-200 bg-[#FCFCFD] p-3 rounded-lg w-full"
//                 >
//                   <div className="flex items-center gap-2 mb-4 font-semibold text-[14px] text-gray-700">
//                     <p className="leading-5">{option.name}</p>
//                     <div className="w-1 h-1 bg-[#D0D5DD] rounded-full" />
//                     <p className="leading-5">{value}</p>
//                   </div>
//                   <div className="space-y-4">
//                     <div className="flex w-full gap-4">
//                       <InputComponent type="number" name="" placeholder="Cost price" className="w-full" />
//                       <InputComponent type="number" name="" placeholder="Selling price" className="w-full" />
//                     </div>
//                     <InputComponent type="number" name="" placeholder="Quantity" className="w-full" />
//                   </div>
//                 </div>
//               )),
//             )}
//           </div>
//         </div>

//         <div
//           style={{
//             boxShadow: '0px -2px 12px 0px rgba(0, 0, 0, 0.06)',
//           }}
//           className="w-full py-5"
//         >
//           <Button variant="secondary" disabled={false} text={'Save Products'} size="full" font="semibold" />
//         </div>
//       </div>
//     </AddModal>
//   );
// };

// export default ProductVariants;

import React, { useEffect, useState } from "react";
import AddModal from "../modal/AddModal";
import { Button } from "../../../common";
import { Option } from "../LineItemsSection";
import { InputComponent } from "../../../core/Forms";
import { useNavigate } from "react-router-dom";
import { Variation } from "../../../../@types";
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
  Key,
} from "react";
import { VariationItem } from "../../../../@types/inventory";
import toast from "react-hot-toast";

interface ProductVariantsProps {
  options: Option[];
  setCurrentView: React.Dispatch<
    React.SetStateAction<
      | "selectItem"
      | "addInventory"
      | "productOptions"
      | "productVariants"
      | "viewSelectItems"
      | "showSelectedItem"
      | null
    >
  >;
  onSave: (savedVariants: VariationItem[]) => void;
  setOptions: (options: Option[]) => void;
}

interface VariantInput {
  name: string;
  type: string;
  costPrice: number | null;
  price: number | null;
  quantity: number | null;
}

const ProductVariants: React.FC<ProductVariantsProps> = ({
  options,
  setCurrentView,
  onSave,
  setOptions,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [variants, setVariants] = useState<VariantInput[]>(
    //@ts-ignore
    options?.flatMap((option) =>
      option?.type?.map((type) => ({
        name: option.name,
        type,
        costPrice: null,
        price: null,
        quantity: null,
      })),
    ),
  );

  const navigate = useNavigate();

  const handleBackToAddProduct = () => {
    console.log("Navigating back to product options");
    setCurrentView("productOptions");
  };

  const handleInputChange = (
    index: number,
    field: keyof Omit<VariantInput, "optionName" | "value">,
    value: number | null,
  ) => {
    console.log(`Updating ${field} for variant at index ${index}:`, value);
    setVariants((prev: any[]) =>
      prev.map((variant: any, idx: number) =>
        idx === index ? { ...variant, [field]: value } : variant,
      ),
    );
  };

  const validateVariants = () => {
    let isValid = true; // Start assuming all fields are valid

    // Loop through each variant
    for (let index = 0; index < variants.length; index++) {
      const variant = variants[index];

      // Check if any field is missing or undefined
      if (
        !variant.name ||
        !variant.type ||
        variant.costPrice === null ||
        variant.price === null ||
        variant.quantity === null
      ) {
        isValid = false; // Set `isValid` to false if validation fails
        console.error(
          `Validation failed for variant at index ${index}. All fields are required.`,
        );
        break; // Exit the loop early since there's no need to check further
      }
    }

    console.log(`Validation result: ${isValid}`);
    return isValid;
  };
  useEffect(() => {
    setIsValid(validateVariants());
  }, [variants.length]);

  const handleSave = () => {
    if (validateVariants()) {
      console.log("Saving the following variants:", variants);
      //@ts-ignore
      onSave(variants); // Pass the saved variants to the parent component or context
      setCurrentView("addInventory"); // Switch to the Add Inventory view
    } else {
      toast.error("Fill all fields");
    }
  };

  useEffect(() => {
    console.log("Variants initialized:", variants);
  }, [variants]);
  return (
    <AddModal>
      <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
        <div>
          <div className="flex justify-between items-center mb-5 pb-2">
            <div onClick={handleBackToAddProduct} className="cursor-pointer">
              <svg
                className="mt-[-2px]"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div className="w-full flex justify-center">
              <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                Product variants
              </p>
            </div>
          </div>

          <div className="space-y-3">
            {variants.map(
              (
                variant: {
                  name:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | Iterable<ReactNode>
                    | null
                    | undefined;
                  type:
                    | string
                    | number
                    | boolean
                    | ReactElement<any, string | JSXElementConstructor<any>>
                    | Iterable<ReactNode>
                    | ReactPortal
                    | Iterable<ReactNode>
                    | null
                    | undefined;
                  costPrice: any;
                  price: any;
                  quantity: any;
                },
                index: Key | null | undefined,
              ) => (
                <div
                  key={index}
                  className="border border-gray-200 bg-[#FCFCFD] p-3 rounded-lg w-full"
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-2 mb-4 font-semibold text-[14px] text-gray-700">
                      <p className="leading-5">{variant.name}</p>
                      <div className="w-1 h-1 bg-[#D0D5DD] rounded-full" />
                      <p className="leading-5">{variant.type}</p>
                    </div>
                    <div
                      onClick={() => {
                        const rest = options?.filter(
                          (i) => i.type !== variant.type,
                        );
                        setOptions(rest);
                        setVariants((prev) =>
                          prev?.filter((i) => i.type !== variant.type),
                        );
                      }}
                      className="text-red-500 mb-4 font-semibold text-[14px] cursor-pointer"
                    >
                      Remove
                    </div>
                  </div>
                  <div className="space-y-4">
                    <div className="flex w-full gap-4">
                      <InputComponent
                        type="number"
                        placeholder="Cost price"
                        className="w-full"
                        value={variant.costPrice || ""}
                        //@ts-ignore
                        handleChange={(e: { target: { value: any } }) =>
                          handleInputChange(
                            //@ts-ignore
                            index,
                            "costPrice",
                            Number(e.target.value),
                          )
                        }
                      />
                      <InputComponent
                        type="number"
                        placeholder="Selling price"
                        className="w-full"
                        value={variant.price || ""}
                        //@ts-ignore
                        handleChange={(e: { target: { value: any } }) =>
                          handleInputChange(
                            //@ts-ignore
                            index,
                            "price",
                            Number(e.target.value),
                          )
                        }
                      />
                    </div>
                    <InputComponent
                      type="number"
                      placeholder="Quantity"
                      className="w-full"
                      value={variant.quantity || ""}
                      handleChange={(e: { target: { value: any } }) =>
                        handleInputChange(
                          //@ts-ignore
                          index,
                          "quantity",
                          Number(e.target.value),
                        )
                      }
                    />
                  </div>
                </div>
              ),
            )}
          </div>
          <div>
            {variants.length == 0 && (
              <h1 className="text-[16px] text-center mt-[20vh] font-semibold">
                Please GO BACK and select a variation!
              </h1>
            )}
          </div>
        </div>

        <div
          style={{
            boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
          }}
          className="w-full py-5"
        >
          <Button
            variant="secondary"
            // disabled={isValid}
            text="Save Products"
            size="full"
            font="semibold"
            onClick={handleSave}
          />
        </div>
      </div>
    </AddModal>
  );
};

export default ProductVariants;
