import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  getInvoiceReport,
  getExpenseReport,
  getCustomerReport,
  getInventoryReport,
  getProfitReport,
} from "../backend-services";
import { Reports } from "../constants/Report";

const useDownloadReport = ({
  type,
  startDate,
  endDate,
  onUpdate,
  customerId,
  status,
  paymentMethod,
  vendorId,
  variation,
}: any) => {
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const downloadFile = async (url: string, fileName: string) => {
    window.open(url, "_blank");
  };

  const handleApiResponse = (data: {
    url: string;
    pdfUrl?: any;
    message?: string;
  }) => {
    if (data.url) {
      downloadFile(data.url, `${type.replace("_", "-")}-Report.pdf`);
      const updatedReport = { ...data, pdfUrl: data.pdfUrl };
      onUpdate(updatedReport);
    } else if (data.message) {
      throw new Error(data.message);
    } else {
      throw new Error("No report records for specified date interval filter");
    }
  };

  const downloadInvoicePDF = async () => {
    setIsLoadingPDF(true);
    try {
      const data = await getInvoiceReport(
        startDate,
        endDate,
        customerId,
        status,
        "pdf"
      );
      handleApiResponse(data);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the PDF");
      toast.error(error.message || "Error downloading the PDF");
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const downloadExpensePDF = async () => {
    setIsLoadingPDF(true);
    try {
      const data = await getExpenseReport(
        startDate,
        endDate,
        paymentMethod,
        vendorId,
        "pdf"
      );
      handleApiResponse(data);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the report");
      toast.error(error.message || "Error downloading the report");
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const downloadCustomerPDF = async () => {
    setIsLoadingPDF(true);
    try {
      const data = await getCustomerReport(startDate, endDate, "pdf");
      handleApiResponse(data);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the PDF");
      toast.error(error.message || "Error downloading the PDF");
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const downloadInventoryPDF = async () => {
    setIsLoadingPDF(true);
    try {
      const data = await getInventoryReport(
        startDate,
        endDate,
        variation,
        "pdf"
      );
      handleApiResponse(data);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the PDF");
      toast.error(error.message || "Error downloading the PDF");
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const downloadProfitLossPDF = async () => {
    setIsLoadingPDF(true);
    try {
      const data = await getProfitReport(startDate, endDate, "pdf");
      handleApiResponse(data);
    } catch (error: any) {
      setErrorMessage(error.message || "Error downloading the PDF");
      toast.error(error.message || "Error downloading the PDF");
    } finally {
      setIsLoadingPDF(false);
    }
  };

  const downloadPDF = () => {
    if (type === Reports.INVOICE_REPORT) {
      return downloadInvoicePDF();
    }
    if (type === Reports.EXPENSE_REPORT) {
      return downloadExpensePDF();
    }
    if (type === Reports.CUSTOMER_REPORT) {
      return downloadCustomerPDF();
    }
    if (type === Reports.INVENTORY_REPORT) {
      return downloadInventoryPDF();
    }
    if (type === Reports.PROFITLOSS_REPORT) {
      return downloadProfitLossPDF();
    }
  };

  return {
    downloadPDF,
    isLoadingPDF,
  };
};

export default useDownloadReport;
