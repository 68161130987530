import React, { useEffect, useState } from "react";
import SelectItem from "./LineItemsSection/SelectItem";
import AddInventory from "./LineItemsSection/AddInventory";
import ProductOptions from "./LineItemsSection/ProductOptions";
import ProductVariants from "./LineItemsSection/ProductVariants";
import { ICreateSalesData, IInventory } from "../../../@types";
import ViewSelectItems from "./LineItemsSection/ViewSelectItems";
import ShowSelectedItem, {
  InventoryFormData,
} from "./LineItemsSection/ShowSelectedItem";
import { VariationItem } from "../../../@types/inventory";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { formatCurrencyMoney, formatMoney } from "../../../utils";
import DesktopLineItems from "./LineItemsSection/DesktopLineItems";
import { useInventoryStore } from "../../../state/useInventoryStore";

export interface Option {
  name: string;
  type: string[];
}

interface LineItemsSectionProps {
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  variations: any[];
  total: number;
}

const LineItemsSection: React.FC<LineItemsSectionProps> = ({
  formData,
  setFormData,
  variations,
  total,
}) => {
  const exchangeRate = formData.exchangeRate ?? 1;
  const [currentView, setCurrentView] = useState<
    | "selectItem"
    | "addInventory"
    | "productOptions"
    | "productVariants"
    | "viewSelectItems"
    | "showSelectedItem"
    | null
  >(null);
  // const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null,
  );
  const [editInventory, setEditInventory] = useState<InventoryFormData | null>(
    null,
  );
  const [quantities, setQuantities] = useState<{ [key: string]: string }>({});
  const inventories = useInventoryStore((state) => state.inventory);
  const [options, setOptions] = useState<Option[]>([{ name: "", type: [""] }]);
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<
    Record<
      string,
      { inventory: any; variationId: string | null; quantity: number }
    >
  >({});
  // Handlers for navigation
  const handleOpenAddInventory = () => setCurrentView("addInventory");
  const handleOpenProductOptions = () => setCurrentView("productOptions");
  const handleOpenVariants = () => setCurrentView("productVariants");

  const setSelectedInventoryAndQuantities = (
    inventory: any,
    updatedQuantities: { [key: string]: string },
  ) => {
    const total = inventory.quantity * inventory.rate;
    const updatedInventory = { ...inventory, total };
    // console.log(updatedInventory, "||", inventory, "||", total);
    setSelectedInventory(updatedInventory);
    setQuantities(updatedQuantities);
  };

  const closeProductOptions = () => {
    setCurrentView(null);
  };
  const [savedVariants, setSavedVariants] = useState<VariationItem[]>([]);
  const handleSave = (savedVariants: VariationItem[]) => {
    setSavedVariants([...savedVariants]);
    setFormData((prev) => ({
      ...prev,
      //@ts-ignore
      variations: [...(prev.inventories?.variations || []), ...savedVariants],
    }));
    setCurrentView(null);
  };

  const openViewSelectItems = (quantities: { [key: string]: string }) => {
    setQuantities(quantities);
    setCurrentView("viewSelectItems");
  };

  const handleEditClick = (data: any, variation?: any) => {
    if (variation) {
      const fData = {
        name: data.name,
        id: data.id,
        amount: data.amount,
        quantity: data.quantity,
        rate: data.rate,
        variations: [variation],
      };
      setEditInventory(data);
    } else {
      setEditInventory(data);
    }
    setCurrentView("showSelectedItem");
  };
  const handleBackToNone = () => setCurrentView(null);
  const handleViewSelection = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      inventories: [...(prev.inventories || []), ...data],
    }));
    setCurrentView(null);
  };

  const closeModal = () => {
    setCurrentView(null);

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };

      return updatedQuantities;
    });
  };

  //@ts-ignore
  const quantity =
    selectedInventory?.variations && selectedInventory.variations.length > 0
      ? //@ts-ignore
        parseFloat(quantities[selectedInventory.variations[0].id] || "0")
      : //@ts-ignore
        parseFloat(
          //@ts-ignore
          quantities[selectedInventory?.id || ""] ||
            selectedInventory?.quantity ||
            "0",
        );
  //@ts-ignore
  const rate = parseFloat(selectedInventory?.rate || "0");
  const price =
    selectedInventory?.variations && selectedInventory.variations.length > 0
      ? parseFloat(selectedInventory.variations[0].price?.toString() || "0")
      : 0;

  const totall = quantity * rate;
  const variationTotal = quantity * price;

  const handleAddItem = () => {
    setCurrentView("selectItem");
  };
  useEffect(() => {
    const rest = formData.inventories.filter((i) => i.id !== "");
    setFormData((prev) => ({ ...prev, inventories: rest }));
  }, []);

  return (
    <div className="w-full py-5 border-y border-gray-300 lg:hidden md:hidden">
      {currentView === null &&
        formData.inventories.filter((i) => i.id !== "").length == 0 && (
          <>
            <p className="text-[14px] font-medium leading-5 text-[#000000]">
              Invoice items
            </p>
            <button
              onClick={() => setCurrentView("selectItem")}
              className="w-full font-medium leading-6 text-gray-700 flex justify-center py-3 my-2 rounded-lg border border-gray-300"
            >
              Select item
            </button>

            <p
              onClick={handleOpenAddInventory}
              className="text-[14px] font-medium leading-5 text-[#044E97]"
            >
              Add new inventory
            </p>
          </>
        )}

      {formData.inventories.filter((i) => i.id !== "").length > 0 && (
        <>
          {formData.inventories
            ?.filter((i) => i.id !== "")
            .map((data) => (
              <div className="flex flex-col justify-between mb-5 ">
                <div>
                  {data?.variations && data?.variations.length > 0 ? (
                    data?.variations.map((variation, index) => {
                      // @ts-ignore

                      const totalForVariation =
                        variation?.quantity * (variation.price || 0);

                      return (
                        <div key={index} className="mb-4">
                          <div className="flex justify-between mb-2">
                            <div>
                              <p className="text-[16px] font-medium text-[#000000] ">
                                {data?.name}
                              </p>
                              <p className="text-gray-600">
                                {variation.name} {variation.type}
                              </p>
                            </div>
                            <p className="font-semibold text-[14px]">
                              {formatCurrencyMoney(
                                totalForVariation,
                                exchangeRate,
                                formData.currency,
                              )}{" "}
                            </p>
                          </div>
                          <div className="flex justify-between mb-2">
                            <p className="text-[14px] font-normal text-gray-800">
                              {variation.quantity} x{" "}
                              {formatCurrencyMoney(
                                variation.price || 0,
                                exchangeRate,
                                formData.currency,
                              )}
                            </p>
                            <button
                              onClick={() => handleEditClick(data, variation)}
                              className="text-[#044E97] text-[14px] font-medium underline cursor-pointer ml-auto"
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>
                      <div className="flex justify-between">
                        <p className="text-[16px] font-medium text-[#000000] ">
                          {data?.name}
                        </p>{" "}
                        <p className="font-semibold text-[14px]">
                          {formatCurrencyMoney(
                            // @ts-ignore
                            data?.quantity * (data?.rate || 0),
                            exchangeRate,
                            formData.currency,
                          )}
                        </p>
                      </div>
                      <div className="flex justify-between mb-2 items-center">
                        <p className="text-[14px] font-normal text-gray-800 mt-[1em]">
                          {data?.quantity || "0"} x{" "}
                          {formatCurrencyMoney(
                            data?.rate || 0,
                            exchangeRate,
                            formData.currency,
                          )}
                        </p>
                        <button
                          onClick={() => handleEditClick(data)}
                          className="text-[#044E97] text-[14px] font-medium underline cursor-pointer ml-auto"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

          {/* Add New Item Button */}
          <div className="mt-[1em] flex gap-[1em] justify-center items-center border border-[#044E97] bg-[white] rounded-md px-2 py-2">
            <PlusIcon color="#044E97" />
            <button
              className="text-[14px] text-[#044E97]"
              onClick={handleAddItem}
            >
              Add New Item
            </button>
          </div>
        </>
      )}

      {currentView === "selectItem" && (
        <SelectItem
          setIsOpen={handleBackToNone}
          setOpenAddInventory={handleOpenAddInventory}
          formData={formData}
          setFormData={setFormData}
          setSelectedInventoryAndQuantities={setSelectedInventoryAndQuantities}
          setShowViewSelection={() => setCurrentView("viewSelectItems")}
        />
      )}

      {currentView === "addInventory" && (
        <AddInventory
          setIsOpen={handleBackToNone}
          setOpenProductOptions={handleOpenProductOptions}
          setFormData={setFormData}
          variants={savedVariants}
          setCurrentView={setCurrentView}
          setVariationList={setSavedVariants}
          variationList={savedVariants}
          onInventoryAdded={(inventory) =>
            setSelectedInventoryAndQuantities(inventory, {})
          }
        />
      )}

      {currentView === "productOptions" && (
        <ProductOptions
          options={options}
          setOptions={setOptions}
          handleOpenVariants={handleOpenVariants}
          setCurrentView={setCurrentView}
          variations={variations}
          setVariationList={setVariationList}
          closeProductOptions={closeProductOptions}
        />
      )}

      {currentView === "productVariants" && (
        <ProductVariants
          options={options}
          setOptions={setOptions}
          setCurrentView={setCurrentView}
          onSave={handleSave}
        />
      )}
      {currentView === "viewSelectItems" && (
        <ViewSelectItems
          selectedInventory={selectedInventory}
          quantities={quantities}
          closeModal={closeModal}
        />
      )}

      {currentView === "showSelectedItem" && (
        <ShowSelectedItem
          setCurrentView={setCurrentView}
          openViewSelectItems={openViewSelectItems}
          // @ts-ignore
          selectedInventory={editInventory}
          closeModal={closeModal}
          formData={formData}
          setFormData={setFormData}
          edit={true}
          fullData={inventories.find((i) => i.id == editInventory?.id)}
        />
      )}
    </div>
  );
};

export default LineItemsSection;
