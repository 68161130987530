import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CloseIconBig } from "../../../../assets/icons";
import { TickIcon } from "../../../../assets/icons/TickIcon";
import { UntickedIcon } from "../../../../assets/icons/UntickedIcon";
import {
  copyPaymentLink,
  validateBankAccount,
} from "../../../../backend-services";
import { useUserStore } from "../../../../state";
import { useCompanyStore } from "../../../../state/useCompanyStore";
import { BankData, Customer } from "../../../../types";
import { InputComponent } from "../../../core/Forms";
import ErrorComponent from "../../../Error";
import { useTranslation } from "react-i18next";


interface GeneratePaymentPromptProps {
  onGenerate: (event: any) => void;
  onClose?: () => void;
  showVerificationPrompt: boolean;
  invoiceId: string | undefined;
  bankAccounts: BankData[];
  accountIndex: number;
  onValidateSuccess: (accountIndex: any) => void;
  showPaymentPrompt: (show: boolean) => void;
  showFailedVerificationPrompt: (show: boolean) => void;
  showFailedRCVerificationPrompt: (show: boolean) => void;
}
const VerficationModal: React.FC<GeneratePaymentPromptProps> = ({
  onGenerate,
  onClose,
  showVerificationPrompt,
  invoiceId,
  bankAccounts,
  accountIndex,
  onValidateSuccess,
  showPaymentPrompt,
  showFailedVerificationPrompt,
  showFailedRCVerificationPrompt,
}) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  if (!user) {
    return null;
  }
  const bankAccount = user.bankAccounts || [];
  const { setCompanyBankAccounts } = useCompanyStore();
  const [error, setError] = useState<string>("");
  const [showInput, setShowInput] = React.useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [showCreateBank, setShowCreateBank] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(
    showVerificationPrompt
  );

  const handleValidate = async (accountIndex: number) => {
    const bankAccountToValidate = user.bankAccounts[accountIndex];

    // Check if bank account is already validated
    if (bankAccountToValidate?.isValidated) {
      setVerificationMessage("Bank account already verified");
      setShowVerificationModal(false);
      showPaymentPrompt(true);
      return;
    }

    try {
      setShowVerificationModal(true);
      setVerificationMessage("Verifying bank account...");
      await validateBankAccount(accountIndex);
      const updatedBankAccounts = user.bankAccounts.map(
        (account: any, idx: number) =>
          idx === accountIndex
            ? { ...bankAccountToValidate, isValidated: true }
            : account
      );
      // Set the updated bank accounts
      setCompanyBankAccounts(updatedBankAccounts);
      onValidateSuccess(accountIndex);
      setVerificationMessage("Bank account verified");
      setShowVerificationModal(false);
      showPaymentPrompt(true);
    } catch (error) {
      setVerificationMessage("Bank account verification failed");

      // Check if user is verified
      const isUserVerified = user?.isVerified;
      if (isUserVerified) {
        showFailedVerificationPrompt(true);
      } else {
        showFailedRCVerificationPrompt(true);
      }
    } finally {
      if (onClose) {
        onClose();
      }
    }
  };

  const activeBankData = bankAccount.filter(
    (bankAccount: BankData) => bankAccount.isActive
  );
  const displayedBankData =
    selectedAccounts.length > 0
      ? bankAccount.filter((bank: { accountNumber: string }) =>
          selectedAccounts.includes(bank.accountNumber)
        )
      : activeBankData;

  useEffect(() => {
    if (showVerificationPrompt && activeBankData.length > 0) {
      handleValidate(accountIndex);
    } else if (showVerificationPrompt && activeBankData.length === 0) {
      setVerificationMessage("No active bank account to verify.");
    }
  }, [showVerificationPrompt, accountIndex, activeBankData]);

  if (!showVerificationModal) {
    return null;
  }
  const { t } = useTranslation("invoices");
  return (
    <>
      <div className="p-[32px] bg-[#ffffff] rounded-[16px] xl:max-h-[748px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[500px] w-[704px] xl:w-[506px] 2xl:w-[506px] biglg:w-[506px] lg:w-[506px] overflow-y-auto">
        <div className="flex justify-between">
          <h1 className="xl:text-[25px] biglg:text-[25px] 2xl:text-[25px] lg:text-[20px] md:text-[16px]  text-[14px] font-semibold mt-[-1em]">
            {t("bankAccountVer3")}
          </h1>
          <button
            onClick={onClose}
            className="pt-2 pb-2 rounded-md focus:outline-none mt-[-1em]  text-[black] shadow-sm bg-[#F4F7FF] pr-3"
          >
            <CloseIconBig />
          </button>
        </div>
        <p
          className={`flex mt-[1em] lg:text-[14px] text-[10px] md:text-[12px] py-2 px-2 rounded-md ${
            verificationMessage === "Verifying bank account..."
              ? "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
              : verificationMessage.includes("failed")
              ? "text-[#D82020] border border-[#FF0000] bg-[#dcc9c9]"
              : "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
          }`}
        >
          {verificationMessage === "Verifying bank account..." ? (
            <span>{verificationMessage}</span>
          ) : (
            <>
              {verificationMessage.includes("verified") ? (
                <span className="pr-2 mt-[0.1em]">
                  <TickIcon />
                </span>
              ) : (
                <span className="pr-2 mt-[0.1em]">
                  <UntickedIcon />
                </span>
              )}
              {verificationMessage}
            </>
          )}
        </p>

        <div role="form" onSubmit={(e) => e.preventDefault()}>
          {showInput && (
            <InputComponent
              placeholder=""
              type="text"
              name="amount"
              className="w-full rounded-md  md:w-[634px] xl:w-[434px] 2xl:w-[434px] mb-6 lg:w-[434px]"
            />
          )}
          <div className="mt-[1em]">
            {displayedBankData.map((bank: BankData, index: number) => (
              <div
                className="mt-[1em] ml-[em] lg:text-[16px] whitespace-nowrap text-[14px] bg-[#EDF2F5] w-full px-[1em] py-[1em]"
                key={index}
              >
                <div className="flex flex-col lg:text-[16px] text-[12px] md:text-[14px] ">
                  <p className="lg:text-[16px] font-normal text-[12px] md:text-[14px] ">
                    Bank Name:
                  </p>
                  <span className="font-semibold">{bank.bankName}</span>
                </div>
                <div className="flex justify-between mt-[1em] lg:text-[16px] text-[12px] md:text-[14px] ">
                  <div className="flex flex-col">
                    <p className="lg:text-[16px] font-normal ">
                      Account Number:
                    </p>
                    <span className="font-semibold">{bank.accountNumber}</span>
                  </div>
                  <div className="flex flex-col lg:text-[16px] text-[12px] md:text-[14px] ">
                    <p className="lg:text-[16px] font-normal  ">
                      Account Name:
                    </p>
                    <span className="font-semibold">{bank.accountName}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export { VerficationModal };
