// Define the Printer type with union type for USBDevice and BluetoothDevice with extended properties
export interface USBDevice {
  opened: boolean;
  open: () => Promise<void>;
  claimInterface: (interfaceNumber: number) => Promise<void>;
  transferOut: (
    endpointNumber: number,
    data: BufferSource
  ) => Promise<USBOutTransferResult>;
  releaseInterface: (interfaceNumber: number) => Promise<void>;
  close: () => Promise<void>;
  vendorId: number;
  productId: number;
}
interface BluetoothDevice {
  gatt: {
    connect: () => Promise<void>;
  };
}

export interface Printer {
  name: string;
  device: USBDevice | BluetoothDevice;
}

export const savePrinterToLocalStorage = (printer: globalThis.USBDevice) => {
  localStorage.setItem(
    "selectedPrinter",
    JSON.stringify({
      name: printer.productName,
      vendorId: printer.vendorId,
      productId: printer.productId,
    })
  );
};

const isUSBPrinter = (printer: Printer): boolean => {
  return "vendorId" in printer && "productId" in printer;
};

export const connectAndPrintUSB = async (
  printerInfo: USBDevice | null,
  data: Uint8Array
): Promise<void> => {
  if (!printerInfo) {
    throw new Error("Printer information is not available.");
  }
  try {
    const devices = await navigator.usb.getDevices();

    const device = devices.find(
      (d) =>
        d.vendorId === printerInfo.vendorId &&
        d.productId === printerInfo.productId
    );
    if (!device) {
      alert(
        "Printer device could not be found please connect a thermal printer or shop www.esemie.io"
      );
      return;
    }
    if (!device.opened) {
      await device.open();
    }
    await device.claimInterface(0);
    await device.transferOut(1, data);
    await device.releaseInterface(0);
    await device.close();
  } catch (err) {
    console.error("Error connecting to USB printer:", err);
    throw err;
  }
};
