import React, { useState } from "react";

import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ItemInput } from "../../../../@types";
import { ServiceData, ServicePriceData } from "../../../../@types/service";
import { createNewService } from "../../../../backend-services/service";
import { validateCreateService } from "../../../../modules/services/services";
import { useServiceStore } from "../../../../state/useServiceStore";
import { convertToFloat } from "../../../../utils/convertToFloat";
import ServiceForm from "../../../services/create/ServiceForm";
import ServiceInvoiceForm from "../../../services/create/ServiceInvoiceForm";
import { useTranslation } from "react-i18next";
import { CloseIcon } from "../../../../assets/icons";

interface CreateServiceProps {
  showBack?: boolean;
  onClose?: () => void;
  setShowCreateService: (show: boolean) => void;
  onCreateSuccess: (newItem: ItemInput) => void;
  handleCreateService: (e: React.FormEvent<HTMLFormElement>) => void;
}

const ServiceOverlay = ({
  showBack,
  onClose,
  setShowCreateService,
  onCreateSuccess,
}: CreateServiceProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [price, setPrice] = useState<ServicePriceData[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData>({
    id: "",
    name: "",
    duration: "",
    description: "",
    price: [],
    status: "Active",
  });
  const [error, setError] = React.useState({
    name: "",
    charge: {
      NGN: "",
      EUR: "",
      USD: "",
    },
  });
  const navigate = useNavigate();
  const { t } = useTranslation("services");
  const { createService } = useServiceStore((state) => ({
    createService: state.createService,
  }));

  const handleCreateService = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPrice = price.map((item) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateCreateService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });

    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);

        let newServiceData = { ...serviceData, price: newPrice };
        if (!serviceData.duration) {
          const today = new Date();
          newServiceData = { ...newServiceData, duration: "0 hours" };
        }

        const response = await createNewService(newServiceData);
        toast.success(t("service.successToast"));
        setShowCreateService(false);
        setIsLoading(false);
        setServiceData({
          name: "",
          duration: "",
          description: "",
          price: [],
          status: "Active",
        });

        onCreateSuccess(response);
      } catch (error) {
        // toast.error('Failed to create service.');
        setIsLoading(false);
        console.error("Error creating service:", error);
      }
    }
  };

  return (
    <>
      <div className="mt-8 mb-12">
        {showBack && (
          <div className="flex items-center gap-4">
            <h1 className="text-[18px] md:text-[20px] font-semibold lg:font-bold lg:hidden">
              Create Service
            </h1>
          </div>
        )}
        <div className="bg-[#FFFFFF]  pt-2 pb-[80px] lg:mt-[-2.5em] md: mt-4">
          <div className="hidden lg:block">
            <div
              className="font-medium text-[24px]  lg:mt-4 flex justify-between"
              onClick={() => setShowCreateService(false)}
            >
              Create Service
              <button>
                <CloseIcon />
              </button>
            </div>
          </div>

          <ServiceInvoiceForm
            serviceData={serviceData}
            setServiceData={setServiceData}
            handleCreateService={handleCreateService}
            setPrice={setPrice}
            price={price}
            error={error}
            setError={setError}
            isLoading={isLoading}
            onClose={() => setShowCreateService(false)}
          />
        </div>
      </div>
    </>
  );
};

export default ServiceOverlay;
