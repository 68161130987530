import { BillType, IInvoice, SalesStatus } from "../@types";
import { Badge } from "../components/common";

export const getInvoiceStatus = (status: SalesStatus) => {
  const getStatusLabel = (): string => {
    switch (status.toLowerCase()) {
      case "draft":
        return "Draft";
      case "due":
        return "Due";
      case "cancelled":
        return "Cancelled";
      case "pending":
        return "Pending";
      case "refunded":
        return "Refunded";
      case "paid":
        return "Paid";
      case "active":
        return "Active";
      case "expired":
        return "Expired";
      case "quote":
        return "Quote";
      default:
        return "Secondary";
    }
  };

  return (
    <Badge
      label={getStatusLabel()}
      color={
        status === "draft"
          ? "draft"
          : status === "due"
          ? "overdue"
          : status === "cancelled"
          ? "cancelled"
          : status === "pending"
          ? "pending"
          : status === "refunded"
          ? "refunded"
          : status === "paid"
          ? "paid"
          : status === "Active"
          ? "active"
          : status === "Expired"
          ? "expired"
          : status === "Quote"
          ? "quote"
          : "secondary"
      }
    />
  );
};

export const getInvoiceReoccur = (isReoccurring: IInvoice["isReoccurring"]) => {
  if (isReoccurring) {
    return "Recurring";
  } else {
    return "Non-recurring";
  }
};

export const getExpenseType = (isBillable: BillType["isBillable"]) => {
  if (isBillable) {
    return "billable";
  } else {
    return "non-billable";
  }
};
