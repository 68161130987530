import React, { useEffect, useState } from "react";
import InvoiceHeader from "./sections/InvoiceHeader";
import CompanySection from "./sections/CompanySection";
import CustomerSection from "./sections/CustomerSection";
import LineItemsSection from "./sections/LineItemsSection";
import AdditionalDetailsSection from "./sections/AdditionalDetailsSection";
import BankSection from "./sections/BankSection";
import RecurringSection from "./sections/RecurringSection";
import ActionButtons from "./sections/ActionButtons";
import SummarySection from "./sections/SummarySection";
import {
  ICreateSalesData,
  ICustomer,
  IInventory,
  ItemInput,
} from "../../@types";
import {
  InvoiceErrors,
  validateFreeFormItemDetails,
  validateInvoiceBasicInfo,
  validateInvoiceItemDetails,
  validateInvoiceSummary,
} from "../../modules/validateInvoiceForm";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useInvoiceFormState } from "../../hooks/invoice/useInvoiceFormState";
import {
  getInvoice,
  updateProfile,
  updateToPending,
} from "../../backend-services";
import { useInvoiceCalculations } from "../../hooks/invoice/useInvoiceCalculations";
import { clearFieldError, handleErrors } from "../../utils";
import { useUserStore } from "../../state";
import {
  createDraftData,
  createInvoiceData,
  createInvoiceFreeformData,
} from "../../modules/invoice/invoice";
import toast from "react-hot-toast";
import { checkSubscription } from "../../utils/subscription";
import { servicesType } from "../../constants/Subscription";
import SendInvoiceModal from "./sendInvoiceModal";
import { Button } from "../common/Button";
import useLineItems from "../../modules/lineItem";
import DesktopLineItems from "./sections/LineItemsSection/DesktopLineItems";
import LineTable from "../Sales/create/SalesCreate/LineTable";
import FreeformTable from "../Sales/create/SalesCreate/FreeformTable";
import LineItemsFreeform from "./sections/WithInventory.tsx/lineItemWithout";
import { useFreeformInvoiceFormState } from "../../hooks/invoice/useFreeformInvoiceState";
import SuccessBanner from "../common/Banner/SuccessBanner";
import ServiceFreeformLineItem from "./Service/ServiceFreeformItem";
import { useCompanyStore } from "../../state/useCompanyStore";

const InvoiceFreeformContainer = () => {
  const navigate = useNavigate();
  const [isAddItemModalOpen, setAddItemModalOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null,
  );
  const [quantities, setQuantities] = useState<{ [key: string]: string }>({});
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const userStore = useUserStore();
  const companyStore = useCompanyStore();
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const [createdInvoiceId, setCreatedInvoiceId] = React.useState("");
  const [createdEmail, setCreatedEmail] = React.useState("");
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [error, setError] = React.useState<string>("");
  const [markAsPaid, setMarkAsPaid] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [shouldSendWhatsApp, setShouldSendWhatsApp] = React.useState(false);
  const [shouldSendSMS, setShouldSendSMS] = React.useState(true);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [inventories, setInventories] = useState([]);
  const [, setCustomer] = React.useState<ICustomer | null>(null);
  const location = useLocation();
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerLinkText, setBannerLinkText] = useState("");
  const [isDraftLoading, setIsisDraftLoading] = React.useState<boolean>(false);
  const [selectedVariation, setSelectedVariation] = useState<
    string | undefined
  >(undefined);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const handleViewSelection = (inventory: IInventory) => {
    setSelectedInventory(inventory);
    setAddItemModalOpen(true);
  };
  const {
    formData,
    setFormData,
    customer,
    handleSetCustomer,
    validationResults,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    showSidebar,
    setShowSidebar,
    handleInputChange,
    validateForm,
    resetForm,
    isRetail,
    setValidationResults,
  } = useFreeformInvoiceFormState();

  const setLogoUrl = (url: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      logoBase64: url,
    }));
  };

  const handleEditProfileLogo = async () => {
    if (!formData.logoBase64 || formData.logoBase64.trim() === "") {
      setErrorMessage("No logo to update.");
      return;
    }

    setIsLoading(true);
    try {
      const updatedLogo = { brandLogo: formData.logoBase64 };
      await updateProfile(updatedLogo);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage("Unable to update profile logo.");
    }
  };
  const { subTotal, total, calcTax, calcDiscount } = useInvoiceCalculations(
    formData,
    isRetail,
  );

  const { items, addItem, setItems, removeItem, updateCell, updateRow } =
    useLineItems([{ id: "", name: "", rate: 0, quantity: 0 }]);

  React.useEffect(() => {
    clearFieldError("inventories", validationResults, setValidationResults);
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        inventories: items.map((item: ItemInput) => ({
          id: item.id,
          name: item.name,
          amount: 0,
          quantity: item.quantity || 0,
          rate: item.rate || 0,
          variations: item.variation || [],
        })),
      } as ICreateSalesData;
    });
  }, [items]);

  React.useEffect(() => {
    clearFieldError("services", validationResults, setValidationResults);
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        services: items,
      };
    });
  }, [items]);

  useEffect(() => {
    if (
      typeof formData.exchangeRate !== "number" &&
      isNaN(Number(formData.exchangeRate))
    ) {
      // Set the exchange rate to 1
      setFormData((prevData) => ({
        ...prevData,
        exchangeRate: 1,
      }));
    }
  }, [formData.exchangeRate]);

  const processCreateInvoice = async (
    sendEmail: boolean,
    markedAsPaid: boolean,
    shouldSendWhatsApp: boolean,
    shouldSendSMS: boolean,
  ) => {
    const exchange = formData.exchangeRate ?? 1;
    try {
      setIsLoading(true);
      //   setShowCreateSidebar(false);

      const {
        invoiceDueAt,
        issueDate,
        inventories,
        services,
        discount,
        tax,
        customerId,
        isReoccurring,
        lineItems,
      } = formData;
      const user = userStore.user;
      const isRetail = companyStore.isRetailBusiness();
      const validInvoiceDueAt = invoiceDueAt ? new Date(invoiceDueAt) : null;
      const validIssueDate = issueDate ? new Date(issueDate) : null;

      if (!validInvoiceDueAt || isNaN(validInvoiceDueAt.getTime())) {
        throw new Error("Invalid invoice due date");
      }

      if (!validIssueDate || isNaN(validIssueDate.getTime())) {
        throw new Error("Invalid issue date");
      }
      const invoiceData: ICreateSalesData = {
        customerId: customerId,
        shouldSendEmail: sendEmail,
        isPaymentCompleted: markedAsPaid,
        shouldSendWhatsApp: shouldSendWhatsApp,
        shouldSendSMS: shouldSendSMS,
        isReoccurring: isReoccurring,
        invoiceDueAt: validInvoiceDueAt.toISOString(),
        issueDate: validIssueDate.toISOString(),
        inventories: isRetail
          ? Array.isArray(inventories) && inventories.length
            ? inventories.map(
                (i: {
                  id: string;
                  name: string;
                  rate: number;
                  quantity: number;
                  variations?: any;
                }) => ({
                  id: i.id,
                  name: i.name,
                  amount: (Number(i.quantity) * Number(i.rate)) / exchange,
                  quantity: Number(i.quantity),
                  rate: Number(i.rate) / exchange,
                  variations: i.variations ? i.variations : undefined,
                }),
              )
            : []
          : [],
        lineItems: isRetail
          ? Array.isArray(lineItems) && lineItems.length
            ? lineItems.map(
                (i: { id: any; name: any; quantity: any; rate: any }) => ({
                  id: i.id,
                  name: i.name,
                  amount: (Number(i.quantity) * Number(i.rate)) / exchange,
                  quantity: Number(i.quantity),
                  rate: Number(i.rate) / exchange,
                }),
              )
            : []
          : [],
        services: !isRetail
          ? Array.isArray(services) && services.length
            ? services.map(
                (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                  id: s.id,
                  name: s.name,
                  duration: s.duration,
                  price: Number(s.price),
                }),
              )
            : []
          : [],
        discount: Number(discount ? discount : 0),
        tax: Number(tax ? tax : 0),
        isReoccurringEndDate: formData.isReoccurringEndDate || "",
        reoccurringGroupName: formData.reoccurringGroupName || "",
        status: formData.status || "pending",
        description: formData.description || "",
        additionalInformation: formData.additionalInformation || "",
        currency: formData.currency || "",
        exchangeRate: formData.exchangeRate,
        paymentMethod: formData.paymentMethod || "",
        invoiceType: "freeform",
      };

      if (customer) {
        if (invoiceNo) {
          const taxValue = formData.tax ? Number(formData.tax) / 100 : 0;
          const formattedTax = taxValue.toFixed(2);
          const updatedInvoiceData = {
            status: "pending",
            invoiceDueAt: validInvoiceDueAt.toISOString(),
            issueDate: validIssueDate.toISOString(),
            invoiceType: "freeform",
            inventories: isRetail
              ? inventories.length
                ? inventories.map(
                    (i: {
                      id: string;
                      name: string;
                      rate: number;
                      quantity: number;
                      variations?: any;
                    }) => ({
                      id: i.id,
                      name: i.name,
                      amount: Number(i.quantity) * Number(i.rate),
                      quantity: Number(i.quantity),
                      rate: Number(i.rate),
                      variations: i.variations ? i.variations : undefined,
                    }),
                  )
                : []
              : [],
            services: !isRetail
              ? services.length
                ? services.map(
                    (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                      id: s.id,
                      name: s.name,
                      duration: s.duration,
                      price: Number(s.price),
                    }),
                  )
                : []
              : [],
            discount: Number(formData.discount ? formData.discount / 100 : 0),
            tax: Number(formattedTax),
          };

          const createdUpdatedInvoice = await updateToPending({
            id: invoiceNo,
            data: updatedInvoiceData,
          });
          const createdUpdatedInvoiceId = createdUpdatedInvoice?.id;
          const createdEmail = customer.email || "";

          setCreatedInvoiceId(createdUpdatedInvoiceId);
          setCreatedEmail(createdEmail);
          setShowSuccessModal(true);
        } else {
          const createdInvoice = await createInvoiceFreeformData(
            invoiceData,
            customer,
            isRetail,
          );
          const createdInvoiceId = createdInvoice?.id;
          const createdEmail = customer.email || "";

          setCreatedInvoiceId(createdInvoiceId);
          setCreatedEmail(createdEmail);

          setBannerMessage("New Invoice Created,");
          setBannerLinkText("View Invoice");
          setIsBannerVisible(true);
        }
      } else {
        console.error("No customer found");
      }
    } catch (error: any) {
      console.error("Error in processing invoice:", error);
      handleErrors(error, setErrorMessage);
      setErrorMessage("An error occurred while processing the invoice.");
      toast.error("An error occurred while processing the invoice.");
      if (error.response) {
        const errorMessage = error.response.data;
        console.error("Error response:", errorMessage);
        setError(errorMessage);
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
      setIsLoading(false);
    }
  };

  const handleCreateInvoice = (e?: React.MouseEvent<HTMLButtonElement>) => {
    console.log(formData);
    e?.preventDefault();
    try {
      const basicInfoValid = validateInvoiceBasicInfo(
        formData,
        setValidationResults,
      );
      // const itemDetailsValid = validateInvoiceItemDetails(
      //   formData,
      //   setValidationResults,
      //   userStore.isRetailBusiness()
      // );
      const summaryValid = validateInvoiceSummary(
        formData,
        setValidationResults,
      );

      if (basicInfoValid && summaryValid) {
        const subscriptionCheck = checkSubscription(servicesType.Invoices);
        if (subscriptionCheck.status) {
          setShowSidebar(true);
        } else {
          setErrorMessage("Insufficient subscription to create invoices.");
          setShowSubscriptionModal(true);
          setSubscriptionErrorMessage(subscriptionCheck.message || "");
        }
      } else {
        setErrorMessage("Please fill out all required fields correctly.");

        const errorElements = [
          { id: "customer-info", hasError: !basicInfoValid },
          { id: "invoice-date", hasError: !basicInfoValid },
          { id: "invoice-currency", hasError: !basicInfoValid },
          {
            id: "line-table",
            hasError: formData.invoiceType === "freeform",
          },
        ];

        const firstErrorElement = errorElements.find((el) => el.hasError);
        if (firstErrorElement) {
          const element = document.getElementById(firstErrorElement.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      }
    } catch (error) {
      toast.error("Failed to create invoice. Please try again later.");
    }
  };

  const onCreateInvoice = async () => {
    await processCreateInvoice(
      sendEmail,
      markAsPaid,
      shouldSendWhatsApp,
      shouldSendSMS,
    );
    resetForm();
    setIsBannerVisible(true);
    setShowSidebar(false);
    setErrorMessage("");
  };
  const handlePaymentDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    console.log("Validation results in InvoiceParent:", validationResults);
  }, [validationResults]);

  function handleSendEmailChange(checked: boolean) {
    setSendEmail(checked);
  }
  function handleMarkAsPaidChange(event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) {
    setMarkAsPaid(event.target.checked);
  }
  function handleshouldSendWhatsAppChange(checked: boolean) {
    setShouldSendWhatsApp(checked);
  }
  function handleshouldSendSMSChange(checked: boolean) {
    setShouldSendSMS(checked);
  }
  // const handleIsReoccurringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const target = e.target as HTMLInputElement;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     isReoccurring: target.checked,
  //   }));
  //   setIsReoccurringChecked(target.checked);
  // };

  const onSaveDraft = async (event: any) => {
    event.preventDefault();
    console.log("click me");
    await processCreateDraftIInvoice();
  };

  const fetchDraftInvoice = async (invoiceNo: string) => {
    if (!invoiceNo) return;

    setLoading(true);

    try {
      const response = await getInvoice(invoiceNo);
      const draftData = response;
      // Ensure we only process the invoice if it is a draft
      if (draftData && draftData.status === "draft") {
        setFormData((prevData) => ({
          ...prevData,
          customerId: draftData.customer?.id || "",
          invoiceDueAt: draftData.invoiceDueAt
            ? new Date(draftData.invoiceDueAt).toISOString()
            : "",
          issueDate: draftData.issueDate
            ? new Date(draftData.issueDate).toISOString()
            : "",
          total: draftData.total || 0,
          currency: draftData.currency || "NGN",
          description: draftData.description || "",
          additionalInformation: draftData.additionalInformation || "",
          exchangeRate: draftData.exchangeRate || 1,
          invoiceType: draftData.invoiceType || "inventory",
          isReoccurring: draftData.isReoccurring || false,
          status: draftData.status || "draft",
        }));

        setCustomer({
          id: draftData.customer?.id || "",
          firstName: draftData.customer?.firstName || "",
          lastName: draftData.customer?.lastName || "",
          phone: draftData.customer?.phone || "",
          email: draftData.customer?.email || "",
        });

        // Handle inventory or service items
        if (draftData.invoiceType === "inventory") {
          setItems(
            draftData.inventories?.map(
              (i: {
                id: any;
                name: any;
                amount: any;
                quantity: any;
                rate: any;
                variations: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: i.amount || 0,
                quantity: Number(i.quantity) || 0,
                rate: Number(i.rate) || 0,
                variations: i.variations || [],
              }),
            ) || [],
          );
        } else if (draftData.invoiceType === "service") {
          setItems(
            draftData.services?.map(
              (service: {
                id: any;
                name: any;
                price: string | any[];
                duration: any;
              }) => ({
                id: service.id,
                name: service.name,
                price:
                  service.price?.length > 0
                    ? parseFloat(service.price[0].amount)
                    : 0,
                duration: service.duration,
              }),
            ) || [],
          );
        }
      } else {
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (invoiceNo) {
      setLoading(true);
      fetchDraftInvoice(invoiceNo);
    } else {
      setIsLoading(false);
    }
  }, [invoiceNo]);

  useEffect(() => {}, [customer, inventories]);

  const processCreateDraftIInvoice = async (e?: any) => {
    setIsisDraftLoading(true);
    setShowSidebar(false);

    const user = userStore.user;
    const isRetail = companyStore.isRetailBusiness();

    const invoiceData: ICreateSalesData = {
      customerId: customer?.id,
      description: formData.description,
      invoiceDueAt: formData?.invoiceDueAt
        ? new Date(formData?.invoiceDueAt)?.toISOString()
        : undefined,
      issueDate: formData?.issueDate
        ? new Date(formData?.issueDate)?.toISOString()
        : undefined,
      additionalInformation: formData.additionalInformation,
      discount: Number(formData.discount ? formData.discount / 100 : 0),
      tax: Number(formData.tax ? formData.tax / 100 : 0),
      isReoccurring: formData.isReoccurring,
      status: "draft",
      currency: formData.currency,
      exchangeRate: formData.exchangeRate,
      invoiceType: "freeform",
      // @ts-ignore
      reminderSettings: formData.reminderSettings,
      inventories: isRetail
        ? formData.inventories.length
          ? formData.inventories.map(
              (i: {
                id: string;
                name: string;
                rate: number;
                quantity: number;
                variations?: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: 0,
                quantity: Number(i.quantity),
                rate: Number(i.rate),
                variations: i.variations ? i.variations : undefined,
              }),
            )
          : []
        : [],
      services: !isRetail
        ? formData.services.length
          ? formData.services.map(
              (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                id: s.id,
                name: s.name,
                duration: s.duration,
                price: Number(s.price),
              }),
            )
          : undefined
        : undefined,
      ...(formData.isReoccurring && {
        reoccurringGroupName: formData.reoccurringGroupName,
        isReoccurringStartDate: new Date().toISOString(),
        isReoccurringEndDate: new Date(
          formData.isReoccurringEndDate || "",
        ).toISOString(),
      }),
    };

    try {
      const response = await createDraftData(invoiceData, customer, isRetail);
      setIsisDraftLoading(false);
      navigate("/invoices");
      toast.success("Draft saved successfully!");
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      setIsisDraftLoading(false);
      if (error.response) {
        const errorMessage = error.response.data;
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
      const errorEl = document.getElementById("error-message");
      errorEl?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document
        .getElementById("top-of-page")
        ?.scrollIntoView({ behavior: "smooth" });
      setIsLoading(false);
      setErrorMessage("An error occurred.");
    }
  };
  const { serviceData } = location.state || {};
  useEffect(() => {
    if (serviceData) {
      const ngnPrice =
        serviceData.price?.find(
          (priceItem: { amount: number; currency: string }) =>
            priceItem.currency === "NGN",
        )?.amount || 0;

      setFormData((prevFormData) => ({
        ...prevFormData,
        services: [
          {
            id: serviceData.id || "",
            name: serviceData.name || "",
            duration: serviceData.duration || "",
            price: ngnPrice,
          },
        ],
      }));
    }
  }, [serviceData]);

  // const isMobile = window.innerWidth < 768;

  // const updateReminderSettings = (updatedSettings: any) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     reminderSettings: updatedSettings,
  //   }));
  // };
  // console.log('Basic Info Valid:', basicInfoValid);
  // console.log('Item Details Valid:', itemDetailsValid);
  // console.log('Summary Valid:', summaryValid);

  const handleViewInvoice = () => {
    if (createdInvoiceId) {
      const viewInvoiceRoute = `/invoices/${createdInvoiceId}/view`;
      navigate(viewInvoiceRoute);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  console.log("Parent FormData Reminder Settings:", formData.reminderSettings);
  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] =
    React.useState("₦ Naira");
  return (
    <div>
      {isBannerVisible && (
        <SuccessBanner
          message={bannerMessage}
          linkText={bannerLinkText}
          onLinkClick={handleViewInvoice}
          onClose={handleCloseBanner}
        />
      )}
      <InvoiceHeader />
      <CompanySection
        logoUrl={formData.logoBase64 || ""}
        setLogoUrl={setLogoUrl}
        error={undefined}
        onUploadSuccess={handleEditProfileLogo}
        formData={formData}
        setFormData={setFormData}
        validationResults={validationResults}
        setValidationResults={setValidationResults}
      />

      <CustomerSection
        customer={customer}
        handleSetCustomer={handleSetCustomer}
        validationResults={validationResults}
        formData={formData}
        // setFormData={setFormData}
        setValidationResults={setValidationResults}
      />
      {isRetail ? (
        <LineItemsFreeform
          formData={formData}
          setFormData={setFormData}
          variations={[]}
          total={selectedInventory?.total || 0}
        />
      ) : (
        <ServiceFreeformLineItem
          formData={formData}
          setFormData={setFormData}
        />
      )}
      <div className="mt-[3em] hidden md:block">
        <FreeformTable
          items={items}
          addItem={addItem}
          setItems={setItems}
          removeItem={removeItem}
          updateCell={updateCell}
          updateRow={updateRow}
          formData={formData}
          setFormData={setFormData}
          selectedCurrencyLabel={selectedCurrencyLabel}
          validationResults={validationResults}
          setValidationResults={setValidationResults}
        />
      </div>
      <SummarySection
        subTotal={subTotal}
        formData={formData}
        calcDiscount={calcDiscount}
        calcTax={calcTax}
        total={total}
        handleInputChange={handleInputChange}
      />
      <hr className="my-5" />
      <div className="hidden md:block">
        {" "}
        <BankSection />
      </div>
      <hr className="my-5" />
      <AdditionalDetailsSection
        formData={formData}
        handleInputChange={handleInputChange}
      />
      <div className="md:hidden">
        {" "}
        <BankSection />
      </div>

      {/* <RecurringSection /> */}
      <hr className="my-5" />
      {/* <ActionButtons /> */}
      <div className="w-full py-5 flex justify-between lg:justify-end gap-[1em]">
        <Button
          outline={true}
          variant="secondary"
          text={"Save as Draft"}
          size="small"
          font="semibold"
          onClick={onSaveDraft}
          isLoading={isDraftLoading}
          disabled={isDraftLoading || formData?.lineItems?.length == 0}
        />
        <div className="flex ">
          <Button
            variant="secondary"
            text={"Save"}
            size="small"
            font="semibold"
            onClick={handleCreateInvoice}
            disabled={formData?.lineItems?.length == 0}
          />
        </div>
      </div>
      {showSidebar && (
        <SendInvoiceModal
          onCreateInvoice={onCreateInvoice}
          onClose={() => setShowSidebar(false)}
          formData={formData}
          validationResults={validationResults}
          handlePaymentDropDownChange={handlePaymentDropDownChange}
          markAsPaid={markAsPaid}
          handleMarkAsPaidChange={handleMarkAsPaidChange}
          handleSendEmailChange={handleSendEmailChange}
          shouldSendSMS={shouldSendSMS}
          customer={customer}
          sendEmail={sendEmail}
          handleshouldSendWhatsAppChange={handleshouldSendWhatsAppChange}
          handleInputChange={handleInputChange}
          handleshouldSendSMSChange={handleshouldSendSMSChange}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

export default InvoiceFreeformContainer;
