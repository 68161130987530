import React, { useEffect, useRef, useState } from "react";
import Profile from "../../../assets/icons/Profile";
import BrandIdentity from "../../../assets/icons/BrandIdentity";
import CashIcon from "../../../assets/icons/CashIcon";
import BankAccountForm from "../../settings/bank/BankAccountForm";
import { BankData } from "../../../types";
import BusinessInfoModal from "./../modal/BusinessInfoModal";
import BrandIdentityModal from "./../modal/BrandIdentityModal";
import { useDashboardStats } from "../../../hooks/useDashboardStats";
import { isCompleted } from "../../../utils/todoSection";
import ToDoCard from "./TodoCard";
import { useUserStore } from "../../../state";
import { useCompanyStore } from "../../../state/useCompanyStore";

const ToDos = () => {
  const { user } = useUserStore();
  const currentBankDetail =
    user && user?.bankAccounts?.length > 0 && user.bankAccounts[0];
  const [isLoading, setIsLoading] = useState(false);
  const { allComplete, brandPercent, bankPercent, profilePercent } =
    useDashboardStats();
  const [showModal, setShowModal] = useState({
    bankForm: false,
    businessInfo: false,
    brandIdentity: false,
  });
  const createBankAccount = useCompanyStore((state) => state.createBankAccount);
  const [activeIndex, setActiveIndex] = useState(0);
  const carouselRef = useRef<HTMLDivElement | null>(null);

  const handleCreateBankAccount = async (data: BankData) => {
    try {
      setIsLoading(true);
      const bankDetails = {
        ...data,
        currency: "NGN",
        isActive: true,
      };
      const newBankAccount = await createBankAccount(bankDetails);
      setShowModal((prev) => ({ ...prev, bankForm: false }));
    } catch (error) {
      throw new Error("Failed to add Bank Detail");
    } finally {
      setIsLoading(false);
    }
  };

  const toDoItems = [
    {
      id: "profile",
      icon: Profile,
      title: "Complete Your Profile",
      isComplete: isCompleted(profilePercent),
      percent: profilePercent,
      onClick: () => setShowModal((prev) => ({ ...prev, businessInfo: true })),
    },
    {
      id: "brandIdentity",
      icon: BrandIdentity,
      title: "Personalize Invoices",
      isComplete: isCompleted(brandPercent),
      percent: brandPercent,
      onClick: () => setShowModal((prev) => ({ ...prev, brandIdentity: true })),
    },
    {
      id: "bankDetails",
      icon: CashIcon,
      title: "Add Bank Details",

      percent: bankPercent,
      onClick: () => setShowModal((prev) => ({ ...prev, bankForm: true })),
    },
  ];

  const handleScroll = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const scrollLeft = carousel.scrollLeft;
      const cardWidth = carousel.scrollWidth / toDoItems.length; // Calculate card width dynamically
      const currentIndex = Math.round(scrollLeft / cardWidth); // Use the card width to calculate the active index
      setActiveIndex(currentIndex);
    }
  };

  return (
    <>
      {!allComplete && (
        <div className="pt-3 pb-3 md:pb-6 bg-[#FFFFFF] w-full mt-4 rounded-lg">
          <h2 className="pl-3 pr-0 md:px-6 font-semibold text-[#000000] leading-6">
            My To-dos
          </h2>
          <hr className="mt-3 mb-6" />
          <div
            onScroll={handleScroll}
            ref={carouselRef}
            className="p-3 pr-3 md:px-6 flex gap-4 overflow-x-auto md:overflow-x-hidden w-full mt-2 scrollbar-hide"
          >
            {toDoItems.map((item) => (
              <ToDoCard
                key={item.id}
                icon={item.icon}
                title={item.title}
                percent={item.percent}
                onClick={item.onClick}
              />
            ))}
          </div>
          <div className="flex justify-center mt-3 md:hidden">
            {toDoItems.map((_, index) => (
              <span
                key={index}
                className={`h-2 w-2 mx-1 rounded-full ${
                  activeIndex === index ? "bg-orange-500" : "bg-gray-300"
                }`}
              />
            ))}
          </div>
        </div>
      )}

      {showModal.businessInfo && (
        <BusinessInfoModal
          setShowBusinessInfo={() =>
            setShowModal((prev) => ({ ...prev, businessInfo: false }))
          }
        />
      )}
      {showModal.brandIdentity && (
        <BrandIdentityModal
          onClose={() =>
            setShowModal((prev) => ({ ...prev, brandIdentity: false }))
          }
        />
      )}

      {showModal.bankForm && (
        <BankAccountForm
          initialData={{
            bankName: currentBankDetail.bankName || "",
            accountNumber: currentBankDetail.accountNumber || "",
            accountName: currentBankDetail.accountName || "",
            isActive: true,
            currency: "NGN",
          }}
          title="Bank Details"
          onSubmit={handleCreateBankAccount}
          onClose={() => setShowModal((prev) => ({ ...prev, bankForm: false }))}
          isLoading={isLoading}
          fromToDos={true}
        />
      )}
    </>
  );
};

export default ToDos;
