import { useState } from "react";
import { ICustomer, IInventory } from "../../@types";

const useInvoiceState = () => {
  const [isAddItemModalOpen, setAddItemModalOpen] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null,
  );
  const [quantities, setQuantities] = useState<{ [key: string]: string }>({});
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [error, setError] = useState<string>("");
  const [isDraftLoading, setIsisDraftLoading] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerLinkText, setBannerLinkText] = useState("");
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    useState<string>("");
  const [createdInvoiceId, setCreatedInvoiceId] = useState("");
  const [createdEmail, setCreatedEmail] = useState("");
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] = useState("₦ Naira");
  const [, setCustomer] = useState<ICustomer | null>(null);
  return {
    isAddItemModalOpen,
    setAddItemModalOpen,
    selectedInventory,
    setSelectedInventory,
    quantities,
    setQuantities,
    showSuccessModal,
    setShowSuccessModal,
    error,
    setError,
    isDraftLoading,
    setIsisDraftLoading,
    loading,
    setLoading,
    bannerMessage,
    setBannerMessage,
    bannerLinkText,
    setBannerLinkText,
    isBannerVisible,
    setIsBannerVisible,
    showSubscriptionModal,
    setShowSubscriptionModal,
    subscriptionErrorMessage,
    setSubscriptionErrorMessage,
    createdInvoiceId,
    setCreatedInvoiceId,
    setCreatedEmail,
    createdEmail,
    setCustomer,
    selectedCurrencyLabel,
    setSelectedCurrencyLabel,
  };
};

export default useInvoiceState;
