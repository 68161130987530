import React from "react";
import { bgClassName } from "../../../utils/bgClass";
import { ArrowDown, ArrowUp } from "../../../assets/icons";
import { formatMoney } from "../../../utils";
import { formatDate } from "../../../utils/formatDate";
import { useNavigate } from "react-router-dom";
import { TransactionDataType } from "../../../@types";

interface TransactionItemProps {
  data: TransactionDataType;
}

const TransactionItem = ({ data }: TransactionItemProps) => {
  const navigate = useNavigate();

  const handleNavigate = (data: any) => {
    if (data.type === "invoice") {
      navigate(`/invoices/${data.id}/view`);
    } else {
      navigate(`/expenses/${data.id}/view`);
    }
  };

  return (
    <div
      onClick={() => handleNavigate(data)}
      className="flex justify-between items-center py-2 cursor-pointer hover:bg-gray-100"
    >
      <div className="flex items-center gap-2">
        <div className={bgClassName(data.type)}>
          {data.type === "invoice" ? (
            <div data-testid="arrow-up">
              <ArrowUp />
            </div>
          ) : (
            <div data-testid="arrow-down">
              <ArrowDown />
            </div>
          )}
        </div>
        <div className="text-[#000] text-[14px] font-medium leading-5">
          <p>
            {data.type === "invoice" ? (
              <span>
                {data?.customer?.firstName || ""}{" "}
                {data?.customer?.lastName || ""}
              </span>
            ) : (
              <span>{data.vendorName || data.category}</span>
            )}
          </p>
          <p className="text-[12px] text-[#475467] leading-4 mt-[2px]">
            {data.type === "invoice"
              ? formatDate(data?.issueDate || "")
              : formatDate(data?.date || "")}
          </p>
        </div>
      </div>

      <div className="text-[#000] font-semibold leading-6">
        {data.type === "invoice" ? (
          <span>{formatMoney(data.total, data.currency)}</span>
        ) : (
          <span>{formatMoney(data.amount, data.currency)}</span>
        )}
      </div>
    </div>
  );
};

export default TransactionItem;
