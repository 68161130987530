import React from "react";
import { ICreateSalesData, ICustomer } from "../../../../@types";
import { CloseIcon } from "../../../../assets/icons";
import { PAYMENT_PROVIDER_LIST } from "../../../../constants";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { Button } from "../../../common";
import PhoneInputComponent from "../../../common/PhoneInputComponent";
import { DropdownComponent, InputComponent } from "../../../core/Forms";
import { useTranslation } from "react-i18next";

interface CreateSalesProps {
  formData: ICreateSalesData;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePaymentDropDownChange: (name: string, value: string) => void;
  handleIsReoccurringChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  validationResults: InvoiceErrors;
  invoiceNo?: string;
  quoteNo?: string;
  sendEmail: boolean;
  markAsPaid: boolean;
  handleSendEmailChange: (value: boolean) => void;
  handleshouldSendWhatsAppChange: (value: boolean) => void;
  handleshouldSendSMSChange: (value: boolean) => void;
  handleMarkAsPaidChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  shouldSendWhatsApp: boolean;
  shouldSendSMS: boolean;
  errorMessage: string | null;
  error: string | null;
  customer: ICustomer | null;
  showMarkAsPaid: boolean;
  onCreateInvoice?: (event: any) => Promise<void>;
  onCreateQuote?: (event: any) => Promise<void>;
  isLoading?: boolean;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
  onClose: () => void;
}

const CreateInvoice: React.FC<CreateSalesProps> = ({
  formData,
  handleInputChange,
  validationResults,
  sendEmail,
  markAsPaid,
  handleSendEmailChange,
  handleMarkAsPaidChange,
  handleshouldSendWhatsAppChange,
  handleshouldSendSMSChange,
  shouldSendWhatsApp,
  shouldSendSMS,
  errorMessage,
  error,
  customer,
  quoteNo,
  invoiceNo,
  showMarkAsPaid,
  onCreateInvoice,
  onCreateQuote,
  isLoading,
  setValidationResults,
  handlePaymentDropDownChange,
  onClose,
}) => {
  const handleShouldSendEmailChange = (value: boolean) => {
    handleSendEmailChange(value);
    handleshouldSendWhatsAppChange(!value);
    handleshouldSendSMSChange(!value);
  };

  const handleWhatsAppChange = (value: boolean) => {
    handleshouldSendWhatsAppChange(value);
    handleSendEmailChange(!value);
    handleshouldSendSMSChange(!value);
  };
  const handleSMSChange = (value: boolean) => {
    handleshouldSendSMSChange(value);
    handleshouldSendWhatsAppChange(!value);
    handleSendEmailChange(!value);
  };

  const isEmailAvailable = customer?.email;
  const isPhoneAvailable = customer?.phone;

  const handlePhoneNumberChange = (phoneNumber: string) => {};

  const dropDownPaymentMethod = PAYMENT_PROVIDER_LIST;

  const handleClose = () => {
    onClose && onClose();
  };
  const { t } = useTranslation("invoices");
  return (
    <div className="relative">
      <h1 className="font-semibold pl-2 xl:text-[25px] lg:text-[20px] text-[18px] biglg:text-[22px] ">
        {" "}
        {t("createInvoice")}
      </h1>

      <button
        className="absolute top-2 mt-[-0.5em] right-2 shadow-sm py-[0.2em] bg-[#F4F7FF] pr-4"
        aria-label="Close modal"
        onClick={handleClose}
      >
        <CloseIcon color="black" />
      </button>
      <p className="lg:text-[14px] xl:text-[16px] biglg:text-[14px] text-[12px] pl-[1em] mt-[2em] biglg:mt-[0.5em]">
        {t("confirmInvoicecreate")}
        <br />
        {t("confirmInvoicecreate1")}
      </p>

      <div className=" mt-3 rounded-md xl:w-[424px] 2xl:w-[474px] biglg:w-[400px] lg:w-[350px] pt-[0.3em] md:w-[300px] bg-[#EDF6FF]  430:w-[350px] 360:w-[300px] w-[320px] lg:ml-[1em] ml-[0.8em] md:ml-[-0.6em] bigmd:ml-[0.5em] xl:ml-[0.8em] md:mt-[0.5em] biglg:mt-[1em] xl:mt-[3em]  2xl:mt-[3.5em]  3xl:mt-[3.1em] biglg:ml-[0.5em] 540:ml-[4em] 3xl:ml-[-1em] pl-[2em] mb-[2em]  pb-[1em]">
        <div className="  flex xl:ml-[-0.7em] biglg:ml-[-0.5em] mt-[1em] ml-[-1em] xl:text-[20px] lg:text-[18px] biglg:text-[18px] text-[#044E97] ">
          <p> {t("sendMediumText")}</p>
        </div>
        {isPhoneAvailable ? (
          <>
            <div className="w-full px-[7px] py-3 text-black font-normal flex items-center justify-between ml-[-1.3em] mt-[-0.5em] lg:mt-0">
              <div className="">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="shouldSendSMS"
                    onChange={() => handleSMSChange(true)}
                    name="shouldSendSMS"
                    checked={shouldSendSMS}
                  />
                  <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[16px] xl:text-[16px] md:text-[16px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                    {" "}
                    Share Via SMS
                  </span>
                </label>
              </div>
            </div>
            {shouldSendSMS && (
              <div className="mt-[-0.5em]  ml-[-0.7em]">
                <label
                  htmlFor="phone"
                  className="block font-sm font-weight-[400] mb-2 text-[13px] text-black text-left"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Phone Number
                </label>
                <div className="w-full">
                  <PhoneInputComponent
                    placeholder={customer?.phone}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    initialPhoneNumber={customer?.phone}
                    disabled
                    inputClass="text-[#8E8E8E] w-full"
                    containerClass="invoiceSummary "
                  />
                </div>
              </div>
            )}
            <div className="xl:mt-[-1em] lg:mt-[-1em] mt-[-1em] w-[300px] px-[15px] py-3 text-black font-normal flex items-center justify-between ml-[-1.7em]">
              <div className="">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="sendEmail"
                    onChange={() => handleShouldSendEmailChange(true)}
                    name="sendEmail"
                    checked={sendEmail}
                  />
                  <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[16px] xl:text-[16px] md:text-[16px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                    {" "}
                    Share Via Mail
                  </span>
                </label>
              </div>
            </div>
            {sendEmail && (
              <div className="ml-[-0.7em] mt-[-0.5em] xl:rounded xl:py-1 w-full xl:mb-[16px]">
                <label
                  htmlFor="email"
                  className="block font-sm text-[13px] text-black text-left"
                >
                  Email
                </label>
                <InputComponent
                  name="email"
                  type="text"
                  value={customer?.email}
                  handleChange={handleInputChange}
                  className="rounded-md text-[#8E8E8E]  w-[280px] 360:w-[260px] md:w-[250px] lg:w-[300px] xl:w-[384px] biglg:w-[358px] "
                  readonly
                />
              </div>
            )}
            {/* <div className="xl:mt-[-2em] lg:mt-[-1em] mt-[-1em] w-full px-[15px] py-3 text-black font-normal flex items-center justify-between ml-[-1.7em]">
              <div className="">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="radio"
                    value="shouldSendWhatsApp"
                    onChange={() => handleWhatsAppChange(true)}
                    name="shouldSendWhatsApp"
                    checked={shouldSendWhatsApp}
                  />
                  <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[16px] xl:text-[16px] md:text-[16px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                    {' '}
                    Share Via WhatsApp
                  </span>
                </label>
              </div>
            </div> */}
            {/* {shouldSendWhatsApp && (
              <div className="mt-[-0.5em]  ml-[-0.7em]">
                <label
                  htmlFor="phone"
                  className="block font-sm font-weight-[400] mb-2 text-[13px] text-black text-left"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Phone Number
                </label>
                <div className="w-full">
                  <PhoneInputComponent
                    placeholder={customer?.phone}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    initialPhoneNumber={customer?.phone}
                    disabled
                    inputClass="text-[#8E8E8E] w-full"
                    containerClass="invoiceSummary "
                  />
                </div>
              </div>
            )} */}
          </>
        ) : (
          <div className="">
            <div className="w-[300px] px-[7px] py-3 text-black font-normal flex items-center justify-between ml-[-1.3em] mt-[-0.5em] lg:mt-0">
              <label className="relative inline-flex items-center cursor-pointer" />
              {isEmailAvailable && (
                <>
                  <input
                    type="checkbox"
                    value="sendEmail"
                    onChange={(e) => handleSendEmailChange(e.target.checked)}
                    name="sendEmail"
                    checked={sendEmail}
                  />
                  <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[16px] xl:text-[16px] md:text-[16px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                    {" "}
                    Share Via Mail
                  </span>
                </>
              )}
            </div>

            {/* <div className="w-[300px] px-[7px] py-3 text-black font-normal flex items-center justify-between ml-[-1em] mt-[-2em] md:mt-[-1em] lg:mt-[-1.5em]">
              <div className="">
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value="shouldSendWhatsApp"
                    onChange={(e) => handleWhatsAppChange(e.target.checked)}
                    name="shouldSendWhatsApp"
                    checked={shouldSendWhatsApp}
                  />
                  <span className="ml-3 py-2 inline-block text-gray-900 lg:text-[16px] xl:text-[16px] md:text-[16px] text-[16px] xl:ml-3 lg:ml-3 md:ml-3 biglg:ml-3 bigmd:ml-3 ">
                    {' '}
                    Share Via WhatsApp
                  </span>
                </label>
              </div>
            </div> */}
            {/* {shouldSendWhatsApp && (
              <div className="mt-[-0.5em]  ml-[-0.7em]  pb-[1em]">
                <label
                  htmlFor="phone"
                  className="block font-sm font-weight-[400] mb-2 text-[13px] text-black text-left"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  Phone Number
                </label>
                <div className="w-full text-[#8E8E8E]  pb-[1em]">
                  <PhoneInputComponent
                    placeholder={customer?.phone}
                    onPhoneNumberChange={handlePhoneNumberChange}
                    initialPhoneNumber={customer?.phone}
                    disabled
                    inputClass="text-[#8E8E8E]"
                    containerClass="invoiceSummary "
                  />
                </div>
              </div>
            )} */}
          </div>
        )}
      </div>
      <div className=" mt-[-1em] rounded-md xl:w-[424px] 2xl:w-[474px] biglg:w-[400px] lg:w-[350px] pt-[0.3em] md:w-[300px] bg-[#EDF6FF]  430:w-[350px] 360:w-[300px] w-[320px] lg:ml-[1em] ml-[0.8em] md:ml-[-0.6em] bigmd:ml-[0.5em] xl:ml-[0.8em] md:mt-[-1.5em] lg:mt-[-1em] biglg:mt-[-1em] xl:mt-[-1em]  2xl:mt-[-1em]  3xl:mt-[-1em] biglg:ml-[0.5em] 540:ml-[4em] 3xl:ml-[-1em] pl-[2em] mb-[2em]">
        {showMarkAsPaid && onCreateInvoice && (
          <div className="xl:w-[400px] biglg:w-[350px] lg:w-[350px] w-[300px] md:w-[300px] pl-[5px] pr-1 py-3 text-gray-900 font-normal flex  lg:gap-[4em] biglg:gap-[12em] xl:gap-[10em] gap-[6em] justify-between ml-[-1.5em] mt-[em] text-[14px]">
            <label
              htmlFor="markAsPaid"
              className="lg:font-sm md:text-[10px] lg:text-[16px] whitespace-nowrap"
            >
              {t("markaspaid")}
            </label>
            <div className="mt-[0.5em] ml-[]">
              <label className="relative inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value="markAsPaid"
                  className="sr-only peer"
                  onChange={handleMarkAsPaidChange}
                  name="markAsPaid"
                />
                <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                <span className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"></span>
              </label>
            </div>
          </div>
        )}
        {markAsPaid && onCreateInvoice && (
          <div className="ml-[-0.5em] mb-8 mt-[-0.5em] biglg:mt-[-1em] lg:ml-[-1em]">
            <div className="relative">
              <label
                htmlFor="customer"
                className=" inline-flex text-[13px] mb-2"
                style={{ whiteSpace: "nowrap" }}
              >
                Payment Method
              </label>
            </div>
            <div className="mb-[1em] w-[280px] 360:w-[260px] md:w-[250px] xl:w-[384px] biglg:w-[362px] lg:w-[300px] pb-[0.5em]">
              <DropdownComponent
                name={"paymentMethod"}
                options={dropDownPaymentMethod}
                placeholder="Payment Method"
                selectedValue={formData.paymentMethod}
                onChange={handlePaymentDropDownChange}
                dropdownClassName="  w-[280px] 360:w-[260px] md:w-[250px] lg:w-[300px] biglg:w-[362px] xl:w-[384px]  max-h-[48px] cursor-pointer bg-[#fff]  rounded-[4px] placeholder:text-[#A4A4A4] border-1 border-[#A4A4A4] mt-[-0.3em]  mb-[1em]"
                iconClassName="mr-[1.5em] lg:mr-[1.7em] xl:mr-[1.2em] biglg:ml-[13em] "
              />
              <div className="xl:mt-[em] mb-6 mt-7">
                {validationResults.paymentMethod && (
                  <span
                    className="text-red-500"
                    style={{
                      whiteSpace: "nowrap",
                      padding: "0",
                      lineHeight: "1.2",
                    }}
                  >
                    {validationResults.paymentMethod}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {onCreateInvoice && (
        <div className="flex justify-end items-end text-right ">
          <Button
            text={t("createInvoice")}
            size="medium"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onCreateInvoice}
          />
        </div>
      )}
      {onCreateQuote && (
        <div className="flex justify-end items-end text-right  ">
          <Button
            text="Create Quote"
            size="medium"
            type="submit"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onCreateQuote}
          />
        </div>
      )}
    </div>
  );
};

export default CreateInvoice;
