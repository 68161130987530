import React from "react";
import { useTranslation } from "react-i18next";
import { ICreateSalesData } from "../../../@types";
import { formatCurrencyMoney, formatMoney } from "../../../utils";
interface SalesCalcsProps {
  subTotal: number;
  formData: ICreateSalesData;
  calcDiscount: number;
  calcTax: number;
  total: number;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SummarySection: React.FC<SalesCalcsProps> = ({
  subTotal,
  formData,
  calcDiscount,
  calcTax,
  total,
  handleInputChange,
}) => {
  const { t } = useTranslation("invoices");
  return (
    <div>
      {/* mobile */}
      <div className="md:hidden text-[14px] mb-8">
        <div className="flex justify-between text-[16px] py-2">
          <p className="font-medium leading-6 text-gray-600">Sub Total:</p>
          <p className="font-semibold leading-6 text-[#000000]">
            {" "}
            {formatMoney(subTotal.toFixed(2), formData.currency)}
          </p>
        </div>

        <div className="flex items-center justify-between text-[16px] py-2">
          <div className="flex items-center gap-2">
            <p className="">{t("discount")}</p>
            <div className="relative w-[60px]">
              <input
                type="number"
                name="discount"
                value={formData?.discount || ""}
                onChange={handleInputChange}
                className="w-full py-2 font-medium text-[14px] text-gray-400 py- pr-4 bg-gray-50 border border-gray-200 rounded-[4px]"
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                %
              </span>
            </div>
          </div>
          <div>
            <p className="font-semibold text-[16px] leading--6 text-[#000000]">
              {formatMoney(calcDiscount.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>

        <div className="flex items-center justify-between text-[16px] py-2">
          <div className="flex items-center gap-2">
            <p className="">VAT</p>
            <div className="relative w-[60px]">
              <input
                type="number"
                name="tax"
                value={formData?.tax || ""}
                onChange={handleInputChange}
                className="w-full py-2 font-medium text-[14px] text-gray-400 py- pr-4 bg-gray-50 border border-gray-200 rounded-[4px]"
              />
              <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                %
              </span>
            </div>
          </div>
          <div>
            <p className="font-semibold text-[16px] leading--6 text-[#000000]">
              {formatMoney(calcTax.toFixed(2), formData.currency)}
            </p>
          </div>
        </div>

        <hr className="mt-2 w-100%" />

        <div className="flex justify-between border-b border-gray-200 py-4 font-semibold text-[17px] text-[#000000]">
          <p className="">Total({formData.currency}):</p>
          <p className="">
            {" "}
            {formatMoney(total.toFixed(2), formData.currency)}
          </p>
        </div>
      </div>

      {/* Desktop */}
      <div className="hidden md:block lg:block ">
        <div className=" md:flex w-full  justify-end mt-[124px] py-6">
          <div className="text-[14px] md:max-w-[350px] w-full">
            <div className="py-1 border-t border-gray-200">
              <div className="flex justify-between text-[16px] py-2 px-3">
                <p className="font-medium leading-6 text-gray-600">Sub-total</p>
                <p className="font-semibold leading-6 text-[#000000]">
                  {formatMoney(subTotal, formData.currency)}
                </p>
              </div>

              <div className="flex items-center justify-between text-[16px] py-2 px-3">
                <div className="flex items-center gap-2">
                  <p className="font-medium leading-6 text-gray-600">
                    {t("discount")}
                  </p>
                  <div className="relative w-[60px]">
                    <input
                      type="number"
                      name="discount"
                      value={formData?.discount || ""}
                      onChange={handleInputChange}
                      className="w-full py-2 font-medium text-[14px] text-gray-400 py- pr-4 bg-gray-50 border border-gray-200 rounded-[4px]"
                    />
                    <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                      %
                    </span>
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-[16px] leading--6 text-[#000000]">
                    {formatMoney(calcDiscount.toFixed(2), formData.currency)}
                  </p>
                </div>
              </div>

              <div className="flex items-center justify-between text-[16px] py-2 px-3">
                <div className="flex items-center gap-2">
                  <p className="font-medium leading-6 text-gray-600">VAT</p>
                  <div className="relative w-[60px]">
                    <input
                      type="number"
                      name="tax"
                      value={formData?.tax || ""}
                      onChange={handleInputChange}
                      className="w-full py-2 font-medium text-[14px] text-gray-400 py- pr-4 bg-gray-50 border border-gray-200 rounded-[4px]"
                    />
                    <span className="absolute right-2 top-1/2 transform -translate-y-1/2 text-black font-semibold">
                      %
                    </span>
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-[16px] leading--6 text-[#000000]">
                    {formatMoney(calcTax.toFixed(2), formData.currency)}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex justify-between bg-[#0D3C6D] p-3 font-semibold text-[16px] text-[#ffffff]">
              <p className="">Total amount</p>
              <p className="">
                {" "}
                {formatMoney(total.toFixed(2), formData.currency)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummarySection;
