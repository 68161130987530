import { useEffect, useState } from "react";
import { ICreateSalesData, ICustomer } from "../../@types";
import {
  InvoiceErrors,
  validateFreeFormItemDetails,
  validateInvoiceBasicInfo,
  validateInvoiceItemDetails,
  validateInvoiceSummary,
} from "../../modules/validateInvoiceForm";
import { useCompanyStore } from "../../state/useCompanyStore";

const initialFormData: ICreateSalesData = {
  additionalInformation: "",
  customerId: "",
  description: "",
  currency: "NGN",
  discount: 0,
  inventories: [{ id: "", name: "", amount: 0, quantity: 0, rate: 0 }],
  services: [{ id: "", name: "", duration: "", price: 0 }],
  invoiceDueAt: "",
  invoiceNumber: "",
  issueDate: new Date().toISOString().substring(0, 10),
  isReoccurring: false,
  isReoccurringEndDate: "",
  isReoccurringStartDate: "",
  reoccurringGroupName: "",
  status: "pending",
  tax: 0,
  isPaymentCompleted: false,
  shouldSendEmail: true,
  exchangeRate: 1,
  paymentMethod: "",
  lineItems: [],
  invoiceType: "freeform",
  logoBase64: "",
  //   reminderSettings: [
  //     { days: 0, remindBy: "before", remindMe: false, isActive: false },
  //     { days: 0, remindBy: "due", remindMe: false, isActive: false },
  //     { days: 0, remindBy: "after", remindMe: false, isActive: false },
  //   ],
};

export const useFreeformInvoiceFormState = (initialData?: ICreateSalesData) => {
  const companyStore = useCompanyStore();
  const isRetail = companyStore.isRetailBusiness();

  const [formData, setFormData] = useState<ICreateSalesData>(
    initialData || initialFormData,
  );
  const [validationResults, setValidationResults] = useState<InvoiceErrors>({});
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDraftLoading, setIsDraftLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
    //@ts-ignore
    if (validationResults[name]) {
      setValidationResults((prev: any) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleSetCustomer = (selectedCustomer: ICustomer | null) => {
    setCustomer(selectedCustomer || null);
    setFormData((prev: any) => ({
      ...prev,
      customerId: selectedCustomer?.id || "",
    }));
  };

  const validateForm = () => {
    const basicInfoValid = validateInvoiceBasicInfo(
      formData,
      setValidationResults,
    );
    // const itemDetailsValid = validateInvoiceItemDetails (
    //   formData,
    //   setValidationResults,
    //   isRetail
    // );
    const summaryValid = validateInvoiceSummary(formData, setValidationResults);
    return basicInfoValid && summaryValid;
  };

  const resetForm = () => {
    setFormData(initialFormData);
    setValidationResults({});
    setCustomer(null);
  };

  return {
    formData,
    setFormData,
    customer,
    handleSetCustomer,
    validationResults,
    setValidationResults,
    errorMessage,
    setErrorMessage,
    isLoading,
    setIsLoading,
    isDraftLoading,
    setIsDraftLoading,
    showSidebar,
    setShowSidebar,
    handleInputChange,
    validateForm,
    resetForm,
    isRetail,
  };
};
