import React, { useEffect } from "react";
import { NotificationEntity } from "../../../../@types";
import { Link, useParams } from "react-router-dom";

interface props {
  notification: NotificationEntity;
}

const ErrorNotification = ({ notification }: props) => {
  const createdAtDate = new Date(notification.createdAt);
  const formattedDate = `${createdAtDate.getDate()}/${
    createdAtDate.getMonth() + 1
  }/${createdAtDate.getFullYear()}`;
  return (
    <div className="flex flex-row rounded-md bg-[#F9DEDE] w-[439px]">
      <div className="bg-red-600 w-2"></div>
      <div className=" p-4 flex flex-col gap-[23px]">
        {notification.entityType === "expense" ? (
          <>
            <div className="flex justify-between gap-[162px]">
              <h1 className="text-[18px] text-[#000] w-[185px] space-x-10 font-semibold">
                {notification.type}
              </h1>
              <p className="font-light text-sm">{formattedDate}</p>
            </div>
            <div>
              <p className="flex flex-col ">
                <span className="">{notification.text}</span>
                <u>
                  <Link className="text-blue-900" to="/expenses/create">
                    Create Expense
                  </Link>
                </u>
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-between gap-[162px]">
              <h1 className="text-[18px] text-[#000] w-[185px] font-semibold">
                Invoice Upload Failed
              </h1>
              <p className="font-light text-sm">{formattedDate}</p>
            </div>
            <p className="font-semibold text-md">{notification.type}</p>
            <div>
              <p className="flex flex-col gap-[20px]">
                <span className="text-[16px] text-[#000] w-[365px]">
                  {notification.text}
                  <Link
                    className="text-blue-900"
                    to={`/invoices/${notification.entityId}/view?action=showPayment`}
                  >
                    INVOO1
                  </Link>
                  . The image uploaded might be too blurred. We suggest you
                  re-upload the image or input manually
                </span>
                <u>
                  <Link className="text-blue-900" to={`/invoices`}>
                    Input Manually
                  </Link>
                </u>
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ErrorNotification;
