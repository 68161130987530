export const withRetry = async (
  fn: () => Promise<any>,
  retries = 3,
  backoff = 1000,
): Promise<any> => {
  // List of non-retryable status codes
  const nonRetryableStatusCodes = [400, 401, 403, 404, 422];

  for (let i = 0; i < retries; i++) {
    try {
      return await fn();
    } catch (error) {
      if (isNonRetryableError(error, nonRetryableStatusCodes)) {
        throw error;
      }

      // If it's the last retry attempt, throw the error
      if (i >= retries - 1) {
        throw error;
      }

      // Wait before the next retry attempt
      await new Promise((res) => setTimeout(res, backoff * Math.pow(2, i)));
    }
  }
};

const isNonRetryableError = (
  error: unknown,
  nonRetryableStatusCodes: number[]
): boolean => {
  if (typeof error === "object" && error !== null && "response" in error) {
    const response = (error as { response?: { status?: number } }).response;
    if (response && typeof response.status === "number") {
      return nonRetryableStatusCodes.includes(response.status);
    }
  }
  return false;
};
