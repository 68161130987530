import { InputComponent } from "../../core/Forms";
import * as UI from "../../common";
import React, { useState } from "react";
import View from "../../../assets/icons/svgicons/View.svg";
import { Button, SidebarDrawer } from "../../common";
import ProcessSuccessful from "../../../assets/icons/ProcessSuccessful";
import { validateNewPasswordForm } from "../../../modules/settings/settings";
import toast from "react-hot-toast";
import ErrorComponent from "../../Error";
import { useTranslation } from "react-i18next";

interface NewPasswordProps {
  oldPassword: string;
  onSubmit: (newPassword: string, confirmPassword: string) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const NewPassword: React.FC<NewPasswordProps> = ({
  onSubmit,
  onCancel,
  oldPassword,
  isLoading,
}) => {
  const { t } = useTranslation("settings");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const handleNewPasswordSubmit = (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e?.preventDefault();
    const validationErrors = validateNewPasswordForm(
      newPassword,
      confirmPassword,
      oldPassword
    );

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      onSubmit(newPassword, confirmPassword);
      setNewPassword("");
      setConfirmPassword("");
      setErrors({});
    } catch (error) {
      return <ErrorComponent errorCode={500} />;
    }
  };

  return (
    <div className="bg-[#FFFFFF] rounded-[8px] w-full lg:w-full h-screen lg:h-[788px] p-[5.7%] lg:p-11 text-[#212121]">
      <h1 className="text-[16px] md:text-[20px] font-semibold">
        {t("Settings.changepassword")}
      </h1>
      <p className="text-[12px] md:text-[16px] mt-2 md:mt-3">
        {t("Settings.secureyouraccountwithastrongpassword")}
      </p>

      <hr className="mt-[10px] mb-[20px] md:my-6 border border-[#000000] border-opacity-25 lg:max-w-[992px]" />

      <div>
        <div className="w-full md:max-w-[434px]">
          <div className="text-[14px] md:text-[16px] mb-1 md:mb-2">
            {t("Settings.createpassword")}
          </div>
          <InputComponent
            placeholder={t("Settings.createpassword")}
            name="newPassword"
            type="password"
            value={newPassword}
            handleChange={(event: {
              target: { value: React.SetStateAction<string> };
            }) => setNewPassword(event.target.value)}
            className="w-full !pl-4 rounded h-10 md:h-12 border !border-[#A4A4A4]"
            error={errors.newPassword}
            imageClassName="ml-[90%] mt-[-2em] md:mt-[-2.2em]"
          />
        </div>

        <div className="mt-4 w-full md:max-w-[434px]">
          <div className="text-[14px] md:text-[16px] mb-1 md:mb-2">
            {t("Settings.confirmpassword")}
          </div>
          <InputComponent
            placeholder={t("Settings.confirmpassword")}
            name="confirmPassword"
            type="password"
            image={View}
            value={confirmPassword}
            handleChange={(event: {
              target: { value: React.SetStateAction<string> };
            }) => setConfirmPassword(event.target.value)}
            className="w-full !pl-4 rounded h-10 md:h-12 border !border-[#A4A4A4]"
            error={errors.confirmPassword}
            imageClassName="ml-[90%] mt-[-2em] md:mt-[-2.2em]"
          />
        </div>
      </div>

      <div className="mt-8 flex flex-row-reverse md:flex-row w-full md:max-w-[434px] gap-4 items-center">
        <Button
          size="full"
          text="Cancel"
          disabled={isLoading}
          variant="secondary"
          font="semibold"
          onClick={onCancel}
          outline
        />
        <UI.Button
          size="full"
          text="Save Password"
          isLoading={isLoading}
          disabled={isLoading}
          font="semibold"
          onClick={(e) => handleNewPasswordSubmit(e)}
        />
      </div>
    </div>
  );
};
