export const populatedValues = (obj: {}) => {
  const values = Object.values(obj);
  const populatedCount = values.filter(
    (value) => value !== null && value !== undefined && value !== ""
  ).length;
  const totalCount = values.length;

  let percent = (populatedCount / totalCount) * 100;
  return { populatedCount, totalCount, percent };
};
