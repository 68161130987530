import * as React from "react";
import { CloseIcon, SearchIcon } from "../../../assets/icons";

import { GroupButton } from "../GroupButtons";

import { SearchInput } from "../SearchInput";

import { DropdownComponent, InputComponent } from "../../core/Forms";

import { FILTER_LIST } from "../../../constants";

import Chip from "../Chip";

import { Filter } from "../../../@types/invoice";

type FilterCallback = (filter: Filter) => void;

interface HeadProps {
  buttons?: Array<{
    text: string;
    icon: any;
    onClick?: () => void;
    href?: string;
  }>;
  handleSearch?: (text: string) => void;
  handleFilter?: FilterCallback;
  filter?: Filter;
  handleSort?: (text: string) => void;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
}

const Head: React.FC<HeadProps> = (props) => {
  const [text, setText] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(" Recent");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const dropDownArray = FILTER_LIST;

  const handleSearch = async (text: string) => {
    if (!text || text.length < 2) return;
    try {
      await props.handleSearch?.(text);
    } catch (error) {
      console.error("Error occurred during search:", error);
    }
  };

  const handleDropDownChange = (value: string) => {
    let sortValue = "";
    if (value === "Recent") sortValue = "recent";
    if (value === "Last Week") sortValue = "last_week";
    if (value === "Last Month") sortValue = "last_month";
    if (value === "Last Quarter") sortValue = "last_quarter";

    props.handleSort ? props.handleSort(sortValue) : null;
  };

  const handleChipClose = (chipKey: keyof Filter) => {
    if (props?.filter) {
      let newFilter = { ...props?.filter };

      if (chipKey in newFilter) {
        if (typeof newFilter[chipKey] === "boolean") {
          (newFilter[chipKey] as boolean | null) = null;
        } else if (typeof newFilter[chipKey] === "string") {
          (newFilter[chipKey] as string) = "";
        }
      }

      props.handleFilter ? props.handleFilter(newFilter as Filter) : undefined;
    }
  };

  const renderFilterChip = () => {
    const chips = [];

    const filterLabels = {
      status: "Status",

      isReoccurring: { true: "Reoccurring", false: "One-time" },

      billable: { true: "Billable", false: "Not Billable" },

      paymentMethod: "Payment Method",
    };

    if (props.filter) {
      for (const key in filterLabels) {
        const filterKey = key as keyof Filter;

        if (
          filterKey in props.filter &&
          props.filter[filterKey] !== null &&
          props.filter[filterKey] !== ""
        ) {
          const label =
            typeof props.filter[filterKey] === "boolean"
              ? filterLabels[filterKey][
                  props.filter[filterKey] as "true" | "false"
                ]
              : props.filter[filterKey];

          chips.push(
            <Chip
              key={filterKey}
              label={label}
              clearAction={() => handleChipClose(filterKey)}
            />
          );
        }
      }
    }

    return chips;
  };

  return (
    <>
      {/* desktop */}
      <div className="hidden lg:flex justify-between items-center w-full pt-4 px-3">
        <div className="w-full max-w-[350px]">
          <SearchInput
            placeholder={props.searchPlaceHolder}
            displayKey={props.searchDisplayKey}
            isLoading={props.isSearching}
            searchedDone={props.searchedDone}
            setSearchedDone={props.setSearchedDone}
            value={text}
            onSelect={props.handleSearchSelect}
            handleChangeText={handleSearch}
            clearIcon={CloseIcon}
            icon={<SearchIcon />}
            data={props.searchResult}
            emptyMessage={"This Item is not found"}
            dropdownClassName="md:ml-[2.7em] lg:w-full lg:ml-[0.1em] xl:ml-[0.1em]"
            dropdownStyle={{
              maxHeight: "400px",

              overflowY: "auto",
            }}
            classNames="h-[48px] rounded-xl text-[16px] pl-[4em] w-full max-w-[350px] border border-gray-200"
            searchKey={["customer.firstName", "vendorName", "name"]}
          />
        </div>
      </div>

      {/* Tablet */}

      <div
        className={`${
          props.buttons ? "flex" : ""
        } gap-[7.9%] lg:hidden w-full mt-8`}
      >
        <div className="md:w-full">
          {props.buttons && <GroupButton buttons={props.buttons} />}
        </div>

        <div className="w-full">
          <SearchInput
            placeholder={props.searchPlaceHolder}
            displayKey={props.searchDisplayKey}
            // isLoading={isSearching}
            isLoading={props.isSearching}
            searchedDone={props.searchedDone}
            setSearchedDone={props.setSearchedDone}
            value={text}
            onSelect={props.handleSearchSelect}
            handleChangeText={handleSearch}
            icon={<SearchIcon />}
            clearIcon={CloseIcon}
            data={props.searchResult}
            emptyMessage={"This Item is not found"}
            dropdownClassName="w-full md:ml-[0.2em] ml-[0.2e]"
            dropdownStyle={{
              maxHeight: "400px",

              overflowY: "auto",
            }}
            classNames="h-[48px] border border-transparent rounded-md md:w-full md:ml-[0.1em]"
            searchKey={["customer.firstName", "vendorName", "name"]}
          />
        </div>
      </div>

      <div className="flex lg:hidden">{renderFilterChip()}</div>
    </>
  );
};

export { Head };
