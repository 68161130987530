import React, { useEffect, useState } from "react";
import { ICreateProfile } from "../../../../@types/profile";
import { updateProfile } from "../../../../backend-services";
import { EditProfile } from "./EditForm";
import toast from "react-hot-toast";
import { useUserStore } from "../../../../state";
import ErrorComponent from "../../../Error";
import { CloseIcon } from "../../../../assets/icons";
import cx from "classnames";
import { useTranslation } from "react-i18next";

interface CreateEditProfileProps {
  onCreateEditProfile?: (profile: ICreateProfile) => void;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  show: boolean;
  onClose?: () => void;
}

const CreateEditProfile = ({
  onCreateEditProfile,
  show,
  onClose,
}: CreateEditProfileProps) => {
  const { t } = useTranslation("settings");
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [, setErrorMessage] = React.useState("");
  const userStore = useUserStore();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    businessName: " ",
    email: "",
    phone: "",
    industry: "",
    address: "",
    state: "",
    city: "",
    code: "",
    businessSize: "",
    brandColor: "",
    logoBase64: "",
    isVerified: false,
  });
  const handleEditProfile = async (profile: ICreateProfile) => {
    setIsLoading(true);
    try {
      const updatedFields: Partial<ICreateProfile> = {};
      for (const key of Object.keys(profile)) {
        if (Object.prototype.hasOwnProperty.call(profile, key)) {
          if (
            //@ts-ignore
            formData[key as keyof ICreateProfile] !==
            profile[key as keyof ICreateProfile]
          ) {
            //@ts-ignore
            updatedFields[key as keyof ICreateProfile] =
              profile[key as keyof ICreateProfile];
          }
        }
      }
      const data = await updateProfile(updatedFields);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...data,
      }));
      setIsLoading(false);
      setIsOpen(false);
      toast.success("Profile Changes saved");
      onCreateEditProfile && onCreateEditProfile(data);
      onClose && onClose();
    } catch (e) {
      setIsLoading(false);
      setErrorMessage("Unable to update profile.");
      return <ErrorComponent errorCode={500} />;
    }
  };

  useEffect(() => {
    if (userStore.user) {
      setFormData(userStore.user);
    }
  }, [userStore.user]);

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return (
    <>
      {isOpen && (
        <div className="fixed top-0 right-0 bottom-0 left-0 z-50 flex items-start bg-gray-900 bg-opacity-50">
          <div
            className={cx(
              "fixed top-0 right-0 bottom-0 z-50 bg-white shadow h-full w-full max-w-lg overflow-y-auto",
              {
                "animate-slide-in-right": isOpen,
              }
            )}
          >
            <div className="flex px-[30px] items-center justify-between">
              <div className="flex items-start justify-between p-3">
                <h3 className="text-[25px] font-bold ml-[-1em]">
                  {t("settings.Editprofile")}
                </h3>
              </div>
              <button
                aria-label="Close"
                className="xl:inline-flex xl:items-center rounded-lg bg-transparent text-red-400"
                type="button"
                onClick={handleClose}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="xl:p-4 pt-10">
              <EditProfile
                isLoading={isLoading}
                show={true}
                onSubmit={handleEditProfile}
                onClose={handleClose}
                data={formData}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { CreateEditProfile };
