import toast from "react-hot-toast";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  errorProps,
  InventoryItem,
  VariationError,
  VariationItem,
} from "../../../../@types/inventory";
import { useInventoryStore } from "../../../../state/useInventoryStore";
import { convertToFloat } from "../../../../utils/convertToFloat";
import {
  validateCreateInventory,
  ValidateVariation,
} from "../../../../modules/inventory/inventory";
import { InventoryForm } from "../../../inventory/Create";
import { Button, SidebarDrawer } from "../../../common";
import VariationList from "../../../inventory/Variations/VariationList";
import VariationForm from "../../../inventory/Variations/VariationForm";
import { IInventory, ItemInput } from "../../../../@types";
import { createInventory } from "../../../../backend-services";
import { CloseIcon } from "../../../../assets/icons";
import { InventoryCreateTable } from "./inventoryCreateShort";

interface InventoryPageProps {
  showTitle?: boolean;
  showSidebar?: boolean;
  showVariationList?: boolean;
  showCreateForm?: boolean;
  showForms?: boolean;
  onCreateSuccess: (item: ItemInput) => void;
  setShowCreateInventory: (show: boolean) => void;
  handleCreateInventory?: (
    e?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
  onclose?: () => void;
}

const InventoryOverlay: React.FC<InventoryPageProps> = ({
  showSidebar = true,
  showVariationList = true,
  showCreateForm: initialShowCreateForm = false,
  showTitle = true,
  showForms = true,
  setShowCreateInventory,
  onCreateSuccess,
  onclose,
}) => {
  const navigate = useNavigate();
  const [error, setError] = useState({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [variationList, setVariationList] = useState<VariationItem[]>([]);
  const [variantName, setVariantName] = useState<string>("");
  const [variantTypes, setVariantTypes] = useState<string[]>([]);
  const [variantPrices, setVariantPrices] = useState<number[]>([]);
  const [inventories, setInventories] = useState<InventoryItem>({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    description: "",
    variants: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { variations, fetchVariations } = useInventoryStore((state) => ({
    variations: state.variations,
    fetchVariations: state.fetchVariation,
  }));

  useEffect(() => {
    if (variationList.length > 0) {
      setVariantName(variationList[0].name || "");
      setVariantTypes(variationList.map((item) => item.type || ""));
      setVariantPrices(variationList.map((item) => convertToFloat(item.price)));
    }
  }, [variationList]);

  useEffect(() => {
    fetchVariations();
  }, [fetchVariations]);

  useEffect(() => {
    console.log("Variation List Updated:", variationList);
    variationList.forEach((variation, index) => {
      console.log(`Variation ${index + 1}:`, {
        Name: variation.name,
        Type: variation.type,
        Price: variation.price,
        CostPrice: variation.costPrice,
        Quantity: variation.quantity,
      });
    });

    if (variationList.length > 0) {
      setVariantName(variationList[0].name || "");
      setVariantTypes(variationList.map((item) => item.type || ""));
      setVariantPrices(variationList.map((item) => convertToFloat(item.price)));
    }
  }, [variationList]);

  const handleCreateInventory = async () => {
    console.log("handleCreateInventory triggered", inventories);
    setError({ name: "", rate: "", costPrice: "", quantity: "" });

    const newRate = convertToFloat(inventories.rate);
    const newCostPrice = convertToFloat(inventories.costPrice);
    const newQuantity = convertToFloat(inventories.quantity);

    //@ts-ignore
    const newVariationList = inventories.variants
      .map(
        (variant: {
          types: {
            name: any;
            costPrice: string | number | undefined;
            price: string | number | undefined;
            quantity: string | number | undefined;
          }[];
          variantName: any;
        }) => {
          return variant.types.map(
            (type: {
              name: any;
              costPrice: string | number | undefined;
              price: string | number | undefined;
              quantity: string | number | undefined;
            }) => ({
              name: variant.variantName,
              type: type.name,
              costPrice: convertToFloat(type.costPrice),
              price: convertToFloat(type.price),
              quantity: convertToFloat(type.quantity),
            }),
          );
        },
      )
      .flat();

    const { hasErrors, errors } = validateCreateInventory({
      rate: newRate,
      costPrice: newCostPrice,
      name: inventories.name,
      variationLen: newVariationList.length,
    });

    const variationErrorMsg = ValidateVariation(newVariationList);

    if (hasErrors || variationErrorMsg.length > 0) {
      setError(errors);
      setErrorMessage("Cannot create inventory with duplicate variations.");
      return;
    }

    try {
      setIsLoading(true);

      const payload = {
        name: inventories.name,
        rate: newRate || 0,
        costPrice: newCostPrice || 0,
        quantity: newQuantity || 0,
        variations: newVariationList,
      };

      // API call
      const response: IInventory = await createInventory(payload);

      const newItem: ItemInput = {
        id: response.id,
        name: response.name,
        rate: response.rate ?? 0,
        //@ts-ignore
        variations: response.variations?.map((variation) => ({
          ...variation,
          price: variation.price ?? 0,
          quantity: variation.quantity ?? 0,
        })),
      };

      toast.success("New Inventory Added Successfully!");
      setShowCreateInventory(false);
      onCreateSuccess(newItem);
    } catch (error) {
      toast.error("Failed to create inventory");
      setShowCreateInventory(false);
      console.error("Error creating inventory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-between ">
        <div className="flex items-center gap-4"></div>
      </div>
      <>
        <div className="flex items-start justify-between gap-[6em]">
          <div className="mt-3">
            <h1 className="text-[14px] md:text-[18px] lg:text-[24px] font-normal">
              Create Inventory
            </h1>
          </div>
          <button
            className=" rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black] w-35 h-35 "
            onClick={onclose}
          >
            <CloseIcon />
          </button>
        </div>
        <InventoryCreateTable
          //@ts-ignore
          inventory={inventories}
          //@ts-ignore
          setInventories={setInventories}
          variations={variationList}
          setVariations={setVariationList}
          error={error}
          setError={setError}
          variationList={variations}
        />

        <div className="flex justify-end mt-[2em]">
          <Button
            variant="secondary"
            text="Done"
            size="small"
            font="semibold"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={handleCreateInventory}
          />
        </div>
      </>
    </div>
  );
};

export { InventoryOverlay };
