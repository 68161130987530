import React from "react";
import { IVariation } from "../../../../@types";
import ArrowRight from "../../../../assets/icons/ArrowRight";
import PlusIcon from "../../../../assets/icons/PlusIcon";

const SelectVariation = ({
  variations,
  handleVariationClick,
  openModalDirect,
}: {
  variations: IVariation[];
  handleVariationClick: (variant: IVariation) => void;
  openModalDirect: () => void;
}) => {
  return (
    <div>
      <div className="py-4 border-t-2 border-b-2 border-gray-200 px-5 mt-[1em]">
        <div className="font-medium text-[14px]">
          <p className="leading-5 text-[#000000]">Product options</p>
          <div className="flex items-center gap-2 mt-2">
            <p className="leading-5 text-gray-600">
              Select your preferred option
            </p>
          </div>
        </div>
      </div>

      <div className="max-h-[40vh] overflow-hidden overflow-y-scroll scrollbar">
        {variations.map((variant) => (
          <div
            key={variant.id}
            onClick={() => handleVariationClick(variant)}
            className="flex justify-between items-center py-4 hover:bg-slate-300/10 cursor-pointer px-5"
          >
            <div>
              <h1 className="mb-2">{variant.variantName}</h1>
              <div className="flex gap-4 items-center">
                {variant.types.map((v, i) => (
                  <span
                    key={i}
                    className="text-sm bg-gray-100 p-2 font-medium rounded-md"
                  >
                    {v}
                  </span>
                ))}
              </div>
            </div>
            <ArrowRight color={"black"} size={"17"} />
          </div>
        ))}
      </div>

      <div className="py-6 border-b-2 border-t-2 border-gray-200 px-5">
        <div className="font-medium text-[14px]">
          <p
            onClick={openModalDirect}
            className="cursor-pointer leading-5 text-[#044E97] flex items-center space-x-3"
          >
            <PlusIcon /> <span> Add another option</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SelectVariation;
