import React, { useState, useEffect } from "react";
import Location from "../../../constants/Location.json";
import { DropdownComponent } from "../../core/Forms";

interface LocationData {
  name: string;
  cities: string[];
}

interface LocationSelectorProps {
  onLocationChange: (state: string, cities: string[]) => void;
  onError: (error: string) => void;
  selectedState: string;
  selectedCity: string;
}

interface DropdownOption {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

const CustomerLocationSelector: React.FC<LocationSelectorProps> = ({
  onLocationChange,
  onError,
  selectedState,
  selectedCity,
}) => {
  const [isCityDisabled, setIsCityDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsCityDisabled(!selectedState);
  }, [selectedState]);

  useEffect(() => {
    if (selectedState && !selectedCity) {
      onError("Please select a city.");
    } else {
      onError("");
    }
  }, [selectedState, selectedCity, onError]);

  const handleStateChange = (name: string, value: string) => {
    if (value) {
      const selectedStateData = Location.find(
        (state: LocationData) => state.name === value
      );
      const citiesForState = selectedStateData?.cities || [];
      setIsCityDisabled(false);
      onLocationChange(value, citiesForState);
    } else {
      setIsCityDisabled(true);
      onLocationChange("", []);
    }
  };

  const handleCityChange = (name: string, value: string) => {
    onLocationChange(selectedState, [value]);
  };

  const stateOptions: DropdownOption[] = Location.map(
    (state: LocationData) => ({
      value: state.name,
      label: state.name,
    })
  );

  const selectedStateData = Location.find(
    (state: LocationData) => state.name === selectedState
  );
  const cityOptions: DropdownOption[] =
    selectedStateData?.cities.map((city: string) => ({
      value: city,
      label: city,
    })) || [];

  return (
    <div className="grid grid-cols-2 gap-4 text-[16px]">
      <div className="col-span-1 w-[420px]">
        <label className="font-normal text-gray-700 flex justify-between">
          State
          <span className="text-red-500"></span>
        </label>
        <DropdownComponent
          options={stateOptions}
          name="state"
          value={selectedState}
          placeholder="Select a state"
          onChange={handleStateChange}
          iconClassName="ml-[-2em]"
          selectedValue={selectedState}
          dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-[420px]"
        />
      </div>
      <div className="col-span-1 w-[420px] ml-[21em]">
        <label className="font-normal text-gray-700 flex justify-between">
          City
          <span className="text-red-500"></span>
        </label>
        <DropdownComponent
          options={cityOptions}
          name="city"
          value={selectedCity}
          placeholder="Select a city"
          onChange={handleCityChange}
          disabled={isCityDisabled}
          iconClassName="ml-[-2em]"
          dropdownClassName="w-[420px] z-10 max-h-52 overflow-y-auto bg-white border border-gray-300"
          selectedValue={selectedCity}
        />
      </div>
    </div>
  );
};

export default CustomerLocationSelector;
