import React, { useState } from "react";
import * as UI from "../../common";
import { AuthenticationDetails } from "amazon-cognito-identity-js";
import toast from "react-hot-toast";
import userPool from "../../../utils/userPool";
import { validateSubUserNewPasswordForm } from "../../../modules/settings/settings";
import ErrorComponent from "../../Error";
import {
  PasswordResetIcon,
  PasswordResetIconSmall,
} from "../../../assets/icons/PasswordReset";
import { InputComponent } from "../Forms";
import { useSubUserStore } from "../../../state/useSubUserStore";
import { useUserStore } from "../../../state";

interface ChangePasswordProps {
  onClose: () => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [tempPassword, setTempPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [validationErrors, setValidationErrors] = useState<{
    tempPassword?: string;
    newPassword?: string;
    confirmPassword?: string;
  }>({});
  const userSubStore = useSubUserStore();
  const updateUserPasswordChangedFlag = useUserStore(
    (state) => state.updateUserPasswordChangedFlag
  );
  const setPasswordChanged = useSubUserStore(
    (state) => state.setPasswordChanged
  );

  const handleChangePassword = (
    tempPassword: string,
    newPassword: string,
    confirmPassword: any
  ) => {
    setIsLoading(true);
    const user = userPool.getCurrentUser();
    if (!user) {
      setIsLoading(false);
      setMessage("User is not authenticated");
      return;
    }
    const authenticationDetails = new AuthenticationDetails({
      Username: user.getUsername(),
      Password: tempPassword,
    });
    user.authenticateUser(authenticationDetails, {
      onSuccess: async (result) => {
        user.changePassword(
          tempPassword,
          newPassword,
          async (error, result) => {
            setIsLoading(false);
            if (error) {
              setMessage(error.message);
            } else {
              setMessage("Password has been changed successfully!");

              const username = user.getUsername();
              if (username) {
                const loggedInUser = userSubStore.users.find(
                  (user) => user.email === username
                );
                if (loggedInUser && loggedInUser.id) {
                  await updateUserPasswordChangedFlag(loggedInUser.id, true);
                  setPasswordChanged(true);
                } else {
                  setMessage("User not found or user ID is undefined");
                }
              } else {
                setMessage("Username is undefined");
              }
            }
          }
        );
      },
      onFailure: (error) => {
        setIsLoading(false);
        setMessage(error.message);
      },
    });
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const validationErrors = validateSubUserNewPasswordForm(
      newPassword,
      confirmPassword,
      tempPassword
    );
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrors(validationErrors);
      toast.error("Please fill in the required Info");
      return;
    }
    setIsLoading(true);
    const user = userPool.getCurrentUser();
    if (!user) {
      setIsLoading(false);
      setErrorMessage("User is not authenticated");
      return;
    }
    const authenticationDetails = new AuthenticationDetails({
      Username: user.getUsername(),
      Password: tempPassword,
    });

    try {
      await new Promise<void>((resolve, reject) => {
        user.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            resolve();
          },
          onFailure: (error) => {
            setIsLoading(false);
            if (error.code === "NotAuthorizedException") {
              setValidationErrors({ tempPassword: "Wrong current password" });
              toast.error("Wrong current password!");
            } else {
              setValidationErrors({
                tempPassword: "Error occurred during authentication",
              });
              toast.error("Authentication error occurred!");
            }
            setErrorMessage(error.message);
            reject(error);
          },
        });
      });
      handleChangePassword(tempPassword, newPassword, confirmPassword);
      setIsSuccess(true);
      setShowSuccessModal(true);
      setValidationErrors({});
    } catch (error) {
      return <ErrorComponent errorCode={500} />;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="px-4 py-6 md:p-4 bg-[#ffffff] fixed rounded-[16px] h-[415px] w-[292px] md:w-[660px] md:h-[400px] lg:w-[845px] lg:h-[500px]">
        <div className="md:grid md:grid-cols-2 ">
          <div className="hidden md:block lg:hidden">
            <PasswordResetIconSmall />
          </div>
          <div className="hidden lg:block ">
            <PasswordResetIcon />
          </div>
          {showSuccessModal ? (
            <div>
              <div>
                <p className="font-semibold md:text-[16px] lg:text-[18px] text-center mt-0 md:mt-[4em] lg:mt-[5em]">
                  Password Reset Successful
                </p>
                <p className="font-normal text-[10px] md:text-[13px] mt-5 lg:text-[13px] 2xl:text-[17px]">
                  Your password has been successfully reset. You can now log in
                  with your new password. If you have any further questions or
                  concerns, please don't hesitate to contact our support team.
                </p>
                <p className="text-center text-[10px] md:text-[13px] lg:text-[13px] 2xl:text-[17px]">
                  Thank you!
                </p>
              </div>
              <div className="lg:h-[48px] lg:justify-start md:justify-start justify-center  h-[28.86px] md:text-[7px] mt-[1em] flex  mb-[40px] lg:py-[55px] w-[261px] lg:mx-auto lg:w-[355px] xl:w-[395px] md:mt-[7em] lg:mt-[-2em] biglg:mt-[-3em] md:ml-[2em] 2xl:ml-[3em]  ml-[-0.8em] xl:ml-[1em] biglg:ml-[1em] xl:mt-[1em] ">
                <div className="lg:w-[355px] xl:w-[395px] biglg:w-[335px] w-[261px] text-[12px] md:w-[260px] ">
                  <UI.Button
                    text="Go Back Home"
                    isLoading={isLoading}
                    onClick={onClose}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <div className="text-center">
                <h2 className="font-semibold lg:text-[24px] text-[16px] flex justify-center">
                  Password Reset Required
                </h2>
                <p className="inline-block text-[12px]">
                  Your current password is temporary;
                </p>
                <p className="text-[12px]">Please reset password</p>
              </div>
              <div className="md:w-full">
                <form onSubmit={handleFormSubmit}>
                  <div className="mt-2 lg:mt-4">
                    <label
                      htmlFor="tempPassword"
                      className="md:text-[13px] text-black text-[12px]"
                    >
                      Temporary Password
                    </label>
                    <InputComponent
                      placeholder="Password"
                      name="tempPassword"
                      type="password"
                      value={tempPassword}
                      handleChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setTempPassword(e.target.value)}
                      className="w-[261px] rounded-md  md:w-full"
                      imageClassName="ml-[90%] w-[24px] h-[24px] mt-[-36px]"
                    />
                    {validationErrors.tempPassword && (
                      <div className="text-red-500 text-[12px]">
                        {validationErrors.tempPassword}
                      </div>
                    )}
                  </div>
                  <div className="mt-2 lg:mt-4">
                    <label
                      htmlFor="newPassword"
                      className="md:text-[13px] text-black text-[12px]"
                    >
                      Enter New Password
                    </label>
                    <InputComponent
                      placeholder="Password"
                      name="newPassword"
                      type="password"
                      value={newPassword}
                      handleChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setNewPassword(e.target.value)}
                      className="w-[261px] rounded-md  md:w-full"
                      imageClassName="ml-[90%] w-[24px] h-[24px] mt-[-36px]"
                    />
                    {validationErrors.newPassword && (
                      <div className="text-red-500 text-[12px] mt-[-1.3em]">
                        {validationErrors.newPassword}
                      </div>
                    )}
                  </div>
                  <div className="mt-2 lg:mt-4">
                    <label
                      htmlFor="confirmPassword"
                      className="md:text-[13px] text-black text-[12px]"
                    >
                      Confirm Password
                    </label>
                    <InputComponent
                      placeholder="Password"
                      name="confirmPassword"
                      type="password"
                      value={confirmPassword}
                      handleChange={(e: {
                        target: { value: React.SetStateAction<string> };
                      }) => setConfirmPassword(e.target.value)}
                      className="w-[261px] rounded-md md:w-full"
                      imageClassName="ml-[90%] w-[24px] h-[24px] mt-[-36px]"
                    />
                    {validationErrors.confirmPassword && (
                      <div className="text-red-500 text-[12px]">
                        {validationErrors.confirmPassword}
                      </div>
                    )}
                  </div>
                  <div className="mt-[2.5em] md:mt-[1em] lg:mt-[3em] w-full">
                    <UI.Button
                      text={"Reset Password"}
                      variant={isSuccess ? "success" : "primary"}
                      isLoading={isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { ChangePassword };
