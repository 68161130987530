import React, { useState, useCallback } from "react";
import dayjs from "dayjs";
import usePaymentHistory from "../../../../hooks/invoice/usePaymentHistory";
import { IPayment } from "../../../../@types";
import { formatMoney } from "../../../../utils";
import DownloadButton from "../../../common/downloadButton";
import LoadingSpinner from "../../../common/loadingSpinner";
import EmptyState from "../../../common/emptyState";
import { MobileTable } from "../../../common/mobileTable";
import { Table } from "../../../common";
import { ReusableTable } from "../../../table";

type Props = {
  reloadSignal?: number;
};

const PaymentHistoryTable: React.FC<Props> = ({ reloadSignal }) => {
  const { paymentHistory, isLoading, error } = usePaymentHistory(reloadSignal);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);

  const handleDownloadReceipt = useCallback(
    async (receiptUrl: string, payment: IPayment) => {
      const { id, createdAt } = payment;
      const formattedDate = dayjs(createdAt).format("YYYY-MM-DD");
      const fileName = `receipt_${id}_${formattedDate}.pdf`;

      setDownloadingId(id);
      try {
        const response = await fetch(receiptUrl);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      } catch (err: any) {
        console.error("Download failed:", err);
        alert("Failed to download receipt. Please try again.");
      } finally {
        setDownloadingId(null);
      }
    },
    []
  );

  const renderPaymentData = paymentHistory.map((payment) => ({
    date: dayjs(payment.createdAt).format("MMM DD, YYYY"),
    amount: formatMoney(payment.amount),
    method: payment.paymentType,
    entityType: (
      <span className="pl-2 py-1  text-[#0469C5]">
        {payment.receiptUrl ? "Upload" : "Manual"}
      </span>
    ),
    proof: (
      <DownloadButton
        receiptUrl={payment.receiptUrl}
        isDownloading={downloadingId === payment.id}
        onDownload={() => {
          if (payment.receiptUrl)
            handleDownloadReceipt(payment.receiptUrl, payment);
        }}
      />
    ),
  }));

  const columns = [
    { key: "entityType", label: "Payment Type" },
    { key: "method", label: "Payment Method" },
    { key: "date", label: "Payment Date" },
    { key: "amount", label: "Payment Amount" },
    { key: "proof", label: "" },
  ];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <EmptyState message={error} />;
  }

  if (paymentHistory.length === 0) {
    return (
      <EmptyState message="Payment unavailable... you can simply add using the Record payment button." />
    );
  }

  return (
    <>
      <h2 className="text-lg font-semibold mb-4">Payment History</h2>
      <div className="hidden md:block">
        <ReusableTable
          columns={columns}
          isLoading={isLoading}
          data={renderPaymentData}
          key={paymentHistory.length}
        />
      </div>
      <div className="md:hidden">
        <MobileTable
          head={{
            date: { title: "Payment Date" },
            amount: { title: "Payment Amount" },
            method: { title: "Payment Method" },
            proof: { title: "Proof of Payment" },
          }}
          data={renderPaymentData}
          isLoading={isLoading}
          key={paymentHistory.length}
          payHistoryTable={true}
        />
      </div>
    </>
  );
};

export default PaymentHistoryTable;
