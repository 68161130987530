import React, { ReactNode } from "react";
import { ListInfoCardProps } from "./desktop/ListInfoCard";
import { Filter } from "../../@types/invoice";
import ListDesktopTable from "./desktop/ListDesktopTable";
import { useNavigate } from "react-router-dom";
import { ICustomer, IPaginationMeta } from "../../@types";
import { ReusableTableColumn } from "../../@types/table";
import ListOverview from "./desktop/ListOverview";
import { FilterOption } from "./desktop/ListFilter";
import MobileListOverview from "./mobile/MobileListOverview";
import { MobileTableBody } from "../common/MobileTableBody";
import { FilterCategory } from "./mobile/MobileFilter";
import PlusIcon from "../../assets/icons/PlusIcon";
import EmptyPage from "../emptyPage/EmptyPage";

interface ReusableListPageProps {
  showCalendar?: boolean;
  filterByDate?: boolean;
  handleCalendarToggle?: () => void;
  selectedLabel?: string;
  handleCreate: () => void;
  infoCardData: ListInfoCardProps[];
  isLoading: boolean;
  isLoadingMore: boolean;
  isLoadingFilter?: boolean;
  data: any[];
  fetchMoreData: () => void;
  onSearchData?: (text: string) => void;
  handleFilter?: (filter: Filter) => void;
  isSearching: boolean | undefined;
  searchedDone?: boolean;
  setSearchedDone?: (searchedDone: boolean) => void;
  handleSearch?: (text: string) => void;
  searchResult: any[];
  selectedFilter?: Filter | undefined;
  meta: IPaginationMeta;
  modules: string;
  searchDisplayKey: string;
  searchPlaceHolder: string;
  columns: ReusableTableColumn[];
  cardComponent?: ReactNode;
  showBudgetCard?: boolean;
  showReOccurring?: boolean;
  showFilter?: boolean;
  createTitle?: string;
  pageTitle: string;
  categories?: FilterOption[];
  mobileCategories?: FilterCategory[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  renderActionMenu?: (
    row: Record<string, any>,
    toggleVisibility: () => void,
    isVisible: boolean
  ) => React.ReactNode;
  mobileCard?: string;
  clearAllFilters?: () => void;
  expenseUpload?: ReactNode;
  emptyStateDescription?: string;
  emptyPageIcon?: ReactNode;
  emptyPageHref?: string;
  emptyPageLinkName?: string;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  emptyPageOnClose?: () => void;
  emptyMessage?: string;
  reOccurringText?: string;
}

const ReusableListPage: React.FC<ReusableListPageProps> = ({
  showCalendar,
  filterByDate,
  handleCalendarToggle,
  selectedLabel,
  handleCreate,
  infoCardData,
  data,
  handleSearch,
  handleFilter,
  isSearching,
  searchedDone,
  setSearchedDone,
  searchResult,
  searchDisplayKey,
  searchPlaceHolder,
  columns,
  modules,
  isLoading,
  fetchMoreData,
  onSearchData,
  meta,
  showBudgetCard,
  createTitle,
  showReOccurring,
  categories,
  mobileCategories,
  selectedFilter,
  onSubmit,
  renderActionMenu,
  mobileCard,
  showFilter = true,
  clearAllFilters,
  expenseUpload,
  isLoadingMore,
  pageTitle,
  emptyStateDescription,
  emptyPageIcon,
  emptyPageHref,
  emptyPageLinkName,
  handleSetCustomer,
  emptyPageOnClose,
  emptyMessage,
  isLoadingFilter,
  reOccurringText,
}) => {
  const navigate = useNavigate();

  const fetchPageData = (direction: "up" | "down") => {
    if (direction === "down" && meta.hasMoreData) {
      fetchMoreData();
    }
  };

  const handleSearchSelect = (item: any) => {
    navigate(`/${modules}/${item.id}/view`);
  };

  const onSearch = (text: string) => {
    if (onSearchData && handleSearch) {
      handleSearch(text);
    }
  };

  return (
    <>
      <div className="hidden md:block">
        <ListOverview
          showCalendar={showCalendar}
          infoCardData={infoCardData}
          handleCalendarToggle={handleCalendarToggle}
          handleCreate={handleCreate}
          selectedLabel={selectedLabel}
          showBudgetCard={showBudgetCard}
          createTitle={createTitle}
          showReOccurring={showReOccurring}
          filterByDate={filterByDate}
          modules={modules}
          reOccurringText={reOccurringText}
        />
        <ListDesktopTable
          isLoadingMore={isLoadingMore ? isLoadingMore : false}
          isLoadingFilter={isLoadingFilter ? isLoadingFilter : false}
          columns={columns}
          searchDisplayKey={searchDisplayKey}
          data={data}
          searchPlaceHolder={searchPlaceHolder}
          searchResult={searchResult}
          isSearching={isSearching}
          searchedDone={searchedDone}
          setSearchedDone={setSearchedDone}
          handleSearchSelect={handleSearchSelect}
          fetchMoreData={fetchPageData}
          meta={meta}
          handleSearch={onSearch}
          modules={modules}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          categories={categories}
          onSubmit={onSubmit}
          renderActionMenu={renderActionMenu}
          showFilter={showFilter}
          clearAllFilters={clearAllFilters}
          emptyStateDescription={emptyStateDescription}
          emptyPageIcon={emptyPageIcon}
          emptyPageHref={emptyPageHref}
          emptyPageLinkName={emptyPageLinkName}
          handleSetCustomer={handleSetCustomer}
          emptyPageOnClose={emptyPageOnClose}
          emptyMessage={emptyMessage}
        />
      </div>
      <div className="md:hidden">
        <>
          <MobileListOverview
            showCalendar={showCalendar}
            infoCardData={infoCardData}
            handleCalendarToggle={handleCalendarToggle}
            handleCreate={handleCreate}
            selectedLabel={selectedLabel}
            showBudgetCard={showBudgetCard}
            showReOccurring={showReOccurring}
            filterByDate={filterByDate}
            searchPlaceHolder={searchPlaceHolder}
            searchResult={searchResult}
            isSearching={isSearching}
            searchedDone={searchedDone}
            setSearchedDone={setSearchedDone}
            handleSearchSelect={handleSearchSelect}
            handleSearch={onSearch}
            modules={modules}
            selectedFilter={selectedFilter}
            handleFilter={handleFilter}
            categories={mobileCategories ? mobileCategories : []}
            searchDisplayKey={searchDisplayKey}
            onSubmit={onSubmit}
            showFilter={showFilter}
            clearAllFilters={clearAllFilters}
            pageTitle={pageTitle}
          />
          {data.length > 0 ? (
            <MobileTableBody
              data={data}
              isLoading={isLoadingMore}
              isLoadingFilter={isLoadingFilter ? isLoadingFilter : false}
              fetchMoreData={fetchMoreData}
              invoiceCard={mobileCard === "invoiceCard"}
              quoteCard={mobileCard === "quoteCard"}
              expenseCard={mobileCard === "expenseCard"}
              CustomerCard={mobileCard === "CustomerCard"}
              inventoryCard={mobileCard === "inventoryCard"}
              serviceCard={mobileCard === "serviceCard"}
              modules={modules}
            />
          ) : (
            <EmptyPage
              pageTitle={""}
              description={emptyStateDescription}
              pageIcon={emptyPageIcon}
              href={emptyPageHref}
              linkName={emptyPageLinkName ? emptyPageLinkName : ""}
              handleSetCustomer={handleSetCustomer}
              onClose={emptyPageOnClose}
              emptyMessage={emptyMessage}
            />
          )}
        </>

        <button
          onClick={handleCreate}
          className="fixed bottom-16 right-8 bg-[#044E97] text-white text-[] rounded-full w-12 h-12 flex items-center justify-center shadow-lg"
          aria-label="Add"
        >
          <PlusIcon color="#ffffff" />
        </button>
      </div>

      {expenseUpload && <>{expenseUpload}</>}
    </>
  );
};

export default ReusableListPage;
