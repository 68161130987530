import React from "react";
import { EmptyIcon } from "../../../assets/icons";
import { Button } from "../../common";
import cx from "classnames";

interface ExpensePreviewSuccessProps {
  isLoading: boolean;
}

const ExpensePreviewSuccess: React.FC<ExpensePreviewSuccessProps> = ({
  isLoading,
}) => {
  return (
    <>
      <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh] flex items-end md:items-center justify-center">
        <div
          className={cx(
            "overflow-y-auto overflow-x-hidden fixed md:top-[10%] w-full md:w-[500px] lg:w-[538px] p-6 bg-white",
            "rounded-t-[16px] rounded-b-[16px] shadow-md" // Added rounded corners for both top and bottom
          )}
        >
          <div className="text-[25px] font-bold text-center ">
            <h1>Your upload is being processed</h1>
            <div className="text-[16px] mt-[2em] font-normal text-center">
              <h3>
                A preview of your uploaded receipt will pop-
                <br />
                up after it has been processed. You will be <br /> able to edit
                and save
              </h3>
            </div>
          </div>
          <div className="mt-[2em] flex justify-center">
            <EmptyIcon />
          </div>
          <div>
            <Button
              variant="secondary"
              text={"Auto Save"}
              isLoading={isLoading}
              disabled={isLoading}
              size="medium"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExpensePreviewSuccess;
