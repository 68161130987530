import { TransactionType } from "../../@types";
import { getPresignedURL } from "./getPresignedURL";
import { uploadToPresignedURL } from "./uploadToPresignedURL";

export const handleRecordPayment = async (
  file: File,
  entity: any,
  paymentType: TransactionType
) => {
  try {
    const data = await getPresignedURL(file, entity, paymentType);
    const { url, fields } = data;
    await uploadToPresignedURL(url, fields, file);
    return { success: true };
  } catch (error: any) {
    throw new Error(
      "An error occurred while uploading image: " + error.message
    );
  }
};
