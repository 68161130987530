import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ISetReminder } from "../../../../../@types";
import { CloseIcon } from "../../../../../assets/icons";
import { useUserStore } from "../../../../../state";
import { DisablePrompt } from "./DisableReminder";
import ReminderViewOptions from "./ViewOptions";
import { useCompanyStore } from "../../../../../state/useCompanyStore";

interface AutomateReminderModalProps {
  setShowSendModal: (showReminder: boolean) => void;
  onSaveReminder: (data: ISetReminder) => void;
  reminderType: "before" | "due" | "after";
  days: number;
  invoiceId?: string;
}

const ReminderViewModal = ({
  setShowSendModal,
  onSaveReminder,
  reminderType,
  days,
  invoiceId,
}: AutomateReminderModalProps) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const user = useUserStore((state) => state.user);
  const { hasReminder, reminder, setReminder } = useCompanyStore((state) => ({
    hasReminder: state.hasReminder,
    reminder: state.reminder,
    setReminder: state.setReminder,
  }));

  const [isEnabled, setIsEnabled] = useState(true);
  const [daysInput, setDaysInput] = useState<string>(days.toString());
  const [reminderData, setReminderData] = useState<ISetReminder[]>([
    { days: 0, remindBy: "before", remindMe: isEnabled, isActive: false },
    { days: 0, remindBy: "due", remindMe: isEnabled, isActive: false },
    { days: 0, remindBy: "after", remindMe: isEnabled, isActive: false },
  ]);

  useEffect(() => {
    setReminderData((prevData) =>
      prevData.map((reminder) => ({
        ...reminder,
        remindMe: isEnabled,
        days:
          reminder.remindBy === reminderType
            ? Number(daysInput)
            : reminder.days,
      }))
    );
  }, [daysInput, isEnabled, reminderType]);

  const handleReminderToggle = () => {
    if (isEnabled) {
      setShowPrompt(true);
    } else {
      setIsEnabled(true);
      setReminder(reminder);
      toast.success("Reminder enabled successfully!");
    }
  };

  const handleReminderChange = (updatedReminderData: {
    activeStatus: { before: boolean; due: boolean; after: boolean };
    days: { before: number; due: number; after: number };
  }) => {
    const adaptedReminderData: ISetReminder[] = Object.keys(
      updatedReminderData.days
    ).map((key) => ({
      days: updatedReminderData.days[key as "before" | "due" | "after"],
      remindBy: key as "before" | "due" | "after",
      remindMe: isEnabled,
      isActive:
        updatedReminderData.activeStatus[key as "before" | "due" | "after"],
    }));

    setReminderData(adaptedReminderData);
  };

  const handleSave = () => {
    if (Array.isArray(reminderData)) {
      reminderData.forEach((data) => onSaveReminder(data));
    } else {
      onSaveReminder(reminderData);
    }
  };

  const handleDisableReminder = () => {
    setReminder(null);
    setIsEnabled(false);
    setShowPrompt(false);
    setShowSendModal(false);
    toast.success("Reminder disabled successfully!");
  };

  return (
    <>
      {showPrompt ? (
        <DisablePrompt
          onDisable={handleDisableReminder}
          onClose={() => setShowPrompt(false)}
          showPrompt={showPrompt}
        />
      ) : (
        <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
          <div className="flex items-end justify-center md:items-center h-full w-full z-50">
            <div className="overflow-y-auto overflow-x-hidden fixed md:top-[20%] w-full md:w-[500px] lg:w-[500px] p-6 z-50 bg-white rounded-[8px]">
              <div className="flex justify-between items-center cursor-pointer mb-2">
                <h1 className="text-[16px] md:text-[24px] lg:text-[24px] text-[#292D32] leading-7 font-normal">
                  Automate Reminder
                </h1>
                <div onClick={() => setShowSendModal(false)}>
                  <CloseIcon />
                </div>
              </div>
              <p className="text-gray-700 mb-4 text-[12px] lg:text-[16px]">
                Send pre-set reminders to clients{" "}
                {reminderType === "before"
                  ? "before"
                  : reminderType === "after"
                  ? "after"
                  : "on"}{" "}
                the due date.
              </p>
              <div>
                <div className="flex items-center justify-between border border-gray-300 py-2 px-[1em] md:max-w-[580px] rounded-md">
                  <span className="text-sm text-gray-700">
                    Disable Automated Reminders
                  </span>
                  <div
                    className={`w-12 h-6 p-1 rounded-[69.33px] border-[0.53px] border-[#044E97] flex items-center transition duration-200 ease-inr ${
                      isEnabled
                        ? "bg-[#044E97] justify-end"
                        : "bg-[#ffffff] justify-start"
                    }`}
                    onClick={handleReminderToggle}
                  >
                    <div
                      className={`${
                        isEnabled ? "bg-[#ffffff]" : "bg-[#D1D5DB]"
                      } cursor-pointer w-4 h-4 rounded-full flex justify-center items-center`}
                    ></div>
                  </div>
                </div>
                <div className="bg-[#F9FAFB] p-5 rounded-[4px] md:max-w-[540px] border border-[#044E9733] border-opacity-20 mt-[0.5em] lg:ml-[2.2em]">
                  <p className="text-[#0A0A0A] mb-8 text-[12px]">
                    Automatically send timely reminders to clients before or
                    after invoice due dates to improve payment collection and
                    reduce manual follow-ups.
                  </p>
                  <div className="mt-[-2em]">
                    <ReminderViewOptions
                      onReminderChange={handleReminderChange}
                      invoiceId={invoiceId}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ReminderViewModal;
