import { create } from "zustand";
import { inventoryService } from "../services";
import { debounce } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Inventory, Variation } from "../@types/inventory";
import { IInventory, IPaginationMeta, IVariation } from "../@types";
import { Filter } from "../@types/invoice";

interface FetchOptions {
  startDate?: string;
  endDate?: string;
  actionType?: "initial" | "filter";
}

interface InventoryStore {
  inventories: IInventory[];
  newInventories: IInventory[];
  archivedInventory: IInventory[];
  variations: IVariation[];
  variationItems: [];
  isLoading: boolean;
  isLoadingInitial: boolean;
  isLoadingFilter: boolean;
  isLoadingMore: boolean;
  error: string | null;
  pagination: IPaginationMeta;
  createInventory: (newInventoryData: any) => Promise<void>;
  addInventory: (inventory: Omit<IInventory, "id" | "variations">) => void;
  updateInventory: (id: string, updatedFields: Partial<IInventory>) => void;
  removeInventory: (id: string) => void;
  addVariation: (inventoryId: string, variation: Omit<Variation, "id">) => void;
  createVariation: (data: { variantName: string; types: string[] }) => void;
  updateVariation: (
    inventoryId: string,
    variationId: string,
    updatedFields: Partial<Variation>,
  ) => void;
  removeVariation: (inventoryId: string, variationId: string) => void;
  fetchInventory: () => Promise<void>;
  fetchVariations: () => Promise<void>;
  fetchVariation: () => Promise<void>;
  fetchData: (options?: FetchOptions) => Promise<void> | undefined;
  fetchMoreData: () => Promise<void>;
  setSearchText: (text: string) => void;
  handleSort: (text: string) => void;
  setFilter: (filter: Filter) => void;
  searchText: string;
  range: string;
  filter: Filter;
  inventory: IInventory[];
  fetchInventoryById: (id: string) => Promise<void>;
  fetchArchivedInventory: () => Promise<void>;
  fetchMoreArchivedData: () => Promise<void>;
}

export const useInventoryStore = create<InventoryStore>((set, get) => ({
  inventories: [],
  archivedInventory: [],
  newInventories: [],
  variations: [],
  variationItems: [],
  inventory: [],
  isLoading: false,
  isLoadingInitial: true,
  isLoadingFilter: false,
  isLoadingMore: false,
  error: null,
  searchText: "",
  range: "",
  filter: {
    stockStatus: "",
    variantStatus: "",
  },
  pagination: {
    cursor: null,
    backCursor: null,
    hasMoreData: false,
    limit: undefined,
    count: undefined,
  },
  fetchData: async (options?: FetchOptions) => {
    const { startDate, endDate, actionType = "initial" } = options || {};
    const { searchText, newInventories, filter, range } = get();

    if (actionType === "initial") {
      set({ isLoadingInitial: true });
    } else if (actionType === "filter") {
      set({ isLoadingFilter: true });
    }

    try {
      let query = `/inventories`;
      console.log(endDate);
      if (startDate && endDate) {
        query += `?startDate=${startDate}&endDate=${endDate}`;
      }
      if (
        range.length > 0 ||
        searchText.length > 0 ||
        ("stockStatus" in filter && filter.stockStatus.length > 0) ||
        ("variantStatus" in filter && filter.variantStatus !== null)
      ) {
        if (!query.includes("?")) {
          query += `?`;
        } else {
          query += `&`; // Append '&' instead of '?' to avoid duplicating it
        }
        console.log("first");
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range}&`;
        if ("stockStatus" in filter && filter.stockStatus.length > 0)
          query += `stockStatus=${filter.stockStatus.toLowerCase()}&`;
        if ("variantStatus" in filter && filter.variantStatus?.length > 0)
          query += `variantStatus=${filter.variantStatus}&`;
      }

      const res = await inventoryService.get(query);
      let combinedInventories = res.data.data;
      if (searchText && searchText.length > 0) {
        const searchRegex = new RegExp(searchText, "i");
        const filteredNewInventories = newInventories.filter(
          (inventory) => inventory.name && searchRegex.test(inventory.name),
        );
        combinedInventories = [
          ...filteredNewInventories,
          ...combinedInventories,
        ];
      }

      set((prevState: InventoryStore) => {
        return {
          ...prevState,
          inventory: res.data.data,
          pagination: res.data.meta,
          isLoadingInitial: false,
          isLoadingFilter: false,
        };
      });
    } catch (e: any) {
      set((prevState: InventoryStore) => {
        return {
          ...prevState,
          isLoadingInitial: false,
          isLoadingFilter: false,
          error: e.message,
        };
      });
    }
  },
  fetchInventory: async () => {
    set((prevState: InventoryStore) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const res = await inventoryService.get(`/inventories`);
      set((prevState: InventoryStore) => ({
        ...prevState,
        inventory: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
    }
  },

  createInventory: async (newInventoryData: any): Promise<void> => {
    set({ isLoading: true });
    try {
      const res = await inventoryService.post("/inventories", newInventoryData);
      const newInventory = res.data.data;
      set((state) => ({
        newInventories: [newInventory, ...state.newInventories],
        isLoading: false,
      }));
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
      throw e; // Ensure the error is propagated
    }
  },
  fetchVariations: async () => {
    set((prevState: InventoryStore) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const res = await inventoryService.get("/inventories/variant");
      const variations = res.data;
      set((prevState: InventoryStore) => ({
        ...prevState,
        variations: res.data,
        variationItems: res.data,
        pagination: res.data.meta,
        isLoading: false,
      }));
      return variations;
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
    }
  },
  fetchVariation: async (id?: string) => {
    set((prevState: InventoryStore) => ({
      ...prevState,
      isLoading: true,
    }));

    try {
      const endpoint = id
        ? `/inventories/variant/${id}` // Fetch the specific variant
        : `/inventories/variant`; // Fetch all variants
      const res = await inventoryService.get(endpoint);

      if (!id) {
        // For all variants
        set((prevState: InventoryStore) => ({
          ...prevState,
          variations: res.data,
          variationItems: res.data,
          pagination: res.data.meta,
          isLoading: false,
        }));
      }

      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
      }));
      return id ? res.data : res.data; // Return the specific or all variants
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
      throw e;
    }
  },

  fetchMoreData: async () => {
    const { filter } = get();

    if (!get().pagination.hasMoreData || get().isLoadingMore) return;
    set({ isLoadingMore: true });
    const cursor = get().pagination.cursor;
    try {
      let query = `/inventories?cursor=${cursor}`;

      if (
        ("stockStatus" in filter && filter.stockStatus.length > 0) ||
        ("variantStatus" in filter && filter.variantStatus !== null)
      ) {
        if ("stockStatus" in filter && filter.stockStatus.length > 0)
          query += `&stockStatus=${filter.stockStatus.toLowerCase()}`;
        if ("variantStatus" in filter && filter.variantStatus?.length > 0)
          query += `&variantStatus=${filter.variantStatus}`;
      }

      const res = await inventoryService.get(query);
      set((state) => ({
        inventory: [...state.inventory, ...res.data.data],
        pagination: res.data.meta,
        isLoadingMore: false,
      }));
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoadingMore: false,
        error: e.message,
      }));
    }
  },
  setSearchText: (text: string) => {
    set({ searchText: text });
  },
  handleSort: (text: string) => {
    set({ range: text });
  },
  setFilter: (filter: Filter) => {
    set({ filter: filter as Filter });
  },
  addInventory: (inventory: Omit<IInventory, "id" | "variations">) => {
    const newInventory: IInventory = {
      ...inventory,
      id: uuidv4(),
      variations: [],
    };
    set((state: InventoryStore) => ({
      ...state,
      inventories: [...state.inventories, newInventory],
    }));
  },

  updateInventory: (id: string, updatedFields: Partial<IInventory>) => {
    set((state: InventoryStore) => ({
      ...state,
      inventories: state.inventories.map((inventory) =>
        inventory.id === id ? { ...inventory, ...updatedFields } : inventory,
      ),
    }));
  },

  removeInventory: (id) => {
    set((state) => ({
      inventories: state.inventories.filter((inventory) => inventory.id !== id),
    }));
  },

  addVariation: (inventoryId, variation) => {
    const newVariation = { ...variation, id: uuidv4() };
    set((state) => ({
      inventories: state.inventories.map((inventory) =>
        inventory.id === inventoryId
          ? {
              ...inventory,
              variations: [...inventory.variations, newVariation],
            }
          : inventory,
      ),
    }));
  },
  updateVariation: (inventoryId, variationId, updatedFields) => {
    set((state) => {
      const newState = { ...state };

      const inventory = newState.inventories.find(
        (inv) => inv.id === inventoryId,
      );

      if (inventory) {
        inventory.variations = inventory.variations.map((variation) =>
          variation.id === variationId
            ? { ...variation, ...updatedFields }
            : variation,
        );
      }

      return newState;
    });
  },
  createVariation: async (data: { variantName: string; types: string[] }) => {
    set({ isLoading: true });
    try {
      const res = await inventoryService.post("/inventories/variant", data);
      return res.data;
    } catch (e: any) {
      set({ isLoading: false, error: e.message });
    }
  },
  removeVariation: (inventoryId, variationId) => {
    set((state) => ({
      inventories: state.inventories.map((inventory) => {
        if (inventory.id === inventoryId) {
          return {
            ...inventory,
            variations: inventory.variations.filter(
              (variation: { id?: string }) => variation.id !== variationId,
            ),
          };
        }
        return inventory;
      }),
    }));
  },

  fetchInventoryById: async (id: string) => {
    set((prevState: InventoryStore) => ({
      ...prevState,
      isLoading: true,
    }));
    try {
      const res = await inventoryService.get(`/inventories/${id}`);
      set((prevState: InventoryStore) => ({
        ...prevState,
        inventory: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
    }
  },

  fetchMoreArchivedData: async () => {
    if (!get().pagination.hasMoreData || get().isLoading) return;
    set({ isLoading: true });
    const cursor = get().pagination.cursor;
    try {
      const res = await inventoryService.get(
        `/inventories?isArchived=true?cursor=${cursor}`,
      );
      set((state) => ({
        // ...prevState,
        archivedInventory: [...state.archivedInventory, ...res.data.data],
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (e: any) {
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: e.message,
      }));
    }
  },

  fetchArchivedInventory: async () => {
    set({ isLoading: true });
    const { range, searchText } = get();
    try {
      let query = `/inventories?isArchived=true`;
      if (range.length > 0 || searchText.length > 0) {
        if (searchText.length > 0) query += `search=${searchText}&`;
        if (range.length > 0) query += `range=${range}&`;
      }
      const res = await inventoryService.get(query);
      set((prevState: InventoryStore) => ({
        ...prevState,
        archivedInventory: res.data.data,
        pagination: res.data.meta,
        isLoading: false,
      }));
    } catch (error) {
      console.error("Error fetching archived inventory:", error);
      set((prevState: InventoryStore) => ({
        ...prevState,
        isLoading: false,
        error: "Failed to fetch archived inventory.",
      }));
    }
  },
}));
