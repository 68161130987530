import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { InventoryItem, IVariation } from "../../../../@types";
import {
  errorProps,
  VariantType,
  VariationItem,
} from "../../../../@types/inventory";
import DeleteIcon from "../../../../assets/icons/DeleteIcon";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import { useInventoryStore } from "../../../../state/useInventoryStore";
import {
  clearFieldError,
  formatMoney,
  toSentenceCase,
} from "../../../../utils";
import { commasToNumber } from "../../../../utils/convertToFloat";
import { Button } from "../../../common";
import { InputComponent } from "../../../core/Forms/InputComponent";
import SelectVariation from "./SelectVariation";

interface VariantResponse {
  id: string;
  variantName: string;
  types: string[];
}

interface InventoryTableProps {
  inventory: InventoryItem;
  setInventories: (inventories: InventoryItem) => void;
  variations: VariationItem[];
  setVariations: (Variations: VariationItem[]) => void;
  error: errorProps;
  setError: (error: errorProps) => void;
  variationList: any;
  toolTipText?: string;
  toolTipText2?: string;
  toolTipText3?: string;
  toolTipText4?: string;
}

function InventoryCreateTable({
  inventory,
  setInventories,
  setVariations,
  error,
  setError,
  variationList,
}: InventoryTableProps) {
  const { createVariation, variations } = useInventoryStore();
  const [priceInput, setPriceInput] = useState(true);
  const [variants, setVariants] = useState<VariantType[]>([]);

  const { t } = useTranslation("inventories");

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    if (name === "name" || name === "description") {
      const sentenceValue = toSentenceCase(value);
      const updatedInventory = { ...inventory, [name]: sentenceValue };
      setInventories(updatedInventory);
      name === "name" && clearFieldError(name, error, setError);
    }

    if (name === "rate" || name === "costPrice" || name === "quantity") {
      const resValue = commasToNumber(value);
      const updatedInventory = { ...inventory, [name]: resValue };
      setInventories(updatedInventory);
      clearFieldError(name, error, setError);
    }
  };

  const handleVariantInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    variantId: string,
    field: keyof VariantType["types"][0],
    typeIndex: number,
  ) => {
    const updatedValue = isNaN(Number(e.target.value))
      ? 0
      : Number(e.target.value);

    setVariants((prevVariants) =>
      prevVariants.map((variant) =>
        variant.id === variantId
          ? {
              ...variant,
              types: variant.types.map((type, idx) =>
                idx === typeIndex ? { ...type, [field]: updatedValue } : type,
              ),
            }
          : variant,
      ),
    );
  };

  console.log("variants", variants);
  useEffect(() => {
    //@ts-ignore
    setInventories({ ...inventory, variants });
  }, [variants]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [withoutVariation, setWithoutVariation] = useState(true);

  const openModal = () => {
    if (variations?.length > 0) {
      setWithoutVariation(!withoutVariation);
    } else {
      setIsModalOpen(true);
      if (!isDoneDisabled) {
        setPriceInput(false);
      }
    }
  };
  const openModalDirect = () => {
    setIsModalOpen(true);
    if (!isDoneDisabled) {
      setPriceInput(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (variants.length === 0) {
      setPriceInput(true);
    }
  };

  const [newVariantId, setNewVariantId] = useState<string | null>(null);

  const [formData, setFormData] = useState<{ variationName: string }>({
    variationName: "",
  });

  const [variationInputTypes, setVariationInputTypes] = useState([
    { value: "" },
  ]);

  const handleVariationClick = (variation: IVariation) => {
    // Populate the states with selected variation details
    setFormData({ variationName: variation.variantName });
    setVariationInputTypes(variation.types.map((type) => ({ value: type })));
    openModalDirect();
  };

  const handleVarOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDelete = (variant: VariantType, name: string) => {
    console.log(variant);
    setVariants((prevVariants) =>
      prevVariants.map((prev) =>
        variant.id === prev.id
          ? { ...prev, types: prev.types.filter((t) => t.name !== name) }
          : prev,
      ),
    );
  };
  const handleAddVariationType = () => {
    // Prevent adding a new variation if the last one is empty
    if (
      variationInputTypes[variationInputTypes.length - 1].value.trim() === ""
    ) {
      toast("Please fill in the previous option type before adding a new one.");
      return;
    }

    setVariationInputTypes((prev) => [...prev, { value: "" }]);
  };

  const handleDeleteVariationType = (index: number) => {
    setVariationInputTypes((prev) => prev.filter((_, i) => i !== index));
  };

  const isDoneDisabled =
    !formData.variationName.trim() ||
    !variationInputTypes.some((input) => input.value.trim());
  const [isLoading, setIsLoading] = useState(false);

  const handleVariantCreation = async () => {
    try {
      setIsLoading(true);
      const types = variationInputTypes
        .map((type) => type.value)
        .filter(Boolean);

      if (!formData.variationName || types.length === 0) {
        toast.error("Please fill in all required fields.");
        return;
      }

      const response = await createVariation({
        variantName: formData.variationName,
        types,
      });

      const newVariant: VariantType = {
        //@ts-ignore
        id: response.id,
        variantName: formData.variationName,
        //@ts-ignore
        types: types.map((name) => ({ name })),
      };

      setVariants([newVariant]);
      //@ts-ignore
      setVariations([newVariant]);
      closeModal();
      setWithoutVariation(!withoutVariation);
    } catch (err) {
      console.error(err);
      toast.error("Failed to create variant.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (variants.length > 0) {
      const updatedInventory = {
        ...inventory,
        variants,
      };
      setInventories(updatedInventory);
      setPriceInput(false);
    }
  }, [variants]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    const isName = variations.find((item: any) => item.variantName === value);
    if (isName) {
      toast.error("Variation Name already exists");
      return;
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="mt-4">
      {withoutVariation ? (
        <div>
          <div className="text-[16px] text-[#212121]">
            <div className="lg:flex lg:gap-6 mb-6">
              {/* Product Name */}
              <div className="w-full">
                <div className="flex gap-2 mb-1 md:mb-2">Product name</div>
                <InputComponent
                  className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                  type="text"
                  name="name"
                  placeholder="Inventory Name"
                  value={inventory.name}
                  handleChange={handleInputChange}
                  error={error.name}
                />
              </div>

              {/* Quantity */}
              {priceInput && (
                <div className="w-full">
                  <div className="flex gap-2 mb-1 md:mb-2">Quantity</div>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                    type="text"
                    name="quantity"
                    placeholder="Quantity"
                    value={inventory.quantity}
                    handleChange={handleInputChange}
                    error={error.quantity}
                  />
                </div>
              )}
            </div>

            {/* Cost Price & Selling Price */}
            {priceInput && (
              <div className="flex gap-6 mb-6">
                <div className="w-full">
                  <div className="flex gap-2 mb-1 md:mb-2">
                    {t("inventory.costPrice")}
                  </div>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                    type="text"
                    placeholder={`${formatMoney(0.0, "NGN")}`}
                    name="costPrice"
                    value={inventory.costPrice}
                    handleChange={handleInputChange}
                    error={error.costPrice}
                  />
                </div>

                <div className="w-full">
                  <div className="flex gap-2 mb-1 md:mb-2">
                    {t("inventory.sellingPrice")}
                  </div>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                    type="text"
                    name="rate"
                    placeholder={`${formatMoney(0.0, "NGN")}`}
                    value={inventory.rate}
                    handleChange={handleInputChange}
                    error={error.rate}
                  />
                </div>
              </div>
            )}

            {/* Description */}
            <div className="mb-6 md:mb-0">
              <label className="block mb-1 md:mb-2">
                {t("inventory.description")}
              </label>
              <textarea
                className="block w-full border-[0.5px] border-[#A4A4A4] h-[80px] md:h-[72px] rounded"
                id="grid-first-name"
                name="description"
                placeholder="Provide more details about the product.."
                value={inventory.description}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Product Options */}
          <div
            onClick={openModal}
            className="py-6 cursor-pointer border-t-2 border-b-2 border-gray-200 px-5 mt-[1em]"
          >
            <div className="font-medium text-[14px]">
              <p className="leading-5 text-[#000000]">Product options</p>
              <div className="flex items-center gap-2 mt-2">
                <input
                  className="w-[14px] h-[14px] border border-gray-600"
                  type="checkbox"
                  checked={isModalOpen || !isDoneDisabled}
                  onChange={openModal}
                />
                <p className="leading-5 text-gray-600">
                  This item has options like color, size
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SelectVariation
          handleVariationClick={handleVariationClick}
          openModalDirect={openModalDirect}
          variations={variations}
        />
      )}

      {Array.isArray(variants) && variants.length > 0 && withoutVariation && (
        <div className="mt-4">
          <table className="w-full border-collapse border border-gray-200">
            <thead>
              <tr>
                <th className="border border-gray-200 px-4 py-2">Variant</th>
                <th className="border border-gray-200 px-4 py-2">Cost Price</th>
                <th className="border border-gray-200 px-4 py-2">
                  Selling Price
                </th>
                <th className="border border-gray-200 px-4 py-2">Quantity</th>
                <th className="border border-gray-200 px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {variants
                .filter(
                  (variant) => variant.id === newVariantId || !newVariantId,
                )
                .map((variant) =>
                  Array.isArray(variant.types) && variant.types.length > 0 ? (
                    variant.types.map((type, index) => (
                      <tr key={`${variant.id}-${type.name}`}>
                        {/* Variant Type - Displaying the Type Name */}
                        <td className="border border-gray-200 px-4 py-2 font-semibold">
                          {type.name} {/* Access the 'name' property */}
                        </td>

                        {/* Cost Price Input */}
                        <td className="border border-gray-200 px-4 py-2">
                          <input
                            type="number"
                            placeholder="Cost Price"
                            required
                            value={type.costPrice || ""}
                            onChange={(e) =>
                              handleVariantInputChange(
                                e,
                                variant.id,
                                "costPrice",
                                index,
                              )
                            }
                            className={`border border-gray-200 px-2 py-1 rounded w-full ${type.costPrice || "border-red-500"}`}
                          />
                        </td>

                        {/* Selling Price Input */}
                        <td className={`border border-gray-200 px-4 py-2 `}>
                          <input
                            type="number"
                            placeholder="Selling Price"
                            required
                            value={type.price || ""}
                            onChange={(e) =>
                              handleVariantInputChange(
                                e,
                                variant.id,
                                "price",
                                index,
                              )
                            }
                            className={`border border-gray-200 px-2 py-1 rounded w-full ${type.price || "border-red-500"}`}
                          />
                        </td>

                        {/* Quantity Input */}
                        <td className="border border-gray-200 px-4 py-2">
                          <input
                            type="number"
                            placeholder="Quantity"
                            required
                            value={type.quantity || ""}
                            onChange={(e) =>
                              handleVariantInputChange(
                                e,
                                variant.id,
                                "quantity",
                                index,
                              )
                            }
                            className={`border border-gray-200 px-2 py-1 rounded w-full ${type.quantity || "border-red-500"}`}
                          />
                        </td>
                        <td className="border border-gray-200 px-4 py-2">
                          <button
                            onClick={() => handleDelete(variant, type.name)}
                            className="p-2 "
                          >
                            <DeleteIcon />
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr key={variant.id}>
                      <td colSpan={4} className="text-center">
                        No types available
                      </td>
                    </tr>
                  ),
                )}
            </tbody>
          </table>
        </div>
      )}

      {/* Modal for Variations */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 ">
          <div className="bg-white p-7 rounded-lg shadow-lg w-[400px] max-h-[85vh]">
            <div className="mb-1">
              <p className="text-[18px] font-semibold">Product Options</p>
              <div
                className={`w-full ${variations.length > 0 && "mt-6"} max-h-[51vh] p-2 overflow-hidden scrollbar overflow-y-scroll`}
              >
                {/* Variations */}
                <div className="flex flex-col justify-start">
                  <label className="text-left whitespace-nowrap mb-1">
                    Option name
                  </label>
                  <div className="flex gap-4">
                    <InputComponent
                      type="text"
                      className="w-[320px] rounded-md"
                      value={formData.variationName}
                      name="variationName"
                      placeholder="Variation Name"
                      handleChange={handleOnChange}
                    />
                  </div>
                </div>
                <div className="flex flex-col ml-[2em] mt-[1em]">
                  <p className="whitespace-nowrap mb-1">Option Type</p>
                  <div className=" flex flex-col gap-4 ml-[]">
                    {variationInputTypes.map((input, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <InputComponent
                          className="block w-[250px] border-[0.5px] border-[#A4A4A4] rounded py-3 px-4"
                          type="text"
                          value={input.value}
                          handleChange={(e) => {
                            const newTypes = [...variationInputTypes];
                            newTypes[index].value = e.target.value;
                            setVariationInputTypes(newTypes);
                          }}
                        />
                        {variationInputTypes.length > 1 && (
                          <button
                            className="p-2 text-red-600"
                            onClick={() => handleDeleteVariationType(index)}
                          >
                            <DeleteIcon />
                          </button>
                        )}
                      </div>
                    ))}
                    <button
                      type="button"
                      className="flex justify-end "
                      onClick={handleAddVariationType}
                    >
                      <PlusIcon />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-[2em] gap-4">
              <button
                className="text-gray-700  text-lg border border-gray-200 py-2 px-[3em]  text-right"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <Button
                variant="secondary"
                disabled={isLoading}
                text={"Done"}
                size="small"
                font="semibold"
                isLoading={isLoading}
                //@ts-ignore
                onClick={handleVariantCreation}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export { InventoryCreateTable };
