import React, { useState } from "react";
import { ICreateSalesData, ItemInput } from "../@types";

interface InvoiceItemErrors {
  [key: string]: string | undefined;
}

export type InvoiceErrors = {
  [P in keyof ICreateSalesData]?: string;
} & {
  items?: InvoiceItemErrors;
};

export const validateInvoiceBasicInfo = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (!formData[field]) {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  validateField("customerId", "Please enter customers name");
  validateField("issueDate", "Issue Date is required");
  validateField("invoiceDueAt", "Select a due date");

  const issueDate = formData.issueDate ? new Date(formData.issueDate) : null;
  const dueDate = formData.invoiceDueAt
    ? new Date(formData.invoiceDueAt)
    : null;

  if (issueDate && dueDate) {
    if (dueDate < issueDate) {
      errors.invoiceDueAt = "Due Date cannot be less than the Issue Date";
      isError = true;
    } else {
      delete errors.invoiceDueAt;
    }
  }

  setValidationResults((prevErrors) => ({ ...prevErrors, ...errors }));
  return !isError;
};

export const validateQuoteBasicInfo = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (!formData[field]) {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  validateField("customerId", "Please enter customers name");
  validateField("issueDate", "Issue Date is required");
  validateField("expiryDate", "Select an expiry date");

  const issueDate = formData.issueDate ? new Date(formData.issueDate) : null;
  const expiryDate = formData.expiryDate ? new Date(formData.expiryDate) : null;

  if (issueDate && expiryDate) {
    if (expiryDate < issueDate) {
      errors.expiryDate = "Expiry Date cannot be less than the Issue Date";
      isError = true;
    } else {
      delete errors.expiryDate;
    }
  }

  setValidationResults((prevErrors) => {
    return { ...prevErrors, ...errors };
  });
  return !isError;
};

export const validateInventoryItem = (
  item: ItemInput,
  index: number,
  errors: InvoiceErrors,
): boolean => {
  if (!item.variations) {
    ["id", "rate", "quantity"].forEach((field) => {
      if (!item[field]) {
        errors.inventories += ` Inventory ${index + 1} is missing ${field}.`;
        return false;
      }
    });
  }

  if (item.quantity !== undefined && item.quantity === 0 && !item.variations) {
    errors.inventories += ` Inventory ${
      index + 1
    } quantity should be greater than zero.`;
    return false;
  }

  // Add variation validation
  if (item.variations && item.variations.length > 0) {
    item.variations.forEach((variation, variationIndex) => {
      if (!variation.type) {
        errors.inventories += ` Inventory ${index + 1} variation ${
          variationIndex + 1
        } is missing type or value.`;
        return false;
      }
    });
  }

  return true;
};

export const validateServiceItem = (
  service: ItemInput,
  index: number,
  errors: InvoiceErrors,
): boolean => {
  ["id", "duration", "price"].forEach((field) => {
    if (!service[field]) {
      errors.services += ` Service ${index + 1} is missing ${field}.`;
      return false;
    }
  });

  return true;
};
export const validateInvoiceItemDetails = (
  formData: any,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
  isRetail?: boolean,
): boolean => {
  const errors: InvoiceErrors = { inventories: "", services: "" };
  const selectedIds: Set<string> = new Set();

  if (isRetail) {
    if (
      !formData.inventories ||
      formData.inventories.length === 0 ||
      !formData.inventories[0].id
    ) {
      errors.inventories = "Please add at least one item to your invoice";
    } else {
      formData.inventories.forEach((item: ItemInput, index: number) => {
        if (!item.id) {
          errors.inventories = "Please fill up the table fields";
        } else {
          if (!item.variations && selectedIds.has(item.id)) {
            errors.inventories = "Duplicate items are not allowed";
          } else {
            selectedIds.add(item.id);
            if (item.stockStatus === "out-of-stock") {
              errors.inventories = "One or more inventories are out of stock";
            }
            const inventoryValidationResult = validateInventoryItem(
              item,
              index,
              errors,
            );

            if (!inventoryValidationResult) {
              errors.inventories = "Inventory is missing quantity";
            }
          }
        }
      });
    }
  } else {
    // Validate services
    if (
      !formData.services ||
      formData.services.length === 0 ||
      !formData.services[0].id
    ) {
      errors.services = "Please add at least one service to your invoice";
    } else {
      formData.services.forEach((service: ItemInput, index: number) => {
        if (!service.id) {
          errors.services = "Please fill up the table fields";
        } else {
          if (selectedIds.has(service.id)) {
            errors.services = "Duplicate items are not allowed";
          } else {
            selectedIds.add(service.id);
            if (!validateServiceItem(service, index, errors)) {
              errors.services = "One or more services have validation errors";
            }
          }
        }
      });
    }
  }

  // Clear duplicate error if no duplicates are found
  if (
    errors.inventories === "Duplicate items are not allowed" &&
    !formData.inventories.some((item: { id: string }) =>
      selectedIds.has(item.id),
    )
  ) {
    errors.inventories = "";
  }
  if (
    errors.services === "Duplicate items are not allowed" &&
    !formData.services.some((service: { id: string }) =>
      selectedIds.has(service.id),
    )
  ) {
    errors.services = "";
  }

  setValidationResults((prevErrors) => ({ ...prevErrors, ...errors }));
  return Object.values(errors).every((error) => !error);
};

export const validateFreeFormItemDetails = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
  isRetail?: boolean,
): boolean => {
  const errors: InvoiceErrors = { lineItems: "", services: "" };
  let isValid = true;

  if (isRetail) {
    // Validate lineItems for retail invoices
    if (formData.lineItems && formData.lineItems.length > 0) {
      const lineItemsErrors: InvoiceItemErrors = {};
      formData.lineItems.forEach(
        (
          item: { name: string; rate: number; quantity: number },
          index: number,
        ) => {
          let errorMessage = "";
          if (!item.name) errorMessage += "Name is required. ";
          if (!item.rate) errorMessage += "Rate is required. ";
          if (!item.quantity) errorMessage += "Quantity is required. ";

          if (errorMessage) {
            lineItemsErrors[`lineItem${index}`] = errorMessage.trim();
            isValid = false;
          }
        },
      );

      if (Object.keys(lineItemsErrors).length > 0) {
        errors.lineItems = "At least one valid Line Item is required.";
        errors.items = lineItemsErrors;
      }
    } else {
      errors.lineItems = "At least one Line Item is required.";
      isValid = false;
    }
  } else {
    // Validate services for non-retail invoices
    if (formData.services && formData.services.length > 0) {
      const servicesErrors: InvoiceItemErrors = {};
      formData.services.forEach(
        (
          service: { name: string; duration: string; price: string },
          index: number,
        ) => {
          let errorMessage = "";
          if (!service.name) errorMessage += "Service name is required. ";
          if (!service.duration) errorMessage += "Duration is required. ";
          if (!service.price) errorMessage += "Price is required. ";

          if (errorMessage) {
            servicesErrors[`service${index}`] = errorMessage.trim();
            isValid = false;
          }
        },
      );

      if (Object.keys(servicesErrors).length > 0) {
        errors.services = "At least one valid Service item is required.";
        errors.items = servicesErrors;
      }
    } else {
      errors.services = "At least one Service is required.";
      isValid = false;
    }
  }

  setValidationResults(errors);
  return isValid;
};

export const validateInvoiceSummary = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (formData[field] === undefined || formData[field] === "") {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  if (formData.isPaymentCompleted && !formData.paymentMethod) {
    validateField("paymentMethod", "Payment Method is required ");
  }

  setValidationResults((prevErrors) => ({ ...prevErrors, ...errors }));
  return !isError;
};
export const validateQuoteSummary = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (!formData[field]) {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  setValidationResults(errors as InvoiceErrors);
  return !isError;
};
