import React, { useState, useEffect } from "react";
import Location from "../../../constants/Location.json";
import { DropdownComponent } from "../../core/Forms";
import useJsonData from "../../../hooks/useJsonData";

interface LocationData {
  name: string;
  cities: string[];
}

interface LocationSelectorProps {
  onLocationChange: (state: string, cities: string[]) => void;
  onError: (error: string) => void;
  selectedState: string;
  selectedCity: string;
  display?: string;
  stateError?: string;
  cityError?: string;
  isSubmitted?: boolean;
  required?: boolean;
}

interface DropdownOption {
  value: string;
  label: string;
  icon?: React.ReactNode;
}

const LocationSelector: React.FC<LocationSelectorProps> = ({
  onLocationChange,
  onError,
  selectedState,
  selectedCity,
  display,
  stateError,
  cityError,
  isSubmitted,
  required = true,
}) => {
  const [, setSelectedState] = useState<string>("");
  const [, setSelectedCity] = useState<string>("");
  const [isCityDisabled, setIsCityDisabled] = useState<boolean>(true);

  useEffect(() => {
    setIsCityDisabled(!selectedState);
  }, [selectedState]);

  useEffect(() => {
    if (selectedState && !selectedCity) {
      onError("Please select a city.");
    } else {
      onError("");
    }
  }, [selectedState, selectedCity, onError]);

  const handleStateChange = (name: string, value: string) => {
    setSelectedState(value);
    if (value) {
      const selectedStateData = Location.find(
        (state: LocationData) => state.name === value,
      );
      const citiesForState = selectedStateData?.cities || [];
      setSelectedCity("");
      onLocationChange(value, citiesForState);
    } else {
      setSelectedCity("");
      onLocationChange("", []);
    }
  };

  const handleCityChange = (name: string, value: string) => {
    setSelectedCity(value);
    if (selectedState) {
      onLocationChange(selectedState, [value]);
    }
  };

  const stateOptions: DropdownOption[] = Location.map(
    (state: LocationData) => ({
      value: state.name,
      label: state.name,
    }),
  );

  const selectedStateData = Location.find(
    (state: LocationData) => state.name === selectedState,
  );
  const cityOptions: DropdownOption[] =
    selectedStateData?.cities.map((city: string) => ({
      value: city,
      label: city,
    })) || [];

  return (
    <div className={`${display} relative w-full`}>
      <div className="w-full">
        <div className="flex gap-1 items-center text-[14px] mb-1">
          <p className=" font-medium text-[14px] leading-5 text-[#000000]">
            State
          </p>
          {display && required && <p className="leading-5 text-[#F04438]">*</p>}
        </div>
        <DropdownComponent
          options={stateOptions}
          name="state"
          value={selectedState}
          placeholder="Select a state"
          onChange={handleStateChange}
          iconClassName="mt-[6px] md:mt-0"
          selectedValue={selectedState}
          error={stateError}
          isSubmitted={isSubmitted}
          dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full placeholder:text-[16px] text-[16px]"
        />
      </div>

      <div className={`${display ? "" : "mt-[1em]"} w-full`}>
        <div className="flex gap-1 items-center text-[14px] mb-1">
          <p className=" font-medium text-[14px] leading-5 text-[#000000]">
            City
          </p>
          {display && required && <p className="leading-5 text-[#F04438]">*</p>}
        </div>
        <DropdownComponent
          options={cityOptions}
          name="city"
          value={selectedCity}
          placeholder="Select a city"
          onChange={handleCityChange}
          disabled={isCityDisabled}
          iconClassName="mt-[6px] md:mt-0"
          error={cityError}
          dropdownClassName=" z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full placeholder:text-[16px] text-[16px]"
          selectedValue={selectedCity}
          isSubmitted={isSubmitted}
        />
      </div>
    </div>
  );
};

export default LocationSelector;
