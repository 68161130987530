import React, { useEffect, useState } from "react";
import { ICreateSalesData, IService } from "../../../@types";
import SelectedMarkIcon from "../../../assets/icons/SelectedIcon";
import { useServiceSearch } from "../../../hooks/service/useServiceSearch";
import { useServiceStore } from "../../../state/useServiceStore";
import { Button } from "../../common";
import { NewSearchInput } from "../../common/SearchInput/NewSearch";
import AddModal from "../sections/modal/AddModal";

interface SelectItemProps {
  setIsOpen: (isOpen: boolean) => void;
  setOpenAddService: (isOpen: boolean) => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  handleSetFormData: (selectedService: IService) => void;
  setCurrentView: React.Dispatch<
    React.SetStateAction<"SelectService" | "addItem" | null>
  >;
}

const SelectService = ({
  setIsOpen,
  setOpenAddService,
  formData,
  setFormData,
  handleSetFormData,
  setCurrentView,
}: SelectItemProps) => {
  const [selectedService, setSelectedService] = useState<IService | null>(null);
  const [displayedServices, setDisplayedServices] = useState<IService[]>([]);
  const [showItems, setShowItems] = useState(false);
  const services = useServiceStore((state) => state.services);
  const fetchData = useServiceStore((state) => state.fetchData);

  const {
    data: searchResult,
    isSearching: isServiceSearching,
    handleSearch: handleServiceSearch,
  } = useServiceSearch();

  const handleOpenInventory = () => {
    setIsOpen(false);
    // setOpenAddService(true);
    setCurrentView("addItem");
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!setIsOpen) {
      setSelectedService(null);
      setDisplayedServices(services);
    }
  }, [setIsOpen]);

  useEffect(() => {
    if (searchResult && searchResult.length > 0) {
      setDisplayedServices(searchResult);
    }
  }, [searchResult]);

  const handleSearchChange = (searchText: string) => {
    // Call search handler
    handleServiceSearch(searchText);

    // Filter based on search text
    const filteredServices = services.filter((service) =>
      service.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setDisplayedServices(filteredServices);
  };

  const handleSelectService = (selectedService: IService) => {
    const updatedServices = [...(formData.services || []), selectedService];
    setFormData((prev) => ({ ...prev, services: updatedServices }));
    setSelectedService(selectedService);
    setShowItems(true);
    handleSetFormData(selectedService);
  };

  const renderServiceItem = (service: IService) => {
    const { name, duration, price } = service;
    const isSelected = selectedService?.id === service.id;

    let priceDisplay = "N/A";

    if (price && Array.isArray(price)) {
      const priceDetails = price.find((p) => p.currency === "NGN");
      if (priceDetails && priceDetails.amount) {
        priceDisplay =
          typeof priceDetails.amount === "number"
            ? `N${priceDetails.amount.toFixed(2)}` // Ensuring that we format numbers correctly
            : `N${priceDetails.amount}`;
      }
      //@ts-ignore
    } else if (price && price.amount) {
      //@ts-ignore
      priceDisplay =
        //@ts-ignore
        typeof price.amount === "number"
          ? //@ts-ignore
            `N${price.amount.toFixed(2)}`
          : //@ts-ignore
            `N${price.amount}`;
    }

    console.log("Service:", service); // Log the service to inspect the data
    console.log("Price:", price); // Log the price object or array
    console.log("Price Display:", priceDisplay);

    return (
      <div
        key={service.id}
        onClick={() => {
          setSelectedService(service);
          setShowItems(true);
          handleSetFormData(service);
        }}
        className="p-2 border border-gray-200 rounded-lg flex justify-between items-center cursor-pointer hover:shadow-md transition"
      >
        {/* Left Section */}
        <div className="font-medium">
          <p className="text-[14px] leading-5 text-[#000000]">{name}</p>
          <p className="text-[12px] leading-4 text-gray-600 my-1">
            {duration ? `Duration: ${duration}` : "No duration available"}
          </p>
        </div>

        {/* Right Section */}
        <div className="flex items-center gap-3">
          <p className="font-semibold text-[16px] leading-6 text-[#000000]">
            {priceDisplay}
          </p>

          {isSelected ? (
            <SelectedMarkIcon />
          ) : (
            <div className="rounded-full w-4 h-4 border border-gray-600" />
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <AddModal>
        <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center mb-5 pb-2">
              <div onClick={() => setIsOpen(false)}>
                <svg
                  className="mt-[-2px] cursor-pointer"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1L1.5 8L8.5 15"
                    stroke="#33363F"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="w-full flex justify-center">
                <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                  Select Item
                </p>
              </div>
            </div>

            {/* Search Input */}
            <NewSearchInput
              placeholder="Search"
              data={services}
              isLoading={isServiceSearching}
              classNames="w-full border border-gray-300 rounded-lg focus:outline-none"
              searchKey="name"
              handleChangeText={handleSearchChange}
              displayKey={"name"}
              icon={<></>}
              value={selectedService ? selectedService.name : ""}
              selected={selectedService}
              onSelect={(item) => {
                if (item) {
                  // Check if item is not null
                  setSelectedService(item); // Update selected service
                  setShowItems(true); // Show selected item details
                  handleSetFormData(item); // Pass item to the form data handler
                }
              }}
            />

            {/* Conditionally Render Service List */}
            {showItems ? (
              <div className="mt-5 space-y-4">
                {renderServiceItem(selectedService!)}{" "}
                {/* Only show selected item */}
              </div>
            ) : (
              <div className="mt-5 space-y-4">
                {displayedServices.map(renderServiceItem)}{" "}
                {/* Show all filtered services */}
              </div>
            )}

            <div
              onClick={handleOpenInventory}
              className="p-2 mt-8 font-semibold text-[16px] leading-6 text-[#044E97]"
            >
              Add new Service
            </div>
          </div>
          <div
            style={{
              boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
            }}
            className="w-full py-5"
          >
            <Button
              variant="secondary"
              disabled={false}
              text={"Add service"}
              size="full"
              font="semibold"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
      </AddModal>
    </>
  );
};

export default SelectService;
