import React, { useEffect } from "react";
import { ICreateSalesData, ItemInput } from "../../../../@types";
import {
  EXPENSE_TABLE_HEADER,
  SERVICE_TABLE_HEADER,
} from "../../../../constants";
import { InvoiceErrors } from "../../../../modules/validateInvoiceForm";
import { useUserStore } from "../../../../state";
import {
  LineItemTable,
  TableColumn,
} from "../../../common/LineItemTable/InventoryLineItemTable";
import { ServiceLineItemTable } from "../../../common/LineItemTable/ServiceLineItemTable";
import { useCompanyStore } from "../../../../state/useCompanyStore";

interface ExpenseDetailTwoProps {
  formData: ICreateSalesData;
  updatedNewItem?: ItemInput;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  items: ItemInput[];
  addItem: () => void;
  setItems: any;
  removeItem: (index: any) => void;
  updateCell: (index: number, field: string, value: string | number) => void;
  updateRow: (index: number, item: ItemInput) => void;
  selectedCurrencyLabel: string;
  validationResults: InvoiceErrors;
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>;
}

const ExpenseLineTable: React.FC<ExpenseDetailTwoProps> = ({
  formData,
  setFormData,
  items,
  addItem,
  setItems,
  removeItem,
  updateCell,
  updateRow,
  selectedCurrencyLabel,
  setValidationResults,
  validationResults,
}) => {
  const columns: TableColumn[] = EXPENSE_TABLE_HEADER;
  const column: TableColumn[] = SERVICE_TABLE_HEADER;

  const [total, setTotal] = React.useState<number>(0);
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();

  const handleClearErrors = () => {};
  return (
    <div className="">
      <div className="flex w-full">
        <div className="mt-[-1em] w-full">
          {isRetail ? (
            <LineItemTable
              columns={columns}
              items={items}
              isSearchInput={false}
              addItem={addItem}
              removeItem={removeItem}
              updateCell={updateCell}
              updateRow={updateRow}
              isImportedToInvoice={true}
              isInvoice={false}
              handleClearErrors={handleClearErrors}
              currency={formData.currency ?? ""}
              //@ts-ignore
              exchangeRate={formData.exchangeRate}
            />
          ) : (
            <ServiceLineItemTable
              columns={column}
              items={formData.services}
              isSearchInput={false}
              addItem={addItem}
              removeItem={removeItem}
              updateCell={updateCell}
              updateRow={updateRow}
              isImportedToInvoice={true}
              selectedCurrencyLabel={selectedCurrencyLabel}
              handleClearErrors={handleClearErrors}
              isInvoice={false}
              currency={formData.currency ?? ""}
              //@ts-ignore
              exchangeRate={formData.exchangeRate}
            />
          )}
          {/* {validationResults.lineItems && (
            <div className="mt-[-5em] ml-4 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
              <span className="text-red-500">
                {validationResults.}
              </span>
            </div>
          )} */}
          {validationResults.inventories && (
            <div className=" ml-4 xl:text-[14px] lg:text-[12px] biglg:text-[12px] 2xl:text-[14px] md:text-[11px] text-[9px]">
              <span className="text-red-500">
                {validationResults.inventories}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExpenseLineTable;
