import * as React from "react";

interface PinCodeMobileProps {
  codeLength?: number;
  onCodeComplete: (value: string) => void;
}

const PinCodeMobile: React.FC<PinCodeMobileProps> = ({
  codeLength,
  onCodeComplete,
}) => {
  const [values, setValues] = React.useState<string[]>(
    Array(codeLength).fill("")
  );
  const inputRefs = React.useRef<HTMLInputElement[]>([]);

  const handleChange = (value: string, index: number) => {
    const newValues = [...values];
    newValues[index] = value;
    setValues(newValues);
    //@ts-ignore
    if (value.length > 0 && index < codeLength - 1) {
      setTimeout(() => inputRefs.current[index + 1]?.focus(), 10); // Delay to ensure focus works on mobile
    }

    if (newValues.join("").length === codeLength) {
      onCodeComplete(newValues.join(""));
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && values[index] === "" && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  React.useEffect(() => {
    // Set initial focus on the first input
    inputRefs.current[0]?.focus();
  }, []);

  return (
    <div className="flex space-x-2 items-center">
      {values.map((value, index) => (
        <input
          key={index}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          maxLength={1}
          value={value}
          ref={(el) => (inputRefs.current[index] = el!)}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          className=" lg:h-[61px] border-[#044E97] text-[16px] lg:text-[26px] w-12 h-12 md:w-[40px]  lg:w-[50px] rounded-[6px] text-center font-medium flex items-center justify-center"
        />
      ))}
    </div>
  );
};

export { PinCodeMobile };
