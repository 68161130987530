import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CloseIconBig } from "../../../../assets/icons";
import { TickIcon } from "../../../../assets/icons/TickIcon";
import { UntickedIcon } from "../../../../assets/icons/UntickedIcon";
import { updateRC } from "../../../../backend-services";
import { InputComponent } from "../../../core/Forms";

interface GeneratePaymentPromptProps {
  onGenerate: (event: any) => void;
  onClose?: () => void;
  showVerificationPrompt: boolean;
  setShowSideModal: (show: boolean) => void;
  onVerifySuccess: () => void;
}

const RCVerificationModal: React.FC<GeneratePaymentPromptProps> = ({
  onGenerate,
  onClose,
  showVerificationPrompt,
  setShowSideModal,
  onVerifySuccess,
}) => {
  const navigate = useNavigate();
  const [rcNumber, setRcNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [verificationMessage, setVerificationMessage] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(
    showVerificationPrompt
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRcNumber(e.target.value);
  };

  const handleVerifyRC = async () => {
    setIsLoading(true);
    try {
      setVerificationMessage("Verifying registration code...");
      setError("");
      const response = await updateRC(rcNumber);
      setVerificationMessage(`Registration code verified`);
      onVerifySuccess();
      setShowVerificationModal(true);
    } catch (error: any) {
      // setError('Verification failed. Please try again.');
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        if (error.response.data.message === "Invalid registration code") {
          setVerificationMessage("Invalid registration code.");
        } else {
          setVerificationMessage("Registration code verification failed");
        }
      } else {
        setVerificationMessage("Registration code verification failed");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = rcNumber.length !== 7;

  return (
    <>
      <div className="pl-[32px] pt-[32px] pr-[32px] pb-[22px] bg-[#ffffff] rounded-[16px] xl:max-h-[748px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[500px] w-[704px] xl:w-[506px] 2xl:w-[506px] biglg:w-[506px] lg:w-[506px] overflow-y-auto">
        <div className="flex justify-between">
          <h1 className="xl:text-[20px] biglg:text-[20px] 2xl:text-[20px] lg:text-[20px] md:text-[16px] text-[14px] font-semibold mt-[-1em]">
            Business Registration Code
          </h1>
          <button
            onClick={onClose}
            className="pt-1 pb-1 rounded-md focus:outline-none mt-[-1em] text-[black] shadow-sm bg-[#F4F7FF] pr-2"
          >
            <CloseIconBig />
          </button>
        </div>
        <p className="lg:text-[16px] mb-4 mt-[-1em] text-[10px] md:text-[12px]">
          Enter your business registration code below for{" "}
          <br className="hidden lg:block" /> verification.
        </p>
        {verificationMessage && (
          <p
            className={`flex mt-[1em] lg:text-[14px] text-[10px] md:text-[12px] py-2 px-2 rounded-md ${
              verificationMessage === "Verifying registration code..."
                ? "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
                : verificationMessage.includes("failed") ||
                  verificationMessage.includes("Invalid registration code")
                ? "text-[#D82020] border border-[#FF0000] bg-[#FFE2E2]"
                : "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
            }`}
          >
            {verificationMessage === "Verifying registration code..." ? (
              <span>{verificationMessage}</span>
            ) : (
              <>
                {verificationMessage.includes("verified") ? (
                  <span className="pr-2 mt-[0.1em]">
                    <TickIcon />
                  </span>
                ) : (
                  <span className="pr-2 mt-[0.1em]">
                    <UntickedIcon />
                  </span>
                )}
                {verificationMessage}
              </>
            )}
          </p>
        )}

        <div role="form" onSubmit={(e) => e.preventDefault()}>
          <label className="text-[13px]">Registration Code</label>
          <InputComponent
            placeholder="Registration Code"
            type="text"
            name="rcnumber"
            value={rcNumber}
            handleChange={handleInputChange}
            className="w-full rounded-md"
          />
        </div>
        <button
          className={`w-full rounded-md h-[46px] mt-[1em] ${
            isButtonDisabled ? "bg-gray-400" : "bg-[#FF6702] text-[white]"
          }`}
          onClick={handleVerifyRC}
          disabled={isButtonDisabled || isLoading} // Disable button when loading
        >
          {isLoading ? "Loading..." : "Save"} {/* Show loading text */}
        </button>
      </div>
    </>
  );
};

export { RCVerificationModal };
