import React, { useState } from "react";
import cx from "classnames";
import { ArrowDown, ArrowUp } from "../../../assets/icons";
import NewTooltips from "../../../assets/icons/NewTooltips";
import { formatMoney } from "../../../utils";

interface TrendStatsCardProps {
  title: string;
  titleIcon: any;
  value: string | number;
  trendtitle?: string;
  isUptrend: boolean;
  isCashBalance?: boolean;
  toolTipText?: string;
}

const TrendStatsCard: React.FC<TrendStatsCardProps> = ({
  title,
  titleIcon,
  isUptrend,
  value,
  trendtitle,
  toolTipText,
  isCashBalance,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <div className="w-full px-3 pt-3 pb-[35px] bg-[#ffffff] border border-[#EAECF0] rounded-lg">
        <div className="text-[#344054] font-medium flex items-center justify-between">
          <div className="flex items-center gap-2">
            <span>{titleIcon}</span>
            <p className="text-[12px] 1220:text-[13px] xl:text-[14px]  leading-5">
              {title}
            </p>
            {toolTipText && (
              <div
                className="relative"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <div data-testid="tooltip-icon">
                  <NewTooltips />
                </div>
                {isHovered && (
                  <div className="absolute w-[204px] left-[-5.7em]">
                    <div className="flex items-center justify-center">
                      <svg
                        width="18"
                        height="15"
                        viewBox="0 0 18 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9 0L17.6603 15H0.339746L9 0Z"
                          fill="#FFF4C6"
                        />
                      </svg>
                    </div>
                    <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                      {toolTipText}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {trendtitle && <p className="text-[11px] leading-3">{trendtitle}</p>}
        </div>

        <div className="flex items-center gap-3 mt-7">
          <p className="text-[14px] 1220:text-[16px] xl:text-[20px] text-[#000000] font-semibold leading-6">
            {Number(value) == 0
              ? "--"
              : isCashBalance
              ? formatMoney(Number(value))
              : value}
          </p>
          {Number(value) !== 0 && isCashBalance && (
            <div
              className={` p-1 flex rounded-[4px] gap-1 items-center ${
                isUptrend ? "bg-uptrend" : "bg-downtrend"
              }`}
            >
              {isUptrend ? <ArrowUp /> : <ArrowDown />}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { TrendStatsCard };
