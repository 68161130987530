import React, {
  CSSProperties,
  FunctionComponent,
  useEffect,
  useRef,
  useState,
} from "react";
import { SearchIcon } from "../../../assets/icons";
import DropIcon from "../../../assets/icons/DropIcon";
import PlusBoxIcon from "../../../assets/icons/PlusBoxIcon";
import { CustomDropdownItem } from "./customDropdown";

interface SearchProps<T> {
  categories: {
    name: string;
    placeholder: string;
    searchKey: string;
    handleSearch: (text: string) => void;
  }[];
  data: Array<T>;
  value?: string | null;
  searchKey: keyof T | string;
  onClearError?: () => void;
  onSelect?: (item: T | null) => void;
  displayKey: keyof T;
  placeholder?: string;
  error?: string;
  isLoading?: boolean;
  dropdownWidth?: string;
  emptyMessage?: string;
  openModal?: () => void | undefined;
  newName?: string;
  emptyLinks?: Array<{
    label: string;
    onClick: () => void;
    icon?: React.ReactNode;
  }>;
  emptyLinkStyle?: CSSProperties;
  dropdownStyle?: React.CSSProperties;
  dropdownClassName?: string;
  clearIconClass?: string;
  selected?: T | null;
  clearIcon?: FunctionComponent<{ color?: string }>;
  handleChangeText?: (text: string) => void;
  onCategoryChange?: (categoryName: string) => void;
  classNames?: string;
  ref?: React.Ref<HTMLDivElement>;
}

const CustomSearchDropdown = <T extends { [key: string]: any }>(
  {
    categories,
    data,
    searchKey,
    onClearError,
    onSelect,
    displayKey,
    error,
    isLoading,
    dropdownWidth = "",
    emptyMessage = "No Result",
    emptyLinks,
    emptyLinkStyle,
    dropdownClassName,
    dropdownStyle,
    clearIconClass,
    newName,
    openModal,
    selected,
    handleChangeText,
    clearIcon: ClearIcon,
    onCategoryChange,
    placeholder,
    value,
    classNames,
  }: SearchProps<T>,
  ref: React.Ref<HTMLDivElement>
) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("Customers");
  const [searchText, setSearchText] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [isItemSelected, setIsItemSelected] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [hasError, setHasError] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const selectCategory = (category: string) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
    onCategoryChange && onCategoryChange(category);
    handleClearSelection();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value.toLowerCase();
    setSearchText(searchText);
    const category = categories.find((cat) => cat.name === selectedCategory);
    const searchKeys = category?.searchKey;

    const filteredData = data.filter((item) => {
      const searchTextLower = searchText.toLowerCase();
      return String(item[searchKeys as keyof T])
        .toLowerCase()
        .includes(searchTextLower);
    });

    setHasError(false);
    setShowDropdown(event.target.value.length > 0);
    handleChangeText && handleChangeText(searchText);
  };

  const handleDropdownClick = (item: T, event: React.MouseEvent) => {
    onClearError && onClearError();
    onSelect && onSelect(item);
    setSearchText(String(item[displayKey]));
    setShowDropdown(false);
    setIsItemSelected(true);
    event.stopPropagation();
  };

  const handleClearSelection = () => {
    setSearchText("");
    setShowDropdown(false);
    onSelect && onSelect(null);
    onClearError && onClearError();
  };

  const getNestedPropertyValue = (obj: any, key: string | number) => {
    const keys = String(key).split(".");
    return keys.reduce((acc, curr) => (acc ? acc[curr] : undefined), obj);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const renderDropdownItems = () => {
    const itemsToRender =
      data.length > 0
        ? data.filter((item) => {
            const searchTextLower = searchText.toLowerCase();
            const searchKeysArray = Array.isArray(searchKey)
              ? searchKey
              : [searchKey];
            return searchKeysArray.some((key) => {
              //@ts-ignore
              const itemValue = String(
                getNestedPropertyValue(item, key)
              ).toLowerCase();
              return itemValue.includes(searchTextLower);
            });
          })
        : [];

    return (
      <div>
        {error && <div className="text-red-500 text-sm">{error}</div>}
        {isLoading ? (
          <div
            className={`flex items-start justify-start  cursor-pointer   ${dropdownWidth}`}
          >
            <span className="text-gray-500">Loading...</span>
          </div>
        ) : (
          <ul className="">
            {itemsToRender.map((item, i) => (
              <div key={i} className="dropdown-item">
                <li
                  key={i}
                  className={`${dropdownWidth} cursor-pointer hover:bg-[#D5EBF8]  `}
                  onClick={(event) => handleDropdownClick(item, event)}
                >
                  <CustomDropdownItem
                    item={item}
                    displayKey={displayKey}
                    onClick={handleDropdownClick}
                  />
                </li>
                <hr className="dropdown-divider" />
              </div>
            ))}
          </ul>
        )}
        {!isLoading && itemsToRender.length === 0 && (
          <div className={`${dropdownWidth}`}>
            <div className="text-gray-500 ml-[0.5em] flex flex-col gap-1">
              <span className="italic md:text-[14px] text-[12px]">
                {emptyMessage}
              </span>
              {(emptyMessage === "T" ||
                emptyMessage === "This vendor is not found") && (
                <a
                  href="#customer-modal"
                  className="inline-flex items-center text-[14px] gap-[4px] text-[#044E97] font-medium"
                  onClick={openModal}
                >
                  {newName}
                  <PlusBoxIcon />
                </a>
              )}
            </div>
            {emptyLinks && emptyLinks.length > 0 && (
              <ul>
                {emptyLinks.map(({ label, onClick, icon }, index) => (
                  <li key={index} style={emptyLinkStyle}>
                    <a
                      href="#"
                      className="text-[#044E97] flex items-center space-x-1 mt-[2em] ml-[1em] font-semi-bold text-[16px]"
                      onClick={(event) => {
                        event.stopPropagation();
                        onClick();
                      }}
                    >
                      <span>{label}</span>
                      {icon && <span>{icon}</span>}
                    </a>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </div>
    );
  };
  return (
    <form className="max-w-lg mx-auto relative lg:h-[59px] h-[41px] ">
      <div className="flex justify-center  ">
        <div className="relative h-[59px] xl:w-[150px] biglg:w-[150px] 2xl:w-[150px] w-[100px]">
          <button
            onClick={toggleDropdown}
            className={`flex-shrink-0 z-10 w-[150px] h-[59px] inline-flex items-center  px-4 text-[16px]  font-medium text-center text-gray-900 bg-gray-100 border border-gray-300 rounded-s-full  hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600 ${
              showDropdown ? "rounded-bl-none rounded-br-none" : ""
            }`}
            style={{ width: "11rem" }}
            type="button"
          >
            {selectedCategory}
            <span className="lg:ml-6 ml-1">
              <DropIcon />
            </span>
          </button>

          {isDropdownOpen && (
            <div
              id="dropdown"
              className="absolute top-full z-20 bg-white divide-y divide-gray-100 rounded-lg border border-[#D5EBF8] dark:bg-gray-700"
            >
              <ul
                className="py-2 lg:text-[16px] text-[11px] text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdown-button"
              >
                {categories.map(({ name, placeholder }) => (
                  <li key={name}>
                    <button
                      onClick={() => selectCategory(name)}
                      type="button"
                      className="inline-flex w-[150px] px-4 py-2  hover:bg-[#D5EBF8] "
                    >
                      {name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="relative xl:w-[600px]  w-[150px] h-[59px] ">
          <div className="relative">
            <SearchIcon className="absolute top-1/2 transform -translate-y-1/2 left-3 w-6 h-6 text-gray-400" />
            <input
              value={searchText}
              type="text"
              id="search-dropdown"
              onChange={handleInputChange}
              className={`pl-10 block xl:w-[600px] w-[270px] md:w-[508px] h-[59px] z-20 text-sm text-gray-900 bg-gray-50 rounded-r-full  border-s-gray-50 border-s-2 border border-gray-300 dark:bg-gray-700 dark:border-s-gray-700  dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-none focus:ring-0 appearance-none leading-normal ${
                showDropdown ? "rounded-bl-none rounded-br-none" : ""
              }`}
              placeholder={placeholder}
              required
              ref={inputRef}
              style={{ outline: "none !important", boxShadow: "none" }}
            />
            {selected && (
              <button
                onClick={handleClearSelection}
                className="absolute right-0 top-0 mt-[1.2em]"
              >
                {ClearIcon ? <ClearIcon color="#FF0000" /> : "x"}
              </button>
            )}

            {showDropdown && (
              <div
                className={`absolute z-10 bg-white border w-[290px] xl:w-[750px] biglg:w-[647px] max-h-60 overflow-y-auto border-gray-300 shadow-lg  ${
                  hasError ? "border-red-500" : "border-gray-300"
                } mt-[0.2px] ${dropdownClassName || ""}`}
                style={{ ...dropdownStyle }}
                ref={dropdownRef}
              >
                {renderDropdownItems()}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
};

export { CustomSearchDropdown };
