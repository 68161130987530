import React, { useState } from "react";
import { ICreateSalesData, ItemInput } from "../../../../@types";
import AddModal from "../modal/AddModal";

interface Item {
  name: string;
  id: string;
  quantity: number;
  rate: number;
  amount: number;
}

interface AddItem {
  onSave: (savedItem: ItemInput) => void;
  setIsOpen: (isOpen: boolean) => void;
  editItem: Item | undefined;
  setEditItem: React.Dispatch<React.SetStateAction<Item | undefined>>;
  isEdit: boolean;
  deleteOldData: () => void;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddItem: React.FC<AddItem> = ({
  onSave,
  setIsOpen,
  editItem,
  isEdit,
  setEditItem,
  deleteOldData,
  setIsEdit,
}) => {
  const [item, setItem] = useState<ItemInput>({
    id: "", // Default id or generate dynamically
    name: isEdit ? editItem?.name! : "",
    quantity: isEdit ? editItem?.quantity! : undefined,
    rate: isEdit ? editItem?.rate! : undefined,
  });
  const [errors, setErrors] = useState<string[]>([]);

  // Handle input field changes
  const handleUpdate = (field: keyof ItemInput, value: string | number) => {
    setItem((prev) => ({
      ...prev,
      [field]: field === "quantity" || field === "rate" ? Number(value) : value,
    }));
  };
  const generateTimestampId = () => {
    return Date.now().toString() + Math.floor(Math.random() * 1000).toString();
  };
  const handleSaveItem = () => {
    const { name, quantity, rate } = item;

    // Validate inputs
    const newErrors = [];
    if (!name) newErrors.push("name");
    if (!quantity || quantity <= 0) newErrors.push("quantity");
    if (!rate || rate <= 0) newErrors.push("rate");

    if (newErrors.length > 0) {
      setErrors(newErrors);
      console.error("Please fill out all required fields.");
      return;
    }

    // Calculate total amount
    // @ts-ignore
    const totalAmount = quantity * rate;

    // Create the saved item object
    const savedItem: ItemInput = {
      ...item,
      amount: totalAmount,
      id: generateTimestampId(),
    };

    // Pass data to parent
    onSave(savedItem);

    if (isEdit) {
      deleteOldData();
    }
    setIsEdit(false);
    // Optionally reset the form
    setItem({ id: "", name: "", quantity: undefined, rate: undefined });
    setErrors([]);
  };

  return (
    <>
      <AddModal>
        <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
          <div className="">
            <div className="flex justify-between items-center mb-5 pb-2">
              <div
                onClick={() => {
                  setIsEdit(false);
                  setIsOpen(false);
                }}
              >
                <svg
                  className="mt-[-2px] cursor-pointer"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1L1.5 8L8.5 15"
                    stroke="#33363F"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="w-full flex justify-center">
                <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                  {isEdit ? "Edit Item" : "Add Item"}
                </p>
              </div>
            </div>
            {isEdit ? (
              <form className="px-3">
                {/* Item Name Field */}
                <div className="mb-4">
                  <input
                    type="text"
                    id="itemName"
                    placeholder="Item name"
                    className={`mt-1 block w-full px-3 py-2 border ${
                      errors.includes("name")
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    value={item.name}
                    onChange={({ target }) =>
                      handleUpdate("name", target.value)
                    }
                  />
                </div>

                {/* Quantity and Rate Fields */}
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="">
                    <input
                      type="number"
                      id="quantity"
                      placeholder="Quantity"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        errors.includes("quantity")
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      value={item.quantity}
                      onChange={({ target }) =>
                        handleUpdate("quantity", target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <input
                      type="number"
                      id="rate"
                      placeholder="Rate"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        errors.includes("rate")
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      value={item.rate}
                      onChange={({ target }) =>
                        handleUpdate("rate", target.value)
                      }
                    />
                  </div>
                </div>

                {/* Total Amount Field */}
                <div className="mb-4">
                  <input
                    type="number"
                    id="totalAmount"
                    readOnly
                    placeholder="Total amount"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    // @ts-ignore
                    value={item?.quantity * item?.rate || ""}
                  />
                </div>

                {/* Save Item Button */}
                <div className="flex items-center gap-x-5">
                  <button
                    type="button"
                    onClick={() => {
                      deleteOldData();
                      setIsEdit(false);
                      setIsOpen(false);
                    }}
                    className="w-full bg-red-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    onClick={handleSaveItem}
                    className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    Save Item
                  </button>
                </div>
              </form>
            ) : (
              <form className="px-3">
                {/* Item Name Field */}
                <div className="mb-4">
                  <input
                    type="text"
                    id="itemName"
                    placeholder="Item name"
                    className={`mt-1 block w-full px-3 py-2 border ${
                      errors.includes("name")
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                    value={item.name}
                    onChange={({ target }) =>
                      handleUpdate("name", target.value)
                    }
                  />
                </div>

                {/* Quantity and Rate Fields */}
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="">
                    <input
                      type="number"
                      id="quantity"
                      placeholder="Quantity"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        errors.includes("quantity")
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      value={item.quantity}
                      onChange={({ target }) =>
                        handleUpdate("quantity", target.value)
                      }
                    />
                  </div>
                  <div className="">
                    <input
                      type="number"
                      id="rate"
                      placeholder="Rate"
                      className={`mt-1 block w-full px-3 py-2 border ${
                        errors.includes("rate")
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
                      value={item.rate}
                      onChange={({ target }) =>
                        handleUpdate("rate", target.value)
                      }
                    />
                  </div>
                </div>

                {/* Total Amount Field */}
                <div className="mb-4">
                  <input
                    type="number"
                    id="totalAmount"
                    readOnly
                    placeholder="Total amount"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    // @ts-ignore
                    value={item?.quantity * item?.rate || ""}
                  />
                </div>

                {/* Save Item Button */}
                <button
                  type="button"
                  onClick={handleSaveItem}
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  Save Item
                </button>
              </form>
            )}
          </div>
        </div>
      </AddModal>
    </>
  );
};

export default AddItem;
