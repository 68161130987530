import { TransactionEvidenceEntity, TransactionType } from "../../@types";
import { generateUploadURL } from "../../backend-services";

export const getPresignedURL = async (
  file: File,
  entity: any,
  paymentType: TransactionType
): Promise<{ url: string; fields: any }> => {
  const res = await generateUploadURL({
    customerId: paymentType === "revenue" ? entity?.customer.id : undefined,
    transactionType: paymentType,
    entityId: paymentType === "revenue" ? entity?.id : undefined,
    fileFormat: file.type.split(
      "/"
    )[1] as TransactionEvidenceEntity["fileFormat"],
  });
  const data = res.uploadURL;
  return { url: data.url, fields: data.fields };
};
