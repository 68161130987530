import React, { useState, useRef } from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { ServiceData, ServicePriceData } from "../../../@types/service";
import { commasToNumber } from "../../../utils/convertToFloat";
import { Button } from "../../common";
import { Spinner } from "flowbite-react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { calculateDurationFromDate } from "../../../utils/duration";
import { minDate } from "../../../utils/duration";
import { clearFieldError } from "../../../utils/handleErrors";
import { useTranslation } from "react-i18next";
type ErrorProps = {
  name: string;
  charge: {
    NGN?: string;
    EUR?: string;
    USD?: string;
  };
};

interface ServiceFormProps {
  serviceData: ServiceData;
  setServiceData: (ServiceData: ServiceData) => void;
  handleCreateService: (e: React.FormEvent<HTMLFormElement>) => void;
  setPrice: (ServicePrice: ServicePriceData[]) => void;
  price: ServicePriceData[];
  error: ErrorProps;
  setError: any;
  isLoading: boolean;
  onClose?: () => void;
}
type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

function ServiceInvoiceForm({
  serviceData,
  setServiceData,
  handleCreateService,
  setPrice,
  price,
  error,
  setError,
  isLoading,
  onClose,
}: ServiceFormProps) {
  // Create refs for the form fields
  const nameRef = useRef<HTMLInputElement>(null);
  const chargeNGNRef = useRef<HTMLInputElement>(null);
  const chargeUSDRef = useRef<HTMLInputElement>(null);
  const chargeEURRef = useRef<HTMLInputElement>(null);

  // Function to scroll to the first error
  const scrollToError = () => {
    if (error.name && nameRef.current) {
      nameRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
    } else if (error.charge.NGN && chargeNGNRef.current) {
      chargeNGNRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (error.charge.USD && chargeUSDRef.current) {
      chargeUSDRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else if (error.charge.EUR && chargeEURRef.current) {
      chargeEURRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };
  const { t } = useTranslation("services");
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedServiceData = { ...serviceData, [name]: value };
    setServiceData(updatedServiceData);
    clearFieldError(name, error, setError);
  };

  const handleServicePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = commasToNumber(value);
    const existingPriceIndex = price.findIndex(
      (p: ServicePriceData) => p.currency === name
    );

    if (formattedValue === "") {
      if (existingPriceIndex !== -1) {
        const updatedPrice = [...price];
        updatedPrice.splice(existingPriceIndex, 1);
        setPrice(updatedPrice);
        setError({ ...error, charge: { [name]: "" } });
      }
    } else {
      const updatedPrice = [...price];
      if (existingPriceIndex === -1) {
        updatedPrice.push({ amount: formattedValue, currency: name });
      } else {
        updatedPrice[existingPriceIndex].amount = formattedValue;
      }
      setPrice(updatedPrice);
      setError({ ...error, charge: { [name]: "" } });
    }
  };

  const [duration, setDuration] = useState<Value>(new Date());

  const handleDuration = (value: Value) => {
    if (value === null) {
      const today = new Date();
      setDuration(today);
      const newDuration = calculateDurationFromDate(today);
      const updatedServiceData = { ...serviceData, duration: newDuration };
      setServiceData(updatedServiceData);
    } else {
      setDuration(value);

      const newDuration = calculateDurationFromDate(value);
      const updatedServiceData = { ...serviceData, duration: newDuration };
      setServiceData(updatedServiceData);
    }
  };

  React.useEffect(() => {
    scrollToError();
  }, [error]);

  return (
    <div className="mt-6 lg:w-[600px] lg:h-[350px] ">
      <form onSubmit={handleCreateService}>
        <div className="space-y-6 lg:">
          {/* <div className="flex flex-col "> */}
          <div className="w-full  md:space-y-3 ">
            <label
              htmlFor="Service name"
              className="text-[14px] lg:text-[16px] font-normal"
            >
              {t("service.serviceName")}
            </label>
            <InputComponent
              ref={nameRef} // Attach the ref
              className="w-full py-3  border border-[#A4A4A4] rounded-[4px] block"
              type="text"
              name="name"
              placeholder="Enter Name"
              value={serviceData.name}
              handleChange={handleInputChange}
              error={error.name}
            />
          </div>
          <div className="lg:flex gap-4">
            {/* </div> */}
            {/* <div className=" lg:items-center"> */}
            <div className="w-full  md:space-y-3 ">
              <div className="flex justify-between lg:text-[16px] ">
                <label htmlFor="">
                  {" "}
                  {t("service.serviceCharge")}(in Naira)
                </label>
                <p className="font-semibold"></p>
              </div>
              <InputComponent
                ref={chargeNGNRef} // Attach the ref
                className="w-full py-3  border border-[#A4A4A4] rounded-[4px] block"
                type="text"
                name="NGN"
                placeholder="N0.00"
                value={
                  price.find((p: any) => p.currency === "NGN")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.NGN}
              />
            </div>
            <div className="w-full space-y-2 md:space-y-3 lg:space-y-2">
              <label
                htmlFor="Duration"
                className="text-[14px] lg:text-[16px]  font-normal"
              >
                {t("service.duration")}( in hours)
              </label>
              <div>
                <InputComponent
                  className="w-full py-3  border border-[#A4A4A4] rounded-[4px] block"
                  type="number"
                  name="duration"
                  placeholder="Service Duration"
                  value={serviceData.duration}
                  handleChange={handleInputChange}
                />
              </div>
            </div>
          </div>

          <div className="hidden w-full space-y-2 md:space-y-3 lg:space-y-2 lg:block">
            <label
              htmlFor="Description"
              className="text-[14px] lg:text-[16px]  font-normal"
            >
              {t("service.description")}
            </label>
            <textarea
              className="w-full h-[60px] md:h-[140px] py-3  border border-[#A4A4A4] rounded-[14px] block"
              name="description"
              placeholder="Provide more details about the product..."
              value={serviceData.description}
              onChange={handleInputChange}
            />
          </div>
          {/* </div> */}
          <div className="w-full space-y-2 md:space-y-3 lg:hidden">
            <label
              htmlFor="Description"
              className="text-[14px] lg:text-[16px]  font-normal"
            >
              {t("service.description")}
            </label>
            <textarea
              className="w-full h-[80px] md:h-[140px] py-3  border border-[#A4A4A4] rounded-[4px] block"
              name="description"
              placeholder="Describe Service..."
              value={serviceData.description}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* <div className=" mt-9 text-[14px]">
          <h2 className="font-semibold text-[16px]">Optional</h2>
          <div className="border border-[#A4A4A4]/40 w-full mt-4 mb-[22px]" />
          <div className="lg:flex gap-8">
            <div className="mb-6 w-full">
              <div className="flex justify-between lg:text-[16px] ">
                <label htmlFor=""> {t("service.serviceCharge")}</label>
                <p className="font-semibold">USD $</p>
              </div>
              <InputComponent
                ref={chargeUSDRef} // Attach the ref
                className="w-full py-3  border border-[#A4A4A4] rounded-[4px] block"
                type="text"
                name="USD"
                placeholder="USD 0.00"
                value={
                  price.find((p: any) => p.currency === "USD")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.USD}
              />
            </div>
            <div className="mb-6 w-full">
              <div className="flex justify-between lg:text-[16px] ">
                <label htmlFor=""> {t("service.serviceCharge")}</label>
                <p className="font-semibold">EUR €</p>
              </div>
              <InputComponent
                ref={chargeEURRef} // Attach the ref
                className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
                type="text"
                name="EUR"
                placeholder="EUR 0.00"
                value={
                  price.find((p: any) => p.currency === "EUR")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.EUR}
              />
            </div>
          </div>
        </div> */}

        <div className="md:flex justify-end lg:mt-[1em] md:mt-16 mb-2">
          {/* <div className="w-full px-4">
            <button
              className=" w-full h-[48px] md:h-[52px] inline-flex items-center bg justify-center border border-[#044E97] text-[#044E97] rounded-[4px] text-[14px]"
              onClick={onClose}
            >
              {t("service.cancel")}
            </button>
          </div> */}
          <div className="md:mb-[44px] w-[300px] md:relative mt-3 lg:mt-0 px-4 flex justify-end ">
            <button
              type="submit"
              className="w-full h-[48px] md:h-[52px]  text-[16px] bg-[#044E97] border font-semibold rounded-[4px]  text-white inline-flex items-center justify-center whitespace-nowrap"
            >
              {isLoading ? (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              ) : (
                "Create Service"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ServiceInvoiceForm;
