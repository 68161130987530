import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { ItemInput } from "../../../@types";
import { ServiceData, ServicePriceData } from "../../../@types/service";
import { createNewService } from "../../../backend-services/service";
import { validateCreateService } from "../../../modules/services/services";
import { clearFieldError } from "../../../utils";
import { commasToNumber, convertToFloat } from "../../../utils/convertToFloat";
import { InputComponent } from "../../core/Forms";
import AddModal from "../sections/modal/AddModal";

type ErrorProps = {
  name: string;
  charge: {
    NGN?: string;
    EUR?: string;
    USD?: string;
  };
};

interface InputServiceItemsProps {
  setIsOpen: (isOpen: boolean) => void;
  onCreateSuccess: (newItem: ItemInput) => void;
}

const InputServiceItems: React.FC<InputServiceItemsProps> = ({
  setIsOpen,
  onCreateSuccess,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [price, setPrice] = useState<ServicePriceData[]>([]);
  const [serviceData, setServiceData] = useState<ServiceData>({
    name: "",
    duration: "",
    description: "",
    price: [],
    status: "Active",
  });

  const [error, setError] = React.useState<ErrorProps>({
    name: "",
    charge: {},
  });

  const handleCreateService = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPrice = price.map((item) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateCreateService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });

    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        const newServiceData = {
          ...serviceData,
          price: newPrice,
        };

        const response = await createNewService(newServiceData);
        // toast.success(t("service.successToast"));
        // setShowCreateService(false);
        setIsLoading(false);
        setServiceData({
          name: "",
          duration: "",
          description: "",
          price: [],
          status: "Active",
        });

        onCreateSuccess(response);
      } catch (error) {
        // toast.error('Failed to create service.');
        setIsLoading(false);
        console.error("Error creating service:", error);
      }
    }
  };

  const handleServicePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = commasToNumber(value);
    const existingPriceIndex = price.findIndex(
      (p: ServicePriceData) => p.currency === name
    );

    if (formattedValue === "") {
      if (existingPriceIndex !== -1) {
        const updatedPrice = [...price];
        updatedPrice.splice(existingPriceIndex, 1);
        setPrice(updatedPrice);
        setError({ ...error, charge: { [name]: "" } });
      }
    } else {
      const updatedPrice = [...price];
      if (existingPriceIndex === -1) {
        updatedPrice.push({ amount: formattedValue, currency: name });
      } else {
        updatedPrice[existingPriceIndex].amount = formattedValue;
      }
      setPrice(updatedPrice);
      setError({ ...error, charge: { [name]: "" } });
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedServiceData = { ...serviceData, [name]: value };
    setServiceData(updatedServiceData);
    clearFieldError(name, error, setError);
  };
  const handlePriceChange = (currency: string, value: string) => {
    const sanitizedValue = value.replace(/[^0-9.,]/g, "");
    const formattedValue = sanitizedValue.replace(",", ".");

    const existingPriceIndex = price.findIndex((p) => p.currency === currency);
    const updatedPrice = [...price];

    if (formattedValue === "") {
      if (existingPriceIndex !== -1) {
        updatedPrice.splice(existingPriceIndex, 1);
      }
    } else {
      if (existingPriceIndex === -1) {
        updatedPrice.push({ amount: formattedValue, currency });
      } else {
        updatedPrice[existingPriceIndex].amount = formattedValue;
      }
    }

    setPrice(updatedPrice);
  };

  return (
    <AddModal>
      <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
        <div className="">
          <div className="flex justify-between items-center mb-5 pb-2">
            <div onClick={() => setIsOpen(false)}>
              <svg
                className="mt-[-2px] cursor-pointer"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div className="w-full flex justify-center">
              <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                Add Item
              </p>
            </div>
          </div>

          <form onSubmit={handleCreateService}>
            {/* Item Name Field */}
            <div className="mb-4">
              <label
                htmlFor="itemName"
                className="block text-sm font-medium text-gray-700"
              >
                Item name
              </label>
              <InputComponent
                type="text"
                id="itemName"
                placeholder="Item name"
                value={serviceData.name}
                name="name"
                handleChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mb-4">
              <div className="">
                <InputComponent
                  type="text"
                  placeholder="duration"
                  name="duration"
                  value={serviceData.duration}
                  handleChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
              <div className="relative">
                <InputComponent
                  type="number"
                  placeholder="price"
                  name="NGN"
                  value={
                    price.find((p: any) => p.currency === "NGN")?.amount || ""
                  }
                  handleChange={(e) => handlePriceChange("NGN", e.target.value)}
                  error={error.charge.NGN}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            {/* Add Button */}
            <div className="flex justify-end mb-6">
              <button
                type="button"
                className="text-blue-500 font-medium hover:underline"
              >
                + Add
              </button>
            </div>

            {/* Save Item Button */}
            {/* <button
              type="submit"
              className="w-full bg-blue-500 text-white py-2 px-4 rounded-md shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Save service
            </button> */}
            <button
              type="submit"
              className="w-full h-[48px] md:h-[52px]  text-[16px] bg-[#044E97] border font-semibold rounded-[4px]  text-white inline-flex items-center justify-center whitespace-nowrap"
            >
              {isLoading ? (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              ) : (
                " Save service"
              )}
            </button>
          </form>
        </div>
      </div>
    </AddModal>
  );
};

export default InputServiceItems;
