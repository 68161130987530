import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPage } from "./analytics";

interface GAListenerProps {
  children: React.ReactNode;
}

const GAListener: React.FC<GAListenerProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    trackPage(location.pathname + location.search);
  }, [location]);

  return <>{children}</>;
};

export default GAListener;
