import React, { useState } from "react";
import { Variation, VariationItem } from "../../../@types/inventory";
import { AddSquareIcon } from "../../../assets/icons";
import { SubtractSquareIcon } from "../../../assets/icons/SubtractSquareIcon";
import ArrowPointerDown from "../../../assets/icons/ArrowPointerDown";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { values } from "lodash";
import BinIcon from "../../../assets/icons/BinIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { spawn } from "child_process";
import { Tooltip } from "../../../assets/icons/Tooltip";
import { useTranslation } from "react-i18next";

interface VariationTableProps {
  onAddVariation: (newVariation: VariationItem) => void;
  variations: VariationItem[];
  onRemoveVariation: (id: number) => void;
  onUpdateVariation: (id: number, field: string, value: string) => void;
  variationList?: any;
  variationError: any[];
  setVariationError: (error: any[]) => void;
  variationName: string;
  setVariationName: (arg0: string) => void;
  toolTipText?: string;
}

function VariationTable({
  onAddVariation,
  variations,
  onRemoveVariation,
  onUpdateVariation,
  variationList,
  variationError,
  setVariationError,
  variationName,
  setVariationName,
  toolTipText,
}: VariationTableProps) {
  const handleAddVariation = () => {
    const isPreviousVariationEmpty =
      variations.length > 0 &&
      variations[variations.length - 1].name.trim() === "";
    if (!isPreviousVariationEmpty) {
      const newVariation: VariationItem = {
        name: "",
        type: "",
        costPrice: "",
        price: "",
        quantity: "",
      };
      onAddVariation(newVariation);
      setVariationName("");
    } else {
      setVariationName("name required");
    }
  };
  const [selectedType, setSelectedType] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("");
  const [isHovered, setIsHovered] = useState(false);
  const { t } = useTranslation("inventories");
  const handleVariation = (index: number, name: string, value: string) => {
    if (name === "name") {
      setSelectedName(value);
      onUpdateVariation(index, "name", value);
    }
    if (name === "type") {
      setSelectedType(value);
      onUpdateVariation(index, "type", value);
    }
  };

  const variationNameOptions = variationList.map((item: any) => ({
    value: item.variantName,
    label: item.variantName,
  }));

  const variant = variationList.find(
    (item: any) => item.variantName === selectedName
  );

  const variationTypesOptions =
    variant?.types.map((type: string) => ({ value: type, label: type })) || [];

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className={`${variations.length > 0 && "mt-12"}`}>
      {variations.length > 0 && (
        <div className="text-[20px] font-medium">
          {t("inventory.productVariation")}
        </div>
      )}
      <div className={`w-full ${variations.length > 0 && "mt-6"} `}>
        {variations.map((variation, index) => (
          <div key={index + 1} className="">
            <div className="md:flex md:items-center md:gap-[40px] lg:gap-[6.2%] xl:justify-between">
              <div className="flex flex-col gap-8 md:flex-row justify-between items-center px-3 pt-4 pb-3 border border-gray-400 rounded md:border-0 md:px-0 md:py-0 md:gap-4">
                <div className="w-full space-y-4 md:space-y-0 h-[5.5rem]">
                  <label
                    className="block text-[#212121] tracking-wide text-[14px] md:mb-1 font-normal"
                    htmlFor="cost-price"
                  >
                    {t("inventory.variations")}
                  </label>
                  <DropdownComponent
                    options={variationNameOptions}
                    value={selectedName}
                    selectedValue={
                      variation.name ? variation.name : "Variation"
                    }
                    setSelectedValue={setSelectedName}
                    handlevariation={handleVariation}
                    name="name"
                    variationIndex={index}
                    dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em]  md:mr-[8px] lg:mr-[8px]"
                  />
                  {variations.length - 1 === index && (
                    <span style={{ color: "red" }}>{variationName}</span>
                  )}
                </div>
                <div className="w-full space-y-4 md:space-y-0 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] md:mb-1 font-normal">
                    Type
                  </label>
                  <DropdownComponent
                    options={variationTypesOptions}
                    value={selectedType}
                    selectedValue={variation.type ? variation.type : "Type"}
                    setSelectedValue={setSelectedType}
                    handlevariation={handleVariation}
                    name="type"
                    variationIndex={index}
                    dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full w-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em]  md:mr-[8px] lg:mr-[8px]"
                    error={variation.type === "" ? "Type is required" : null}
                  />
                </div>
                <div className="w-full space-y-4 md:space-y-0 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] md:mb-1 font-normal">
                    {t("inventory.costPrice")}
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    name="costPrice"
                    type="text"
                    value={variation.costPrice}
                    handleChange={(e) =>
                      onUpdateVariation(index, "costPrice", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={
                      variation.costPrice === ""
                        ? "Cost Price is required"
                        : null
                    }
                  />
                </div>
                <div className="w-full space-y-4 md:space-y-0 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] md:mb-1  font-normal">
                    {t("inventory.sellingPrice")}
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    name="price"
                    type="text"
                    value={variation.price}
                    handleChange={(e) =>
                      onUpdateVariation(index, "price", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={variation.price === "" ? " Price is required" : null}
                  />
                </div>
                <div className="w-full space-y-4 md:space-y-0 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] md:mb-1 font-normal">
                    Quantity
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    type="text"
                    name="quantity"
                    value={variation.quantity}
                    handleChange={(e) =>
                      onUpdateVariation(index, "quantity", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={
                      variation.quantity === "" ? "quantity is required" : null
                    }
                  />
                </div>
                <div className="flex md:hidden lg:hidden item center justify-end mt-5 self-end">
                  <button
                    onClick={() => onRemoveVariation(index)}
                    className="text-red-500 py-1 flex items-center gap-1"
                  >
                    Remove
                    <div>
                      <SubtractSquareIcon />
                    </div>
                  </button>
                </div>
              </div>
              <div
                className="hidden md:block biglg:hidden"
                onClick={() => onRemoveVariation(index)}
              >
                <DeleteIcon />
              </div>
              <div className="hidden biglg:flex item center justify-end mt-5">
                <button
                  onClick={() => onRemoveVariation(index)}
                  className="text-red-500 py-1 flex items-center gap-1"
                >
                  Remove
                  <div>
                    <SubtractSquareIcon />
                  </div>
                </button>
              </div>
            </div>
            {
              <div className="mt-6 border-[1px] border-gray-300 md:hidden"></div>
            }
          </div>
        ))}
      </div>

      <div
        className="flex items-center gap-1 font-semibold cursor-pointer w-fit mt-4"
        onClick={handleAddVariation}
      >
        <button className="text-[#044E97] text-[16px]">Add Variation</button>
        {toolTipText && (
          <div
            className="relative mt-[0.2em]"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip />
            {isHovered && (
              <div className="absolute w-[204px] left-[-5.7em] z-10">
                <div className="flex items-center justify-center">
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 0L17.6603 15H0.339746L9 0Z" fill="#FFF4C6" />
                  </svg>
                </div>
                <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                  {toolTipText}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export { VariationTable };
