import React from "react";

const SelectedMarkIcon = () => {
  return (
    <>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.9974 17.9577C4.04984 17.9577 0.0390625 13.9469 0.0390625 8.99935C0.0390625 4.0518 4.04984 0.0410156 8.9974 0.0410156C13.9449 0.0410156 17.9557 4.0518 17.9557 8.99935C17.9557 13.9469 13.9449 17.9577 8.9974 17.9577ZM12.7307 6.81398C13.1345 6.59315 13.2828 6.08679 13.062 5.683C12.8412 5.2792 12.3348 5.13087 11.931 5.3517C10.4072 6.18507 9.14705 7.79339 8.29981 9.09072C7.9867 9.57018 7.71926 10.0241 7.50522 10.4086C7.3056 10.2149 7.10788 10.0468 6.93104 9.90701C6.69965 9.72406 6.49141 9.57977 6.33959 9.48032L6.07705 9.31729C5.67727 9.08928 5.16834 9.22854 4.94033 9.62832C4.71237 10.028 4.85151 10.5368 5.25108 10.7649L5.42632 10.8745C5.54533 10.9525 5.7121 11.0679 5.89737 11.2144C6.27867 11.5159 6.69012 11.9111 6.94904 12.3439C7.10795 12.6096 7.40103 12.7653 7.71013 12.7482C8.01914 12.7312 8.29335 12.5441 8.4221 12.2626L8.50396 12.0914C8.56038 11.9761 8.6449 11.8074 8.75489 11.5996C8.97531 11.1832 9.2958 10.6137 9.69526 10.002C10.5147 8.74729 11.5879 7.43895 12.7307 6.81398Z"
          fill="#044E97"
        />
      </svg>
    </>
  );
};

export default SelectedMarkIcon;
