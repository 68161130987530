import React, { useState } from "react";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { commasToNumber } from "../../../utils/convertToFloat";
import { ServicePriceData } from "../../../@types/service";
import { Spinner } from "flowbite-react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import {
  calculateDurationFromDate,
  convertDurationToDate,
} from "../../../utils/duration";
import { minDate } from "../../../utils/duration";

interface EditServiceFormProps {
  editDetail: any;
  setEditDetail: any;
  setPrice: any;
  price: any;
  isLoading: boolean;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClose: () => void;
  error: any;
}

type ValuePiece = Date | null;
type Value = ValuePiece | [ValuePiece, ValuePiece];

const EditServiceForm = ({
  setEditDetail,
  error,
  editDetail,
  price,
  setPrice,
  isLoading,
  onClick,
  onClose,
}: EditServiceFormProps) => {
  const [duration, setDuration] = useState<Value>(new Date());

  React.useEffect(() => {
    // Set initial value for DateTimePicker when editing
    if (editDetail.duration) {
      const initialDate = convertDurationToDate(editDetail.duration);
      setDuration(initialDate);
    }
  }, [editDetail.duration]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    const updatedServiceData = { ...editDetail, [name]: value };
    setEditDetail(updatedServiceData);
  };

  const handleServicePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = commasToNumber(value);

    const existingPriceIndex = price.findIndex(
      (p: ServicePriceData) => p.currency === name
    );

    if (formattedValue === "") {
      if (existingPriceIndex !== -1) {
        const updatedPrice = [...price];
        updatedPrice.splice(existingPriceIndex, 1); // Remove the object
        setPrice(updatedPrice);
      }
    } else {
      const updatedPrice = [...price];
      if (existingPriceIndex === -1) {
        updatedPrice.push({ amount: formattedValue, currency: name });
      } else {
        updatedPrice[existingPriceIndex].amount = formattedValue;
      }
      setPrice(updatedPrice);
    }
  };

  const handleDuration = (value: Value) => {
    setDuration(value);
    const newDuration = calculateDurationFromDate(value);
    const updatedServiceData = { ...editDetail, duration: newDuration };
    setEditDetail(updatedServiceData);
  };

  return (
    <div className="mt-10">
      <form className="">
        <div className="space-y-6">
          <div className="w-full space-y-2 md:space-y-3 lg:space-y-2">
            <label
              htmlFor="Service Name"
              className="text-[14px] lg:text-[16px] font-normal"
            >
              Service Name
            </label>
            <InputComponent
              className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
              type="text"
              name="name"
              placeholder="Service Name"
              value={editDetail.name}
              handleChange={handleInputChange}
              error={error.name}
            />
          </div>
          <div className="w-full space-y-2 md:space-y-3 lg:space-y-2">
            <label
              htmlFor="Duration"
              className="text-[14px] lg:text-[16px] font-normal"
            >
              Duration (Hours)
            </label>
            <div>
              <InputComponent
                className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
                type="number"
                name="duration"
                placeholder="Service Duration"
                value={editDetail.duration}
                handleChange={handleInputChange}
                error={error.name}
              />
            </div>
          </div>
          <div className="hidden w-full space-y-2 md:space-y-3 lg:space-y-2">
            <label
              htmlFor="Service Charge"
              className="text-[14px] lg:text-[16px] font-normal"
            >
              Description
            </label>
            <InputComponent
              className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
              type="text"
              name="description"
              placeholder="Describe Service..."
              value={editDetail.description}
              handleChange={handleInputChange}
            />
          </div>

          <div className="w-full space-y-2 md:space-y-3">
            <label
              htmlFor="Description"
              className="text-[14px] lg:text-[16px] font-normal"
            >
              Description
            </label>
            <textarea
              className="w-full h-[80px] md:h-[100px] py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
              name="description"
              placeholder="Describe Service..."
              value={editDetail.description}
              onChange={handleInputChange}
            />
          </div>
          <div className="w-full space-y-2 md:space-y-3 lg:space-y-2">
            <div className="flex justify-between text-[14px] lg:text-[16px]">
              <label htmlFor="">Service Charge</label>
              <p className="font-medium">NGN ₦</p>
            </div>
            <InputComponent
              className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
              type="text"
              name="NGN"
              placeholder="N0.00"
              value={price.find((p: any) => p.currency === "NGN")?.amount || ""}
              handleChange={handleServicePrice}
              error={error.charge.NGN}
            />
          </div>
        </div>

        {/* Optional Form for USD and EUR */}
        <div className="mt-8 text-[14px]">
          <h2 className="font-semibold text-[16px]">Optional</h2>
          <div className="border border-[#A4A4A4]/50 w-full mt-4 mb-6" />
          <div className="">
            <div className="mb-6 w-full">
              <div className="flex justify-between text-[14px] lg:text-[16px]">
                <label htmlFor="">Service Charge</label>
                <p className="font-medium">USD $</p>
              </div>
              <InputComponent
                className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
                type="text"
                name="USD"
                placeholder="$0.00"
                value={
                  price.find((p: any) => p.currency === "USD")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.USD}
              />
            </div>
            <div className="w-full">
              <div className="flex justify-between text-[14px] lg:text-[16px]">
                <label htmlFor="">Service Charge</label>
                <p className="font-medium">EUR €</p>
              </div>
              <InputComponent
                className="w-full py-3 px-4 border border-[#A4A4A4] rounded-[4px] block"
                type="text"
                name="EUR"
                placeholder="€0.00"
                value={
                  price.find((p: any) => p.currency === "EUR")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.EUR}
              />
            </div>
          </div>
        </div>

        {/* button */}

        <div className="mt-[48px] mb-[84px] md:relative md:w-full flex gap-6 md:mt-10">
          <div className="w-full">
            <button
              onClick={onClose}
              type={"button"}
              className="w-full h-[40px] md:h-[52px] text-[16px] text-[#044E97] bg-[#ffffff] border  border-[#044E97] font-semibold rounded-[4px] inline-flex items-center justify-center whitespace-nowrap"
            >
              Cancel
            </button>
          </div>
          <div className="w-full">
            <button
              type="button"
              onClick={onClick}
              className="w-full h-[40px] md:h-[52px] text-[16px] bg-[#044E97] border font-semibold rounded-[4px]  text-white inline-flex items-center justify-center whitespace-nowrap"
            >
              {isLoading ? (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              ) : (
                "Save"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditServiceForm;
