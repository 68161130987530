import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { CloseIcon } from "../assets/icons";
import { Shortcut } from "../@types";
import { defaultShortcuts } from "../constants/helpServices";

type RangeGenerator = () => [Date, Date];
interface DatePickerWithShortcutsProps {
  shortcuts?: Shortcut[];
  onRangeSelect: (range: [Date, Date]) => void;
  selected: [Date, Date] | null;
  onClose: () => void;
  onApplyButtonClick: (startDate: Date, endDate: Date) => Promise<void>;
  fetchData?: () => void;
}

const DatePickerWithShortcuts: React.FC<DatePickerWithShortcutsProps> = ({
  shortcuts = defaultShortcuts,
  onRangeSelect,
  selected,
  onClose,
  onApplyButtonClick,
  fetchData,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [, setSelected] = useState<[Date, Date] | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isCustomRange, setIsCustomRange] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [applyActiveIndex, setApplyActiveIndex] = useState<number | null>(null);

  const handleClose = () => {
    setIsOpen(false);
    setShowCalendar(!showCalendar);
    onClose();
  };

  const handleClearFilter = () => {
    setIsOpen(false);
    setShowCalendar(!showCalendar);
    onClose();
    fetchData && fetchData();
  };

  useEffect(() => {
    if (selected && !mounted) {
      setMounted(true);
      setIsOpen(true);
    }
  }, [selected, mounted]);

  const handleShortcutClick = (
    rangeGenerator: RangeGenerator,
    index: number,
  ): void => {
    const range: [Date, Date] = rangeGenerator();
    setSelected(range);
    setStartDate(range[0]);
    setEndDate(range[1]);
    setActiveIndex(index);
  };

  const handleCalendarChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setIsCustomRange(true);
  };

  const handleApplyButtonClick = async () => {
    if (startDate && endDate) {
      await onApplyButtonClick(startDate, endDate);
    } else if (startDate && !endDate) {
      //@ts-ignore
      await onApplyButtonClick(startDate, null);
    }
    setApplyActiveIndex(activeIndex);
  };

  useEffect(() => {
    if (selected) {
      setStartDate(selected[0]);
      setEndDate(selected[1]);
    }
  }, [selected]);

  if (!isOpen) {
    return null;
  }

  return (
    //Mobile view
    <>
      <div className=" relative">
        <div className="md:hidden bg-white rounded-md w-[full] h-[687px]  fixed bottom-0 right-0 left-0">
          <div className="flex justify-between mb-4">
            <p className="text-[16px] font-medium pl-[1em] pt-[1em] mt-[0.5em]">
              Pick a date range:
            </p>
            {selected && (
              <div className="pl-[2em] pt-[2em] lg:text-[16px] md:text-[16px]  text-[12px] ml-[-1em] lg:ml-0">
                {format(selected[0], "yyyy-MM-dd")} to{" "}
                {format(selected[1], "yyyy-MM-dd")}
              </div>
            )}
            <div
              className="lg:pr-[2em] lg:pt-[1em] pt-[1em] pr-[0.5em] md:mr-[1em] cursor-pointer biglg:mr-[0.5em]"
              onClick={handleClose}
            >
              <CloseIcon />
            </div>
          </div>
          <hr className="w-[100%] text-[#A4A4A4] lg:mt-[-0.2em]" />

          <div>
            <div className="grid grid-cols-3 gap-x-4em px-[4em] text-[12px] mt-[3em] md:hidden lg:hidden">
              {shortcuts.slice(0, 3).map((shortcut, index) => (
                <button
                  key={index}
                  onClick={() => handleShortcutClick(shortcut.range, index)}
                  className={`text-left focus:outline-none w-[100px] h-[33px]  rounded-md flex justify-center items-center ${
                    activeIndex === index ? "font-bold active" : ""
                  } hover:bg-[#044E97] hover:text-white `}
                  style={
                    startDate &&
                    endDate &&
                    shortcut.range()[0].getTime() === startDate.getTime() &&
                    shortcut.range()[1].getTime() === endDate.getTime()
                      ? { width: "100px", height: "33px" }
                      : {}
                  }
                >
                  {shortcut.label}
                </button>
              ))}

              <div className="col-span-3 flex justify-center items-center gap-x-8em mt-[2em]">
                <button
                  className={`text-left focus:outline-none w-[100px] h-[33px]  rounded-md flex justify-center items-center ${
                    activeIndex === 3 ? "font-bold active" : ""
                  } hover:bg-[#044E97]  rounded-md hover:text-white`}
                  onClick={() => handleShortcutClick(shortcuts[3].range, 3)}
                >
                  {shortcuts[3].label}
                </button>
                <button
                  className={`text-left focus:outline-none w-[100px] h-[33px]  rounded-md flex justify-center items-center ${
                    activeIndex === 4 ? "font-bold active" : ""
                  } hover:bg-[#044E97]  rounded-md hover:text-white`}
                  onClick={() => handleShortcutClick(shortcuts[4].range, 4)}
                >
                  {shortcuts[4].label}
                </button>
              </div>
            </div>

            <div className="w-full px-[1em] h-[334px] mt-[3em] ">
              <DatePicker
                selected={startDate}
                onChange={handleCalendarChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
                className="text-[12px]  w-full ml-[4em] !important "
                wrapperClassName="w-full h-[344px] "
                calendarClassName="w-full h-[344px] !important"
                popperClassName="w-full h-[334px] "
              />
            </div>
          </div>
          <div className="flex justify-end items-center mt-[2.5em]  pr-[1em] pb-0 ">
            <button
              onClick={handleClearFilter}
              className="lg:bg-white bg-[#FFFFFF] md:bg-[#FFFFFF] border border-[#044E97] text-[#044E97] py-2 px-4 rounded ml-2 w-[156px] h-[48px] text-[16px] mr-[1em]"
            >
              Clear Filter
            </button>
            <button
              onClick={handleApplyButtonClick}
              className=" bg-[#044E97] md:bg-[#044E97] text-white py-2 px-4 rounded ml-2 w-[71px] h-[48px] text-[16px] xl:mr-[1em] md:mr-[1.7em]"
            >
              OK
            </button>
          </div>
        </div>
      </div>

      {/* Desktop and Tablet */}
      <div className="bg-white rounded-md mt-[4em] w-full h-[617px] md:w-[634px] md:h-[505px] lg:w-[624px] lg:h-[502px] xl:h-[510px] biglg:h-[490px] hidden md:block">
        <div className="flex justify-between mb-4">
          <p className="lg:pl-[2em] lg:pt-[0.3em] lg:text-[16px] md:pl-[2em] md:pt-[1em] md:text-[16px] md:font-semibold text-[12px] pl-[1em] pt-[1em] mt-[0.5em]">
            Pick a date range:
          </p>
          {selected && (
            <div className="pl-[2em] pt-[2em] lg:text-[16px] md:text-[16px]  text-[12px] ml-[-1em] lg:ml-0">
              {format(selected[0], "yyyy-MM-dd")} to{" "}
              {format(selected[1], "yyyy-MM-dd")}
            </div>
          )}
          <div
            className="lg:pr-[2em] lg:pt-[1em] pt-[1em] pr-[0.5em] md:mr-[1em] cursor-pointer biglg:mr-[0.5em]"
            onClick={handleClose}
          >
            <CloseIcon />
          </div>
        </div>
        <hr className="w-[100%] text-[#A4A4A4] lg:mt-[-0.2em]" />

        <div className="flex px-[5px] font-normal">
          <div className="flex flex-col px-[1em] lg:text-[16px] md:text-[16px] text-[12px] mt-[0.5em] justify-start text-left hidden md:block lg:block">
            {shortcuts.map((shortcut, index) => (
              <button
                key={index}
                onClick={() => handleShortcutClick(shortcut.range, index)}
                className={`mb-2 text-left focus:outline-none w-[150px] h-[40px] pl-[1em] ${
                  activeIndex === index ? "font-bold active" : ""
                } ${
                  activeIndex !== index
                    ? "lg:hover:bg-[#bad9f1] lg:hover:text-black md:hover:bg-[#044E97] md:hover:text-white"
                    : ""
                } ${activeIndex === index ? "lg:bg-[#044E97] lg:text-white" : ""}`}
                style={
                  startDate &&
                  endDate &&
                  shortcut.range()[0].getTime() === startDate.getTime() &&
                  shortcut.range()[1].getTime() === endDate.getTime()
                    ? { width: "150px" }
                    : {}
                }
              >
                {shortcut.label}
              </button>
            ))}
          </div>

          <div className='w-[358px] h-[334px] mt-[1em] md:mt-[1em] md:mr-[2em] md:justify-end rounded-md xl:mr-[2em] biglg:ml-[2em] lg"ml-[1em]  '>
            <DatePicker
              selected={startDate}
              onChange={handleCalendarChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              inline
              className="text-[12px]  w-[358px] mr-[1em] !important "
              wrapperClassName="w-[358px] h-[344px] "
              calendarClassName="w-[358px] h-[344px] md:mr-[1em] !important"
              popperClassName="w-[358px] h-[334px] "
            
            />
          </div>
        </div>
        <hr className="w-[100%] text-[#A4A4A4] md:mt-[2em] biglg:mt-[1.5em]" />
        <div className="flex justify-end items-center mt-[0.5em] pr-[1em] md:pb-0">
          <button
            onClick={handleClose}
            className="lg:bg-white bg-[#FFFFFF] md:bg-[#FFFFFF] border border-[#044E97] text-[#044E97] py-2 px-4 rounded ml-2 w-[156px] h-[48px] text-[16px] mr-[1em]"
          >
            Clear Filter
          </button>
          <button
            onClick={handleApplyButtonClick}
            className=" bg-[#044E97] md:bg-[#044E97] text-white py-2 px-4 rounded ml-2 w-[71px] h-[48px] text-[16px] xl:mr-[1em] md:mr-[1.7em]"
          >
            OK
          </button>
        </div>
      </div>
    </>
  );
};

export default DatePickerWithShortcuts;
