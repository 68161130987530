import * as React from "react";
import {
  getInvoice,
  toggleReoccurringInvoice,
  cancelInvoice,
  refundInvoice,
} from "../../../backend-services";
import { IInvoice } from "../../../@types";
import PageLoader from "../../../components/common/PageLoader";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Annotation } from "../../../@types/annotation";
import { ViewInvoice } from "../../../components/Sales/invoice/view/ViewInvoice";

const ViewInvoicePage: React.FC = () => {
  const { invoiceNo } = useParams();
  if (!invoiceNo) {
    throw new Error("Invoice Id is missing");
  }
  const [invoice, setInvoice] = React.useState<IInvoice>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isRefunding, setIsRefunding] = React.useState(false);
  const [areDatesValid, setAreDatesValid] = React.useState<boolean>(true);
  const [isChecked, setIsChecked] = React.useState(false);
  const [previousChecked, setPreviousChecked] = React.useState(false);
  const [annotations, setAnnotations] = React.useState<Annotation[]>([]);
  const navigate = useNavigate();

  const handleToggleReoccurringInvoice = React.useCallback(
    async ({
      isReoccurringEndDate,
      reoccurringGroupName,
    }: {
      isReoccurringEndDate: string;
      reoccurringGroupName: string;
    }) => {
      if (!areDatesValid) {
        setIsLoading(false);
        setIsChecked(false);
        setPreviousChecked(false);
        return;
      }
      try {
        const invoice = await toggleReoccurringInvoice({
          id: invoiceNo,
          isReoccurringEndDate: isReoccurringEndDate
            ? new Date(isReoccurringEndDate).toISOString()
            : undefined,
          reoccurringGroupName,
        });
        setInvoice(invoice.invoice);
        toast.success("Success! Your invoice is now set to reoccur.");
      } catch (e) {
        setIsLoading(false);
        setIsChecked(false);
        setPreviousChecked(false);
      }
    },
    [invoiceNo, areDatesValid]
  );

  const handleCancelInvoice = React.useCallback(async () => {
    setIsLoading(true);
    try {
      const invoice = await cancelInvoice({ id: invoiceNo });
      setIsLoading(false);
      setInvoice(invoice);
    } catch (e) {
      setIsLoading(false);
      toast.error("Failed to cancel invoice");
    }
  }, [invoiceNo]);

  const handleRefundInvoice = React.useCallback(async () => {
    setIsRefunding(true);
    try {
      const invoice = await refundInvoice({ id: invoiceNo });
      setIsRefunding(false);
      setInvoice(invoice);
      toast.success("Invoice refunded successfully!");
    } catch (e) {
      toast.error("Failed to refund invoice.");
    } finally {
      setIsRefunding(false);
    }
  }, [invoiceNo]);

  const fetchInvoice = React.useCallback(async () => {
    try {
      const data = await getInvoice(invoiceNo);
      setInvoice(data);
      const annotationsData =
        data?.metadata?.annotations || data?.annotations || [];
      setAnnotations(Array.isArray(annotationsData) ? annotationsData : []);
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to load invoice");
      setIsLoading(false);
    }
  }, [invoiceNo]);

  React.useEffect(() => {
    fetchInvoice();
    const intervalId = setInterval(() => {
      fetchInvoice();
    }, 30000);
    return () => clearInterval(intervalId);
  }, [fetchInvoice]);

  return (
    <div className="">
      {isLoading ? (
        <PageLoader></PageLoader>
      ) : (
        <ViewInvoice
          invoice={invoice}
          annotations={annotations}
          isRefunding={isRefunding}
          handleReOccurringInvoice={handleToggleReoccurringInvoice}
          handleCancelInvoice={handleCancelInvoice}
          handleRefundInvoice={handleRefundInvoice}
          customerId={""}
        />
      )}
    </div>
  );
};
export { ViewInvoicePage };
