import React, { useState, useEffect } from "react";
import AddModal from "../modal/AddModal";
import { CloseIcon, SearchIcon } from "../../../../assets/icons";
import { SearchInput } from "../../../common";
import { ICreateSalesData, ICustomer } from "../../../../@types";
import { useCustomerSearch } from "../../../../hooks";
import { useCustomerStore } from "../../../../state";
import { CustomerSearchInput } from "../../../common/SearchInput/customerSearch";
import { NewSearchInput } from "../../../common/SearchInput/NewSearch";
import SelectedMarkIcon from "../../../../assets/icons/SelectedIcon";

interface SelectCustomerProps {
  setModalInView: (text: string) => void;
  setIsOpen: (isOpen: boolean) => void;
  customer: ICustomer | null;
  handleSetCustomer: (selectedCustomer: ICustomer | null) => void;
  formData: ICreateSalesData;
}

const SelectCustomer: React.FC<SelectCustomerProps> = ({
  setIsOpen,
  setModalInView,
  handleSetCustomer,
  formData,
  customer,
}) => {
  const {
    data: customers,
    isSearching,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();
  const [isTyping, setIsTyping] = useState(false);
  const [canDisplayError, setCanDisplayError] = useState(false);
  const [displayedCustomers, setDisplayedCustomers] = useState<ICustomer[]>([]);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null,
  );
  const { fetchData, isLoading } = useCustomerStore();
  const data = useCustomerStore((state) => state.customers);

  useEffect(() => {
    if (data && data.length > 0) {
      //@ts-ignore
      setDisplayedCustomers(data.slice(0, 3));
    }
  }, [data]);

  const handleSearchChange = (searchText: string) => {
    setCanDisplayError(false);
    setIsTyping(true);
    (async () => {
      try {
        handleCustomerSearch(searchText);
        if (searchText.trim() === "") {
          setDisplayedCustomers(customers.slice(0, 3));
          setIsTyping(false);
        } else {
          const filteredCustomers = customers.filter((customer) =>
            `${customer.firstName} ${customer.lastName}`
              .toLowerCase()
              .includes(searchText.toLowerCase()),
          );
          setDisplayedCustomers(filteredCustomers);
          setIsTyping(false);
          await new Promise((resolve) => setTimeout(resolve, 5000));
        }
      } catch (error) {
      } finally {
        setCanDisplayError(true);
      }
    })();
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const renderCustomerItem = (customer: ICustomer) => (
    <div
      key={customer.id}
      className={`mt-2 flex justify-between items-center p-2 rounded-md cursor-pointer ${
        selectedCustomer?.id === customer.id
          ? "bg-blue-100 border-2 border-blue-500"
          : "hover:bg-gray-100"
      }`}
      onClick={() => setSelectedCustomer(customer)}
    >
      <div>
        <h5 className="font-medium text-[14px]">{`${customer.firstName} ${customer.lastName}`}</h5>
        {customer.phone && (
          <p className="text-gray-600 text-[14px]">{customer.phone}</p>
        )}
      </div>
      {selectedCustomer?.id === customer.id ? (
        <SelectedMarkIcon />
      ) : (
        <div className="rounded-full w-4 h-4 border border-gray-600" />
      )}
    </div>
  );

  return (
    <AddModal>
      <div className="flex justify-center">
        <div className="w-[60px] h-[6px] rounded-[4px] bg-gray-300" />
      </div>
      <div className="flex justify-between items-center my-4">
        <p className="text-[18px] font-semibold leading-6">Select Customer</p>
        <div onClick={() => setIsOpen(false)}>
          <CloseIcon />
        </div>
      </div>

      <NewSearchInput
        placeholder="Search"
        // data={data}
        data={displayedCustomers}
        isLoading={isSearching}
        value={
          formData.customerId
            ? customers.find(
                (cust: { id: string | undefined }) =>
                  cust.id === formData.customerId,
              )?.firstName +
                " " +
                customers.find(
                  (cust: { id: string | undefined }) =>
                    cust.id === formData.customerId,
                )?.lastName || ""
            : ""
        }
        //@ts-ignore
        // selected={customer}
        selected={selectedCustomer}
        onSelect={(selected) => setSelectedCustomer(selected as ICustomer)}
        displayKey="name"
        classNames="w-full border border-gray-300 rounded-lg focus:outline-none"
        searchKey="name"
        handleChangeText={handleSearchChange}
        icon={<></>}
        renderItem={renderCustomerItem}
      />
      {isSearching || isTyping || isLoading ? (
        <div className="mt-5 space-y-4">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="mt-5 space-y-4">
          {displayedCustomers.length > 0 ? (
            displayedCustomers.map(renderCustomerItem)
          ) : (
            <>
              {data && data.length > 0 && displayedCustomers.length > 0 ? (
                <p>Loading...</p>
              ) : canDisplayError ? (
                <p>No customers found</p>
              ) : (
                <p>Loading...</p>
              )}
            </>
          )}
        </div>
      )}
      <div className="flex justify-between gap-4">
        <button
          type="button"
          onClick={() => setModalInView("add_customer")}
          className="w-full py-[10px] text-[#044E97] border border-[#044E97] rounded-[4px] mt-8 font-semibold"
        >
          Add new customer
        </button>
        <button
          type="button"
          className="w-full py-[10px] text-[#044E97] border border-[#044E97] rounded-[4px] mt-8 font-semibold"
          onClick={() => {
            if (selectedCustomer) {
              handleSetCustomer(selectedCustomer);
              setIsOpen(false);
            }
          }}
        >
          Continue
        </button>
      </div>
    </AddModal>
  );
};

export default SelectCustomer;
