import toast from "react-hot-toast";
import { subscriptionService } from "../services";
import { handleError, withRetry } from "../utils";

interface createSubscriptionData {
  authorization_url: string;
  access_code: string;
  reference: string;
}

export const createSubscriptionPaymentLink = async (data: {
  paymentProvider: string;
  plan: string;
  callback_url: string;
}): Promise<createSubscriptionData | undefined | any> => {
  return withRetry(async () => {
    const res = await subscriptionService.post(
      `/subscriptions/payment-link?${data.callback_url}`,
      data
    );
    return res.data;
  }).catch(handleError);
};

export const createSubscription = async (data: { plan: string }) => {
  return withRetry(async () => {
    const res = await subscriptionService.post("/subscriptions", data);
    return res.data;
  }).catch(handleError);
};

export const processSubscription = async (id: number) => {
  return withRetry(async () => {
    const res = await subscriptionService.patch(`/subscriptions/${id}/process`);
    return res.data;
  }).catch(handleError);
};

export const cancelSubscription = async (id: string) => {
  return withRetry(async () => {
    const res = await subscriptionService.patch(`/subscriptions/${id}/cancel`);
    return res.data;
  }).catch(handleError);
};

export const getActiveSubscription = async () => {
  return withRetry(async () => {
    const res = await subscriptionService.get("/subscriptions/active");
    return res.data;
  }).catch(handleError);
};

export const getActiveSubscriptionWithHandling = async (query: string) => {
  return withRetry(async () => {
    const response = await subscriptionService.get(
      `/subscriptions/active?q=${query}`
    );
    const active = response.data && response.data.status === "active";

    if (active) {
      return { active: true };
    } else {
      return { active: false };
    }
  }).catch(handleError);
};

export const getSpecificSubscription = async (id: number) => {
  return withRetry(async () => {
    const res = await subscriptionService.get(`/subscriptions/${id}`);
    return res.data;
  }).catch(handleError);
};

export const getSubscriptionHistory = async () => {
  return withRetry(async () => {
    const res = await subscriptionService.get("/subscriptions/history");
    return res.data;
  }).catch(handleError);
};
