import React from "react";
import CompleteIcon from "../../../assets/icons/CompleteIcon";
import ProgressBar from "./ProgressBar";
import { isCompleted } from "../../../utils/todoSection";

interface ToDoCardProps {
  icon: React.ComponentType<{ color: string }>;
  title: string;
  percent: number;
  onClick: () => void;
}

const ToDoCard: React.FC<ToDoCardProps> = ({
  icon: Icon,
  title,
  percent,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={`${
      isCompleted(percent) ? "bg-[#ECFDF3]" : "bg-[#FFF8EC] cursor-pointer"
    } py-5 px-3 w-[170px] rounded-lg flex-none md:w-full md:flex-auto text-[14px] font-medium`}
  >
    <Icon color={isCompleted(percent) ? "#039855" : "#CC4802"} />
    <p
      className={`${
        isCompleted(percent) ? "text-[#000000] mb-4" : "text-[#A1390B] mb-6"
      } leading-5 mt-2`}
    >
      {title}
    </p>
    <ProgressBar percentage={percent} />
  </div>
);

export default ToDoCard;
