import React, { useState, useEffect } from "react";
import { SearchIcon } from "../../../assets/icons";
import SelectedMarkIcon from "../../../assets/icons/SelectedIcon";

interface SearchInputProps<T> {
  data: Array<T>;
  displayKey: keyof T;
  onSelect?: (item: T | null) => void;
  isLoading?: boolean;
  placeholder?: string;
  classNames?: string;
  value?: string;
  selected?: T | null;
  handleChangeText?: (text: string) => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
  searchKey: keyof T | Array<keyof T>;
  renderItem?: (item: T) => React.ReactNode;
  defaultItemCount?: number;
}

const NewSearchInput = <T extends Record<string, any>>({
  data,
  displayKey,
  onSelect,
  isLoading,
  placeholder,
  classNames,
  selected,
  handleChangeText,
  style,
  icon,
  searchKey,
  value = "",
  renderItem,
  defaultItemCount = 3,
}: SearchInputProps<T>) => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<T[]>([]);

  useEffect(() => {
    if (data.length > 0) {
      setFilteredData(data.slice(0, defaultItemCount));
    } else {
      setFilteredData([]);
    }
  }, [data, defaultItemCount]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchText(value);
    handleChangeText && handleChangeText(value);

    if (value.trim() === "") {
      setFilteredData(data.slice(0, defaultItemCount));
    } else {
      const keys = Array.isArray(searchKey) ? searchKey : [searchKey];
      const filtered = data.filter((item) =>
        keys.some((key) =>
          String(item[key]).toLowerCase().includes(value.toLowerCase()),
        ),
      );
      setFilteredData(filtered.slice(0, defaultItemCount));
    }
  };

  const handleItemClick = (item: T, event: React.MouseEvent) => {
    onSelect && onSelect(item);
    // setSearchText(String(item[displayKey]));

    event.stopPropagation();
  };

  const handleClearSelection = () => {
    setSearchText("");
    onSelect && onSelect(null);
    setFilteredData(data.slice(0, defaultItemCount));
  };

  return (
    <div className="relative" style={style}>
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        {icon ? icon : <SearchIcon className="text-gray-500" />}
      </span>
      <input
        type="text"
        placeholder={placeholder}
        value={
          selected && selected[displayKey]
            ? String(selected[displayKey])
            : searchText
        }
        onChange={handleInputChange}
        className={`placeholder:text-[#A4A4A4] ${icon ? "pl-[2.25em]" : ""} ${
          classNames ||
          "border border-[#138EFF] md:h-[48px] h-[35px] rounded-md lg:placeholder:text-[16px] placeholder:text-[14px] lg:p-2 lg:mt-[5px] lg:leading-normal"
        }`}
        disabled={Boolean(selected)}
      />

      {isLoading && (
        <div className="absolute inset-y-0 right-6 flex items-center">
          <div className="w-5 h-5 border-2 border-gray-200 border-t-[#044E97] rounded-full animate-spin"></div>
        </div>
      )}

      <div className="mt-2">
        {filteredData.map((item, index) => (
          <div className="flex justify-between cursor-pointer  hover:bg-gray-100">
            <div
              key={index}
              onClick={(e) => handleItemClick(item, e)}
              className=" "
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { NewSearchInput };
