import React, { useEffect, useState } from "react";
import { expenseService, invoiceService } from "../../../services";
import { IInvoice, TransactionType } from "../../../@types";
import { WidgetIcon } from "../../../assets/icons";
import TransactionItem from "./TransactionItem";
import { DocumentIcon } from "../../../assets/icons/DocumentIcon";
import DueInvoices from "./DueInvoices";
import { ChevronLeftIcon } from "../../../assets/icons/ChevronLeftIcon";
import { ChevronRightIcon } from "../../../assets/icons/ChevronRightIcon";
import EmptyTransaction from "./EmptyTransaction";
import { ImSpinner } from "react-icons/im";
import { useTranslation } from "react-i18next";

const CardWithSlideshow = () => {
  const { t } = useTranslation("dashboard");
  const [recentTransactions, setRecentTransactions] = useState<
    TransactionType[]
  >([]);
  const [dueInvoices, setDueInvoices] = useState<IInvoice[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [errorInvoice, setErrorInvoice] = useState<string | null>(null);
  const [isLoadingTransaction, setIsLoadingTransaction] =
    useState<boolean>(false);
  const [loadingDueInvoice, setLoadingDueInvoice] = useState<boolean>(false);

  const fetchTransactions = async (startDate: Date, endDate: Date) => {
    setIsLoadingTransaction(true);
    try {
      const [invoices, expenses] = await Promise.all([
        invoiceService.get(`/invoices?limit=${3}`),
        expenseService.get(`/expenses?limit=${3}`),
      ]);

      // Combine invoices and expenses into a single array
      const mergedTransactions: TransactionType[] = [
        ...invoices.data.data.map((invoice: any) => ({
          data: { ...invoice, type: "invoice" },
        })),
        ...expenses.data.data.map((expense: any) => ({
          data: { ...expense, type: "expense" },
        })),
      ];

      const sortedTransactions = mergedTransactions.sort((a, b) => {
        //@ts-ignore
        const timestampA = new Date(a.data.createdAt).getTime();
        //@ts-ignore
        const timestampB = new Date(b.data.createdAt).getTime();
        return timestampB - timestampA;
      });
      const recentTransactions = sortedTransactions.slice(0, 5);

      setRecentTransactions(recentTransactions);
      setIsLoadingTransaction(false);
    } catch (error) {
      setError("Error fetching transactions.");
      setIsLoadingTransaction(false);
    }
  };

  const fetchDueInvoices = async () => {
    setLoadingDueInvoice(true);
    try {
      const res = await invoiceService.get(`/invoices?status=due`);
      const data = res.data.data.slice(0, 4);
      setDueInvoices(data);
      setLoadingDueInvoice(false);
    } catch (error) {
      setErrorInvoice("Error fetching transactions.");
      setLoadingDueInvoice(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    fetchTransactions(today, today);
    fetchDueInvoices();
  }, []);

  const views = [
    {
      id: 1,
      name: `${t("recentTransactions")}`,
      icon: <WidgetIcon />,
      content: (
        <div>
          {isLoadingTransaction ? (
            <div className="h-[300px] w-full flex justify-center items-center">
              <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
            </div>
          ) : recentTransactions.length > 0 ? (
            <div className="pb-[]">
              {recentTransactions.map(({ data }: any, index) => (
                <TransactionItem key={data.id} data={data} />
              ))}
            </div>
          ) : (
            <EmptyTransaction text="You do not have any transactions yet." />
          )}
        </div>
      ),
    },
    {
      id: 2,
      name: "Due Invoices",
      icon: <DocumentIcon />,
      content: (
        <div>
          {loadingDueInvoice ? (
            <div className="h-[300px] w-full flex justify-center items-center">
              <ImSpinner className="h-16 w-20 mx-auto text-slate-300 animate-spin" />
            </div>
          ) : dueInvoices.length > 0 ? (
            <div className="flex flex-col gap-8 mt-4">
              {dueInvoices.map((invoice) => (
                <DueInvoices key={invoice.id} invoice={invoice} />
              ))}
            </div>
          ) : (
            <EmptyTransaction text="You have punctual customers! No overdue invoices." />
          )}
        </div>
      ),
    },
  ];

  const [currentViewIndex, setCurrentViewIndex] = useState(0);

  const handleNext = () => {
    setCurrentViewIndex((prevIndex: number) => (prevIndex + 1) % views.length);
  };

  const handlePrevious = () => {
    setCurrentViewIndex(
      (prevIndex: number) => (prevIndex - 1 + views.length) % views.length
    );
  };

  const { name, icon, content } = views[currentViewIndex];

  return (
    <div className="rounded-[4px] bg-white overflow-hidden">
      <div className="flex justify-between items-center py-4 pr-[20px] pl-[18px] ">
        <div className="flex items-center">
          {icon}
          <h5 className="ml-2 text-lg font-normal text-gray-900">{name}</h5>
        </div>
        <div className="flex gap-4">
          <button
            onClick={handlePrevious}
            className="px-2 py-[5px] rounded-[2px] border-[0.5px] border-[#A4A4A4]"
          >
            <ChevronLeftIcon />
          </button>
          <button
            onClick={handleNext}
            className="px-2 py-[5px] rounded-[2px] border-[0.5px] border-[#A4A4A4]"
          >
            <ChevronRightIcon />
          </button>
        </div>
      </div>
      <div className="px-4 pt-[4px] pb-[17px]">{content}</div>
    </div>
  );
};

export { CardWithSlideshow };
