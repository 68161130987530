import React, { useState } from "react";
import { ICreateSalesData, ItemInput } from "../@types";

interface InvoiceItemErrors {
  [key: string]: string | undefined;
}

export type InvoiceErrors = {
  [P in keyof ICreateSalesData]?: string;
} & {
  items?: InvoiceItemErrors;
};

export const validateQuoteBasicInfo = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (!formData[field]) {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  validateField("customerId", "Please enter customers name");
  validateField("issueDate", "Issue Date is required");
  validateField("expiryDate", "Select a due date");

  const issueDate = formData.issueDate ? new Date(formData.issueDate) : null;
  const expiryDate = formData.expiryDate ? new Date(formData.expiryDate) : null;

  if (issueDate && expiryDate) {
    if (expiryDate < issueDate) {
      errors.expiryDate = "Expiry Date cannot be less than the Issue Date";
      isError = true;
    } else {
      delete errors.expiryDate;
    }
  }

  setValidationResults(errors as InvoiceErrors);
  // setValidationResults((prevErrors) => ({ ...prevErrors, ...errors }));
  // setValidationResults((prevErrors) => {
  //   console.log("Previous errors:", prevErrors);  // Log previous errors
  //   console.log("New errors:", errors);  // Log new errors
  //   return { ...prevErrors, ...errors };
  // });
  setValidationResults((prevErrors) => {
    return { ...prevErrors, ...errors };
  });
  return !isError;
};

export const validateInventoryItem = (
  item: ItemInput,
  index: number,
  errors: InvoiceErrors
): boolean => {
  ["id", "rate", "quantity"].forEach((field) => {
    if (!item[field]) {
      errors.inventories += ` Inventory ${index + 1} is missing ${field}.`;
      return false;
    }
  });

  if (item.quantity !== undefined && item.quantity === 0) {
    errors.inventories += ` Inventory ${
      index + 1
    } quantity should be greater than zero.`;
    return false;
  }

  // Add variation validation
  if (item.variations && item.variations.length > 0) {
    item.variations.forEach((variation, variationIndex) => {
      if (!variation.type) {
        errors.inventories += ` Inventory ${index + 1} variation ${
          variationIndex + 1
        } is missing type or value.`;
        return false;
      }
    });
  }

  return true;
};

export const validateServiceItem = (
  service: ItemInput,
  index: number,
  errors: InvoiceErrors
): boolean => {
  ["id", "duration", "price"].forEach((field) => {
    if (!service[field]) {
      errors.services += ` Service ${index + 1} is missing ${field}.`;
      return false;
    }
  });

  return true;
};

export const validateQuoteItemDetails = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>,
  isRetail?: boolean
): boolean => {
  const errors: InvoiceErrors = { inventories: "", services: "" };

  if (isRetail) {
    if (
      !formData.inventories ||
      formData.inventories.length === 0 ||
      !formData.inventories[0].id
    ) {
      errors.inventories = "Please add at least one item to your quote";
    } else {
      formData.inventories.forEach((item: ItemInput, index: number) => {
        if (!item.id) {
          errors.inventories = "One or more inventories are out of stock";
        } else {
          if (item.stockStatus === "out-of-stock") {
            errors.inventories = "One or more inventories are out of stock";
          }
          const inventoryValidationResult = validateInventoryItem(
            item,
            index,
            errors
          );
          if (!inventoryValidationResult) {
            errors.inventories = "Inventory is missing quantity";
          }
        }
      });
    }
  } else {
    // Validate services
    if (
      !formData.services ||
      formData.services.length === 0 ||
      !formData.services[0].id
    ) {
      errors.services = "Add at least one service";
    } else {
      formData.services.forEach((service: ItemInput, index: number) => {
        if (!validateServiceItem(service, index, errors)) {
          // If there is an error in service validation, set the main error
          errors.services = "One or more services have validation errors";
        }
      });
    }
  }

  setValidationResults(errors as InvoiceErrors);

  // Return true if there are no errors, otherwise, return false
  return Object.values(errors).every((error) => !error);
};

export const validateQuoteSummary = (
  formData: ICreateSalesData,
  setValidationResults: React.Dispatch<React.SetStateAction<InvoiceErrors>>
): boolean => {
  let isError = false;
  const errors: InvoiceErrors = {};

  const validateField = (field: keyof ICreateSalesData, message: string) => {
    if (!formData[field]) {
      errors[field] = message;
      isError = true;
    } else {
      delete errors[field];
    }
  };

  setValidationResults(errors as InvoiceErrors);
  return !isError;
};
