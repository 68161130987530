import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../../../../assets/icons";
import { copyPaymentLink, getBankAccounts } from "../../../../backend-services";
import { useUserStore } from "../../../../state";
import { BankData, Customer } from "../../../../types";
import { formatMoney } from "../../../../utils";
import {
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "../../../core/Forms";

interface GeneratePaymentPromptProps {
  onGenerate: (event: any) => void;
  onClose?: () => void;
  showPaymentPrompt: boolean;
  showVerificationPrompt: (show: boolean) => void;
  showFailedVerificationPrompt: (show: boolean) => void;
  showFailedRCVerificationPrompt: (show: boolean) => void;
  customer: Customer;
  balanceDue: string;
  currency: string;
  invoiceId: string | undefined;
  bankAccounts: BankData;
}
const GeneratePaymentPrompt: React.FC<GeneratePaymentPromptProps> = ({
  onGenerate,
  onClose,
  showPaymentPrompt,
  customer,
  balanceDue,
  currency,
  invoiceId,
  // bankAccounts,
  showVerificationPrompt,
  showFailedVerificationPrompt,
  showFailedRCVerificationPrompt,
}) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const bankAccount = user.bankAccounts || [];
  const [error, setError] = useState<string>("");
  const [showInput, setShowInput] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankData[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<string>("");
  const [selectedAccountLabel, setSelectedAccountLabel] = useState<string>("");

  useEffect(() => {
    const fetchBankAccounts = async () => {
      try {
        const accounts = await getBankAccounts();
        setBankAccounts(accounts);
        const firstValidatedAccount = accounts.find(
          (account: BankData) => account.isValidated
        );
        if (firstValidatedAccount) {
          const formattedLabel = formatAccountLabel(firstValidatedAccount);
          setSelectedAccount(firstValidatedAccount.subaccountId);
          setSelectedAccountLabel(formattedLabel);
        }
      } catch (error) {
        setError("Failed to fetch bank accounts");
      }
    };

    fetchBankAccounts();
  }, []);

  const handleGenerateLink = async () => {
    setIsLoading(true);
    try {
      const data = {
        invoiceId: invoiceId || "",
        paymentProvider: "paystack",
        amount: 0,
        subaccountId: selectedAccount,
        type: "invoice",
      };
      if (showInput) {
        const amountInput = document.querySelector(
          'input[name="amount"]'
        ) as HTMLInputElement;
        data.amount = amountInput ? parseFloat(amountInput.value) : 0;
      } else {
        data.amount = parseFloat(balanceDue);
      }
      const response = await copyPaymentLink(data);
      const paymentLink = response.authorization_url;

      await navigator.clipboard.writeText(paymentLink);

      setError("");
      toast.success("Payment link copied to clipboard.");
    } catch (error) {
      setError("Error generating payment link. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const formatAccountLabel = (account: {
    accountNumber: string;
    bankName: string;
    accountName: string;
  }) =>
    `${account.accountNumber} - ${account.bankName} - ${account.accountName}`;

  const dropdownOptions = bankAccount
    .filter((account: { isValidated: boolean }) => account.isValidated)
    .map(
      (account: {
        accountNumber: string;
        bankName: string;
        accountName: string;
        subaccountId: string;
      }) => ({
        label: formatAccountLabel(account),
        value: account.subaccountId,
      })
    );

  const handleDropDownChange = (name: string, value: string) => {
    const selectedOption = dropdownOptions.find(
      (option: { value: string }) => option.value === value
    );
    if (selectedOption) {
      setSelectedAccountLabel(selectedOption.label);
      setSelectedAccount(selectedOption.value);
    }
  };

  return (
    <>
      <div className="p-[32px] bg-[#ffffff] rounded-[16px] xl:max-h-[748px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[500px] w-[704px] xl:w-[506px] 2xl:w-[506px] biglg:w-[506px] lg:w-[506px] overflow-y-auto">
        <div className="flex justify-between">
          <h1 className="xl:text-[25px] biglg:text-[25px] 2xl:text-[25px] lg:text-[20px] text-[14px] font-bold  mb-4">
            Generate Payment Link
          </h1>
          <button
            onClick={onClose}
            className=" rounded-md focus:outline-none mt-[-1em] py-1  text-[black] shadow-sm bg-[#F4F7FF] pr-3"
          >
            <CloseIcon />
          </button>
        </div>
        <hr className="mt-[1em]" />
        <div className=" flex justify-between lg:text-[16px] mt-5 mb-4">
          <h1>Customer Name</h1>
          <p className="font-semibold">
            {customer.firstName} {customer.lastName}
          </p>
        </div>
        <div className=" flex justify-between lg:text-[16px] mt-5 mb-4">
          <h1>Invoice amount</h1>
          <p className="font-semibold">{formatMoney(balanceDue, currency)}</p>
        </div>
        <div className=" flex justify-between lg:text-[16px] mt-5 mb-4">
          <h1>Payment Provider</h1>
          <p className="font-semibold">Paystack</p>
        </div>
        <div className=" flex justify-between lg:text-[16px] mt-5 mb-4">
          <h1>Payment Charges</h1>
          <p className="text-[#044E97] font-semibold">
            {currency === "NGN"
              ? "1.5% + NGN 100 on Local Transactions"
              : "3.9% + NGN 100 on International"}
          </p>
        </div>
        <div role="form" onSubmit={(e) => e.preventDefault()}>
          <div className="py-1 shadow-sm bg-[#F4F7FF] pl-2 pr-4 rounded-md">
            <div className="text-[16px] text-[black] font-normal flex justify-between gap mt-7 mb-5  ">
              Allow Part Payment
              <CheckboxComponent
                label=""
                onChange={(e) => setShowInput(e.target.checked)}
                name=""
              />
            </div>
            {showInput && (
              <div className="lg:text-[16px]">
                Cost Price
                <InputComponent
                  placeholder=""
                  type="text"
                  name="amount"
                  className="w-full rounded-md  md:w-[634px] xl:w-[424px] 2xl:w-[424px] mb-6 lg:w-[424px] "
                />
              </div>
            )}
          </div>
          <div className="text-[16px] text-[black] font-normal  mt-3 mb-5">
            This is the account we would make payment to below
          </div>
          <div className="w-[370px]  md:w-[634px] biglg:w-[434px] lg:w-[434px] border border-gray-300 bg-white xl:w-[434px] 360:w-[310px] 375:w-[320px] 414:w-[350px] 390:w-[330px] 430:w-[350px] 412:w-[350px] rounded">
            <DropdownComponent
              options={dropdownOptions}
              value={selectedAccount}
              selectedValue={selectedAccountLabel}
              setSelectedValue={setSelectedAccount}
              onChange={handleDropDownChange}
              placeholder="Select Account"
              dropdownClassName="w-[370px] md:w-[634px] biglg:w-[434px] lg:w-[434px] border border-gray-300 bg-white xl:w-[434px] 2xl:w-[434px] 360:w-[310px] 375:w-[320px] 414:w-[350px] 390:w-[330px] 430:w-[350px] 412:w-[350px] rounded"
              iconClassName="375:mr-[1em] 414:mr-[1em] 412:mr-[1em] md:mr-[1em] xl:mr-[-0.5em] lg:mr-[-0.5em] biglg:mr-[-0.5em] 375:mr-[1em] bigmd:mr-[1em]"
              optionStyles={{
                label: {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
          </div>
          <hr className="mt-5" />
          <div className="flex ml-[10px] pt-[39px] justify-between gap-2">
            <button
              className="w-[220px] rounded-[4px] h-[55px] bg-[white] text-black border border-[black] font-semibold"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="w-[220px] rounded-[4px] h-[55px] bg-[#FF6702] text-white font-semibold"
              onClick={handleGenerateLink}
              disabled={isLoading}
            >
              {isLoading ? "Generating..." : "Copy Payment Link"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { GeneratePaymentPrompt };
