import React, { Dispatch, SetStateAction, useState } from "react";
import {
  DropdownComponent,
  InputComponent,
} from "../../../components/core/Forms";
import { useReportStore } from "../../../state/useReportStore";
import { Button, SearchInput } from "../../../components/common";
import { CloseIcon, SearchIcon } from "../../../assets/icons";
import { useExpenseSearch, useInventorySearch } from "../../../hooks";
import { IInventory, ItemInput } from "../../../@types";
import { useTranslation } from "react-i18next";

interface InventoryFilterProps {
  handleOnChange: (e: any) => void;
  formData: any;
  item: ItemInput;
  setFormData: Dispatch<
    SetStateAction<{
      vendorId?: string;
      customerId?: string;
      inventoryId?: string;
      startDate: string;
      endDate: string;
      status?: string;
      paymentMethod?: string;
    }>
  >;
}

const InventoryFilterForm = ({
  handleOnChange,
  formData,
  setFormData,
  item,
}: InventoryFilterProps) => {
  const { t } = useTranslation("report");
  const [text, setText] = React.useState("");
  const [avaliability, setAvaliability] = useState(
    t("report.selectAvailability")
  );
  const [variation, setVariation] = useState(t("report.selectVariation"));
  const [selectedVariation, setSelectedVariation] = React.useState<string>("");
  const {
    data: customers,
    isSearching,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();
  const handleInventorySearchDebounced = React.useCallback(
    handleInventorySearch,
    []
  );
  const handleInventorySelect = (selectedInventory: IInventory | null) => {
    if (selectedInventory) {
      if (selectedInventory.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          inventoryId: selectedInventory.id,
        }));
      }
    }
  };
  const selectedInventoryVariations = item.variations || [];

  const variationTypesOptions = selectedInventoryVariations.map(
    (variation) => ({
      value: variation.type,
      label: variation.type,
    })
  );

  const handleVariationSelect = (selectedOption: any) => {
    setVariation(selectedOption.value);
  };
  return (
    <div className="flex flex-col gap-[16px] md:gap-[16px] md:flex-row justify-between  pt-[20px] xl:pt-[33px]">
      <div className="280:flex 280:flex-col 280:justify-center 350:block md:w-[276px] lg:w-[209px]">
        <label>{t("report.byAvailability")}</label>
        <SearchInput
          placeholder={"Search by Availability"}
          displayKey={"name"}
          isLoading={isSearching}
          value={text}
          clearIcon={CloseIcon}
          onSelect={handleInventorySelect}
          handleChangeText={handleInventorySearchDebounced}
          data={customers.map((cu: any) => ({
            ...cu,
            display: `${cu.name}`,
          }))}
          emptyMessage={"This Item is not found"}
          dropdownClassName="lg:ml-0 w-[100%] xsmd:w-[576px] xl:w-[434px] cursor-pointer"
          dropdownStyle={{
            maxHeight: "800px",
            overflowY: "auto",
          }}
          clearIconClass="xl:mr-[1em] mr-[0.47em] md:mr-[3.2em] biglg:mr-[2em] biglg:mb-[4em]"
          classNames="w-[100%] xmd:w-[209px] border border-gray-300 bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px]  rounded p-2  focus:outline-none focus:shadow-outline appearance-none focus:ring-0 leading-normal"
          searchKey={"name"}
          icon={<SearchIcon />}
        />
      </div>

      <div className="280:flex 280:flex-col 280:justify-center 350:block md:w-[276px] lg:w-[209px]">
        <label className="tex whitespace-nowrap">
          {t("report.byVariation")}
        </label>

        <DropdownComponent
          options={variationTypesOptions}
          value={variation}
          onChange={handleVariationSelect}
          selectedValue={selectedVariation}
          setSelectedValue={setSelectedVariation}
          placeholder="Select "
          name="type"
          dropdownClassName="w-[100%] md:w-[276px] lg:w-[209px] cursor-pointer"
          iconClassName="mr-[2em] sm:mr-[7em] md:mr-[1.5em]"
        />
      </div>
    </div>
  );
};

export default InventoryFilterForm;
