import React from "react";
import PlusIcon from "../../../assets/icons/PlusIcon";
import { ListInfoCard, ListInfoCardProps } from "./ListInfoCard";
import { useTranslation } from "react-i18next";
import { useUserStore } from "../../../state";
import { Calendar } from "../../../assets/icons/calendar";
import { isNotOperator } from "../../../utils/isNotOperator";
import ListToggleButton from "../../common/ListToggleButton";
import BudgetHistoryWidget from "../../expense/BudgetHistoryWidget";
import { useLocation, useNavigate } from "react-router-dom";

interface ListOverviewProps {
  showCalendar?: boolean;
  filterByDate?: boolean;
  handleCalendarToggle?: () => void;
  selectedLabel?: string;
  handleCreate: () => void;
  infoCardData: ListInfoCardProps[];
  showReOccurring?: boolean;
  showBudgetCard?: boolean;
  createTitle?: string;
  modules: string;
  reOccurringText?: string;
}

const ListOverview: React.FC<ListOverviewProps> = ({
  showCalendar,
  handleCalendarToggle,
  selectedLabel,
  handleCreate,
  infoCardData,
  showReOccurring,
  showBudgetCard,
  createTitle,
  filterByDate = true,
  modules,
  reOccurringText,
}) => {
  const { roles } = useUserStore();
  const [isInfoCardVisible, setIsInfoCardVisible] = React.useState(true);
  const label = selectedLabel ? selectedLabel : "";
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    const path = location.pathname;
    if (path === "/inventories") {
      navigate("/inventories/archive");
    }
  };

  return (
    <>
      {modules !== "quotes" && (
        <div className="border border-gray-200 rounded-xl">
          <div
            className={`flex  items-center p-3 ${
              showReOccurring
                ? "md:justify-end lg:justify-between"
                : "justify-between"
            }`}
          >
            <h1 className="hidden lg:block leading-6 text-[20px] lg:font-semibold">
              Overview
            </h1>
            {!showReOccurring && (
              <h1 className="hidden md:block lg:hidden leading-6 text-[20px] lg:font-semibold">
                Overview
              </h1>
            )}

            <div className="flex lg:items-center gap-4">
              <ListToggleButton
                isInfoCardVisible={isInfoCardVisible}
                setIsInfoCardVisible={setIsInfoCardVisible}
              />

              {filterByDate && (
                <div
                  className={`relative hidden cursor-pointer justify-start md:justify-end ml-4 md:flex`}
                >
                  <span
                    className="mr-[-2em] mt-[0.9em] z-0"
                    onClick={handleCalendarToggle}
                  >
                    <Calendar />
                  </span>
                  <div onClick={handleCalendarToggle}>
                    <div
                      className={`pt-[0.75em] bg-white  text-gray-800 text-[16px] border border-gray-300 h-[48px] rounded-md z-0  pl-10 ${
                        label.length > 20
                          ? "w-[260px]"
                          : label.length > 7
                            ? "w-[220px]"
                            : "w-[174px]"
                      }`}
                    >
                      {selectedLabel}
                    </div>
                  </div>
                </div>
              )}

              {showReOccurring && (
                <button
                  onClick={handleNavigate}
                  className="border border-gray-300 font-semibold text-[16px] hidden lg:block rounded-[4px] text-gray-700 p-3"
                >
                  {reOccurringText}
                </button>
              )}
              {showReOccurring && (
                <button
                  onClick={handleNavigate}
                  className="border border-gray-300 font-medium text-[16px] lg:hidden rounded-[4px] text-gray-700 p-3"
                >
                  {reOccurringText}
                </button>
              )}

              {createTitle && (
                <button
                  className="bg-[#044E97] font-semibold text-[16px] h-[48px] rounded-[4px] text-[#ffffff] p-3 flex items-center gap-2"
                  onClick={handleCreate}
                >
                  <PlusIcon color="white" />
                  {createTitle}
                </button>
              )}
            </div>
          </div>

          {isInfoCardVisible && <hr className="border border-gray-200" />}

          {/* Desktop */}
          {isInfoCardVisible && isNotOperator(roles) && (
            <div className="lg:flex gap-4 hidden p-5 w-full">
              <div
                className={`lg:flex gap-4 hidden ${
                  showBudgetCard ? "w-[50%]" : "w-full"
                }`}
              >
                {infoCardData.map((cardData, index) => (
                  <ListInfoCard key={index} {...cardData} />
                ))}
              </div>
              {showBudgetCard && <BudgetHistoryWidget />}
            </div>
          )}

          {/* tablet */}
          {isInfoCardVisible && isNotOperator(roles) && (
            <div
              className={`${
                showBudgetCard ? "md:flex gap-4" : "md:block"
              } hidden lg:hidden p-5 w-full`}
            >
              <div
                className={`flex gap-4 ${
                  showBudgetCard ? "w-[50%]" : "w-full"
                } `}
              >
                {infoCardData.slice(0, 2).map((cardData, index) => (
                  <ListInfoCard key={index} {...cardData} />
                ))}
              </div>
              {infoCardData.length === 4 && (
                <div className=" hidden md:flex lg:hidden gap-4 mt-6 w-full">
                  {infoCardData.slice(2, 4).map((cardData, index) => (
                    <ListInfoCard key={index} {...cardData} />
                  ))}
                </div>
              )}
              {showBudgetCard && <BudgetHistoryWidget />}
            </div>
          )}
        </div>
      )}
      {modules == "quotes" && (
        <div className="w-full">
          <div className="flex items-end w-full flex-1">
            {createTitle && (
              <button
                className="bg-[#044E97] font-semibold text-[16px] h-[48px] rounded-[4px] text-[#ffffff] p-3 flex items-center gap-2 ml-auto"
                onClick={handleCreate}
              >
                <PlusIcon color="white" />
                {createTitle}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ListOverview;
