import React from "react";

export const ClipIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 10L15 8C16.3807 6.61929 16.3807 4.38071 15 3V3C13.6193 1.61929 11.3807 1.61929 10 3L8 5M5 8L3 10C1.61929 11.3807 1.61929 13.6193 3 15V15C4.38071 16.3807 6.61929 16.3807 8 15L10 13"
        stroke="#044E97"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const ClipSmallIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.91797 11.0827L11.0846 6.91602"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M13.0386 11.1746L15.2132 9C16.9289 7.28427 16.9289 4.50252 15.2132 2.7868C13.4975 1.07107 10.7157 1.07107 9 2.7868L6.82538 4.96142M11.1746 13.0386L9 15.2132C7.28427 16.9289 4.50252 16.9289 2.7868 15.2132C1.07107 13.4975 1.07107 10.7157 2.7868 9L4.96142 6.82538"
        stroke="#344054"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
