import React, { useState, useEffect } from "react";
import AddModal from "../modal/AddModal";
import { Button } from "../../../common";
import { useInventoryStore } from "../../../../state/useInventoryStore";
import { NewSearchInput } from "../../../common/SearchInput/NewSearch";
import { ICreateSalesData, IInventory } from "../../../../@types";
import { useInventorySearch } from "../../../../hooks";
import ShowSelectedItem, { Vary } from "./ShowSelectedItem";
import SelectedMarkIcon from "../../../../assets/icons/SelectedIcon";
import toast from "react-hot-toast";
import { formatCurrencyMoney } from "../../../../utils";

interface SelectItemProps {
  setIsOpen: (isOpen: boolean) => void;
  setOpenAddInventory: (isOpen: boolean) => void;
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  setShowViewSelection: (isOpen: boolean) => void;
  setSelectedInventoryAndQuantities: (
    inventory: IInventory | null,
    updatedQuantities: { [key: string]: string },
  ) => void;
}

const SelectItem = ({
  setIsOpen,
  setOpenAddInventory,
  formData,
  setFormData,
  setShowViewSelection,
  setSelectedInventoryAndQuantities,
}: SelectItemProps) => {
  const [displayedInventories, setDisplayedInventories] = useState<
    IInventory[]
  >([]);
  const [selectedInventory, setSelectedInventory] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false); // Loading state

  const inventories = useInventoryStore((state) => state.inventory);
  const fetchData = useInventoryStore((state) => state.fetchData);
  const {
    data: searchResult,
    isSearching,
    handleSearch: handleInventorySearch,
  } = useInventorySearch();
  const [showItems, setShowItems] = useState(false);

  useEffect(() => {
    const fetchInventories = async () => {
      setIsLoading(true);
      await fetchData();
      setIsLoading(false);
    };

    fetchInventories();
  }, [fetchData]);

  useEffect(() => {
    if (!setIsOpen) {
      setSelectedInventory(null);
      setDisplayedInventories(inventories);
    }
  }, [setIsOpen, inventories]);

  useEffect(() => {
    if (searchResult && searchResult.length > 0) {
      setDisplayedInventories(searchResult);
    }
  }, [searchResult]);

  const handleSelectInventory = (selectedInventory: any) => {
    if (
      selectedInventory?.realQuant == 0 &&
      selectedInventory?.variations == undefined
    ) {
      toast("No Stock Available!");
    } else {
      const { realQuant, ...rest } = selectedInventory;
      setSelectedInventory(rest);
      setShowItems(true);
    }
  };

  const renderInventoryItem = (item: any) => {
    const { name, variations, price, isOutOfStock } = item;
    const hasVariations = variations?.length > 0;
    const isSelected =
      selectedInventory?.id === item.id ||
      formData.inventories.find((i) => i.id == item.id);

    const priceRange = hasVariations
      ? `${formatCurrencyMoney(Math.min(...variations.map((v: { price: number }) => v.price)), formData.exchangeRate, formData.currency)} - ${formatCurrencyMoney(Math.max(...variations.map((v: { price: number }) => v.price)), formData.exchangeRate, formData.currency)}`
      : null;

    return (
      <div
        key={item.id}
        onClick={() =>
          handleSelectInventory({
            id: item.id,
            name: item.name,
            amount: 0,
            quantity: 0,
            rate: item.rate,
            realQuant: item.quantity,
            variations:
              item.variations.length > 0
                ? // @ts-ignore
                  item.variations.map((i: Vary) => {
                    const { costPrice, id, name, price, quantity, type } = i;
                    return {
                      costPrice,
                      id,
                      name,
                      price,
                      type,
                      quantity: 0,
                    };
                  })
                : undefined,
          })
        }
        className="p-2 border border-gray-200 rounded-lg flex justify-between items-center cursor-pointer hover:shadow-md transition w-[100%] mb-3"
      >
        <div className="font-medium flex flex-col justify-start">
          <p className="text-[14px] leading-5 text-[#000000]">{name}</p>
          {hasVariations ? (
            <p className="text-[12px] leading-4 text-gray-600 my-1">
              {variations.length} variations
            </p>
          ) : (
            <>
              <p className="text-[12px] leading-4 text-gray-600 my-1">
                No variations
              </p>
              {item.quantity == 0 && (
                <p className="text-[8px] leading-2 text-[#B42318]">
                  Out of stock
                </p>
              )}
            </>
          )}
        </div>
        <div className="flex items-end justify-end gap-3">
          {priceRange ? (
            <p className="font-semibold text-[16px] leading-6 text-[#000000]">
              {priceRange}
            </p>
          ) : (
            <p className="font-semibold text-[16px] leading-6 text-[#000000]">
              {item?.rate
                ? `${formatCurrencyMoney(item.rate, formData.exchangeRate, formData.currency)}`
                : "N/A"}
            </p>
          )}
          {isSelected ? (
            <SelectedMarkIcon />
          ) : (
            <div className="rounded-full w-4 h-4 border border-gray-600" />
          )}
        </div>
      </div>
    );
  };

  const handleSearchChange = (searchText: string) => {
    handleInventorySearch(searchText);
    const filteredInventories = inventories.filter((inventory) =>
      inventory.name.toLowerCase().includes(searchText.toLowerCase()),
    );
    setDisplayedInventories(filteredInventories);
  };

  const closeModal = () => {
    setShowItems(false);
    setSelectedInventory(null);
  };
  return (
    <>
      <AddModal>
        <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
          <div>
            <div className="flex justify-between items-center mb-5 pb-2">
              <div onClick={() => setIsOpen(false)}>
                <svg
                  className="mt-[-2px] cursor-pointer"
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.5 1L1.5 8L8.5 15"
                    stroke="#33363F"
                    strokeWidth="2"
                  />
                </svg>
              </div>
              <div className="w-full flex justify-center">
                <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                  Select Item
                </p>
              </div>
            </div>

            <NewSearchInput
              placeholder="Search"
              data={inventories}
              isLoading={isSearching}
              classNames="w-full border border-gray-300 rounded-lg focus:outline-none"
              searchKey="name"
              handleChangeText={handleSearchChange}
              displayKey={"name"}
              icon={<></>}
              value={selectedInventory ? selectedInventory.name : ""}
              selected={selectedInventory}
              onSelect={(item) => {
                setSelectedInventory(item);
                setShowItems(true);
              }}
              renderItem={(item) => renderInventoryItem(item)}
            />

            <div className="mt-5 space-y-4">
              {isLoading ? (
                <p className="text-center text-gray-500">Loading...</p>
              ) : searchResult.length > 0 ? (
                searchResult.map(renderInventoryItem)
              ) : (
                inventories.map(renderInventoryItem)
              )}
            </div>

            <div
              onClick={() => setOpenAddInventory(true)}
              className="p-2 mt-8 font-semibold text-[16px] leading-6 text-[#044E97]"
            >
              Add new inventory
            </div>
          </div>

          <div
            style={{
              boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
            }}
            className="w-full py-5"
          >
            <Button
              variant="secondary"
              disabled={false}
              text={"Add item"}
              size="full"
              font="semibold"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
        {showItems && selectedInventory && (
          <ShowSelectedItem
            openViewSelectItems={(quantities, selectedInventory) => {
              setSelectedInventoryAndQuantities(selectedInventory, quantities);
            }}
            selectedInventory={selectedInventory}
            closeModal={closeModal}
            setCurrentView={() => setShowViewSelection(true)}
            setFormData={setFormData}
            formData={formData}
            fullData={
              searchResult.length > 0
                ? searchResult.find((i) => i.id == selectedInventory.id)
                : inventories.find((i) => i.id == selectedInventory.id)
            }
          />
        )}
      </AddModal>
    </>
  );
};

export default SelectItem;
