import { useState, useEffect } from "react";
import { useUserStore } from "../../state";

const usePaymentLinkInfo = () => {
  const [buttonText, setButtonText] = useState("Activate payment link");
  const { user } = useUserStore();

  useEffect(() => {
    const validatedBankExists = user.bankAccounts?.some(
      (account: { isValidated: boolean }) => account.isValidated
    );

    if (validatedBankExists && user.isVerified) {
      setButtonText("Proceed to copy payment link");
    } else {
      setButtonText("Activate payment link");
    }
  }, [user]);

  return { buttonText };
};

export default usePaymentLinkInfo;
