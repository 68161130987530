import React from "react";
import { ICreateSalesData } from "../../../@types";
import { InputComponent } from "../../core/Forms";

interface NoteProps {
  formData: ICreateSalesData;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const AdditionalDetailsSection: React.FC<NoteProps> = ({
  formData,
  handleInputChange,
}) => {
  return (
    <div className="w-full md:py-5 md:border-b-2 border-gray-200 md:border-transparent">
      <p className="text-[14px] lg:text-[16px] font-medium leading-5 mb-1">
        Add notes
      </p>
      <InputComponent
        className="p-3 w-full placeholder:text-gray-400 border border-gray-300 h-[100px] md:hidden lg:hidden"
        placeholder="Write something..."
        value={formData.additionalInformation}
        name="additionalInformation"
        handleChange={(e: any) => handleInputChange(e)}
      />
      <div className="hidden md:block lg:block">
        <textarea
          name=""
          id=""
          className="h-[100px] w-[350px] bg-gray-50 border border-gray-200 rounded "
        ></textarea>
      </div>
    </div>
  );
};

export default AdditionalDetailsSection;
