import React from "react";
import { ICreateSalesData } from "../../../@types";
import { InvoiceErrors } from "../../../modules/validateInvoiceForm";
import { PAYMENT_PROVIDER_LIST } from "../../../constants";
import { DropdownComponent } from "../../core/Forms";
import { useTranslation } from "react-i18next";
import ExchangeRateIcon from "../../../assets/icons/ExchangeRateIcon";
import { MobileDropdownComponent } from "../../core/Forms/DropdownComponent/mobile";

interface MarkAsPaidSectionProps {
  markAsPaid: boolean;
  handleMarkAsPaidChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: ICreateSalesData;
  validationResults: InvoiceErrors;
  handlePaymentDropDownChange: (name: string, value: string) => void;
  // t: (key: string) => string;
}

const MarkAsPaidSection: React.FC<MarkAsPaidSectionProps> = ({
  markAsPaid,
  handleMarkAsPaidChange,
  formData,
  validationResults,
  handlePaymentDropDownChange,
  // t
}) => {
  const { t } = useTranslation("invoices");
  return (
    <>
      <div className="flex justify-between items-center text-gray-900 font-normal py-3 text-[14px]  w-full">
        <label htmlFor="markAsPaid" className="text-[18px] font-semibold">
          {t("markaspaid")}
        </label>
        <label className="relative inline-flex items-center cursor-pointer justify-end">
          <input
            type="checkbox"
            value="markAsPaid"
            className="sr-only peer"
            onChange={handleMarkAsPaidChange}
            name="markAsPaid"
          />
          <div className="w-10 h-6 bg-gray-300 rounded-full peer-checked:bg-[#044E97] peer-checked:after:translate-x-4 after:content-[''] after:absolute after:top-0.5 after:left-0.5 after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
        </label>
      </div>

      {markAsPaid && (
        <div className="mt-4">
          <div className="flex gap-4 w-full mt-[-0.5em]">
            <ExchangeRateIcon />
            <div className="w-[100%]">
              <MobileDropdownComponent
                name="paymentMethod"
                options={PAYMENT_PROVIDER_LIST}
                placeholder={t("paymentMethod")}
                selectedValue={formData.paymentMethod}
                onChange={handlePaymentDropDownChange}
                dropdownClassName="w-[100%] bg-[#fff] rounded-[4px] border border-[#A4A4A4] h-[48px]"
                iconClassName="mr-[0.5em]"
              />
            </div>
          </div>

          {validationResults.paymentMethod && (
            <span className="text-red-500 text-[13px]">
              {validationResults.paymentMethod}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default MarkAsPaidSection;
