import { Table } from "flowbite-react";
import { ItemInput } from "../../../../@types";
import LineItem from "./LineItem";
import React, { useEffect, useState } from "react";
import { LineItemMobile } from "./LineItemMobile";
import PlusBoxIcon from "../../../../assets/icons/PlusBoxIcon";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export interface TableColumn {
  toLowerCase(): string;
  label: string;
  key: keyof ItemInput;
}

export interface Props {
  columns: TableColumn[];
  items: ItemInput[];
  isSearchInput: boolean;
  addItem: () => void;
  removeItem: (index: any) => void;
  updateCell: (index: number, field: string, value: string | number) => void;
  updateRow: (index: number, item: ItemInput) => void;
  isImportedToInvoice?: boolean;
  onUpdateVariation?: (id: number, field: string, value: string) => void;
  isInvoice: boolean;
  handleClearErrors: () => void;
  currency: string;
  exchangeRate: number;
}

const LineItemTable: React.FC<Props> = ({
  columns,
  items,
  isSearchInput = false,
  addItem,
  removeItem,
  updateCell,
  updateRow,
  isImportedToInvoice,
  onUpdateVariation,
  isInvoice = false,
  handleClearErrors,
  currency,
  exchangeRate,
}) => {
  const { t } = useTranslation("invoices");
  const [errors, setErrors] = React.useState<string[][]>(items.map(() => []));
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const [isMobileTableExpanded, setIsMobileTableExpanded] = useState(true);
  const [isMiniCardVisible, setIsMiniCardVisible] = useState(false);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const tableElement = document.querySelector(
            '[data-testid="table-element"]',
          ) as HTMLElement;
          if (tableElement) {
            tableElement.style.overflowX = "visible";
            observer.disconnect();
          }
        }
      }
    });

    observer.observe(document, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const clearErrorsForIndex = (index: number) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = [];
      return newErrors;
    });
  };

  const updateRowAndValidate = (index: number, item: ItemInput) => {
    updateRow(index, item);
    // validateItems(items);
  };

  // Function to update error state
  const updateError = (index: number, error: string[]) => {
    setErrors((prevErrors) => {
      const newErrors = [...prevErrors];
      newErrors[index] = error;
      return newErrors;
    });
  };

  const validateItems = (items: ItemInput[]) => {
    const newErrors = items.map((item) => {
      const fields: string[] = [];
      if (!item.name) fields.push("name");
      if (!item.quantity || item.quantity < 0) fields.push("quantity");
      if (!item.rate || item.rate < 0) fields.push("rate");
      return fields;
    });
    setErrors(newErrors);
    const isValid = items.every((item) => {
      return !(
        !item?.name ||
        !item?.quantity ||
        item.quantity < 0 ||
        !item?.rate ||
        item.rate < 0
      );
    });

    return isValid;
  };

  // const [expandedRowIndex, setExpandedRowIndex] = useState<number>(-1);
  // const [isExpanded, setIsExpanded] = useState(false);
  // const [expandedTableIndex, setExpandedTableIndex] = useState<number | null>(
  //   0
  // );

  const handleAddItem = () => {
    if (validateItems(items)) {
      setErrors([...errors, []]); // Add an empty error state for the new row
      addItem();
    } else {
      toast.error("Please fix errors in the table before adding a new item.");
    }
  };

  const renderErrors = (index: number) => {
    return errors[index]?.length > 0 ? (
      <ul className="text-red-500 text-sm">
        {errors[index].map((error, i) => (
          <li key={i}>{error}</li>
        ))}
      </ul>
    ) : null;
  };

  // const handleInfoCircleHover = () => {
  //   setIsMiniCardVisible(true);
  // };

  // const handleInfoCircleMouseLeave = () => {
  //   setIsMiniCardVisible(false);
  // };

  return (
    <>
      <div className="w-full px-0">
        <Table className="w-full px-0">
          {!isMobile && (
            <Table.Head>
              {columns.map((column, index) => (
                <Table.HeadCell key={index}>{column.label}</Table.HeadCell>
              ))}
            </Table.Head>
          )}
          <Table.Body className="divide-y">
            {items.map((item, index) => (
              <React.Fragment key={`${item.id}-${index}`}>
                <LineItem
                  key={`${item.id}-${index}`}
                  index={index}
                  columns={columns}
                  isSearchInput={isSearchInput}
                  item={items[index]}
                  removeItem={removeItem}
                  updateCell={updateCell}
                  updateRow={updateRowAndValidate}
                  errors={errors[index] || []}
                  isSidebarCollapsed={true} // Add this line
                  updateError={(newErrors) => {
                    setErrors((prev) => {
                      const updatedErrors = [...prev];
                      return updatedErrors;
                    });
                  }}
                  isInvoice={isInvoice}
                  handleClearErrors={handleClearErrors}
                  currency={currency}
                  exchangeRate={exchangeRate}
                />
                {renderErrors(index)}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
        <div className="flex justify-end">
          <span
            onClick={handleAddItem}
            className="flex text-[#044E97] mt-6 font-medium cursor-pointer"
          >
            {t("addLine")}
            <div className="ml-2">
              <PlusBoxIcon />
            </div>
          </span>
        </div>
      </div>
    </>
  );
};

export { LineItemTable };
