import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { ServiceData, ServicePriceData } from "../../../@types/service";
import { createNewService } from "../../../backend-services/service";
import { validateCreateService } from "../../../modules/services/services";
import { clearFieldError } from "../../../utils";
import { commasToNumber, convertToFloat } from "../../../utils/convertToFloat";
import { InputComponent } from "../../core/Forms";
import AddModal from "../sections/modal/AddModal";

type ErrorProps = {
  name: string;
  charge: {
    NGN?: string;
    EUR?: string;
    USD?: string;
  };
};

interface AddServiceProps {
  setIsOpen: (isOpen: boolean) => void;
  onServiceCreated: (createdService: ServiceData) => void;
}

const AddServiceForm = ({ setIsOpen, onServiceCreated }: AddServiceProps) => {
  const [serviceData, setServiceData] = useState<ServiceData>({
    name: "",
    duration: "",
    description: "",
    price: [],
    status: "Active",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const updatedServiceData = { ...serviceData, [name]: value };
    setServiceData(updatedServiceData);
    clearFieldError(name, error, setError);
  };

  const [error, setError] = React.useState<ErrorProps>({
    name: "",
    charge: {},
  });

  const [price, setPrice] = useState<ServicePriceData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleServicePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const formattedValue = commasToNumber(value);

    const existingPriceIndex = price.findIndex(
      (p: ServicePriceData) => p.currency === name
    );

    if (!formattedValue) {
      if (existingPriceIndex !== -1) {
        const updatedPrice = [...price];
        updatedPrice.splice(existingPriceIndex, 1); // Remove the invalid object
        setPrice(updatedPrice);
        setError({ ...error, charge: { [name]: "" } });
      }
    } else {
      const updatedPrice = [...price];
      if (existingPriceIndex === -1) {
        updatedPrice.push({ amount: formattedValue, currency: name });
      } else {
        updatedPrice[existingPriceIndex].amount = formattedValue;
      }
      setPrice(updatedPrice);
      setError({ ...error, charge: { [name]: "" } });
    }
  };

  const handleCreateService = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPrice = price.map((item) => ({
      ...item,
      amount: convertToFloat(item.amount),
    }));

    const { hasErrors, errors } = validateCreateService({
      name: serviceData.name,
      duration: serviceData.duration,
      description: serviceData.description,
      price: newPrice,
    });

    if (hasErrors) {
      setError(errors);
    } else {
      try {
        setIsLoading(true);
        const newServiceData = {
          ...serviceData,
          price: newPrice,
        };
        const res = await createNewService(newServiceData);
        toast.success("Service was created Successfully");

        // Pass the created service to the parent
        setServiceData({
          name: "",
          duration: "",
          description: "",
          price: [],
          status: "Active",
        });
        setIsLoading(false);
        setIsOpen(false); // Close the modal
        onServiceCreated(res); // Call the parent callback with the created service
      } catch (e: any) {
        if (e) {
          setErrorMessage(e.message || "An error occurred");
          toast.error(e.message);
        } else {
          setErrorMessage("An error occurred while creating service");
          toast.error("An error occurred while creating service");
        }
        setIsLoading(false);
      }
    }
  };

  return (
    <AddModal>
      <div className="h-screen md:hidden pt-12 flex flex-col justify-between">
        <div className="">
          <div className="flex justify-between items-center mb-5 pb-2">
            <div onClick={() => setIsOpen(false)}>
              <svg
                className="mt-[-2px] cursor-pointer"
                width="10"
                height="16"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.5 1L1.5 8L8.5 15"
                  stroke="#33363F"
                  strokeWidth="2"
                />
              </svg>
            </div>
            <div className="w-full flex justify-center">
              <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                Add Service
              </p>
            </div>
          </div>
          <form onSubmit={handleCreateService}>
            {" "}
            {/* Add handleCreateService to form submission */}
            {/* Service Name Field */}
            <div className="mb-4">
              <label
                htmlFor="serviceName"
                className="block text-[14px] font-medium text-gray-700"
              >
                Service name <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type="text"
                id="serviceName"
                placeholder="Business name"
                name="name"
                handleChange={handleInputChange}
                error={error.name}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Duration Field */}
            <div className="mb-4 mt-[2em]">
              <label
                htmlFor="duration"
                className="block text-[14px] font-medium text-gray-700"
              >
                Duration
                {/* <span className="text-red-500">*</span> */}
              </label>
              <InputComponent
                type="text"
                id="serviceName"
                placeholder="3 hours"
                name="duration"
                handleChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Service Charge Field */}
            <div className="mb-4 mt-[2em]">
              <label
                htmlFor="serviceCharge"
                className="block text-[14px] font-medium text-gray-700"
              >
                Service charge <span className="text-red-500">*</span>
              </label>
              <InputComponent
                type="text"
                name="NGN"
                placeholder="Enter amount"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                value={
                  price.find(
                    (p: any) =>
                      p.currency === "NGN" &&
                      p.amount !== 0 &&
                      p.currency !== ""
                  )?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.NGN}
              />
            </div>
            {/* Description Field */}
            <div className="mb-4 mt-[2em]">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700"
              >
                Description
              </label>
              <textarea
                placeholder="Describe Service..."
                value={serviceData.description}
                name="description"
                onChange={handleInputChange}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              ></textarea>
            </div>
            {/* Optional Section */}
            <h2 className="text-[14px] font-semibold text-gray-600 mb-2">
              Optional
            </h2>
            {/* Service Charge (USD) */}
            <div className="mb-4 mt-[2em]">
              <label
                htmlFor="serviceChargeUSD"
                className="block text-sm font-medium text-gray-700"
              >
                Service charge (USD)
              </label>
              <InputComponent
                type="number"
                id="serviceChargeUSD"
                placeholder="Enter amount"
                value={
                  price.find((p: any) => p.currency === "USD")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.USD}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              />
            </div>
            {/* Service Charge (EUR) */}
            <div className="mb-4 mt-[2em]">
              <label
                htmlFor="serviceChargeEUR"
                className="block text-[14px] font-medium text-gray-700"
              >
                Service charge (EUR)
              </label>
              <InputComponent
                type="number"
                id="serviceChargeEUR"
                placeholder="Enter amount"
                value={
                  price.find((p: any) => p.currency === "EUR")?.amount || ""
                }
                handleChange={handleServicePrice}
                error={error.charge.EUR}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none sm:text-sm"
              />
            </div>
            {/* Add Service Button */}
            <button
              type="submit"
              className="mt-[3em] w-full bg-[#044E97] text-white py-2 px-4 rounded-md shadow-md "
            >
              {isLoading ? (
                <div className="mr-3">
                  <Spinner size="sm" light={true} />
                </div>
              ) : (
                "Add Service"
              )}
            </button>
          </form>
        </div>
      </div>
    </AddModal>
  );
};

export default AddServiceForm;
