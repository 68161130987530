import React from "react";
import { Filter } from "../../../@types/invoice";
import ListFilter, { FilterOption } from "./ListFilter";
import { SearchInput } from "../../common";
import { CloseIcon, SearchIcon } from "../../../assets/icons";

interface HeadProps {
  handleSearch?: (text: string) => void;
  handleFilter?: (filter: Filter) => void;
  filter?: Filter;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  showFilter: boolean;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
  categories?: FilterOption[];
  onSubmit?: (filter: Record<string, string | boolean>) => void;
  clearAllFilters?: () => void;
}

const ListHead: React.FC<HeadProps> = (props) => {
  const [text, setText] = React.useState("");

  const handleSearch = (text: string) => {
    if (!text || text.length < 2) return;
    try {
      props.handleSearch?.(text);
    } catch (error) {
      console.error("Error occurred during search:", error);
    }
  };

  return (
    <div
      className={`flex ${
        props.showFilter ? "justify-between" : "justify-end"
      } items-center pt-4 px-4`}
    >
      {props.showFilter && (
        <ListFilter
          onSubmit={props.onSubmit}
          categories={props.categories}
          selectedFilter={props.filter}
          handleFilter={props.handleFilter}
          clearAllFilters={props.clearAllFilters}
        />
      )}

      <div className="w-full max-w-[350px]">
        <SearchInput
          placeholder={props.searchPlaceHolder}
          displayKey={props.searchDisplayKey}
          isLoading={props.isSearching}
          searchedDone={props.searchedDone}
          setSearchedDone={props.setSearchedDone}
          value={text}
          onSelect={props.handleSearchSelect}
          handleChangeText={handleSearch}
          clearIcon={CloseIcon}
          icon={<SearchIcon />}
          data={props.searchResult}
          emptyMessage={"This Item is not found"}
          dropdownClassName="md:ml-[2.7em] lg:w-full lg:ml-[0.1em] xl:ml-[0.1em]"
          dropdownStyle={{
            maxHeight: "400px",

            overflowY: "auto",
          }}
          classNames="h-[48px] rounded-xl text-[16px] w-full max-w-[350px] border border-gray-200"
          searchKey={["customer.firstName", "vendorName", "name"]}
        />
      </div>
    </div>
  );
};

export default ListHead;
