import React from "react";
import { DropdownComponent, InputComponent } from "../../../core/Forms";
import PhoneInputComponent from "../../../common/PhoneInputComponent";
import { ROLE_ARRAY } from "../../../../constants";
import { Button } from "../../../common";
import { UserDetail, errorProps } from "../../../../types";
import { toSentenceCase, validatePhoneNumber } from "../../../../utils";
import { clearFieldError } from "../../../../utils/handleErrors";
import { useTranslation } from "react-i18next";

interface CreateFormProps {
  user: UserDetail;
  setUser: (user: UserDetail) => void;
  handleSubmit?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading: boolean;
  error: errorProps;
  setError: (error: errorProps) => void;
  close: () => void;
}

const CreateForm = ({
  user,
  isLoading,
  setUser,
  handleSubmit,
  error,
  setError,
  close,
}: CreateFormProps) => {
  const [selectedRole, setSelectedRole] = React.useState("");
  const { t } = useTranslation("user");
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    let trimmedValue = value.trim();
    if (name === "firstname" || name === "lastname") {
      const sentenceValue = toSentenceCase(trimmedValue);
      const updatedUser = { ...user, [name]: sentenceValue };
      setUser(updatedUser);
      clearFieldError(name, error, setError);
    }

    if (name === "email") {
      const lowercaseEmail = trimmedValue.toLowerCase();
      const updatedUser = { ...user, [name]: lowercaseEmail };
      setUser(updatedUser);
      clearFieldError(name, error, setError);
    }
  };

  const handleRole = (name: string, value: string) => {
    const updatedUser = { ...user, [name]: value };
    setUser(updatedUser);
    clearFieldError(name, error, setError);
  };

  const handlePhoneNumberChange = (value: string) => {
    const normalizedValue = value.replace(/\D/g, "");

    const isValidPhoneNumber = validatePhoneNumber(normalizedValue);

    if (isValidPhoneNumber) {
      // Format to include country code if necessary
      const formattedPhoneNumber = normalizedValue.startsWith("+")
        ? normalizedValue
        : `+${normalizedValue}`;
      setUser({ ...user, phone: formattedPhoneNumber });
      clearFieldError("phone", error, setError);
    } else {
      setError({ ...error, phone: "Invalid phone number format" });
    }
  };

  return (
    <div>
      <div>
        <div className=" mt-[24px] lg:my-[] ">
          <label
            htmlFor="Last Name"
            className="block text-[13px] text-black text-left "
          >
            First Name <span className="text-red-500">*</span>
          </label>
          <InputComponent
            name="firstname"
            placeholder="First Name"
            value={user.firstname}
            type="text"
            handleChange={handleInputChange}
            className="rounded-[5px] xl:w-[420px] w-[320px] md:w-[420px]"
            error={error.firstname}
          />
        </div>
        <div className=" mt-[24px] lg:my-[24px]">
          <label
            htmlFor="Last Name"
            className="block text-[13px] text-black text-left "
          >
            Last Name <span className="text-red-500">*</span>
          </label>
          <InputComponent
            name="lastname"
            placeholder="Last Name"
            value={user.lastname}
            type="text"
            handleChange={handleInputChange}
            className="rounded-[5px] xl:w-[420px] w-[320px] md:w-[420px]"
            error={error.lastname}
          />
        </div>
        <div className=" mt-[24px] lg:my-[24px]">
          <label
            htmlFor="Email"
            className="block text-[13px] text-black text-left "
          >
            Email <span className="text-red-500">*</span>
          </label>
          <InputComponent
            name="email"
            placeholder="Email Address"
            value={user.email}
            type="email"
            handleChange={handleInputChange}
            className="rounded-[5px] xl:w-[420px] w-[320px] md:w-[420px]"
            error={error.email}
          />
        </div>

        <div className="my-[24px] ">
          <label
            htmlFor="Phone Number"
            className="flex justify-between font-sm font-weight-[400] text-[13px] text-black text-left "
            style={{ whiteSpace: "nowrap" }}
          >
            <span>Phone number</span>
          </label>
          <PhoneInputComponent
            containerClass="customer"
            inputClass="customer"
            placeholder={"+234 900 000 0000"}
            initialPhoneNumber={user.phone}
            onPhoneNumberChange={handlePhoneNumberChange}
          />
          {error.phone && (
            <span className="text-[#FB0303] text-[12px]">{error.phone}</span>
          )}
        </div>

        <div className="w-full mt-[14px] ">
          <label className="block text-[#212121] tracking-wide text-[14px] md:mb-1 font-normal">
            {t("user.role")} <span className="text-red-500">*</span>
          </label>
          <DropdownComponent
            options={ROLE_ARRAY}
            value={selectedRole}
            selectedValue={user.role ? user.role : "Select Role"}
            setSelectedValue={setSelectedRole}
            onChange={handleRole}
            name="role"
            dropdownClassName="z-10 max-h-52 overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full xl:w-[420px] w-[320px] md:w-[420px]"
            iconClassName="xl:mt-[1px] mr-[13px] mt-[0em] md:mr-[18px] lg:mr-[2px] xl:mr-[4px]"
          />
          {error.role && (
            <span className="text-[#FB0303] text-[12px]">{error.role}</span>
          )}
        </div>
        <div className="md:hidden ">
          <div className="mt-[5em] w-[full] flex justify-center items-center">
            <Button
              isLoading={isLoading}
              text={"Done"}
              size="full"
              onClick={handleSubmit}
              variant="secondary"
            />
          </div>
        </div>
        <div className="hidden md:block">
          <div className="flex justify-end mt-[0.8em] items-end ">
            <div className="mt-[32px] xl:mt-[2.5em] w-[215px] flex justify-end items-end ml-[5em]">
              <Button
                text={"Cancel"}
                size="small"
                onClick={close}
                variant="white"
              />
            </div>
            <div className="mt-[32px]   xl:mt-[2.5em] w-[215px] flex justify-end items-end">
              <Button
                isLoading={isLoading}
                text="Create User"
                size="small"
                onClick={handleSubmit}
                variant="secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CreateForm };
