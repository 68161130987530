import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Bank } from "../../../../@types";
import { CloseIcon, SearchIcon } from "../../../../assets/icons";
import { TickIcon } from "../../../../assets/icons/TickIcon";
import { Tooltip } from "../../../../assets/icons/Tooltip";
import { UntickedIcon } from "../../../../assets/icons/UntickedIcon";
import {
  createBankAccount,
  editBankAccount,
  getBankAccounts,
  validateBankAccount,
} from "../../../../backend-services";
import { useBankAccountSearch } from "../../../../hooks/bankAccount/useBankSearch";
import {
  validateBankForm,
  ValidationError,
} from "../../../../modules/settings/settings";
import { useUserStore } from "../../../../state";
import { BankData } from "../../../../types";
import { clearFieldError, handleErrors } from "../../../../utils";
import { Button, SearchInput, SidebarDrawer } from "../../../common";
import { InputComponent } from "../../../core/Forms";
import ErrorComponent from "../../../Error";
import BankAccountForm from "../../../settings/bank/BankAccountForm";

interface CreateProps {
  onCreate: (data: BankData) => void;
  setShowSideModal: (show: boolean) => void;
  onClose?: () => void;
  initialData: BankData;
  toolTipText: string;
  toolTipText2: string;
  toolTipText3: string;
  onValidateSuccess: (accountIndex: any) => void;
  accountIndex: number;
  showPaymentPrompt: (show: boolean) => void;
}

const BankModal: React.FC<CreateProps> = ({
  setShowSideModal,
  onClose,
  initialData,
  toolTipText,
  toolTipText2,
  toolTipText3,
  accountIndex,
  onValidateSuccess,
  showPaymentPrompt,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<BankData[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [activeBank, setActiveBank] = useState<BankData | null>(null);
  const [buttonColor, setButtonColor] = useState("bg-[#FF6702] text-[white]");
  const [error, setError] = useState<string>("");

  const [validationResults, setValidationResults] = useState<ValidationError>({
    bankName: "",
    accountNumber: "",
    accountName: "",
  });
  const [verificationMessage, setVerificationMessage] = useState("");

  const handleCreateBankAccount = async (formData: BankData) => {
    try {
      const newBankAccount = await createBankAccount({
        ...formData,
        currency: "NGN",
        isActive: true,
      });
      setBankAccounts((oldAccounts) => [...oldAccounts, newBankAccount]);
      setShowSideModal(false);
    } catch (error) {
      handleErrors(error, setErrorMessage);
      toast.error("Unable to create Bank Account. Please try again");
    }
  };

  const handleValidate = async (accountIndex: number) => {
    try {
      const data = await validateBankAccount(accountIndex);
      onValidateSuccess(accountIndex);
    } catch (error) {
      // Handle error
      return <ErrorComponent errorCode={500} />;
    }
  };

  const handleCreate = async (data: BankData) => {
    try {
      await handleCreateBankAccount(data);
      setShowSideModal(false);
    } catch (error) {
      return <ErrorComponent errorCode={500} />;
    }
  };

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      if (!validateBankForm(formData, setValidationResults)) {
        setIsLoading(true);
        try {
          setVerificationMessage("Creating bank account...");
          // Create the bank account
          await handleCreateBankAccount({ ...formData });

          // Fetch the updated bank accounts
          setVerificationMessage("Fetching updated bank accounts...");
          const accounts = await getBankAccounts();
          setBankAccounts(accounts);

          // Find the newly created bank account
          const newAccount = accounts.find(
            (account: { accountNumber: string }) =>
              account.accountNumber === formData.accountNumber
          );
          if (newAccount) {
            const accountIndex = accounts.indexOf(newAccount);
            setVerificationMessage("Verifying bank account...");
            await handleValidate(accountIndex);
            setVerificationMessage("Bank account verified");
            showPaymentPrompt(true);
          } else {
            setVerificationMessage("Bank account not found");
          }
        } catch (error) {
          setVerificationMessage(
            "Bank account creation or verification failed"
          );
          setButtonColor("bg-orange-500 text-white");
        } finally {
          setIsLoading(false);
        }
      }
    },
    [formData]
  );

  const handleClose = () => {
    setIsOpen(false);
    setShowSideModal(false);
    onClose && onClose();
  };

  const handleEditBankAccount = async (index: number, newData: BankData) => {
    try {
      await editBankAccount(index, newData);
      setBankAccounts((prevAccounts) =>
        prevAccounts.map((account, i) => (i === index ? newData : account))
      );
      setActiveBank(newData);
    } catch (error) {
      handleErrors(error, setErrorMessage);
    }
  };

  const handleButtonClick = async () => {
    if (verificationMessage === "Bank account verification failed") {
      await handleEditBankAccount(accountIndex, formData);
      setButtonColor("bg-orange-500 text-white"); // Change button color to orange
    }
  };

  const {
    data: bankAccount,
    isSearching,
    handleSearch: handletBankAccountSearch,
  } = useBankAccountSearch();

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;

      if (name === "accountNumber") {
        const numericValue = value.replace(/\D/g, "");
        const restrictedValue = numericValue.slice(0, 10);

        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: restrictedValue,
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }

      clearFieldError(name, validationResults, setValidationResults);
    },
    [formData, validationResults]
  );

  const handleSetBankAccount = (selectedBank: Bank | null) => {
    if (selectedBank) {
      setFormData((prevData) => ({
        ...prevData,
        bankName: selectedBank.name,
      }));
      setSelectedBank(selectedBank);
      setValidationResults((prevErrors) => ({
        ...prevErrors,
        bankName: "",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        bankName: "",
      }));
      setSelectedBank(null);
    }
    clearFieldError("selectedBank", validationResults, setValidationResults);
  };

  const selected = selectedBank
    ? { name: selectedBank.name, slug: selectedBank.name }
    : null;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <div className="p-[32px] flex flex-col  bg-[#ffffff] rounded-[16px] xl:max-h-[550px] 2xl:min-h-[748px] biglg:max-h-[648px] lg:max-h-[600px] w-[300px] xl:w-[434px] 2xl:w-[506px] biglg:w-[434px] lg:w-[400px]">
            <div className="flex justify-between">
              <h1 className="font-semibold text-start lg:text-[20px] ">
                Bank Account
              </h1>
              <button
                onClick={handleClose}
                className="p rounded-md focus:outline-none mt-[-1em] text-[black] shadow-sm pr-3 bg-[#F4F7FF]"
              >
                <CloseIcon />
              </button>
            </div>
            <p className="font-normal lg:text-[16px] text-[10px] md:text-[12px] mt-2">
              Add payment links integrated to your business account.{" "}
            </p>
            {verificationMessage && (
              <p
                className={`flex mt-[1em] lg:text-[14px] text-[10px] md:text-[12px] py-2 px-2 w-[200px]  350:w-[310px]   lg:w-[350px] xl:w-[350px] rounded-md ${
                  verificationMessage === "Verifying bank account..."
                    ? "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
                    : verificationMessage.includes("failed")
                    ? "text-[#D82020] border border-[#FF0000] bg-[#dcc9c9]"
                    : "text-[#20A852] border border-[#20A852] bg-[#F1F8F5]"
                }`}
              >
                {verificationMessage === "Verifying bank account..." ? (
                  <span>{verificationMessage}</span>
                ) : (
                  <>
                    {verificationMessage.includes("verified") ? (
                      <span className="pr-2 mt-[0.1em]">
                        <TickIcon />
                      </span>
                    ) : (
                      <span className="pr-2 mt-[0.1em]">
                        <UntickedIcon />
                      </span>
                    )}
                    {verificationMessage}
                  </>
                )}
              </p>
            )}
            <div className="w-[200px] rounded-md 350:w-[310px] items-center  lg:w-[350px] lg:mt-[1em] lg:mb-6 mt-[35px] sm:mt-[24px] xl:mt-[] mb-[16px] sm:mb-[16px]">
              {/* <div className=''> */}
              <label
                htmlFor="Bank Name"
                className="flex gap-2  lg:font-sm lg:text-[16px] lg:text-black "
              >
                Bank Name
                {toolTipText && (
                  <div
                    className="relative mt-[0.3em]"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <Tooltip />
                    {isHovered && (
                      <div className="absolute w-[204px] left-[-5.7em]">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </label>
              <SearchInput
                data={bankAccount}
                placeholder="Search Bank "
                displayKey="name"
                isLoading={isSearching}
                value={formData.bankName}
                key={initialData.bankName}
                clearIconClass="mr-[0.5em] md:mt-[0.8em]"
                dropdownClassName="xl:w-[350px] w-[200px]  biglg:w-[350px] xl:w-[350px] "
                dropdownStyle={{
                  maxHeight: "800px",
                  overflowY: "auto",
                }}
                emptyMessage={"This bank is not found"}
                classNames="w-[200px] rounded-md  biglg:w-[350px]  border border-gray-300 bg-white  placeholder:text-[12.25px]rounded  mt-[5px] focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal xl:w-[350px] lg:w-[350px]"
                onSelect={handleSetBankAccount}
                handleChangeText={handletBankAccountSearch}
                searchKey={"name"}
                //@ts-ignore
                selected={selected}
                icon={<SearchIcon />}
                clearIcon={CloseIcon}
              />
              {validationResults.bankName && (
                <div className="text-red-500">{validationResults.bankName}</div>
              )}
            </div>
            <div className="w-[200px]  390:w-[310px]  lg:w-[434px] lg:h-[48px] mb-[16px] sm:mb-[16px] lg:mb-6 flex flex-col items-start">
              <label
                htmlFor="account number"
                className="flex gap-2  lg:font-sm lg:text-[13px] lg:text-black items-start text-left"
              >
                Account Number
                {toolTipText2 && (
                  <div
                    className="relative mt-[0.3em] md:mt-[0.16em]"
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                  >
                    <Tooltip />
                    {isHovered2 && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText2}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </label>
              <div className="w-full 390:w-[310px] ">
                <InputComponent
                  placeholder="Account Number"
                  name="accountNumber"
                  type="number"
                  value={formData.accountNumber}
                  handleChange={handleInputChange}
                  className=" w-[200px]  350:w-[310px]   lg:w-[350px] xl:w-[350px]"
                />
                {validationResults.accountNumber && (
                  <div className="text-red-500">
                    {validationResults.accountNumber}
                  </div>
                )}
              </div>
            </div>
            <div className="w-[full] 390:w-[310px]  lg:w-[434px] lg:h-[48px] lg:mt-[22px] mb-[32px] flex flex-col items-left">
              <label
                htmlFor="account name"
                className="flex gap-2 lg:font-sm lg:text-[13px] lg:text-black items-start text-left "
              >
                Account Name
                {toolTipText3 && (
                  <div
                    className="relative mt-[0.3em] md:mt-[0.16em] "
                    onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}
                  >
                    <Tooltip />
                    {isHovered3 && (
                      <div className="absolute w-[204px] left-[-5.7em] z-10">
                        <div className="flex items-center justify-center">
                          <svg
                            width="18"
                            height="15"
                            viewBox="0 0 18 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9 0L17.6603 15H0.339746L9 0Z"
                              fill="#FFF4C6"
                            />
                          </svg>
                        </div>
                        <div className="p-3 bg-[#FFF4C6] rounded-[4px] text-[12px] font-medium leading-[14.4px]">
                          {toolTipText3}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </label>
              <InputComponent
                placeholder="Account Name"
                name="accountName"
                type="text"
                value={formData.accountName}
                handleChange={handleInputChange}
                className="w-[200px]  lg:w-[350px] xl:w-[350px] 30:w-[310px] "
              />
              {validationResults.accountName && (
                <div className="text-red-500">
                  {validationResults.accountName}
                </div>
              )}
            </div>

            {/* <div className={`w-[full] lg:w-[350px] xl:w-[350px] rounded-md py-4 mt-[2em] items-center text-center flex justify-center ${buttonColor}`}> */}
            <div
              className={`w-[full] lg:w-[350px] xl:w-[350px] rounded-md py-3 mt-[1em] items-center text-center flex justify-center bg-[#FF6702] text-[white] ${buttonColor}`}
            >
              <button
                type="submit"
                disabled={isLoading}
                onClick={handleButtonClick}
              >
                {isLoading ? "Loading..." : "Verify"}
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    </>
  );
};

export default BankModal;
