import React, { useState, useEffect, useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { ReusableTableColumn } from "../../@types/table";
import { useNavigate } from "react-router-dom";
import { Loading } from "../common/Table/Loading";

interface TableProps {
  columns: ReusableTableColumn[];
  data: Array<Record<string, any>>;
  hasActions?: boolean;
  loadMore?: (direction: "up" | "down") => Promise<void>;
  modules?: string;
  isLoading: boolean;
  isLoadingFilter?: boolean;
  isFetchingMore?: boolean;
  renderActionMenu?: (
    row: Record<string, any>,
    toggleVisibility: () => void,
    isVisible: boolean
  ) => React.ReactNode;
}

const ReusableTable: React.FC<TableProps> = ({
  columns,
  data,
  hasActions = false,
  loadMore,
  isLoading,
  renderActionMenu,
  isLoadingFilter,
  isFetchingMore,
  modules,
}) => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [activeRow, setActiveRow] = useState<number | null>(null);

  const [ref, inView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView && !isLoading && loadMore) {
      setIsFetching(true);
      loadMore("down");
      setIsFetching(false);
    }
  }, [inView, isLoading, loadMore]);

  const toggleRowVisibility = (index: number) => {
    setActiveRow((prev) => (prev === index ? null : index));
  };

  const handleRowClick = (id: any) => {
    if (modules) {
    }
  };

  return (
    <div className="w-full overflow-x-auto">
      <table className="table-auto w-full border-collapse">
        <thead className="bg-gray-50 rounded">
          <tr>
            {columns.map((column) => (
              <th
                style={{ width: `${column.width}%` }}
                key={column.key}
                className={`p-3 text-left text-sm font-medium text-[#1D2939] ${column.className} `}
              >
                {column.label}
              </th>
            ))}
            {hasActions && (
              <th className="p-3 text-left text-sm font-medium text-gray-600">
                Action
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoadingFilter && <Loading data={columns} />}

          {data.length === 0 ? (
            <tr>
              <td
                colSpan={columns.length + (hasActions ? 1 : 0)}
                className="px-4 py-2 text-center text-gray-500"
              >
                Loading....
              </td>
            </tr>
          ) : (
            data.map((row, index) => (
              <tr
                key={index}
                className={`bg-white border-y border-gray-200 first:border-t-transparent last:border-b-transparent ${
                  index === data.length - 1 ? "border-b-0" : ""
                }`}
              >
                {columns.map((column) => (
                  <td
                    onClick={() => handleRowClick(row.id)}
                    key={column.key}
                    className={`px-3 py-[18px] text-sm text-[#1D2939] ${column.bodyClassName}`}
                  >
                    {row[column.key]}
                  </td>
                ))}
                {hasActions && (
                  <td className="px-4 py-2 relative">
                    {renderActionMenu && activeRow === index ? (
                      renderActionMenu(
                        row,
                        () => toggleRowVisibility(index),
                        true
                      )
                    ) : (
                      // Render inline buttons (Scenario 1) if no visibility toggle is used
                      <div className="flex items-center gap-4">
                        {renderActionMenu &&
                          renderActionMenu(
                            row,
                            () => toggleRowVisibility(index),
                            false
                          )}
                      </div>
                    )}
                  </td>
                )}
              </tr>
            ))
          )}
          {isLoading && <Loading data={columns} />}
          <tr ref={ref}>
            <td
              colSpan={columns.length + (hasActions ? 1 : 0)}
              className="h-1"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export { ReusableTable };
