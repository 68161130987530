import React from "react";
import { IChangePassword, ICreateBudget } from "../../@types";
import { BankCredential, BankData, PaymentCredential } from "../../types";

export const validatePaymentForm = (
  formData: PaymentCredential,
  setValidationResults: React.Dispatch<React.SetStateAction<PaymentCredential>>
): boolean => {
  const errors = {
    paymentProvider: "",
    paymentCredentials: {
      publicKey: "",
      secretKey: "",
    },
  };
  let errorFound = false;
  if (!formData.paymentProvider) {
    errors.paymentProvider = "payment Provider is required";
    errorFound = true;
  }
  if (!formData.paymentCredentials.publicKey) {
    errors.paymentCredentials.publicKey = "public Key is required";
    errorFound = true;
  }
  if (!formData.paymentCredentials.secretKey) {
    errors.paymentCredentials.secretKey = "secret Key is required";
    errorFound = true;
  }
  setValidationResults(errors);
  return errorFound;
};

export const validateBudgetForm = (
  formData: ICreateBudget,
  setValidationResults: React.Dispatch<React.SetStateAction<ICreateBudget>>
): boolean => {
  const errors = {
    monthYear: "",
    allocatedBudget: "",
  };
  let errorFound = false;
  if (!formData?.monthYear) {
    errors.monthYear = "Month is required";
    errorFound = true;
  }
  if (!formData.allocatedBudget) {
    errors.allocatedBudget = "Amount is required";
    errorFound = true;
  }

  setValidationResults(errors);
  return errorFound;
};

export interface ValidationError {
  bankName: string;
  accountNumber: string;
  accountName: string;
}

export const validateBankForm = (
  formData: BankData,
  setValidationResults: React.Dispatch<React.SetStateAction<ValidationError>>
): boolean => {
  const errors: ValidationError = {
    bankName: "",
    accountNumber: "",
    accountName: "",
  }; // Start with an empty object

  if (!formData.bankName) {
    errors.bankName = "Bank name is required";
  }
  if (!formData.accountNumber) {
    errors.accountNumber = "Account number is required";
  }
  if (!formData.accountName) {
    errors.accountName = "Account name is required";
  }

  setValidationResults(errors);
  return Object.keys(errors).length > 0; // Returns true if there are any errors
};

export const validateCreatePasswordForm = (
  formData: IChangePassword
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  if (!formData.currentPassword) {
    errors.currentPassword = "Please enter your current password";
  }

  return errors;
};

export const validateNewPasswordForm = (
  newPassword: string,
  confirmPassword: string,
  oldPassword: string
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  // Validate new password
  if (!newPassword) {
    errors.newPassword = "Please enter your new password";
  } else if (newPassword.length < 8) {
    errors.newPassword = "Password must be at least 8 characters";
  } else if (!/[A-Z]/.test(newPassword)) {
    errors.newPassword = "Password must contain at least one uppercase letter";
  } else if (!/[a-z]/.test(newPassword)) {
    errors.newPassword = "Password must contain at least one lowercase letter";
  } else if (!/[0-9]/.test(newPassword)) {
    errors.newPassword = "Password must contain at least one number";
  } else if (!/[!@#$%^&*]/.test(newPassword)) {
    errors.newPassword =
      "Password must contain at least one special character (e.g., @, #, $, %, &, *)";
  }

  // Validate confirm password
  if (!confirmPassword) {
    errors.confirmPassword = "Please confirm your new password";
  } else if (newPassword !== confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};

export const validateSubUserNewPasswordForm = (
  newPassword: string,
  confirmPassword: string,
  tempPassword: string
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  if (!tempPassword) {
    errors.tempPassword = "Please enter your current password";
  }

  if (!newPassword) {
    errors.newPassword = "Please enter your new password";
  }

  if (newPassword !== confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }

  return errors;
};
