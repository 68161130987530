import React from "react";
import { CloseIcon } from "../../assets/icons";
import cx from "classnames";
import toast from "react-hot-toast";
import { VendorT } from "../../types";
import CreateVendorForm from "./Createvendor";
import { createVendor } from "../../backend-services/vendor";
import { IVendor } from "../../@types";

interface CreateVendorProps {
  onVendorCreate?: (vendor: any) => void;
  show: boolean;
  onClose?: () => void;
}

const AddVendors = ({ onVendorCreate, show, onClose }: CreateVendorProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const handleCreateVendor = async (vendor: VendorT) => {
    setIsLoading(true);
    setErrorMessage("");

    if (!vendor.name) {
      setErrorMessage("The name field is required.");
      setIsLoading(false);
      return;
    }

    const flatVendor = {
      name: vendor.name,
      phone: vendor.phone,
      address: vendor.address,
      city: vendor.city,
      state: vendor.state,
    };

    try {
      const data = await createVendor(flatVendor);
      setIsLoading(false);
      setIsOpen(false);
      toast.success("Vendor created successfully!");
      if (onVendorCreate) {
        onVendorCreate({
          id: data.id,
          name: data.name,
          phone: data.phone,
          address: data.address,
        });
      }
      if (onClose) onClose();
    } catch (error: any) {
      setErrorMessage(error.message || "Error creating vendor");
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="lg:hidden md:hidden fixed top-0 right-0 left-0 z-50 h-full overflow-y-auto overflow-x-hidden items-start flex bg-gray-900 bg-opacity-50"
      >
        <div
          className={cx({
            "relative z-50 overflow-y-auto": true,
            "p-3 max-w-lg bg-white shadow h-[100vh]": true,
            "animate-slide-in": isOpen,
          })}
        >
          <div className="flex justify-between items-center pt-[20px] mb-[20px]">
            <h3 className="text-[25px] font-bold pl-[20px]">
              {"Add New Vendor"}
            </h3>
            <button
              aria-label="Close"
              className="mr-[2em] inline-flex items-center rounded-lg shadow-sm py-2 pr-3 bg-[#F4F7FF] text-[black]"
              type="button"
              onClick={handleClose}
            >
              <CloseIcon />
            </button>
          </div>

          {/* MODAL BODY */}
          <div className="p-4">
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <CreateVendorForm
              isLoading={isLoading}
              show={true}
              onSubmit={handleCreateVendor}
              buttontitle={"Add Vendor"}
              setFormData={function (
                value: React.SetStateAction<IVendor>
              ): void {
                throw new Error("Function not implemented.");
              }} // errorMessage={errorMessage}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { AddVendors };
