import React from "react";
import { useTranslation } from "react-i18next";
import TruncatedText from "../../components/TruncatedText";
import { formatMoney } from "../../utils";
import { formattedDate } from "../../utils/formatDate";

interface InventoryDetailsProps {
  details?: {
    unitprice?: number; // Made optional
    quantity: number;
    rate: number;
    desc: string;
    name: string;
    date: string;
    variations: any[];
  };
  getPriceRange: (variations: any[]) => string;
  stats?: {
    totalPurchase: number;
    totalSellingPrice: number;
  };
}

const InventoryDetails: React.FC<InventoryDetailsProps> = ({
  details,
  getPriceRange,
  stats,
}) => {
  const { t } = useTranslation("inventories");
  return (
    <>
      <div className="flex flex-col lg:hidden md:hidden">
        <div className="flex justify-between">
          <h3 className="text-sm text-gray-500">Quantity Left</h3>
          <p className="font-semibold">{details?.quantity}</p>
        </div>
        <div className="flex justify-between">
          <h3 className="text-sm text-gray-500">Unit Price</h3>
          <p className="font-semibold">
            {details?.variations?.length
              ? getPriceRange(details.variations)
              : details?.unitprice
              ? formatMoney(details.unitprice)
              : "--"}
          </p>
        </div>
        <div className="flex justify-between">
          <h3 className="text-sm text-gray-500">Date Added</h3>
          <p className="font-semibold">{formattedDate(details?.date)}</p>
        </div>
        <div className="flex justify-between">
          <h3 className="text-sm text-gray-500">
            {details?.desc ? "Description" : ""}
          </h3>
          <p className="font-semibold">
            {details?.desc ? (
              <TruncatedText text={details.desc} maxLength={20} />
            ) : (
              ""
            )}
          </p>
        </div>
      </div>

      <div className="hidden lg:block md:block">
        <div className="md:flex md:items-center md:justify-between lg:mt-[64px] border border-gray-200  rounded-md px-4 pt-2 mt-[1em]  ">
          <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
            <h3 className="text-[14px] text-[#667085] font-medium">
              {" "}
              Quantity Sold
            </h3>
            <p className="font-semibold">{stats?.totalPurchase}</p>
          </div>
          <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
            <h3 className="text-[14px] text-[#667085] font-medium">
              {" "}
              {t("inventory.quantityLeft")}
            </h3>
            <p className="font-semibold">{details?.quantity}</p>
          </div>
          <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
            <h3 className="text-[14px] text-[#667085] font-medium">
              {t("inventory.unitPrice")}
            </h3>
            <p className="font-semibold">
              {details?.variations?.length
                ? getPriceRange(details.variations)
                : details?.unitprice
                ? formatMoney(details.unitprice)
                : "--"}
            </p>
          </div>
          <div className="flex items-center mb-4 justify-between md:flex-col md:items-start mt-[12px] md:!mt-0">
            <h3 className="text-[14px] text-[#667085] font-medium">
              {" "}
              {t("inventory.dateAdded")}
            </h3>
            <p className="font-semibold">{formattedDate(details?.date)}</p>
          </div>
          {/* <div className="flex items-center mt-[12px] md:!mt-0 justify-between md:flex-col md:items-start">
            <h3 className="text-[14px] text-[#667085] font-medium">{details?.desc ? 'Description' : ''}</h3>
            <p className="font-semibold md:!ml-0">
              {details?.desc ? <TruncatedText text={details?.desc} maxLength={20} /> : ''}
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default InventoryDetails;
