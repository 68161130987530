import React, { useEffect, useState } from "react";
import AddModal from "../modal/AddModal";
import { Button } from "../../../common";
import { InputComponent } from "../../../core/Forms";
import { UnionIcon } from "../../../../assets/icons/UnionIcon";
import { clearFieldError, toSentenceCase } from "../../../../utils";
import {
  commasToNumber,
  convertToFloat,
} from "../../../../utils/convertToFloat";
import { InventoryItem, VariationItem } from "../../../../@types/inventory";
import {
  validateCreateInventory,
  ValidateVariation,
} from "../../../../modules/inventory/inventory";
import { ICreateSalesData, IInventory, ItemInput } from "../../../../@types";
import { createInventory } from "../../../../backend-services";
import toast from "react-hot-toast";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import { CloseIcon } from "../../../../assets/icons";

interface AddInventoryProps {
  setIsOpen: (isOpen: boolean) => void;
  setOpenProductOptions: (isOpen: boolean) => void;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  onInventoryAdded?: (inventory: InventoryItem) => void;
  variants?: VariationItem[];
  setCurrentView: React.Dispatch<
    React.SetStateAction<
      | "selectItem"
      | "addInventory"
      | "productOptions"
      | "productVariants"
      | "viewSelectItems"
      | "showSelectedItem"
      | null
    >
  >;
  variationList: VariationItem[];
  setVariationList: React.Dispatch<React.SetStateAction<VariationItem[]>>;
}

const AddInventory = ({
  setIsOpen,
  setOpenProductOptions,
  setFormData,
  onInventoryAdded,
  variants = [],
  setCurrentView,
  variationList,
  setVariationList,
}: AddInventoryProps) => {
  // const [showVariation, setShowVariation] = useState(false);
  const [showVariation, setShowVariation] = useState(variants.length > 0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [selectedInventory, setSelectedInventory] =
    useState<InventoryItem | null>(null);
  const [inventory, setInventories] = useState<InventoryItem>({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
    description: "",
    variations: variants,
  });
  const [error, setError] = useState({
    name: "",
    rate: "",
    costPrice: "",
    quantity: "",
  });
  const handleOpenProductOptions = () => {
    setIsOpen(false);
    setOpenProductOptions(true);
  };

  useEffect(() => {
    console.log("Variants passed to AddInventory:", variants);

    if (selectedInventory) {
      console.log("selectedInventory", selectedInventory);

      setInventories({
        name: selectedInventory.name,
        rate: selectedInventory.rate || "",
        costPrice: selectedInventory.costPrice || "",
        quantity: selectedInventory.quantity || "",
        description: selectedInventory.description || "",
        variations: selectedInventory.variations || [],
      });
      //@ts-ignore
      setVariationList(selectedInventory.variations || []);
      //@ts-ignore
      setShowVariation(selectedInventory.variations?.length > 0);

      console.log("Variations set:", selectedInventory.variations);
    } else {
      setVariationList(variants);
      setShowVariation(variants.length > 0);
      console.log("Initial variations from props:", variants);
    }
  }, [selectedInventory, variants]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;

    if (name === "name" || name === "description") {
      const sentenceValue = toSentenceCase(value);
      const updatedInventory = { ...inventory, [name]: sentenceValue };
      setInventories(updatedInventory);
      name === "name" && clearFieldError(name, error, setError);
    }

    if (name === "rate" || name === "costPrice" || name === "quantity") {
      const resValue = commasToNumber(value);
      const updatedInventory = { ...inventory, [name]: resValue };
      setInventories(updatedInventory);
      clearFieldError(name, error, setError);
    }
  };
  const handleCreateInventory = async () => {
    setError({
      name: "",
      rate: "",
      costPrice: "",
      quantity: "",
    });

    const newRate = convertToFloat(inventory.rate);
    const newCostPrice = convertToFloat(inventory.costPrice);
    const newQuantity = convertToFloat(inventory.quantity);

    const newVariationList = variationList.map((item) => ({
      ...item,
      price: convertToFloat(item.price),
      costPrice: convertToFloat(item.costPrice),
      quantity: convertToFloat(item.quantity),
    }));

    const { hasErrors, errors } = validateCreateInventory({
      rate: newRate,
      costPrice: newCostPrice,
      name: inventory.name,
      variationLen: newVariationList.length,
    });

    if (hasErrors) {
      setError(errors);
      toast.error("Validation failed. Please fix the errors.");
      return;
    }

    try {
      setIsLoading(true);

      const payload: any = {
        name: inventory.name,
        ...(newVariationList.length > 0
          ? { variations: newVariationList }
          : {
              rate: newRate || 0,
              costPrice: newCostPrice || 0,
              quantity: newQuantity || 0,
            }),
      };

      const response: IInventory = await createInventory(payload);

      if (response) {
        const newItem: ItemInput = {
          id: response.id,
          name: response.name,
          rate: response.rate ?? 0,
          description: response.description || "",
          quantity: response.quantity || newQuantity,
          total:
            (response.rate ?? newRate) * (response.quantity || newQuantity),
          variations: response.variations?.map((variation) => ({
            type: variation.type || "",
            name: variation.name || "",
            id: variation.id || "",
            price: typeof variation.price === "number" ? variation.price : 0,
            quantity:
              typeof variation.quantity === "number" ? variation.quantity : 0,
          })),
        };

        setSelectedInventory(newItem as unknown as InventoryItem);
        //@ts-ignore
        setVariationList(response.variations || []);
        setShowVariation(response.variations?.length > 0);

        toast.success("New Inventory Added Successfully!");
        //@ts-ignore
        setFormData((prevData) => ({
          //@ts-ignore
          ...prevData,
          //@ts-ignore
          inventories: [...(prevData.inventories || []), newItem],
        }));

        // Trigger the new prop callback
        onInventoryAdded?.(newItem as unknown as InventoryItem);

        setCurrentView("selectItem");
      }
      setShowVariation(!showVariation);
      setVariationList([]);
    } catch (error) {
      toast.error("Failed to create inventory");
      console.error("Error creating inventory:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AddModal className="md:max-w-[750px]">
        <div className="h-screen md:hidden md:h-full pt-12 flex flex-col ">
          <div className="flex-1">
            <div>
              <div className="flex justify-between items-center mb-5 pb-2">
                <div onClick={() => setIsOpen(false)}>
                  <svg
                    className="mt-[-2px] cursor-pointer"
                    width="10"
                    height="16"
                    viewBox="0 0 10 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5 1L1.5 8L8.5 15"
                      stroke="#33363F"
                      strokeWidth="2"
                    />
                  </svg>
                </div>

                <div className="w-full flex justify-center ">
                  <p className="font-semibold text-[20px] leading-6 text-[#000000]">
                    Add inventory
                  </p>
                </div>
              </div>
              <div className="space-y-5">
                <div className="w-full">
                  <label
                    htmlFor="First Name"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Product name
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="Your product name"
                    name="name"
                    type="text"
                    value={inventory.name}
                    handleChange={handleInputChange}
                    error={error.name}
                    className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                  />
                </div>

                {!showVariation && (
                  <div className="w-full flex gap-5">
                    <div className="w-full">
                      <label
                        htmlFor="Cost price "
                        className="font-medium text-[14px] leading-5 text-[#000000]"
                      >
                        Cost Price
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <InputComponent
                        placeholder="Enter price"
                        name="costPrice"
                        type="text"
                        value={inventory.costPrice}
                        handleChange={handleInputChange}
                        error={error.costPrice}
                        className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="Selling price"
                        className="font-medium text-[14px] leading-5 text-[#000000]"
                      >
                        Selling price
                        <span className="text-red-500 ml-1">*</span>
                      </label>
                      <InputComponent
                        placeholder="Enter price"
                        name="rate"
                        type="text"
                        value={inventory.rate}
                        handleChange={handleInputChange}
                        error={error.rate}
                        className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                      />
                    </div>
                  </div>
                )}
                {!showVariation && (
                  <div className="w-full">
                    <label
                      htmlFor="Quantity"
                      className="font-medium text-[14px] leading-5 text-[#000000]"
                    >
                      Quantity
                      <span className="text-red-500 ml-1">*</span>
                    </label>
                    <InputComponent
                      placeholder="Enter quantity"
                      name="quantity"
                      type="text"
                      value={inventory.quantity}
                      handleChange={handleInputChange}
                      error={error.quantity}
                      className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                    />
                  </div>
                )}
                <div className="w-full">
                  <label
                    htmlFor="description"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Description
                  </label>
                  <textarea
                    placeholder="Provide more details about the product..."
                    name="description"
                    value={inventory.description}
                    onChange={handleInputChange}
                    className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300 h-[80px] rounded-lg"
                  />
                </div>
              </div>

              <hr className="my-5" />

              <div className="font-medium text-[14px]">
                <p className="leading-5 text-[#000000]">Product options</p>
                <div className="flex items-center gap-2 mt-2">
                  <input
                    className="w-[14px] h-[14px] border border-gray-600"
                    type="checkbox"
                    checked={showVariation}
                    onClick={() => setShowVariation(true)}
                    onChange={() => setShowVariation(!showVariation)}
                  />
                  <p className="leading-5 text-gray-600">
                    This item has options like color, size
                  </p>
                </div>
              </div>

              {showVariation && (
                <div
                  onClick={handleOpenProductOptions}
                  className="p-2 pr-[15px] w-full bg-gray-50 rounded-lg border border-gray-200 flex justify-between items-center mt-4"
                >
                  <div className="font-medium text-[14px] space-y-2">
                    <p className="text-[#000] leading-5">Add Options</p>
                    <p className="text-gray-700 leading-5">
                      like color, size, etc
                    </p>
                  </div>
                  <UnionIcon />
                </div>
              )}
            </div>

            {showVariation && variationList.length > 0 && (
              <div>
                <div className="flex justify-between items-center">
                  <h3 className="font-medium text-[16px] mb-2 mt-2">
                    {" "}
                    Products variants
                  </h3>

                  <h3
                    onClick={() => setCurrentView("productVariants")}
                    className="font-medium text-[16px] mb-2 mt-5 text-blue-700"
                  >
                    {" "}
                    Edit variants
                  </h3>
                </div>
                {variationList.map((variant, index) => (
                  <div className="mx-auto rounded-sm shadow-sm overflow-hidden md:max-w-2xl w-full h-[118px] border border-gray-100">
                    <div className="flex flex-col gap-5">
                      {/* Header section */}
                      <div className="bg-[#EAECF0] w-full">
                        <div className="flex justify-between px-[16px] py-[5px]">
                          <div className="flex gap-1 text-[14px] font-medium">
                            <span>{`${variant.type}`}</span>
                          </div>
                        </div>
                      </div>

                      {/* Body section */}
                      <div className="flex justify-between items-end px-[16px] mb-4">
                        <div className="flex flex-col  text-[12px] font-normal  w-full gap-2 ">
                          <div className="flex justify-between ">
                            <div className="flex flex-col items-start gap-[4px] justify-start ">
                              <p className="font-medium text-gray-600">
                                Cost Price
                              </p>
                              <span className="font-medium text-black">
                                {variant.costPrice}
                              </span>
                            </div>
                            <div className="flex flex-col text-right gap-[4px] items-center justify-center">
                              <p className="font-medium text-gray-600 whitespace-nowrap">
                                Selling Price
                              </p>
                              <span className="font-medium text-black">
                                {variant.price}
                              </span>
                            </div>
                            <div className="flex flex-col items-start gap-[4px] justify-end ">
                              <p className="font-medium text-gray-600">
                                Quantity
                              </p>
                              <span className="font-medium text-black">
                                {variant.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            style={{
              boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
            }}
            className="w-full py-5"
          >
            <Button
              variant="secondary"
              disabled={false}
              text={"Add inventory"}
              size="full"
              font="semibold"
              onClick={handleCreateInventory}
              isLoading={isLoading}
            />
          </div>
        </div>

        {/* tablet */}
        <div className="hidden md:block h-full">
          <div className="flex justify-between items-center my-4 md:mt-0">
            <p className="text-[18px] font-semibold leading-6">
              Create Inventory
            </p>

            <div onClick={() => setIsOpen(false)}>
              <CloseIcon />
            </div>
          </div>
          <div className="space-y-5 py-6">
            <div className="flex gap-4 w-full">
              <div className="w-full">
                <label
                  htmlFor="First Name"
                  className="font-medium text-[14px] leading-5 text-[#000000]"
                >
                  Product name
                  <span className="text-red-500 ml-1">*</span>
                </label>
                <InputComponent
                  placeholder="Your product name"
                  name="productName"
                  type="text"
                  className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                />
              </div>
              {!showVariation && (
                <div className="w-full">
                  <label
                    htmlFor="Quantity"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Quantity
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="Enter quantity"
                    name="quantity"
                    type="text"
                    className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                  />
                </div>
              )}
            </div>

            {!showVariation && (
              <div className="w-full flex gap-5">
                <div className="w-full">
                  <label
                    htmlFor="Cost price "
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Cost Price
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="Enter price"
                    name="costPrice"
                    type="text"
                    className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                  />
                </div>
                <div className="w-full">
                  <label
                    htmlFor="Selling price"
                    className="font-medium text-[14px] leading-5 text-[#000000]"
                  >
                    Selling price
                    <span className="text-red-500 ml-1">*</span>
                  </label>
                  <InputComponent
                    placeholder="Enter price"
                    name="sellingPrice"
                    type="text"
                    className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300"
                  />
                </div>
              </div>
            )}
            <div className="w-full">
              <label
                htmlFor="description"
                className="font-medium text-[14px] leading-5 text-[#000000]"
              >
                Description
              </label>
              <textarea
                placeholder="Provide more details about the product..."
                name="description"
                className="w-full placeholder:text-[14px] placeholder:text-gray-400 border border-gray-300 h-[80px] rounded-lg"
              />
            </div>
          </div>

          <div className="py-6 mx-[-20px] border-2 border-gray-200 px-5">
            <div className="font-medium text-[14px]">
              <p className="leading-5 text-[#000000]">Product options</p>
              <div className="flex items-center gap-2 mt-2">
                <input
                  className="w-[14px] h-[14px] border border-gray-600"
                  type="checkbox"
                  checked={showVariation}
                  // onClick={() => setOpenOptions(true)}
                />
                <p className="leading-5 text-gray-600">
                  This item has options like color, size
                </p>
              </div>
            </div>
          </div>

          <div className="py-6 mx-[-20px] border-b-2 border-gray-200 px-5 mb-6 font-medium text-[16px] ">
            <div className="flex justify-between items-center">
              <p className="leading-6 text-[#000000]">Size</p>
              <button className="w-[52px] h-10 border border-[#044E97] text-[#044E97] rounded leading-6">
                Edit
              </button>
            </div>
            <div className="flex gap-2 mt-2">
              <div className="border border-gray-200 bg-gray-50 py-2 px-3 leading-6 text-gray-600">
                Small
              </div>
              <div className="border border-gray-200 bg-gray-50 py-2 px-3 leading-6 text-gray-600">
                Medium
              </div>
            </div>
          </div>

          <button className="flex gap-2 items-center mb-4">
            <PlusIcon />
            <span className="text-[#044E97] font-semibold text-[16px] leading-6">
              Add another option
            </span>
          </button>

          <div className="w-full py-3 flex justify-end">
            <Button
              variant="secondary"
              disabled={false}
              text={"Done"}
              size="small"
              font="semibold"
              onClick={() => setIsOpen(false)}
            />
          </div>
        </div>
        {/* {openOptions && (
          <ProductOptions
            options={options}
            setOpenOptions={setOpenOptions}
            setOptions={setOptions}
            handleOpenVariants={() => {}}
          />
        )} */}
      </AddModal>
    </>
  );
};

export default AddInventory;
