import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatMoney } from "../../utils";
import { useTranslation } from "react-i18next";
import { useBudgetStore } from "../../state/useBudgetStore";

export interface BudgetHistoryWidgetProps {
  isLoading?: boolean;
  hasHeader?: boolean;
}

const BudgetHistoryWidget: React.FC<BudgetHistoryWidgetProps> = ({
  hasHeader = true,
}) => {
  const { t } = useTranslation("expenses");
  const location = useLocation();
  const [actualExpenses, setActualExpenses] = useState<null | number>(null);
  const [monthlyBudget, setMonthlyBudget] = useState<null | number>(null);
  const [actualBudgetPercentage, setActualBudgetPercentage] = useState<
    null | number
  >(null);
  const [monthlyBudgetPercentage, setMonthlyBudgetPercentage] = useState<
    null | number
  >(null);
  const {
    budgets,
    fetchBudgets,
    isBudgetLoading: isLoading,
    error,
  } = useBudgetStore();

  useEffect(() => {
    if (budgets) {
      setActualExpenses(budgets[0]?.actualExpenses ?? null);
      setMonthlyBudget(budgets[0]?.allocatedBudget ?? null);
    }
  }, [budgets]);

  useEffect(() => {
    fetchBudgets();
  }, []);

  useEffect(() => {
    if (actualExpenses !== null || monthlyBudget !== null) {
      let percentage;
      if (actualExpenses && monthlyBudget) {
        percentage = (actualExpenses / monthlyBudget) * 100;
      } else {
        percentage = 0;
      }
      setActualBudgetPercentage(percentage > 100 ? 100 : percentage);
      setMonthlyBudgetPercentage(100 - percentage);
    }
  }, [actualExpenses, monthlyBudget]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (budgets.length === 0) {
    return (
      <>
        <div className="border-[1.5px] border-gray-100 rounded-2xl h-[120px] pt-4 px-3 mt-4 md:mt-0 w-full md:w-1/2">
          <div className="text-[14px] md:text-[16px] w-full flex justify-end">
            {location.pathname === "/expenses" && (
              <a
                className="text-[#044E97] underline font-medium leading-6 cursor-pointer"
                href="/settings?tab=expense"
              >
                Set Budget
              </a>
            )}
          </div>

          <div className="mt-3 flex items-center justify-between">
            <>
              <div
                className="relative flex-grow-0 bg-[#F2F4F7] h-[7px] rounded-[4px]"
                style={{ width: "50%" }}
              />
              <div
                className="relative flex-grow-0 bg-gray-100 h-[7px] rounded-[4px]"
                style={{ width: `50%` }}
              />
            </>
          </div>

          <div className="flex items-center justify-between">
            <div>
              <h1 className="font-medium leading-6 text-[#D92D20]">
                Actual Expense
              </h1>
              <p className={`font-semibold text-[#212121]`}>--</p>
            </div>
            <div>
              <h1 className="font-medium leading-6 text-[#039855]">
                Monthly Budget
              </h1>
              <p className={`font-semibold text-[#212121]`}>--</p>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="w-full md:w-1/2 border md:border-[1.5px] border-gray-100 rounded-2xl h-[156px] md:h-[120px] mt-4 md:mt-0 pt-3 md:pt-4">
      <div className="text-[14px] font-medium  md:text-[16px] w-full flex justify-between md:justify-end px-3">
        <p className="leading-5 text-[#000] md:hidden ">Budget vs Expenses</p>
        {location.pathname === "/expenses" && (
          <a
            className="text-[#044E97] underline leading-5 cursor-pointer"
            href="/settings?tab=expense"
          >
            {t("budget.update")}
          </a>
        )}
      </div>

      <hr className="border border-gray-200 w-full mt-3 mb-4 md:hidden" />

      <div className="px-3 pt-3">
        <div className="flex items-center justify-between">
          {monthlyBudget ? (
            <>
              <div
                className="relative flex-grow-0 bg-[#D82020] h-[7px]"
                style={{ width: `${actualBudgetPercentage || 50}%` }}
              />
              <div
                className="relative flex-grow-0 bg-blue-500 h-[7px]"
                style={{ width: `${monthlyBudgetPercentage || 50}%` }}
              />
            </>
          ) : (
            <div
              className="relative flex-grow-0 bg-[#D82020] h-[7px] rounded-sm"
              style={{ width: "100%" }}
            />
          )}
        </div>

        <div className="flex items-center justify-between text-[16px]">
          <div>
            <h1 className="font-medium leading-6 text-[#D92D20]">
              Actual Expense
            </h1>
            <p className={`font-semibold text-[#212121]`}>
              {` ${
                actualExpenses === null || actualExpenses === 0
                  ? "--"
                  : formatMoney(actualExpenses)
              }`}
            </p>
          </div>
          <div>
            <h1 className="font-medium leading-6 text-[#039855]">
              Monthly Budget
            </h1>
            <p className={`font-semibold text-[#212121]`}>
              {` ${monthlyBudget === null || monthlyBudget === 0 ? "--" : formatMoney(monthlyBudget)}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BudgetHistoryWidget;
