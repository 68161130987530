import React, { useState } from "react";
import { IInventory, Product } from "../../../@types";
import { AlertTag } from "../../../assets/icons/AlertTag";
import { TableRow } from "./TableRow";
import { cardProducts } from "../../../utils/cardProducts";
import { useNavigate } from "react-router-dom";
import EmptyTransaction from "./EmptyTransaction";
import { InventoryModal } from "../../inventory/InventoryModal";
import Restock from "../../inventory/restock/Restock";
import { createPortal } from "react-dom";

type CardComponentProps = {
  inventories: IInventory[];
};

const CardComponent: React.FC<CardComponentProps> = ({ inventories }) => {
  const navigate = useNavigate();
  const products = cardProducts(inventories);
  const [openModal, setOpenModal] = useState(false);
  const [modalSize, setModalSize] = useState(false);
  const [id, setId] = useState("");

  const handleActionClick = (productId: string) => {
    setOpenModal(true);
    setId(productId);
    const item = products.find((item: Product) => item.id === productId);
    const modalSize = item?.type ? true : false;
    setModalSize(modalSize);
  };

  return (
    <>
      <div className="lg:w-1/2 py-[20px] rounded-[4px] bg-white">
        <div className="pl-[17px] pr-[15px] flex items-center justify-between">
          <div className="flex items-center gap-2 py-[9px] pl-2 pr-[13px] bg-[#D5EBF8] rounded-[4px]">
            <AlertTag />
            <span className="font-semibold text-[12px] leading-[12px] text-[#2D9CDB]">
              Restock Alert
            </span>
          </div>
          <div>
            <button
              onClick={() => navigate("/inventories")}
              className="text-[12px] cursor-pointer font-semibold px-[11px] py-[9px] border border-opacity-[10%] border-[#0A0A0A1A] rounded-[4px] leading-[16px] text-[#044E97]"
            >
              View Inventory
            </button>
          </div>
        </div>
        {products.length > 0 ? (
          <div className="mt-[25px]">
            <div>
              <td className="grid grid-cols-5 gap-[8px] mx-[16px] text-[12px] font-semibold leading-[14.4px] text-[#212121] text-center">
                <span className="ml-[-1em]">Product</span>
                <span className="">Qty</span>
                <span className="whitespace-nowrap">Selling Price</span>
                <span className="text-center col-span-2">Action</span>
              </td>
              <div className="border-b border-[#CECECE] w-full mt-[9px]" />
            </div>

            {products.map((product: Product, index: number) => (
              <>
                <TableRow
                  key={product.id}
                  product={product}
                  onActionClick={handleActionClick}
                />

                {index < products.length - 1 && (
                  <div className="border border-[#CECECE80] border-opacity-[50%] ml-[18px] mr-[36px]" />
                )}
              </>
            ))}
          </div>
        ) : (
          <EmptyTransaction text="All your products are in stock" />
        )}
      </div>
      {openModal &&
        createPortal(
          <InventoryModal
            children={<Restock restockId={id} setOpenModal={setOpenModal} />}
            setOpenModal={setOpenModal}
            modalSize={modalSize}
          />,
          document.body
        )}
    </>
  );
};

export default CardComponent;
