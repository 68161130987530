import React, { useEffect } from "react";
import { ICreateExpenseData, ItemInput } from "../../../@types";
import { EXPENSE_TABLE_HEADER } from "../../../constants";
import { clearFieldError, formatMoney } from "../../../utils";
import { LineItemTable } from "../../common/LineItemTable/InventoryLineItemTable";
import { TableColumn } from "../../common/LineItemTable/ServiceLineItemTable";

interface ExpenseDetailTwoProps {
  formData: ICreateExpenseData;
  updatedNewItem?: ItemInput;
  expense: ICreateExpenseData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateExpenseData>>;
  validateCurrentStep: () => boolean;
  validationResults: ICreateExpenseData;
  setValidationResults: React.Dispatch<
    React.SetStateAction<ICreateExpenseData>
  >;
  items: ItemInput[];
  addItem: () => void;
  setItems: any;
  removeItem: (index: any) => void;
  updateCell: (index: number, field: string, value: string | number) => void;
  updateRow: (index: number, item: ItemInput) => void;
}

const ExpenseLineTable: React.FC<ExpenseDetailTwoProps> = ({
  formData,
  expense,
  setFormData,
  validateCurrentStep,
  validationResults,
  setValidationResults,
  items,
  addItem,
  setItems,
  removeItem,
  updateCell,
  updateRow,
}) => {
  const columns: TableColumn[] = EXPENSE_TABLE_HEADER;

  const [total, setTotal] = React.useState<number>(0);

  useEffect(() => {
    clearFieldError("lineItems", validationResults, setValidationResults);
    setFormData((prevState: ICreateExpenseData) => ({
      ...prevState,
      lineItems: items.map((item: any) => ({
        ...item,
        amount: (item?.rate ?? 0) * (item?.quantity ?? 0),
      })),
    }));

    const total = items.reduce(
      (total: any, exp: any) => total + (exp?.rate ?? 0) * (exp?.quantity ?? 0),
      0
    );
    setTotal(total);
  }, [items]);

  const handleClearErrors = () => {
    setValidationResults((prevResults: any) => ({
      ...prevResults,
      lineItems: "", // Ensure to clear lineItems errors
    }));
  };

  return (
    <div>
      <div className="flex w-full">
        <div className="mt-[-1em] w-full">
          <LineItemTable
            columns={columns}
            items={items}
            isSearchInput={false}
            addItem={addItem}
            removeItem={removeItem}
            updateCell={updateCell}
            updateRow={updateRow}
            isImportedToInvoice={true}
            isInvoice={false}
            handleClearErrors={handleClearErrors}
            currency={formData.currency ?? ""}
            //@ts-ignore
            exchangeRate={formData.exchangeRate}
          />
          {validationResults.lineItems && (
            <span
              className="text-red-500 error-message"
              style={{ whiteSpace: "nowrap" }}
            >
              {validationResults.lineItems}
            </span>
          )}
        </div>
      </div>
      <hr className="lg:hidden md:hidden mt-[1em]" />
      <div className="flex justify-end items-end text-right">
        <div className="hidden lg:block md:block ">
          <div className="flex justify-end items-end text-right mt-[4em] biglg:gap-[8em] xl:gap-[8em] 2xl:gap-[8em] gap-[1em] lg:gap-[1em] bg-[#DBE7FF] py-[0.5em] pr-3 md:pl-2 pl-2 lg:w-[315px]">
            <label
              htmlFor="total"
              className="block lg:text-[14px] text-[11px] font-semibold ml-[-3em]"
              style={{ whiteSpace: "nowrap" }}
            >
              Total Amount:
            </label>
            <div className="min-w-[70px] overflow-hidden">
              <span
                className="text-black lg:text-[14px] text-[11px] font-semibold"
                id="totalAmount"
              >
                {`${formatMoney(total.toFixed(2))}`}
              </span>
            </div>
          </div>
        </div>
        <div className="lg:hidden md:hidden flex justify-end items-end text-right mt-[4em] biglg:gap-[8em] xl:gap-[8em] 2xl:gap-[8em] gap-[2em] lg:gap-[1em] bg-[#DBE7FF] py-[0.5em] pr-3 md:pl-2 pl-[1em]  lg:w-[315px] w-[200px]">
          <label
            htmlFor="total"
            className="block lg:text-[14px] text-[11px] font-semibold ml-[-3em]"
            style={{ whiteSpace: "nowrap" }}
          >
            Total Amount:
          </label>
          <div className="min-w-[70px] overflow-hidden">
            <span
              className="text-black lg:text-[14px] text-[11px] font-semibold"
              id="totalAmount"
            >
              {`${formatMoney(total.toFixed(2))}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpenseLineTable;
