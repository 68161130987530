import React, { useState } from "react";
import { GroupButton } from "../../common";
import EditIcon from "../../../assets/icons/EditIcon";
import {
  AddSquareIcon,
  CalendarIcon,
  DownloadIcon,
} from "../../../assets/icons";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { CustomerT } from "../../../types";
import { useTranslation } from "react-i18next";
import ActionModal from "../../common/ActionModal";
import UploadIcon from "../../../assets/icons/svgicons/UploadIcon.svg";
import { useUserStore } from "../../../state";
import { BUSINESS_TYPE_RETAIL } from "../../../constants";
import useRetailBusinessStatus from "../../../hooks/invoice/useRetailBusinessStatus";
import generateActionButtons from "../../Sales/invoice/invoiceCreationModal";

interface CustomerHeaderProps {
  customer: CustomerT;
  toggleDrawer: () => void;
  setShowPrompt: (show: boolean) => void;
  setIsSidebarOpen: (show: boolean) => void;
  handleCreateInvoice: () => void;
}

export const CustomerHeader: React.FC<CustomerHeaderProps> = ({
  customer,
  toggleDrawer,
  setShowPrompt,
  setIsSidebarOpen,
  handleCreateInvoice,
}) => {
  const { t } = useTranslation("customer");
  const isRetailBusiness = useRetailBusinessStatus();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const actionButtons = generateActionButtons(
    isRetailBusiness,
    handleCreateInvoice,
    handleCreateInvoice
  );

  return (
    <div className="mt-4 md:mt-0">
      <div className="w-full md:hidden xl:text-[17px] lg:mt-[-0.5em] mt-[1em] md:mt-[-0.5em]">
        <GroupButton
          buttons={[
            {
              text: t("customer.createInvoice"),
              icon: <AddSquareIcon />,
              onClick: openModal,
              fontSize: "font-semibold text-[16px] gap-1",
            },
            {
              text: t("customer.edit"),
              icon: <EditIcon />,
              onClick: toggleDrawer,
              fontSize: "font-semibold text-[16px] gap-1",
            },
            {
              text: "Delete",
              icon: <DeleteIcon />,
              onClick: () => setShowPrompt(true),
              textColorClass: "text-[#FB0303]",
              fontSize: "font-semibold text-[16px] gap-1",
            },
          ]}
        />
      </div>

      {/* Modal to select invoice creation option */}
      <div>
        <ActionModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Select an invoice creation option"
          icon={UploadIcon}
          actionButtons={actionButtons}
        />
      </div>
    </div>
  );
};
