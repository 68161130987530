import { Alert } from "flowbite-react";
import React, { FormEvent, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button } from "../common";
import { InputComponent } from "../core/Forms";
import * as UI from "../common";
import LogoBlack from "../LogoBlack";
import { useTranslation } from "react-i18next";
import useCognito from "../../hooks/auth/useCognito";
import ForgetPasswordIcon from "../../assets/icons/forgetPasswordIcon";
import useViewport from "../../hooks/auth/useViewPort";

const NewPasswordView = () => {
  const navigate = useNavigate();
  const { confirmPassword, loading, error } = useCognito();
  const location = useLocation();
  const { t } = useTranslation("onboarding");

  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Set New Password");
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [verificationCode, setVerificationCode] = useState("");

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get("email") || "";

  const viewportWidth = useViewport();

  const handleNewPasswordSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!newPassword || !verificationCode) {
      return;
    }

    try {
      await confirmPassword(email, verificationCode, newPassword);
      setIsSuccess(true);
      setButtonText("Password Set Successfully");
      navigate("/login");
    } catch (err: any) {
      setIsSuccess(false);
      setButtonText("Set New Password");
      setErrorMessage(
        err?.response?.data?.message ||
          "An error occurred while resetting the password."
      );
    }
  };

  return (
    <>
      {/* Desktop View */}
      {viewportWidth.isDesktop && (
        <div className="flex items-center justify-center min-h-screen bg-[#F2F9FD]">
          <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
            <div className="flex justify-center">
              <ForgetPasswordIcon />
            </div>

            <div className="text-center mb-4 mt-[1em]">
              <h1 className="text-2xl font-semibold lg:text-3xl">
                {t("onboarding.resetyourpassword")}
              </h1>
              <p className="text-sm lg:text-base text-gray-600 mt-2">
                {t("onboarding.newPassword")}
              </p>
              {errorMessage && (
                <Alert color="failure" className="mt-4">
                  {errorMessage}
                </Alert>
              )}
            </div>

            <form onSubmit={handleNewPasswordSubmit}>
              <div className="mb-4">
                <label
                  htmlFor="verificationCode"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Verification Code
                </label>
                <InputComponent
                  placeholder="Verification Code"
                  id="verificationCode"
                  name="verificationCode"
                  type="text"
                  value={verificationCode}
                  handleChange={(e) => setVerificationCode(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Password
                </label>
                <InputComponent
                  placeholder="Enter new password"
                  id="newPassword"
                  name="newPassword"
                  type="text"
                  value={newPassword}
                  handleChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                />
              </div>

              <div className="mb-6">
                <UI.Button
                  text={buttonText}
                  variant="secondary"
                  isLoading={isLoading}
                  type="submit"
                  disabled={isLoading || loading}
                />
              </div>

              <div className="text-center">
                <Link
                  to="/"
                  className="text-blue-600 hover:underline text-sm font-semibold"
                >
                  Cancel
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Mobile View */}
      {!viewportWidth.isDesktop && (
        <div className="mt-[6em]">
          <div className="flex justify-center">
            <ForgetPasswordIcon />
          </div>

          <div className="text-center mb-4 mt-[1em]">
            <h1 className="text-2xl font-semibold lg:text-3xl">
              {t("onboarding.resetyourpassword")}
            </h1>
            <p className="text-sm lg:text-base text-gray-600 mt-2 px-[2em]">
              {t("onboarding.resetpasswordlink")}
            </p>
            {errorMessage && (
              <Alert color="failure" className="mt-4">
                {errorMessage}
              </Alert>
            )}
          </div>

          <form onSubmit={handleNewPasswordSubmit}>
            <div className="mb-4 px-[2em]">
              <label
                htmlFor="verificationCode"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                {t("onboarding.email")}
              </label>
              <InputComponent
                placeholder="Verification Code"
                id="verificationCode"
                name="verificationCode"
                type="text"
                value={verificationCode}
                handleChange={(e) => setVerificationCode(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div className="mb-4 px-[2em]">
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                New Password
              </label>
              <InputComponent
                placeholder="Enter new password"
                id="newPassword"
                name="newPassword"
                type="text"
                value={newPassword}
                handleChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-2 border border-gray-300 rounded-md"
              />
            </div>

            <div className="mb-6 px-[2em]">
              <UI.Button
                text={buttonText}
                variant="secondary"
                isLoading={isLoading}
              />
            </div>

            <div className="text-center">
              <Link
                to="/"
                className="text-blue-600 hover:underline text-sm font-semibold"
              >
                Cancel
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export { NewPasswordView };
