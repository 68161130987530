import React, { lazy, useEffect, useState } from "react";
import {
  createDraft,
  getInvoice,
  updateToPending,
} from "../../../backend-services";
import { Button } from "../../common";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  ICreateProfile,
  ICreateSalesData,
  ICustomer,
  ISetReminder,
  ItemInput,
} from "../../../@types";
import {
  createDraftData,
  createInvoiceData,
  InvoiceValidationErrors,
} from "../../../modules/invoice/invoice";
import {
  InvoiceErrors,
  validateInvoiceBasicInfo,
  validateInvoiceItemDetails,
  validateInvoiceSummary,
} from "../../../modules/validateInvoiceForm";
import { clearFieldError, handleErrors } from "../../../utils/handleErrors";
import toast from "react-hot-toast";
import useLineItems from "../../../modules/lineItem";
import { useUserStore } from "../../../state";
import LogoComponent from "../create/SalesCreate/LogoComponent";
import InvoiceDate from "../create/SalesCreate/InvoiceDate";
import LineTable from "../create/SalesCreate/LineTable";
import InvoiceCalculation from "../create/SalesCreate/InvoiceCalculation";
import BankDetails from "../create/SalesCreate/BankDetails";
import Notes from "../create/SalesCreate/Notes";
import CustomerCreateInfo from "../create/SalesCreate/CustomerInfo";
import { updateProfile } from "../../../backend-services";
import CreateInvoice from "../create/SalesCreate/CreateInvoice";
import InvoiceCurrency from "../create/SalesCreate/InvoiceCurrency";
import { CreateModal } from "../../common/CreateModal";
import SuccessBanner from "../../common/Banner/SuccessBanner";
import { checkSubscription } from "../../../utils/subscription";
import { servicesType } from "../../../constants/Subscription";
import { SubscriptionModal } from "../../common/Subscription/SubscriptionModal";
import { MobileSubscriptionModal } from "../../common/Subscription/MobileSubscriptionModal";
import DesktopSubscriptionModal from "../../common/Subscription/DesktopSubscriptionModal";
import { CustomerT } from "../../../types";
import { useTranslation } from "react-i18next";
import CompanyUpdate from "../create/SalesCreate/companyProfile";
import BusinessTypeSelector from "../../auth/BusinessTypeModal";
import PageLoader from "../../common/PageLoader";
import EnableReminder from "../create/Reminder/EnableReminder";
import { useCompanyStore } from "../../../state/useCompanyStore";

interface InvoiceFormProps {
  customerId?: string;
  invoiceData: ICreateSalesData;
  setInvoiceData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  headingText: string;
  updatedNewItem?: ItemInput;
  onCreateInvoice?: (event: any) => Promise<void>;
  showSidebar?: boolean;
}
const InvoiceParent: React.FC<InvoiceFormProps> = (props) => {
  const { t } = useTranslation("invoices");
  const userStore = useCompanyStore();
  const isRetail = userStore.isRetailBusiness();
  const [formData, setFormData] = React.useState<ICreateSalesData>({
    additionalInformation: "",
    customerId: "",
    description: "",
    currency: "NGN",
    discount: 0,

    inventories: [
      {
        id: "",
        name: "",
        amount: 0,
        quantity: 0,
        rate: 0,
        variations: [
          {
            type: "",
            name: "",
            id: "",
            price: 0,
            quantity: 0,
          },
        ],
      },
    ],
    services: [{ id: "", name: "", duration: "", price: 0 }],
    invoiceDueAt: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    isReoccurring: false,
    isReoccurringEndDate: "",
    isReoccurringStartDate: "",
    reoccurringGroupName: "",
    status: "pending",
    tax: 0,
    isPaymentCompleted: false,
    shouldSendEmail: true,
    exchangeRate: 1,
    paymentMethod: "",
    invoiceType: "inventory",
    logoBase64: "",
    customer: {
      firstName: "",
      lastName: "",
      id: "",
    },
    reminderSettings: [
      { days: 0, remindBy: "before", remindMe: false, isActive: false },
      { days: 0, remindBy: "due", remindMe: false, isActive: false },
      { days: 0, remindBy: "after", remindMe: false, isActive: false },
    ],
  });
  React.useEffect(() => {
    if (props.invoiceData) {
      setFormData((prevState: any) => ({
        ...prevState,
        additionalInformation: props.invoiceData.additionalInformation,
        customerId: props.invoiceData.customerId,
        customer: props.invoiceData.customerId ? customer : null,
        description: props.invoiceData.description,
        discount: props.invoiceData.discount,
        inventories: props.invoiceData.inventories,
        services: props.invoiceData.services,
        invoiceDueAt: props.invoiceData.invoiceDueAt,
        invoiceNumber: props.invoiceData.invoiceNumber,
        issueDate: props.invoiceData.issueDate,
        isReoccurring: props.invoiceData.isReoccurring,
        isReoccurringEndDate: props.invoiceData.isReoccurringEndDate,
        isReoccurringStartDate: props.invoiceData.isReoccurringStartDate,
        reoccurringGroupName: props.invoiceData.reoccurringGroupName,
        shippingCharge: props.invoiceData.shippingCharge,
        status: props.invoiceData.status,
        tax: props.invoiceData.tax,
        isPaymentCompleted: props.invoiceData.isPaymentCompleted,
        shouldSendEmail: props.invoiceData.shouldSendEmail,
        currency: props.invoiceData.currency,
        exchangeRate: props.invoiceData.exchangeRate,
        paymentMethod: props.invoiceData.paymentMethod,
        invoiceType: props.invoiceData.invoiceType,
        reminderSettings: props.invoiceData.reminderSettings,
      }));
    }
  }, [props.invoiceData]);

  const initialFormData: ICreateSalesData = {
    additionalInformation: "",
    customerId: "",
    description: "",
    currency: "NGN",
    discount: 0,
    inventories: [
      {
        id: "",
        name: "",
        amount: 0,
        quantity: 0,
        rate: 0,
        variations: [
          {
            type: "",
            name: "",
            id: "",
            price: 0,
            quantity: 0,
          },
        ],
      },
    ],
    services: [{ id: "", name: "", duration: "", price: 0 }],
    invoiceDueAt: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    isReoccurring: false,
    isReoccurringEndDate: "",
    isReoccurringStartDate: "",
    reoccurringGroupName: "",
    status: "pending",
    tax: 0,
    isPaymentCompleted: false,
    shouldSendEmail: true,
    exchangeRate: 1,
    paymentMethod: "",
    invoiceType: "inventory",
    logoBase64: "",
    reminderSettings: [
      { days: 0, remindBy: "before", remindMe: false, isActive: false },
      { days: 0, remindBy: "due", remindMe: false, isActive: false },
      { days: 0, remindBy: "after", remindMe: false, isActive: false },
    ],
  };

  const resetForm = () => {
    setFormData({
      ...(initialFormData as ICreateSalesData),
      invoiceDueAt: "",
      additionalInformation: "",
    });
    setValidationResults({});
    setCustomer(null);

    // Reset line items (inventories and services)
    setItems([{ id: "", name: "", rate: 0, quantity: 0 }]);
    setShowSidebar(false);
  };

  const [validationResults, setValidationResults] =
    React.useState<InvoiceErrors>({});
  const { invoiceNo } = useParams<{ invoiceNo: string }>();
  const navigate = useNavigate();
  const [customer, setCustomer] = React.useState<ICustomer | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isDraftLoading, setIsisDraftLoading] = React.useState<boolean>(false);
  const [subTotal, setSubtotal] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [calcTax, setCalcTax] = React.useState(0);
  const [calcDiscount, setCalcDiscount] = React.useState(0);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [isReoccurringChecked, setIsReoccurringChecked] = React.useState(false);
  const [showCreateSidebar, setShowCreateSidebar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [createdInvoiceId, setCreatedInvoiceId] = React.useState("");
  const [createdEmail, setCreatedEmail] = React.useState("");
  const [showRateInput, setShowRateInput] = useState(false);
  const [markAsPaid, setMarkAsPaid] = React.useState(false);
  const [sendEmail, setSendEmail] = React.useState(false);
  const [shouldSendWhatsApp, setShouldSendWhatsApp] = React.useState(false);
  const [shouldSendSMS, setShouldSendSMS] = React.useState(false);
  const [selectedCurrencyLabel, setSelectedCurrencyLabel] =
    React.useState("₦ Naira");
  const [selectedVariation, setSelectedVariation] = useState<
    string | undefined
  >(undefined);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionErrorMessage, setSubscriptionErrorMessage] =
    React.useState<string>("");
  const [error, setError] = React.useState<string>("");
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [bannerLinkText, setBannerLinkText] = useState("");
  const [inventories, setInventories] = useState([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [userData, setUserData] = useState<ICreateProfile | null>(null);

  const handleProfileUpdate = (updatedProfile: ICreateProfile) => {
    setUserData(updatedProfile);
  };

  const [draftValidationResults, setDraftValidationResults] =
    React.useState<InvoiceValidationErrors>({
      customerId: undefined,
      invoiceNumber: undefined,
      invoiceDueAt: "",
      description: undefined,
      status: undefined,
      issueDate: undefined,
      additionalInformation: undefined,
      shippingCharge: undefined,
      discount: undefined,
      tax: undefined,
      isReoccurring: undefined,
      isReoccurringStartDate: undefined,
      isReoccurringEndDate: undefined,
      reoccurringGroupName: undefined,
      task: undefined,
      inventories: undefined,
      services: undefined,
      currency: undefined,
      exchangeRate: undefined,
      paymentMethod: undefined,
      invoiceType: undefined,
      reminderSettings: undefined,
    });

  const { items, addItem, setItems, removeItem, updateCell, updateRow } =
    useLineItems([{ id: "", name: "", rate: 0, quantity: 0 }]);

  useEffect(() => {
    if (
      formData.reminderSettings?.every(
        (reminder) =>
          reminder.days === 0 &&
          reminder.remindBy === "" &&
          reminder.remindMe === false &&
          reminder.isActive === false
      )
    ) {
      setFormData((prevData) => ({
        ...prevData,
        reminderSettings: [
          {
            days: 0,
            remindBy: "",
            remindMe: false,
            isActive: false,
          },
        ],
      }));
    }
  }, [formData.reminderSettings]);

  React.useEffect(() => {
    clearFieldError("inventories", validationResults, setValidationResults);
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        inventories: items.map((item: ItemInput) => ({
          id: item.id,
          name: item.name,
          amount: 0,
          quantity: item.quantity || 0,
          rate: item.rate || 0,
          variations: item.variation || [],
        })),
      } as ICreateSalesData;
    });
  }, [items]);

  React.useEffect(() => {
    clearFieldError("services", validationResults, setValidationResults);
    setFormData((prevState: ICreateSalesData) => {
      return {
        ...prevState,
        services: items,
      };
    });
  }, [items]);

  const handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    setFormData((prevData) => {
      return { ...prevData, [name]: value };
    });
    clearFieldError(name, validationResults, setValidationResults);
  };

  useEffect(() => {
    let subTotal = 0;

    // Calculate subtotal based on the type of business
    if (isRetail && formData && formData.inventories) {
      for (const inventory of formData.inventories) {
        if (inventory.variations && inventory.variations.length > 0) {
          const variationToUse = selectedVariation
            ? inventory.variations.find(
                (variation) => variation.type === selectedVariation
              )
            : inventory.variations[0];

          if (variationToUse) {
            subTotal += inventory.quantity * inventory.rate;
          }
        } else {
          subTotal += inventory.quantity * inventory.rate;
        }
      }
    } else if (!isRetail && formData && formData.services) {
      for (const service of formData.services) {
        const serviceAmount = Number(service.price);
        subTotal += serviceAmount;
      }
    }

    let discountAmount =
      subTotal * (formData?.discount ? formData.discount / 100 : 0);
    let taxAmount = subTotal * (formData?.tax ? formData.tax / 100 : 0);
    let total = subTotal - discountAmount + taxAmount;

    setSubtotal(subTotal);
    setTotal(total);
    setCalcTax(taxAmount);
    setCalcDiscount(discountAmount);
  }, [formData, userStore, selectedVariation]);
  useEffect(() => {
    setFormData((prevData) => {
      return { ...prevData, total: total.toFixed(2).toString() };
    });
  }, [total]);

  useEffect(() => {
    handleDropDownChange("currency", selectedCurrencyLabel);
  }, []);

  const handleDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    const currencyValue = value === "₦ Naira" ? "NGN" : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: currencyValue || "NGN",
    }));
    setSelectedCurrencyLabel(value);
    setShowRateInput(currencyValue !== "NGN");
  };

  const handlePaymentDropDownChange = (name: string, value: string) => {
    clearFieldError(name, validationResults, setValidationResults);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const location = useLocation();
  const { customerId } = useParams();
  React.useEffect(() => {
    if (customerId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        name: decodeURIComponent(customerId || ""),
      }));
    }
  }, [customerId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const customerId = queryParams.get("customerId");
    const customerName = queryParams.get("customerName");
    const customerPhone = queryParams.get("customerPhone");

    if (customerId && customerName) {
      setFormData((prevData) => ({
        ...prevData,
        customerId,
        customerName,
        customerPhone,
      }));
    }
  }, [location.search]);

  const handleSetCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      if (selectedCustomer.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
          name: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
        }));
      }
    } else {
      setCustomer(null);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        customerId: "",
        name: "",
      }));
    }
  };

  const setLogoUrl = (url: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      logoBase64: url,
    }));
  };

  const handleEditProfileLogo = async () => {
    if (!formData.logoBase64 || formData.logoBase64.trim() === "") {
      setErrorMessage("No logo to update.");
      return;
    }

    setIsLoading(true);
    try {
      const updatedLogo = { brandLogo: formData.logoBase64 };
      await updateProfile(updatedLogo);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setErrorMessage("Unable to update profile logo.");
    }
  };

  useEffect(() => {
    if (
      typeof formData.exchangeRate !== "number" &&
      isNaN(Number(formData.exchangeRate))
    ) {
      // Set the exchange rate to 1
      setFormData((prevData) => ({
        ...prevData,
        exchangeRate: 1,
      }));
    }
  }, [formData.exchangeRate]);

  const handleViewInvoice = () => {
    if (createdInvoiceId) {
      const viewInvoiceRoute = `/invoices/${createdInvoiceId}/view`;
      navigate(viewInvoiceRoute);
    }
  };

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const processCreateInvoice = async (
    sendEmail: boolean,
    markedAsPaid: boolean,
    shouldSendWhatsApp: boolean,
    shouldSendSMS: boolean
  ) => {
    try {
      setIsLoading(true);
      setShowCreateSidebar(false);
      const {
        invoiceDueAt,
        issueDate,
        inventories,
        services,
        discount,
        tax,
        customerId,
        isReoccurring,
      } = formData;
      const user = userStore.user;
      const isRetail = userStore.isRetailBusiness();
      const validInvoiceDueAt = invoiceDueAt ? new Date(invoiceDueAt) : null;
      const validIssueDate = issueDate ? new Date(issueDate) : null;

      if (!validInvoiceDueAt || isNaN(validInvoiceDueAt.getTime())) {
        throw new Error("Invalid invoice due date");
      }

      if (!validIssueDate || isNaN(validIssueDate.getTime())) {
        throw new Error("Invalid issue date");
      }

      const invoiceData: ICreateSalesData = {
        customerId: customerId,
        shouldSendEmail: sendEmail,
        isPaymentCompleted: markedAsPaid,
        shouldSendWhatsApp: shouldSendWhatsApp,
        shouldSendSMS: shouldSendSMS,
        isReoccurring: isReoccurring,
        invoiceDueAt: validInvoiceDueAt.toISOString(),
        issueDate: validIssueDate.toISOString(),
        inventories: isRetail
          ? inventories.length
            ? inventories.map(
                (i: {
                  id: string;
                  name: string;
                  rate: number;
                  quantity: number;
                  variations?: any;
                }) => ({
                  id: i.id,
                  name: i.name,
                  amount: 0,
                  quantity: Number(i.quantity),
                  rate: Number(i.rate),
                  variations: i.variations ? i.variations : undefined,
                })
              )
            : []
          : [],
        services: !isRetail
          ? services.length
            ? services.map(
                (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                  id: s.id,
                  name: s.name,
                  duration: s.duration,
                  price: Number(s.price),
                })
              )
            : []
          : [],
        reminderSettings: formData.reminderSettings,
        discount: Number(discount ? discount : 0),
        tax: Number(tax ? tax : 0),
        isReoccurringEndDate: formData.isReoccurringEndDate || "",
        reoccurringGroupName: formData.reoccurringGroupName || "",
        status: formData.status || "pending",
        description: formData.description || "",
        additionalInformation: formData.additionalInformation || "",
        currency: formData.currency || "",
        exchangeRate: formData.exchangeRate,
        paymentMethod: formData.paymentMethod || "",
        invoiceType: isRetail ? "inventory" : "service",
      };

      const promises = [];

      if (customer) {
        if (invoiceNo) {
          const taxValue = formData.tax ? Number(formData.tax) / 100 : 0;
          const formattedTax = taxValue.toFixed(2);
          const updatedInvoiceData = {
            status: "pending",
            invoiceDueAt: validInvoiceDueAt.toISOString(),
            issueDate: validIssueDate.toISOString(),
            invoiceType: "freeform",
            inventories: isRetail
              ? inventories.length
                ? inventories.map(
                    (i: {
                      id: string;
                      name: string;
                      rate: number;
                      quantity: number;
                      variations?: any;
                    }) => ({
                      id: i.id,
                      name: i.name,
                      amount: 0,
                      quantity: Number(i.quantity),
                      rate: Number(i.rate),
                      variations: i.variations ? i.variations : undefined,
                    })
                  )
                : []
              : [],
            services: !isRetail
              ? services.length
                ? services.map(
                    (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                      id: s.id,
                      name: s.name,
                      duration: s.duration,
                      price: Number(s.price),
                    })
                  )
                : []
              : [],
            reminderSettings: formData.reminderSettings,
            discount: Number(formData.discount ? formData.discount / 100 : 0),
            tax: Number(formattedTax),
          };

          promises.push(
            updateToPending({ id: invoiceNo, data: updatedInvoiceData })
          );
        } else {
          promises.push(createInvoiceData(invoiceData, customer, isRetail));
        }
      }

      const results = await Promise.all(promises);
      const createdUpdatedInvoice = results[0];

      const createdUpdatedInvoiceId = createdUpdatedInvoice?.id;
      const createdEmail = customer?.email || "";

      setCreatedInvoiceId(createdUpdatedInvoiceId);
      setCreatedEmail(createdEmail);
      setShowSuccessModal(true);
      // setBannerMessage('New Invoice Created Successfully. To view invoice, Click');
      setBannerMessage(t("successText"));
      setBannerLinkText(t("successText1"));
      setIsBannerVisible(true);
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      setErrorMessage("An error occurred while processing the invoice.");
      toast.error("An error occurred while processing the invoice.");
      if (error.response) {
        const errorMessage = error.response.data;
        setError(errorMessage);
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateInvoice = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault();

    try {
      const basicInfoValid = validateInvoiceBasicInfo(
        formData,
        setValidationResults
      );
      const itemDetailsValid = validateInvoiceItemDetails(
        formData,
        setValidationResults,
        userStore.isRetailBusiness()
      );
      const summaryValid = validateInvoiceSummary(
        formData,
        setValidationResults
      );

      if (basicInfoValid && itemDetailsValid && summaryValid) {
        const subscriptionCheck = checkSubscription(servicesType.Invoices);
        if (subscriptionCheck.status) {
          setShowSidebar(true);
        } else {
          setErrorMessage("Insufficient subscription to create invoices.");
          setShowSubscriptionModal(true);
          setSubscriptionErrorMessage(subscriptionCheck.message || "");
        }
      } else {
        setErrorMessage("Please fill out all required fields correctly.");

        const errorElements = [
          { id: "customer-info", hasError: !basicInfoValid },
          { id: "invoice-date", hasError: !basicInfoValid },
          { id: "invoice-currency", hasError: !basicInfoValid },
          { id: "line-table", hasError: !itemDetailsValid },
        ];

        const firstErrorElement = errorElements.find((el) => el.hasError);

        if (firstErrorElement) {
          const element = document.getElementById(firstErrorElement.id);
          if (element) {
            element.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        }
      }
    } catch (error) {
      console.error("Failed to create invoice:", error);
      toast.error("Failed to create invoice. Please try again later.");
    }
  };

  const onCreateInvoice = async () => {
    await processCreateInvoice(
      sendEmail,
      markAsPaid,
      shouldSendWhatsApp,
      shouldSendSMS
    );
    resetForm();
    setIsBannerVisible(true);
    setShowSidebar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    console.log("Validation results in QuoteParent:", validationResults);
  }, [validationResults]);

  function handleSendEmailChange(checked: boolean) {
    setSendEmail(checked);
  }
  function handleMarkAsPaidChange(event: {
    target: { checked: boolean | ((prevState: boolean) => boolean) };
  }) {
    setMarkAsPaid(event.target.checked);
  }
  function handleshouldSendWhatsAppChange(checked: boolean) {
    setShouldSendWhatsApp(checked);
  }
  function handleshouldSendSMSChange(checked: boolean) {
    setShouldSendSMS(checked);
  }
  const handleIsReoccurringChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const target = e.target as HTMLInputElement;
    setFormData((prevData) => ({
      ...prevData,
      isReoccurring: target.checked,
    }));
    setIsReoccurringChecked(target.checked);
  };

  const onSaveDraft = async (event: any) => {
    event.preventDefault();
    await processCreateDraftIInvoice();
  };

  const fetchDraftInvoice = async (invoiceNo: string) => {
    if (!invoiceNo) return;

    setLoading(true);

    try {
      const response = await getInvoice(invoiceNo);
      const draftData = response;
      // Ensure we only process the invoice if it is a draft
      if (draftData && draftData.status === "draft") {
        setFormData((prevData) => ({
          ...prevData,
          customerId: draftData.customer?.id || "",
          invoiceDueAt: draftData.invoiceDueAt
            ? new Date(draftData.invoiceDueAt).toISOString()
            : "",
          issueDate: draftData.issueDate
            ? new Date(draftData.issueDate).toISOString()
            : "",
          total: draftData.total || 0,
          currency: draftData.currency || "NGN",
          description: draftData.description || "",
          additionalInformation: draftData.additionalInformation || "",
          exchangeRate: draftData.exchangeRate || 1,
          invoiceType: draftData.invoiceType || "inventory",
          isReoccurring: draftData.isReoccurring || false,
          status: draftData.status || "draft",
        }));

        setCustomer({
          id: draftData.customer?.id || "",
          firstName: draftData.customer?.firstName || "",
          lastName: draftData.customer?.lastName || "",
          phone: draftData.customer?.phone || "",
          email: draftData.customer?.email || "",
        });

        // Handle inventory or service items
        if (draftData.invoiceType === "inventory") {
          setItems(
            draftData.inventories?.map(
              (i: {
                id: any;
                name: any;
                amount: any;
                quantity: any;
                rate: any;
                variations: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: i.amount || 0,
                quantity: Number(i.quantity) || 0,
                rate: Number(i.rate) || 0,
                variations: i.variations || [],
              })
            ) || []
          );
        } else if (draftData.invoiceType === "service") {
          setItems(
            draftData.services?.map(
              (service: {
                id: any;
                name: any;
                price: string | any[];
                duration: any;
              }) => ({
                id: service.id,
                name: service.name,
                price:
                  service.price?.length > 0
                    ? parseFloat(service.price[0].amount)
                    : 0,
                duration: service.duration,
              })
            ) || []
          );
        }
      } else {
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (invoiceNo) {
      setLoading(true);
      fetchDraftInvoice(invoiceNo);
    } else {
      setIsLoading(false);
    }
  }, [invoiceNo]);

  useEffect(() => {}, [customer, inventories]);

  const processCreateDraftIInvoice = async (e?: any) => {
    setIsisDraftLoading(true);
    setShowSidebar(false);

    const user = userStore.user;
    const isRetail = userStore.isRetailBusiness();

    const invoiceData: ICreateSalesData = {
      customerId: customer?.id,
      description: formData.description,
      invoiceDueAt: formData?.invoiceDueAt
        ? new Date(formData?.invoiceDueAt)?.toISOString()
        : undefined,
      issueDate: formData?.issueDate
        ? new Date(formData?.issueDate)?.toISOString()
        : undefined,
      additionalInformation: formData.additionalInformation,
      discount: Number(formData.discount ? formData.discount / 100 : 0),
      tax: Number(formData.tax ? formData.tax / 100 : 0),
      isReoccurring: formData.isReoccurring,
      status: "draft",
      currency: formData.currency,
      exchangeRate: formData.exchangeRate,
      invoiceType: isRetail ? "inventory" : "service",
      // @ts-ignore
      reminderSettings: formData.reminderSettings,
      inventories: isRetail
        ? formData.inventories.length
          ? formData.inventories.map(
              (i: {
                id: string;
                name: string;
                rate: number;
                quantity: number;
                variations?: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: 0,
                quantity: Number(i.quantity),
                rate: Number(i.rate),
                variations: i.variations ? i.variations : undefined,
              })
            )
          : []
        : [],
      services: !isRetail
        ? formData.services.length
          ? formData.services.map(
              (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                id: s.id,
                name: s.name,
                duration: s.duration,
                price: Number(s.price),
              })
            )
          : undefined
        : undefined,
      ...(formData.isReoccurring && {
        reoccurringGroupName: formData.reoccurringGroupName,
        isReoccurringStartDate: new Date().toISOString(),
        isReoccurringEndDate: new Date(
          formData.isReoccurringEndDate || ""
        ).toISOString(),
      }),
    };

    try {
      const response = await createDraftData(invoiceData, customer, isRetail);
      setIsisDraftLoading(false);
      navigate("/invoices");
      toast.success("Draft saved successfully!");
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      setIsisDraftLoading(false);
      if (error.response) {
        const errorMessage = error.response.data;
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
      const errorEl = document.getElementById("error-message");
      errorEl?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document
        .getElementById("top-of-page")
        ?.scrollIntoView({ behavior: "smooth" });
      setIsLoading(false);
      setErrorMessage("An error occurred.");
    }
  };
  const { serviceData } = location.state || {};
  useEffect(() => {
    if (serviceData) {
      const ngnPrice =
        serviceData.price?.find(
          (priceItem: { amount: number; currency: string }) =>
            priceItem.currency === "NGN"
        )?.amount || 0;

      setFormData((prevFormData) => ({
        ...prevFormData,
        services: [
          {
            id: serviceData.id || "",
            name: serviceData.name || "",
            duration: serviceData.duration || "",
            price: ngnPrice,
          },
        ],
      }));
    }
  }, [serviceData]);

  const isMobile = window.innerWidth < 768;

  const updateReminderSettings = (updatedSettings: any) => {
    setFormData((prevData) => ({
      ...prevData,
      reminderSettings: updatedSettings,
    }));
  };

  console.log("Parent FormData Reminder Settings:", formData.reminderSettings);
  useEffect(() => {
    console.log("Form Data:", formData);
  }, [formData]);

  return (
    <>
      {loading && <PageLoader />}

      {isBannerVisible && (
        <SuccessBanner
          message={bannerMessage}
          linkText={bannerLinkText}
          onLinkClick={handleViewInvoice}
          onClose={handleCloseBanner}
        />
      )}
      <div className="flex items-left gap-[8.5px] ml-[2em] ">
        <a onClick={() => navigate(-1)}>
          <svg
            className="mt-[-2px] ml-[-2em] cursor-pointer"
            width="10"
            height="16"
            viewBox="0 0 10 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8.5 1L1.5 8L8.5 15" stroke="#33363F" strokeWidth="2" />
          </svg>
        </a>
        <h1 className="hidden lg:block md:block flex items-center xl:text-[20px] text-[16px] ml-[-1em] mt-[-0.4em] xl:mt-[-0.4em] font-bold">
          {t("addNewInvoice")}
        </h1>
        <h1 className="lg:hidden md:hidden flex items-center xl:text-[20px] text-[16px] ml-[-1em] mt-[-0.4em] xl:mt-[-0.4em] font-bold">
          {t("addNewInvoice")}
        </h1>
      </div>

      <div
        className="mx-auto h-full bg-white lg:ml-0 border-2 border-gray-100 mt-3 "
        id="invoice-parent"
      >
        {errorMessage && (
          <p className="text-[red] lg:pl-[3em] pl-3 md:pl-[3em] mt-3">
            {errorMessage}
          </p>
        )}
        <div className="flex justify-between lg:pt-[4em] pt-[2em] ">
          <div className="lg:pl-[3em] pl-3 md:pl-[3em] cursor-pointer ">
            <LogoComponent
              logoUrl={formData.logoBase64 || ""}
              setLogoUrl={setLogoUrl}
              error={undefined}
              onUploadSuccess={handleEditProfileLogo}
            />
          </div>
          <h1 className="hidden lg:block md:block xl:text-[24px] biglg:text-[24px] lg:text-[24px] text-[18px] font-medium lg:px-[3em] pr-3 md:pr-[3em]">
            INVOICE
          </h1>
          <h1 className="lg:hidden md:hidden text-[18px] font-semibold lg:px-[3em] pr-3 md:pr-[3em] mt-[1em]">
            INVOICE
          </h1>
        </div>
        <div className="hidden lg:block md:block">
          <div className="flex justify-between ">
            <div className="flex flex-col mt-[2em]">
              <div className="pl-3 lg:pl-[3em] ">
                <CompanyUpdate
                  show={true}
                  toolTipText={t("businessNameTooltip")}
                  toolTipText2={t("businessAddressTooltip")}
                  toolTipText3={t("phoneNumberTooltip")}
                  toolTipText4={t("RCNumberTooltip")}
                  onCreateProfile={handleProfileUpdate}
                />
              </div>
              <div
                id="customer-info"
                className="lg:pl-[3em] pl-3 md:pl-[3em] mt-[-1em]"
              >
                <CustomerCreateInfo
                  handleSetCustomer={handleSetCustomer}
                  formData={formData}
                  customer={customer}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  toolTipText={t("billTooltip")}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <div id="invoice-date" className="pr-[1em] md:pr-[3em] mt-[-1em]">
                <InvoiceDate
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  toolTipText={t("IssuedTooltip")}
                  toolTipText2={t("dueTooltip")}
                  handleInputChange={handleInputChange}
                />
              </div>
              <div id="invoice-currency" className="lg:mt-[3em]">
                <InvoiceCurrency
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  showRateInput={showRateInput}
                  toolTipText={t("currencyTooltip")}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="lg:hidden md:hidden">
          <div className="flex justify-between ">
            <div className="flex flex-col mt-[2em]">
              <div className="pl-3 lg:pl-[3em] ">
                <CompanyUpdate show={true} />
              </div>
            </div>
            <div className="flex flex-col">
              <div
                id="invoice-date"
                className="pr-[1em] md:pr-[3em] mt-[-2.2em]"
              >
                <InvoiceDate
                  handleDropDownChange={handleDropDownChange}
                  formData={formData}
                  selectedCurrencyLabel={selectedCurrencyLabel}
                  setValidationResults={setValidationResults}
                  validationResults={validationResults}
                  setFormData={setFormData}
                  toolTipText={t("IssuedTooltip")}
                  toolTipText2={t("dueTooltip")}
                  handleInputChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div
            id="customer-info"
            className="lg:pl-[3em] pl-3 md:pl-[3em] mt-[-1em]"
          >
            <CustomerCreateInfo
              handleSetCustomer={handleSetCustomer}
              formData={formData}
              customer={customer}
              setValidationResults={setValidationResults}
              validationResults={validationResults}
              toolTipText={t("billTooltip")}
            />
          </div>
          <div id="invoice-currency" className="lg:mt-[3em]">
            <InvoiceCurrency
              handleDropDownChange={handleDropDownChange}
              formData={formData}
              selectedCurrencyLabel={selectedCurrencyLabel}
              setValidationResults={setValidationResults}
              validationResults={validationResults}
              setFormData={setFormData}
              showRateInput={showRateInput}
              toolTipText={t("currencyTooltip")}
            />
          </div>
        </div>

        <p className="lg:hidden md:hidden text-[16px] font-semibold flex justify-start text-left mt-[3em] pl-3">
          Item Details
        </p>
        <div
          id="line-table"
          className="lg:mt-[em] mt-[-1em] md:px-[3em] md:mt-[3em] "
        >
          <LineTable
            items={items}
            addItem={addItem}
            setItems={setItems}
            removeItem={removeItem}
            updateCell={updateCell}
            updateRow={updateRow}
            formData={formData}
            setFormData={setFormData}
            invoiceNo={invoiceNo}
            validationResults={validationResults}
            setValidationResults={setValidationResults}
            selectedCurrencyLabel={selectedCurrencyLabel}
          />
        </div>
        <div className="hidden lg:block md:block">
          <div className="pr-[3em] flex justify-between items-end">
            <div className="pl-[3em] flex flex-col justify-between h-full">
              <BankDetails />
            </div>
            <div className="flex flex-col justify-between h-full">
              <InvoiceCalculation
                subTotal={subTotal}
                formData={formData}
                calcDiscount={calcDiscount}
                calcTax={calcTax}
                total={total}
                handleInputChange={handleInputChange}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-start lg:hidden md:hidden ">
          <div className="pr-[3em]">
            <InvoiceCalculation
              subTotal={subTotal}
              formData={formData}
              calcDiscount={calcDiscount}
              calcTax={calcTax}
              total={total}
              handleInputChange={handleInputChange}
            />
          </div>
        </div>
        <hr className="border mt-[3em]" />
        <div className="hidden lg:block md:block">
          <div className=" flex justify-between md:gap-[3em]">
            <div className="pl-[3em]">
              <Notes
                formData={formData}
                handleInputChange={handleInputChange}
              />
            </div>
            <div>
              <EnableReminder
                selectedReminders={formData.reminderSettings || []}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
          </div>
        </div>
        <div className="lg:hidden md:hidden flex flex-col">
          <div className="px-[1em]">
            <BankDetails />
          </div>
          <div className="px-[1em]">
            <Notes formData={formData} handleInputChange={handleInputChange} />
          </div>
        </div>
        <hr className="border mt-[3em]" />
        <div className="flex justify-end pr-[3em] ">
          {onSaveDraft && (
            <div className="hidden md:block mt-3  p-2">
              <Button
                text={t("saveAsDraft")}
                size="medium"
                outline
                onClick={onSaveDraft}
                isLoading={isDraftLoading}
                disabled={isDraftLoading}
              />
            </div>
          )}
          <div className="hidden md:block mt-5">
            <Button
              text={t("newInvoice")}
              size="medium"
              type="submit"
              onClick={handleCreateInvoice}
              data-testid="create-invoice-desktop-button"
            />
          </div>
        </div>
        <div className="flex justify-center md:hidden lg:hidden">
          {onSaveDraft && (
            <div className=" md:hidden mt-3  p-2">
              <Button
                text={t("saveAsDraft")}
                size="small"
                outline
                onClick={onSaveDraft}
                isLoading={isDraftLoading}
                disabled={isDraftLoading}
              />
            </div>
          )}
          <div className="md:hidden mt-5">
            <Button
              text={t("newInvoice")}
              size="small"
              type="submit"
              onClick={handleCreateInvoice}
              data-testid="create-invoice-mobile-button"
            />
          </div>
        </div>
        {showSidebar && (
          <CreateModal>
            <CreateInvoice
              formData={formData}
              handleInputChange={handleInputChange}
              handlePaymentDropDownChange={handlePaymentDropDownChange}
              handleIsReoccurringChange={handleIsReoccurringChange}
              validationResults={validationResults}
              setValidationResults={setValidationResults}
              invoiceNo={invoiceNo}
              sendEmail={sendEmail}
              markAsPaid={markAsPaid}
              shouldSendWhatsApp={shouldSendWhatsApp}
              shouldSendSMS={shouldSendSMS}
              handleMarkAsPaidChange={handleMarkAsPaidChange}
              handleSendEmailChange={handleSendEmailChange}
              handleshouldSendWhatsAppChange={handleshouldSendWhatsAppChange}
              handleshouldSendSMSChange={handleshouldSendSMSChange}
              errorMessage={errorMessage}
              error={error}
              customer={customer}
              showMarkAsPaid={true}
              onCreateInvoice={onCreateInvoice}
              isLoading={isLoading}
              onClose={() => setShowSidebar(false)}
            />
          </CreateModal>
        )}
        {showSubscriptionModal && (
          <SubscriptionModal>
            <MobileSubscriptionModal
              onClose={() => setShowSubscriptionModal(false)}
              titleText={`Need to access more features?`}
              subtitleText={subscriptionErrorMessage}
            />
          </SubscriptionModal>
        )}
      </div>
    </>
  );
};

export default InvoiceParent;
