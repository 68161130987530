export function calculateDurationFromDate(inputDateString: any): string {
  const inputDate = new Date(inputDateString);
  const currentDate = new Date();

  // Use number type for timeDifference
  const timeDifference: number = inputDate.getTime() - currentDate.getTime();

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  if (days > 0) {
    return `${days}d ${hours}h ${minutes}m`;
  } else if (hours > 0) {
    return `${hours}h ${minutes}m`;
  } else {
    return `${minutes}m`;
  }
}

const today = new Date();
today.setHours(0, 0, 0, 0);

export const minDate = today;

export function convertDurationToDate(duration: any) {
  const durationArray = duration.match(/\d+[dhm]/g);

  if (!durationArray) {
    return null;
  }

  const now = new Date();
  let futureDate = new Date(now);

  durationArray.forEach((item: any) => {
    const value = parseInt(item.slice(0, -1), 10);
    const unit = item.slice(-1);

    switch (unit) {
      case "d":
        futureDate.setDate(now.getDate() + value);
        break;
      case "h":
        futureDate.setHours(now.getHours() + value);
        break;
      case "m":
        futureDate.setMinutes(now.getMinutes() + value);
        break;
      default:
        break;
    }
  });

  return futureDate;
}

export function getYesterdayDate(): string {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const day = yesterday.getDate();
  const monthIndex = yesterday.getMonth();
  const month = monthNames[monthIndex];

  // Add ordinal suffix to the day
  const suffix =
    day % 10 === 1 && day !== 11
      ? "st"
      : day % 10 === 2 && day !== 12
      ? "nd"
      : day % 10 === 3 && day !== 13
      ? "rd"
      : "th";

  return `${month} ${day}${suffix}`;
}
