import * as React from "react";
import { CloseIcon, SearchIcon } from "../../../assets/icons";
import { GroupButton } from "../GroupButtons";
import { SearchInput } from "../SearchInput";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { FILTER_LIST } from "../../../constants";
import Chip from "../Chip";
import { Filter } from "../../../@types/inventory";
type FilterCallback = (filter: Filter) => void;

interface HeadProps {
  buttons?: Array<{
    text: string;
    icon: any;
    onClick?: () => void;
    href?: string;
  }>;
  handleSearch?: (text: string) => void;
  handleFilter?: FilterCallback;
  filter?: Filter;
  handleSort?: (text: string) => void;
  handleSearchSelect?: (item: any) => void;
  isSearching?: boolean | undefined;
  searchedDone?: boolean | undefined;
  setSearchedDone?: (searchedDone: boolean) => void;
  searchResult: any[];
  searchDisplayKey: string;
  searchPlaceHolder: string;
}
const MobileHead: React.FC<HeadProps> = (props) => {
  const [text, setText] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(" Recent");
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const dropDownArray = FILTER_LIST;

  const handleSearch = async (text: string) => {
    if (!text || text.length < 2) return;
    try {
      await props.handleSearch?.(text);
    } catch (error) {
      console.error("Error occurred during search:", error);
    }
  };

  const handleDropDownChange = (value: string) => {
    props.handleSort ? props.handleSort(selectedValue) : null;
  };

  const handleChipClose = (chipKey: keyof Filter) => {
    if (props?.filter) {
      let newFilter = { ...props?.filter };
      if (chipKey in newFilter) {
        if (typeof newFilter[chipKey] === "string") {
          (newFilter[chipKey] as string) = "";
        }
      }
      props.handleFilter ? props.handleFilter(newFilter as Filter) : undefined;
    }
  };

  const renderFilterChip = () => {
    const chips = [];
    const filterLabels = {
      status: "Status",
      isReoccurring: { true: "Reoccurring", false: "One-time" },
      billable: { true: "Billable", false: "Not Billable" },
      paymentMethod: "Payment Method",
    };

    if (props.filter) {
      for (const key in filterLabels) {
        const filterKey = key as keyof Filter;
        if (
          filterKey in props.filter &&
          props.filter[filterKey] !== null &&
          props.filter[filterKey] !== ""
        ) {
          const label = props.filter[filterKey];
          chips.push(
            <Chip
              key={filterKey}
              label={label}
              clearAction={() => handleChipClose(filterKey)}
            />
          );
        }
      }
    }

    return chips;
  };

  return (
    <>
      <div className="md:hidden w-full">
        <div
          className={`${
            props.buttons ? "flex" : ""
          } flex-row-reverse gap-[28px] justify-between border-transparent items-center lg:hidden`}
        >
          {props.buttons && <GroupButton buttons={props.buttons} />}
          <div>
            <SearchInput
              placeholder={props.searchPlaceHolder}
              displayKey={props.searchDisplayKey}
              isLoading={props.isSearching}
              value={text}
              searchedDone={props.searchedDone}
              setSearchedDone={props.setSearchedDone}
              onSelect={props.handleSearchSelect}
              handleChangeText={handleSearch}
              clearIcon={CloseIcon}
              icon={<SearchIcon />}
              data={props.searchResult}
              emptyMessage={"This Item is not found"}
              dropdownClassName="w-full"
              searchKey={["customer.firstName", "vendorName", "name"]}
              dropdownStyle={{
                maxHeight: "500px",
                overflowY: "auto",
              }}
              classNames="w-full border border-transparent bg-white max-h-[48px] min-h-[48px] placeholder:text-[15.25px] h-[39.77px] rounded-[4px] p-2 mt-[5px] focus:outline-none focus:shadow-outline appearance-none lg:focus:ring-0 leading-normal"
            />
          </div>
        </div>
        <div className="z-9 mt-[em]">
          <DropdownComponent
            options={dropDownArray}
            value={selectedValue}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
            isSubmitted={isSubmitted}
            onChange={handleDropDownChange}
            dropdownClassName="w-full py-2 mt-[1em] rounded py-1 focus:ring-0 pointer-events bg-white z-9"
            iconClassName="mt-[1.2em]"
          />
        </div>
      </div>

      <div className="flex">{renderFilterChip()}</div>
    </>
  );
};

export { MobileHead };
