import React from "react";
import { CloseIcon } from "../../../assets/icons";
import cx from "classnames";
import toast from "react-hot-toast";
import { createCustomer } from "../../../backend-services";
import CreateForm from "./CreateForm";
import { CustomerT, newCustomerT } from "../../../types";
import { ICustomer } from "../../../@types";
import { validatePhoneNumber } from "../../../utils";

interface CreateCustomerProps {
  onCustomerCreate?: (customer: any) => void;
  show: boolean;
  onClose?: () => void;
  fetchCustomers?: () => void;
  customerListNavigate?: boolean;
  createCustomers?: (newCustomer: any) => void;
}

const CreateCustomer = ({
  onCustomerCreate,
  show,
  onClose,
  fetchCustomers,
  customerListNavigate,
  createCustomers,
}: CreateCustomerProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [backendErrorMessage, setBackendErrorMessage] = React.useState("");
  const [formData, setFormData] = React.useState<ICustomer>({} as ICustomer);

  function isErrorResponse(
    error: any
  ): error is { response: { data: { message: string } } } {
    return (
      typeof error === "object" &&
      error.response &&
      typeof error.response === "object" &&
      error.response.data &&
      typeof error.response.data === "object" &&
      error.response.data.message
    );
  }

  const handleCreateCustomer = async (customer: CustomerT) => {
    setIsLoading(true);
    setErrorMessage("");
    setBackendErrorMessage("");

    if (!customer.phone || !validatePhoneNumber(customer.phone)) {
      setErrorMessage("Please input a valid phone number");
      setBackendErrorMessage("");
      setIsLoading(false);
      return;
    }

    try {
      let data;

      if (onCustomerCreate) {
        data = await createCustomer({
          ...customer,
          phone: customer.phone,
          isOptedIn: false,
          outletId: "",
        });
      } else if (createCustomers) {
        data = createCustomers({
          ...customer,
          phone: customer.phone,
          outletId: "",
        });
      }

      setIsLoading(false);

      if (data && !data.firstName && !data.lastName && !data.metadata) {
        toast.error(
          "A customer with this phone number or email already exists."
        );
      } else {
        fetchCustomers && fetchCustomers();
        setIsOpen(false);
        toast.success("Customer created successfully!");

        if (data && onCustomerCreate) {
          onCustomerCreate({
            id: data.id,
            name: `${data.firstName} ${data.lastName}`,
            email: data.email,
            businessName: data.businessName,
            phone: data.phone,
            address: data.address,
            city: data.city,
            state: data.state,
          });
        }
      }
    } catch (error: any) {
      setIsLoading(false);

      if (isErrorResponse(error)) {
        const errorMessage = error.response.data.message;
        setErrorMessage(errorMessage);
        setBackendErrorMessage(errorMessage);
      } else {
        setErrorMessage(error.message);
        setBackendErrorMessage(error.message);
      }
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  React.useEffect(() => {
    setIsOpen(show);
  }, [show]);

  return isOpen ? (
    <>
      {/* Modal Background */}
      <div
        aria-hidden="false"
        className="lg:hidden md:hidden fixed top-0 right-0 left-0 z-50 h-full overflow-y-auto overflow-x-hidden items-start justify-end flex bg-gray-900 bg-opacity-50 "
      >
        <div
          className={cx({
            "relative z-50 overflow-y-auto": true,
            "p-8 max-w-lg bg-white shadow h-[100vh]": true,
            // 'animate-slide-in': isOpen,
          })}
        >
          {/* MODAL HEADER */}
          <div className="flex items-center justify-between">
            <h3 className="text-[24px] font-medium">{"Add New Customer"}</h3>

            <button
              aria-label="Close"
              className="flex items-center justify-center bg-[#F4F7FF] h-11 w-11 pr-[1em]"
              type="button"
              onClick={handleClose}
            >
              <CloseIcon color="#212121" />
            </button>
          </div>

          {/* MODAL BODY */}
          <div className="mt-10 w-full">
            <CreateForm
              isLoading={isLoading}
              show={true}
              onSubmit={handleCreateCustomer}
              buttontitle={"Add"}
              backendErrorMessage={backendErrorMessage}
              setFormData={setFormData}
              onClose={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export { CreateCustomer };
