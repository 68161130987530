import React from "react";
import { ICreateSalesData, ICustomer, ItemInput } from "../../@types";
import {
  createDraftData,
  createInvoiceData,
} from "../../modules/invoice/invoice";
import toast from "react-hot-toast";
import { handleErrors } from "../../utils";
import { Navigate } from "react-router-dom";
import { getInvoice, updateToPending } from "../../backend-services";

interface Props {
  isRetail: boolean;
  setShowSidebar: (value: React.SetStateAction<boolean>) => void;
  setIsisDraftLoading: (value: React.SetStateAction<boolean>) => void;
  customer: ICustomer | null;
  formData: ICreateSalesData;
  setShowSubscriptionModal: (value: React.SetStateAction<boolean>) => void;
  setErrorMessage: (value: React.SetStateAction<string>) => void;
  setIsLoading: (value: React.SetStateAction<boolean>) => void;
  setSubscriptionErrorMessage: (value: React.SetStateAction<string>) => void;
  invoiceNo: string | undefined;
  setCreatedInvoiceId: (value: React.SetStateAction<string>) => void;
  setCreatedEmail: (value: React.SetStateAction<string>) => void;
  setShowSuccessModal: (value: React.SetStateAction<boolean>) => void;
  setBannerLinkText: (value: React.SetStateAction<string>) => void;
  setError: (value: React.SetStateAction<string>) => void;
  setBannerMessage: (value: React.SetStateAction<string>) => void;
  setIsBannerVisible: (value: React.SetStateAction<boolean>) => void;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setFormData: (value: React.SetStateAction<ICreateSalesData>) => void;
  setCustomer: (value: React.SetStateAction<ICustomer | null>) => void;
  setItems: (value: React.SetStateAction<ItemInput[]>) => void;
}

export const useInvoiceActions = ({
  isRetail,
  setShowSidebar,
  setIsisDraftLoading,
  customer,
  formData,
  setShowSubscriptionModal,
  setErrorMessage,
  setIsLoading,
  setSubscriptionErrorMessage,
  invoiceNo,
  setBannerLinkText,
  setCreatedEmail,
  setCreatedInvoiceId,
  setError,
  setShowSuccessModal,
  setBannerMessage,
  setIsBannerVisible,
  setCustomer,
  setFormData,
  setItems,
  setLoading,
}: Props) => {
  function wrapVariationsAsArray(data: ICreateSalesData) {
    return {
      ...data,
      inventories: data.inventories.map((inventory) => ({
        ...inventory,
        variations: Array.isArray(inventory.variations)
          ? inventory.variations
          : inventory.variations
            ? [inventory.variations]
            : [],
      })),
    };
  }
  const processCreateDraftInvoice = async (e?: any) => {
    const exchangeRate = formData.exchangeRate || 1;
    setIsisDraftLoading(true);
    setShowSidebar(false);

    const invoiceData: ICreateSalesData = {
      customerId: customer?.id,
      description: formData.description,
      invoiceDueAt: formData?.invoiceDueAt
        ? new Date(formData?.invoiceDueAt)?.toISOString()
        : undefined,
      issueDate: formData?.issueDate
        ? new Date(formData?.issueDate)?.toISOString()
        : undefined,
      additionalInformation: formData.additionalInformation,
      discount: Number(formData.discount ? formData.discount / 100 : 0),
      tax: Number(formData.tax ? formData.tax / 100 : 0),
      isReoccurring: formData.isReoccurring,
      status: "draft",
      currency: formData.currency,
      exchangeRate: formData.exchangeRate,
      invoiceType: isRetail ? "inventory" : "service",
      // @ts-ignore
      reminderSettings: formData.reminderSettings,
      inventories: isRetail
        ? formData.inventories.length > 0
          ? formData.inventories.map(
              (i: {
                id: string;
                name: string;
                rate: number;
                quantity: number;
                variations?: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: 0,
                quantity: Number(i.quantity),
                rate: Number(i.rate),
                variations:
                  i.variations && i.variations.length > 0
                    ? (i.variations.map((variation: any) => ({
                        ...variation,
                        rate: +variation.price / exchangeRate,
                        price: +variation.price / exchangeRate,
                      })) ?? i.variations)
                    : undefined,
              }),
            )
          : []
        : [],
      services: !isRetail
        ? formData.services.length
          ? formData.services.map(
              (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                id: s.id,
                name: s.name,
                duration: s.duration,
                price: Number(s.price),
              }),
            )
          : undefined
        : undefined,
      ...(formData.isReoccurring && {
        reoccurringGroupName: formData.reoccurringGroupName,
        isReoccurringStartDate: new Date().toISOString(),
        isReoccurringEndDate: new Date(
          formData.isReoccurringEndDate || "",
        ).toISOString(),
      }),
    };

    try {
      const response = await createDraftData(invoiceData, customer, isRetail);
      setIsisDraftLoading(false);
      toast.success("Draft saved successfully!");
      return response;
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      setIsisDraftLoading(false);
      if (error.response) {
        const errorMessage = error.response.data;
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
      const errorEl = document.getElementById("error-message");
      errorEl?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      document
        .getElementById("top-of-page")
        ?.scrollIntoView({ behavior: "smooth" });
      setIsLoading(false);
      setErrorMessage("An error occurred.");
    }
  };

  const processCreateInvoice = async (
    sendEmail: boolean,
    markedAsPaid: boolean,
    shouldSendWhatsApp: boolean,
    shouldSendSMS: boolean,
  ) => {
    const data = wrapVariationsAsArray(formData);
    const exchangeRate = formData.exchangeRate || 1;

    try {
      setIsLoading(true);

      // setShowCreateSidebar(false);
      const {
        invoiceDueAt,
        issueDate,
        inventories,
        services,
        discount,
        tax,
        customerId,
        isReoccurring,
      } = data;
      const validInvoiceDueAt = invoiceDueAt ? new Date(invoiceDueAt) : null;
      const validIssueDate = issueDate ? new Date(issueDate) : null;

      if (!validInvoiceDueAt || isNaN(validInvoiceDueAt.getTime())) {
        throw new Error("Invalid invoice due date");
      }

      if (!validIssueDate || isNaN(validIssueDate.getTime())) {
        throw new Error("Invalid issue date");
      }

      const invoiceData: ICreateSalesData = {
        customerId: customerId,
        shouldSendEmail: sendEmail,
        isPaymentCompleted: markedAsPaid,
        shouldSendWhatsApp: shouldSendWhatsApp,
        shouldSendSMS: shouldSendSMS,
        isReoccurring: isReoccurring,
        invoiceDueAt: validInvoiceDueAt.toISOString(),
        issueDate: validIssueDate.toISOString(),
        inventories: isRetail
          ? inventories.length
            ? inventories.map(
                (i: {
                  id: string;
                  name: string;
                  rate: number;
                  quantity: number;
                  variations?: any;
                }) => {
                  return {
                    id: i.id,
                    name: i.name,
                    amount: 0,
                    quantity:
                      i.variations && i.variations.length > 0
                        ? 0
                        : Number(i.quantity),
                    rate:
                      i.variations && i.variations.length > 0
                        ? 0
                        : Number(i.rate) / exchangeRate,
                    variations:
                      i.variations && i.variations.length > 0
                        ? i.variations.map((variation: any) => ({
                            ...variation,
                            quantity:
                              i.quantity == 0 ? variation.quantity : i.quantity,
                            rate:
                              +variation.rate / exchangeRate ||
                              +variation.price / exchangeRate,
                            price:
                              +variation.rate / exchangeRate ||
                              +variation.price / exchangeRate,
                          }))
                        : undefined,
                  };
                },
              )
            : []
          : [],
        services: !isRetail
          ? services.length
            ? services.map(
                (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                  id: s.id,
                  name: s.name,
                  duration: s.duration,
                  price: Number(s.price),
                }),
              )
            : []
          : [],
        reminderSettings: formData.reminderSettings,
        discount: Number(discount ? discount : 0),
        tax: Number(tax ? tax : 0),
        isReoccurringEndDate: formData.isReoccurringEndDate || "",
        reoccurringGroupName: formData.reoccurringGroupName || "",
        status: formData.status || "pending",
        description: formData.description || "",
        additionalInformation: formData.additionalInformation || "",
        currency: formData.currency || "",
        exchangeRate: formData.exchangeRate,
        paymentMethod: formData.paymentMethod || "",
        invoiceType: isRetail ? "inventory" : "service",
      };
      const promises = [];

      if (customer) {
        if (invoiceNo) {
          const taxValue = formData.tax ? Number(formData.tax) / 100 : 0;
          const formattedTax = taxValue.toFixed(2);
          const updatedInvoiceData = {
            status: "pending",
            invoiceDueAt: validInvoiceDueAt.toISOString(),
            issueDate: validIssueDate.toISOString(),
            invoiceType: "freeform",
            inventories: isRetail
              ? inventories.length
                ? inventories.map(
                    (i: {
                      id: string;
                      name: string;
                      rate: number;
                      quantity: number;
                      variations?: any;
                    }) => {
                      return {
                        id: i.id,
                        name: i.name,
                        amount: 0,
                        quantity:
                          i.variations && i.variations.length > 0
                            ? 0
                            : Number(i.quantity),
                        rate:
                          i.variations && i.variations.length > 0
                            ? 0
                            : Number(i.rate) / exchangeRate,
                        variations:
                          i.variations && i.variations.length > 0
                            ? i.variations.map((variation: any) => ({
                                ...variation,
                                quantity:
                                  i.quantity == 0
                                    ? variation.quantity
                                    : i.quantity,
                                rate:
                                  +variation.rate ||
                                  +variation.price / exchangeRate,
                                price:
                                  +variation.rate ||
                                  +variation.price / exchangeRate,
                              }))
                            : undefined,
                      };
                    },
                  )
                : []
              : [],
            services: !isRetail
              ? services.length
                ? services.map(
                    (s: { id: ""; name: ""; duration: ""; price: "" }) => ({
                      id: s.id,
                      name: s.name,
                      duration: s.duration,
                      price: Number(s.price),
                    }),
                  )
                : []
              : [],
            reminderSettings: formData.reminderSettings,
            discount: Number(formData.discount ? formData.discount / 100 : 0),
            tax: Number(formattedTax),
          };

          promises.push(
            updateToPending({ id: invoiceNo, data: updatedInvoiceData }),
          );
        } else {
          promises.push(createInvoiceData(invoiceData, customer, isRetail));
        }
      }
      const results = await Promise.all(promises);
      const createdUpdatedInvoice = results[0];

      const createdUpdatedInvoiceId = createdUpdatedInvoice?.id;
      const createdEmail = customer?.email || "";

      setCreatedInvoiceId(createdUpdatedInvoiceId);
      setCreatedEmail(createdEmail);
      setShowSuccessModal(true);
      setBannerMessage("New Invoice Created,");
      // setBannerMessage('');
      setBannerLinkText("View Invoice");
      setIsBannerVisible(true);
    } catch (error: any) {
      handleErrors(error, setErrorMessage);
      setErrorMessage("An error occurred while processing the invoice.");
      toast.error("An error occurred while processing the invoice.");
      if (error.response) {
        const errorMessage = error.response.data;
        setError(errorMessage);
        setShowSubscriptionModal(true);
        setSubscriptionErrorMessage(errorMessage);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDraftInvoice = async (invoiceNo: string) => {
    if (!invoiceNo) return;

    setLoading(true);

    try {
      const response = await getInvoice(invoiceNo);
      const draftData = response;
      // Ensure we only process the invoice if it is a draft
      if (draftData && draftData.status === "draft") {
        setFormData((prevData) => ({
          ...prevData,
          customerId: draftData.customer?.id || "",
          invoiceDueAt: draftData.invoiceDueAt
            ? new Date(draftData.invoiceDueAt).toISOString()
            : "",
          issueDate: draftData.issueDate
            ? new Date(draftData.issueDate).toISOString()
            : "",
          total: draftData.total || 0,
          currency: draftData.currency || "NGN",
          description: draftData.description || "",
          additionalInformation: draftData.additionalInformation || "",
          exchangeRate: draftData.exchangeRate || 1,
          invoiceType: draftData.invoiceType || "inventory",
          isReoccurring: draftData.isReoccurring || false,
          status: draftData.status || "draft",
        }));

        setCustomer({
          id: draftData.customer?.id || "",
          firstName: draftData.customer?.firstName || "",
          lastName: draftData.customer?.lastName || "",
          phone: draftData.customer?.phone || "",
          email: draftData.customer?.email || "",
        });

        // Handle inventory or service items
        if (draftData.invoiceType === "inventory") {
          setItems(
            draftData.inventories?.map(
              (i: {
                id: any;
                name: any;
                amount: any;
                quantity: any;
                rate: any;
                variations: any;
              }) => ({
                id: i.id,
                name: i.name,
                amount: i.amount || 0,
                quantity: Number(i.quantity) || 0,
                rate: Number(i.rate) || 0,
                variations: i.variations || [],
              }),
            ) || [],
          );
        } else if (draftData.invoiceType === "service") {
          setItems(
            draftData.services?.map(
              (service: {
                id: any;
                name: any;
                price: string | any[];
                duration: any;
              }) => ({
                id: service.id,
                name: service.name,
                price:
                  service.price?.length > 0
                    ? parseFloat(service.price[0].amount)
                    : 0,
                duration: service.duration,
              }),
            ) || [],
          );
        }
      } else {
      }
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };
  return { processCreateInvoice, processCreateDraftInvoice, fetchDraftInvoice };
};
