import React, { useEffect, useState } from "react";
import cx from "classnames";
import { SalesStatus } from "../../@types";
import { EmptyPieChart } from "./PieChart/Empty";
import { customColors, customTextColors } from "../Sales/invoice/Status/status";
import { formatMoney } from "../../utils";
import { PieChart, Pie, Tooltip } from "recharts";

interface PieData {
  value: number;
  fill: string;
}

interface PieChartProps {
  title?: string;
  isLoaded?: boolean;
  data: Record<string, PieData> | any;
  toolTipText?: string;
}
const InvoiceStatus: React.FC<PieChartProps> = ({
  title,
  data = {},
  isLoaded,
  toolTipText,
}) => {
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    const transformedData = Object.keys(data)
      .filter((key) => data[key].fill && data[key].value !== null)
      .map((key) => ({
        name: key,
        fill: data[key].fill,
        value: data[key].value,
        amount: data[key].amount,
      }));
    setChartData(transformedData);
  }, [data]);

  console.log(chartData);

  return (
    <>
      {!isLoaded ? (
        <p>Loading...</p>
      ) : (
        <div>
          {/* Invoice Subheading */}
          <div className="py-4 px-5">
            <p
              className={`font-semibold text-[16px] text-[##000000] leading-6`}
            >
              {title}
            </p>
          </div>
          <hr className="border border-[#EAECF0] mt-1 w-full" />
          <div className="flex flex-col items-center justify-center px-6 pb-5">
            {chartData.length > 0 ? (
              <PieChart width={220} height={200}>
                <Pie
                  data={chartData}
                  dataKey="amount"
                  nameKey="name"
                  innerRadius={60}
                  outerRadius={85}
                  paddingAngle={0}
                  style={{ outline: "none" }}
                />
                <Tooltip
                  formatter={(value: number, name: string, entry: any) =>
                    `${formatMoney(entry.payload.amount)}`
                  }
                  contentStyle={{
                    fontSize: "12px",
                    padding: "5px",
                    backgroundColor: "#FFFFFF",
                    border: "none",
                    borderRadius: "8px",
                  }}
                />
              </PieChart>
            ) : (
              <EmptyPieChart
                link={`/invoices/create`}
                buttonText="Create an invoice"
                paragraph={`Create your invoice to start recording payments`}
              />
            )}

            <div className="flex flex-wrap gap-3 justify-center">
              {chartData &&
                chartData?.map((item, i) => {
                  const backgroundColor =
                    customColors[item.name as keyof typeof customColors];
                  const textColor =
                    customTextColors[
                      item.name as keyof typeof customTextColors
                    ];
                  const formattedLabel =
                    item.name.charAt(0).toUpperCase() +
                    item.name.slice(1).toLowerCase();

                  return (
                    <div
                      className={`flex flex-row gap-1 items-center justify-center`}
                      key={i}
                    >
                      <div
                        className={cx(
                          `flex items-center justify-center font-poppins px-1 rounded-md`
                        )}
                        style={{ backgroundColor }}
                      >
                        <p
                          className={cx(
                            `text-[12px] font-medium leading-4 px-2 py-[3px]`
                          )}
                          style={{ color: textColor }}
                        >
                          {formattedLabel}
                        </p>
                      </div>
                      <p className="flex justify-between font-semibold leading-5 text-[14px] text-[#344054]">
                        <span>-{item.value} </span> <span>%</span>{" "}
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { InvoiceStatus };
