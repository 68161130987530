import React, { useState } from "react";
import { VariationItem } from "../../../@types/inventory";
import { AddSquareIcon } from "../../../assets/icons";
import { SubtractSquareIcon } from "../../../assets/icons/SubtractSquareIcon";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import { DropdownComponent, InputComponent } from "../../core/Forms";
import { useTranslation } from "react-i18next";

interface EditVariationFormProps {
  onAddVariation: (newVariation: VariationItem) => void;
  variations: VariationItem[];
  onRemoveVariation: (id: number) => void;
  onUpdateVariation: (id: number, field: string, value: string) => void;
  variationList: any;
  variationError?: any;
}

function EditVariationForm({
  onAddVariation,
  variations,
  onRemoveVariation,
  onUpdateVariation,
  variationList,
  variationError,
}: EditVariationFormProps) {
  const handleAddVariation = () => {
    const newVariation: VariationItem = {
      name: "",
      type: "",
      costPrice: "",
      price: "",
      quantity: "",
    };
    onAddVariation(newVariation);
  };
  const { t } = useTranslation("inventories");
  const [selectedType, setSelectedType] = React.useState("");
  const [selectedName, setSelectedName] = React.useState("");

  const handleVariation = (index: number, name: string, value: string) => {
    if (name === "name") {
      setSelectedName(value);
      onUpdateVariation(index, "name", value);
    }
    if (name === "type") {
      setSelectedType(value);
      onUpdateVariation(index, "type", value);
    }
  };

  const variationNameOptions = variationList.map((item: any) => ({
    value: item.variantName,
    label: item.variantName,
  }));

  const variant = variationList.find(
    (item: any) => item.variantName === selectedName
  );

  const variationTypesOptions =
    variant?.types.map((type: string) => ({ value: type, label: type })) || [];

  return (
    <div className="mt-12">
      {variations.length > 0 && (
        <div className="hidden lg:block text">
          {" "}
          {t("inventory.productVariation")}
        </div>
      )}
      <div className="w-full mt-6">
        <div className="lg:hidden mb-4 lg:px-0">
          <p className="text-[18px] md:text-[20px] font-medium">
            {" "}
            {t("inventory.productVariation")}
          </p>
        </div>
        {variations.map((variation, index) => (
          <div key={index + 1} className="py-2">
            <div className="md:flex md:items-center md:gap-[40px] lg:gap-[6.2%] xl:justify-between rounded">
              <div className="md:flex justify-between items-center px-3 pt-2 pb-1 border border-gray-400 rounded md:border-0 md:px-0 md:gap-4 md:mb-4">
                <div className="w-full mb-4 md:mb-0 space-y-4 md:space-y-1 h-[5.5rem]">
                  <label
                    className="block text-[#212121] tracking-wide text-[14px] font-normal "
                    htmlFor="cost-price"
                  >
                    Variation
                  </label>
                  <DropdownComponent
                    options={variationNameOptions}
                    value={variation.name ? variation.name : selectedName}
                    selectedValue={variation.name}
                    setSelectedValue={setSelectedName}
                    handlevariation={handleVariation}
                    name="name"
                    variationIndex={index}
                    dropdownClassName="z-10 max-h-52 w-full overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em] md:mt-[0.3em] md:mr-[1.2em] md:mt-[0.5em]"
                  />
                </div>
                <div className="w-full mb-4 md:mb-0 space-y-4 md:space-y-1 h-[5.5rem]">
                  <label
                    className="block text-[#212121] tracking-wide text-[14px] font-normal "
                    htmlFor="cost-price"
                  >
                    Type
                  </label>
                  <DropdownComponent
                    options={variationTypesOptions}
                    value={variation.type ? variation.type : selectedType}
                    selectedValue={variation.type}
                    setSelectedValue={setSelectedType}
                    handlevariation={handleVariation}
                    name="type"
                    variationIndex={index}
                    dropdownClassName="z-10 max-h-52 w-full overflow-y-auto bg-white border border-gray-300 left-0 right-0 top-full"
                    iconClassName="xl:mt-[3px] mr-[16px] mt-[0em] md:mt-[0.3em] md:mr-[1.2em] md:mt-[0.5em]"
                  />
                  <span style={{ color: "red" }}>
                    {
                      variationError.find((item: any) => item.id === index)
                        ?.type
                    }
                  </span>
                </div>
                <div className="w-full mb-4 md:mb-0 space-y-4 md:space-y-1 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] font-normal ">
                    {" "}
                    {t("inventory.costPrice")}
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    type="text"
                    value={variation.costPrice}
                    handleChange={(e) =>
                      onUpdateVariation(index, "costPrice", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={
                      variationError.find((item: any) => item.id === index)
                        ?.costPrice
                    }
                  />
                </div>
                <div className="w-full mb-4 md:mb-0 space-y-4 md:space-y-1 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] font-normal whitespace-nowrap">
                    {t("inventory.sellingPrice")}
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    type="text"
                    value={variation.price}
                    handleChange={(e) =>
                      onUpdateVariation(index, "price", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={
                      variationError.find((item: any) => item.id === index)
                        ?.price
                    }
                  />
                </div>
                <div className="w-full mb-4 md:mb-0 space-y-4 md:space-y-1 h-[5.5rem]">
                  <label className="block text-[#212121] tracking-wide text-[14px] font-normal">
                    Quantity
                  </label>
                  <InputComponent
                    className="block w-full border-[0.5px] border-[#A4A4A4] rounded py-3 px-4 ring-1 ring-[#138EFF]"
                    type="text"
                    value={variation.quantity}
                    handleChange={(e) =>
                      onUpdateVariation(index, "quantity", e.target.value)
                    }
                    placeholder="E.g 20"
                    error={
                      variationError.find((item: any) => item.id === index)
                        ?.quantity
                    }
                  />
                </div>
                <div className="flex md:hidden lg:flex item center justify-end mt-5">
                  <button
                    onClick={() => onRemoveVariation(index)}
                    className="text-red-500 py-1 flex items-center gap-1"
                  >
                    {t("inventory.remove")}
                    <div>
                      <SubtractSquareIcon />
                    </div>
                  </button>
                </div>
              </div>
              <div className="hidden md:block lg:hidden">
                <DeleteIcon />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="my-6">
        <div className="flex items-center gap-1 font-semibold">
          <button
            className="  text-[#044E97] text-[16px]"
            onClick={handleAddVariation}
          >
            {t("inventory.addVariation")}
          </button>
          <AddSquareIcon />
        </div>
      </div>
    </div>
  );
}

export { EditVariationForm };
