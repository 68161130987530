import React, { useState } from "react";
import toast from "react-hot-toast";
import RetailIcon2, { RetailIconMobile } from "../../assets/icons/RetailIcon2";
import ServiceIcon2, {
  ServiceIconMobile,
} from "../../assets/icons/Serviceicon2";
import { useUserStore } from "../../state";
import { BusinessType } from "../../types";
import useViewport from "../../hooks/auth/useViewPort";
import { useCompanyStore } from "../../state/useCompanyStore";

interface BusinessTypeSelectorProps {
  onClose: () => void;
}

const BusinessTypeSelector: React.FC<BusinessTypeSelectorProps> = ({
  onClose,
}) => {
  const [selectedType, setSelectedType] = useState<BusinessType | null>(null);
  const [hoveredType, setHoveredType] = useState<BusinessType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { updateBusinessType } = useCompanyStore();
  const viewportWidth = useViewport();
  const handleSelect = async (type: BusinessType) => {
    if (loading) return;
    setSelectedType(type);
    setLoading(true);
    setError(null);

    try {
      await updateBusinessType(type);
      setLoading(false);
      toast.success("Business type updated successfully!");
      onClose();
    } catch (err) {
      setLoading(false);
      setError("Failed to update business type. Please try again.");
      console.error(err);
    }
  };

  const handleMouseEnter = (type: BusinessType) => setHoveredType(type);
  const handleMouseLeave = () => setHoveredType(null);

  const handleConfirm = async () => {
    if (loading || !selectedType) return;

    setLoading(true);
    try {
      await updateBusinessType(selectedType);
      setLoading(false);
      toast.success("Business type updated successfully!");
      onClose();
    } catch (err) {
      setLoading(false);
      setError("Failed to update business type. Please try again.");
      console.error(err);
    }
  };

  const handleSelectMobile = (type: BusinessType) => {
    setSelectedType(type);
    setError(null);
  };

  return (
    <>
      {/* Desktop */}
      {viewportWidth.isDesktop && (
        <div className="bg-[#F2F9FD] mb-3 px-3 relative hidden lg:block md:block w-[400px]">
          <div className="relative">
            <h2 className="text-center lg:text-[20px] text-[16px] font-semibold">
              Select a Business Type
            </h2>
            <p className="text-center mt-[1em] text-[#4E4E4E]">
              Please select a business type. This helps us <br /> tailor the
              Invoice form to your needs.
            </p>

            <div className="mt-[2em] relative">
              {/* Loading Overlay */}
              {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                  <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-8 h-8 animate-spin"></div>
                </div>
              )}

              {/* Retail Business Option */}
              <div
                className={`flex items-center bg-white border border-[#A4A4A4] px-2 rounded-md cursor-pointer transition-all duration-200 ${
                  loading ? "opacity-50 pointer-events-none" : ""
                } ${
                  hoveredType === "retail" && !loading ? "bg-blue-100" : ""
                } ${
                  selectedType === "retail" ? "bg-blue-200 border-blue-500" : ""
                }`}
                onClick={() => handleSelect("retail")}
                onMouseEnter={() => handleMouseEnter("retail")}
                onMouseLeave={handleMouseLeave}
                data-testid="retail-business-selector"
              >
                <div className="ml-[-2em]">
                  <RetailIcon2 />
                </div>
                <div className="text-left mt-[-0.5em] ml-[-2em]">
                  <h3 className="text-[16px] font-medium mb-1">
                    Retail Based Business
                  </h3>
                  <p className="text-[14px] font-normal text-[#4E4E4E]">
                    I sell products directly to consumers <br /> through
                    physical stores or online <br /> platforms.
                  </p>
                </div>
              </div>

              {/* Service Business Option */}
              <div
                className={`flex items-center bg-white border border-[#A4A4A4] mt-[1em] px-2 rounded-md cursor-pointer transition-all duration-200 ${
                  loading ? "opacity-50 pointer-events-none" : ""
                } ${
                  hoveredType === "service" && !loading ? "bg-blue-100" : ""
                } ${
                  selectedType === "service"
                    ? "bg-blue-200 border-blue-500"
                    : ""
                }`}
                onClick={() => handleSelect("service")}
                onMouseEnter={() => handleMouseEnter("service")}
                onMouseLeave={handleMouseLeave}
              >
                <div className="ml-[-2em]">
                  <ServiceIcon2 />
                </div>
                <div className="text-left mt-[-0.8em] ml-[-2em]">
                  <h3 className="text-[16px] font-medium mb-1">
                    Service Based Business
                  </h3>
                  <p className="text-[14px] font-normal text-[#4E4E4E] ">
                    I offer services to clients, such as <br /> consulting,
                    cleaning, or freelancing
                  </p>
                </div>
              </div>
            </div>

            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      )}
      {/* Mobile */}
      {!viewportWidth.isDesktop && (
        <div className="bg-[#F2F9FD] mb-3 px-3 relative lg:hidden w-[300px]">
          <div className="relative">
            <h2 className="text-center mb-1 text-[18px] font-semibold text-[#0E2943]">
              Let’s get your business set up.
            </h2>
            <p className="text-center text-[14px] 360:text-[12px] font-medium text-[#4E4E4E] whitespace-nowrap">
              Select a type that best fit into your business
            </p>

            <div className="mt-[2em] relative">
              {/* Loading Overlay */}
              {loading && (
                <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
                  <div className="loader border-t-4 border-blue-500 border-solid rounded-full w-8 h-8 animate-spin"></div>
                </div>
              )}

              {/* Retail Business Option */}
              <div
                className={`flex items-center bg-white border border-[#A4A4A4] px-2 rounded-md cursor-pointer transition-all duration-200 mt-[-1em] w-[280px] max-width: 100%;
            ${loading ? "opacity-50 pointer-events-none" : ""} ${
                  hoveredType === "retail" && !loading ? "bg-blue-100" : ""
                } ${
                  selectedType === "retail" ? "bg-blue-200 border-blue-500" : ""
                }`}
                onClick={() => handleSelect("retail")}
                onMouseEnter={() => handleMouseEnter("retail")}
                onMouseLeave={handleMouseLeave}
                data-testid="retail-business-selector"
              >
                <div className="ml-[-1.5em]">
                  <RetailIconMobile />
                </div>
                <div className="text-left mt-[-0.5em] ml-[-1em]">
                  <h3 className="text-[16px] font-medium mb-1">
                    Retail Based Business
                  </h3>
                  <p className="text-[12px] font-normal text-[#4E4E4E]">
                    I sell products directly to consumers through physical
                    stores or online platforms.
                  </p>
                </div>
              </div>

              {/* Service Business Option */}
              <div
                className={`flex items-center bg-white border border-[#A4A4A4] mt-[1em] px-2 rounded-md cursor-pointer transition-all duration-200 w-[280px]${
                  loading ? "opacity-50 pointer-events-none" : ""
                } ${
                  hoveredType === "service" && !loading ? "bg-blue-100" : ""
                } ${
                  selectedType === "service"
                    ? "bg-blue-200 border-blue-500"
                    : ""
                }`}
                onClick={() => handleSelect("service")}
                onMouseEnter={() => handleMouseEnter("service")}
                onMouseLeave={handleMouseLeave}
              >
                <div className="ml-[-1.5em]">
                  <ServiceIconMobile />
                </div>
                <div className="text-left mt-[-0.8em] ml-[-1em]">
                  <h3 className="text-[16px] font-medium mb-1">
                    Service Based Business
                  </h3>
                  <p className="text-[12px] font-normal text-[#4E4E4E]">
                    I offer services to clients, such as consulting, cleaning,
                    or freelancing
                  </p>
                </div>
              </div>
            </div>

            {error && <p className="text-red-500">{error}</p>}
          </div>
        </div>
      )}
    </>
  );
};

export default BusinessTypeSelector;
