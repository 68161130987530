import { CustomerT } from "../../../types";
import React from "react";
import { formatMoney } from "../../../utils";
import { useTranslation } from "react-i18next";

interface CustomerContactProps {
  customer: CustomerT;
}
export const OutstandingAmount: React.FC<CustomerContactProps> = ({
  customer,
}) => {
  const { t } = useTranslation("customer");
  return (
    <div className="mt-8 md:mt-[2em]">
      <h2 className="text-[24px] font-semibold leading-7 mb-[29px] text-[#212121]">{`${customer?.firstName} ${customer?.lastName}`}</h2>
      <p className="font-medium text-[#212121] text-[16px] leading-4">
        {" "}
        {t("customer.amountOutstanding")}
      </p>
      <p className="text-[#ef4444] text-[20px] md:text-[25px] font-semibold mt-2 md:mt-3">
        {formatMoney(
          customer?.totalOutstandings ? customer?.totalOutstandings : 0
        )}
      </p>
    </div>
  );
};
