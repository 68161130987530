import React, { useEffect } from "react";
import { useDashboardStats } from "../../hooks/useDashboardStats";
import Subscription from "./Subscription";
import Overview from "./Overview";
import RecentTransactions from "./RecentTransactions";
import TopSellingProducts from "./TopSellingProducts";
import { InvoiceCard } from "./InvoiceCard";
import { Invoiceicon } from "../../assets/icons";
import { ExpenseCategories } from "./ExpenseCategories";
import { InvoiceStatus } from "./InvoicesStatus";
import CashflowTrend from "./CashflowTrend";
import DatePickerWithShortcuts from "../DatePicker";
import PageLoader from "../common/PageLoader";
import { useTranslation } from "react-i18next";
import MostBookedServices from "./MostBookedServices";
import { useFetchTransactions } from "../../hooks/useFetchTransactions";
import ToDos from "./ToDos";

const Dashboard = () => {
  const { t } = useTranslation("dashboard");
  const {
    selected,
    selectedLabel,
    isLoadingDashBoardStat,
    dashBoardStatError,
    dashboardStats,
    showCalendar,
    setShowCalendar,
    handleRangeSelect,
    handleApplyButtonClick,
    calendarRef,
    businessType,
    fetchDashboardStats,
  } = useDashboardStats();

  const { transactions, error, isLoading } = useFetchTransactions();

  useEffect(() => {
    const handleClickOutside = (event: { target: any }) => {
      //@ts-ignore
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  if (isLoadingDashBoardStat) {
    return (
      <div data-testid="loading-indicator">
        <PageLoader />;
      </div>
    );
  }

  if (dashBoardStatError) {
    return <div>{dashBoardStatError}</div>;
  }

  return (
    <div>
      <>
        <div className="mb-[5em] relative">
          <Subscription />
          <ToDos />
          <Overview
            selectedLabel={selectedLabel}
            showCalendar={showCalendar}
            dashboardStats={dashboardStats}
            handleCalendarToggle={handleCalendarToggle}
          />

          <div className="flex flex-col lg:flex-row gap-4 mt-4">
            {
              <RecentTransactions
                isLoading={isLoading}
                transactions={transactions}
              />
            }
            {businessType === "retail" && <TopSellingProducts />}
            {businessType === "service" && <MostBookedServices />}
          </div>

          <div className="flex flex-col lg:flex-row gap-4 mt-4 h-full">
            <InvoiceCard
              icon={<Invoiceicon />}
              title={t("transactionInvoices")}
              toolTipText={t("InvoicesTooltip")}
              value={selectedLabel}
              text={t("totalInvoices")}
              totalNumber={dashboardStats?.invoices?.totalInvoices}
              data={dashboardStats?.invoices}
            />
            <div className="w-full flex-auto hidden bg-white lg:block rounded-lg">
              <ExpenseCategories
                toolTipText={t("InvoiceStatusTooltip")}
                title="Expense categories"
                isLoaded={true}
                data={dashboardStats?.expenseCategories}
              />
            </div>

            <div className="w-full bg-white hidden lg:block rounded-lg">
              <InvoiceStatus
                title={t("invoiceStatus")}
                toolTipText={t("InvoiceStatusTooltip")}
                isLoaded={true}
                data={dashboardStats?.invoiceStatuses as any}
              />
            </div>

            {/* Mobile */}
            <div className="flex flex-col gap-4 md:flex-row lg:hidden ">
              <div className="w-full flex-auto bg-white rounded-lg">
                <ExpenseCategories
                  toolTipText={t("InvoiceStatusTooltip")}
                  title="Expense categories"
                  isLoaded={true}
                  data={dashboardStats?.expenseCategories}
                />
              </div>
              <div className="w-full bg-white rounded-lg">
                <InvoiceStatus
                  title={t("invoiceStatus")}
                  toolTipText={t("InvoiceStatusTooltip")}
                  isLoaded={true}
                  data={dashboardStats?.invoiceStatuses as any}
                />
              </div>
            </div>
          </div>
          <CashflowTrend />
        </div>

        <div
          className={`relative flex justify-center ${
            showCalendar ? "cursor-pointer" : ""
          } `}
          ref={calendarRef}
        >
          {showCalendar && (
            <>
              <div className="fixed inset-0  bg-gray-800 bg-opacity-80 z-40">
                <div className="flex justify-center ">
                  <DatePickerWithShortcuts
                    onRangeSelect={handleRangeSelect}
                    selected={selected}
                    onClose={handleCalendarToggle}
                    onApplyButtonClick={(startDate, endDate) =>
                      handleApplyButtonClick(startDate, endDate)
                    }
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </>
    </div>
  );
};

export default Dashboard;
