import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomerStore, useUserStore } from "../../state";
import { formatMoney } from "../../utils";
import { useCustomerSearch } from "../../hooks/customer/useCustomerSearch";
import EmptyPage from "../../components/emptyPage/EmptyPage";
import ExpenseEmptyIcon from "../../assets/icons/InvCusExpEmptyIcon";
import { CreateCustomer } from "../../components/customers";
import { useCustomerStatsData } from "../../hooks/Stats/customer";
import { defaultShortcuts, TODAY } from "../../constants/helpServices";
import DatePickerWithShortcuts from "../../components/DatePicker";
import { CreateCustomerModal } from "../../components/Sales/customer/createCustomer";
import { ICreateSalesData, ICustomer } from "../../@types";
import { useTranslation } from "react-i18next";
import ReusableListPage from "../../components/list-page/";
import { columns, renderCustomersData } from "../../utils/customers/list-page";
import { ListInfoCardProps } from "../../components//list-page/desktop/ListInfoCard";
import PageLoader from "../../components/common/PageLoader";

export interface CustomerStatsData {
  totalInvoiceValue: number;
  totalDeletedCustomers: number;
  totalNewCustomers: number;
  totalOverDueInvoices: number;
  totalBilled?: number;
  totalTransactions: number;
  totalCustomers: number;
  dateRange: string;
}

const ViewCustomersPages = () => {
  const { t } = useTranslation("customer");
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const currentDate = new Date();
  const isLoading = useCustomerStore((state) => state.isLoading);
  const isLoadingMore = useCustomerStore((state) => state.isLoadingMore);
  const [startOfMonth, setStartOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  );
  const [endOfMonth, setEndOfMonth] = useState(
    new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  );
  const { customerStats, error } = useCustomerStatsData(
    startOfMonth,
    endOfMonth
  );
  const navigate = useNavigate();
  const fetchCustomers = useCustomerStore((state) => state.fetchCustomers);
  const fetchMoreData = useCustomerStore((state) => state.fetchMoreData);
  const onSearchData = useCustomerStore((state) => state.setSearchText);
  const fetchData = useCustomerStore((state) => state.fetchData);
  const createCustomer = useCustomerStore((state) => state.createCustomer);
  const customers = useCustomerStore((state) => state.customers);
  const range = useCustomerStore((state) => state.range);
  const pagination = useCustomerStore((state) => state.pagination);
  const searchText = useCustomerStore((state) => state.searchText);
  const {
    data: searchResult,
    isSearching,
    searchedDone,
    setSearchedDone,
    handleSearch: handleCustomerSearch,
  } = useCustomerSearch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startDateRange = params.get("startDate") || "";
    const endDateRange = params.get("endDate") || "";
    setStartDate(startDateRange);
    setEndDate(endDateRange);
  }, [location.search]);

  const [isSideBarOpen, setIsSidebarOpen] = React.useState(false);

  const fetchAllData = async () => {
    try {
      await Promise.all([
        startDate && endDate ? fetchData(startDate, endDate) : fetchData(),
        fetchCustomers(),
      ]);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  useEffect(() => {
    fetchAllData();
  }, [searchText, range, startDate, endDate]);

  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<string>(TODAY.label);
  const [selected, setSelected] = useState<[Date, Date] | null>(null);

  const listInfoCardData = [
    {
      title: `${t("customer.TotalInvoices")}`,
      mobileTitle: `Total billed`,
      value: `${formatMoney(customerStats?.totalBilled, "N").toLocaleString()}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#F0F7FF] ",
    },
    {
      title: t("customer.totalTransactions"),
      mobileTitle: `Total transactions`,
      value: `${customerStats?.totalTransactions || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#ECFDF3] ",
    },
    {
      title: t("customer.totalCustomers"),
      mobileTitle: `Total Customers`,
      value: `${customerStats?.totalCustomers || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#FFFAEB] ",
    },
    {
      title: t("customer.activeCustomers"),
      mobileTitle: `Active customers`,
      value: `${customerStats?.totalNewCustomers || 0}`,
      className: "w-full md:border-[1.5px] border-gray-100 bg-[#FEF3F2] ",
    },
  ] as ListInfoCardProps[];

  const handleCalendarToggle = () => {
    setShowCalendar(!showCalendar);
  };

  const handleRangeSelect = async (ranges: any) => {
    setShowCalendar(false);
    setSelected(ranges);
    const startDate = ranges[0];
    const endDate = ranges[1];
    let label = "";
    for (const shortcut of defaultShortcuts) {
      const [shortcutStart, shortcutEnd] = shortcut.range();

      if (
        startDate.getTime() === shortcutStart.getTime() &&
        endDate.getTime() === shortcutEnd.getTime()
      ) {
        label = shortcut.label;
        break;
      }
    }
    if (label) {
      setSelectedLabel(label);
    } else {
      setSelectedLabel(
        `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`
      );
    }
    setStartOfMonth(new Date(startDate));
    setEndOfMonth(new Date(endDate));
  };

  const handleApplyButtonClick = async (
    startDate: Date,
    endDate: Date | null
  ) => {
    if (endDate === null) {
      endDate = new Date(startDate);
      endDate.setHours(23, 59, 59, 999);
    }
    let newStartDate = startDate?.toISOString().replace(".000Z", "");
    let newEndDate = endDate?.toISOString().replace(".000Z", "");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    try {
      await handleRangeSelect([startDate, endDate]);
      handleCalendarToggle();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!showCalendar) {
      setSelected(null);
    }
  }, [showCalendar]);
  const [customer, setCustomer] = React.useState<ICustomer | null>(null);
  const [formData, setFormData] = React.useState<ICreateSalesData>({
    additionalInformation: "",
    customerId: "",
    description: "",
    currency: "NGN",
    discount: 0,
    inventories: [
      {
        id: "",
        name: "",
        amount: 0,
        quantity: 0,
        rate: 0,
        variations: [
          {
            type: "",
            name: "",
            id: "",
            price: 0,
            quantity: 0,
          },
        ],
      },
    ],
    services: [{ id: "", name: "", duration: "", price: 0 }],
    invoiceDueAt: "",
    invoiceNumber: "",
    issueDate: new Date().toISOString().substring(0, 10),
    isReoccurring: false,
    isReoccurringEndDate: "",
    isReoccurringStartDate: "",
    reoccurringGroupName: "",
    status: "pending",
    tax: 0,
    isPaymentCompleted: false,
    shouldSendEmail: true,
    exchangeRate: 1,
    paymentMethod: "",
    invoiceType: "inventory",
    logoBase64: "",
  });
  const handleSetCustomer = (selectedCustomer: ICustomer | null) => {
    if (selectedCustomer) {
      setCustomer(selectedCustomer);
      if (selectedCustomer.id) {
        setFormData((prevFormData: any) => ({
          ...prevFormData,
          customerId: selectedCustomer.id,
          name: `${selectedCustomer.firstName} ${selectedCustomer.lastName}`,
        }));
      }
    } else {
      setCustomer(null);
      setFormData((prevFormData: any) => ({
        ...prevFormData,
        customerId: "",
        name: "",
      }));
    }
  };

  const toggleDrawer = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  if (isLoading) {
    return (
      <>
        <PageLoader />
      </>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <>
      <div className="pb-12">
        <ReusableListPage
          createTitle="Add new customer"
          pageTitle="Customers"
          showCalendar={showCalendar}
          infoCardData={listInfoCardData}
          handleCalendarToggle={handleCalendarToggle}
          handleCreate={toggleDrawer}
          selectedLabel={selectedLabel}
          fetchMoreData={fetchMoreData}
          onSearchData={onSearchData}
          handleSearch={handleCustomerSearch}
          meta={pagination}
          data={renderCustomersData(customers)}
          modules="invoices"
          searchDisplayKey={"name"}
          searchPlaceHolder="Search by customer name"
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          isSearching={isSearching}
          searchResult={searchResult}
          searchedDone={searchedDone}
          setSearchedDone={setSearchedDone}
          columns={columns}
          mobileCard="CustomerCard"
          showFilter={false}
          emptyStateDescription="Creating a customer helps you keep proper records. Not sure how to create an customer?"
          emptyPageIcon={<ExpenseEmptyIcon />}
          emptyPageHref="https://www.youtube.com/watch?si=MqUzjg-b6k0u767M&v=G-6sAdSDvRM&feature=youtu.be"
          emptyPageLinkName="customers"
          handleSetCustomer={handleSetCustomer}
          emptyPageOnClose={() => setIsSidebarOpen(false)}
        />
      </div>

      <div className="lg:hidden md:hidden">
        {isSideBarOpen && (
          <CreateCustomer
            createCustomers={createCustomer}
            onClose={() => setIsSidebarOpen(false)}
            show={isSideBarOpen}
            customerListNavigate={true}
          />
        )}
      </div>
      <div className="hidden lg:block md:block">
        {isSideBarOpen && (
          <CreateCustomerModal
            showSideModal={isSideBarOpen}
            setShowSideModal={setIsSidebarOpen}
            handleSetCustomer={handleSetCustomer}
            onCustomerCreate={async (customer: ICustomer | null) => {
              handleSetCustomer(customer);
              await fetchCustomers();
              setIsSidebarOpen(false);
            }}
            onClose={() => setIsSidebarOpen(false)}
          />
        )}
      </div>
      {showCalendar && (
        <>
          <div className="bg-gray-900 bg-opacity-50 fixed top-0 right-0 left-0 z-50 h-[100vh]">
            <div className=" md:flex justify-center items-center h-full w-full">
              <DatePickerWithShortcuts
                onRangeSelect={handleRangeSelect}
                selected={selected}
                onClose={handleCalendarToggle}
                onApplyButtonClick={(startDate, endDate) =>
                  handleApplyButtonClick(startDate, endDate)
                }
                fetchData={fetchData}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { ViewCustomersPages };
