import React, { useEffect, useState } from "react";
import { ICreateSalesData, IInventory, ItemInput } from "../../../../@types";
import { VariationItem } from "../../../../@types/inventory";
import PlusIcon from "../../../../assets/icons/PlusIcon";
import { formatCurrencyMoney, formatMoney } from "../../../../utils";
import AddItem from "./AddItem";

export interface Option {
  name: string;
  type: string[];
}

interface LineItemsSectionProps {
  formData: ICreateSalesData;
  setFormData: React.Dispatch<React.SetStateAction<ICreateSalesData>>;
  variations: any[];
  total: number;
}

const LineItemsFreeform: React.FC<LineItemsSectionProps> = ({
  formData,
  setFormData,
  variations,
  total,
}) => {
  // Initialize state with null for no view by default
  const [currentView, setCurrentView] = useState<
    | "addItem"
    | "addInventory"
    | "productOptions"
    | "productVariants"
    | "viewSelectItems"
    | "showSelectedItem"
    | null
  >(null);

  const [selectedInventory, setSelectedInventory] = useState<IInventory | null>(
    null,
  );
  const [quantities, setQuantities] = useState<{ [key: string]: string }>({});
  const [options, setOptions] = useState<Option[]>([{ name: "", type: [""] }]);
  const [variationList, setVariationList] = useState<VariationItem[]>([]);

  // Handlers for navigation
  const handleOpenAddInventory = () => setCurrentView("addInventory");
  const handleOpenProductOptions = () => setCurrentView("productOptions");
  const handleOpenVariants = () => setCurrentView("productVariants");

  // const setSelectedInventoryAndQuantities = (inventory: any, updatedQuantities: { [key: string]: string }) => {
  //   setSelectedInventory(inventory);
  //   setQuantities(updatedQuantities);
  // };
  const setSelectedInventoryAndQuantities = (
    inventory: any,
    updatedQuantities: { [key: string]: string },
  ) => {
    // Calculate total here if needed
    const total = inventory.quantity * inventory.rate; // Example calculation, adjust as needed
    const updatedInventory = { ...inventory, total }; // Add total to the inventory object

    setSelectedInventory(updatedInventory); // Set the updated inventory with total
    setQuantities(updatedQuantities);
  };

  const closeProductOptions = () => {
    setCurrentView(null);
  };
  const [savedVariants, setSavedVariants] = useState<VariationItem[]>([]);
  const handleSave = (savedVariants: VariationItem[]) => {
    console.log("Saved Variants:", savedVariants);
    setSavedVariants((prev) => [...prev, ...savedVariants]);
    setFormData((prev) => ({
      ...prev,
      //@ts-ignore
      variations: [...(prev.inventories?.variations || []), ...savedVariants],
    }));
    setCurrentView(null);
  };

  const openViewSelectItems = (quantities: { [key: string]: string }) => {
    console.log("Quantities:", quantities);
    console.log("Selected Inventory:", selectedInventory);
    setQuantities(quantities);
    setCurrentView("viewSelectItems");
  };

  const handleEditClick = () => setCurrentView("showSelectedItem");
  const handleBackToNone = () => setCurrentView(null);
  const handleViewSelection = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      inventories: [...(prev.inventories || []), ...data],
    }));
    setCurrentView(null);
  };
  useEffect(() => {
    console.log("Current selectedInventory:", selectedInventory);
  }, [selectedInventory]);

  const closeModal = () => {
    setCurrentView(null);

    setQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };

      return updatedQuantities;
    });
  };

  //@ts-ignore
  const quantity =
    selectedInventory?.variations && selectedInventory.variations.length > 0
      ? //@ts-ignore
        parseFloat(quantities[selectedInventory.variations[0].id] || "0")
      : //@ts-ignore
        parseFloat(
          //@ts-ignore
          quantities[selectedInventory?.id || ""] ||
            selectedInventory?.quantity ||
            "0",
        );
  //@ts-ignore
  const rate = parseFloat(selectedInventory?.rate || "0");
  const price =
    selectedInventory?.variations && selectedInventory.variations.length > 0
      ? parseFloat(selectedInventory.variations[0].price?.toString() || "0")
      : 0;

  const totall = quantity * rate;
  const variationTotal = quantity * price;

  const handleSaveItem = (item: ItemInput) => {
    // setSelectedInventory(item); // Update selectedInventory
    setFormData((prev) => ({
      ...prev,
      lineItems: [...(prev.lineItems || []), item], // Add to lineItemsFreeform
      inventories: [...(prev.lineItems || []), item],
    }));

    setCurrentView(null); // Close modal
  };

  interface Item {
    name: string;
    quantity: number;
    rate: number;
    amount: number;
    id: string;
  }
  const [editItem, setEditItem] = useState<Item>();
  const [isEdit, setIsEdit] = useState(false);
  const edit = (item: Item) => {
    setIsEdit(true);
    setEditItem(item);
    setCurrentView("addItem");
  };
  // console.log(formData.lineItems);
  const deleteOldData = () => {
    setFormData((prev) => ({
      ...prev,
      lineItems: prev.lineItems.filter((i: any) => i.id !== editItem?.id),
      inventories: prev.lineItems.filter((i: any) => i.id !== editItem?.id),
    }));
  };

  return (
    <div className="w-full py-5 border-y border-gray-300 lg:hidden md:hidden">
      {currentView === null && formData.lineItems?.length === 0 && (
        <>
          <p className="text-[14px] font-medium leading-5 text-[#000000]">
            Invoice items
          </p>
          <button
            onClick={() => setCurrentView("addItem")}
            className="w-full font-medium leading-6 text-gray-700 flex justify-center py-3 my-2 rounded-lg border border-gray-300"
          >
            Add item
          </button>
        </>
      )}

      {formData.lineItems && formData.lineItems.length > 0 ? (
        <div className="flex flex-col j mb-4">
          <p className="text-[14px] font-medium leading-5 text-[#000000] mb-3">
            Invoice items
          </p>
          {/* Line Items List */}
          <div className="mb-4 flex flex-col">
            <p className="text-[16px] font-medium text-[#000000]">
              {formData.lineItems.name}
            </p>
            {formData.lineItems.map(
              (
                item: {
                  name: string;
                  quantity: number;
                  rate: number;
                  amount: number;
                  id: string;
                },
                index: number,
              ) => (
                <div key={index} className="mb-5">
                  <div className="flex justify-between mb-2">
                    <p className="text-[15px] font-medium text-[#000000]">
                      {item.name}
                    </p>

                    <p className="font-semibold text-[14px]">
                      {item.amount &&
                        `${formatCurrencyMoney(item.amount, formData.exchangeRate, formData.currency)}`}
                    </p>
                  </div>

                  <div className="flex justify-between">
                    {" "}
                    <p className="text-[14px] font-medium text-[#000000]">
                      {item.quantity} x{" "}
                      {formatCurrencyMoney(
                        item.rate,
                        formData.exchangeRate,
                        formData.currency,
                      )}
                    </p>
                    <button
                      onClick={() => edit(item)}
                      className="text-[#044E97] text-[14px] font-medium underline cursor-pointer"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              ),
            )}
          </div>
          <div className="mt-[1em] flex gap-[1em] justify-center items-center text-center border border-[#044E97] bg-[white] rounded-md px-2 py-2">
            <PlusIcon color="#044E97" />
            <button
              className="text-[14px] text-[#044E97]"
              onClick={() => setCurrentView("addItem")}
            >
              Add New Item
            </button>
          </div>
        </div>
      ) : null}

      {currentView === "addItem" && (
        <AddItem
          onSave={handleSaveItem}
          setIsOpen={handleBackToNone}
          editItem={editItem}
          setEditItem={setEditItem}
          isEdit={isEdit}
          deleteOldData={deleteOldData}
          setIsEdit={setIsEdit}
        />
      )}
    </div>
  );
};

export default LineItemsFreeform;
