import { useState, useEffect } from "react";
import { ExpenseStatsData } from "../../@types";
import { getExpenseStats } from "../../backend-services";

export function useExpenseStatsData(startDate: Date, endDate: Date) {
  const [expenseData, setExpenseData] = useState<ExpenseStatsData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const fetchData = async () => {
    try {
      const response = await getExpenseStats(
        startDate.toISOString(),
        endDate.toISOString()
      );
      const data: ExpenseStatsData = {
        totalExpenses: response.totalExpenses,
        totalExpenseValue: response.totalExpenseValue,
        totalBillableExpenseValue: response.totalBillableExpenseValue,
        totalBillableExpenses: response.totalBillableExpenses,
        totalNonBillableExpenseValue: response.totalNonBillableExpenseValue,
        totalNonBillableExpenses: response.totalNonBillableExpenses,
        dateRange: `${startDate.toDateString()} - ${endDate.toDateString()}`,
      };
      setExpenseData(data);
      setError(null);
    } catch (error) {
      setError("Failed to fetch expense statistics");
    }
  };

  useEffect(() => {
    fetchData();
  }, [startDate, endDate]);

  return { expenseData, error };
}
