import React, { forwardRef } from "react";
import { formatMoney, getInvoiceStatus } from "../../../utils";

interface DropdownItemProps<T> {
  item: T;
  displayKey: keyof T;
  onClick: (item: T, event: React.MouseEvent) => void;
  isLoading?: boolean;
}

export const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps<any>>(
  ({ item, displayKey, onClick, isLoading }, ref) => {
    const renderContent = () => {
      if (displayKey === "name") {
        return (
          <div className="flex flex-col justify">
            <span className="text-[14px] font-semibold">{item.name}</span>
            <span className="text-xs text-gray-500">{item.phone}</span>
          </div>
        );
      }

      if (displayKey === "customer.firstName") {
        return (
          <div className="flex px-3 justify-between">
            <div className="flex flex-col justify-start items-start">
              {isLoading ? (
                <span className="text-sm">Loading...</span>
              ) : (
                <>
                  <span className="lg:text-[16px] text-[12px] font-semibold flex justify-start">
                    {item.customer.firstName} {item.customer.lastName}
                  </span>
                  <span className="lg:text-[13px] text-[10px] font-light flex justify-start">
                    {item.invoiceNumber}
                  </span>
                </>
              )}
            </div>
            <div className="flex flex-col ml-2 justify-end items-end">
              <span className="lg:text-[16px] text-[12px] font-light flex justify-end items-end">
                {formatMoney(item.balanceDue, item.currency)}
              </span>
              <span className="lg:text-[11px] text-[10px] justify-end items-end">
                {getInvoiceStatus(item.status)}
              </span>
            </div>
          </div>
        );
      }

      if (displayKey === "id") {
        return (
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <span className="text-sm font-semibold">{item?.vendorName}</span>
              <span className="text-xs font-light">{item?.category}</span>
            </div>
            <div className="flex flex-col ml-2">
              {isLoading ? (
                <span>Loading...</span>
              ) : (
                <>
                  <span>{formatMoney(item.amount, item.currency)}</span>
                  <span>{new Date(item.createdAt).toLocaleDateString()}</span>
                </>
              )}
            </div>
          </div>
        );
      }

      // Default case for rendering the displayKey value
      return <span>{item[displayKey]}</span>;
    };

    return (
      <div className="p-2" ref={ref}>
        {renderContent()}
      </div>
    );
  }
);
